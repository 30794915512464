import Link from "next/link"
import { StyledLink } from "@styled/Link"
import { Row, Col } from "react-bootstrap"
import styled from "styled-components"
import { toast } from "react-toastify"
import { useContext } from "react"
import { AppContext } from "AppContext"
import { isEmpty } from "lodash"
import { useMobileMediaQuery } from "./Device"
import { useStudentReferralCodeLazyQuery } from "@generated/graphql"

export const FooterWrapper = styled.div`
  margin-top: 40px;
  background: url(/assets/svg/footer-background.svg) no-repeat;
  background-size: cover;
  position: absolute;
  width: 100%;
  height: fit-content;
  text-align: center;
  left: 0;
  & > div {
    position: relative;
    margin-top: 8%;
  }
  @media (max-width: 576px) {
    margin-top: 0;
  }
  @media (max-width: 859px) {
    & > div{
      position: relative;
      margin-top: 15%;
    }
  }
`

const StyledFooterImage = styled.img`
  min-height: 65px;
  background: url('/assets/svg/footer-button.svg') no-repeat;
  background-position: center;
  background-size: contain;
  padding: 15px;
  pointer-events: none;
`

function copyCode(code: string) {
  const el = document.createElement("textarea")
  el.value = code
  document.body.appendChild(el)
  el.select()
  document.execCommand("copy")
  document.body.removeChild(el)
}

interface IProps {
  classes?: string
}

export default function Footer({ classes = "" }: IProps) {
  const { state } = useContext(AppContext)
  const isMobile = useMobileMediaQuery()
  const [studentReferralCodeLazyQuery] = useStudentReferralCodeLazyQuery()

  const onShareClick = () => {
    studentReferralCodeLazyQuery().then((result) => {
      if (result && result.data && result.data.studentReferralCode.success) {
        const title = "Free Online Activities!!!"
        const description = "Check this out. I love completing daily activities on MySkillShaala Community.\nUse this link to learn skills like coding, origami, Maths, STEM, Paper Crafts and more for FREE.\nYou get Free 50 coins on registering using this link."
        const referralUrl = location.protocol + "//" + location.host + "/login?code=" + result.data.studentReferralCode.code
        if (isMobile) {
          // @ts-ignore
          if (navigator.share) {
            // @ts-ignore
            navigator.share({
              title: title,
              text: description,
              url: referralUrl,
            })
              .then(() => console.log("Successful share"))
              .catch((error: any) => console.log("Error sharing", error))
          } else {
            copyCode(`${title}\n${description}\n${referralUrl}`)
            toast("Referral URL is copied to clipboard!", {
              type: "success"
            })
          }
        } else {
          copyCode(`${title}\n${description}\n${referralUrl}`)
          toast("Referral URL is copied to clipboard!", {
            type: "success"
          })
        }
      }
      else {
        toast("Something went wrong!", {
          type: "error"
        })
      }
    })
  }
  return (
    <FooterWrapper className={classes}>
      {isEmpty(state.user) ?
        <Row className="mx-auto">
          <Col className="dashboard" xs={6} sm={4} md={2}>
            <StyledLink href="https://myskillshaala.com" target="_blank" rel="noreferrer">
              <StyledFooterImage src="/assets/svg/footer-dashboard.svg" alt="Home" />
              <span>Home</span>
            </StyledLink>
          </Col>
          <Col className="footer-login" xs={6} sm={4} md={2}>
            <Link href="/login" passHref legacyBehavior>
              <StyledLink>
                <StyledFooterImage src="/assets/svg/footer-login.svg" alt="Login" />
                <span>Login</span>
              </StyledLink>
            </Link>
          </Col>
          <Col className="products" xs={6} sm={4} md={2}>
            <Link href="/activities" passHref legacyBehavior>
              <StyledLink>
                <StyledFooterImage src="/assets/svg/footer-activities.svg" alt="Activities" />
                <span>Activities</span>
              </StyledLink>
            </Link>
          </Col>
          <Col className="help" xs={6} sm={4} md={2}>
            <StyledLink href="/workshops">
              <StyledFooterImage src="/assets/svg/footer-workshops.svg" alt="Workshops" />
              <span>Workshops</span>
            </StyledLink>
          </Col>
          <Col className="chat-now" xs={6} sm={4} md={2}>
            <StyledLink href="/products">
              <StyledFooterImage src="/assets/svg/footer-products.svg" alt="Products" />
              <span>Products</span>
            </StyledLink>
          </Col>
          <Col className="chat-now" xs={6} sm={4} md={2}>
            <StyledLink href="/faq" target="_blank">
              <StyledFooterImage src="/assets/svg/footer-help.svg" alt="Support" />
              <span>Support</span>
            </StyledLink>
          </Col>
        </Row>
        :
        <Row className="mx-auto">
          <Col className="dashboard">
            <Link href="/dashboard" passHref legacyBehavior>
              <StyledLink>
                <StyledFooterImage src="/assets/svg/footer-dashboard.svg" alt="Dashboard" />
                <span>Dashboard</span>
              </StyledLink>
            </Link>
          </Col>
          <Col className="profile">
            <Link href="/profile" passHref legacyBehavior>
              <StyledLink>
                <StyledFooterImage src="/assets/svg/footer-profile.svg" alt="Profile" />
                <span>Profile</span>
              </StyledLink>
            </Link>
          </Col>
          <Col className="leaderboard">
            <Link href="/leaderboard" passHref legacyBehavior>
              <StyledLink>
                <StyledFooterImage src="/assets/svg/footer-leaderboard.svg" alt="Leaderboard" />
                <span>Leaderboard</span>
              </StyledLink>
            </Link>
          </Col>
          <Col className="help">
            <StyledLink href="https://mss-support.tawk.help" target="_blank">
              <StyledFooterImage src="/assets/svg/footer-help.svg" alt="Help" />
              <span>Help</span>
            </StyledLink>
          </Col>
          <Col className="rewards">
            <Link href="/rewards" passHref legacyBehavior>
              <StyledLink>
                <StyledFooterImage src="/assets/svg/footer-rewards.svg" alt="Rewards" />
                <span>Rewards</span>
              </StyledLink>
            </Link>
          </Col>
          <Col className="invite-friend" onClick={onShareClick}>
            <StyledLink>
              <StyledFooterImage src="/assets/svg/footer-invite.svg" alt="Invite Friends" />
              <span>Invite Friends</span>
            </StyledLink>
          </Col>
          <Col className="logout">
            <StyledLink href="/logout">
              <StyledFooterImage src="/assets/svg/footer-logout.svg" alt="Logout" />
              <span>Logout</span>
            </StyledLink>
          </Col>
        </Row>
      }
    </FooterWrapper>
  )
}
