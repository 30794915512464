import { useMediaQuery } from "react-responsive"

const Desktop = ({ children }: { children: JSX.Element }) => {
  const isDesktop = useMediaQuery({ minWidth: 992 })
  return isDesktop ? children : null
}
const Tablet = ({ children }: { children: JSX.Element }) => {
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 })
  return isTablet ? children : null
}
const Mobile = ({ children }: { children: JSX.Element }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 })
  return isMobile ? children : null
}

const useDesktopMediaQuery = () => useMediaQuery({ query: "(min-width: 1280px)" })
const useMobileMediaQuery = () => useMediaQuery({ query: "(max-width: 1224px)" })

export { Desktop, Tablet, Mobile, useDesktopMediaQuery, useMobileMediaQuery }
