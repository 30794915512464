import { useEffect } from "react"
import { createGlobalStyle, ThemeProvider } from "styled-components"
import { useCookies } from "react-cookie"
import { ToastContainer } from "react-toastify"
import { ApolloProvider } from "@apollo/client"
import { AppProvider } from "AppContext"
import { useApollo } from "apollo/client"
import { GTMPageView } from "@utils/gtm"
import { useRouter } from "next/router"
import "../src/styles/App.scss"
import packageJSON from "../package.json"
import Layout from "../src/modules/Common/components/Layout"
import { poppins } from "@utils/fonts"

const GlobalStyle = createGlobalStyle`
  body {
    font-family: ${poppins.style.fontFamily}, sans-serif !important;
    margin: 0;
    padding: 0px;
    box-sizing: border-box;
  }
`

const light = {
  primary: "#3ba1da",
  secondary: "#eeeeee",
  sideNavColor: "white",
  textColor: "black",
}

const dark = {
  primary: "#0070f3",
  sideNavColor: "black",
  textColor: "white",
}

function MyApp({ Component, pageProps, err }: any) {

  console.log(`release version: ${packageJSON.version}`)

  useEffect(() => {
    if (window.location.toString().indexOf("localhost") === -1) {
      if (window !== undefined) {
        if (window.location.protocol === "http:") {
          window.location.href = window.location.toString().replace("http", "https")
        }
      }
    }
  }, [])

  const [cookies] = useCookies(["theme"])
  const theme = cookies.theme === "light" ? light : dark

  const router = useRouter()

  useEffect(() => {
    const handleRouteChange = (url: string) => GTMPageView(url)
    router.events.on("routeChangeComplete", handleRouteChange)
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange)
    }
  }, [router.events])

  const apolloClient = useApollo(pageProps.initialApolloState)
  return (
    <>
      <GlobalStyle />
      <ThemeProvider theme={theme}>
        <AppProvider>
          <ApolloProvider client={apolloClient}>
            <Layout>
              <ToastContainer hideProgressBar closeOnClick pauseOnHover draggable closeButton={false} autoClose={3000} position="bottom-center" theme="colored" limit={3} />
              <Component {...pageProps} err={err} />
            </Layout>
          </ApolloProvider>
        </AppProvider>
      </ThemeProvider>
    </>
  )
}

export default MyApp
