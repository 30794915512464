import { ReactNode, createContext, useReducer } from "react"
import { MeQuery, ProductPageDataQuery } from "@generated/graphql"

export enum ACTION_TYPE {
  SET_USER,
  SET_LOGIN_MODAL_VISIBLE,
  SET_PRODUCT_CHECKOUT
}

interface SetUserAction {
  type: ACTION_TYPE.SET_USER
  payload: NonNullable<MeQuery["me"]>
}

interface SetLoginModalStatusAction {
  type: ACTION_TYPE.SET_LOGIN_MODAL_VISIBLE
  payload: boolean
}

type SetProductCheckoutPayload = {
  product: ProductPageDataQuery["newReleasedProducts"][0]
}

interface SetProductCheckoutAction {
  type: ACTION_TYPE.SET_PRODUCT_CHECKOUT
  payload: SetProductCheckoutPayload | null
}

interface IStoreState {
  user?: NonNullable<MeQuery["me"]>,
  showLoginModal: boolean,
  checkout?: SetProductCheckoutPayload
}

const initialState: IStoreState = {
  showLoginModal: false
}

type IAction = SetUserAction | SetLoginModalStatusAction | SetProductCheckoutAction

type IAppContext = {
  state: IStoreState,
  dispatch: React.Dispatch<IAction>
}

const AppContext = createContext<IAppContext>({ state: initialState, dispatch: () => null })
const { Provider } = AppContext

const AppProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer((state: any, action: IAction) => {
    const { type, payload } = action
    switch (type) {
      case ACTION_TYPE.SET_USER:
        return { ...state, user: payload }
      case ACTION_TYPE.SET_LOGIN_MODAL_VISIBLE:
        return { ...state, showLoginModal: payload }
      case ACTION_TYPE.SET_PRODUCT_CHECKOUT:
        return { ...state, checkout: payload }
      default:
        return state
    }
  }, initialState)

  return <Provider value={{ state, dispatch }}>{children}</Provider>
}

export { AppContext, AppProvider }
