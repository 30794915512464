import { gql } from "@apollo/client"
import * as Apollo from "@apollo/client"
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never };
const defaultOptions = {} as const
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
  JSON: { input: any; output: any; }
  Upload: { input: any; output: any; }
};

export type Activity = {
  __typename?: "Activity";
  _count?: Maybe<ActivityCount>;
  approvedBy?: Maybe<User>;
  approvedById?: Maybe<Scalars["Int"]["output"]>;
  challengeStatement: Scalars["String"]["output"];
  coinsToSpend: Scalars["Int"]["output"];
  comments: Array<Comment>;
  createdAt: Scalars["DateTime"]["output"];
  description?: Maybe<Scalars["String"]["output"]>;
  difficultyLevel: Scalars["String"]["output"];
  displayOrder: Scalars["Int"]["output"];
  featuredImage?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["Int"]["output"];
  image?: Maybe<Scalars["String"]["output"]>;
  insideWorkshops: Array<Workshop>;
  instructionImage?: Maybe<Scalars["String"]["output"]>;
  instructionText?: Maybe<Scalars["String"]["output"]>;
  isChallenge: Scalars["Boolean"]["output"];
  isCommentEnabled: Scalars["Boolean"]["output"];
  isFeatured: Scalars["Boolean"]["output"];
  isIndexable: Scalars["Boolean"]["output"];
  isLive: Scalars["Boolean"]["output"];
  isVisible: Scalars["Boolean"]["output"];
  jsonLD?: Maybe<Scalars["JSON"]["output"]>;
  materialsNeeded?: Maybe<Scalars["String"]["output"]>;
  meta?: Maybe<Scalars["JSON"]["output"]>;
  metaTagData?: Maybe<Scalars["JSON"]["output"]>;
  nextActivity?: Maybe<Activity>;
  nextActivityId?: Maybe<Scalars["Int"]["output"]>;
  previousActivities: Array<Activity>;
  products: Array<Product>;
  promoCodes: Array<PromoCode>;
  publishedDate?: Maybe<Scalars["DateTime"]["output"]>;
  questionXps: Scalars["Int"]["output"];
  questions: Array<ActivityQuestion>;
  relatedWorkshops: Array<Workshop>;
  scheduleTime?: Maybe<Scalars["DateTime"]["output"]>;
  shortDescription?: Maybe<Scalars["String"]["output"]>;
  skill?: Maybe<Skill>;
  skillId?: Maybe<Scalars["Int"]["output"]>;
  slug: Scalars["String"]["output"];
  standards: Array<Standard>;
  status: ActivityStatus;
  subType?: Maybe<Scalars["String"]["output"]>;
  tags: Array<Tag>;
  timeRequired: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
  video?: Maybe<Scalars["String"]["output"]>;
  xps: Scalars["Int"]["output"];
};


export type ActivityCommentsArgs = {
  cursor?: InputMaybe<CommentWhereUniqueInput>;
  distinct?: InputMaybe<Array<CommentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CommentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<CommentWhereInput>;
};


export type ActivityInsideWorkshopsArgs = {
  cursor?: InputMaybe<WorkshopWhereUniqueInput>;
  distinct?: InputMaybe<Array<WorkshopScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<WorkshopOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<WorkshopWhereInput>;
};


export type ActivityPreviousActivitiesArgs = {
  cursor?: InputMaybe<ActivityWhereUniqueInput>;
  distinct?: InputMaybe<Array<ActivityScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ActivityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ActivityWhereInput>;
};


export type ActivityProductsArgs = {
  cursor?: InputMaybe<ProductWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProductScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProductOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ProductWhereInput>;
};


export type ActivityPromoCodesArgs = {
  cursor?: InputMaybe<PromoCodeWhereUniqueInput>;
  distinct?: InputMaybe<Array<PromoCodeScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PromoCodeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<PromoCodeWhereInput>;
};


export type ActivityQuestionsArgs = {
  cursor?: InputMaybe<ActivityQuestionWhereUniqueInput>;
  distinct?: InputMaybe<Array<ActivityQuestionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ActivityQuestionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ActivityQuestionWhereInput>;
};


export type ActivityRelatedWorkshopsArgs = {
  cursor?: InputMaybe<WorkshopWhereUniqueInput>;
  distinct?: InputMaybe<Array<WorkshopScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<WorkshopOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<WorkshopWhereInput>;
};


export type ActivityStandardsArgs = {
  cursor?: InputMaybe<StandardWhereUniqueInput>;
  distinct?: InputMaybe<Array<StandardScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StandardOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<StandardWhereInput>;
};


export type ActivityTagsArgs = {
  cursor?: InputMaybe<TagWhereUniqueInput>;
  distinct?: InputMaybe<Array<TagScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TagOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<TagWhereInput>;
};

export type ActivityAvgAggregate = {
  __typename?: "ActivityAvgAggregate";
  approvedById?: Maybe<Scalars["Float"]["output"]>;
  coinsToSpend?: Maybe<Scalars["Float"]["output"]>;
  displayOrder?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  nextActivityId?: Maybe<Scalars["Float"]["output"]>;
  questionXps?: Maybe<Scalars["Float"]["output"]>;
  skillId?: Maybe<Scalars["Float"]["output"]>;
  xps?: Maybe<Scalars["Float"]["output"]>;
};

export type ActivityBanner = {
  __typename?: "ActivityBanner";
  createdAt: Scalars["DateTime"]["output"];
  destinationUrl: Scalars["String"]["output"];
  displayOrder: Scalars["Int"]["output"];
  id: Scalars["Int"]["output"];
  image?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
};

export type ActivityBannerCreateInput = {
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  destinationUrl: Scalars["String"]["input"];
  displayOrder?: InputMaybe<Scalars["Int"]["input"]>;
  image?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ActivityBannerOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  destinationUrl?: InputMaybe<SortOrder>;
  displayOrder?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  image?: InputMaybe<SortOrderInput>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum ActivityBannerScalarFieldEnum {
  CREATEDAT = "createdAt",
  DESTINATIONURL = "destinationUrl",
  DISPLAYORDER = "displayOrder",
  ID = "id",
  IMAGE = "image",
  UPDATEDAT = "updatedAt"
}

export type ActivityBannerUpdateInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  destinationUrl?: InputMaybe<StringFieldUpdateOperationsInput>;
  displayOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ActivityBannerWhereInput = {
  AND?: InputMaybe<Array<ActivityBannerWhereInput>>;
  NOT?: InputMaybe<Array<ActivityBannerWhereInput>>;
  OR?: InputMaybe<Array<ActivityBannerWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  destinationUrl?: InputMaybe<StringFilter>;
  displayOrder?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  image?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ActivityBannerWhereUniqueInput = {
  id?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ActivityCount = {
  __typename?: "ActivityCount";
  comments: Scalars["Int"]["output"];
  insideWorkshops: Scalars["Int"]["output"];
  previousActivities: Scalars["Int"]["output"];
  products: Scalars["Int"]["output"];
  promoCodes: Scalars["Int"]["output"];
  questions: Scalars["Int"]["output"];
  relatedWorkshops: Scalars["Int"]["output"];
  standards: Scalars["Int"]["output"];
  tags: Scalars["Int"]["output"];
};


export type ActivityCountCommentsArgs = {
  where?: InputMaybe<CommentWhereInput>;
};


export type ActivityCountInsideWorkshopsArgs = {
  where?: InputMaybe<WorkshopWhereInput>;
};


export type ActivityCountPreviousActivitiesArgs = {
  where?: InputMaybe<ActivityWhereInput>;
};


export type ActivityCountProductsArgs = {
  where?: InputMaybe<ProductWhereInput>;
};


export type ActivityCountPromoCodesArgs = {
  where?: InputMaybe<PromoCodeWhereInput>;
};


export type ActivityCountQuestionsArgs = {
  where?: InputMaybe<ActivityQuestionWhereInput>;
};


export type ActivityCountRelatedWorkshopsArgs = {
  where?: InputMaybe<WorkshopWhereInput>;
};


export type ActivityCountStandardsArgs = {
  where?: InputMaybe<StandardWhereInput>;
};


export type ActivityCountTagsArgs = {
  where?: InputMaybe<TagWhereInput>;
};

export type ActivityCountAggregate = {
  __typename?: "ActivityCountAggregate";
  _all: Scalars["Int"]["output"];
  approvedById: Scalars["Int"]["output"];
  challengeStatement: Scalars["Int"]["output"];
  coinsToSpend: Scalars["Int"]["output"];
  createdAt: Scalars["Int"]["output"];
  description: Scalars["Int"]["output"];
  difficultyLevel: Scalars["Int"]["output"];
  displayOrder: Scalars["Int"]["output"];
  featuredImage: Scalars["Int"]["output"];
  id: Scalars["Int"]["output"];
  image: Scalars["Int"]["output"];
  instructionImage: Scalars["Int"]["output"];
  instructionText: Scalars["Int"]["output"];
  isChallenge: Scalars["Int"]["output"];
  isCommentEnabled: Scalars["Int"]["output"];
  isFeatured: Scalars["Int"]["output"];
  isIndexable: Scalars["Int"]["output"];
  isLive: Scalars["Int"]["output"];
  isVisible: Scalars["Int"]["output"];
  jsonLD: Scalars["Int"]["output"];
  materialsNeeded: Scalars["Int"]["output"];
  meta: Scalars["Int"]["output"];
  metaTagData: Scalars["Int"]["output"];
  nextActivityId: Scalars["Int"]["output"];
  publishedDate: Scalars["Int"]["output"];
  questionXps: Scalars["Int"]["output"];
  scheduleTime: Scalars["Int"]["output"];
  shortDescription: Scalars["Int"]["output"];
  skillId: Scalars["Int"]["output"];
  slug: Scalars["Int"]["output"];
  status: Scalars["Int"]["output"];
  subType: Scalars["Int"]["output"];
  timeRequired: Scalars["Int"]["output"];
  title: Scalars["Int"]["output"];
  updatedAt: Scalars["Int"]["output"];
  video: Scalars["Int"]["output"];
  xps: Scalars["Int"]["output"];
};

export type ActivityCreateInput = {
  approvedBy?: InputMaybe<UserCreateNestedOneWithoutActivitiesInput>;
  challengeStatement: Scalars["String"]["input"];
  coinsToSpend?: InputMaybe<Scalars["Int"]["input"]>;
  comments?: InputMaybe<CommentCreateNestedManyWithoutActivityInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  difficultyLevel: Scalars["String"]["input"];
  displayOrder?: InputMaybe<Scalars["Int"]["input"]>;
  featuredImage?: InputMaybe<Scalars["String"]["input"]>;
  image?: InputMaybe<Scalars["String"]["input"]>;
  insideWorkshops?: InputMaybe<WorkshopCreateNestedManyWithoutInsiderActivitiesInput>;
  instructionImage?: InputMaybe<Scalars["String"]["input"]>;
  instructionText?: InputMaybe<Scalars["String"]["input"]>;
  isChallenge?: InputMaybe<Scalars["Boolean"]["input"]>;
  isCommentEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  isFeatured?: InputMaybe<Scalars["Boolean"]["input"]>;
  isIndexable?: InputMaybe<Scalars["Boolean"]["input"]>;
  isLive?: InputMaybe<Scalars["Boolean"]["input"]>;
  isVisible?: InputMaybe<Scalars["Boolean"]["input"]>;
  jsonLD?: InputMaybe<Scalars["JSON"]["input"]>;
  materialsNeeded?: InputMaybe<Scalars["String"]["input"]>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  metaTagData?: InputMaybe<Scalars["JSON"]["input"]>;
  nextActivity?: InputMaybe<ActivityCreateNestedOneWithoutPreviousActivitiesInput>;
  previousActivities?: InputMaybe<ActivityCreateNestedManyWithoutNextActivityInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutRelatedActivitiesInput>;
  promoCodes?: InputMaybe<PromoCodeCreateNestedManyWithoutActivitiesInput>;
  publishedDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  questionXps?: InputMaybe<Scalars["Int"]["input"]>;
  questions?: InputMaybe<ActivityQuestionCreateNestedManyWithoutActivityInput>;
  relatedWorkshops?: InputMaybe<WorkshopCreateNestedManyWithoutRelatedActivitiesInput>;
  scheduleTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  shortDescription?: InputMaybe<Scalars["String"]["input"]>;
  skill?: InputMaybe<SkillCreateNestedOneWithoutActivitiesInput>;
  slug: Scalars["String"]["input"];
  standards?: InputMaybe<StandardCreateNestedManyWithoutActivitiesInput>;
  status?: InputMaybe<ActivityStatus>;
  subType?: InputMaybe<Scalars["String"]["input"]>;
  tags?: InputMaybe<TagCreateNestedManyWithoutActivitiesInput>;
  timeRequired: Scalars["String"]["input"];
  title: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  video?: InputMaybe<Scalars["String"]["input"]>;
  xps?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ActivityCreateManyApprovedByInput = {
  challengeStatement: Scalars["String"]["input"];
  coinsToSpend?: InputMaybe<Scalars["Int"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  difficultyLevel: Scalars["String"]["input"];
  displayOrder?: InputMaybe<Scalars["Int"]["input"]>;
  featuredImage?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  image?: InputMaybe<Scalars["String"]["input"]>;
  instructionImage?: InputMaybe<Scalars["String"]["input"]>;
  instructionText?: InputMaybe<Scalars["String"]["input"]>;
  isChallenge?: InputMaybe<Scalars["Boolean"]["input"]>;
  isCommentEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  isFeatured?: InputMaybe<Scalars["Boolean"]["input"]>;
  isIndexable?: InputMaybe<Scalars["Boolean"]["input"]>;
  isLive?: InputMaybe<Scalars["Boolean"]["input"]>;
  isVisible?: InputMaybe<Scalars["Boolean"]["input"]>;
  jsonLD?: InputMaybe<Scalars["JSON"]["input"]>;
  materialsNeeded?: InputMaybe<Scalars["String"]["input"]>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  metaTagData?: InputMaybe<Scalars["JSON"]["input"]>;
  nextActivityId?: InputMaybe<Scalars["Int"]["input"]>;
  publishedDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  questionXps?: InputMaybe<Scalars["Int"]["input"]>;
  scheduleTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  shortDescription?: InputMaybe<Scalars["String"]["input"]>;
  skillId?: InputMaybe<Scalars["Int"]["input"]>;
  slug: Scalars["String"]["input"];
  status?: InputMaybe<ActivityStatus>;
  subType?: InputMaybe<Scalars["String"]["input"]>;
  timeRequired: Scalars["String"]["input"];
  title: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  video?: InputMaybe<Scalars["String"]["input"]>;
  xps?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ActivityCreateManyApprovedByInputEnvelope = {
  data: Array<ActivityCreateManyApprovedByInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ActivityCreateManyNextActivityInput = {
  approvedById?: InputMaybe<Scalars["Int"]["input"]>;
  challengeStatement: Scalars["String"]["input"];
  coinsToSpend?: InputMaybe<Scalars["Int"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  difficultyLevel: Scalars["String"]["input"];
  displayOrder?: InputMaybe<Scalars["Int"]["input"]>;
  featuredImage?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  image?: InputMaybe<Scalars["String"]["input"]>;
  instructionImage?: InputMaybe<Scalars["String"]["input"]>;
  instructionText?: InputMaybe<Scalars["String"]["input"]>;
  isChallenge?: InputMaybe<Scalars["Boolean"]["input"]>;
  isCommentEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  isFeatured?: InputMaybe<Scalars["Boolean"]["input"]>;
  isIndexable?: InputMaybe<Scalars["Boolean"]["input"]>;
  isLive?: InputMaybe<Scalars["Boolean"]["input"]>;
  isVisible?: InputMaybe<Scalars["Boolean"]["input"]>;
  jsonLD?: InputMaybe<Scalars["JSON"]["input"]>;
  materialsNeeded?: InputMaybe<Scalars["String"]["input"]>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  metaTagData?: InputMaybe<Scalars["JSON"]["input"]>;
  publishedDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  questionXps?: InputMaybe<Scalars["Int"]["input"]>;
  scheduleTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  shortDescription?: InputMaybe<Scalars["String"]["input"]>;
  skillId?: InputMaybe<Scalars["Int"]["input"]>;
  slug: Scalars["String"]["input"];
  status?: InputMaybe<ActivityStatus>;
  subType?: InputMaybe<Scalars["String"]["input"]>;
  timeRequired: Scalars["String"]["input"];
  title: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  video?: InputMaybe<Scalars["String"]["input"]>;
  xps?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ActivityCreateManyNextActivityInputEnvelope = {
  data: Array<ActivityCreateManyNextActivityInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ActivityCreateManySkillInput = {
  approvedById?: InputMaybe<Scalars["Int"]["input"]>;
  challengeStatement: Scalars["String"]["input"];
  coinsToSpend?: InputMaybe<Scalars["Int"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  difficultyLevel: Scalars["String"]["input"];
  displayOrder?: InputMaybe<Scalars["Int"]["input"]>;
  featuredImage?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  image?: InputMaybe<Scalars["String"]["input"]>;
  instructionImage?: InputMaybe<Scalars["String"]["input"]>;
  instructionText?: InputMaybe<Scalars["String"]["input"]>;
  isChallenge?: InputMaybe<Scalars["Boolean"]["input"]>;
  isCommentEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  isFeatured?: InputMaybe<Scalars["Boolean"]["input"]>;
  isIndexable?: InputMaybe<Scalars["Boolean"]["input"]>;
  isLive?: InputMaybe<Scalars["Boolean"]["input"]>;
  isVisible?: InputMaybe<Scalars["Boolean"]["input"]>;
  jsonLD?: InputMaybe<Scalars["JSON"]["input"]>;
  materialsNeeded?: InputMaybe<Scalars["String"]["input"]>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  metaTagData?: InputMaybe<Scalars["JSON"]["input"]>;
  nextActivityId?: InputMaybe<Scalars["Int"]["input"]>;
  publishedDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  questionXps?: InputMaybe<Scalars["Int"]["input"]>;
  scheduleTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  shortDescription?: InputMaybe<Scalars["String"]["input"]>;
  slug: Scalars["String"]["input"];
  status?: InputMaybe<ActivityStatus>;
  subType?: InputMaybe<Scalars["String"]["input"]>;
  timeRequired: Scalars["String"]["input"];
  title: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  video?: InputMaybe<Scalars["String"]["input"]>;
  xps?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ActivityCreateManySkillInputEnvelope = {
  data: Array<ActivityCreateManySkillInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ActivityCreateNestedManyWithoutApprovedByInput = {
  connect?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ActivityCreateOrConnectWithoutApprovedByInput>>;
  create?: InputMaybe<Array<ActivityCreateWithoutApprovedByInput>>;
  createMany?: InputMaybe<ActivityCreateManyApprovedByInputEnvelope>;
};

export type ActivityCreateNestedManyWithoutInsideWorkshopsInput = {
  connect?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ActivityCreateOrConnectWithoutInsideWorkshopsInput>>;
  create?: InputMaybe<Array<ActivityCreateWithoutInsideWorkshopsInput>>;
};

export type ActivityCreateNestedManyWithoutNextActivityInput = {
  connect?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ActivityCreateOrConnectWithoutNextActivityInput>>;
  create?: InputMaybe<Array<ActivityCreateWithoutNextActivityInput>>;
  createMany?: InputMaybe<ActivityCreateManyNextActivityInputEnvelope>;
};

export type ActivityCreateNestedManyWithoutProductsInput = {
  connect?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ActivityCreateOrConnectWithoutProductsInput>>;
  create?: InputMaybe<Array<ActivityCreateWithoutProductsInput>>;
};

export type ActivityCreateNestedManyWithoutPromoCodesInput = {
  connect?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ActivityCreateOrConnectWithoutPromoCodesInput>>;
  create?: InputMaybe<Array<ActivityCreateWithoutPromoCodesInput>>;
};

export type ActivityCreateNestedManyWithoutRelatedWorkshopsInput = {
  connect?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ActivityCreateOrConnectWithoutRelatedWorkshopsInput>>;
  create?: InputMaybe<Array<ActivityCreateWithoutRelatedWorkshopsInput>>;
};

export type ActivityCreateNestedManyWithoutSkillInput = {
  connect?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ActivityCreateOrConnectWithoutSkillInput>>;
  create?: InputMaybe<Array<ActivityCreateWithoutSkillInput>>;
  createMany?: InputMaybe<ActivityCreateManySkillInputEnvelope>;
};

export type ActivityCreateNestedManyWithoutStandardsInput = {
  connect?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ActivityCreateOrConnectWithoutStandardsInput>>;
  create?: InputMaybe<Array<ActivityCreateWithoutStandardsInput>>;
};

export type ActivityCreateNestedManyWithoutTagsInput = {
  connect?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ActivityCreateOrConnectWithoutTagsInput>>;
  create?: InputMaybe<Array<ActivityCreateWithoutTagsInput>>;
};

export type ActivityCreateNestedOneWithoutCommentsInput = {
  connect?: InputMaybe<ActivityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ActivityCreateOrConnectWithoutCommentsInput>;
  create?: InputMaybe<ActivityCreateWithoutCommentsInput>;
};

export type ActivityCreateNestedOneWithoutPreviousActivitiesInput = {
  connect?: InputMaybe<ActivityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ActivityCreateOrConnectWithoutPreviousActivitiesInput>;
  create?: InputMaybe<ActivityCreateWithoutPreviousActivitiesInput>;
};

export type ActivityCreateNestedOneWithoutQuestionsInput = {
  connect?: InputMaybe<ActivityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ActivityCreateOrConnectWithoutQuestionsInput>;
  create?: InputMaybe<ActivityCreateWithoutQuestionsInput>;
};

export type ActivityCreateOrConnectWithoutApprovedByInput = {
  create: ActivityCreateWithoutApprovedByInput;
  where: ActivityWhereUniqueInput;
};

export type ActivityCreateOrConnectWithoutCommentsInput = {
  create: ActivityCreateWithoutCommentsInput;
  where: ActivityWhereUniqueInput;
};

export type ActivityCreateOrConnectWithoutInsideWorkshopsInput = {
  create: ActivityCreateWithoutInsideWorkshopsInput;
  where: ActivityWhereUniqueInput;
};

export type ActivityCreateOrConnectWithoutNextActivityInput = {
  create: ActivityCreateWithoutNextActivityInput;
  where: ActivityWhereUniqueInput;
};

export type ActivityCreateOrConnectWithoutPreviousActivitiesInput = {
  create: ActivityCreateWithoutPreviousActivitiesInput;
  where: ActivityWhereUniqueInput;
};

export type ActivityCreateOrConnectWithoutProductsInput = {
  create: ActivityCreateWithoutProductsInput;
  where: ActivityWhereUniqueInput;
};

export type ActivityCreateOrConnectWithoutPromoCodesInput = {
  create: ActivityCreateWithoutPromoCodesInput;
  where: ActivityWhereUniqueInput;
};

export type ActivityCreateOrConnectWithoutQuestionsInput = {
  create: ActivityCreateWithoutQuestionsInput;
  where: ActivityWhereUniqueInput;
};

export type ActivityCreateOrConnectWithoutRelatedWorkshopsInput = {
  create: ActivityCreateWithoutRelatedWorkshopsInput;
  where: ActivityWhereUniqueInput;
};

export type ActivityCreateOrConnectWithoutSkillInput = {
  create: ActivityCreateWithoutSkillInput;
  where: ActivityWhereUniqueInput;
};

export type ActivityCreateOrConnectWithoutStandardsInput = {
  create: ActivityCreateWithoutStandardsInput;
  where: ActivityWhereUniqueInput;
};

export type ActivityCreateOrConnectWithoutTagsInput = {
  create: ActivityCreateWithoutTagsInput;
  where: ActivityWhereUniqueInput;
};

export type ActivityCreateWithoutApprovedByInput = {
  challengeStatement: Scalars["String"]["input"];
  coinsToSpend?: InputMaybe<Scalars["Int"]["input"]>;
  comments?: InputMaybe<CommentCreateNestedManyWithoutActivityInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  difficultyLevel: Scalars["String"]["input"];
  displayOrder?: InputMaybe<Scalars["Int"]["input"]>;
  featuredImage?: InputMaybe<Scalars["String"]["input"]>;
  image?: InputMaybe<Scalars["String"]["input"]>;
  insideWorkshops?: InputMaybe<WorkshopCreateNestedManyWithoutInsiderActivitiesInput>;
  instructionImage?: InputMaybe<Scalars["String"]["input"]>;
  instructionText?: InputMaybe<Scalars["String"]["input"]>;
  isChallenge?: InputMaybe<Scalars["Boolean"]["input"]>;
  isCommentEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  isFeatured?: InputMaybe<Scalars["Boolean"]["input"]>;
  isIndexable?: InputMaybe<Scalars["Boolean"]["input"]>;
  isLive?: InputMaybe<Scalars["Boolean"]["input"]>;
  isVisible?: InputMaybe<Scalars["Boolean"]["input"]>;
  jsonLD?: InputMaybe<Scalars["JSON"]["input"]>;
  materialsNeeded?: InputMaybe<Scalars["String"]["input"]>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  metaTagData?: InputMaybe<Scalars["JSON"]["input"]>;
  nextActivity?: InputMaybe<ActivityCreateNestedOneWithoutPreviousActivitiesInput>;
  previousActivities?: InputMaybe<ActivityCreateNestedManyWithoutNextActivityInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutRelatedActivitiesInput>;
  promoCodes?: InputMaybe<PromoCodeCreateNestedManyWithoutActivitiesInput>;
  publishedDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  questionXps?: InputMaybe<Scalars["Int"]["input"]>;
  questions?: InputMaybe<ActivityQuestionCreateNestedManyWithoutActivityInput>;
  relatedWorkshops?: InputMaybe<WorkshopCreateNestedManyWithoutRelatedActivitiesInput>;
  scheduleTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  shortDescription?: InputMaybe<Scalars["String"]["input"]>;
  skill?: InputMaybe<SkillCreateNestedOneWithoutActivitiesInput>;
  slug: Scalars["String"]["input"];
  standards?: InputMaybe<StandardCreateNestedManyWithoutActivitiesInput>;
  status?: InputMaybe<ActivityStatus>;
  subType?: InputMaybe<Scalars["String"]["input"]>;
  tags?: InputMaybe<TagCreateNestedManyWithoutActivitiesInput>;
  timeRequired: Scalars["String"]["input"];
  title: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  video?: InputMaybe<Scalars["String"]["input"]>;
  xps?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ActivityCreateWithoutCommentsInput = {
  approvedBy?: InputMaybe<UserCreateNestedOneWithoutActivitiesInput>;
  challengeStatement: Scalars["String"]["input"];
  coinsToSpend?: InputMaybe<Scalars["Int"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  difficultyLevel: Scalars["String"]["input"];
  displayOrder?: InputMaybe<Scalars["Int"]["input"]>;
  featuredImage?: InputMaybe<Scalars["String"]["input"]>;
  image?: InputMaybe<Scalars["String"]["input"]>;
  insideWorkshops?: InputMaybe<WorkshopCreateNestedManyWithoutInsiderActivitiesInput>;
  instructionImage?: InputMaybe<Scalars["String"]["input"]>;
  instructionText?: InputMaybe<Scalars["String"]["input"]>;
  isChallenge?: InputMaybe<Scalars["Boolean"]["input"]>;
  isCommentEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  isFeatured?: InputMaybe<Scalars["Boolean"]["input"]>;
  isIndexable?: InputMaybe<Scalars["Boolean"]["input"]>;
  isLive?: InputMaybe<Scalars["Boolean"]["input"]>;
  isVisible?: InputMaybe<Scalars["Boolean"]["input"]>;
  jsonLD?: InputMaybe<Scalars["JSON"]["input"]>;
  materialsNeeded?: InputMaybe<Scalars["String"]["input"]>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  metaTagData?: InputMaybe<Scalars["JSON"]["input"]>;
  nextActivity?: InputMaybe<ActivityCreateNestedOneWithoutPreviousActivitiesInput>;
  previousActivities?: InputMaybe<ActivityCreateNestedManyWithoutNextActivityInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutRelatedActivitiesInput>;
  promoCodes?: InputMaybe<PromoCodeCreateNestedManyWithoutActivitiesInput>;
  publishedDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  questionXps?: InputMaybe<Scalars["Int"]["input"]>;
  questions?: InputMaybe<ActivityQuestionCreateNestedManyWithoutActivityInput>;
  relatedWorkshops?: InputMaybe<WorkshopCreateNestedManyWithoutRelatedActivitiesInput>;
  scheduleTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  shortDescription?: InputMaybe<Scalars["String"]["input"]>;
  skill?: InputMaybe<SkillCreateNestedOneWithoutActivitiesInput>;
  slug: Scalars["String"]["input"];
  standards?: InputMaybe<StandardCreateNestedManyWithoutActivitiesInput>;
  status?: InputMaybe<ActivityStatus>;
  subType?: InputMaybe<Scalars["String"]["input"]>;
  tags?: InputMaybe<TagCreateNestedManyWithoutActivitiesInput>;
  timeRequired: Scalars["String"]["input"];
  title: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  video?: InputMaybe<Scalars["String"]["input"]>;
  xps?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ActivityCreateWithoutInsideWorkshopsInput = {
  approvedBy?: InputMaybe<UserCreateNestedOneWithoutActivitiesInput>;
  challengeStatement: Scalars["String"]["input"];
  coinsToSpend?: InputMaybe<Scalars["Int"]["input"]>;
  comments?: InputMaybe<CommentCreateNestedManyWithoutActivityInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  difficultyLevel: Scalars["String"]["input"];
  displayOrder?: InputMaybe<Scalars["Int"]["input"]>;
  featuredImage?: InputMaybe<Scalars["String"]["input"]>;
  image?: InputMaybe<Scalars["String"]["input"]>;
  instructionImage?: InputMaybe<Scalars["String"]["input"]>;
  instructionText?: InputMaybe<Scalars["String"]["input"]>;
  isChallenge?: InputMaybe<Scalars["Boolean"]["input"]>;
  isCommentEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  isFeatured?: InputMaybe<Scalars["Boolean"]["input"]>;
  isIndexable?: InputMaybe<Scalars["Boolean"]["input"]>;
  isLive?: InputMaybe<Scalars["Boolean"]["input"]>;
  isVisible?: InputMaybe<Scalars["Boolean"]["input"]>;
  jsonLD?: InputMaybe<Scalars["JSON"]["input"]>;
  materialsNeeded?: InputMaybe<Scalars["String"]["input"]>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  metaTagData?: InputMaybe<Scalars["JSON"]["input"]>;
  nextActivity?: InputMaybe<ActivityCreateNestedOneWithoutPreviousActivitiesInput>;
  previousActivities?: InputMaybe<ActivityCreateNestedManyWithoutNextActivityInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutRelatedActivitiesInput>;
  promoCodes?: InputMaybe<PromoCodeCreateNestedManyWithoutActivitiesInput>;
  publishedDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  questionXps?: InputMaybe<Scalars["Int"]["input"]>;
  questions?: InputMaybe<ActivityQuestionCreateNestedManyWithoutActivityInput>;
  relatedWorkshops?: InputMaybe<WorkshopCreateNestedManyWithoutRelatedActivitiesInput>;
  scheduleTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  shortDescription?: InputMaybe<Scalars["String"]["input"]>;
  skill?: InputMaybe<SkillCreateNestedOneWithoutActivitiesInput>;
  slug: Scalars["String"]["input"];
  standards?: InputMaybe<StandardCreateNestedManyWithoutActivitiesInput>;
  status?: InputMaybe<ActivityStatus>;
  subType?: InputMaybe<Scalars["String"]["input"]>;
  tags?: InputMaybe<TagCreateNestedManyWithoutActivitiesInput>;
  timeRequired: Scalars["String"]["input"];
  title: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  video?: InputMaybe<Scalars["String"]["input"]>;
  xps?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ActivityCreateWithoutNextActivityInput = {
  approvedBy?: InputMaybe<UserCreateNestedOneWithoutActivitiesInput>;
  challengeStatement: Scalars["String"]["input"];
  coinsToSpend?: InputMaybe<Scalars["Int"]["input"]>;
  comments?: InputMaybe<CommentCreateNestedManyWithoutActivityInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  difficultyLevel: Scalars["String"]["input"];
  displayOrder?: InputMaybe<Scalars["Int"]["input"]>;
  featuredImage?: InputMaybe<Scalars["String"]["input"]>;
  image?: InputMaybe<Scalars["String"]["input"]>;
  insideWorkshops?: InputMaybe<WorkshopCreateNestedManyWithoutInsiderActivitiesInput>;
  instructionImage?: InputMaybe<Scalars["String"]["input"]>;
  instructionText?: InputMaybe<Scalars["String"]["input"]>;
  isChallenge?: InputMaybe<Scalars["Boolean"]["input"]>;
  isCommentEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  isFeatured?: InputMaybe<Scalars["Boolean"]["input"]>;
  isIndexable?: InputMaybe<Scalars["Boolean"]["input"]>;
  isLive?: InputMaybe<Scalars["Boolean"]["input"]>;
  isVisible?: InputMaybe<Scalars["Boolean"]["input"]>;
  jsonLD?: InputMaybe<Scalars["JSON"]["input"]>;
  materialsNeeded?: InputMaybe<Scalars["String"]["input"]>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  metaTagData?: InputMaybe<Scalars["JSON"]["input"]>;
  previousActivities?: InputMaybe<ActivityCreateNestedManyWithoutNextActivityInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutRelatedActivitiesInput>;
  promoCodes?: InputMaybe<PromoCodeCreateNestedManyWithoutActivitiesInput>;
  publishedDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  questionXps?: InputMaybe<Scalars["Int"]["input"]>;
  questions?: InputMaybe<ActivityQuestionCreateNestedManyWithoutActivityInput>;
  relatedWorkshops?: InputMaybe<WorkshopCreateNestedManyWithoutRelatedActivitiesInput>;
  scheduleTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  shortDescription?: InputMaybe<Scalars["String"]["input"]>;
  skill?: InputMaybe<SkillCreateNestedOneWithoutActivitiesInput>;
  slug: Scalars["String"]["input"];
  standards?: InputMaybe<StandardCreateNestedManyWithoutActivitiesInput>;
  status?: InputMaybe<ActivityStatus>;
  subType?: InputMaybe<Scalars["String"]["input"]>;
  tags?: InputMaybe<TagCreateNestedManyWithoutActivitiesInput>;
  timeRequired: Scalars["String"]["input"];
  title: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  video?: InputMaybe<Scalars["String"]["input"]>;
  xps?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ActivityCreateWithoutPreviousActivitiesInput = {
  approvedBy?: InputMaybe<UserCreateNestedOneWithoutActivitiesInput>;
  challengeStatement: Scalars["String"]["input"];
  coinsToSpend?: InputMaybe<Scalars["Int"]["input"]>;
  comments?: InputMaybe<CommentCreateNestedManyWithoutActivityInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  difficultyLevel: Scalars["String"]["input"];
  displayOrder?: InputMaybe<Scalars["Int"]["input"]>;
  featuredImage?: InputMaybe<Scalars["String"]["input"]>;
  image?: InputMaybe<Scalars["String"]["input"]>;
  insideWorkshops?: InputMaybe<WorkshopCreateNestedManyWithoutInsiderActivitiesInput>;
  instructionImage?: InputMaybe<Scalars["String"]["input"]>;
  instructionText?: InputMaybe<Scalars["String"]["input"]>;
  isChallenge?: InputMaybe<Scalars["Boolean"]["input"]>;
  isCommentEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  isFeatured?: InputMaybe<Scalars["Boolean"]["input"]>;
  isIndexable?: InputMaybe<Scalars["Boolean"]["input"]>;
  isLive?: InputMaybe<Scalars["Boolean"]["input"]>;
  isVisible?: InputMaybe<Scalars["Boolean"]["input"]>;
  jsonLD?: InputMaybe<Scalars["JSON"]["input"]>;
  materialsNeeded?: InputMaybe<Scalars["String"]["input"]>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  metaTagData?: InputMaybe<Scalars["JSON"]["input"]>;
  nextActivity?: InputMaybe<ActivityCreateNestedOneWithoutPreviousActivitiesInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutRelatedActivitiesInput>;
  promoCodes?: InputMaybe<PromoCodeCreateNestedManyWithoutActivitiesInput>;
  publishedDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  questionXps?: InputMaybe<Scalars["Int"]["input"]>;
  questions?: InputMaybe<ActivityQuestionCreateNestedManyWithoutActivityInput>;
  relatedWorkshops?: InputMaybe<WorkshopCreateNestedManyWithoutRelatedActivitiesInput>;
  scheduleTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  shortDescription?: InputMaybe<Scalars["String"]["input"]>;
  skill?: InputMaybe<SkillCreateNestedOneWithoutActivitiesInput>;
  slug: Scalars["String"]["input"];
  standards?: InputMaybe<StandardCreateNestedManyWithoutActivitiesInput>;
  status?: InputMaybe<ActivityStatus>;
  subType?: InputMaybe<Scalars["String"]["input"]>;
  tags?: InputMaybe<TagCreateNestedManyWithoutActivitiesInput>;
  timeRequired: Scalars["String"]["input"];
  title: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  video?: InputMaybe<Scalars["String"]["input"]>;
  xps?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ActivityCreateWithoutProductsInput = {
  approvedBy?: InputMaybe<UserCreateNestedOneWithoutActivitiesInput>;
  challengeStatement: Scalars["String"]["input"];
  coinsToSpend?: InputMaybe<Scalars["Int"]["input"]>;
  comments?: InputMaybe<CommentCreateNestedManyWithoutActivityInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  difficultyLevel: Scalars["String"]["input"];
  displayOrder?: InputMaybe<Scalars["Int"]["input"]>;
  featuredImage?: InputMaybe<Scalars["String"]["input"]>;
  image?: InputMaybe<Scalars["String"]["input"]>;
  insideWorkshops?: InputMaybe<WorkshopCreateNestedManyWithoutInsiderActivitiesInput>;
  instructionImage?: InputMaybe<Scalars["String"]["input"]>;
  instructionText?: InputMaybe<Scalars["String"]["input"]>;
  isChallenge?: InputMaybe<Scalars["Boolean"]["input"]>;
  isCommentEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  isFeatured?: InputMaybe<Scalars["Boolean"]["input"]>;
  isIndexable?: InputMaybe<Scalars["Boolean"]["input"]>;
  isLive?: InputMaybe<Scalars["Boolean"]["input"]>;
  isVisible?: InputMaybe<Scalars["Boolean"]["input"]>;
  jsonLD?: InputMaybe<Scalars["JSON"]["input"]>;
  materialsNeeded?: InputMaybe<Scalars["String"]["input"]>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  metaTagData?: InputMaybe<Scalars["JSON"]["input"]>;
  nextActivity?: InputMaybe<ActivityCreateNestedOneWithoutPreviousActivitiesInput>;
  previousActivities?: InputMaybe<ActivityCreateNestedManyWithoutNextActivityInput>;
  promoCodes?: InputMaybe<PromoCodeCreateNestedManyWithoutActivitiesInput>;
  publishedDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  questionXps?: InputMaybe<Scalars["Int"]["input"]>;
  questions?: InputMaybe<ActivityQuestionCreateNestedManyWithoutActivityInput>;
  relatedWorkshops?: InputMaybe<WorkshopCreateNestedManyWithoutRelatedActivitiesInput>;
  scheduleTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  shortDescription?: InputMaybe<Scalars["String"]["input"]>;
  skill?: InputMaybe<SkillCreateNestedOneWithoutActivitiesInput>;
  slug: Scalars["String"]["input"];
  standards?: InputMaybe<StandardCreateNestedManyWithoutActivitiesInput>;
  status?: InputMaybe<ActivityStatus>;
  subType?: InputMaybe<Scalars["String"]["input"]>;
  tags?: InputMaybe<TagCreateNestedManyWithoutActivitiesInput>;
  timeRequired: Scalars["String"]["input"];
  title: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  video?: InputMaybe<Scalars["String"]["input"]>;
  xps?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ActivityCreateWithoutPromoCodesInput = {
  approvedBy?: InputMaybe<UserCreateNestedOneWithoutActivitiesInput>;
  challengeStatement: Scalars["String"]["input"];
  coinsToSpend?: InputMaybe<Scalars["Int"]["input"]>;
  comments?: InputMaybe<CommentCreateNestedManyWithoutActivityInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  difficultyLevel: Scalars["String"]["input"];
  displayOrder?: InputMaybe<Scalars["Int"]["input"]>;
  featuredImage?: InputMaybe<Scalars["String"]["input"]>;
  image?: InputMaybe<Scalars["String"]["input"]>;
  insideWorkshops?: InputMaybe<WorkshopCreateNestedManyWithoutInsiderActivitiesInput>;
  instructionImage?: InputMaybe<Scalars["String"]["input"]>;
  instructionText?: InputMaybe<Scalars["String"]["input"]>;
  isChallenge?: InputMaybe<Scalars["Boolean"]["input"]>;
  isCommentEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  isFeatured?: InputMaybe<Scalars["Boolean"]["input"]>;
  isIndexable?: InputMaybe<Scalars["Boolean"]["input"]>;
  isLive?: InputMaybe<Scalars["Boolean"]["input"]>;
  isVisible?: InputMaybe<Scalars["Boolean"]["input"]>;
  jsonLD?: InputMaybe<Scalars["JSON"]["input"]>;
  materialsNeeded?: InputMaybe<Scalars["String"]["input"]>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  metaTagData?: InputMaybe<Scalars["JSON"]["input"]>;
  nextActivity?: InputMaybe<ActivityCreateNestedOneWithoutPreviousActivitiesInput>;
  previousActivities?: InputMaybe<ActivityCreateNestedManyWithoutNextActivityInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutRelatedActivitiesInput>;
  publishedDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  questionXps?: InputMaybe<Scalars["Int"]["input"]>;
  questions?: InputMaybe<ActivityQuestionCreateNestedManyWithoutActivityInput>;
  relatedWorkshops?: InputMaybe<WorkshopCreateNestedManyWithoutRelatedActivitiesInput>;
  scheduleTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  shortDescription?: InputMaybe<Scalars["String"]["input"]>;
  skill?: InputMaybe<SkillCreateNestedOneWithoutActivitiesInput>;
  slug: Scalars["String"]["input"];
  standards?: InputMaybe<StandardCreateNestedManyWithoutActivitiesInput>;
  status?: InputMaybe<ActivityStatus>;
  subType?: InputMaybe<Scalars["String"]["input"]>;
  tags?: InputMaybe<TagCreateNestedManyWithoutActivitiesInput>;
  timeRequired: Scalars["String"]["input"];
  title: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  video?: InputMaybe<Scalars["String"]["input"]>;
  xps?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ActivityCreateWithoutQuestionsInput = {
  approvedBy?: InputMaybe<UserCreateNestedOneWithoutActivitiesInput>;
  challengeStatement: Scalars["String"]["input"];
  coinsToSpend?: InputMaybe<Scalars["Int"]["input"]>;
  comments?: InputMaybe<CommentCreateNestedManyWithoutActivityInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  difficultyLevel: Scalars["String"]["input"];
  displayOrder?: InputMaybe<Scalars["Int"]["input"]>;
  featuredImage?: InputMaybe<Scalars["String"]["input"]>;
  image?: InputMaybe<Scalars["String"]["input"]>;
  insideWorkshops?: InputMaybe<WorkshopCreateNestedManyWithoutInsiderActivitiesInput>;
  instructionImage?: InputMaybe<Scalars["String"]["input"]>;
  instructionText?: InputMaybe<Scalars["String"]["input"]>;
  isChallenge?: InputMaybe<Scalars["Boolean"]["input"]>;
  isCommentEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  isFeatured?: InputMaybe<Scalars["Boolean"]["input"]>;
  isIndexable?: InputMaybe<Scalars["Boolean"]["input"]>;
  isLive?: InputMaybe<Scalars["Boolean"]["input"]>;
  isVisible?: InputMaybe<Scalars["Boolean"]["input"]>;
  jsonLD?: InputMaybe<Scalars["JSON"]["input"]>;
  materialsNeeded?: InputMaybe<Scalars["String"]["input"]>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  metaTagData?: InputMaybe<Scalars["JSON"]["input"]>;
  nextActivity?: InputMaybe<ActivityCreateNestedOneWithoutPreviousActivitiesInput>;
  previousActivities?: InputMaybe<ActivityCreateNestedManyWithoutNextActivityInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutRelatedActivitiesInput>;
  promoCodes?: InputMaybe<PromoCodeCreateNestedManyWithoutActivitiesInput>;
  publishedDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  questionXps?: InputMaybe<Scalars["Int"]["input"]>;
  relatedWorkshops?: InputMaybe<WorkshopCreateNestedManyWithoutRelatedActivitiesInput>;
  scheduleTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  shortDescription?: InputMaybe<Scalars["String"]["input"]>;
  skill?: InputMaybe<SkillCreateNestedOneWithoutActivitiesInput>;
  slug: Scalars["String"]["input"];
  standards?: InputMaybe<StandardCreateNestedManyWithoutActivitiesInput>;
  status?: InputMaybe<ActivityStatus>;
  subType?: InputMaybe<Scalars["String"]["input"]>;
  tags?: InputMaybe<TagCreateNestedManyWithoutActivitiesInput>;
  timeRequired: Scalars["String"]["input"];
  title: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  video?: InputMaybe<Scalars["String"]["input"]>;
  xps?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ActivityCreateWithoutRelatedWorkshopsInput = {
  approvedBy?: InputMaybe<UserCreateNestedOneWithoutActivitiesInput>;
  challengeStatement: Scalars["String"]["input"];
  coinsToSpend?: InputMaybe<Scalars["Int"]["input"]>;
  comments?: InputMaybe<CommentCreateNestedManyWithoutActivityInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  difficultyLevel: Scalars["String"]["input"];
  displayOrder?: InputMaybe<Scalars["Int"]["input"]>;
  featuredImage?: InputMaybe<Scalars["String"]["input"]>;
  image?: InputMaybe<Scalars["String"]["input"]>;
  insideWorkshops?: InputMaybe<WorkshopCreateNestedManyWithoutInsiderActivitiesInput>;
  instructionImage?: InputMaybe<Scalars["String"]["input"]>;
  instructionText?: InputMaybe<Scalars["String"]["input"]>;
  isChallenge?: InputMaybe<Scalars["Boolean"]["input"]>;
  isCommentEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  isFeatured?: InputMaybe<Scalars["Boolean"]["input"]>;
  isIndexable?: InputMaybe<Scalars["Boolean"]["input"]>;
  isLive?: InputMaybe<Scalars["Boolean"]["input"]>;
  isVisible?: InputMaybe<Scalars["Boolean"]["input"]>;
  jsonLD?: InputMaybe<Scalars["JSON"]["input"]>;
  materialsNeeded?: InputMaybe<Scalars["String"]["input"]>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  metaTagData?: InputMaybe<Scalars["JSON"]["input"]>;
  nextActivity?: InputMaybe<ActivityCreateNestedOneWithoutPreviousActivitiesInput>;
  previousActivities?: InputMaybe<ActivityCreateNestedManyWithoutNextActivityInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutRelatedActivitiesInput>;
  promoCodes?: InputMaybe<PromoCodeCreateNestedManyWithoutActivitiesInput>;
  publishedDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  questionXps?: InputMaybe<Scalars["Int"]["input"]>;
  questions?: InputMaybe<ActivityQuestionCreateNestedManyWithoutActivityInput>;
  scheduleTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  shortDescription?: InputMaybe<Scalars["String"]["input"]>;
  skill?: InputMaybe<SkillCreateNestedOneWithoutActivitiesInput>;
  slug: Scalars["String"]["input"];
  standards?: InputMaybe<StandardCreateNestedManyWithoutActivitiesInput>;
  status?: InputMaybe<ActivityStatus>;
  subType?: InputMaybe<Scalars["String"]["input"]>;
  tags?: InputMaybe<TagCreateNestedManyWithoutActivitiesInput>;
  timeRequired: Scalars["String"]["input"];
  title: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  video?: InputMaybe<Scalars["String"]["input"]>;
  xps?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ActivityCreateWithoutSkillInput = {
  approvedBy?: InputMaybe<UserCreateNestedOneWithoutActivitiesInput>;
  challengeStatement: Scalars["String"]["input"];
  coinsToSpend?: InputMaybe<Scalars["Int"]["input"]>;
  comments?: InputMaybe<CommentCreateNestedManyWithoutActivityInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  difficultyLevel: Scalars["String"]["input"];
  displayOrder?: InputMaybe<Scalars["Int"]["input"]>;
  featuredImage?: InputMaybe<Scalars["String"]["input"]>;
  image?: InputMaybe<Scalars["String"]["input"]>;
  insideWorkshops?: InputMaybe<WorkshopCreateNestedManyWithoutInsiderActivitiesInput>;
  instructionImage?: InputMaybe<Scalars["String"]["input"]>;
  instructionText?: InputMaybe<Scalars["String"]["input"]>;
  isChallenge?: InputMaybe<Scalars["Boolean"]["input"]>;
  isCommentEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  isFeatured?: InputMaybe<Scalars["Boolean"]["input"]>;
  isIndexable?: InputMaybe<Scalars["Boolean"]["input"]>;
  isLive?: InputMaybe<Scalars["Boolean"]["input"]>;
  isVisible?: InputMaybe<Scalars["Boolean"]["input"]>;
  jsonLD?: InputMaybe<Scalars["JSON"]["input"]>;
  materialsNeeded?: InputMaybe<Scalars["String"]["input"]>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  metaTagData?: InputMaybe<Scalars["JSON"]["input"]>;
  nextActivity?: InputMaybe<ActivityCreateNestedOneWithoutPreviousActivitiesInput>;
  previousActivities?: InputMaybe<ActivityCreateNestedManyWithoutNextActivityInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutRelatedActivitiesInput>;
  promoCodes?: InputMaybe<PromoCodeCreateNestedManyWithoutActivitiesInput>;
  publishedDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  questionXps?: InputMaybe<Scalars["Int"]["input"]>;
  questions?: InputMaybe<ActivityQuestionCreateNestedManyWithoutActivityInput>;
  relatedWorkshops?: InputMaybe<WorkshopCreateNestedManyWithoutRelatedActivitiesInput>;
  scheduleTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  shortDescription?: InputMaybe<Scalars["String"]["input"]>;
  slug: Scalars["String"]["input"];
  standards?: InputMaybe<StandardCreateNestedManyWithoutActivitiesInput>;
  status?: InputMaybe<ActivityStatus>;
  subType?: InputMaybe<Scalars["String"]["input"]>;
  tags?: InputMaybe<TagCreateNestedManyWithoutActivitiesInput>;
  timeRequired: Scalars["String"]["input"];
  title: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  video?: InputMaybe<Scalars["String"]["input"]>;
  xps?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ActivityCreateWithoutStandardsInput = {
  approvedBy?: InputMaybe<UserCreateNestedOneWithoutActivitiesInput>;
  challengeStatement: Scalars["String"]["input"];
  coinsToSpend?: InputMaybe<Scalars["Int"]["input"]>;
  comments?: InputMaybe<CommentCreateNestedManyWithoutActivityInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  difficultyLevel: Scalars["String"]["input"];
  displayOrder?: InputMaybe<Scalars["Int"]["input"]>;
  featuredImage?: InputMaybe<Scalars["String"]["input"]>;
  image?: InputMaybe<Scalars["String"]["input"]>;
  insideWorkshops?: InputMaybe<WorkshopCreateNestedManyWithoutInsiderActivitiesInput>;
  instructionImage?: InputMaybe<Scalars["String"]["input"]>;
  instructionText?: InputMaybe<Scalars["String"]["input"]>;
  isChallenge?: InputMaybe<Scalars["Boolean"]["input"]>;
  isCommentEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  isFeatured?: InputMaybe<Scalars["Boolean"]["input"]>;
  isIndexable?: InputMaybe<Scalars["Boolean"]["input"]>;
  isLive?: InputMaybe<Scalars["Boolean"]["input"]>;
  isVisible?: InputMaybe<Scalars["Boolean"]["input"]>;
  jsonLD?: InputMaybe<Scalars["JSON"]["input"]>;
  materialsNeeded?: InputMaybe<Scalars["String"]["input"]>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  metaTagData?: InputMaybe<Scalars["JSON"]["input"]>;
  nextActivity?: InputMaybe<ActivityCreateNestedOneWithoutPreviousActivitiesInput>;
  previousActivities?: InputMaybe<ActivityCreateNestedManyWithoutNextActivityInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutRelatedActivitiesInput>;
  promoCodes?: InputMaybe<PromoCodeCreateNestedManyWithoutActivitiesInput>;
  publishedDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  questionXps?: InputMaybe<Scalars["Int"]["input"]>;
  questions?: InputMaybe<ActivityQuestionCreateNestedManyWithoutActivityInput>;
  relatedWorkshops?: InputMaybe<WorkshopCreateNestedManyWithoutRelatedActivitiesInput>;
  scheduleTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  shortDescription?: InputMaybe<Scalars["String"]["input"]>;
  skill?: InputMaybe<SkillCreateNestedOneWithoutActivitiesInput>;
  slug: Scalars["String"]["input"];
  status?: InputMaybe<ActivityStatus>;
  subType?: InputMaybe<Scalars["String"]["input"]>;
  tags?: InputMaybe<TagCreateNestedManyWithoutActivitiesInput>;
  timeRequired: Scalars["String"]["input"];
  title: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  video?: InputMaybe<Scalars["String"]["input"]>;
  xps?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ActivityCreateWithoutTagsInput = {
  approvedBy?: InputMaybe<UserCreateNestedOneWithoutActivitiesInput>;
  challengeStatement: Scalars["String"]["input"];
  coinsToSpend?: InputMaybe<Scalars["Int"]["input"]>;
  comments?: InputMaybe<CommentCreateNestedManyWithoutActivityInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  difficultyLevel: Scalars["String"]["input"];
  displayOrder?: InputMaybe<Scalars["Int"]["input"]>;
  featuredImage?: InputMaybe<Scalars["String"]["input"]>;
  image?: InputMaybe<Scalars["String"]["input"]>;
  insideWorkshops?: InputMaybe<WorkshopCreateNestedManyWithoutInsiderActivitiesInput>;
  instructionImage?: InputMaybe<Scalars["String"]["input"]>;
  instructionText?: InputMaybe<Scalars["String"]["input"]>;
  isChallenge?: InputMaybe<Scalars["Boolean"]["input"]>;
  isCommentEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  isFeatured?: InputMaybe<Scalars["Boolean"]["input"]>;
  isIndexable?: InputMaybe<Scalars["Boolean"]["input"]>;
  isLive?: InputMaybe<Scalars["Boolean"]["input"]>;
  isVisible?: InputMaybe<Scalars["Boolean"]["input"]>;
  jsonLD?: InputMaybe<Scalars["JSON"]["input"]>;
  materialsNeeded?: InputMaybe<Scalars["String"]["input"]>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  metaTagData?: InputMaybe<Scalars["JSON"]["input"]>;
  nextActivity?: InputMaybe<ActivityCreateNestedOneWithoutPreviousActivitiesInput>;
  previousActivities?: InputMaybe<ActivityCreateNestedManyWithoutNextActivityInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutRelatedActivitiesInput>;
  promoCodes?: InputMaybe<PromoCodeCreateNestedManyWithoutActivitiesInput>;
  publishedDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  questionXps?: InputMaybe<Scalars["Int"]["input"]>;
  questions?: InputMaybe<ActivityQuestionCreateNestedManyWithoutActivityInput>;
  relatedWorkshops?: InputMaybe<WorkshopCreateNestedManyWithoutRelatedActivitiesInput>;
  scheduleTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  shortDescription?: InputMaybe<Scalars["String"]["input"]>;
  skill?: InputMaybe<SkillCreateNestedOneWithoutActivitiesInput>;
  slug: Scalars["String"]["input"];
  standards?: InputMaybe<StandardCreateNestedManyWithoutActivitiesInput>;
  status?: InputMaybe<ActivityStatus>;
  subType?: InputMaybe<Scalars["String"]["input"]>;
  timeRequired: Scalars["String"]["input"];
  title: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  video?: InputMaybe<Scalars["String"]["input"]>;
  xps?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ActivityListRelationFilter = {
  every?: InputMaybe<ActivityWhereInput>;
  none?: InputMaybe<ActivityWhereInput>;
  some?: InputMaybe<ActivityWhereInput>;
};

export type ActivityMaxAggregate = {
  __typename?: "ActivityMaxAggregate";
  approvedById?: Maybe<Scalars["Int"]["output"]>;
  challengeStatement?: Maybe<Scalars["String"]["output"]>;
  coinsToSpend?: Maybe<Scalars["Int"]["output"]>;
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  difficultyLevel?: Maybe<Scalars["String"]["output"]>;
  displayOrder?: Maybe<Scalars["Int"]["output"]>;
  featuredImage?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  image?: Maybe<Scalars["String"]["output"]>;
  instructionImage?: Maybe<Scalars["String"]["output"]>;
  instructionText?: Maybe<Scalars["String"]["output"]>;
  isChallenge?: Maybe<Scalars["Boolean"]["output"]>;
  isCommentEnabled?: Maybe<Scalars["Boolean"]["output"]>;
  isFeatured?: Maybe<Scalars["Boolean"]["output"]>;
  isIndexable?: Maybe<Scalars["Boolean"]["output"]>;
  isLive?: Maybe<Scalars["Boolean"]["output"]>;
  isVisible?: Maybe<Scalars["Boolean"]["output"]>;
  materialsNeeded?: Maybe<Scalars["String"]["output"]>;
  nextActivityId?: Maybe<Scalars["Int"]["output"]>;
  publishedDate?: Maybe<Scalars["DateTime"]["output"]>;
  questionXps?: Maybe<Scalars["Int"]["output"]>;
  scheduleTime?: Maybe<Scalars["DateTime"]["output"]>;
  shortDescription?: Maybe<Scalars["String"]["output"]>;
  skillId?: Maybe<Scalars["Int"]["output"]>;
  slug?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<ActivityStatus>;
  subType?: Maybe<Scalars["String"]["output"]>;
  timeRequired?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
  video?: Maybe<Scalars["String"]["output"]>;
  xps?: Maybe<Scalars["Int"]["output"]>;
};

export type ActivityMinAggregate = {
  __typename?: "ActivityMinAggregate";
  approvedById?: Maybe<Scalars["Int"]["output"]>;
  challengeStatement?: Maybe<Scalars["String"]["output"]>;
  coinsToSpend?: Maybe<Scalars["Int"]["output"]>;
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  difficultyLevel?: Maybe<Scalars["String"]["output"]>;
  displayOrder?: Maybe<Scalars["Int"]["output"]>;
  featuredImage?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  image?: Maybe<Scalars["String"]["output"]>;
  instructionImage?: Maybe<Scalars["String"]["output"]>;
  instructionText?: Maybe<Scalars["String"]["output"]>;
  isChallenge?: Maybe<Scalars["Boolean"]["output"]>;
  isCommentEnabled?: Maybe<Scalars["Boolean"]["output"]>;
  isFeatured?: Maybe<Scalars["Boolean"]["output"]>;
  isIndexable?: Maybe<Scalars["Boolean"]["output"]>;
  isLive?: Maybe<Scalars["Boolean"]["output"]>;
  isVisible?: Maybe<Scalars["Boolean"]["output"]>;
  materialsNeeded?: Maybe<Scalars["String"]["output"]>;
  nextActivityId?: Maybe<Scalars["Int"]["output"]>;
  publishedDate?: Maybe<Scalars["DateTime"]["output"]>;
  questionXps?: Maybe<Scalars["Int"]["output"]>;
  scheduleTime?: Maybe<Scalars["DateTime"]["output"]>;
  shortDescription?: Maybe<Scalars["String"]["output"]>;
  skillId?: Maybe<Scalars["Int"]["output"]>;
  slug?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<ActivityStatus>;
  subType?: Maybe<Scalars["String"]["output"]>;
  timeRequired?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
  video?: Maybe<Scalars["String"]["output"]>;
  xps?: Maybe<Scalars["Int"]["output"]>;
};

export type ActivityOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ActivityOrderByWithRelationInput = {
  approvedBy?: InputMaybe<UserOrderByWithRelationInput>;
  approvedById?: InputMaybe<SortOrderInput>;
  challengeStatement?: InputMaybe<SortOrder>;
  coinsToSpend?: InputMaybe<SortOrder>;
  comments?: InputMaybe<CommentOrderByRelationAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrderInput>;
  difficultyLevel?: InputMaybe<SortOrder>;
  displayOrder?: InputMaybe<SortOrder>;
  featuredImage?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  image?: InputMaybe<SortOrderInput>;
  insideWorkshops?: InputMaybe<WorkshopOrderByRelationAggregateInput>;
  instructionImage?: InputMaybe<SortOrderInput>;
  instructionText?: InputMaybe<SortOrderInput>;
  isChallenge?: InputMaybe<SortOrder>;
  isCommentEnabled?: InputMaybe<SortOrder>;
  isFeatured?: InputMaybe<SortOrder>;
  isIndexable?: InputMaybe<SortOrder>;
  isLive?: InputMaybe<SortOrder>;
  isVisible?: InputMaybe<SortOrder>;
  jsonLD?: InputMaybe<SortOrderInput>;
  materialsNeeded?: InputMaybe<SortOrderInput>;
  meta?: InputMaybe<SortOrderInput>;
  metaTagData?: InputMaybe<SortOrderInput>;
  nextActivity?: InputMaybe<ActivityOrderByWithRelationInput>;
  nextActivityId?: InputMaybe<SortOrderInput>;
  previousActivities?: InputMaybe<ActivityOrderByRelationAggregateInput>;
  products?: InputMaybe<ProductOrderByRelationAggregateInput>;
  promoCodes?: InputMaybe<PromoCodeOrderByRelationAggregateInput>;
  publishedDate?: InputMaybe<SortOrderInput>;
  questionXps?: InputMaybe<SortOrder>;
  questions?: InputMaybe<ActivityQuestionOrderByRelationAggregateInput>;
  relatedWorkshops?: InputMaybe<WorkshopOrderByRelationAggregateInput>;
  scheduleTime?: InputMaybe<SortOrderInput>;
  shortDescription?: InputMaybe<SortOrderInput>;
  skill?: InputMaybe<SkillOrderByWithRelationInput>;
  skillId?: InputMaybe<SortOrderInput>;
  slug?: InputMaybe<SortOrder>;
  standards?: InputMaybe<StandardOrderByRelationAggregateInput>;
  status?: InputMaybe<SortOrder>;
  subType?: InputMaybe<SortOrderInput>;
  tags?: InputMaybe<TagOrderByRelationAggregateInput>;
  timeRequired?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  video?: InputMaybe<SortOrderInput>;
  xps?: InputMaybe<SortOrder>;
};

export type ActivityQuestion = {
  __typename?: "ActivityQuestion";
  activityId?: Maybe<Scalars["Int"]["output"]>;
  answerText?: Maybe<Scalars["String"]["output"]>;
  answers: Array<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["Int"]["output"];
  incorrectText?: Maybe<Scalars["String"]["output"]>;
  options?: Maybe<Scalars["JSON"]["output"]>;
  order: Scalars["Int"]["output"];
  questionImage?: Maybe<Scalars["String"]["output"]>;
  questionText: Scalars["String"]["output"];
  storyActivityId?: Maybe<Scalars["Int"]["output"]>;
  type: Scalars["String"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export type ActivityQuestionCreateManyActivityInput = {
  answerText?: InputMaybe<Scalars["String"]["input"]>;
  answers?: InputMaybe<ActivityQuestionCreateanswersInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  incorrectText?: InputMaybe<Scalars["String"]["input"]>;
  options?: InputMaybe<Scalars["JSON"]["input"]>;
  order?: InputMaybe<Scalars["Int"]["input"]>;
  questionImage?: InputMaybe<Scalars["String"]["input"]>;
  questionText: Scalars["String"]["input"];
  storyActivityId?: InputMaybe<Scalars["Int"]["input"]>;
  type: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ActivityQuestionCreateManyActivityInputEnvelope = {
  data: Array<ActivityQuestionCreateManyActivityInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ActivityQuestionCreateManyInput = {
  activityId?: InputMaybe<Scalars["Int"]["input"]>;
  answerText?: InputMaybe<Scalars["String"]["input"]>;
  answers?: InputMaybe<ActivityQuestionCreateanswersInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  incorrectText?: InputMaybe<Scalars["String"]["input"]>;
  options?: InputMaybe<Scalars["JSON"]["input"]>;
  order?: InputMaybe<Scalars["Int"]["input"]>;
  questionImage?: InputMaybe<Scalars["String"]["input"]>;
  questionText: Scalars["String"]["input"];
  storyActivityId?: InputMaybe<Scalars["Int"]["input"]>;
  type: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ActivityQuestionCreateManyStoryActivityInput = {
  activityId?: InputMaybe<Scalars["Int"]["input"]>;
  answerText?: InputMaybe<Scalars["String"]["input"]>;
  answers?: InputMaybe<ActivityQuestionCreateanswersInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  incorrectText?: InputMaybe<Scalars["String"]["input"]>;
  options?: InputMaybe<Scalars["JSON"]["input"]>;
  order?: InputMaybe<Scalars["Int"]["input"]>;
  questionImage?: InputMaybe<Scalars["String"]["input"]>;
  questionText: Scalars["String"]["input"];
  type: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ActivityQuestionCreateManyStoryActivityInputEnvelope = {
  data: Array<ActivityQuestionCreateManyStoryActivityInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ActivityQuestionCreateNestedManyWithoutActivityInput = {
  connect?: InputMaybe<Array<ActivityQuestionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ActivityQuestionCreateOrConnectWithoutActivityInput>>;
  create?: InputMaybe<Array<ActivityQuestionCreateWithoutActivityInput>>;
  createMany?: InputMaybe<ActivityQuestionCreateManyActivityInputEnvelope>;
};

export type ActivityQuestionCreateNestedManyWithoutStoryActivityInput = {
  connect?: InputMaybe<Array<ActivityQuestionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ActivityQuestionCreateOrConnectWithoutStoryActivityInput>>;
  create?: InputMaybe<Array<ActivityQuestionCreateWithoutStoryActivityInput>>;
  createMany?: InputMaybe<ActivityQuestionCreateManyStoryActivityInputEnvelope>;
};

export type ActivityQuestionCreateOrConnectWithoutActivityInput = {
  create: ActivityQuestionCreateWithoutActivityInput;
  where: ActivityQuestionWhereUniqueInput;
};

export type ActivityQuestionCreateOrConnectWithoutStoryActivityInput = {
  create: ActivityQuestionCreateWithoutStoryActivityInput;
  where: ActivityQuestionWhereUniqueInput;
};

export type ActivityQuestionCreateWithoutActivityInput = {
  answerText?: InputMaybe<Scalars["String"]["input"]>;
  answers?: InputMaybe<ActivityQuestionCreateanswersInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  incorrectText?: InputMaybe<Scalars["String"]["input"]>;
  options?: InputMaybe<Scalars["JSON"]["input"]>;
  order?: InputMaybe<Scalars["Int"]["input"]>;
  questionImage?: InputMaybe<Scalars["String"]["input"]>;
  questionText: Scalars["String"]["input"];
  storyActivity?: InputMaybe<StoryActivityCreateNestedOneWithoutQuestionsInput>;
  type: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ActivityQuestionCreateWithoutStoryActivityInput = {
  activity?: InputMaybe<ActivityCreateNestedOneWithoutQuestionsInput>;
  answerText?: InputMaybe<Scalars["String"]["input"]>;
  answers?: InputMaybe<ActivityQuestionCreateanswersInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  incorrectText?: InputMaybe<Scalars["String"]["input"]>;
  options?: InputMaybe<Scalars["JSON"]["input"]>;
  order?: InputMaybe<Scalars["Int"]["input"]>;
  questionImage?: InputMaybe<Scalars["String"]["input"]>;
  questionText: Scalars["String"]["input"];
  type: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ActivityQuestionCreateanswersInput = {
  set: Array<Scalars["String"]["input"]>;
};

export type ActivityQuestionListRelationFilter = {
  every?: InputMaybe<ActivityQuestionWhereInput>;
  none?: InputMaybe<ActivityQuestionWhereInput>;
  some?: InputMaybe<ActivityQuestionWhereInput>;
};

export type ActivityQuestionOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ActivityQuestionOrderByWithRelationInput = {
  activity?: InputMaybe<ActivityOrderByWithRelationInput>;
  activityId?: InputMaybe<SortOrderInput>;
  answerText?: InputMaybe<SortOrderInput>;
  answers?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  incorrectText?: InputMaybe<SortOrderInput>;
  options?: InputMaybe<SortOrderInput>;
  order?: InputMaybe<SortOrder>;
  questionImage?: InputMaybe<SortOrderInput>;
  questionText?: InputMaybe<SortOrder>;
  storyActivity?: InputMaybe<StoryActivityOrderByWithRelationInput>;
  storyActivityId?: InputMaybe<SortOrderInput>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum ActivityQuestionScalarFieldEnum {
  ACTIVITYID = "activityId",
  ANSWERTEXT = "answerText",
  ANSWERS = "answers",
  CREATEDAT = "createdAt",
  ID = "id",
  INCORRECTTEXT = "incorrectText",
  OPTIONS = "options",
  ORDER = "order",
  QUESTIONIMAGE = "questionImage",
  QUESTIONTEXT = "questionText",
  STORYACTIVITYID = "storyActivityId",
  TYPE = "type",
  UPDATEDAT = "updatedAt"
}

export type ActivityQuestionScalarWhereInput = {
  AND?: InputMaybe<Array<ActivityQuestionScalarWhereInput>>;
  NOT?: InputMaybe<Array<ActivityQuestionScalarWhereInput>>;
  OR?: InputMaybe<Array<ActivityQuestionScalarWhereInput>>;
  activityId?: InputMaybe<IntNullableFilter>;
  answerText?: InputMaybe<StringNullableFilter>;
  answers?: InputMaybe<StringNullableListFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  incorrectText?: InputMaybe<StringNullableFilter>;
  options?: InputMaybe<JsonNullableFilter>;
  order?: InputMaybe<IntFilter>;
  questionImage?: InputMaybe<StringNullableFilter>;
  questionText?: InputMaybe<StringFilter>;
  storyActivityId?: InputMaybe<IntNullableFilter>;
  type?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ActivityQuestionUpdateInput = {
  activity?: InputMaybe<ActivityUpdateOneWithoutQuestionsNestedInput>;
  answerText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  answers?: InputMaybe<ActivityQuestionUpdateanswersInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  incorrectText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  options?: InputMaybe<Scalars["JSON"]["input"]>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  questionImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  questionText?: InputMaybe<StringFieldUpdateOperationsInput>;
  storyActivity?: InputMaybe<StoryActivityUpdateOneWithoutQuestionsNestedInput>;
  type?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ActivityQuestionUpdateManyMutationInput = {
  answerText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  answers?: InputMaybe<ActivityQuestionUpdateanswersInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  incorrectText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  options?: InputMaybe<Scalars["JSON"]["input"]>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  questionImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  questionText?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ActivityQuestionUpdateManyWithWhereWithoutActivityInput = {
  data: ActivityQuestionUpdateManyMutationInput;
  where: ActivityQuestionScalarWhereInput;
};

export type ActivityQuestionUpdateManyWithWhereWithoutStoryActivityInput = {
  data: ActivityQuestionUpdateManyMutationInput;
  where: ActivityQuestionScalarWhereInput;
};

export type ActivityQuestionUpdateManyWithoutActivityNestedInput = {
  connect?: InputMaybe<Array<ActivityQuestionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ActivityQuestionCreateOrConnectWithoutActivityInput>>;
  create?: InputMaybe<Array<ActivityQuestionCreateWithoutActivityInput>>;
  createMany?: InputMaybe<ActivityQuestionCreateManyActivityInputEnvelope>;
  delete?: InputMaybe<Array<ActivityQuestionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ActivityQuestionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ActivityQuestionWhereUniqueInput>>;
  set?: InputMaybe<Array<ActivityQuestionWhereUniqueInput>>;
  update?: InputMaybe<Array<ActivityQuestionUpdateWithWhereUniqueWithoutActivityInput>>;
  updateMany?: InputMaybe<Array<ActivityQuestionUpdateManyWithWhereWithoutActivityInput>>;
  upsert?: InputMaybe<Array<ActivityQuestionUpsertWithWhereUniqueWithoutActivityInput>>;
};

export type ActivityQuestionUpdateManyWithoutStoryActivityNestedInput = {
  connect?: InputMaybe<Array<ActivityQuestionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ActivityQuestionCreateOrConnectWithoutStoryActivityInput>>;
  create?: InputMaybe<Array<ActivityQuestionCreateWithoutStoryActivityInput>>;
  createMany?: InputMaybe<ActivityQuestionCreateManyStoryActivityInputEnvelope>;
  delete?: InputMaybe<Array<ActivityQuestionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ActivityQuestionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ActivityQuestionWhereUniqueInput>>;
  set?: InputMaybe<Array<ActivityQuestionWhereUniqueInput>>;
  update?: InputMaybe<Array<ActivityQuestionUpdateWithWhereUniqueWithoutStoryActivityInput>>;
  updateMany?: InputMaybe<Array<ActivityQuestionUpdateManyWithWhereWithoutStoryActivityInput>>;
  upsert?: InputMaybe<Array<ActivityQuestionUpsertWithWhereUniqueWithoutStoryActivityInput>>;
};

export type ActivityQuestionUpdateWithWhereUniqueWithoutActivityInput = {
  data: ActivityQuestionUpdateWithoutActivityInput;
  where: ActivityQuestionWhereUniqueInput;
};

export type ActivityQuestionUpdateWithWhereUniqueWithoutStoryActivityInput = {
  data: ActivityQuestionUpdateWithoutStoryActivityInput;
  where: ActivityQuestionWhereUniqueInput;
};

export type ActivityQuestionUpdateWithoutActivityInput = {
  answerText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  answers?: InputMaybe<ActivityQuestionUpdateanswersInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  incorrectText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  options?: InputMaybe<Scalars["JSON"]["input"]>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  questionImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  questionText?: InputMaybe<StringFieldUpdateOperationsInput>;
  storyActivity?: InputMaybe<StoryActivityUpdateOneWithoutQuestionsNestedInput>;
  type?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ActivityQuestionUpdateWithoutStoryActivityInput = {
  activity?: InputMaybe<ActivityUpdateOneWithoutQuestionsNestedInput>;
  answerText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  answers?: InputMaybe<ActivityQuestionUpdateanswersInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  incorrectText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  options?: InputMaybe<Scalars["JSON"]["input"]>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  questionImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  questionText?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ActivityQuestionUpdateanswersInput = {
  push?: InputMaybe<Array<Scalars["String"]["input"]>>;
  set?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

export type ActivityQuestionUpsertWithWhereUniqueWithoutActivityInput = {
  create: ActivityQuestionCreateWithoutActivityInput;
  update: ActivityQuestionUpdateWithoutActivityInput;
  where: ActivityQuestionWhereUniqueInput;
};

export type ActivityQuestionUpsertWithWhereUniqueWithoutStoryActivityInput = {
  create: ActivityQuestionCreateWithoutStoryActivityInput;
  update: ActivityQuestionUpdateWithoutStoryActivityInput;
  where: ActivityQuestionWhereUniqueInput;
};

export type ActivityQuestionWhereInput = {
  AND?: InputMaybe<Array<ActivityQuestionWhereInput>>;
  NOT?: InputMaybe<Array<ActivityQuestionWhereInput>>;
  OR?: InputMaybe<Array<ActivityQuestionWhereInput>>;
  activity?: InputMaybe<ActivityRelationFilter>;
  activityId?: InputMaybe<IntNullableFilter>;
  answerText?: InputMaybe<StringNullableFilter>;
  answers?: InputMaybe<StringNullableListFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  incorrectText?: InputMaybe<StringNullableFilter>;
  options?: InputMaybe<JsonNullableFilter>;
  order?: InputMaybe<IntFilter>;
  questionImage?: InputMaybe<StringNullableFilter>;
  questionText?: InputMaybe<StringFilter>;
  storyActivity?: InputMaybe<StoryActivityRelationFilter>;
  storyActivityId?: InputMaybe<IntNullableFilter>;
  type?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ActivityQuestionWhereUniqueInput = {
  id?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ActivityRelationFilter = {
  is?: InputMaybe<ActivityWhereInput>;
  isNot?: InputMaybe<ActivityWhereInput>;
};

export enum ActivityScalarFieldEnum {
  APPROVEDBYID = "approvedById",
  CHALLENGESTATEMENT = "challengeStatement",
  COINSTOSPEND = "coinsToSpend",
  CREATEDAT = "createdAt",
  DESCRIPTION = "description",
  DIFFICULTYLEVEL = "difficultyLevel",
  DISPLAYORDER = "displayOrder",
  FEATUREDIMAGE = "featuredImage",
  ID = "id",
  IMAGE = "image",
  INSTRUCTIONIMAGE = "instructionImage",
  INSTRUCTIONTEXT = "instructionText",
  ISCHALLENGE = "isChallenge",
  ISCOMMENTENABLED = "isCommentEnabled",
  ISFEATURED = "isFeatured",
  ISINDEXABLE = "isIndexable",
  ISLIVE = "isLive",
  ISVISIBLE = "isVisible",
  JSONLD = "jsonLD",
  MATERIALSNEEDED = "materialsNeeded",
  META = "meta",
  METATAGDATA = "metaTagData",
  NEXTACTIVITYID = "nextActivityId",
  PUBLISHEDDATE = "publishedDate",
  QUESTIONXPS = "questionXps",
  SCHEDULETIME = "scheduleTime",
  SHORTDESCRIPTION = "shortDescription",
  SKILLID = "skillId",
  SLUG = "slug",
  STATUS = "status",
  SUBTYPE = "subType",
  TIMEREQUIRED = "timeRequired",
  TITLE = "title",
  UPDATEDAT = "updatedAt",
  VIDEO = "video",
  XPS = "xps"
}

export type ActivityScalarWhereInput = {
  AND?: InputMaybe<Array<ActivityScalarWhereInput>>;
  NOT?: InputMaybe<Array<ActivityScalarWhereInput>>;
  OR?: InputMaybe<Array<ActivityScalarWhereInput>>;
  approvedById?: InputMaybe<IntNullableFilter>;
  challengeStatement?: InputMaybe<StringFilter>;
  coinsToSpend?: InputMaybe<IntFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  difficultyLevel?: InputMaybe<StringFilter>;
  displayOrder?: InputMaybe<IntFilter>;
  featuredImage?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  image?: InputMaybe<StringNullableFilter>;
  instructionImage?: InputMaybe<StringNullableFilter>;
  instructionText?: InputMaybe<StringNullableFilter>;
  isChallenge?: InputMaybe<BoolFilter>;
  isCommentEnabled?: InputMaybe<BoolFilter>;
  isFeatured?: InputMaybe<BoolFilter>;
  isIndexable?: InputMaybe<BoolFilter>;
  isLive?: InputMaybe<BoolFilter>;
  isVisible?: InputMaybe<BoolFilter>;
  jsonLD?: InputMaybe<JsonNullableFilter>;
  materialsNeeded?: InputMaybe<StringNullableFilter>;
  meta?: InputMaybe<JsonNullableFilter>;
  metaTagData?: InputMaybe<JsonNullableFilter>;
  nextActivityId?: InputMaybe<IntNullableFilter>;
  publishedDate?: InputMaybe<DateTimeNullableFilter>;
  questionXps?: InputMaybe<IntFilter>;
  scheduleTime?: InputMaybe<DateTimeNullableFilter>;
  shortDescription?: InputMaybe<StringNullableFilter>;
  skillId?: InputMaybe<IntNullableFilter>;
  slug?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumActivityStatusFilter>;
  subType?: InputMaybe<StringNullableFilter>;
  timeRequired?: InputMaybe<StringFilter>;
  title?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  video?: InputMaybe<StringNullableFilter>;
  xps?: InputMaybe<IntFilter>;
};

export enum ActivityStatus {
  DELETED = "DELETED",
  DRAFT = "DRAFT",
  PENDING_REVIEW = "PENDING_REVIEW",
  PUBLISHED = "PUBLISHED",
  SCHEDULED = "SCHEDULED",
  UNPUBLISHED = "UNPUBLISHED"
}

export type ActivitySumAggregate = {
  __typename?: "ActivitySumAggregate";
  approvedById?: Maybe<Scalars["Int"]["output"]>;
  coinsToSpend?: Maybe<Scalars["Int"]["output"]>;
  displayOrder?: Maybe<Scalars["Int"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  nextActivityId?: Maybe<Scalars["Int"]["output"]>;
  questionXps?: Maybe<Scalars["Int"]["output"]>;
  skillId?: Maybe<Scalars["Int"]["output"]>;
  xps?: Maybe<Scalars["Int"]["output"]>;
};

/** Activity Type Enum */
export enum ActivityType {
  ACTIVITY = "ACTIVITY",
  STORY_ACTIVITY = "STORY_ACTIVITY"
}

export type ActivityUpdateInput = {
  approvedBy?: InputMaybe<UserUpdateOneWithoutActivitiesNestedInput>;
  challengeStatement?: InputMaybe<StringFieldUpdateOperationsInput>;
  coinsToSpend?: InputMaybe<IntFieldUpdateOperationsInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutActivityNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  difficultyLevel?: InputMaybe<StringFieldUpdateOperationsInput>;
  displayOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  insideWorkshops?: InputMaybe<WorkshopUpdateManyWithoutInsiderActivitiesNestedInput>;
  instructionImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  instructionText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isChallenge?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isCommentEnabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isFeatured?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isIndexable?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isLive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isVisible?: InputMaybe<BoolFieldUpdateOperationsInput>;
  jsonLD?: InputMaybe<Scalars["JSON"]["input"]>;
  materialsNeeded?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  metaTagData?: InputMaybe<Scalars["JSON"]["input"]>;
  nextActivity?: InputMaybe<ActivityUpdateOneWithoutPreviousActivitiesNestedInput>;
  previousActivities?: InputMaybe<ActivityUpdateManyWithoutNextActivityNestedInput>;
  products?: InputMaybe<ProductUpdateManyWithoutRelatedActivitiesNestedInput>;
  promoCodes?: InputMaybe<PromoCodeUpdateManyWithoutActivitiesNestedInput>;
  publishedDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  questionXps?: InputMaybe<IntFieldUpdateOperationsInput>;
  questions?: InputMaybe<ActivityQuestionUpdateManyWithoutActivityNestedInput>;
  relatedWorkshops?: InputMaybe<WorkshopUpdateManyWithoutRelatedActivitiesNestedInput>;
  scheduleTime?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  shortDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  skill?: InputMaybe<SkillUpdateOneWithoutActivitiesNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  standards?: InputMaybe<StandardUpdateManyWithoutActivitiesNestedInput>;
  status?: InputMaybe<EnumActivityStatusFieldUpdateOperationsInput>;
  subType?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  tags?: InputMaybe<TagUpdateManyWithoutActivitiesNestedInput>;
  timeRequired?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  video?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  xps?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type ActivityUpdateManyMutationInput = {
  challengeStatement?: InputMaybe<StringFieldUpdateOperationsInput>;
  coinsToSpend?: InputMaybe<IntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  difficultyLevel?: InputMaybe<StringFieldUpdateOperationsInput>;
  displayOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  instructionImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  instructionText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isChallenge?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isCommentEnabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isFeatured?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isIndexable?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isLive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isVisible?: InputMaybe<BoolFieldUpdateOperationsInput>;
  jsonLD?: InputMaybe<Scalars["JSON"]["input"]>;
  materialsNeeded?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  metaTagData?: InputMaybe<Scalars["JSON"]["input"]>;
  publishedDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  questionXps?: InputMaybe<IntFieldUpdateOperationsInput>;
  scheduleTime?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  shortDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumActivityStatusFieldUpdateOperationsInput>;
  subType?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timeRequired?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  video?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  xps?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type ActivityUpdateManyWithWhereWithoutApprovedByInput = {
  data: ActivityUpdateManyMutationInput;
  where: ActivityScalarWhereInput;
};

export type ActivityUpdateManyWithWhereWithoutInsideWorkshopsInput = {
  data: ActivityUpdateManyMutationInput;
  where: ActivityScalarWhereInput;
};

export type ActivityUpdateManyWithWhereWithoutNextActivityInput = {
  data: ActivityUpdateManyMutationInput;
  where: ActivityScalarWhereInput;
};

export type ActivityUpdateManyWithWhereWithoutProductsInput = {
  data: ActivityUpdateManyMutationInput;
  where: ActivityScalarWhereInput;
};

export type ActivityUpdateManyWithWhereWithoutPromoCodesInput = {
  data: ActivityUpdateManyMutationInput;
  where: ActivityScalarWhereInput;
};

export type ActivityUpdateManyWithWhereWithoutRelatedWorkshopsInput = {
  data: ActivityUpdateManyMutationInput;
  where: ActivityScalarWhereInput;
};

export type ActivityUpdateManyWithWhereWithoutSkillInput = {
  data: ActivityUpdateManyMutationInput;
  where: ActivityScalarWhereInput;
};

export type ActivityUpdateManyWithWhereWithoutStandardsInput = {
  data: ActivityUpdateManyMutationInput;
  where: ActivityScalarWhereInput;
};

export type ActivityUpdateManyWithWhereWithoutTagsInput = {
  data: ActivityUpdateManyMutationInput;
  where: ActivityScalarWhereInput;
};

export type ActivityUpdateManyWithoutApprovedByNestedInput = {
  connect?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ActivityCreateOrConnectWithoutApprovedByInput>>;
  create?: InputMaybe<Array<ActivityCreateWithoutApprovedByInput>>;
  createMany?: InputMaybe<ActivityCreateManyApprovedByInputEnvelope>;
  delete?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ActivityScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  set?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  update?: InputMaybe<Array<ActivityUpdateWithWhereUniqueWithoutApprovedByInput>>;
  updateMany?: InputMaybe<Array<ActivityUpdateManyWithWhereWithoutApprovedByInput>>;
  upsert?: InputMaybe<Array<ActivityUpsertWithWhereUniqueWithoutApprovedByInput>>;
};

export type ActivityUpdateManyWithoutInsideWorkshopsNestedInput = {
  connect?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ActivityCreateOrConnectWithoutInsideWorkshopsInput>>;
  create?: InputMaybe<Array<ActivityCreateWithoutInsideWorkshopsInput>>;
  delete?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ActivityScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  set?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  update?: InputMaybe<Array<ActivityUpdateWithWhereUniqueWithoutInsideWorkshopsInput>>;
  updateMany?: InputMaybe<Array<ActivityUpdateManyWithWhereWithoutInsideWorkshopsInput>>;
  upsert?: InputMaybe<Array<ActivityUpsertWithWhereUniqueWithoutInsideWorkshopsInput>>;
};

export type ActivityUpdateManyWithoutNextActivityNestedInput = {
  connect?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ActivityCreateOrConnectWithoutNextActivityInput>>;
  create?: InputMaybe<Array<ActivityCreateWithoutNextActivityInput>>;
  createMany?: InputMaybe<ActivityCreateManyNextActivityInputEnvelope>;
  delete?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ActivityScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  set?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  update?: InputMaybe<Array<ActivityUpdateWithWhereUniqueWithoutNextActivityInput>>;
  updateMany?: InputMaybe<Array<ActivityUpdateManyWithWhereWithoutNextActivityInput>>;
  upsert?: InputMaybe<Array<ActivityUpsertWithWhereUniqueWithoutNextActivityInput>>;
};

export type ActivityUpdateManyWithoutProductsNestedInput = {
  connect?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ActivityCreateOrConnectWithoutProductsInput>>;
  create?: InputMaybe<Array<ActivityCreateWithoutProductsInput>>;
  delete?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ActivityScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  set?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  update?: InputMaybe<Array<ActivityUpdateWithWhereUniqueWithoutProductsInput>>;
  updateMany?: InputMaybe<Array<ActivityUpdateManyWithWhereWithoutProductsInput>>;
  upsert?: InputMaybe<Array<ActivityUpsertWithWhereUniqueWithoutProductsInput>>;
};

export type ActivityUpdateManyWithoutPromoCodesNestedInput = {
  connect?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ActivityCreateOrConnectWithoutPromoCodesInput>>;
  create?: InputMaybe<Array<ActivityCreateWithoutPromoCodesInput>>;
  delete?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ActivityScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  set?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  update?: InputMaybe<Array<ActivityUpdateWithWhereUniqueWithoutPromoCodesInput>>;
  updateMany?: InputMaybe<Array<ActivityUpdateManyWithWhereWithoutPromoCodesInput>>;
  upsert?: InputMaybe<Array<ActivityUpsertWithWhereUniqueWithoutPromoCodesInput>>;
};

export type ActivityUpdateManyWithoutRelatedWorkshopsNestedInput = {
  connect?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ActivityCreateOrConnectWithoutRelatedWorkshopsInput>>;
  create?: InputMaybe<Array<ActivityCreateWithoutRelatedWorkshopsInput>>;
  delete?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ActivityScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  set?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  update?: InputMaybe<Array<ActivityUpdateWithWhereUniqueWithoutRelatedWorkshopsInput>>;
  updateMany?: InputMaybe<Array<ActivityUpdateManyWithWhereWithoutRelatedWorkshopsInput>>;
  upsert?: InputMaybe<Array<ActivityUpsertWithWhereUniqueWithoutRelatedWorkshopsInput>>;
};

export type ActivityUpdateManyWithoutSkillNestedInput = {
  connect?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ActivityCreateOrConnectWithoutSkillInput>>;
  create?: InputMaybe<Array<ActivityCreateWithoutSkillInput>>;
  createMany?: InputMaybe<ActivityCreateManySkillInputEnvelope>;
  delete?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ActivityScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  set?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  update?: InputMaybe<Array<ActivityUpdateWithWhereUniqueWithoutSkillInput>>;
  updateMany?: InputMaybe<Array<ActivityUpdateManyWithWhereWithoutSkillInput>>;
  upsert?: InputMaybe<Array<ActivityUpsertWithWhereUniqueWithoutSkillInput>>;
};

export type ActivityUpdateManyWithoutStandardsNestedInput = {
  connect?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ActivityCreateOrConnectWithoutStandardsInput>>;
  create?: InputMaybe<Array<ActivityCreateWithoutStandardsInput>>;
  delete?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ActivityScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  set?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  update?: InputMaybe<Array<ActivityUpdateWithWhereUniqueWithoutStandardsInput>>;
  updateMany?: InputMaybe<Array<ActivityUpdateManyWithWhereWithoutStandardsInput>>;
  upsert?: InputMaybe<Array<ActivityUpsertWithWhereUniqueWithoutStandardsInput>>;
};

export type ActivityUpdateManyWithoutTagsNestedInput = {
  connect?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ActivityCreateOrConnectWithoutTagsInput>>;
  create?: InputMaybe<Array<ActivityCreateWithoutTagsInput>>;
  delete?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ActivityScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  set?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  update?: InputMaybe<Array<ActivityUpdateWithWhereUniqueWithoutTagsInput>>;
  updateMany?: InputMaybe<Array<ActivityUpdateManyWithWhereWithoutTagsInput>>;
  upsert?: InputMaybe<Array<ActivityUpsertWithWhereUniqueWithoutTagsInput>>;
};

export type ActivityUpdateOneWithoutCommentsNestedInput = {
  connect?: InputMaybe<ActivityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ActivityCreateOrConnectWithoutCommentsInput>;
  create?: InputMaybe<ActivityCreateWithoutCommentsInput>;
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  update?: InputMaybe<ActivityUpdateWithoutCommentsInput>;
  upsert?: InputMaybe<ActivityUpsertWithoutCommentsInput>;
};

export type ActivityUpdateOneWithoutPreviousActivitiesNestedInput = {
  connect?: InputMaybe<ActivityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ActivityCreateOrConnectWithoutPreviousActivitiesInput>;
  create?: InputMaybe<ActivityCreateWithoutPreviousActivitiesInput>;
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  update?: InputMaybe<ActivityUpdateWithoutPreviousActivitiesInput>;
  upsert?: InputMaybe<ActivityUpsertWithoutPreviousActivitiesInput>;
};

export type ActivityUpdateOneWithoutQuestionsNestedInput = {
  connect?: InputMaybe<ActivityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ActivityCreateOrConnectWithoutQuestionsInput>;
  create?: InputMaybe<ActivityCreateWithoutQuestionsInput>;
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  update?: InputMaybe<ActivityUpdateWithoutQuestionsInput>;
  upsert?: InputMaybe<ActivityUpsertWithoutQuestionsInput>;
};

export type ActivityUpdateWithWhereUniqueWithoutApprovedByInput = {
  data: ActivityUpdateWithoutApprovedByInput;
  where: ActivityWhereUniqueInput;
};

export type ActivityUpdateWithWhereUniqueWithoutInsideWorkshopsInput = {
  data: ActivityUpdateWithoutInsideWorkshopsInput;
  where: ActivityWhereUniqueInput;
};

export type ActivityUpdateWithWhereUniqueWithoutNextActivityInput = {
  data: ActivityUpdateWithoutNextActivityInput;
  where: ActivityWhereUniqueInput;
};

export type ActivityUpdateWithWhereUniqueWithoutProductsInput = {
  data: ActivityUpdateWithoutProductsInput;
  where: ActivityWhereUniqueInput;
};

export type ActivityUpdateWithWhereUniqueWithoutPromoCodesInput = {
  data: ActivityUpdateWithoutPromoCodesInput;
  where: ActivityWhereUniqueInput;
};

export type ActivityUpdateWithWhereUniqueWithoutRelatedWorkshopsInput = {
  data: ActivityUpdateWithoutRelatedWorkshopsInput;
  where: ActivityWhereUniqueInput;
};

export type ActivityUpdateWithWhereUniqueWithoutSkillInput = {
  data: ActivityUpdateWithoutSkillInput;
  where: ActivityWhereUniqueInput;
};

export type ActivityUpdateWithWhereUniqueWithoutStandardsInput = {
  data: ActivityUpdateWithoutStandardsInput;
  where: ActivityWhereUniqueInput;
};

export type ActivityUpdateWithWhereUniqueWithoutTagsInput = {
  data: ActivityUpdateWithoutTagsInput;
  where: ActivityWhereUniqueInput;
};

export type ActivityUpdateWithoutApprovedByInput = {
  challengeStatement?: InputMaybe<StringFieldUpdateOperationsInput>;
  coinsToSpend?: InputMaybe<IntFieldUpdateOperationsInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutActivityNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  difficultyLevel?: InputMaybe<StringFieldUpdateOperationsInput>;
  displayOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  insideWorkshops?: InputMaybe<WorkshopUpdateManyWithoutInsiderActivitiesNestedInput>;
  instructionImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  instructionText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isChallenge?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isCommentEnabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isFeatured?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isIndexable?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isLive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isVisible?: InputMaybe<BoolFieldUpdateOperationsInput>;
  jsonLD?: InputMaybe<Scalars["JSON"]["input"]>;
  materialsNeeded?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  metaTagData?: InputMaybe<Scalars["JSON"]["input"]>;
  nextActivity?: InputMaybe<ActivityUpdateOneWithoutPreviousActivitiesNestedInput>;
  previousActivities?: InputMaybe<ActivityUpdateManyWithoutNextActivityNestedInput>;
  products?: InputMaybe<ProductUpdateManyWithoutRelatedActivitiesNestedInput>;
  promoCodes?: InputMaybe<PromoCodeUpdateManyWithoutActivitiesNestedInput>;
  publishedDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  questionXps?: InputMaybe<IntFieldUpdateOperationsInput>;
  questions?: InputMaybe<ActivityQuestionUpdateManyWithoutActivityNestedInput>;
  relatedWorkshops?: InputMaybe<WorkshopUpdateManyWithoutRelatedActivitiesNestedInput>;
  scheduleTime?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  shortDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  skill?: InputMaybe<SkillUpdateOneWithoutActivitiesNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  standards?: InputMaybe<StandardUpdateManyWithoutActivitiesNestedInput>;
  status?: InputMaybe<EnumActivityStatusFieldUpdateOperationsInput>;
  subType?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  tags?: InputMaybe<TagUpdateManyWithoutActivitiesNestedInput>;
  timeRequired?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  video?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  xps?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type ActivityUpdateWithoutCommentsInput = {
  approvedBy?: InputMaybe<UserUpdateOneWithoutActivitiesNestedInput>;
  challengeStatement?: InputMaybe<StringFieldUpdateOperationsInput>;
  coinsToSpend?: InputMaybe<IntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  difficultyLevel?: InputMaybe<StringFieldUpdateOperationsInput>;
  displayOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  insideWorkshops?: InputMaybe<WorkshopUpdateManyWithoutInsiderActivitiesNestedInput>;
  instructionImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  instructionText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isChallenge?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isCommentEnabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isFeatured?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isIndexable?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isLive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isVisible?: InputMaybe<BoolFieldUpdateOperationsInput>;
  jsonLD?: InputMaybe<Scalars["JSON"]["input"]>;
  materialsNeeded?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  metaTagData?: InputMaybe<Scalars["JSON"]["input"]>;
  nextActivity?: InputMaybe<ActivityUpdateOneWithoutPreviousActivitiesNestedInput>;
  previousActivities?: InputMaybe<ActivityUpdateManyWithoutNextActivityNestedInput>;
  products?: InputMaybe<ProductUpdateManyWithoutRelatedActivitiesNestedInput>;
  promoCodes?: InputMaybe<PromoCodeUpdateManyWithoutActivitiesNestedInput>;
  publishedDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  questionXps?: InputMaybe<IntFieldUpdateOperationsInput>;
  questions?: InputMaybe<ActivityQuestionUpdateManyWithoutActivityNestedInput>;
  relatedWorkshops?: InputMaybe<WorkshopUpdateManyWithoutRelatedActivitiesNestedInput>;
  scheduleTime?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  shortDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  skill?: InputMaybe<SkillUpdateOneWithoutActivitiesNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  standards?: InputMaybe<StandardUpdateManyWithoutActivitiesNestedInput>;
  status?: InputMaybe<EnumActivityStatusFieldUpdateOperationsInput>;
  subType?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  tags?: InputMaybe<TagUpdateManyWithoutActivitiesNestedInput>;
  timeRequired?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  video?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  xps?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type ActivityUpdateWithoutInsideWorkshopsInput = {
  approvedBy?: InputMaybe<UserUpdateOneWithoutActivitiesNestedInput>;
  challengeStatement?: InputMaybe<StringFieldUpdateOperationsInput>;
  coinsToSpend?: InputMaybe<IntFieldUpdateOperationsInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutActivityNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  difficultyLevel?: InputMaybe<StringFieldUpdateOperationsInput>;
  displayOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  instructionImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  instructionText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isChallenge?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isCommentEnabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isFeatured?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isIndexable?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isLive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isVisible?: InputMaybe<BoolFieldUpdateOperationsInput>;
  jsonLD?: InputMaybe<Scalars["JSON"]["input"]>;
  materialsNeeded?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  metaTagData?: InputMaybe<Scalars["JSON"]["input"]>;
  nextActivity?: InputMaybe<ActivityUpdateOneWithoutPreviousActivitiesNestedInput>;
  previousActivities?: InputMaybe<ActivityUpdateManyWithoutNextActivityNestedInput>;
  products?: InputMaybe<ProductUpdateManyWithoutRelatedActivitiesNestedInput>;
  promoCodes?: InputMaybe<PromoCodeUpdateManyWithoutActivitiesNestedInput>;
  publishedDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  questionXps?: InputMaybe<IntFieldUpdateOperationsInput>;
  questions?: InputMaybe<ActivityQuestionUpdateManyWithoutActivityNestedInput>;
  relatedWorkshops?: InputMaybe<WorkshopUpdateManyWithoutRelatedActivitiesNestedInput>;
  scheduleTime?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  shortDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  skill?: InputMaybe<SkillUpdateOneWithoutActivitiesNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  standards?: InputMaybe<StandardUpdateManyWithoutActivitiesNestedInput>;
  status?: InputMaybe<EnumActivityStatusFieldUpdateOperationsInput>;
  subType?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  tags?: InputMaybe<TagUpdateManyWithoutActivitiesNestedInput>;
  timeRequired?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  video?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  xps?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type ActivityUpdateWithoutNextActivityInput = {
  approvedBy?: InputMaybe<UserUpdateOneWithoutActivitiesNestedInput>;
  challengeStatement?: InputMaybe<StringFieldUpdateOperationsInput>;
  coinsToSpend?: InputMaybe<IntFieldUpdateOperationsInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutActivityNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  difficultyLevel?: InputMaybe<StringFieldUpdateOperationsInput>;
  displayOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  insideWorkshops?: InputMaybe<WorkshopUpdateManyWithoutInsiderActivitiesNestedInput>;
  instructionImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  instructionText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isChallenge?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isCommentEnabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isFeatured?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isIndexable?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isLive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isVisible?: InputMaybe<BoolFieldUpdateOperationsInput>;
  jsonLD?: InputMaybe<Scalars["JSON"]["input"]>;
  materialsNeeded?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  metaTagData?: InputMaybe<Scalars["JSON"]["input"]>;
  previousActivities?: InputMaybe<ActivityUpdateManyWithoutNextActivityNestedInput>;
  products?: InputMaybe<ProductUpdateManyWithoutRelatedActivitiesNestedInput>;
  promoCodes?: InputMaybe<PromoCodeUpdateManyWithoutActivitiesNestedInput>;
  publishedDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  questionXps?: InputMaybe<IntFieldUpdateOperationsInput>;
  questions?: InputMaybe<ActivityQuestionUpdateManyWithoutActivityNestedInput>;
  relatedWorkshops?: InputMaybe<WorkshopUpdateManyWithoutRelatedActivitiesNestedInput>;
  scheduleTime?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  shortDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  skill?: InputMaybe<SkillUpdateOneWithoutActivitiesNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  standards?: InputMaybe<StandardUpdateManyWithoutActivitiesNestedInput>;
  status?: InputMaybe<EnumActivityStatusFieldUpdateOperationsInput>;
  subType?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  tags?: InputMaybe<TagUpdateManyWithoutActivitiesNestedInput>;
  timeRequired?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  video?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  xps?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type ActivityUpdateWithoutPreviousActivitiesInput = {
  approvedBy?: InputMaybe<UserUpdateOneWithoutActivitiesNestedInput>;
  challengeStatement?: InputMaybe<StringFieldUpdateOperationsInput>;
  coinsToSpend?: InputMaybe<IntFieldUpdateOperationsInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutActivityNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  difficultyLevel?: InputMaybe<StringFieldUpdateOperationsInput>;
  displayOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  insideWorkshops?: InputMaybe<WorkshopUpdateManyWithoutInsiderActivitiesNestedInput>;
  instructionImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  instructionText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isChallenge?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isCommentEnabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isFeatured?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isIndexable?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isLive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isVisible?: InputMaybe<BoolFieldUpdateOperationsInput>;
  jsonLD?: InputMaybe<Scalars["JSON"]["input"]>;
  materialsNeeded?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  metaTagData?: InputMaybe<Scalars["JSON"]["input"]>;
  nextActivity?: InputMaybe<ActivityUpdateOneWithoutPreviousActivitiesNestedInput>;
  products?: InputMaybe<ProductUpdateManyWithoutRelatedActivitiesNestedInput>;
  promoCodes?: InputMaybe<PromoCodeUpdateManyWithoutActivitiesNestedInput>;
  publishedDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  questionXps?: InputMaybe<IntFieldUpdateOperationsInput>;
  questions?: InputMaybe<ActivityQuestionUpdateManyWithoutActivityNestedInput>;
  relatedWorkshops?: InputMaybe<WorkshopUpdateManyWithoutRelatedActivitiesNestedInput>;
  scheduleTime?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  shortDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  skill?: InputMaybe<SkillUpdateOneWithoutActivitiesNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  standards?: InputMaybe<StandardUpdateManyWithoutActivitiesNestedInput>;
  status?: InputMaybe<EnumActivityStatusFieldUpdateOperationsInput>;
  subType?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  tags?: InputMaybe<TagUpdateManyWithoutActivitiesNestedInput>;
  timeRequired?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  video?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  xps?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type ActivityUpdateWithoutProductsInput = {
  approvedBy?: InputMaybe<UserUpdateOneWithoutActivitiesNestedInput>;
  challengeStatement?: InputMaybe<StringFieldUpdateOperationsInput>;
  coinsToSpend?: InputMaybe<IntFieldUpdateOperationsInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutActivityNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  difficultyLevel?: InputMaybe<StringFieldUpdateOperationsInput>;
  displayOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  insideWorkshops?: InputMaybe<WorkshopUpdateManyWithoutInsiderActivitiesNestedInput>;
  instructionImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  instructionText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isChallenge?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isCommentEnabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isFeatured?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isIndexable?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isLive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isVisible?: InputMaybe<BoolFieldUpdateOperationsInput>;
  jsonLD?: InputMaybe<Scalars["JSON"]["input"]>;
  materialsNeeded?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  metaTagData?: InputMaybe<Scalars["JSON"]["input"]>;
  nextActivity?: InputMaybe<ActivityUpdateOneWithoutPreviousActivitiesNestedInput>;
  previousActivities?: InputMaybe<ActivityUpdateManyWithoutNextActivityNestedInput>;
  promoCodes?: InputMaybe<PromoCodeUpdateManyWithoutActivitiesNestedInput>;
  publishedDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  questionXps?: InputMaybe<IntFieldUpdateOperationsInput>;
  questions?: InputMaybe<ActivityQuestionUpdateManyWithoutActivityNestedInput>;
  relatedWorkshops?: InputMaybe<WorkshopUpdateManyWithoutRelatedActivitiesNestedInput>;
  scheduleTime?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  shortDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  skill?: InputMaybe<SkillUpdateOneWithoutActivitiesNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  standards?: InputMaybe<StandardUpdateManyWithoutActivitiesNestedInput>;
  status?: InputMaybe<EnumActivityStatusFieldUpdateOperationsInput>;
  subType?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  tags?: InputMaybe<TagUpdateManyWithoutActivitiesNestedInput>;
  timeRequired?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  video?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  xps?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type ActivityUpdateWithoutPromoCodesInput = {
  approvedBy?: InputMaybe<UserUpdateOneWithoutActivitiesNestedInput>;
  challengeStatement?: InputMaybe<StringFieldUpdateOperationsInput>;
  coinsToSpend?: InputMaybe<IntFieldUpdateOperationsInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutActivityNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  difficultyLevel?: InputMaybe<StringFieldUpdateOperationsInput>;
  displayOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  insideWorkshops?: InputMaybe<WorkshopUpdateManyWithoutInsiderActivitiesNestedInput>;
  instructionImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  instructionText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isChallenge?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isCommentEnabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isFeatured?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isIndexable?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isLive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isVisible?: InputMaybe<BoolFieldUpdateOperationsInput>;
  jsonLD?: InputMaybe<Scalars["JSON"]["input"]>;
  materialsNeeded?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  metaTagData?: InputMaybe<Scalars["JSON"]["input"]>;
  nextActivity?: InputMaybe<ActivityUpdateOneWithoutPreviousActivitiesNestedInput>;
  previousActivities?: InputMaybe<ActivityUpdateManyWithoutNextActivityNestedInput>;
  products?: InputMaybe<ProductUpdateManyWithoutRelatedActivitiesNestedInput>;
  publishedDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  questionXps?: InputMaybe<IntFieldUpdateOperationsInput>;
  questions?: InputMaybe<ActivityQuestionUpdateManyWithoutActivityNestedInput>;
  relatedWorkshops?: InputMaybe<WorkshopUpdateManyWithoutRelatedActivitiesNestedInput>;
  scheduleTime?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  shortDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  skill?: InputMaybe<SkillUpdateOneWithoutActivitiesNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  standards?: InputMaybe<StandardUpdateManyWithoutActivitiesNestedInput>;
  status?: InputMaybe<EnumActivityStatusFieldUpdateOperationsInput>;
  subType?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  tags?: InputMaybe<TagUpdateManyWithoutActivitiesNestedInput>;
  timeRequired?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  video?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  xps?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type ActivityUpdateWithoutQuestionsInput = {
  approvedBy?: InputMaybe<UserUpdateOneWithoutActivitiesNestedInput>;
  challengeStatement?: InputMaybe<StringFieldUpdateOperationsInput>;
  coinsToSpend?: InputMaybe<IntFieldUpdateOperationsInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutActivityNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  difficultyLevel?: InputMaybe<StringFieldUpdateOperationsInput>;
  displayOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  insideWorkshops?: InputMaybe<WorkshopUpdateManyWithoutInsiderActivitiesNestedInput>;
  instructionImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  instructionText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isChallenge?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isCommentEnabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isFeatured?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isIndexable?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isLive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isVisible?: InputMaybe<BoolFieldUpdateOperationsInput>;
  jsonLD?: InputMaybe<Scalars["JSON"]["input"]>;
  materialsNeeded?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  metaTagData?: InputMaybe<Scalars["JSON"]["input"]>;
  nextActivity?: InputMaybe<ActivityUpdateOneWithoutPreviousActivitiesNestedInput>;
  previousActivities?: InputMaybe<ActivityUpdateManyWithoutNextActivityNestedInput>;
  products?: InputMaybe<ProductUpdateManyWithoutRelatedActivitiesNestedInput>;
  promoCodes?: InputMaybe<PromoCodeUpdateManyWithoutActivitiesNestedInput>;
  publishedDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  questionXps?: InputMaybe<IntFieldUpdateOperationsInput>;
  relatedWorkshops?: InputMaybe<WorkshopUpdateManyWithoutRelatedActivitiesNestedInput>;
  scheduleTime?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  shortDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  skill?: InputMaybe<SkillUpdateOneWithoutActivitiesNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  standards?: InputMaybe<StandardUpdateManyWithoutActivitiesNestedInput>;
  status?: InputMaybe<EnumActivityStatusFieldUpdateOperationsInput>;
  subType?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  tags?: InputMaybe<TagUpdateManyWithoutActivitiesNestedInput>;
  timeRequired?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  video?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  xps?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type ActivityUpdateWithoutRelatedWorkshopsInput = {
  approvedBy?: InputMaybe<UserUpdateOneWithoutActivitiesNestedInput>;
  challengeStatement?: InputMaybe<StringFieldUpdateOperationsInput>;
  coinsToSpend?: InputMaybe<IntFieldUpdateOperationsInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutActivityNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  difficultyLevel?: InputMaybe<StringFieldUpdateOperationsInput>;
  displayOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  insideWorkshops?: InputMaybe<WorkshopUpdateManyWithoutInsiderActivitiesNestedInput>;
  instructionImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  instructionText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isChallenge?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isCommentEnabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isFeatured?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isIndexable?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isLive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isVisible?: InputMaybe<BoolFieldUpdateOperationsInput>;
  jsonLD?: InputMaybe<Scalars["JSON"]["input"]>;
  materialsNeeded?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  metaTagData?: InputMaybe<Scalars["JSON"]["input"]>;
  nextActivity?: InputMaybe<ActivityUpdateOneWithoutPreviousActivitiesNestedInput>;
  previousActivities?: InputMaybe<ActivityUpdateManyWithoutNextActivityNestedInput>;
  products?: InputMaybe<ProductUpdateManyWithoutRelatedActivitiesNestedInput>;
  promoCodes?: InputMaybe<PromoCodeUpdateManyWithoutActivitiesNestedInput>;
  publishedDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  questionXps?: InputMaybe<IntFieldUpdateOperationsInput>;
  questions?: InputMaybe<ActivityQuestionUpdateManyWithoutActivityNestedInput>;
  scheduleTime?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  shortDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  skill?: InputMaybe<SkillUpdateOneWithoutActivitiesNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  standards?: InputMaybe<StandardUpdateManyWithoutActivitiesNestedInput>;
  status?: InputMaybe<EnumActivityStatusFieldUpdateOperationsInput>;
  subType?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  tags?: InputMaybe<TagUpdateManyWithoutActivitiesNestedInput>;
  timeRequired?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  video?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  xps?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type ActivityUpdateWithoutSkillInput = {
  approvedBy?: InputMaybe<UserUpdateOneWithoutActivitiesNestedInput>;
  challengeStatement?: InputMaybe<StringFieldUpdateOperationsInput>;
  coinsToSpend?: InputMaybe<IntFieldUpdateOperationsInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutActivityNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  difficultyLevel?: InputMaybe<StringFieldUpdateOperationsInput>;
  displayOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  insideWorkshops?: InputMaybe<WorkshopUpdateManyWithoutInsiderActivitiesNestedInput>;
  instructionImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  instructionText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isChallenge?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isCommentEnabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isFeatured?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isIndexable?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isLive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isVisible?: InputMaybe<BoolFieldUpdateOperationsInput>;
  jsonLD?: InputMaybe<Scalars["JSON"]["input"]>;
  materialsNeeded?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  metaTagData?: InputMaybe<Scalars["JSON"]["input"]>;
  nextActivity?: InputMaybe<ActivityUpdateOneWithoutPreviousActivitiesNestedInput>;
  previousActivities?: InputMaybe<ActivityUpdateManyWithoutNextActivityNestedInput>;
  products?: InputMaybe<ProductUpdateManyWithoutRelatedActivitiesNestedInput>;
  promoCodes?: InputMaybe<PromoCodeUpdateManyWithoutActivitiesNestedInput>;
  publishedDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  questionXps?: InputMaybe<IntFieldUpdateOperationsInput>;
  questions?: InputMaybe<ActivityQuestionUpdateManyWithoutActivityNestedInput>;
  relatedWorkshops?: InputMaybe<WorkshopUpdateManyWithoutRelatedActivitiesNestedInput>;
  scheduleTime?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  shortDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  standards?: InputMaybe<StandardUpdateManyWithoutActivitiesNestedInput>;
  status?: InputMaybe<EnumActivityStatusFieldUpdateOperationsInput>;
  subType?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  tags?: InputMaybe<TagUpdateManyWithoutActivitiesNestedInput>;
  timeRequired?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  video?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  xps?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type ActivityUpdateWithoutStandardsInput = {
  approvedBy?: InputMaybe<UserUpdateOneWithoutActivitiesNestedInput>;
  challengeStatement?: InputMaybe<StringFieldUpdateOperationsInput>;
  coinsToSpend?: InputMaybe<IntFieldUpdateOperationsInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutActivityNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  difficultyLevel?: InputMaybe<StringFieldUpdateOperationsInput>;
  displayOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  insideWorkshops?: InputMaybe<WorkshopUpdateManyWithoutInsiderActivitiesNestedInput>;
  instructionImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  instructionText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isChallenge?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isCommentEnabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isFeatured?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isIndexable?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isLive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isVisible?: InputMaybe<BoolFieldUpdateOperationsInput>;
  jsonLD?: InputMaybe<Scalars["JSON"]["input"]>;
  materialsNeeded?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  metaTagData?: InputMaybe<Scalars["JSON"]["input"]>;
  nextActivity?: InputMaybe<ActivityUpdateOneWithoutPreviousActivitiesNestedInput>;
  previousActivities?: InputMaybe<ActivityUpdateManyWithoutNextActivityNestedInput>;
  products?: InputMaybe<ProductUpdateManyWithoutRelatedActivitiesNestedInput>;
  promoCodes?: InputMaybe<PromoCodeUpdateManyWithoutActivitiesNestedInput>;
  publishedDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  questionXps?: InputMaybe<IntFieldUpdateOperationsInput>;
  questions?: InputMaybe<ActivityQuestionUpdateManyWithoutActivityNestedInput>;
  relatedWorkshops?: InputMaybe<WorkshopUpdateManyWithoutRelatedActivitiesNestedInput>;
  scheduleTime?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  shortDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  skill?: InputMaybe<SkillUpdateOneWithoutActivitiesNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumActivityStatusFieldUpdateOperationsInput>;
  subType?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  tags?: InputMaybe<TagUpdateManyWithoutActivitiesNestedInput>;
  timeRequired?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  video?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  xps?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type ActivityUpdateWithoutTagsInput = {
  approvedBy?: InputMaybe<UserUpdateOneWithoutActivitiesNestedInput>;
  challengeStatement?: InputMaybe<StringFieldUpdateOperationsInput>;
  coinsToSpend?: InputMaybe<IntFieldUpdateOperationsInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutActivityNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  difficultyLevel?: InputMaybe<StringFieldUpdateOperationsInput>;
  displayOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  insideWorkshops?: InputMaybe<WorkshopUpdateManyWithoutInsiderActivitiesNestedInput>;
  instructionImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  instructionText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isChallenge?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isCommentEnabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isFeatured?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isIndexable?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isLive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isVisible?: InputMaybe<BoolFieldUpdateOperationsInput>;
  jsonLD?: InputMaybe<Scalars["JSON"]["input"]>;
  materialsNeeded?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  metaTagData?: InputMaybe<Scalars["JSON"]["input"]>;
  nextActivity?: InputMaybe<ActivityUpdateOneWithoutPreviousActivitiesNestedInput>;
  previousActivities?: InputMaybe<ActivityUpdateManyWithoutNextActivityNestedInput>;
  products?: InputMaybe<ProductUpdateManyWithoutRelatedActivitiesNestedInput>;
  promoCodes?: InputMaybe<PromoCodeUpdateManyWithoutActivitiesNestedInput>;
  publishedDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  questionXps?: InputMaybe<IntFieldUpdateOperationsInput>;
  questions?: InputMaybe<ActivityQuestionUpdateManyWithoutActivityNestedInput>;
  relatedWorkshops?: InputMaybe<WorkshopUpdateManyWithoutRelatedActivitiesNestedInput>;
  scheduleTime?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  shortDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  skill?: InputMaybe<SkillUpdateOneWithoutActivitiesNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  standards?: InputMaybe<StandardUpdateManyWithoutActivitiesNestedInput>;
  status?: InputMaybe<EnumActivityStatusFieldUpdateOperationsInput>;
  subType?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timeRequired?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  video?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  xps?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type ActivityUpsertWithWhereUniqueWithoutApprovedByInput = {
  create: ActivityCreateWithoutApprovedByInput;
  update: ActivityUpdateWithoutApprovedByInput;
  where: ActivityWhereUniqueInput;
};

export type ActivityUpsertWithWhereUniqueWithoutInsideWorkshopsInput = {
  create: ActivityCreateWithoutInsideWorkshopsInput;
  update: ActivityUpdateWithoutInsideWorkshopsInput;
  where: ActivityWhereUniqueInput;
};

export type ActivityUpsertWithWhereUniqueWithoutNextActivityInput = {
  create: ActivityCreateWithoutNextActivityInput;
  update: ActivityUpdateWithoutNextActivityInput;
  where: ActivityWhereUniqueInput;
};

export type ActivityUpsertWithWhereUniqueWithoutProductsInput = {
  create: ActivityCreateWithoutProductsInput;
  update: ActivityUpdateWithoutProductsInput;
  where: ActivityWhereUniqueInput;
};

export type ActivityUpsertWithWhereUniqueWithoutPromoCodesInput = {
  create: ActivityCreateWithoutPromoCodesInput;
  update: ActivityUpdateWithoutPromoCodesInput;
  where: ActivityWhereUniqueInput;
};

export type ActivityUpsertWithWhereUniqueWithoutRelatedWorkshopsInput = {
  create: ActivityCreateWithoutRelatedWorkshopsInput;
  update: ActivityUpdateWithoutRelatedWorkshopsInput;
  where: ActivityWhereUniqueInput;
};

export type ActivityUpsertWithWhereUniqueWithoutSkillInput = {
  create: ActivityCreateWithoutSkillInput;
  update: ActivityUpdateWithoutSkillInput;
  where: ActivityWhereUniqueInput;
};

export type ActivityUpsertWithWhereUniqueWithoutStandardsInput = {
  create: ActivityCreateWithoutStandardsInput;
  update: ActivityUpdateWithoutStandardsInput;
  where: ActivityWhereUniqueInput;
};

export type ActivityUpsertWithWhereUniqueWithoutTagsInput = {
  create: ActivityCreateWithoutTagsInput;
  update: ActivityUpdateWithoutTagsInput;
  where: ActivityWhereUniqueInput;
};

export type ActivityUpsertWithoutCommentsInput = {
  create: ActivityCreateWithoutCommentsInput;
  update: ActivityUpdateWithoutCommentsInput;
};

export type ActivityUpsertWithoutPreviousActivitiesInput = {
  create: ActivityCreateWithoutPreviousActivitiesInput;
  update: ActivityUpdateWithoutPreviousActivitiesInput;
};

export type ActivityUpsertWithoutQuestionsInput = {
  create: ActivityCreateWithoutQuestionsInput;
  update: ActivityUpdateWithoutQuestionsInput;
};

export type ActivityWhereInput = {
  AND?: InputMaybe<Array<ActivityWhereInput>>;
  NOT?: InputMaybe<Array<ActivityWhereInput>>;
  OR?: InputMaybe<Array<ActivityWhereInput>>;
  approvedBy?: InputMaybe<UserRelationFilter>;
  approvedById?: InputMaybe<IntNullableFilter>;
  challengeStatement?: InputMaybe<StringFilter>;
  coinsToSpend?: InputMaybe<IntFilter>;
  comments?: InputMaybe<CommentListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  difficultyLevel?: InputMaybe<StringFilter>;
  displayOrder?: InputMaybe<IntFilter>;
  featuredImage?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  image?: InputMaybe<StringNullableFilter>;
  insideWorkshops?: InputMaybe<WorkshopListRelationFilter>;
  instructionImage?: InputMaybe<StringNullableFilter>;
  instructionText?: InputMaybe<StringNullableFilter>;
  isChallenge?: InputMaybe<BoolFilter>;
  isCommentEnabled?: InputMaybe<BoolFilter>;
  isFeatured?: InputMaybe<BoolFilter>;
  isIndexable?: InputMaybe<BoolFilter>;
  isLive?: InputMaybe<BoolFilter>;
  isVisible?: InputMaybe<BoolFilter>;
  jsonLD?: InputMaybe<JsonNullableFilter>;
  materialsNeeded?: InputMaybe<StringNullableFilter>;
  meta?: InputMaybe<JsonNullableFilter>;
  metaTagData?: InputMaybe<JsonNullableFilter>;
  nextActivity?: InputMaybe<ActivityRelationFilter>;
  nextActivityId?: InputMaybe<IntNullableFilter>;
  previousActivities?: InputMaybe<ActivityListRelationFilter>;
  products?: InputMaybe<ProductListRelationFilter>;
  promoCodes?: InputMaybe<PromoCodeListRelationFilter>;
  publishedDate?: InputMaybe<DateTimeNullableFilter>;
  questionXps?: InputMaybe<IntFilter>;
  questions?: InputMaybe<ActivityQuestionListRelationFilter>;
  relatedWorkshops?: InputMaybe<WorkshopListRelationFilter>;
  scheduleTime?: InputMaybe<DateTimeNullableFilter>;
  shortDescription?: InputMaybe<StringNullableFilter>;
  skill?: InputMaybe<SkillRelationFilter>;
  skillId?: InputMaybe<IntNullableFilter>;
  slug?: InputMaybe<StringFilter>;
  standards?: InputMaybe<StandardListRelationFilter>;
  status?: InputMaybe<EnumActivityStatusFilter>;
  subType?: InputMaybe<StringNullableFilter>;
  tags?: InputMaybe<TagListRelationFilter>;
  timeRequired?: InputMaybe<StringFilter>;
  title?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  video?: InputMaybe<StringNullableFilter>;
  xps?: InputMaybe<IntFilter>;
};

export type ActivityWhereUniqueInput = {
  id?: InputMaybe<Scalars["Int"]["input"]>;
  slug?: InputMaybe<Scalars["String"]["input"]>;
};

export type AdminLeaderboardInput = {
  endDate: Scalars["DateTime"]["input"];
  schoolId?: InputMaybe<Scalars["Int"]["input"]>;
  standardId?: InputMaybe<Scalars["Int"]["input"]>;
  startDate: Scalars["DateTime"]["input"];
};

export type AdminLeaderboardResponse = {
  __typename?: "AdminLeaderboardResponse";
  level: Scalars["Int"]["output"];
  name: Scalars["String"]["output"];
  phoneNumber: Scalars["String"]["output"];
  school: Scalars["String"]["output"];
  standard: Scalars["String"]["output"];
  stars: Scalars["Int"]["output"];
  xps: Scalars["Int"]["output"];
};

export type AffectedRowsOutput = {
  __typename?: "AffectedRowsOutput";
  count: Scalars["Int"]["output"];
};

export type AggregateActivity = {
  __typename?: "AggregateActivity";
  _avg?: Maybe<ActivityAvgAggregate>;
  _count?: Maybe<ActivityCountAggregate>;
  _max?: Maybe<ActivityMaxAggregate>;
  _min?: Maybe<ActivityMinAggregate>;
  _sum?: Maybe<ActivitySumAggregate>;
};

export type AggregateBadge = {
  __typename?: "AggregateBadge";
  _avg?: Maybe<BadgeAvgAggregate>;
  _count?: Maybe<BadgeCountAggregate>;
  _max?: Maybe<BadgeMaxAggregate>;
  _min?: Maybe<BadgeMinAggregate>;
  _sum?: Maybe<BadgeSumAggregate>;
};

export type AggregateComment = {
  __typename?: "AggregateComment";
  _avg?: Maybe<CommentAvgAggregate>;
  _count?: Maybe<CommentCountAggregate>;
  _max?: Maybe<CommentMaxAggregate>;
  _min?: Maybe<CommentMinAggregate>;
  _sum?: Maybe<CommentSumAggregate>;
};

export type AggregateDailyActivity = {
  __typename?: "AggregateDailyActivity";
  _avg?: Maybe<DailyActivityAvgAggregate>;
  _count?: Maybe<DailyActivityCountAggregate>;
  _max?: Maybe<DailyActivityMaxAggregate>;
  _min?: Maybe<DailyActivityMinAggregate>;
  _sum?: Maybe<DailyActivitySumAggregate>;
};

export type AggregateGamePointType = {
  __typename?: "AggregateGamePointType";
  _avg?: Maybe<GamePointTypeAvgAggregate>;
  _count?: Maybe<GamePointTypeCountAggregate>;
  _max?: Maybe<GamePointTypeMaxAggregate>;
  _min?: Maybe<GamePointTypeMinAggregate>;
  _sum?: Maybe<GamePointTypeSumAggregate>;
};

export type AggregateLevel = {
  __typename?: "AggregateLevel";
  _avg?: Maybe<LevelAvgAggregate>;
  _count?: Maybe<LevelCountAggregate>;
  _max?: Maybe<LevelMaxAggregate>;
  _min?: Maybe<LevelMinAggregate>;
  _sum?: Maybe<LevelSumAggregate>;
};

export type AggregateOrder = {
  __typename?: "AggregateOrder";
  _avg?: Maybe<OrderAvgAggregate>;
  _count?: Maybe<OrderCountAggregate>;
  _max?: Maybe<OrderMaxAggregate>;
  _min?: Maybe<OrderMinAggregate>;
  _sum?: Maybe<OrderSumAggregate>;
};

export type AggregatePrivacyPolicy = {
  __typename?: "AggregatePrivacyPolicy";
  _avg?: Maybe<PrivacyPolicyAvgAggregate>;
  _count?: Maybe<PrivacyPolicyCountAggregate>;
  _max?: Maybe<PrivacyPolicyMaxAggregate>;
  _min?: Maybe<PrivacyPolicyMinAggregate>;
  _sum?: Maybe<PrivacyPolicySumAggregate>;
};

export type AggregatePrivacyPolicyUpdate = {
  __typename?: "AggregatePrivacyPolicyUpdate";
  _avg?: Maybe<PrivacyPolicyUpdateAvgAggregate>;
  _count?: Maybe<PrivacyPolicyUpdateCountAggregate>;
  _max?: Maybe<PrivacyPolicyUpdateMaxAggregate>;
  _min?: Maybe<PrivacyPolicyUpdateMinAggregate>;
  _sum?: Maybe<PrivacyPolicyUpdateSumAggregate>;
};

export type AggregateProduct = {
  __typename?: "AggregateProduct";
  _avg?: Maybe<ProductAvgAggregate>;
  _count?: Maybe<ProductCountAggregate>;
  _max?: Maybe<ProductMaxAggregate>;
  _min?: Maybe<ProductMinAggregate>;
  _sum?: Maybe<ProductSumAggregate>;
};

export type AggregatePromoCode = {
  __typename?: "AggregatePromoCode";
  _avg?: Maybe<PromoCodeAvgAggregate>;
  _count?: Maybe<PromoCodeCountAggregate>;
  _max?: Maybe<PromoCodeMaxAggregate>;
  _min?: Maybe<PromoCodeMinAggregate>;
  _sum?: Maybe<PromoCodeSumAggregate>;
};

export type AggregatePromoCodeGroup = {
  __typename?: "AggregatePromoCodeGroup";
  _avg?: Maybe<PromoCodeGroupAvgAggregate>;
  _count?: Maybe<PromoCodeGroupCountAggregate>;
  _max?: Maybe<PromoCodeGroupMaxAggregate>;
  _min?: Maybe<PromoCodeGroupMinAggregate>;
  _sum?: Maybe<PromoCodeGroupSumAggregate>;
};

export type AggregatePromoCodeHistory = {
  __typename?: "AggregatePromoCodeHistory";
  _avg?: Maybe<PromoCodeHistoryAvgAggregate>;
  _count?: Maybe<PromoCodeHistoryCountAggregate>;
  _max?: Maybe<PromoCodeHistoryMaxAggregate>;
  _min?: Maybe<PromoCodeHistoryMinAggregate>;
  _sum?: Maybe<PromoCodeHistorySumAggregate>;
};

export type AggregatePromoCodeToPromoCodeGroup = {
  __typename?: "AggregatePromoCodeToPromoCodeGroup";
  _avg?: Maybe<PromoCodeToPromoCodeGroupAvgAggregate>;
  _count?: Maybe<PromoCodeToPromoCodeGroupCountAggregate>;
  _max?: Maybe<PromoCodeToPromoCodeGroupMaxAggregate>;
  _min?: Maybe<PromoCodeToPromoCodeGroupMinAggregate>;
  _sum?: Maybe<PromoCodeToPromoCodeGroupSumAggregate>;
};

export type AggregateReferralCode = {
  __typename?: "AggregateReferralCode";
  _avg?: Maybe<ReferralCodeAvgAggregate>;
  _count?: Maybe<ReferralCodeCountAggregate>;
  _max?: Maybe<ReferralCodeMaxAggregate>;
  _min?: Maybe<ReferralCodeMinAggregate>;
  _sum?: Maybe<ReferralCodeSumAggregate>;
};

export type AggregateReferralCodeHistory = {
  __typename?: "AggregateReferralCodeHistory";
  _avg?: Maybe<ReferralCodeHistoryAvgAggregate>;
  _count?: Maybe<ReferralCodeHistoryCountAggregate>;
  _max?: Maybe<ReferralCodeHistoryMaxAggregate>;
  _min?: Maybe<ReferralCodeHistoryMinAggregate>;
  _sum?: Maybe<ReferralCodeHistorySumAggregate>;
};

export type AggregateReport = {
  __typename?: "AggregateReport";
  _avg?: Maybe<ReportAvgAggregate>;
  _count?: Maybe<ReportCountAggregate>;
  _max?: Maybe<ReportMaxAggregate>;
  _min?: Maybe<ReportMinAggregate>;
  _sum?: Maybe<ReportSumAggregate>;
};

export type AggregateSamskara = {
  __typename?: "AggregateSamskara";
  _avg?: Maybe<SamskaraAvgAggregate>;
  _count?: Maybe<SamskaraCountAggregate>;
  _max?: Maybe<SamskaraMaxAggregate>;
  _min?: Maybe<SamskaraMinAggregate>;
  _sum?: Maybe<SamskaraSumAggregate>;
};

export type AggregateSamskaraCategory = {
  __typename?: "AggregateSamskaraCategory";
  _avg?: Maybe<SamskaraCategoryAvgAggregate>;
  _count?: Maybe<SamskaraCategoryCountAggregate>;
  _max?: Maybe<SamskaraCategoryMaxAggregate>;
  _min?: Maybe<SamskaraCategoryMinAggregate>;
  _sum?: Maybe<SamskaraCategorySumAggregate>;
};

export type AggregateSchool = {
  __typename?: "AggregateSchool";
  _avg?: Maybe<SchoolAvgAggregate>;
  _count?: Maybe<SchoolCountAggregate>;
  _max?: Maybe<SchoolMaxAggregate>;
  _min?: Maybe<SchoolMinAggregate>;
  _sum?: Maybe<SchoolSumAggregate>;
};

export type AggregateSchoolUpdate = {
  __typename?: "AggregateSchoolUpdate";
  _avg?: Maybe<SchoolUpdateAvgAggregate>;
  _count?: Maybe<SchoolUpdateCountAggregate>;
  _max?: Maybe<SchoolUpdateMaxAggregate>;
  _min?: Maybe<SchoolUpdateMinAggregate>;
  _sum?: Maybe<SchoolUpdateSumAggregate>;
};

export type AggregateSkill = {
  __typename?: "AggregateSkill";
  _avg?: Maybe<SkillAvgAggregate>;
  _count?: Maybe<SkillCountAggregate>;
  _max?: Maybe<SkillMaxAggregate>;
  _min?: Maybe<SkillMinAggregate>;
  _sum?: Maybe<SkillSumAggregate>;
};

export type AggregateStandard = {
  __typename?: "AggregateStandard";
  _avg?: Maybe<StandardAvgAggregate>;
  _count?: Maybe<StandardCountAggregate>;
  _max?: Maybe<StandardMaxAggregate>;
  _min?: Maybe<StandardMinAggregate>;
  _sum?: Maybe<StandardSumAggregate>;
};

export type AggregateStory = {
  __typename?: "AggregateStory";
  _avg?: Maybe<StoryAvgAggregate>;
  _count?: Maybe<StoryCountAggregate>;
  _max?: Maybe<StoryMaxAggregate>;
  _min?: Maybe<StoryMinAggregate>;
  _sum?: Maybe<StorySumAggregate>;
};

export type AggregateStoryActivity = {
  __typename?: "AggregateStoryActivity";
  _avg?: Maybe<StoryActivityAvgAggregate>;
  _count?: Maybe<StoryActivityCountAggregate>;
  _max?: Maybe<StoryActivityMaxAggregate>;
  _min?: Maybe<StoryActivityMinAggregate>;
  _sum?: Maybe<StoryActivitySumAggregate>;
};

export type AggregateStoryTag = {
  __typename?: "AggregateStoryTag";
  _avg?: Maybe<StoryTagAvgAggregate>;
  _count?: Maybe<StoryTagCountAggregate>;
  _max?: Maybe<StoryTagMaxAggregate>;
  _min?: Maybe<StoryTagMinAggregate>;
  _sum?: Maybe<StoryTagSumAggregate>;
};

export type AggregateStudent = {
  __typename?: "AggregateStudent";
  _avg?: Maybe<StudentAvgAggregate>;
  _count?: Maybe<StudentCountAggregate>;
  _max?: Maybe<StudentMaxAggregate>;
  _min?: Maybe<StudentMinAggregate>;
  _sum?: Maybe<StudentSumAggregate>;
};

export type AggregateStudentGameLog = {
  __typename?: "AggregateStudentGameLog";
  _avg?: Maybe<StudentGameLogAvgAggregate>;
  _count?: Maybe<StudentGameLogCountAggregate>;
  _max?: Maybe<StudentGameLogMaxAggregate>;
  _min?: Maybe<StudentGameLogMinAggregate>;
  _sum?: Maybe<StudentGameLogSumAggregate>;
};

export type AggregateStudentRecord = {
  __typename?: "AggregateStudentRecord";
  _avg?: Maybe<StudentRecordAvgAggregate>;
  _count?: Maybe<StudentRecordCountAggregate>;
  _max?: Maybe<StudentRecordMaxAggregate>;
  _min?: Maybe<StudentRecordMinAggregate>;
  _sum?: Maybe<StudentRecordSumAggregate>;
};

export type AggregateTag = {
  __typename?: "AggregateTag";
  _avg?: Maybe<TagAvgAggregate>;
  _count?: Maybe<TagCountAggregate>;
  _max?: Maybe<TagMaxAggregate>;
  _min?: Maybe<TagMinAggregate>;
  _sum?: Maybe<TagSumAggregate>;
};

export type AggregateTrigger = {
  __typename?: "AggregateTrigger";
  _avg?: Maybe<TriggerAvgAggregate>;
  _count?: Maybe<TriggerCountAggregate>;
  _max?: Maybe<TriggerMaxAggregate>;
  _min?: Maybe<TriggerMinAggregate>;
  _sum?: Maybe<TriggerSumAggregate>;
};

export type AggregateUser = {
  __typename?: "AggregateUser";
  _avg?: Maybe<UserAvgAggregate>;
  _count?: Maybe<UserCountAggregate>;
  _max?: Maybe<UserMaxAggregate>;
  _min?: Maybe<UserMinAggregate>;
  _sum?: Maybe<UserSumAggregate>;
};

export type AggregateUserLog = {
  __typename?: "AggregateUserLog";
  _avg?: Maybe<UserLogAvgAggregate>;
  _count?: Maybe<UserLogCountAggregate>;
  _max?: Maybe<UserLogMaxAggregate>;
  _min?: Maybe<UserLogMinAggregate>;
  _sum?: Maybe<UserLogSumAggregate>;
};

export type AggregateWorkshop = {
  __typename?: "AggregateWorkshop";
  _avg?: Maybe<WorkshopAvgAggregate>;
  _count?: Maybe<WorkshopCountAggregate>;
  _max?: Maybe<WorkshopMaxAggregate>;
  _min?: Maybe<WorkshopMinAggregate>;
  _sum?: Maybe<WorkshopSumAggregate>;
};

export type AggregateWorkshopRegistration = {
  __typename?: "AggregateWorkshopRegistration";
  _avg?: Maybe<WorkshopRegistrationAvgAggregate>;
  _count?: Maybe<WorkshopRegistrationCountAggregate>;
  _max?: Maybe<WorkshopRegistrationMaxAggregate>;
  _min?: Maybe<WorkshopRegistrationMinAggregate>;
  _sum?: Maybe<WorkshopRegistrationSumAggregate>;
};

export type AnswerStudentDailyActivityInput = {
  answer: Scalars["Int"]["input"];
  timeDiff: Scalars["Int"]["input"];
};

export type ApplyPromoCodeOutput = {
  __typename?: "ApplyPromoCodeOutput";
  message: Scalars["String"]["output"];
  success: Scalars["Boolean"]["output"];
};

export type Badge = {
  __typename?: "Badge";
  _count?: Maybe<BadgeCount>;
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["Int"]["output"];
  image?: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  slug: Scalars["String"]["output"];
  studentBadges: Array<StudentBadge>;
  triggers: Array<Trigger>;
  updatedAt: Scalars["DateTime"]["output"];
};


export type BadgeStudentBadgesArgs = {
  cursor?: InputMaybe<StudentBadgeWhereUniqueInput>;
  distinct?: InputMaybe<Array<StudentBadgeScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StudentBadgeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<StudentBadgeWhereInput>;
};


export type BadgeTriggersArgs = {
  cursor?: InputMaybe<TriggerWhereUniqueInput>;
  distinct?: InputMaybe<Array<TriggerScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TriggerOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<TriggerWhereInput>;
};

export type BadgeAvgAggregate = {
  __typename?: "BadgeAvgAggregate";
  id?: Maybe<Scalars["Float"]["output"]>;
};

export type BadgeCount = {
  __typename?: "BadgeCount";
  studentBadges: Scalars["Int"]["output"];
  triggers: Scalars["Int"]["output"];
};


export type BadgeCountStudentBadgesArgs = {
  where?: InputMaybe<StudentBadgeWhereInput>;
};


export type BadgeCountTriggersArgs = {
  where?: InputMaybe<TriggerWhereInput>;
};

export type BadgeCountAggregate = {
  __typename?: "BadgeCountAggregate";
  _all: Scalars["Int"]["output"];
  createdAt: Scalars["Int"]["output"];
  id: Scalars["Int"]["output"];
  image: Scalars["Int"]["output"];
  name: Scalars["Int"]["output"];
  slug: Scalars["Int"]["output"];
  updatedAt: Scalars["Int"]["output"];
};

export type BadgeCreateInput = {
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  image?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  slug: Scalars["String"]["input"];
  studentBadges?: InputMaybe<StudentBadgeCreateNestedManyWithoutBadgeInput>;
  triggers?: InputMaybe<TriggerCreateNestedManyWithoutBadgeInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type BadgeCreateNestedOneWithoutStudentBadgesInput = {
  connect?: InputMaybe<BadgeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BadgeCreateOrConnectWithoutStudentBadgesInput>;
  create?: InputMaybe<BadgeCreateWithoutStudentBadgesInput>;
};

export type BadgeCreateNestedOneWithoutTriggersInput = {
  connect?: InputMaybe<BadgeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BadgeCreateOrConnectWithoutTriggersInput>;
  create?: InputMaybe<BadgeCreateWithoutTriggersInput>;
};

export type BadgeCreateOrConnectWithoutStudentBadgesInput = {
  create: BadgeCreateWithoutStudentBadgesInput;
  where: BadgeWhereUniqueInput;
};

export type BadgeCreateOrConnectWithoutTriggersInput = {
  create: BadgeCreateWithoutTriggersInput;
  where: BadgeWhereUniqueInput;
};

export type BadgeCreateWithoutStudentBadgesInput = {
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  image?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  slug: Scalars["String"]["input"];
  triggers?: InputMaybe<TriggerCreateNestedManyWithoutBadgeInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type BadgeCreateWithoutTriggersInput = {
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  image?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  slug: Scalars["String"]["input"];
  studentBadges?: InputMaybe<StudentBadgeCreateNestedManyWithoutBadgeInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type BadgeMaxAggregate = {
  __typename?: "BadgeMaxAggregate";
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  image?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  slug?: Maybe<Scalars["String"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type BadgeMinAggregate = {
  __typename?: "BadgeMinAggregate";
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  image?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  slug?: Maybe<Scalars["String"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type BadgeOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  image?: InputMaybe<SortOrderInput>;
  name?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  studentBadges?: InputMaybe<StudentBadgeOrderByRelationAggregateInput>;
  triggers?: InputMaybe<TriggerOrderByRelationAggregateInput>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type BadgeRelationFilter = {
  is?: InputMaybe<BadgeWhereInput>;
  isNot?: InputMaybe<BadgeWhereInput>;
};

export enum BadgeScalarFieldEnum {
  CREATEDAT = "createdAt",
  ID = "id",
  IMAGE = "image",
  NAME = "name",
  SLUG = "slug",
  UPDATEDAT = "updatedAt"
}

export type BadgeSumAggregate = {
  __typename?: "BadgeSumAggregate";
  id?: Maybe<Scalars["Int"]["output"]>;
};

export type BadgeUpdateInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  studentBadges?: InputMaybe<StudentBadgeUpdateManyWithoutBadgeNestedInput>;
  triggers?: InputMaybe<TriggerUpdateManyWithoutBadgeNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type BadgeUpdateOneRequiredWithoutStudentBadgesNestedInput = {
  connect?: InputMaybe<BadgeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BadgeCreateOrConnectWithoutStudentBadgesInput>;
  create?: InputMaybe<BadgeCreateWithoutStudentBadgesInput>;
  update?: InputMaybe<BadgeUpdateWithoutStudentBadgesInput>;
  upsert?: InputMaybe<BadgeUpsertWithoutStudentBadgesInput>;
};

export type BadgeUpdateOneWithoutTriggersNestedInput = {
  connect?: InputMaybe<BadgeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BadgeCreateOrConnectWithoutTriggersInput>;
  create?: InputMaybe<BadgeCreateWithoutTriggersInput>;
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  update?: InputMaybe<BadgeUpdateWithoutTriggersInput>;
  upsert?: InputMaybe<BadgeUpsertWithoutTriggersInput>;
};

export type BadgeUpdateWithoutStudentBadgesInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  triggers?: InputMaybe<TriggerUpdateManyWithoutBadgeNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type BadgeUpdateWithoutTriggersInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  studentBadges?: InputMaybe<StudentBadgeUpdateManyWithoutBadgeNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type BadgeUpsertWithoutStudentBadgesInput = {
  create: BadgeCreateWithoutStudentBadgesInput;
  update: BadgeUpdateWithoutStudentBadgesInput;
};

export type BadgeUpsertWithoutTriggersInput = {
  create: BadgeCreateWithoutTriggersInput;
  update: BadgeUpdateWithoutTriggersInput;
};

export type BadgeWhereInput = {
  AND?: InputMaybe<Array<BadgeWhereInput>>;
  NOT?: InputMaybe<Array<BadgeWhereInput>>;
  OR?: InputMaybe<Array<BadgeWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  image?: InputMaybe<StringNullableFilter>;
  name?: InputMaybe<StringFilter>;
  slug?: InputMaybe<StringFilter>;
  studentBadges?: InputMaybe<StudentBadgeListRelationFilter>;
  triggers?: InputMaybe<TriggerListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type BadgeWhereUniqueInput = {
  id?: InputMaybe<Scalars["Int"]["input"]>;
  slug?: InputMaybe<Scalars["String"]["input"]>;
};

export type BoolFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type BoolFilter = {
  equals?: InputMaybe<Scalars["Boolean"]["input"]>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type Comment = {
  __typename?: "Comment";
  activity?: Maybe<Activity>;
  activityId?: Maybe<Scalars["Int"]["output"]>;
  approvedBy?: Maybe<User>;
  approvedById?: Maybe<Scalars["Int"]["output"]>;
  attachmentUrl?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  createdBy?: Maybe<Student>;
  createdById?: Maybe<Scalars["Int"]["output"]>;
  id: Scalars["Int"]["output"];
  isDailyChallenge: Scalars["Boolean"]["output"];
  isDeleted: Scalars["Boolean"]["output"];
  isFeatured: Scalars["Boolean"]["output"];
  meta?: Maybe<Scalars["JSON"]["output"]>;
  quality: Scalars["Int"]["output"];
  status?: Maybe<CommentStatus>;
  storyActivity?: Maybe<StoryActivity>;
  storyActivityId?: Maybe<Scalars["Int"]["output"]>;
  text: Scalars["String"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

/** Comment Activity Type Enum */
export enum CommentActivityType {
  ACTIVITY = "ACTIVITY",
  STORY_ACTIVITY = "STORY_ACTIVITY"
}

export type CommentAvgAggregate = {
  __typename?: "CommentAvgAggregate";
  activityId?: Maybe<Scalars["Float"]["output"]>;
  approvedById?: Maybe<Scalars["Float"]["output"]>;
  createdById?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  quality?: Maybe<Scalars["Float"]["output"]>;
  storyActivityId?: Maybe<Scalars["Float"]["output"]>;
};

export type CommentCountAggregate = {
  __typename?: "CommentCountAggregate";
  _all: Scalars["Int"]["output"];
  activityId: Scalars["Int"]["output"];
  approvedById: Scalars["Int"]["output"];
  attachmentUrl: Scalars["Int"]["output"];
  createdAt: Scalars["Int"]["output"];
  createdById: Scalars["Int"]["output"];
  id: Scalars["Int"]["output"];
  isDailyChallenge: Scalars["Int"]["output"];
  isDeleted: Scalars["Int"]["output"];
  isFeatured: Scalars["Int"]["output"];
  meta: Scalars["Int"]["output"];
  quality: Scalars["Int"]["output"];
  status: Scalars["Int"]["output"];
  storyActivityId: Scalars["Int"]["output"];
  text: Scalars["Int"]["output"];
  updatedAt: Scalars["Int"]["output"];
};

export type CommentCreateManyActivityInput = {
  approvedById?: InputMaybe<Scalars["Int"]["input"]>;
  attachmentUrl?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  createdById?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  isDailyChallenge?: InputMaybe<Scalars["Boolean"]["input"]>;
  isDeleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  isFeatured?: InputMaybe<Scalars["Boolean"]["input"]>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  quality?: InputMaybe<Scalars["Int"]["input"]>;
  status?: InputMaybe<CommentStatus>;
  storyActivityId?: InputMaybe<Scalars["Int"]["input"]>;
  text: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type CommentCreateManyActivityInputEnvelope = {
  data: Array<CommentCreateManyActivityInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type CommentCreateManyApprovedByInput = {
  activityId?: InputMaybe<Scalars["Int"]["input"]>;
  attachmentUrl?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  createdById?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  isDailyChallenge?: InputMaybe<Scalars["Boolean"]["input"]>;
  isDeleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  isFeatured?: InputMaybe<Scalars["Boolean"]["input"]>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  quality?: InputMaybe<Scalars["Int"]["input"]>;
  status?: InputMaybe<CommentStatus>;
  storyActivityId?: InputMaybe<Scalars["Int"]["input"]>;
  text: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type CommentCreateManyApprovedByInputEnvelope = {
  data: Array<CommentCreateManyApprovedByInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type CommentCreateManyCreatedByInput = {
  activityId?: InputMaybe<Scalars["Int"]["input"]>;
  approvedById?: InputMaybe<Scalars["Int"]["input"]>;
  attachmentUrl?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  isDailyChallenge?: InputMaybe<Scalars["Boolean"]["input"]>;
  isDeleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  isFeatured?: InputMaybe<Scalars["Boolean"]["input"]>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  quality?: InputMaybe<Scalars["Int"]["input"]>;
  status?: InputMaybe<CommentStatus>;
  storyActivityId?: InputMaybe<Scalars["Int"]["input"]>;
  text: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type CommentCreateManyCreatedByInputEnvelope = {
  data: Array<CommentCreateManyCreatedByInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type CommentCreateManyStoryActivityInput = {
  activityId?: InputMaybe<Scalars["Int"]["input"]>;
  approvedById?: InputMaybe<Scalars["Int"]["input"]>;
  attachmentUrl?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  createdById?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  isDailyChallenge?: InputMaybe<Scalars["Boolean"]["input"]>;
  isDeleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  isFeatured?: InputMaybe<Scalars["Boolean"]["input"]>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  quality?: InputMaybe<Scalars["Int"]["input"]>;
  status?: InputMaybe<CommentStatus>;
  text: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type CommentCreateManyStoryActivityInputEnvelope = {
  data: Array<CommentCreateManyStoryActivityInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type CommentCreateNestedManyWithoutActivityInput = {
  connect?: InputMaybe<Array<CommentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CommentCreateOrConnectWithoutActivityInput>>;
  create?: InputMaybe<Array<CommentCreateWithoutActivityInput>>;
  createMany?: InputMaybe<CommentCreateManyActivityInputEnvelope>;
};

export type CommentCreateNestedManyWithoutApprovedByInput = {
  connect?: InputMaybe<Array<CommentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CommentCreateOrConnectWithoutApprovedByInput>>;
  create?: InputMaybe<Array<CommentCreateWithoutApprovedByInput>>;
  createMany?: InputMaybe<CommentCreateManyApprovedByInputEnvelope>;
};

export type CommentCreateNestedManyWithoutCreatedByInput = {
  connect?: InputMaybe<Array<CommentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CommentCreateOrConnectWithoutCreatedByInput>>;
  create?: InputMaybe<Array<CommentCreateWithoutCreatedByInput>>;
  createMany?: InputMaybe<CommentCreateManyCreatedByInputEnvelope>;
};

export type CommentCreateNestedManyWithoutStoryActivityInput = {
  connect?: InputMaybe<Array<CommentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CommentCreateOrConnectWithoutStoryActivityInput>>;
  create?: InputMaybe<Array<CommentCreateWithoutStoryActivityInput>>;
  createMany?: InputMaybe<CommentCreateManyStoryActivityInputEnvelope>;
};

export type CommentCreateOrConnectWithoutActivityInput = {
  create: CommentCreateWithoutActivityInput;
  where: CommentWhereUniqueInput;
};

export type CommentCreateOrConnectWithoutApprovedByInput = {
  create: CommentCreateWithoutApprovedByInput;
  where: CommentWhereUniqueInput;
};

export type CommentCreateOrConnectWithoutCreatedByInput = {
  create: CommentCreateWithoutCreatedByInput;
  where: CommentWhereUniqueInput;
};

export type CommentCreateOrConnectWithoutStoryActivityInput = {
  create: CommentCreateWithoutStoryActivityInput;
  where: CommentWhereUniqueInput;
};

export type CommentCreateWithoutActivityInput = {
  approvedBy?: InputMaybe<UserCreateNestedOneWithoutCommentsInput>;
  attachmentUrl?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  createdBy?: InputMaybe<StudentCreateNestedOneWithoutCommentsInput>;
  isDailyChallenge?: InputMaybe<Scalars["Boolean"]["input"]>;
  isDeleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  isFeatured?: InputMaybe<Scalars["Boolean"]["input"]>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  quality?: InputMaybe<Scalars["Int"]["input"]>;
  status?: InputMaybe<CommentStatus>;
  storyActivity?: InputMaybe<StoryActivityCreateNestedOneWithoutCommentsInput>;
  text: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type CommentCreateWithoutApprovedByInput = {
  activity?: InputMaybe<ActivityCreateNestedOneWithoutCommentsInput>;
  attachmentUrl?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  createdBy?: InputMaybe<StudentCreateNestedOneWithoutCommentsInput>;
  isDailyChallenge?: InputMaybe<Scalars["Boolean"]["input"]>;
  isDeleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  isFeatured?: InputMaybe<Scalars["Boolean"]["input"]>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  quality?: InputMaybe<Scalars["Int"]["input"]>;
  status?: InputMaybe<CommentStatus>;
  storyActivity?: InputMaybe<StoryActivityCreateNestedOneWithoutCommentsInput>;
  text: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type CommentCreateWithoutCreatedByInput = {
  activity?: InputMaybe<ActivityCreateNestedOneWithoutCommentsInput>;
  approvedBy?: InputMaybe<UserCreateNestedOneWithoutCommentsInput>;
  attachmentUrl?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  isDailyChallenge?: InputMaybe<Scalars["Boolean"]["input"]>;
  isDeleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  isFeatured?: InputMaybe<Scalars["Boolean"]["input"]>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  quality?: InputMaybe<Scalars["Int"]["input"]>;
  status?: InputMaybe<CommentStatus>;
  storyActivity?: InputMaybe<StoryActivityCreateNestedOneWithoutCommentsInput>;
  text: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type CommentCreateWithoutStoryActivityInput = {
  activity?: InputMaybe<ActivityCreateNestedOneWithoutCommentsInput>;
  approvedBy?: InputMaybe<UserCreateNestedOneWithoutCommentsInput>;
  attachmentUrl?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  createdBy?: InputMaybe<StudentCreateNestedOneWithoutCommentsInput>;
  isDailyChallenge?: InputMaybe<Scalars["Boolean"]["input"]>;
  isDeleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  isFeatured?: InputMaybe<Scalars["Boolean"]["input"]>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  quality?: InputMaybe<Scalars["Int"]["input"]>;
  status?: InputMaybe<CommentStatus>;
  text: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type CommentListRelationFilter = {
  every?: InputMaybe<CommentWhereInput>;
  none?: InputMaybe<CommentWhereInput>;
  some?: InputMaybe<CommentWhereInput>;
};

export type CommentMaxAggregate = {
  __typename?: "CommentMaxAggregate";
  activityId?: Maybe<Scalars["Int"]["output"]>;
  approvedById?: Maybe<Scalars["Int"]["output"]>;
  attachmentUrl?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  createdById?: Maybe<Scalars["Int"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  isDailyChallenge?: Maybe<Scalars["Boolean"]["output"]>;
  isDeleted?: Maybe<Scalars["Boolean"]["output"]>;
  isFeatured?: Maybe<Scalars["Boolean"]["output"]>;
  quality?: Maybe<Scalars["Int"]["output"]>;
  status?: Maybe<CommentStatus>;
  storyActivityId?: Maybe<Scalars["Int"]["output"]>;
  text?: Maybe<Scalars["String"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type CommentMinAggregate = {
  __typename?: "CommentMinAggregate";
  activityId?: Maybe<Scalars["Int"]["output"]>;
  approvedById?: Maybe<Scalars["Int"]["output"]>;
  attachmentUrl?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  createdById?: Maybe<Scalars["Int"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  isDailyChallenge?: Maybe<Scalars["Boolean"]["output"]>;
  isDeleted?: Maybe<Scalars["Boolean"]["output"]>;
  isFeatured?: Maybe<Scalars["Boolean"]["output"]>;
  quality?: Maybe<Scalars["Int"]["output"]>;
  status?: Maybe<CommentStatus>;
  storyActivityId?: Maybe<Scalars["Int"]["output"]>;
  text?: Maybe<Scalars["String"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type CommentOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type CommentOrderByWithRelationInput = {
  activity?: InputMaybe<ActivityOrderByWithRelationInput>;
  activityId?: InputMaybe<SortOrderInput>;
  approvedBy?: InputMaybe<UserOrderByWithRelationInput>;
  approvedById?: InputMaybe<SortOrderInput>;
  attachmentUrl?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<StudentOrderByWithRelationInput>;
  createdById?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  isDailyChallenge?: InputMaybe<SortOrder>;
  isDeleted?: InputMaybe<SortOrder>;
  isFeatured?: InputMaybe<SortOrder>;
  meta?: InputMaybe<SortOrderInput>;
  quality?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrderInput>;
  storyActivity?: InputMaybe<StoryActivityOrderByWithRelationInput>;
  storyActivityId?: InputMaybe<SortOrderInput>;
  text?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum CommentScalarFieldEnum {
  ACTIVITYID = "activityId",
  APPROVEDBYID = "approvedById",
  ATTACHMENTURL = "attachmentUrl",
  CREATEDAT = "createdAt",
  CREATEDBYID = "createdById",
  ID = "id",
  ISDAILYCHALLENGE = "isDailyChallenge",
  ISDELETED = "isDeleted",
  ISFEATURED = "isFeatured",
  META = "meta",
  QUALITY = "quality",
  STATUS = "status",
  STORYACTIVITYID = "storyActivityId",
  TEXT = "text",
  UPDATEDAT = "updatedAt"
}

export type CommentScalarWhereInput = {
  AND?: InputMaybe<Array<CommentScalarWhereInput>>;
  NOT?: InputMaybe<Array<CommentScalarWhereInput>>;
  OR?: InputMaybe<Array<CommentScalarWhereInput>>;
  activityId?: InputMaybe<IntNullableFilter>;
  approvedById?: InputMaybe<IntNullableFilter>;
  attachmentUrl?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  createdById?: InputMaybe<IntNullableFilter>;
  id?: InputMaybe<IntFilter>;
  isDailyChallenge?: InputMaybe<BoolFilter>;
  isDeleted?: InputMaybe<BoolFilter>;
  isFeatured?: InputMaybe<BoolFilter>;
  meta?: InputMaybe<JsonNullableFilter>;
  quality?: InputMaybe<IntFilter>;
  status?: InputMaybe<EnumCommentStatusNullableFilter>;
  storyActivityId?: InputMaybe<IntNullableFilter>;
  text?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export enum CommentStatus {
  APPROVED = "APPROVED",
  DELETED = "DELETED",
  PENDING = "PENDING",
  REJECTED = "REJECTED"
}

export type CommentSumAggregate = {
  __typename?: "CommentSumAggregate";
  activityId?: Maybe<Scalars["Int"]["output"]>;
  approvedById?: Maybe<Scalars["Int"]["output"]>;
  createdById?: Maybe<Scalars["Int"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  quality?: Maybe<Scalars["Int"]["output"]>;
  storyActivityId?: Maybe<Scalars["Int"]["output"]>;
};

export type CommentUpdateInput = {
  activity?: InputMaybe<ActivityUpdateOneWithoutCommentsNestedInput>;
  approvedBy?: InputMaybe<UserUpdateOneWithoutCommentsNestedInput>;
  attachmentUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<StudentUpdateOneWithoutCommentsNestedInput>;
  isDailyChallenge?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isDeleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isFeatured?: InputMaybe<BoolFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  quality?: InputMaybe<IntFieldUpdateOperationsInput>;
  status?: InputMaybe<NullableEnumCommentStatusFieldUpdateOperationsInput>;
  storyActivity?: InputMaybe<StoryActivityUpdateOneWithoutCommentsNestedInput>;
  text?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CommentUpdateManyMutationInput = {
  attachmentUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  isDailyChallenge?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isDeleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isFeatured?: InputMaybe<BoolFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  quality?: InputMaybe<IntFieldUpdateOperationsInput>;
  status?: InputMaybe<NullableEnumCommentStatusFieldUpdateOperationsInput>;
  text?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CommentUpdateManyWithWhereWithoutActivityInput = {
  data: CommentUpdateManyMutationInput;
  where: CommentScalarWhereInput;
};

export type CommentUpdateManyWithWhereWithoutApprovedByInput = {
  data: CommentUpdateManyMutationInput;
  where: CommentScalarWhereInput;
};

export type CommentUpdateManyWithWhereWithoutCreatedByInput = {
  data: CommentUpdateManyMutationInput;
  where: CommentScalarWhereInput;
};

export type CommentUpdateManyWithWhereWithoutStoryActivityInput = {
  data: CommentUpdateManyMutationInput;
  where: CommentScalarWhereInput;
};

export type CommentUpdateManyWithoutActivityNestedInput = {
  connect?: InputMaybe<Array<CommentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CommentCreateOrConnectWithoutActivityInput>>;
  create?: InputMaybe<Array<CommentCreateWithoutActivityInput>>;
  createMany?: InputMaybe<CommentCreateManyActivityInputEnvelope>;
  delete?: InputMaybe<Array<CommentWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CommentScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CommentWhereUniqueInput>>;
  set?: InputMaybe<Array<CommentWhereUniqueInput>>;
  update?: InputMaybe<Array<CommentUpdateWithWhereUniqueWithoutActivityInput>>;
  updateMany?: InputMaybe<Array<CommentUpdateManyWithWhereWithoutActivityInput>>;
  upsert?: InputMaybe<Array<CommentUpsertWithWhereUniqueWithoutActivityInput>>;
};

export type CommentUpdateManyWithoutApprovedByNestedInput = {
  connect?: InputMaybe<Array<CommentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CommentCreateOrConnectWithoutApprovedByInput>>;
  create?: InputMaybe<Array<CommentCreateWithoutApprovedByInput>>;
  createMany?: InputMaybe<CommentCreateManyApprovedByInputEnvelope>;
  delete?: InputMaybe<Array<CommentWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CommentScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CommentWhereUniqueInput>>;
  set?: InputMaybe<Array<CommentWhereUniqueInput>>;
  update?: InputMaybe<Array<CommentUpdateWithWhereUniqueWithoutApprovedByInput>>;
  updateMany?: InputMaybe<Array<CommentUpdateManyWithWhereWithoutApprovedByInput>>;
  upsert?: InputMaybe<Array<CommentUpsertWithWhereUniqueWithoutApprovedByInput>>;
};

export type CommentUpdateManyWithoutCreatedByNestedInput = {
  connect?: InputMaybe<Array<CommentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CommentCreateOrConnectWithoutCreatedByInput>>;
  create?: InputMaybe<Array<CommentCreateWithoutCreatedByInput>>;
  createMany?: InputMaybe<CommentCreateManyCreatedByInputEnvelope>;
  delete?: InputMaybe<Array<CommentWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CommentScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CommentWhereUniqueInput>>;
  set?: InputMaybe<Array<CommentWhereUniqueInput>>;
  update?: InputMaybe<Array<CommentUpdateWithWhereUniqueWithoutCreatedByInput>>;
  updateMany?: InputMaybe<Array<CommentUpdateManyWithWhereWithoutCreatedByInput>>;
  upsert?: InputMaybe<Array<CommentUpsertWithWhereUniqueWithoutCreatedByInput>>;
};

export type CommentUpdateManyWithoutStoryActivityNestedInput = {
  connect?: InputMaybe<Array<CommentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CommentCreateOrConnectWithoutStoryActivityInput>>;
  create?: InputMaybe<Array<CommentCreateWithoutStoryActivityInput>>;
  createMany?: InputMaybe<CommentCreateManyStoryActivityInputEnvelope>;
  delete?: InputMaybe<Array<CommentWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CommentScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CommentWhereUniqueInput>>;
  set?: InputMaybe<Array<CommentWhereUniqueInput>>;
  update?: InputMaybe<Array<CommentUpdateWithWhereUniqueWithoutStoryActivityInput>>;
  updateMany?: InputMaybe<Array<CommentUpdateManyWithWhereWithoutStoryActivityInput>>;
  upsert?: InputMaybe<Array<CommentUpsertWithWhereUniqueWithoutStoryActivityInput>>;
};

export type CommentUpdateWithWhereUniqueWithoutActivityInput = {
  data: CommentUpdateWithoutActivityInput;
  where: CommentWhereUniqueInput;
};

export type CommentUpdateWithWhereUniqueWithoutApprovedByInput = {
  data: CommentUpdateWithoutApprovedByInput;
  where: CommentWhereUniqueInput;
};

export type CommentUpdateWithWhereUniqueWithoutCreatedByInput = {
  data: CommentUpdateWithoutCreatedByInput;
  where: CommentWhereUniqueInput;
};

export type CommentUpdateWithWhereUniqueWithoutStoryActivityInput = {
  data: CommentUpdateWithoutStoryActivityInput;
  where: CommentWhereUniqueInput;
};

export type CommentUpdateWithoutActivityInput = {
  approvedBy?: InputMaybe<UserUpdateOneWithoutCommentsNestedInput>;
  attachmentUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<StudentUpdateOneWithoutCommentsNestedInput>;
  isDailyChallenge?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isDeleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isFeatured?: InputMaybe<BoolFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  quality?: InputMaybe<IntFieldUpdateOperationsInput>;
  status?: InputMaybe<NullableEnumCommentStatusFieldUpdateOperationsInput>;
  storyActivity?: InputMaybe<StoryActivityUpdateOneWithoutCommentsNestedInput>;
  text?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CommentUpdateWithoutApprovedByInput = {
  activity?: InputMaybe<ActivityUpdateOneWithoutCommentsNestedInput>;
  attachmentUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<StudentUpdateOneWithoutCommentsNestedInput>;
  isDailyChallenge?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isDeleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isFeatured?: InputMaybe<BoolFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  quality?: InputMaybe<IntFieldUpdateOperationsInput>;
  status?: InputMaybe<NullableEnumCommentStatusFieldUpdateOperationsInput>;
  storyActivity?: InputMaybe<StoryActivityUpdateOneWithoutCommentsNestedInput>;
  text?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CommentUpdateWithoutCreatedByInput = {
  activity?: InputMaybe<ActivityUpdateOneWithoutCommentsNestedInput>;
  approvedBy?: InputMaybe<UserUpdateOneWithoutCommentsNestedInput>;
  attachmentUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  isDailyChallenge?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isDeleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isFeatured?: InputMaybe<BoolFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  quality?: InputMaybe<IntFieldUpdateOperationsInput>;
  status?: InputMaybe<NullableEnumCommentStatusFieldUpdateOperationsInput>;
  storyActivity?: InputMaybe<StoryActivityUpdateOneWithoutCommentsNestedInput>;
  text?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CommentUpdateWithoutStoryActivityInput = {
  activity?: InputMaybe<ActivityUpdateOneWithoutCommentsNestedInput>;
  approvedBy?: InputMaybe<UserUpdateOneWithoutCommentsNestedInput>;
  attachmentUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<StudentUpdateOneWithoutCommentsNestedInput>;
  isDailyChallenge?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isDeleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isFeatured?: InputMaybe<BoolFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  quality?: InputMaybe<IntFieldUpdateOperationsInput>;
  status?: InputMaybe<NullableEnumCommentStatusFieldUpdateOperationsInput>;
  text?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CommentUpsertWithWhereUniqueWithoutActivityInput = {
  create: CommentCreateWithoutActivityInput;
  update: CommentUpdateWithoutActivityInput;
  where: CommentWhereUniqueInput;
};

export type CommentUpsertWithWhereUniqueWithoutApprovedByInput = {
  create: CommentCreateWithoutApprovedByInput;
  update: CommentUpdateWithoutApprovedByInput;
  where: CommentWhereUniqueInput;
};

export type CommentUpsertWithWhereUniqueWithoutCreatedByInput = {
  create: CommentCreateWithoutCreatedByInput;
  update: CommentUpdateWithoutCreatedByInput;
  where: CommentWhereUniqueInput;
};

export type CommentUpsertWithWhereUniqueWithoutStoryActivityInput = {
  create: CommentCreateWithoutStoryActivityInput;
  update: CommentUpdateWithoutStoryActivityInput;
  where: CommentWhereUniqueInput;
};

export type CommentWhereInput = {
  AND?: InputMaybe<Array<CommentWhereInput>>;
  NOT?: InputMaybe<Array<CommentWhereInput>>;
  OR?: InputMaybe<Array<CommentWhereInput>>;
  activity?: InputMaybe<ActivityRelationFilter>;
  activityId?: InputMaybe<IntNullableFilter>;
  approvedBy?: InputMaybe<UserRelationFilter>;
  approvedById?: InputMaybe<IntNullableFilter>;
  attachmentUrl?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  createdBy?: InputMaybe<StudentRelationFilter>;
  createdById?: InputMaybe<IntNullableFilter>;
  id?: InputMaybe<IntFilter>;
  isDailyChallenge?: InputMaybe<BoolFilter>;
  isDeleted?: InputMaybe<BoolFilter>;
  isFeatured?: InputMaybe<BoolFilter>;
  meta?: InputMaybe<JsonNullableFilter>;
  quality?: InputMaybe<IntFilter>;
  status?: InputMaybe<EnumCommentStatusNullableFilter>;
  storyActivity?: InputMaybe<StoryActivityRelationFilter>;
  storyActivityId?: InputMaybe<IntNullableFilter>;
  text?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type CommentWhereUniqueInput = {
  id?: InputMaybe<Scalars["Int"]["input"]>;
};

export type DailyActivity = {
  __typename?: "DailyActivity";
  _count?: Maybe<DailyActivityCount>;
  answer: Scalars["Int"]["output"];
  answerText?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["Int"]["output"];
  options: Scalars["JSON"]["output"];
  question: Scalars["String"]["output"];
  questionImage?: Maybe<Scalars["String"]["output"]>;
  questionVideo?: Maybe<Scalars["String"]["output"]>;
  scheduledOn: Scalars["DateTime"]["output"];
  standards: Array<Standard>;
  updatedAt: Scalars["DateTime"]["output"];
};


export type DailyActivityStandardsArgs = {
  cursor?: InputMaybe<StandardWhereUniqueInput>;
  distinct?: InputMaybe<Array<StandardScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StandardOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<StandardWhereInput>;
};

export type DailyActivityAvgAggregate = {
  __typename?: "DailyActivityAvgAggregate";
  answer?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
};

export type DailyActivityAvgOrderByAggregateInput = {
  answer?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
};

export type DailyActivityCount = {
  __typename?: "DailyActivityCount";
  standards: Scalars["Int"]["output"];
};


export type DailyActivityCountStandardsArgs = {
  where?: InputMaybe<StandardWhereInput>;
};

export type DailyActivityCountAggregate = {
  __typename?: "DailyActivityCountAggregate";
  _all: Scalars["Int"]["output"];
  answer: Scalars["Int"]["output"];
  answerText: Scalars["Int"]["output"];
  createdAt: Scalars["Int"]["output"];
  id: Scalars["Int"]["output"];
  options: Scalars["Int"]["output"];
  question: Scalars["Int"]["output"];
  questionImage: Scalars["Int"]["output"];
  questionVideo: Scalars["Int"]["output"];
  scheduledOn: Scalars["Int"]["output"];
  updatedAt: Scalars["Int"]["output"];
};

export type DailyActivityCountOrderByAggregateInput = {
  answer?: InputMaybe<SortOrder>;
  answerText?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  options?: InputMaybe<SortOrder>;
  question?: InputMaybe<SortOrder>;
  questionImage?: InputMaybe<SortOrder>;
  questionVideo?: InputMaybe<SortOrder>;
  scheduledOn?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type DailyActivityCreateInput = {
  answer: Scalars["Int"]["input"];
  answerText?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  options: Scalars["JSON"]["input"];
  question: Scalars["String"]["input"];
  questionImage?: InputMaybe<Scalars["String"]["input"]>;
  questionVideo?: InputMaybe<Scalars["String"]["input"]>;
  scheduledOn: Scalars["DateTime"]["input"];
  standards?: InputMaybe<StandardCreateNestedManyWithoutDailyActivitiesInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type DailyActivityCreateNestedManyWithoutStandardsInput = {
  connect?: InputMaybe<Array<DailyActivityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<DailyActivityCreateOrConnectWithoutStandardsInput>>;
  create?: InputMaybe<Array<DailyActivityCreateWithoutStandardsInput>>;
};

export type DailyActivityCreateOrConnectWithoutStandardsInput = {
  create: DailyActivityCreateWithoutStandardsInput;
  where: DailyActivityWhereUniqueInput;
};

export type DailyActivityCreateWithoutStandardsInput = {
  answer: Scalars["Int"]["input"];
  answerText?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  options: Scalars["JSON"]["input"];
  question: Scalars["String"]["input"];
  questionImage?: InputMaybe<Scalars["String"]["input"]>;
  questionVideo?: InputMaybe<Scalars["String"]["input"]>;
  scheduledOn: Scalars["DateTime"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type DailyActivityGroupBy = {
  __typename?: "DailyActivityGroupBy";
  _avg?: Maybe<DailyActivityAvgAggregate>;
  _count?: Maybe<DailyActivityCountAggregate>;
  _max?: Maybe<DailyActivityMaxAggregate>;
  _min?: Maybe<DailyActivityMinAggregate>;
  _sum?: Maybe<DailyActivitySumAggregate>;
  answer: Scalars["Int"]["output"];
  answerText?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["Int"]["output"];
  options: Scalars["JSON"]["output"];
  question: Scalars["String"]["output"];
  questionImage?: Maybe<Scalars["String"]["output"]>;
  questionVideo?: Maybe<Scalars["String"]["output"]>;
  scheduledOn: Scalars["DateTime"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export type DailyActivityListRelationFilter = {
  every?: InputMaybe<DailyActivityWhereInput>;
  none?: InputMaybe<DailyActivityWhereInput>;
  some?: InputMaybe<DailyActivityWhereInput>;
};

export type DailyActivityMaxAggregate = {
  __typename?: "DailyActivityMaxAggregate";
  answer?: Maybe<Scalars["Int"]["output"]>;
  answerText?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  question?: Maybe<Scalars["String"]["output"]>;
  questionImage?: Maybe<Scalars["String"]["output"]>;
  questionVideo?: Maybe<Scalars["String"]["output"]>;
  scheduledOn?: Maybe<Scalars["DateTime"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type DailyActivityMaxOrderByAggregateInput = {
  answer?: InputMaybe<SortOrder>;
  answerText?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  question?: InputMaybe<SortOrder>;
  questionImage?: InputMaybe<SortOrder>;
  questionVideo?: InputMaybe<SortOrder>;
  scheduledOn?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type DailyActivityMinAggregate = {
  __typename?: "DailyActivityMinAggregate";
  answer?: Maybe<Scalars["Int"]["output"]>;
  answerText?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  question?: Maybe<Scalars["String"]["output"]>;
  questionImage?: Maybe<Scalars["String"]["output"]>;
  questionVideo?: Maybe<Scalars["String"]["output"]>;
  scheduledOn?: Maybe<Scalars["DateTime"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type DailyActivityMinOrderByAggregateInput = {
  answer?: InputMaybe<SortOrder>;
  answerText?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  question?: InputMaybe<SortOrder>;
  questionImage?: InputMaybe<SortOrder>;
  questionVideo?: InputMaybe<SortOrder>;
  scheduledOn?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type DailyActivityOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type DailyActivityOrderByWithAggregationInput = {
  _avg?: InputMaybe<DailyActivityAvgOrderByAggregateInput>;
  _count?: InputMaybe<DailyActivityCountOrderByAggregateInput>;
  _max?: InputMaybe<DailyActivityMaxOrderByAggregateInput>;
  _min?: InputMaybe<DailyActivityMinOrderByAggregateInput>;
  _sum?: InputMaybe<DailyActivitySumOrderByAggregateInput>;
  answer?: InputMaybe<SortOrder>;
  answerText?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  options?: InputMaybe<SortOrder>;
  question?: InputMaybe<SortOrder>;
  questionImage?: InputMaybe<SortOrderInput>;
  questionVideo?: InputMaybe<SortOrderInput>;
  scheduledOn?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type DailyActivityOrderByWithRelationInput = {
  answer?: InputMaybe<SortOrder>;
  answerText?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  options?: InputMaybe<SortOrder>;
  question?: InputMaybe<SortOrder>;
  questionImage?: InputMaybe<SortOrderInput>;
  questionVideo?: InputMaybe<SortOrderInput>;
  scheduledOn?: InputMaybe<SortOrder>;
  standards?: InputMaybe<StandardOrderByRelationAggregateInput>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum DailyActivityScalarFieldEnum {
  ANSWER = "answer",
  ANSWERTEXT = "answerText",
  CREATEDAT = "createdAt",
  ID = "id",
  OPTIONS = "options",
  QUESTION = "question",
  QUESTIONIMAGE = "questionImage",
  QUESTIONVIDEO = "questionVideo",
  SCHEDULEDON = "scheduledOn",
  UPDATEDAT = "updatedAt"
}

export type DailyActivityScalarWhereInput = {
  AND?: InputMaybe<Array<DailyActivityScalarWhereInput>>;
  NOT?: InputMaybe<Array<DailyActivityScalarWhereInput>>;
  OR?: InputMaybe<Array<DailyActivityScalarWhereInput>>;
  answer?: InputMaybe<IntFilter>;
  answerText?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  options?: InputMaybe<JsonFilter>;
  question?: InputMaybe<StringFilter>;
  questionImage?: InputMaybe<StringNullableFilter>;
  questionVideo?: InputMaybe<StringNullableFilter>;
  scheduledOn?: InputMaybe<DateTimeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type DailyActivityScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<DailyActivityScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<DailyActivityScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<DailyActivityScalarWhereWithAggregatesInput>>;
  answer?: InputMaybe<IntWithAggregatesFilter>;
  answerText?: InputMaybe<StringNullableWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<IntWithAggregatesFilter>;
  options?: InputMaybe<JsonWithAggregatesFilter>;
  question?: InputMaybe<StringWithAggregatesFilter>;
  questionImage?: InputMaybe<StringNullableWithAggregatesFilter>;
  questionVideo?: InputMaybe<StringNullableWithAggregatesFilter>;
  scheduledOn?: InputMaybe<DateTimeWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type DailyActivitySumAggregate = {
  __typename?: "DailyActivitySumAggregate";
  answer?: Maybe<Scalars["Int"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
};

export type DailyActivitySumOrderByAggregateInput = {
  answer?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
};

export type DailyActivityUpdateInput = {
  answer?: InputMaybe<IntFieldUpdateOperationsInput>;
  answerText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  options?: InputMaybe<Scalars["JSON"]["input"]>;
  question?: InputMaybe<StringFieldUpdateOperationsInput>;
  questionImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  questionVideo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  scheduledOn?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  standards?: InputMaybe<StandardUpdateManyWithoutDailyActivitiesNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type DailyActivityUpdateManyMutationInput = {
  answer?: InputMaybe<IntFieldUpdateOperationsInput>;
  answerText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  options?: InputMaybe<Scalars["JSON"]["input"]>;
  question?: InputMaybe<StringFieldUpdateOperationsInput>;
  questionImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  questionVideo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  scheduledOn?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type DailyActivityUpdateManyWithWhereWithoutStandardsInput = {
  data: DailyActivityUpdateManyMutationInput;
  where: DailyActivityScalarWhereInput;
};

export type DailyActivityUpdateManyWithoutStandardsNestedInput = {
  connect?: InputMaybe<Array<DailyActivityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<DailyActivityCreateOrConnectWithoutStandardsInput>>;
  create?: InputMaybe<Array<DailyActivityCreateWithoutStandardsInput>>;
  delete?: InputMaybe<Array<DailyActivityWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<DailyActivityScalarWhereInput>>;
  disconnect?: InputMaybe<Array<DailyActivityWhereUniqueInput>>;
  set?: InputMaybe<Array<DailyActivityWhereUniqueInput>>;
  update?: InputMaybe<Array<DailyActivityUpdateWithWhereUniqueWithoutStandardsInput>>;
  updateMany?: InputMaybe<Array<DailyActivityUpdateManyWithWhereWithoutStandardsInput>>;
  upsert?: InputMaybe<Array<DailyActivityUpsertWithWhereUniqueWithoutStandardsInput>>;
};

export type DailyActivityUpdateWithWhereUniqueWithoutStandardsInput = {
  data: DailyActivityUpdateWithoutStandardsInput;
  where: DailyActivityWhereUniqueInput;
};

export type DailyActivityUpdateWithoutStandardsInput = {
  answer?: InputMaybe<IntFieldUpdateOperationsInput>;
  answerText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  options?: InputMaybe<Scalars["JSON"]["input"]>;
  question?: InputMaybe<StringFieldUpdateOperationsInput>;
  questionImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  questionVideo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  scheduledOn?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type DailyActivityUpsertWithWhereUniqueWithoutStandardsInput = {
  create: DailyActivityCreateWithoutStandardsInput;
  update: DailyActivityUpdateWithoutStandardsInput;
  where: DailyActivityWhereUniqueInput;
};

export type DailyActivityWhereInput = {
  AND?: InputMaybe<Array<DailyActivityWhereInput>>;
  NOT?: InputMaybe<Array<DailyActivityWhereInput>>;
  OR?: InputMaybe<Array<DailyActivityWhereInput>>;
  answer?: InputMaybe<IntFilter>;
  answerText?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  options?: InputMaybe<JsonFilter>;
  question?: InputMaybe<StringFilter>;
  questionImage?: InputMaybe<StringNullableFilter>;
  questionVideo?: InputMaybe<StringNullableFilter>;
  scheduledOn?: InputMaybe<DateTimeFilter>;
  standards?: InputMaybe<StandardListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type DailyActivityWhereUniqueInput = {
  id?: InputMaybe<Scalars["Int"]["input"]>;
};

export type DateTimeFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type DateTimeFilter = {
  equals?: InputMaybe<Scalars["DateTime"]["input"]>;
  gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  in?: InputMaybe<Array<Scalars["DateTime"]["input"]>>;
  lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars["DateTime"]["input"]>>;
};

export type DateTimeNullableFilter = {
  equals?: InputMaybe<Scalars["DateTime"]["input"]>;
  gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  in?: InputMaybe<Array<Scalars["DateTime"]["input"]>>;
  lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars["DateTime"]["input"]>>;
};

export type DateTimeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedDateTimeFilter>;
  _min?: InputMaybe<NestedDateTimeFilter>;
  equals?: InputMaybe<Scalars["DateTime"]["input"]>;
  gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  in?: InputMaybe<Array<Scalars["DateTime"]["input"]>>;
  lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  not?: InputMaybe<NestedDateTimeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars["DateTime"]["input"]>>;
};

export type DecryptUserTokenInput = {
  token: Scalars["String"]["input"];
};

export type EnumActivityStatusFieldUpdateOperationsInput = {
  set?: InputMaybe<ActivityStatus>;
};

export type EnumActivityStatusFilter = {
  equals?: InputMaybe<ActivityStatus>;
  in?: InputMaybe<Array<ActivityStatus>>;
  not?: InputMaybe<NestedEnumActivityStatusFilter>;
  notIn?: InputMaybe<Array<ActivityStatus>>;
};

export type EnumCommentStatusNullableFilter = {
  equals?: InputMaybe<CommentStatus>;
  in?: InputMaybe<Array<CommentStatus>>;
  not?: InputMaybe<NestedEnumCommentStatusNullableFilter>;
  notIn?: InputMaybe<Array<CommentStatus>>;
};

export type EnumLogAccessTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<LogAccessType>;
};

export type EnumLogAccessTypeFilter = {
  equals?: InputMaybe<LogAccessType>;
  in?: InputMaybe<Array<LogAccessType>>;
  not?: InputMaybe<NestedEnumLogAccessTypeFilter>;
  notIn?: InputMaybe<Array<LogAccessType>>;
};

export type EnumOrderStatusFieldUpdateOperationsInput = {
  set?: InputMaybe<OrderStatus>;
};

export type EnumOrderStatusFilter = {
  equals?: InputMaybe<OrderStatus>;
  in?: InputMaybe<Array<OrderStatus>>;
  not?: InputMaybe<NestedEnumOrderStatusFilter>;
  notIn?: InputMaybe<Array<OrderStatus>>;
};

export type EnumPaymentMethodFieldUpdateOperationsInput = {
  set?: InputMaybe<PaymentMethod>;
};

export type EnumPaymentMethodFilter = {
  equals?: InputMaybe<PaymentMethod>;
  in?: InputMaybe<Array<PaymentMethod>>;
  not?: InputMaybe<NestedEnumPaymentMethodFilter>;
  notIn?: InputMaybe<Array<PaymentMethod>>;
};

export type EnumRoleFieldUpdateOperationsInput = {
  set?: InputMaybe<Role>;
};

export type EnumRoleFilter = {
  equals?: InputMaybe<Role>;
  in?: InputMaybe<Array<Role>>;
  not?: InputMaybe<NestedEnumRoleFilter>;
  notIn?: InputMaybe<Array<Role>>;
};

export type EnumSkillTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<SkillType>;
};

export type EnumSkillTypeFilter = {
  equals?: InputMaybe<SkillType>;
  in?: InputMaybe<Array<SkillType>>;
  not?: InputMaybe<NestedEnumSkillTypeFilter>;
  notIn?: InputMaybe<Array<SkillType>>;
};

export type EnumStatusFieldUpdateOperationsInput = {
  set?: InputMaybe<Status>;
};

export type EnumStatusFilter = {
  equals?: InputMaybe<Status>;
  in?: InputMaybe<Array<Status>>;
  not?: InputMaybe<NestedEnumStatusFilter>;
  notIn?: InputMaybe<Array<Status>>;
};

export type EnumTriggerTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<TriggerType>;
};

export type EnumTriggerTypeFilter = {
  equals?: InputMaybe<TriggerType>;
  in?: InputMaybe<Array<TriggerType>>;
  not?: InputMaybe<NestedEnumTriggerTypeFilter>;
  notIn?: InputMaybe<Array<TriggerType>>;
};

export type EnumWorkshopStatusFieldUpdateOperationsInput = {
  set?: InputMaybe<WorkshopStatus>;
};

export type EnumWorkshopStatusFilter = {
  equals?: InputMaybe<WorkshopStatus>;
  in?: InputMaybe<Array<WorkshopStatus>>;
  not?: InputMaybe<NestedEnumWorkshopStatusFilter>;
  notIn?: InputMaybe<Array<WorkshopStatus>>;
};

export type ExportGameLogsInput = {
  endDate: Scalars["DateTime"]["input"];
  startDate: Scalars["DateTime"]["input"];
};

export type File = {
  __typename?: "File";
  name?: Maybe<Scalars["String"]["output"]>;
  signedUrl?: Maybe<Scalars["String"]["output"]>;
  url?: Maybe<Scalars["String"]["output"]>;
};

/** File Access Enums */
export enum FileAccess {
  PRIVATE = "PRIVATE",
  PUBLIC = "PUBLIC"
}

export type FindPublicManyActivityInput = {
  isChallenge?: InputMaybe<Scalars["Boolean"]["input"]>;
  isLive?: InputMaybe<Scalars["Boolean"]["input"]>;
  isNewReleased?: InputMaybe<Scalars["Boolean"]["input"]>;
  isPopular?: InputMaybe<Scalars["Boolean"]["input"]>;
  skill?: InputMaybe<SkillWhereUniqueInput>;
  tag?: InputMaybe<TagWhereUniqueInput>;
};

export type FindPublicManyWorkshopWhereInput = {
  tag?: InputMaybe<Scalars["String"]["input"]>;
};

export type FindStudentActivityAccessOutput = {
  __typename?: "FindStudentActivityAccessOutput";
  isQaCompleted: Scalars["Boolean"]["output"];
  isUnlocked: Scalars["Boolean"]["output"];
  message: Scalars["String"]["output"];
};

export type FindStudentActivityCommentsInput = {
  createdBy?: Scalars["Boolean"]["input"];
  slug: Scalars["String"]["input"];
  type: CommentActivityType;
};

export type FindStudentActivityCommentsOutput = {
  __typename?: "FindStudentActivityCommentsOutput";
  attachmentUrl?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  createdBy?: Maybe<FindStudentActivityCommentsStudentOutput>;
  id: Scalars["Int"]["output"];
  isFeatured: Scalars["Boolean"]["output"];
  meta?: Maybe<Scalars["JSON"]["output"]>;
  status?: Maybe<CommentStatus>;
  text: Scalars["String"]["output"];
};

export type FindStudentActivityCommentsStudentOutput = {
  __typename?: "FindStudentActivityCommentsStudentOutput";
  displayName: Scalars["String"]["output"];
  profileImage?: Maybe<Scalars["String"]["output"]>;
};

export type FindStudentManyActivityInput = {
  isChallenge?: InputMaybe<Scalars["Boolean"]["input"]>;
  isFeatured?: InputMaybe<Scalars["Boolean"]["input"]>;
  isLive?: InputMaybe<Scalars["Boolean"]["input"]>;
  isNewReleased?: InputMaybe<Scalars["Boolean"]["input"]>;
  isPending?: InputMaybe<Scalars["Boolean"]["input"]>;
  isPopular?: InputMaybe<Scalars["Boolean"]["input"]>;
  skill?: InputMaybe<SkillWhereUniqueInput>;
  tag?: InputMaybe<TagWhereUniqueInput>;
};

export type FindStudentManySkillWhereInput = {
  showNoActivitiesSkills?: InputMaybe<Scalars["Boolean"]["input"]>;
  showNoProductSkills?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type FindStudentStoryActivityAccessResponse = {
  __typename?: "FindStudentStoryActivityAccessResponse";
  message: Scalars["String"]["output"];
  success: Scalars["Boolean"]["output"];
};

export type FindStudentStoryActivityInput = {
  story?: InputMaybe<FindStudentStoryActivitySkillInput>;
  tag?: InputMaybe<StoryTagWhereUniqueInput>;
};

export type FindStudentStoryActivitySkillInput = {
  slug: Scalars["String"]["input"];
};

export type FindStudentUniqueActivityOutput = {
  __typename?: "FindStudentUniqueActivityOutput";
  challengeStatement: Scalars["String"]["output"];
  coinsToSpend: Scalars["Int"]["output"];
  description?: Maybe<Scalars["String"]["output"]>;
  difficultyLevel: Scalars["String"]["output"];
  featuredImage?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["Int"]["output"];
  image?: Maybe<Scalars["String"]["output"]>;
  instructionImage?: Maybe<Scalars["String"]["output"]>;
  instructionText?: Maybe<Scalars["String"]["output"]>;
  isCommentEnabled: Scalars["Boolean"]["output"];
  jsonLD?: Maybe<Scalars["JSON"]["output"]>;
  materialsNeeded?: Maybe<Scalars["String"]["output"]>;
  meta?: Maybe<Scalars["JSON"]["output"]>;
  metaTagData?: Maybe<Scalars["JSON"]["output"]>;
  nextActivity?: Maybe<PublicActivity>;
  questionXps: Scalars["Int"]["output"];
  questions?: Maybe<Array<ActivityQuestion>>;
  shortDescription?: Maybe<Scalars["String"]["output"]>;
  skill?: Maybe<PublicSkill>;
  slug: Scalars["String"]["output"];
  tags?: Maybe<Array<PublicTag>>;
  timeRequired: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
  video?: Maybe<Scalars["String"]["output"]>;
  xps: Scalars["Int"]["output"];
};

export type FindStudentWorkshopAccessResponse = {
  __typename?: "FindStudentWorkshopAccessResponse";
  message: Scalars["String"]["output"];
  success: Scalars["Boolean"]["output"];
};

export type FindTriggerManyActivityInput = {
  title: StringFilter;
};

export type FloatFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars["Float"]["input"]>;
  divide?: InputMaybe<Scalars["Float"]["input"]>;
  increment?: InputMaybe<Scalars["Float"]["input"]>;
  multiply?: InputMaybe<Scalars["Float"]["input"]>;
  set?: InputMaybe<Scalars["Float"]["input"]>;
};

export type FloatFilter = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  gt?: InputMaybe<Scalars["Float"]["input"]>;
  gte?: InputMaybe<Scalars["Float"]["input"]>;
  in?: InputMaybe<Array<Scalars["Float"]["input"]>>;
  lt?: InputMaybe<Scalars["Float"]["input"]>;
  lte?: InputMaybe<Scalars["Float"]["input"]>;
  not?: InputMaybe<NestedFloatFilter>;
  notIn?: InputMaybe<Array<Scalars["Float"]["input"]>>;
};

export type GamePointType = {
  __typename?: "GamePointType";
  _count?: Maybe<GamePointTypeCount>;
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["Int"]["output"];
  image?: Maybe<Scalars["String"]["output"]>;
  pluralName: Scalars["String"]["output"];
  singularName: Scalars["String"]["output"];
  skill?: Maybe<Skill>;
  skillId?: Maybe<Scalars["Int"]["output"]>;
  slug: Scalars["String"]["output"];
  studentGameLogs: Array<StudentGameLog>;
  triggers: Array<Trigger>;
  updatedAt: Scalars["DateTime"]["output"];
};


export type GamePointTypeStudentGameLogsArgs = {
  cursor?: InputMaybe<StudentGameLogWhereUniqueInput>;
  distinct?: InputMaybe<Array<StudentGameLogScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StudentGameLogOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<StudentGameLogWhereInput>;
};


export type GamePointTypeTriggersArgs = {
  cursor?: InputMaybe<TriggerWhereUniqueInput>;
  distinct?: InputMaybe<Array<TriggerScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TriggerOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<TriggerWhereInput>;
};

export type GamePointTypeAvgAggregate = {
  __typename?: "GamePointTypeAvgAggregate";
  id?: Maybe<Scalars["Float"]["output"]>;
  skillId?: Maybe<Scalars["Float"]["output"]>;
};

export type GamePointTypeCount = {
  __typename?: "GamePointTypeCount";
  studentGameLogs: Scalars["Int"]["output"];
  triggers: Scalars["Int"]["output"];
};


export type GamePointTypeCountStudentGameLogsArgs = {
  where?: InputMaybe<StudentGameLogWhereInput>;
};


export type GamePointTypeCountTriggersArgs = {
  where?: InputMaybe<TriggerWhereInput>;
};

export type GamePointTypeCountAggregate = {
  __typename?: "GamePointTypeCountAggregate";
  _all: Scalars["Int"]["output"];
  createdAt: Scalars["Int"]["output"];
  id: Scalars["Int"]["output"];
  image: Scalars["Int"]["output"];
  pluralName: Scalars["Int"]["output"];
  singularName: Scalars["Int"]["output"];
  skillId: Scalars["Int"]["output"];
  slug: Scalars["Int"]["output"];
  updatedAt: Scalars["Int"]["output"];
};

export type GamePointTypeCreateInput = {
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  image?: InputMaybe<Scalars["String"]["input"]>;
  pluralName: Scalars["String"]["input"];
  singularName: Scalars["String"]["input"];
  skill?: InputMaybe<SkillCreateNestedOneWithoutGamePointTypeInput>;
  slug: Scalars["String"]["input"];
  studentGameLogs?: InputMaybe<StudentGameLogCreateNestedManyWithoutPointTypeInput>;
  triggers?: InputMaybe<TriggerCreateNestedManyWithoutGamePointTypeInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type GamePointTypeCreateNestedOneWithoutSkillInput = {
  connect?: InputMaybe<GamePointTypeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<GamePointTypeCreateOrConnectWithoutSkillInput>;
  create?: InputMaybe<GamePointTypeCreateWithoutSkillInput>;
};

export type GamePointTypeCreateNestedOneWithoutStudentGameLogsInput = {
  connect?: InputMaybe<GamePointTypeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<GamePointTypeCreateOrConnectWithoutStudentGameLogsInput>;
  create?: InputMaybe<GamePointTypeCreateWithoutStudentGameLogsInput>;
};

export type GamePointTypeCreateNestedOneWithoutTriggersInput = {
  connect?: InputMaybe<GamePointTypeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<GamePointTypeCreateOrConnectWithoutTriggersInput>;
  create?: InputMaybe<GamePointTypeCreateWithoutTriggersInput>;
};

export type GamePointTypeCreateOrConnectWithoutSkillInput = {
  create: GamePointTypeCreateWithoutSkillInput;
  where: GamePointTypeWhereUniqueInput;
};

export type GamePointTypeCreateOrConnectWithoutStudentGameLogsInput = {
  create: GamePointTypeCreateWithoutStudentGameLogsInput;
  where: GamePointTypeWhereUniqueInput;
};

export type GamePointTypeCreateOrConnectWithoutTriggersInput = {
  create: GamePointTypeCreateWithoutTriggersInput;
  where: GamePointTypeWhereUniqueInput;
};

export type GamePointTypeCreateWithoutSkillInput = {
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  image?: InputMaybe<Scalars["String"]["input"]>;
  pluralName: Scalars["String"]["input"];
  singularName: Scalars["String"]["input"];
  slug: Scalars["String"]["input"];
  studentGameLogs?: InputMaybe<StudentGameLogCreateNestedManyWithoutPointTypeInput>;
  triggers?: InputMaybe<TriggerCreateNestedManyWithoutGamePointTypeInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type GamePointTypeCreateWithoutStudentGameLogsInput = {
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  image?: InputMaybe<Scalars["String"]["input"]>;
  pluralName: Scalars["String"]["input"];
  singularName: Scalars["String"]["input"];
  skill?: InputMaybe<SkillCreateNestedOneWithoutGamePointTypeInput>;
  slug: Scalars["String"]["input"];
  triggers?: InputMaybe<TriggerCreateNestedManyWithoutGamePointTypeInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type GamePointTypeCreateWithoutTriggersInput = {
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  image?: InputMaybe<Scalars["String"]["input"]>;
  pluralName: Scalars["String"]["input"];
  singularName: Scalars["String"]["input"];
  skill?: InputMaybe<SkillCreateNestedOneWithoutGamePointTypeInput>;
  slug: Scalars["String"]["input"];
  studentGameLogs?: InputMaybe<StudentGameLogCreateNestedManyWithoutPointTypeInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type GamePointTypeMaxAggregate = {
  __typename?: "GamePointTypeMaxAggregate";
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  image?: Maybe<Scalars["String"]["output"]>;
  pluralName?: Maybe<Scalars["String"]["output"]>;
  singularName?: Maybe<Scalars["String"]["output"]>;
  skillId?: Maybe<Scalars["Int"]["output"]>;
  slug?: Maybe<Scalars["String"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type GamePointTypeMinAggregate = {
  __typename?: "GamePointTypeMinAggregate";
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  image?: Maybe<Scalars["String"]["output"]>;
  pluralName?: Maybe<Scalars["String"]["output"]>;
  singularName?: Maybe<Scalars["String"]["output"]>;
  skillId?: Maybe<Scalars["Int"]["output"]>;
  slug?: Maybe<Scalars["String"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type GamePointTypeOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  image?: InputMaybe<SortOrderInput>;
  pluralName?: InputMaybe<SortOrder>;
  singularName?: InputMaybe<SortOrder>;
  skill?: InputMaybe<SkillOrderByWithRelationInput>;
  skillId?: InputMaybe<SortOrderInput>;
  slug?: InputMaybe<SortOrder>;
  studentGameLogs?: InputMaybe<StudentGameLogOrderByRelationAggregateInput>;
  triggers?: InputMaybe<TriggerOrderByRelationAggregateInput>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type GamePointTypeRelationFilter = {
  is?: InputMaybe<GamePointTypeWhereInput>;
  isNot?: InputMaybe<GamePointTypeWhereInput>;
};

export enum GamePointTypeScalarFieldEnum {
  CREATEDAT = "createdAt",
  ID = "id",
  IMAGE = "image",
  PLURALNAME = "pluralName",
  SINGULARNAME = "singularName",
  SKILLID = "skillId",
  SLUG = "slug",
  UPDATEDAT = "updatedAt"
}

export type GamePointTypeSumAggregate = {
  __typename?: "GamePointTypeSumAggregate";
  id?: Maybe<Scalars["Int"]["output"]>;
  skillId?: Maybe<Scalars["Int"]["output"]>;
};

export type GamePointTypeUpdateInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  pluralName?: InputMaybe<StringFieldUpdateOperationsInput>;
  singularName?: InputMaybe<StringFieldUpdateOperationsInput>;
  skill?: InputMaybe<SkillUpdateOneWithoutGamePointTypeNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  studentGameLogs?: InputMaybe<StudentGameLogUpdateManyWithoutPointTypeNestedInput>;
  triggers?: InputMaybe<TriggerUpdateManyWithoutGamePointTypeNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type GamePointTypeUpdateOneWithoutSkillNestedInput = {
  connect?: InputMaybe<GamePointTypeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<GamePointTypeCreateOrConnectWithoutSkillInput>;
  create?: InputMaybe<GamePointTypeCreateWithoutSkillInput>;
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  update?: InputMaybe<GamePointTypeUpdateWithoutSkillInput>;
  upsert?: InputMaybe<GamePointTypeUpsertWithoutSkillInput>;
};

export type GamePointTypeUpdateOneWithoutStudentGameLogsNestedInput = {
  connect?: InputMaybe<GamePointTypeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<GamePointTypeCreateOrConnectWithoutStudentGameLogsInput>;
  create?: InputMaybe<GamePointTypeCreateWithoutStudentGameLogsInput>;
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  update?: InputMaybe<GamePointTypeUpdateWithoutStudentGameLogsInput>;
  upsert?: InputMaybe<GamePointTypeUpsertWithoutStudentGameLogsInput>;
};

export type GamePointTypeUpdateOneWithoutTriggersNestedInput = {
  connect?: InputMaybe<GamePointTypeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<GamePointTypeCreateOrConnectWithoutTriggersInput>;
  create?: InputMaybe<GamePointTypeCreateWithoutTriggersInput>;
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  update?: InputMaybe<GamePointTypeUpdateWithoutTriggersInput>;
  upsert?: InputMaybe<GamePointTypeUpsertWithoutTriggersInput>;
};

export type GamePointTypeUpdateWithoutSkillInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  pluralName?: InputMaybe<StringFieldUpdateOperationsInput>;
  singularName?: InputMaybe<StringFieldUpdateOperationsInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  studentGameLogs?: InputMaybe<StudentGameLogUpdateManyWithoutPointTypeNestedInput>;
  triggers?: InputMaybe<TriggerUpdateManyWithoutGamePointTypeNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type GamePointTypeUpdateWithoutStudentGameLogsInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  pluralName?: InputMaybe<StringFieldUpdateOperationsInput>;
  singularName?: InputMaybe<StringFieldUpdateOperationsInput>;
  skill?: InputMaybe<SkillUpdateOneWithoutGamePointTypeNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  triggers?: InputMaybe<TriggerUpdateManyWithoutGamePointTypeNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type GamePointTypeUpdateWithoutTriggersInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  pluralName?: InputMaybe<StringFieldUpdateOperationsInput>;
  singularName?: InputMaybe<StringFieldUpdateOperationsInput>;
  skill?: InputMaybe<SkillUpdateOneWithoutGamePointTypeNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  studentGameLogs?: InputMaybe<StudentGameLogUpdateManyWithoutPointTypeNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type GamePointTypeUpsertWithoutSkillInput = {
  create: GamePointTypeCreateWithoutSkillInput;
  update: GamePointTypeUpdateWithoutSkillInput;
};

export type GamePointTypeUpsertWithoutStudentGameLogsInput = {
  create: GamePointTypeCreateWithoutStudentGameLogsInput;
  update: GamePointTypeUpdateWithoutStudentGameLogsInput;
};

export type GamePointTypeUpsertWithoutTriggersInput = {
  create: GamePointTypeCreateWithoutTriggersInput;
  update: GamePointTypeUpdateWithoutTriggersInput;
};

export type GamePointTypeWhereInput = {
  AND?: InputMaybe<Array<GamePointTypeWhereInput>>;
  NOT?: InputMaybe<Array<GamePointTypeWhereInput>>;
  OR?: InputMaybe<Array<GamePointTypeWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  image?: InputMaybe<StringNullableFilter>;
  pluralName?: InputMaybe<StringFilter>;
  singularName?: InputMaybe<StringFilter>;
  skill?: InputMaybe<SkillRelationFilter>;
  skillId?: InputMaybe<IntNullableFilter>;
  slug?: InputMaybe<StringFilter>;
  studentGameLogs?: InputMaybe<StudentGameLogListRelationFilter>;
  triggers?: InputMaybe<TriggerListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type GamePointTypeWhereUniqueInput = {
  id?: InputMaybe<Scalars["Int"]["input"]>;
  skillId?: InputMaybe<Scalars["Int"]["input"]>;
  slug?: InputMaybe<Scalars["String"]["input"]>;
};

export type GenerateUploadSignedUrlFileInput = {
  access?: InputMaybe<FileAccess>;
  contentType: Scalars["String"]["input"];
  key: Scalars["String"]["input"];
  name?: InputMaybe<Scalars["String"]["input"]>;
};

export type GenerateUploadSignedUrlInput = {
  files: Array<GenerateUploadSignedUrlFileInput>;
};

export type ImportStudentsResponse = {
  __typename?: "ImportStudentsResponse";
  data?: Maybe<Scalars["JSON"]["output"]>;
  message: Scalars["String"]["output"];
  success: Scalars["Boolean"]["output"];
};

export type IntFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars["Int"]["input"]>;
  divide?: InputMaybe<Scalars["Int"]["input"]>;
  increment?: InputMaybe<Scalars["Int"]["input"]>;
  multiply?: InputMaybe<Scalars["Int"]["input"]>;
  set?: InputMaybe<Scalars["Int"]["input"]>;
};

export type IntFilter = {
  equals?: InputMaybe<Scalars["Int"]["input"]>;
  gt?: InputMaybe<Scalars["Int"]["input"]>;
  gte?: InputMaybe<Scalars["Int"]["input"]>;
  in?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  lt?: InputMaybe<Scalars["Int"]["input"]>;
  lte?: InputMaybe<Scalars["Int"]["input"]>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars["Int"]["input"]>>;
};

export type IntNullableFilter = {
  equals?: InputMaybe<Scalars["Int"]["input"]>;
  gt?: InputMaybe<Scalars["Int"]["input"]>;
  gte?: InputMaybe<Scalars["Int"]["input"]>;
  in?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  lt?: InputMaybe<Scalars["Int"]["input"]>;
  lte?: InputMaybe<Scalars["Int"]["input"]>;
  not?: InputMaybe<NestedIntNullableFilter>;
  notIn?: InputMaybe<Array<Scalars["Int"]["input"]>>;
};

export type IntWithAggregatesFilter = {
  _avg?: InputMaybe<NestedFloatFilter>;
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedIntFilter>;
  _min?: InputMaybe<NestedIntFilter>;
  _sum?: InputMaybe<NestedIntFilter>;
  equals?: InputMaybe<Scalars["Int"]["input"]>;
  gt?: InputMaybe<Scalars["Int"]["input"]>;
  gte?: InputMaybe<Scalars["Int"]["input"]>;
  in?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  lt?: InputMaybe<Scalars["Int"]["input"]>;
  lte?: InputMaybe<Scalars["Int"]["input"]>;
  not?: InputMaybe<NestedIntWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars["Int"]["input"]>>;
};

export type JsonFilter = {
  array_contains?: InputMaybe<Scalars["JSON"]["input"]>;
  array_ends_with?: InputMaybe<Scalars["JSON"]["input"]>;
  array_starts_with?: InputMaybe<Scalars["JSON"]["input"]>;
  equals?: InputMaybe<Scalars["JSON"]["input"]>;
  gt?: InputMaybe<Scalars["JSON"]["input"]>;
  gte?: InputMaybe<Scalars["JSON"]["input"]>;
  lt?: InputMaybe<Scalars["JSON"]["input"]>;
  lte?: InputMaybe<Scalars["JSON"]["input"]>;
  not?: InputMaybe<Scalars["JSON"]["input"]>;
  path?: InputMaybe<Array<Scalars["String"]["input"]>>;
  string_contains?: InputMaybe<Scalars["String"]["input"]>;
  string_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  string_starts_with?: InputMaybe<Scalars["String"]["input"]>;
};

export type JsonNullableFilter = {
  array_contains?: InputMaybe<Scalars["JSON"]["input"]>;
  array_ends_with?: InputMaybe<Scalars["JSON"]["input"]>;
  array_starts_with?: InputMaybe<Scalars["JSON"]["input"]>;
  equals?: InputMaybe<Scalars["JSON"]["input"]>;
  gt?: InputMaybe<Scalars["JSON"]["input"]>;
  gte?: InputMaybe<Scalars["JSON"]["input"]>;
  lt?: InputMaybe<Scalars["JSON"]["input"]>;
  lte?: InputMaybe<Scalars["JSON"]["input"]>;
  not?: InputMaybe<Scalars["JSON"]["input"]>;
  path?: InputMaybe<Array<Scalars["String"]["input"]>>;
  string_contains?: InputMaybe<Scalars["String"]["input"]>;
  string_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  string_starts_with?: InputMaybe<Scalars["String"]["input"]>;
};

export type JsonWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedJsonFilter>;
  _min?: InputMaybe<NestedJsonFilter>;
  array_contains?: InputMaybe<Scalars["JSON"]["input"]>;
  array_ends_with?: InputMaybe<Scalars["JSON"]["input"]>;
  array_starts_with?: InputMaybe<Scalars["JSON"]["input"]>;
  equals?: InputMaybe<Scalars["JSON"]["input"]>;
  gt?: InputMaybe<Scalars["JSON"]["input"]>;
  gte?: InputMaybe<Scalars["JSON"]["input"]>;
  lt?: InputMaybe<Scalars["JSON"]["input"]>;
  lte?: InputMaybe<Scalars["JSON"]["input"]>;
  not?: InputMaybe<Scalars["JSON"]["input"]>;
  path?: InputMaybe<Array<Scalars["String"]["input"]>>;
  string_contains?: InputMaybe<Scalars["String"]["input"]>;
  string_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  string_starts_with?: InputMaybe<Scalars["String"]["input"]>;
};

export type Level = {
  __typename?: "Level";
  _count?: Maybe<LevelCount>;
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["Int"]["output"];
  image?: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  sequence: Scalars["Int"]["output"];
  slug: Scalars["String"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export type LevelAvgAggregate = {
  __typename?: "LevelAvgAggregate";
  id?: Maybe<Scalars["Float"]["output"]>;
  sequence?: Maybe<Scalars["Float"]["output"]>;
};

export type LevelCount = {
  __typename?: "LevelCount";
  triggers: Scalars["Int"]["output"];
};


export type LevelCountTriggersArgs = {
  where?: InputMaybe<TriggerWhereInput>;
};

export type LevelCountAggregate = {
  __typename?: "LevelCountAggregate";
  _all: Scalars["Int"]["output"];
  createdAt: Scalars["Int"]["output"];
  id: Scalars["Int"]["output"];
  image: Scalars["Int"]["output"];
  name: Scalars["Int"]["output"];
  sequence: Scalars["Int"]["output"];
  slug: Scalars["Int"]["output"];
  updatedAt: Scalars["Int"]["output"];
};

export type LevelCreateInput = {
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  image?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  sequence?: InputMaybe<Scalars["Int"]["input"]>;
  slug: Scalars["String"]["input"];
  triggers?: InputMaybe<TriggerCreateNestedManyWithoutLevelInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type LevelCreateNestedOneWithoutTriggersInput = {
  connect?: InputMaybe<LevelWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LevelCreateOrConnectWithoutTriggersInput>;
  create?: InputMaybe<LevelCreateWithoutTriggersInput>;
};

export type LevelCreateOrConnectWithoutTriggersInput = {
  create: LevelCreateWithoutTriggersInput;
  where: LevelWhereUniqueInput;
};

export type LevelCreateWithoutTriggersInput = {
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  image?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  sequence?: InputMaybe<Scalars["Int"]["input"]>;
  slug: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type LevelMaxAggregate = {
  __typename?: "LevelMaxAggregate";
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  image?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  sequence?: Maybe<Scalars["Int"]["output"]>;
  slug?: Maybe<Scalars["String"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type LevelMinAggregate = {
  __typename?: "LevelMinAggregate";
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  image?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  sequence?: Maybe<Scalars["Int"]["output"]>;
  slug?: Maybe<Scalars["String"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type LevelOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  image?: InputMaybe<SortOrderInput>;
  name?: InputMaybe<SortOrder>;
  sequence?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  triggers?: InputMaybe<TriggerOrderByRelationAggregateInput>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type LevelRelationFilter = {
  is?: InputMaybe<LevelWhereInput>;
  isNot?: InputMaybe<LevelWhereInput>;
};

export enum LevelScalarFieldEnum {
  CREATEDAT = "createdAt",
  ID = "id",
  IMAGE = "image",
  NAME = "name",
  SEQUENCE = "sequence",
  SLUG = "slug",
  UPDATEDAT = "updatedAt"
}

export type LevelSumAggregate = {
  __typename?: "LevelSumAggregate";
  id?: Maybe<Scalars["Int"]["output"]>;
  sequence?: Maybe<Scalars["Int"]["output"]>;
};

export type LevelUpdateInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  sequence?: InputMaybe<IntFieldUpdateOperationsInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  triggers?: InputMaybe<TriggerUpdateManyWithoutLevelNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type LevelUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  sequence?: InputMaybe<IntFieldUpdateOperationsInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type LevelUpdateOneWithoutTriggersNestedInput = {
  connect?: InputMaybe<LevelWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LevelCreateOrConnectWithoutTriggersInput>;
  create?: InputMaybe<LevelCreateWithoutTriggersInput>;
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  update?: InputMaybe<LevelUpdateWithoutTriggersInput>;
  upsert?: InputMaybe<LevelUpsertWithoutTriggersInput>;
};

export type LevelUpdateWithoutTriggersInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  sequence?: InputMaybe<IntFieldUpdateOperationsInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type LevelUpsertWithoutTriggersInput = {
  create: LevelCreateWithoutTriggersInput;
  update: LevelUpdateWithoutTriggersInput;
};

export type LevelWhereInput = {
  AND?: InputMaybe<Array<LevelWhereInput>>;
  NOT?: InputMaybe<Array<LevelWhereInput>>;
  OR?: InputMaybe<Array<LevelWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  image?: InputMaybe<StringNullableFilter>;
  name?: InputMaybe<StringFilter>;
  sequence?: InputMaybe<IntFilter>;
  slug?: InputMaybe<StringFilter>;
  triggers?: InputMaybe<TriggerListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type LevelWhereUniqueInput = {
  id?: InputMaybe<Scalars["Int"]["input"]>;
  slug?: InputMaybe<Scalars["String"]["input"]>;
};

export enum LogAccessType {
  PRIVATE = "PRIVATE",
  PUBLIC = "PUBLIC"
}

export type LogInInput = {
  idToken: Scalars["String"]["input"];
};

export type LogInResponse = {
  __typename?: "LogInResponse";
  authToken?: Maybe<Scalars["String"]["output"]>;
  expiresAt?: Maybe<Scalars["DateTime"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  message: Scalars["String"]["output"];
  rules?: Maybe<Scalars["JSON"]["output"]>;
  success: Scalars["Boolean"]["output"];
  userId?: Maybe<Scalars["String"]["output"]>;
};

export type MeResponse = {
  __typename?: "MeResponse";
  address1?: Maybe<Scalars["String"]["output"]>;
  address2?: Maybe<Scalars["String"]["output"]>;
  allowStandardUpdate: Scalars["Boolean"]["output"];
  city?: Maybe<Scalars["String"]["output"]>;
  coins: Scalars["Int"]["output"];
  country?: Maybe<Scalars["String"]["output"]>;
  dailyActivityAnswered: Scalars["Boolean"]["output"];
  dailyChallengeAnswered?: Maybe<Scalars["String"]["output"]>;
  displayName?: Maybe<Scalars["String"]["output"]>;
  dob?: Maybe<Scalars["String"]["output"]>;
  email?: Maybe<Scalars["String"]["output"]>;
  firstName: Scalars["String"]["output"];
  id: Scalars["Int"]["output"];
  isHomeschooler: Scalars["Boolean"]["output"];
  isMeta: Scalars["Boolean"]["output"];
  lastName: Scalars["String"]["output"];
  level: Scalars["Float"]["output"];
  levelPercentage: Scalars["Float"]["output"];
  message?: Maybe<Scalars["String"]["output"]>;
  notificationToken: Scalars["Boolean"]["output"];
  phoneNumber: Scalars["String"]["output"];
  postcode?: Maybe<Scalars["String"]["output"]>;
  profileImage?: Maybe<Scalars["String"]["output"]>;
  role: Scalars["String"]["output"];
  rules: Scalars["JSON"]["output"];
  schoolText?: Maybe<Scalars["String"]["output"]>;
  standard: Standard;
  stars: Scalars["Int"]["output"];
  state?: Maybe<Scalars["String"]["output"]>;
  success: Scalars["Boolean"]["output"];
  totalXps: Scalars["Int"]["output"];
  userId?: Maybe<Scalars["String"]["output"]>;
};

export type Mutation = {
  __typename?: "Mutation";
  activityQuestionSubmit: ValidateActivityQuestionSubmitOutput;
  adminLogIn: LogInResponse;
  answerStudentDailyActivity?: Maybe<StudentDailyActivity>;
  applyPromoCode: ApplyPromoCodeOutput;
  createActivity: Activity;
  createActivityBanner: ActivityBanner;
  createBadge: Badge;
  createComment: Comment;
  createDailyActivity: DailyActivity;
  createGamePointType: GamePointType;
  createLevel: Level;
  createManyActivityQuestion: AffectedRowsOutput;
  createManyPromoCodeToPromoCodeGroup: AffectedRowsOutput;
  createOrder: Order;
  createPrivacyPolicy: PrivacyPolicy;
  createProduct: Product;
  createProductBanner: ProductBanner;
  createPromoCode: PromoCode;
  createPromoCodeGroup: PromoCodeGroup;
  createPromoCodeHistory: PromoCodeHistory;
  createPromoCodeToPromoCodeGroup: PromoCodeToPromoCodeGroup;
  createReferralCode: ReferralCode;
  createReferralCodeHistory: ReferralCodeHistory;
  createReport: Report;
  createSamskara: Samskara;
  createSamskaraCategory: SamskaraCategory;
  createSchool: School;
  createSchoolUpdate: SchoolUpdate;
  createSkill: Skill;
  createStandard: Standard;
  createStory: Story;
  createStoryActivity: StoryActivity;
  createStoryBanner: StoryBanner;
  createStoryTag: StoryTag;
  createStudent: Student;
  createStudentGameLog: StudentGameLog;
  createStudentRecord: StudentRecord;
  createTag: Tag;
  createTrigger: Trigger;
  createUser: User;
  createUserLog: UserLog;
  createWorkshop: Workshop;
  createWorkshopRegistration: WorkshopRegistration;
  decryptUserToken?: Maybe<User>;
  deleteActivity?: Maybe<Activity>;
  deleteActivityBanner?: Maybe<ActivityBanner>;
  deleteBadge?: Maybe<Badge>;
  deleteComment: Comment;
  deleteDailyActivity?: Maybe<DailyActivity>;
  deleteGamePointType?: Maybe<GamePointType>;
  deleteLevel?: Maybe<Level>;
  deleteManyActivityQuestion: AffectedRowsOutput;
  deleteManyLevel: AffectedRowsOutput;
  deleteManyOrder: AffectedRowsOutput;
  deleteManyPromoCodeToPromoCodeGroup: AffectedRowsOutput;
  deleteManyStoryTag: AffectedRowsOutput;
  deleteOrder?: Maybe<Order>;
  deleteProduct?: Maybe<Product>;
  deleteProductBanner?: Maybe<ProductBanner>;
  deletePromoCode?: Maybe<PromoCode>;
  deletePromoCodeGroup?: Maybe<PromoCodeGroup>;
  deletePromoCodeHistory?: Maybe<PromoCodeHistory>;
  deletePromoCodeToPromoCodeGroup?: Maybe<PromoCodeToPromoCodeGroup>;
  deleteReferralCode?: Maybe<ReferralCode>;
  deleteReferralCodeHistory?: Maybe<ReferralCodeHistory>;
  deleteReport?: Maybe<Report>;
  deleteSamskara?: Maybe<Samskara>;
  deleteSchool?: Maybe<School>;
  deleteSchoolUpdate?: Maybe<SchoolUpdate>;
  deleteSkill?: Maybe<Skill>;
  deleteStandard?: Maybe<Standard>;
  deleteStory?: Maybe<Story>;
  deleteStoryActivity?: Maybe<StoryActivity>;
  deleteStoryBanner?: Maybe<StoryBanner>;
  deleteStoryTag?: Maybe<StoryTag>;
  deleteStudent?: Maybe<Student>;
  deleteStudentGameLog?: Maybe<StudentGameLog>;
  deleteStudentGameLogs: AffectedRowsOutput;
  deleteTag?: Maybe<Tag>;
  deleteTrigger?: Maybe<Trigger>;
  deleteUser?: Maybe<User>;
  deleteWorkshop?: Maybe<Workshop>;
  deleteWorkshopRegistration?: Maybe<WorkshopRegistration>;
  generateUploadSignedUrl: Array<File>;
  importStudents?: Maybe<ImportStudentsResponse>;
  logIn: LogInResponse;
  logout: RefreshTokenResponse;
  privacyPolicies: PrivacyPolicyUpdate;
  refreshToken: RefreshTokenResponse;
  signUp: LogInResponse;
  studentAssignBadge?: Maybe<StudentAssignBadgeResponse>;
  studentCreateOrder?: Maybe<StudentCreateOrderOutput>;
  studentDeregisterWorkshop?: Maybe<StudentRegisterWorkshopOutput>;
  studentRegisterWorkshop?: Maybe<StudentRegisterWorkshopOutput>;
  studentRemoveBadge?: Maybe<StudentAssignBadgeResponse>;
  unlockStudentActivity?: Maybe<Activity>;
  unlockStudentStoryActivity?: Maybe<StoryActivity>;
  updateActivity?: Maybe<Activity>;
  updateActivityBanner?: Maybe<ActivityBanner>;
  updateBadge?: Maybe<Badge>;
  updateComment?: Maybe<Comment>;
  updateComments: AffectedRowsOutput;
  updateDailyActivity?: Maybe<DailyActivity>;
  updateGamePointType?: Maybe<GamePointType>;
  updateLevel?: Maybe<Level>;
  updateManyLevel: AffectedRowsOutput;
  updateManyOrder: AffectedRowsOutput;
  updateManyPromoCodeToPromoCodeGroup: AffectedRowsOutput;
  updateManyWorkshopRegistration: AffectedRowsOutput;
  updateOneActivityQuestion?: Maybe<ActivityQuestion>;
  updateOrder?: Maybe<Order>;
  updateProduct?: Maybe<Product>;
  updateProductBanner?: Maybe<ProductBanner>;
  updateProfile?: Maybe<Student>;
  updatePromoCode?: Maybe<PromoCode>;
  updatePromoCodeGroup?: Maybe<PromoCodeGroup>;
  updatePromoCodeHistory?: Maybe<PromoCodeHistory>;
  updatePromoCodeToPromoCodeGroup?: Maybe<PromoCodeToPromoCodeGroup>;
  updateReferralCode?: Maybe<ReferralCode>;
  updateReferralCodeHistory?: Maybe<ReferralCodeHistory>;
  updateReport?: Maybe<Report>;
  updateSamskara?: Maybe<Samskara>;
  updateSamskaraCategory?: Maybe<SamskaraCategory>;
  updateSchool?: Maybe<School>;
  updateSchoolUpdate?: Maybe<SchoolUpdate>;
  updateSkill?: Maybe<Skill>;
  updateStandard?: Maybe<Standard>;
  updateStory?: Maybe<Story>;
  updateStoryActivity?: Maybe<StoryActivity>;
  updateStoryBanner?: Maybe<StoryBanner>;
  updateStoryTag?: Maybe<StoryTag>;
  updateStudent?: Maybe<Student>;
  updateStudentMeta?: Maybe<UpdateStudentMetaResponse>;
  updateTag?: Maybe<Tag>;
  updateTrigger?: Maybe<Trigger>;
  updateUser?: Maybe<User>;
  updateWorkshop?: Maybe<Workshop>;
  updateWorkshopRegistration?: Maybe<WorkshopRegistration>;
  upsertPromoCodeToPromoCodeGroup: PromoCodeToPromoCodeGroup;
  validateActivityQuestionAnswer: ValidateActivityQuestionAnswerOutput;
  verifySignUpCode: VerifyUserResponse;
  verifyUser: VerifyUserResponse;
};


export type MutationActivityQuestionSubmitArgs = {
  activity: ActivityWhereUniqueInput;
  data: Array<ValidateActivityQuestionSubmitInput>;
};


export type MutationAdminLogInArgs = {
  data: LogInInput;
};


export type MutationAnswerStudentDailyActivityArgs = {
  data: AnswerStudentDailyActivityInput;
  where: DailyActivityWhereUniqueInput;
};


export type MutationApplyPromoCodeArgs = {
  code: Scalars["String"]["input"];
  studentId?: InputMaybe<Scalars["Int"]["input"]>;
};


export type MutationCreateActivityArgs = {
  data: ActivityCreateInput;
};


export type MutationCreateActivityBannerArgs = {
  data: ActivityBannerCreateInput;
};


export type MutationCreateBadgeArgs = {
  data: BadgeCreateInput;
};


export type MutationCreateCommentArgs = {
  data: StudentCreateCommentInput;
};


export type MutationCreateDailyActivityArgs = {
  data: DailyActivityCreateInput;
};


export type MutationCreateGamePointTypeArgs = {
  data: GamePointTypeCreateInput;
};


export type MutationCreateLevelArgs = {
  data: LevelCreateInput;
};


export type MutationCreateManyActivityQuestionArgs = {
  data: Array<ActivityQuestionCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};


export type MutationCreateManyPromoCodeToPromoCodeGroupArgs = {
  data: Array<PromoCodeToPromoCodeGroupCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};


export type MutationCreateOrderArgs = {
  data: OrderCreateInput;
};


export type MutationCreatePrivacyPolicyArgs = {
  data: PrivacyPolicyCreateInput;
};


export type MutationCreateProductArgs = {
  data: ProductCreateInput;
};


export type MutationCreateProductBannerArgs = {
  data: ProductBannerCreateInput;
};


export type MutationCreatePromoCodeArgs = {
  data: PromoCodeCreateInput;
};


export type MutationCreatePromoCodeGroupArgs = {
  data: PromoCodeGroupCreateInput;
};


export type MutationCreatePromoCodeHistoryArgs = {
  data: PromoCodeHistoryCreateInput;
};


export type MutationCreatePromoCodeToPromoCodeGroupArgs = {
  data: PromoCodeToPromoCodeGroupCreateInput;
};


export type MutationCreateReferralCodeArgs = {
  data: ReferralCodeCreateInput;
};


export type MutationCreateReferralCodeHistoryArgs = {
  data: ReferralCodeHistoryCreateInput;
};


export type MutationCreateReportArgs = {
  data: ReportCreateInput;
};


export type MutationCreateSamskaraArgs = {
  data: SamskaraCreateInput;
};


export type MutationCreateSamskaraCategoryArgs = {
  data: SamskaraCategoryCreateInput;
};


export type MutationCreateSchoolArgs = {
  data: SchoolCreateInput;
};


export type MutationCreateSchoolUpdateArgs = {
  data: SchoolUpdateCreateInput;
};


export type MutationCreateSkillArgs = {
  data: SkillCreateInput;
};


export type MutationCreateStandardArgs = {
  data: StandardCreateInput;
};


export type MutationCreateStoryArgs = {
  data: StoryCreateInput;
};


export type MutationCreateStoryActivityArgs = {
  data: StoryActivityCreateInput;
};


export type MutationCreateStoryBannerArgs = {
  data: StoryBannerCreateInput;
};


export type MutationCreateStoryTagArgs = {
  data: StoryTagCreateInput;
};


export type MutationCreateStudentArgs = {
  data: StudentCreateInput;
};


export type MutationCreateStudentGameLogArgs = {
  data: StudentGameLogCreateInput;
};


export type MutationCreateStudentRecordArgs = {
  data: StudentRecordCreateInput;
};


export type MutationCreateTagArgs = {
  data: TagCreateInput;
};


export type MutationCreateTriggerArgs = {
  data: TriggerCreateInput;
};


export type MutationCreateUserArgs = {
  data: UserCreateInput;
};


export type MutationCreateUserLogArgs = {
  data: UserLogCreateInput;
};


export type MutationCreateWorkshopArgs = {
  data: WorkshopCreateInput;
};


export type MutationCreateWorkshopRegistrationArgs = {
  data: WorkshopRegistrationCreateInput;
};


export type MutationDecryptUserTokenArgs = {
  data: DecryptUserTokenInput;
};


export type MutationDeleteActivityArgs = {
  where: ActivityWhereUniqueInput;
};


export type MutationDeleteActivityBannerArgs = {
  where: ActivityBannerWhereUniqueInput;
};


export type MutationDeleteBadgeArgs = {
  where: BadgeWhereUniqueInput;
};


export type MutationDeleteCommentArgs = {
  where: CommentWhereUniqueInput;
};


export type MutationDeleteDailyActivityArgs = {
  where: DailyActivityWhereUniqueInput;
};


export type MutationDeleteGamePointTypeArgs = {
  where: GamePointTypeWhereUniqueInput;
};


export type MutationDeleteLevelArgs = {
  where: LevelWhereUniqueInput;
};


export type MutationDeleteManyActivityQuestionArgs = {
  where?: InputMaybe<ActivityQuestionWhereInput>;
};


export type MutationDeleteManyLevelArgs = {
  where?: InputMaybe<LevelWhereInput>;
};


export type MutationDeleteManyOrderArgs = {
  where?: InputMaybe<OrderWhereInput>;
};


export type MutationDeleteManyPromoCodeToPromoCodeGroupArgs = {
  where?: InputMaybe<PromoCodeToPromoCodeGroupWhereInput>;
};


export type MutationDeleteManyStoryTagArgs = {
  where?: InputMaybe<StoryTagWhereInput>;
};


export type MutationDeleteOrderArgs = {
  where: OrderWhereUniqueInput;
};


export type MutationDeleteProductArgs = {
  where: ProductWhereUniqueInput;
};


export type MutationDeleteProductBannerArgs = {
  where: ProductBannerWhereUniqueInput;
};


export type MutationDeletePromoCodeArgs = {
  where: PromoCodeWhereUniqueInput;
};


export type MutationDeletePromoCodeGroupArgs = {
  where: PromoCodeGroupWhereUniqueInput;
};


export type MutationDeletePromoCodeHistoryArgs = {
  where: PromoCodeHistoryWhereUniqueInput;
};


export type MutationDeletePromoCodeToPromoCodeGroupArgs = {
  where: PromoCodeToPromoCodeGroupWhereUniqueInput;
};


export type MutationDeleteReferralCodeArgs = {
  where: ReferralCodeWhereUniqueInput;
};


export type MutationDeleteReferralCodeHistoryArgs = {
  where: ReferralCodeHistoryWhereUniqueInput;
};


export type MutationDeleteReportArgs = {
  where: ReportWhereUniqueInput;
};


export type MutationDeleteSamskaraArgs = {
  where: SamskaraWhereUniqueInput;
};


export type MutationDeleteSchoolArgs = {
  where: SchoolWhereUniqueInput;
};


export type MutationDeleteSchoolUpdateArgs = {
  where: SchoolUpdateWhereUniqueInput;
};


export type MutationDeleteSkillArgs = {
  where: SkillWhereUniqueInput;
};


export type MutationDeleteStandardArgs = {
  where: StandardWhereUniqueInput;
};


export type MutationDeleteStoryArgs = {
  where: StoryWhereUniqueInput;
};


export type MutationDeleteStoryActivityArgs = {
  where: StoryActivityWhereUniqueInput;
};


export type MutationDeleteStoryBannerArgs = {
  where: StoryBannerWhereUniqueInput;
};


export type MutationDeleteStoryTagArgs = {
  where: StoryTagWhereUniqueInput;
};


export type MutationDeleteStudentArgs = {
  where: StudentWhereUniqueInput;
};


export type MutationDeleteStudentGameLogArgs = {
  where: StudentGameLogWhereUniqueInput;
};


export type MutationDeleteStudentGameLogsArgs = {
  where?: InputMaybe<StudentGameLogWhereInput>;
};


export type MutationDeleteTagArgs = {
  where: TagWhereUniqueInput;
};


export type MutationDeleteTriggerArgs = {
  where: TriggerWhereUniqueInput;
};


export type MutationDeleteUserArgs = {
  where: UserWhereUniqueInput;
};


export type MutationDeleteWorkshopArgs = {
  where: WorkshopWhereUniqueInput;
};


export type MutationDeleteWorkshopRegistrationArgs = {
  where: WorkshopRegistrationWhereUniqueInput;
};


export type MutationGenerateUploadSignedUrlArgs = {
  data: GenerateUploadSignedUrlInput;
};


export type MutationImportStudentsArgs = {
  file: Scalars["Upload"]["input"];
};


export type MutationLogInArgs = {
  data: LogInInput;
};


export type MutationPrivacyPoliciesArgs = {
  data: PrivacyPolicyUpdateCreateInput;
};


export type MutationSignUpArgs = {
  data: SignUpInput;
};


export type MutationStudentAssignBadgeArgs = {
  data: StudentAssignBadgeInput;
};


export type MutationStudentCreateOrderArgs = {
  data: StudentCreateOrderProductInput;
};


export type MutationStudentDeregisterWorkshopArgs = {
  where: StudentWorkshopUniqueInput;
};


export type MutationStudentRegisterWorkshopArgs = {
  where: StudentWorkshopUniqueInput;
};


export type MutationStudentRemoveBadgeArgs = {
  where: StudentBadgeWhereUniqueInput;
};


export type MutationUnlockStudentActivityArgs = {
  where: ActivityWhereUniqueInput;
};


export type MutationUnlockStudentStoryActivityArgs = {
  where: StoryActivityWhereUniqueInput;
};


export type MutationUpdateActivityArgs = {
  data: ActivityUpdateInput;
  where: ActivityWhereUniqueInput;
};


export type MutationUpdateActivityBannerArgs = {
  data: ActivityBannerUpdateInput;
  where: ActivityBannerWhereUniqueInput;
};


export type MutationUpdateBadgeArgs = {
  data: BadgeUpdateInput;
  where: BadgeWhereUniqueInput;
};


export type MutationUpdateCommentArgs = {
  data: CommentUpdateInput;
  where: CommentWhereUniqueInput;
};


export type MutationUpdateCommentsArgs = {
  data: CommentUpdateManyMutationInput;
  where?: InputMaybe<CommentWhereInput>;
};


export type MutationUpdateDailyActivityArgs = {
  data: DailyActivityUpdateInput;
  where: DailyActivityWhereUniqueInput;
};


export type MutationUpdateGamePointTypeArgs = {
  data: GamePointTypeUpdateInput;
  where: GamePointTypeWhereUniqueInput;
};


export type MutationUpdateLevelArgs = {
  data: LevelUpdateInput;
  where: LevelWhereUniqueInput;
};


export type MutationUpdateManyLevelArgs = {
  data: LevelUpdateManyMutationInput;
  where?: InputMaybe<LevelWhereInput>;
};


export type MutationUpdateManyOrderArgs = {
  data: OrderUpdateManyMutationInput;
  where?: InputMaybe<OrderWhereInput>;
};


export type MutationUpdateManyPromoCodeToPromoCodeGroupArgs = {
  data: PromoCodeToPromoCodeGroupUpdateManyMutationInput;
  where?: InputMaybe<PromoCodeToPromoCodeGroupWhereInput>;
};


export type MutationUpdateManyWorkshopRegistrationArgs = {
  data: WorkshopRegistrationUpdateManyMutationInput;
  where?: InputMaybe<WorkshopRegistrationWhereInput>;
};


export type MutationUpdateOneActivityQuestionArgs = {
  data: ActivityQuestionUpdateInput;
  where: ActivityQuestionWhereUniqueInput;
};


export type MutationUpdateOrderArgs = {
  data: OrderUpdateInput;
  where: OrderWhereUniqueInput;
};


export type MutationUpdateProductArgs = {
  data: ProductUpdateInput;
  where: ProductWhereUniqueInput;
};


export type MutationUpdateProductBannerArgs = {
  data: ProductBannerUpdateInput;
  where: ProductBannerWhereUniqueInput;
};


export type MutationUpdateProfileArgs = {
  data: UpdateProfileInput;
};


export type MutationUpdatePromoCodeArgs = {
  data: PromoCodeUpdateInput;
  where: PromoCodeWhereUniqueInput;
};


export type MutationUpdatePromoCodeGroupArgs = {
  data: PromoCodeGroupUpdateInput;
  where: PromoCodeGroupWhereUniqueInput;
};


export type MutationUpdatePromoCodeHistoryArgs = {
  data: PromoCodeHistoryUpdateInput;
  where: PromoCodeHistoryWhereUniqueInput;
};


export type MutationUpdatePromoCodeToPromoCodeGroupArgs = {
  data: PromoCodeToPromoCodeGroupUpdateInput;
  where: PromoCodeToPromoCodeGroupWhereUniqueInput;
};


export type MutationUpdateReferralCodeArgs = {
  data: ReferralCodeUpdateInput;
  where: ReferralCodeWhereUniqueInput;
};


export type MutationUpdateReferralCodeHistoryArgs = {
  data: ReferralCodeHistoryUpdateInput;
  where: ReferralCodeHistoryWhereUniqueInput;
};


export type MutationUpdateReportArgs = {
  data: ReportUpdateInput;
  where: ReportWhereUniqueInput;
};


export type MutationUpdateSamskaraArgs = {
  data: SamskaraUpdateInput;
  where: SamskaraWhereUniqueInput;
};


export type MutationUpdateSamskaraCategoryArgs = {
  data: SamskaraCategoryUpdateInput;
  where: SamskaraCategoryWhereUniqueInput;
};


export type MutationUpdateSchoolArgs = {
  data: SchoolUpdateInput;
  where: SchoolWhereUniqueInput;
};


export type MutationUpdateSchoolUpdateArgs = {
  data: SchoolUpdateUpdateInput;
  where: SchoolUpdateWhereUniqueInput;
};


export type MutationUpdateSkillArgs = {
  data: SkillUpdateInput;
  where: SkillWhereUniqueInput;
};


export type MutationUpdateStandardArgs = {
  data: StandardUpdateInput;
  where: StandardWhereUniqueInput;
};


export type MutationUpdateStoryArgs = {
  data: StoryUpdateInput;
  where: StoryWhereUniqueInput;
};


export type MutationUpdateStoryActivityArgs = {
  data: StoryActivityUpdateInput;
  where: StoryActivityWhereUniqueInput;
};


export type MutationUpdateStoryBannerArgs = {
  data: StoryBannerUpdateInput;
  where: StoryBannerWhereUniqueInput;
};


export type MutationUpdateStoryTagArgs = {
  data: StoryTagUpdateInput;
  where: StoryTagWhereUniqueInput;
};


export type MutationUpdateStudentArgs = {
  data: StudentUpdateInput;
  where: StudentWhereUniqueInput;
};


export type MutationUpdateStudentMetaArgs = {
  data: UpdateStudentMetaInput;
};


export type MutationUpdateTagArgs = {
  data: TagUpdateInput;
  where: TagWhereUniqueInput;
};


export type MutationUpdateTriggerArgs = {
  data: TriggerUpdateInput;
  where: TriggerWhereUniqueInput;
};


export type MutationUpdateUserArgs = {
  data: UserUpdateInput;
  where: UserWhereUniqueInput;
};


export type MutationUpdateWorkshopArgs = {
  data: WorkshopUpdateInput;
  where: WorkshopWhereUniqueInput;
};


export type MutationUpdateWorkshopRegistrationArgs = {
  data: WorkshopRegistrationUpdateInput;
  where: WorkshopRegistrationWhereUniqueInput;
};


export type MutationUpsertPromoCodeToPromoCodeGroupArgs = {
  create: PromoCodeToPromoCodeGroupCreateInput;
  update: PromoCodeToPromoCodeGroupUpdateInput;
  where: PromoCodeToPromoCodeGroupWhereUniqueInput;
};


export type MutationValidateActivityQuestionAnswerArgs = {
  data: ValidateActivityQuestionAnswerInput;
  where: ActivityQuestionWhereUniqueInput;
};


export type MutationVerifySignUpCodeArgs = {
  data: VerifySignUpCodeInput;
};


export type MutationVerifyUserArgs = {
  data: VerifyUserInput;
};

export type NestedBoolFilter = {
  equals?: InputMaybe<Scalars["Boolean"]["input"]>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type NestedDateTimeFilter = {
  equals?: InputMaybe<Scalars["DateTime"]["input"]>;
  gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  in?: InputMaybe<Array<Scalars["DateTime"]["input"]>>;
  lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars["DateTime"]["input"]>>;
};

export type NestedDateTimeNullableFilter = {
  equals?: InputMaybe<Scalars["DateTime"]["input"]>;
  gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  in?: InputMaybe<Array<Scalars["DateTime"]["input"]>>;
  lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars["DateTime"]["input"]>>;
};

export type NestedDateTimeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedDateTimeFilter>;
  _min?: InputMaybe<NestedDateTimeFilter>;
  equals?: InputMaybe<Scalars["DateTime"]["input"]>;
  gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  in?: InputMaybe<Array<Scalars["DateTime"]["input"]>>;
  lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  not?: InputMaybe<NestedDateTimeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars["DateTime"]["input"]>>;
};

export type NestedEnumActivityStatusFilter = {
  equals?: InputMaybe<ActivityStatus>;
  in?: InputMaybe<Array<ActivityStatus>>;
  not?: InputMaybe<NestedEnumActivityStatusFilter>;
  notIn?: InputMaybe<Array<ActivityStatus>>;
};

export type NestedEnumCommentStatusNullableFilter = {
  equals?: InputMaybe<CommentStatus>;
  in?: InputMaybe<Array<CommentStatus>>;
  not?: InputMaybe<NestedEnumCommentStatusNullableFilter>;
  notIn?: InputMaybe<Array<CommentStatus>>;
};

export type NestedEnumLogAccessTypeFilter = {
  equals?: InputMaybe<LogAccessType>;
  in?: InputMaybe<Array<LogAccessType>>;
  not?: InputMaybe<NestedEnumLogAccessTypeFilter>;
  notIn?: InputMaybe<Array<LogAccessType>>;
};

export type NestedEnumOrderStatusFilter = {
  equals?: InputMaybe<OrderStatus>;
  in?: InputMaybe<Array<OrderStatus>>;
  not?: InputMaybe<NestedEnumOrderStatusFilter>;
  notIn?: InputMaybe<Array<OrderStatus>>;
};

export type NestedEnumPaymentMethodFilter = {
  equals?: InputMaybe<PaymentMethod>;
  in?: InputMaybe<Array<PaymentMethod>>;
  not?: InputMaybe<NestedEnumPaymentMethodFilter>;
  notIn?: InputMaybe<Array<PaymentMethod>>;
};

export type NestedEnumRoleFilter = {
  equals?: InputMaybe<Role>;
  in?: InputMaybe<Array<Role>>;
  not?: InputMaybe<NestedEnumRoleFilter>;
  notIn?: InputMaybe<Array<Role>>;
};

export type NestedEnumSkillTypeFilter = {
  equals?: InputMaybe<SkillType>;
  in?: InputMaybe<Array<SkillType>>;
  not?: InputMaybe<NestedEnumSkillTypeFilter>;
  notIn?: InputMaybe<Array<SkillType>>;
};

export type NestedEnumStatusFilter = {
  equals?: InputMaybe<Status>;
  in?: InputMaybe<Array<Status>>;
  not?: InputMaybe<NestedEnumStatusFilter>;
  notIn?: InputMaybe<Array<Status>>;
};

export type NestedEnumTriggerTypeFilter = {
  equals?: InputMaybe<TriggerType>;
  in?: InputMaybe<Array<TriggerType>>;
  not?: InputMaybe<NestedEnumTriggerTypeFilter>;
  notIn?: InputMaybe<Array<TriggerType>>;
};

export type NestedEnumWorkshopStatusFilter = {
  equals?: InputMaybe<WorkshopStatus>;
  in?: InputMaybe<Array<WorkshopStatus>>;
  not?: InputMaybe<NestedEnumWorkshopStatusFilter>;
  notIn?: InputMaybe<Array<WorkshopStatus>>;
};

export type NestedFloatFilter = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  gt?: InputMaybe<Scalars["Float"]["input"]>;
  gte?: InputMaybe<Scalars["Float"]["input"]>;
  in?: InputMaybe<Array<Scalars["Float"]["input"]>>;
  lt?: InputMaybe<Scalars["Float"]["input"]>;
  lte?: InputMaybe<Scalars["Float"]["input"]>;
  not?: InputMaybe<NestedFloatFilter>;
  notIn?: InputMaybe<Array<Scalars["Float"]["input"]>>;
};

export type NestedIntFilter = {
  equals?: InputMaybe<Scalars["Int"]["input"]>;
  gt?: InputMaybe<Scalars["Int"]["input"]>;
  gte?: InputMaybe<Scalars["Int"]["input"]>;
  in?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  lt?: InputMaybe<Scalars["Int"]["input"]>;
  lte?: InputMaybe<Scalars["Int"]["input"]>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars["Int"]["input"]>>;
};

export type NestedIntNullableFilter = {
  equals?: InputMaybe<Scalars["Int"]["input"]>;
  gt?: InputMaybe<Scalars["Int"]["input"]>;
  gte?: InputMaybe<Scalars["Int"]["input"]>;
  in?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  lt?: InputMaybe<Scalars["Int"]["input"]>;
  lte?: InputMaybe<Scalars["Int"]["input"]>;
  not?: InputMaybe<NestedIntNullableFilter>;
  notIn?: InputMaybe<Array<Scalars["Int"]["input"]>>;
};

export type NestedIntWithAggregatesFilter = {
  _avg?: InputMaybe<NestedFloatFilter>;
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedIntFilter>;
  _min?: InputMaybe<NestedIntFilter>;
  _sum?: InputMaybe<NestedIntFilter>;
  equals?: InputMaybe<Scalars["Int"]["input"]>;
  gt?: InputMaybe<Scalars["Int"]["input"]>;
  gte?: InputMaybe<Scalars["Int"]["input"]>;
  in?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  lt?: InputMaybe<Scalars["Int"]["input"]>;
  lte?: InputMaybe<Scalars["Int"]["input"]>;
  not?: InputMaybe<NestedIntWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars["Int"]["input"]>>;
};

export type NestedJsonFilter = {
  array_contains?: InputMaybe<Scalars["JSON"]["input"]>;
  array_ends_with?: InputMaybe<Scalars["JSON"]["input"]>;
  array_starts_with?: InputMaybe<Scalars["JSON"]["input"]>;
  equals?: InputMaybe<Scalars["JSON"]["input"]>;
  gt?: InputMaybe<Scalars["JSON"]["input"]>;
  gte?: InputMaybe<Scalars["JSON"]["input"]>;
  lt?: InputMaybe<Scalars["JSON"]["input"]>;
  lte?: InputMaybe<Scalars["JSON"]["input"]>;
  not?: InputMaybe<Scalars["JSON"]["input"]>;
  path?: InputMaybe<Array<Scalars["String"]["input"]>>;
  string_contains?: InputMaybe<Scalars["String"]["input"]>;
  string_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  string_starts_with?: InputMaybe<Scalars["String"]["input"]>;
};

export type NestedStringFilter = {
  contains?: InputMaybe<Scalars["String"]["input"]>;
  endsWith?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  gt?: InputMaybe<Scalars["String"]["input"]>;
  gte?: InputMaybe<Scalars["String"]["input"]>;
  in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  lt?: InputMaybe<Scalars["String"]["input"]>;
  lte?: InputMaybe<Scalars["String"]["input"]>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars["String"]["input"]>>;
  startsWith?: InputMaybe<Scalars["String"]["input"]>;
};

export type NestedStringNullableFilter = {
  contains?: InputMaybe<Scalars["String"]["input"]>;
  endsWith?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  gt?: InputMaybe<Scalars["String"]["input"]>;
  gte?: InputMaybe<Scalars["String"]["input"]>;
  in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  lt?: InputMaybe<Scalars["String"]["input"]>;
  lte?: InputMaybe<Scalars["String"]["input"]>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars["String"]["input"]>>;
  startsWith?: InputMaybe<Scalars["String"]["input"]>;
};

export type NestedStringNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedStringNullableFilter>;
  _min?: InputMaybe<NestedStringNullableFilter>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  endsWith?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  gt?: InputMaybe<Scalars["String"]["input"]>;
  gte?: InputMaybe<Scalars["String"]["input"]>;
  in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  lt?: InputMaybe<Scalars["String"]["input"]>;
  lte?: InputMaybe<Scalars["String"]["input"]>;
  not?: InputMaybe<NestedStringNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars["String"]["input"]>>;
  startsWith?: InputMaybe<Scalars["String"]["input"]>;
};

export type NestedStringWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedStringFilter>;
  _min?: InputMaybe<NestedStringFilter>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  endsWith?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  gt?: InputMaybe<Scalars["String"]["input"]>;
  gte?: InputMaybe<Scalars["String"]["input"]>;
  in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  lt?: InputMaybe<Scalars["String"]["input"]>;
  lte?: InputMaybe<Scalars["String"]["input"]>;
  not?: InputMaybe<NestedStringWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars["String"]["input"]>>;
  startsWith?: InputMaybe<Scalars["String"]["input"]>;
};

export type NullableDateTimeFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type NullableEnumCommentStatusFieldUpdateOperationsInput = {
  set?: InputMaybe<CommentStatus>;
};

export type NullableIntFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars["Int"]["input"]>;
  divide?: InputMaybe<Scalars["Int"]["input"]>;
  increment?: InputMaybe<Scalars["Int"]["input"]>;
  multiply?: InputMaybe<Scalars["Int"]["input"]>;
  set?: InputMaybe<Scalars["Int"]["input"]>;
};

export type NullableStringFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars["String"]["input"]>;
};

export enum NullsOrder {
  FIRST = "first",
  LAST = "last"
}

export type Order = {
  __typename?: "Order";
  billingAddress1?: Maybe<Scalars["String"]["output"]>;
  billingAddress2?: Maybe<Scalars["String"]["output"]>;
  billingCity?: Maybe<Scalars["String"]["output"]>;
  billingCountry?: Maybe<Scalars["String"]["output"]>;
  billingFirstName?: Maybe<Scalars["String"]["output"]>;
  billingLastName?: Maybe<Scalars["String"]["output"]>;
  billingPhone?: Maybe<Scalars["String"]["output"]>;
  billingPostcode?: Maybe<Scalars["String"]["output"]>;
  billingState?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  customer: Student;
  customerId: Scalars["Int"]["output"];
  customerIpAddress?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["Int"]["output"];
  paymentMethod: PaymentMethod;
  paymentStatus: Scalars["String"]["output"];
  product: Product;
  productId: Scalars["Int"]["output"];
  shippingAddress1?: Maybe<Scalars["String"]["output"]>;
  shippingAddress2?: Maybe<Scalars["String"]["output"]>;
  shippingCity?: Maybe<Scalars["String"]["output"]>;
  shippingCountry?: Maybe<Scalars["String"]["output"]>;
  shippingFirstName?: Maybe<Scalars["String"]["output"]>;
  shippingLastName?: Maybe<Scalars["String"]["output"]>;
  shippingPhone?: Maybe<Scalars["String"]["output"]>;
  shippingPostcode?: Maybe<Scalars["String"]["output"]>;
  shippingState?: Maybe<Scalars["String"]["output"]>;
  status: OrderStatus;
  total: Scalars["Int"]["output"];
  transactionId?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
  workshopRegistration?: Maybe<WorkshopRegistration>;
};

export type OrderAvgAggregate = {
  __typename?: "OrderAvgAggregate";
  customerId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  productId?: Maybe<Scalars["Float"]["output"]>;
  total?: Maybe<Scalars["Float"]["output"]>;
};

export type OrderCountAggregate = {
  __typename?: "OrderCountAggregate";
  _all: Scalars["Int"]["output"];
  billingAddress1: Scalars["Int"]["output"];
  billingAddress2: Scalars["Int"]["output"];
  billingCity: Scalars["Int"]["output"];
  billingCountry: Scalars["Int"]["output"];
  billingFirstName: Scalars["Int"]["output"];
  billingLastName: Scalars["Int"]["output"];
  billingPhone: Scalars["Int"]["output"];
  billingPostcode: Scalars["Int"]["output"];
  billingState: Scalars["Int"]["output"];
  createdAt: Scalars["Int"]["output"];
  customerId: Scalars["Int"]["output"];
  customerIpAddress: Scalars["Int"]["output"];
  id: Scalars["Int"]["output"];
  paymentMethod: Scalars["Int"]["output"];
  paymentStatus: Scalars["Int"]["output"];
  productId: Scalars["Int"]["output"];
  shippingAddress1: Scalars["Int"]["output"];
  shippingAddress2: Scalars["Int"]["output"];
  shippingCity: Scalars["Int"]["output"];
  shippingCountry: Scalars["Int"]["output"];
  shippingFirstName: Scalars["Int"]["output"];
  shippingLastName: Scalars["Int"]["output"];
  shippingPhone: Scalars["Int"]["output"];
  shippingPostcode: Scalars["Int"]["output"];
  shippingState: Scalars["Int"]["output"];
  status: Scalars["Int"]["output"];
  total: Scalars["Int"]["output"];
  transactionId: Scalars["Int"]["output"];
  updatedAt: Scalars["Int"]["output"];
};

export type OrderCreateInput = {
  billingAddress1?: InputMaybe<Scalars["String"]["input"]>;
  billingAddress2?: InputMaybe<Scalars["String"]["input"]>;
  billingCity?: InputMaybe<Scalars["String"]["input"]>;
  billingCountry?: InputMaybe<Scalars["String"]["input"]>;
  billingFirstName?: InputMaybe<Scalars["String"]["input"]>;
  billingLastName?: InputMaybe<Scalars["String"]["input"]>;
  billingPhone?: InputMaybe<Scalars["String"]["input"]>;
  billingPostcode?: InputMaybe<Scalars["String"]["input"]>;
  billingState?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  customer: StudentCreateNestedOneWithoutOrdersInput;
  customerIpAddress?: InputMaybe<Scalars["String"]["input"]>;
  paymentMethod?: InputMaybe<PaymentMethod>;
  paymentStatus: Scalars["String"]["input"];
  product: ProductCreateNestedOneWithoutOrdersInput;
  shippingAddress1?: InputMaybe<Scalars["String"]["input"]>;
  shippingAddress2?: InputMaybe<Scalars["String"]["input"]>;
  shippingCity?: InputMaybe<Scalars["String"]["input"]>;
  shippingCountry?: InputMaybe<Scalars["String"]["input"]>;
  shippingFirstName?: InputMaybe<Scalars["String"]["input"]>;
  shippingLastName?: InputMaybe<Scalars["String"]["input"]>;
  shippingPhone?: InputMaybe<Scalars["String"]["input"]>;
  shippingPostcode?: InputMaybe<Scalars["String"]["input"]>;
  shippingState?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<OrderStatus>;
  total?: InputMaybe<Scalars["Int"]["input"]>;
  transactionId?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  workshopRegistration?: InputMaybe<WorkshopRegistrationCreateNestedOneWithoutOrderInput>;
};

export type OrderCreateManyCustomerInput = {
  billingAddress1?: InputMaybe<Scalars["String"]["input"]>;
  billingAddress2?: InputMaybe<Scalars["String"]["input"]>;
  billingCity?: InputMaybe<Scalars["String"]["input"]>;
  billingCountry?: InputMaybe<Scalars["String"]["input"]>;
  billingFirstName?: InputMaybe<Scalars["String"]["input"]>;
  billingLastName?: InputMaybe<Scalars["String"]["input"]>;
  billingPhone?: InputMaybe<Scalars["String"]["input"]>;
  billingPostcode?: InputMaybe<Scalars["String"]["input"]>;
  billingState?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  customerIpAddress?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  paymentMethod?: InputMaybe<PaymentMethod>;
  paymentStatus: Scalars["String"]["input"];
  productId: Scalars["Int"]["input"];
  shippingAddress1?: InputMaybe<Scalars["String"]["input"]>;
  shippingAddress2?: InputMaybe<Scalars["String"]["input"]>;
  shippingCity?: InputMaybe<Scalars["String"]["input"]>;
  shippingCountry?: InputMaybe<Scalars["String"]["input"]>;
  shippingFirstName?: InputMaybe<Scalars["String"]["input"]>;
  shippingLastName?: InputMaybe<Scalars["String"]["input"]>;
  shippingPhone?: InputMaybe<Scalars["String"]["input"]>;
  shippingPostcode?: InputMaybe<Scalars["String"]["input"]>;
  shippingState?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<OrderStatus>;
  total?: InputMaybe<Scalars["Int"]["input"]>;
  transactionId?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type OrderCreateManyCustomerInputEnvelope = {
  data: Array<OrderCreateManyCustomerInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type OrderCreateManyProductInput = {
  billingAddress1?: InputMaybe<Scalars["String"]["input"]>;
  billingAddress2?: InputMaybe<Scalars["String"]["input"]>;
  billingCity?: InputMaybe<Scalars["String"]["input"]>;
  billingCountry?: InputMaybe<Scalars["String"]["input"]>;
  billingFirstName?: InputMaybe<Scalars["String"]["input"]>;
  billingLastName?: InputMaybe<Scalars["String"]["input"]>;
  billingPhone?: InputMaybe<Scalars["String"]["input"]>;
  billingPostcode?: InputMaybe<Scalars["String"]["input"]>;
  billingState?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  customerId: Scalars["Int"]["input"];
  customerIpAddress?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  paymentMethod?: InputMaybe<PaymentMethod>;
  paymentStatus: Scalars["String"]["input"];
  shippingAddress1?: InputMaybe<Scalars["String"]["input"]>;
  shippingAddress2?: InputMaybe<Scalars["String"]["input"]>;
  shippingCity?: InputMaybe<Scalars["String"]["input"]>;
  shippingCountry?: InputMaybe<Scalars["String"]["input"]>;
  shippingFirstName?: InputMaybe<Scalars["String"]["input"]>;
  shippingLastName?: InputMaybe<Scalars["String"]["input"]>;
  shippingPhone?: InputMaybe<Scalars["String"]["input"]>;
  shippingPostcode?: InputMaybe<Scalars["String"]["input"]>;
  shippingState?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<OrderStatus>;
  total?: InputMaybe<Scalars["Int"]["input"]>;
  transactionId?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type OrderCreateManyProductInputEnvelope = {
  data: Array<OrderCreateManyProductInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type OrderCreateNestedManyWithoutCustomerInput = {
  connect?: InputMaybe<Array<OrderWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OrderCreateOrConnectWithoutCustomerInput>>;
  create?: InputMaybe<Array<OrderCreateWithoutCustomerInput>>;
  createMany?: InputMaybe<OrderCreateManyCustomerInputEnvelope>;
};

export type OrderCreateNestedManyWithoutProductInput = {
  connect?: InputMaybe<Array<OrderWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OrderCreateOrConnectWithoutProductInput>>;
  create?: InputMaybe<Array<OrderCreateWithoutProductInput>>;
  createMany?: InputMaybe<OrderCreateManyProductInputEnvelope>;
};

export type OrderCreateNestedOneWithoutWorkshopRegistrationInput = {
  connect?: InputMaybe<OrderWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrderCreateOrConnectWithoutWorkshopRegistrationInput>;
  create?: InputMaybe<OrderCreateWithoutWorkshopRegistrationInput>;
};

export type OrderCreateOrConnectWithoutCustomerInput = {
  create: OrderCreateWithoutCustomerInput;
  where: OrderWhereUniqueInput;
};

export type OrderCreateOrConnectWithoutProductInput = {
  create: OrderCreateWithoutProductInput;
  where: OrderWhereUniqueInput;
};

export type OrderCreateOrConnectWithoutWorkshopRegistrationInput = {
  create: OrderCreateWithoutWorkshopRegistrationInput;
  where: OrderWhereUniqueInput;
};

export type OrderCreateWithoutCustomerInput = {
  billingAddress1?: InputMaybe<Scalars["String"]["input"]>;
  billingAddress2?: InputMaybe<Scalars["String"]["input"]>;
  billingCity?: InputMaybe<Scalars["String"]["input"]>;
  billingCountry?: InputMaybe<Scalars["String"]["input"]>;
  billingFirstName?: InputMaybe<Scalars["String"]["input"]>;
  billingLastName?: InputMaybe<Scalars["String"]["input"]>;
  billingPhone?: InputMaybe<Scalars["String"]["input"]>;
  billingPostcode?: InputMaybe<Scalars["String"]["input"]>;
  billingState?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  customerIpAddress?: InputMaybe<Scalars["String"]["input"]>;
  paymentMethod?: InputMaybe<PaymentMethod>;
  paymentStatus: Scalars["String"]["input"];
  product: ProductCreateNestedOneWithoutOrdersInput;
  shippingAddress1?: InputMaybe<Scalars["String"]["input"]>;
  shippingAddress2?: InputMaybe<Scalars["String"]["input"]>;
  shippingCity?: InputMaybe<Scalars["String"]["input"]>;
  shippingCountry?: InputMaybe<Scalars["String"]["input"]>;
  shippingFirstName?: InputMaybe<Scalars["String"]["input"]>;
  shippingLastName?: InputMaybe<Scalars["String"]["input"]>;
  shippingPhone?: InputMaybe<Scalars["String"]["input"]>;
  shippingPostcode?: InputMaybe<Scalars["String"]["input"]>;
  shippingState?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<OrderStatus>;
  total?: InputMaybe<Scalars["Int"]["input"]>;
  transactionId?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  workshopRegistration?: InputMaybe<WorkshopRegistrationCreateNestedOneWithoutOrderInput>;
};

export type OrderCreateWithoutProductInput = {
  billingAddress1?: InputMaybe<Scalars["String"]["input"]>;
  billingAddress2?: InputMaybe<Scalars["String"]["input"]>;
  billingCity?: InputMaybe<Scalars["String"]["input"]>;
  billingCountry?: InputMaybe<Scalars["String"]["input"]>;
  billingFirstName?: InputMaybe<Scalars["String"]["input"]>;
  billingLastName?: InputMaybe<Scalars["String"]["input"]>;
  billingPhone?: InputMaybe<Scalars["String"]["input"]>;
  billingPostcode?: InputMaybe<Scalars["String"]["input"]>;
  billingState?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  customer: StudentCreateNestedOneWithoutOrdersInput;
  customerIpAddress?: InputMaybe<Scalars["String"]["input"]>;
  paymentMethod?: InputMaybe<PaymentMethod>;
  paymentStatus: Scalars["String"]["input"];
  shippingAddress1?: InputMaybe<Scalars["String"]["input"]>;
  shippingAddress2?: InputMaybe<Scalars["String"]["input"]>;
  shippingCity?: InputMaybe<Scalars["String"]["input"]>;
  shippingCountry?: InputMaybe<Scalars["String"]["input"]>;
  shippingFirstName?: InputMaybe<Scalars["String"]["input"]>;
  shippingLastName?: InputMaybe<Scalars["String"]["input"]>;
  shippingPhone?: InputMaybe<Scalars["String"]["input"]>;
  shippingPostcode?: InputMaybe<Scalars["String"]["input"]>;
  shippingState?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<OrderStatus>;
  total?: InputMaybe<Scalars["Int"]["input"]>;
  transactionId?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  workshopRegistration?: InputMaybe<WorkshopRegistrationCreateNestedOneWithoutOrderInput>;
};

export type OrderCreateWithoutWorkshopRegistrationInput = {
  billingAddress1?: InputMaybe<Scalars["String"]["input"]>;
  billingAddress2?: InputMaybe<Scalars["String"]["input"]>;
  billingCity?: InputMaybe<Scalars["String"]["input"]>;
  billingCountry?: InputMaybe<Scalars["String"]["input"]>;
  billingFirstName?: InputMaybe<Scalars["String"]["input"]>;
  billingLastName?: InputMaybe<Scalars["String"]["input"]>;
  billingPhone?: InputMaybe<Scalars["String"]["input"]>;
  billingPostcode?: InputMaybe<Scalars["String"]["input"]>;
  billingState?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  customer: StudentCreateNestedOneWithoutOrdersInput;
  customerIpAddress?: InputMaybe<Scalars["String"]["input"]>;
  paymentMethod?: InputMaybe<PaymentMethod>;
  paymentStatus: Scalars["String"]["input"];
  product: ProductCreateNestedOneWithoutOrdersInput;
  shippingAddress1?: InputMaybe<Scalars["String"]["input"]>;
  shippingAddress2?: InputMaybe<Scalars["String"]["input"]>;
  shippingCity?: InputMaybe<Scalars["String"]["input"]>;
  shippingCountry?: InputMaybe<Scalars["String"]["input"]>;
  shippingFirstName?: InputMaybe<Scalars["String"]["input"]>;
  shippingLastName?: InputMaybe<Scalars["String"]["input"]>;
  shippingPhone?: InputMaybe<Scalars["String"]["input"]>;
  shippingPostcode?: InputMaybe<Scalars["String"]["input"]>;
  shippingState?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<OrderStatus>;
  total?: InputMaybe<Scalars["Int"]["input"]>;
  transactionId?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type OrderListRelationFilter = {
  every?: InputMaybe<OrderWhereInput>;
  none?: InputMaybe<OrderWhereInput>;
  some?: InputMaybe<OrderWhereInput>;
};

export type OrderMaxAggregate = {
  __typename?: "OrderMaxAggregate";
  billingAddress1?: Maybe<Scalars["String"]["output"]>;
  billingAddress2?: Maybe<Scalars["String"]["output"]>;
  billingCity?: Maybe<Scalars["String"]["output"]>;
  billingCountry?: Maybe<Scalars["String"]["output"]>;
  billingFirstName?: Maybe<Scalars["String"]["output"]>;
  billingLastName?: Maybe<Scalars["String"]["output"]>;
  billingPhone?: Maybe<Scalars["String"]["output"]>;
  billingPostcode?: Maybe<Scalars["String"]["output"]>;
  billingState?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  customerId?: Maybe<Scalars["Int"]["output"]>;
  customerIpAddress?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  paymentMethod?: Maybe<PaymentMethod>;
  paymentStatus?: Maybe<Scalars["String"]["output"]>;
  productId?: Maybe<Scalars["Int"]["output"]>;
  shippingAddress1?: Maybe<Scalars["String"]["output"]>;
  shippingAddress2?: Maybe<Scalars["String"]["output"]>;
  shippingCity?: Maybe<Scalars["String"]["output"]>;
  shippingCountry?: Maybe<Scalars["String"]["output"]>;
  shippingFirstName?: Maybe<Scalars["String"]["output"]>;
  shippingLastName?: Maybe<Scalars["String"]["output"]>;
  shippingPhone?: Maybe<Scalars["String"]["output"]>;
  shippingPostcode?: Maybe<Scalars["String"]["output"]>;
  shippingState?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<OrderStatus>;
  total?: Maybe<Scalars["Int"]["output"]>;
  transactionId?: Maybe<Scalars["String"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type OrderMinAggregate = {
  __typename?: "OrderMinAggregate";
  billingAddress1?: Maybe<Scalars["String"]["output"]>;
  billingAddress2?: Maybe<Scalars["String"]["output"]>;
  billingCity?: Maybe<Scalars["String"]["output"]>;
  billingCountry?: Maybe<Scalars["String"]["output"]>;
  billingFirstName?: Maybe<Scalars["String"]["output"]>;
  billingLastName?: Maybe<Scalars["String"]["output"]>;
  billingPhone?: Maybe<Scalars["String"]["output"]>;
  billingPostcode?: Maybe<Scalars["String"]["output"]>;
  billingState?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  customerId?: Maybe<Scalars["Int"]["output"]>;
  customerIpAddress?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  paymentMethod?: Maybe<PaymentMethod>;
  paymentStatus?: Maybe<Scalars["String"]["output"]>;
  productId?: Maybe<Scalars["Int"]["output"]>;
  shippingAddress1?: Maybe<Scalars["String"]["output"]>;
  shippingAddress2?: Maybe<Scalars["String"]["output"]>;
  shippingCity?: Maybe<Scalars["String"]["output"]>;
  shippingCountry?: Maybe<Scalars["String"]["output"]>;
  shippingFirstName?: Maybe<Scalars["String"]["output"]>;
  shippingLastName?: Maybe<Scalars["String"]["output"]>;
  shippingPhone?: Maybe<Scalars["String"]["output"]>;
  shippingPostcode?: Maybe<Scalars["String"]["output"]>;
  shippingState?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<OrderStatus>;
  total?: Maybe<Scalars["Int"]["output"]>;
  transactionId?: Maybe<Scalars["String"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type OrderOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type OrderOrderByWithRelationInput = {
  billingAddress1?: InputMaybe<SortOrderInput>;
  billingAddress2?: InputMaybe<SortOrderInput>;
  billingCity?: InputMaybe<SortOrderInput>;
  billingCountry?: InputMaybe<SortOrderInput>;
  billingFirstName?: InputMaybe<SortOrderInput>;
  billingLastName?: InputMaybe<SortOrderInput>;
  billingPhone?: InputMaybe<SortOrderInput>;
  billingPostcode?: InputMaybe<SortOrderInput>;
  billingState?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  customer?: InputMaybe<StudentOrderByWithRelationInput>;
  customerId?: InputMaybe<SortOrder>;
  customerIpAddress?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  paymentMethod?: InputMaybe<SortOrder>;
  paymentStatus?: InputMaybe<SortOrder>;
  product?: InputMaybe<ProductOrderByWithRelationInput>;
  productId?: InputMaybe<SortOrder>;
  shippingAddress1?: InputMaybe<SortOrderInput>;
  shippingAddress2?: InputMaybe<SortOrderInput>;
  shippingCity?: InputMaybe<SortOrderInput>;
  shippingCountry?: InputMaybe<SortOrderInput>;
  shippingFirstName?: InputMaybe<SortOrderInput>;
  shippingLastName?: InputMaybe<SortOrderInput>;
  shippingPhone?: InputMaybe<SortOrderInput>;
  shippingPostcode?: InputMaybe<SortOrderInput>;
  shippingState?: InputMaybe<SortOrderInput>;
  status?: InputMaybe<SortOrder>;
  total?: InputMaybe<SortOrder>;
  transactionId?: InputMaybe<SortOrderInput>;
  updatedAt?: InputMaybe<SortOrder>;
  workshopRegistration?: InputMaybe<WorkshopRegistrationOrderByWithRelationInput>;
};

export type OrderRelationFilter = {
  is?: InputMaybe<OrderWhereInput>;
  isNot?: InputMaybe<OrderWhereInput>;
};

export enum OrderScalarFieldEnum {
  BILLINGADDRESS1 = "billingAddress1",
  BILLINGADDRESS2 = "billingAddress2",
  BILLINGCITY = "billingCity",
  BILLINGCOUNTRY = "billingCountry",
  BILLINGFIRSTNAME = "billingFirstName",
  BILLINGLASTNAME = "billingLastName",
  BILLINGPHONE = "billingPhone",
  BILLINGPOSTCODE = "billingPostcode",
  BILLINGSTATE = "billingState",
  CREATEDAT = "createdAt",
  CUSTOMERID = "customerId",
  CUSTOMERIPADDRESS = "customerIpAddress",
  ID = "id",
  PAYMENTMETHOD = "paymentMethod",
  PAYMENTSTATUS = "paymentStatus",
  PRODUCTID = "productId",
  SHIPPINGADDRESS1 = "shippingAddress1",
  SHIPPINGADDRESS2 = "shippingAddress2",
  SHIPPINGCITY = "shippingCity",
  SHIPPINGCOUNTRY = "shippingCountry",
  SHIPPINGFIRSTNAME = "shippingFirstName",
  SHIPPINGLASTNAME = "shippingLastName",
  SHIPPINGPHONE = "shippingPhone",
  SHIPPINGPOSTCODE = "shippingPostcode",
  SHIPPINGSTATE = "shippingState",
  STATUS = "status",
  TOTAL = "total",
  TRANSACTIONID = "transactionId",
  UPDATEDAT = "updatedAt"
}

export type OrderScalarWhereInput = {
  AND?: InputMaybe<Array<OrderScalarWhereInput>>;
  NOT?: InputMaybe<Array<OrderScalarWhereInput>>;
  OR?: InputMaybe<Array<OrderScalarWhereInput>>;
  billingAddress1?: InputMaybe<StringNullableFilter>;
  billingAddress2?: InputMaybe<StringNullableFilter>;
  billingCity?: InputMaybe<StringNullableFilter>;
  billingCountry?: InputMaybe<StringNullableFilter>;
  billingFirstName?: InputMaybe<StringNullableFilter>;
  billingLastName?: InputMaybe<StringNullableFilter>;
  billingPhone?: InputMaybe<StringNullableFilter>;
  billingPostcode?: InputMaybe<StringNullableFilter>;
  billingState?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  customerId?: InputMaybe<IntFilter>;
  customerIpAddress?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  paymentMethod?: InputMaybe<EnumPaymentMethodFilter>;
  paymentStatus?: InputMaybe<StringFilter>;
  productId?: InputMaybe<IntFilter>;
  shippingAddress1?: InputMaybe<StringNullableFilter>;
  shippingAddress2?: InputMaybe<StringNullableFilter>;
  shippingCity?: InputMaybe<StringNullableFilter>;
  shippingCountry?: InputMaybe<StringNullableFilter>;
  shippingFirstName?: InputMaybe<StringNullableFilter>;
  shippingLastName?: InputMaybe<StringNullableFilter>;
  shippingPhone?: InputMaybe<StringNullableFilter>;
  shippingPostcode?: InputMaybe<StringNullableFilter>;
  shippingState?: InputMaybe<StringNullableFilter>;
  status?: InputMaybe<EnumOrderStatusFilter>;
  total?: InputMaybe<IntFilter>;
  transactionId?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export enum OrderStatus {
  CANCELLED = "CANCELLED",
  DELIVERED = "DELIVERED",
  IGNORED = "IGNORED",
  INVOICE_CREATED = "INVOICE_CREATED",
  PENDING = "PENDING",
  SHIPPED = "SHIPPED"
}

export type OrderSumAggregate = {
  __typename?: "OrderSumAggregate";
  customerId?: Maybe<Scalars["Int"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  productId?: Maybe<Scalars["Int"]["output"]>;
  total?: Maybe<Scalars["Int"]["output"]>;
};

export type OrderUpdateInput = {
  billingAddress1?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  billingAddress2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  billingCity?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  billingCountry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  billingFirstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  billingLastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  billingPhone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  billingPostcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  billingState?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customer?: InputMaybe<StudentUpdateOneRequiredWithoutOrdersNestedInput>;
  customerIpAddress?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  paymentMethod?: InputMaybe<EnumPaymentMethodFieldUpdateOperationsInput>;
  paymentStatus?: InputMaybe<StringFieldUpdateOperationsInput>;
  product?: InputMaybe<ProductUpdateOneRequiredWithoutOrdersNestedInput>;
  shippingAddress1?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingAddress2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingCity?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingCountry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingFirstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingLastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingPhone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingPostcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingState?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumOrderStatusFieldUpdateOperationsInput>;
  total?: InputMaybe<IntFieldUpdateOperationsInput>;
  transactionId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  workshopRegistration?: InputMaybe<WorkshopRegistrationUpdateOneWithoutOrderNestedInput>;
};

export type OrderUpdateManyMutationInput = {
  billingAddress1?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  billingAddress2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  billingCity?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  billingCountry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  billingFirstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  billingLastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  billingPhone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  billingPostcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  billingState?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customerIpAddress?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  paymentMethod?: InputMaybe<EnumPaymentMethodFieldUpdateOperationsInput>;
  paymentStatus?: InputMaybe<StringFieldUpdateOperationsInput>;
  shippingAddress1?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingAddress2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingCity?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingCountry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingFirstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingLastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingPhone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingPostcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingState?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumOrderStatusFieldUpdateOperationsInput>;
  total?: InputMaybe<IntFieldUpdateOperationsInput>;
  transactionId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OrderUpdateManyWithWhereWithoutCustomerInput = {
  data: OrderUpdateManyMutationInput;
  where: OrderScalarWhereInput;
};

export type OrderUpdateManyWithWhereWithoutProductInput = {
  data: OrderUpdateManyMutationInput;
  where: OrderScalarWhereInput;
};

export type OrderUpdateManyWithoutCustomerNestedInput = {
  connect?: InputMaybe<Array<OrderWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OrderCreateOrConnectWithoutCustomerInput>>;
  create?: InputMaybe<Array<OrderCreateWithoutCustomerInput>>;
  createMany?: InputMaybe<OrderCreateManyCustomerInputEnvelope>;
  delete?: InputMaybe<Array<OrderWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<OrderScalarWhereInput>>;
  disconnect?: InputMaybe<Array<OrderWhereUniqueInput>>;
  set?: InputMaybe<Array<OrderWhereUniqueInput>>;
  update?: InputMaybe<Array<OrderUpdateWithWhereUniqueWithoutCustomerInput>>;
  updateMany?: InputMaybe<Array<OrderUpdateManyWithWhereWithoutCustomerInput>>;
  upsert?: InputMaybe<Array<OrderUpsertWithWhereUniqueWithoutCustomerInput>>;
};

export type OrderUpdateManyWithoutProductNestedInput = {
  connect?: InputMaybe<Array<OrderWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OrderCreateOrConnectWithoutProductInput>>;
  create?: InputMaybe<Array<OrderCreateWithoutProductInput>>;
  createMany?: InputMaybe<OrderCreateManyProductInputEnvelope>;
  delete?: InputMaybe<Array<OrderWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<OrderScalarWhereInput>>;
  disconnect?: InputMaybe<Array<OrderWhereUniqueInput>>;
  set?: InputMaybe<Array<OrderWhereUniqueInput>>;
  update?: InputMaybe<Array<OrderUpdateWithWhereUniqueWithoutProductInput>>;
  updateMany?: InputMaybe<Array<OrderUpdateManyWithWhereWithoutProductInput>>;
  upsert?: InputMaybe<Array<OrderUpsertWithWhereUniqueWithoutProductInput>>;
};

export type OrderUpdateOneWithoutWorkshopRegistrationNestedInput = {
  connect?: InputMaybe<OrderWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrderCreateOrConnectWithoutWorkshopRegistrationInput>;
  create?: InputMaybe<OrderCreateWithoutWorkshopRegistrationInput>;
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  update?: InputMaybe<OrderUpdateWithoutWorkshopRegistrationInput>;
  upsert?: InputMaybe<OrderUpsertWithoutWorkshopRegistrationInput>;
};

export type OrderUpdateWithWhereUniqueWithoutCustomerInput = {
  data: OrderUpdateWithoutCustomerInput;
  where: OrderWhereUniqueInput;
};

export type OrderUpdateWithWhereUniqueWithoutProductInput = {
  data: OrderUpdateWithoutProductInput;
  where: OrderWhereUniqueInput;
};

export type OrderUpdateWithoutCustomerInput = {
  billingAddress1?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  billingAddress2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  billingCity?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  billingCountry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  billingFirstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  billingLastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  billingPhone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  billingPostcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  billingState?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customerIpAddress?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  paymentMethod?: InputMaybe<EnumPaymentMethodFieldUpdateOperationsInput>;
  paymentStatus?: InputMaybe<StringFieldUpdateOperationsInput>;
  product?: InputMaybe<ProductUpdateOneRequiredWithoutOrdersNestedInput>;
  shippingAddress1?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingAddress2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingCity?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingCountry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingFirstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingLastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingPhone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingPostcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingState?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumOrderStatusFieldUpdateOperationsInput>;
  total?: InputMaybe<IntFieldUpdateOperationsInput>;
  transactionId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  workshopRegistration?: InputMaybe<WorkshopRegistrationUpdateOneWithoutOrderNestedInput>;
};

export type OrderUpdateWithoutProductInput = {
  billingAddress1?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  billingAddress2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  billingCity?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  billingCountry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  billingFirstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  billingLastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  billingPhone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  billingPostcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  billingState?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customer?: InputMaybe<StudentUpdateOneRequiredWithoutOrdersNestedInput>;
  customerIpAddress?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  paymentMethod?: InputMaybe<EnumPaymentMethodFieldUpdateOperationsInput>;
  paymentStatus?: InputMaybe<StringFieldUpdateOperationsInput>;
  shippingAddress1?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingAddress2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingCity?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingCountry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingFirstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingLastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingPhone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingPostcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingState?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumOrderStatusFieldUpdateOperationsInput>;
  total?: InputMaybe<IntFieldUpdateOperationsInput>;
  transactionId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  workshopRegistration?: InputMaybe<WorkshopRegistrationUpdateOneWithoutOrderNestedInput>;
};

export type OrderUpdateWithoutWorkshopRegistrationInput = {
  billingAddress1?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  billingAddress2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  billingCity?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  billingCountry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  billingFirstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  billingLastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  billingPhone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  billingPostcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  billingState?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customer?: InputMaybe<StudentUpdateOneRequiredWithoutOrdersNestedInput>;
  customerIpAddress?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  paymentMethod?: InputMaybe<EnumPaymentMethodFieldUpdateOperationsInput>;
  paymentStatus?: InputMaybe<StringFieldUpdateOperationsInput>;
  product?: InputMaybe<ProductUpdateOneRequiredWithoutOrdersNestedInput>;
  shippingAddress1?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingAddress2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingCity?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingCountry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingFirstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingLastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingPhone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingPostcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingState?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumOrderStatusFieldUpdateOperationsInput>;
  total?: InputMaybe<IntFieldUpdateOperationsInput>;
  transactionId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OrderUpsertWithWhereUniqueWithoutCustomerInput = {
  create: OrderCreateWithoutCustomerInput;
  update: OrderUpdateWithoutCustomerInput;
  where: OrderWhereUniqueInput;
};

export type OrderUpsertWithWhereUniqueWithoutProductInput = {
  create: OrderCreateWithoutProductInput;
  update: OrderUpdateWithoutProductInput;
  where: OrderWhereUniqueInput;
};

export type OrderUpsertWithoutWorkshopRegistrationInput = {
  create: OrderCreateWithoutWorkshopRegistrationInput;
  update: OrderUpdateWithoutWorkshopRegistrationInput;
};

export type OrderWhereInput = {
  AND?: InputMaybe<Array<OrderWhereInput>>;
  NOT?: InputMaybe<Array<OrderWhereInput>>;
  OR?: InputMaybe<Array<OrderWhereInput>>;
  billingAddress1?: InputMaybe<StringNullableFilter>;
  billingAddress2?: InputMaybe<StringNullableFilter>;
  billingCity?: InputMaybe<StringNullableFilter>;
  billingCountry?: InputMaybe<StringNullableFilter>;
  billingFirstName?: InputMaybe<StringNullableFilter>;
  billingLastName?: InputMaybe<StringNullableFilter>;
  billingPhone?: InputMaybe<StringNullableFilter>;
  billingPostcode?: InputMaybe<StringNullableFilter>;
  billingState?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  customer?: InputMaybe<StudentRelationFilter>;
  customerId?: InputMaybe<IntFilter>;
  customerIpAddress?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  paymentMethod?: InputMaybe<EnumPaymentMethodFilter>;
  paymentStatus?: InputMaybe<StringFilter>;
  product?: InputMaybe<ProductRelationFilter>;
  productId?: InputMaybe<IntFilter>;
  shippingAddress1?: InputMaybe<StringNullableFilter>;
  shippingAddress2?: InputMaybe<StringNullableFilter>;
  shippingCity?: InputMaybe<StringNullableFilter>;
  shippingCountry?: InputMaybe<StringNullableFilter>;
  shippingFirstName?: InputMaybe<StringNullableFilter>;
  shippingLastName?: InputMaybe<StringNullableFilter>;
  shippingPhone?: InputMaybe<StringNullableFilter>;
  shippingPostcode?: InputMaybe<StringNullableFilter>;
  shippingState?: InputMaybe<StringNullableFilter>;
  status?: InputMaybe<EnumOrderStatusFilter>;
  total?: InputMaybe<IntFilter>;
  transactionId?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  workshopRegistration?: InputMaybe<WorkshopRegistrationRelationFilter>;
};

export type OrderWhereUniqueInput = {
  id?: InputMaybe<Scalars["Int"]["input"]>;
};

export enum PaymentMethod {
  CARD = "CARD",
  COIN = "COIN"
}

export type PrivacyPolicy = {
  __typename?: "PrivacyPolicy";
  _count?: Maybe<PrivacyPolicyCount>;
  content: Scalars["String"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["Int"]["output"];
  privacyPolicyUpdates: Array<PrivacyPolicyUpdate>;
  required: Scalars["Boolean"]["output"];
};


export type PrivacyPolicyPrivacyPolicyUpdatesArgs = {
  cursor?: InputMaybe<PrivacyPolicyUpdateWhereUniqueInput>;
  distinct?: InputMaybe<Array<PrivacyPolicyUpdateScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PrivacyPolicyUpdateOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<PrivacyPolicyUpdateWhereInput>;
};

export type PrivacyPolicyAvgAggregate = {
  __typename?: "PrivacyPolicyAvgAggregate";
  id?: Maybe<Scalars["Float"]["output"]>;
};

export type PrivacyPolicyCount = {
  __typename?: "PrivacyPolicyCount";
  privacyPolicyUpdates: Scalars["Int"]["output"];
};


export type PrivacyPolicyCountPrivacyPolicyUpdatesArgs = {
  where?: InputMaybe<PrivacyPolicyUpdateWhereInput>;
};

export type PrivacyPolicyCountAggregate = {
  __typename?: "PrivacyPolicyCountAggregate";
  _all: Scalars["Int"]["output"];
  content: Scalars["Int"]["output"];
  createdAt: Scalars["Int"]["output"];
  id: Scalars["Int"]["output"];
  required: Scalars["Int"]["output"];
};

export type PrivacyPolicyCreateInput = {
  content: Scalars["String"]["input"];
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  privacyPolicyUpdates?: InputMaybe<PrivacyPolicyUpdateCreateNestedManyWithoutPrivacyPolicyInput>;
  required?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type PrivacyPolicyCreateNestedOneWithoutPrivacyPolicyUpdatesInput = {
  connect?: InputMaybe<PrivacyPolicyWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PrivacyPolicyCreateOrConnectWithoutPrivacyPolicyUpdatesInput>;
  create?: InputMaybe<PrivacyPolicyCreateWithoutPrivacyPolicyUpdatesInput>;
};

export type PrivacyPolicyCreateOrConnectWithoutPrivacyPolicyUpdatesInput = {
  create: PrivacyPolicyCreateWithoutPrivacyPolicyUpdatesInput;
  where: PrivacyPolicyWhereUniqueInput;
};

export type PrivacyPolicyCreateWithoutPrivacyPolicyUpdatesInput = {
  content: Scalars["String"]["input"];
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  required?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type PrivacyPolicyMaxAggregate = {
  __typename?: "PrivacyPolicyMaxAggregate";
  content?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  required?: Maybe<Scalars["Boolean"]["output"]>;
};

export type PrivacyPolicyMinAggregate = {
  __typename?: "PrivacyPolicyMinAggregate";
  content?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  required?: Maybe<Scalars["Boolean"]["output"]>;
};

export type PrivacyPolicyOrderByWithRelationInput = {
  content?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  privacyPolicyUpdates?: InputMaybe<PrivacyPolicyUpdateOrderByRelationAggregateInput>;
  required?: InputMaybe<SortOrder>;
};

export type PrivacyPolicyRelationFilter = {
  is?: InputMaybe<PrivacyPolicyWhereInput>;
  isNot?: InputMaybe<PrivacyPolicyWhereInput>;
};

export enum PrivacyPolicyScalarFieldEnum {
  CONTENT = "content",
  CREATEDAT = "createdAt",
  ID = "id",
  REQUIRED = "required"
}

export type PrivacyPolicySumAggregate = {
  __typename?: "PrivacyPolicySumAggregate";
  id?: Maybe<Scalars["Int"]["output"]>;
};

export type PrivacyPolicyUpdate = {
  __typename?: "PrivacyPolicyUpdate";
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["Int"]["output"];
  privacyPolicy: PrivacyPolicy;
  privacyPolicyId: Scalars["Int"]["output"];
  student: Student;
  studentId: Scalars["Int"]["output"];
};

export type PrivacyPolicyUpdateAvgAggregate = {
  __typename?: "PrivacyPolicyUpdateAvgAggregate";
  id?: Maybe<Scalars["Float"]["output"]>;
  privacyPolicyId?: Maybe<Scalars["Float"]["output"]>;
  studentId?: Maybe<Scalars["Float"]["output"]>;
};

export type PrivacyPolicyUpdateCountAggregate = {
  __typename?: "PrivacyPolicyUpdateCountAggregate";
  _all: Scalars["Int"]["output"];
  createdAt: Scalars["Int"]["output"];
  id: Scalars["Int"]["output"];
  privacyPolicyId: Scalars["Int"]["output"];
  studentId: Scalars["Int"]["output"];
};

export type PrivacyPolicyUpdateCreateInput = {
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  privacyPolicy: PrivacyPolicyCreateNestedOneWithoutPrivacyPolicyUpdatesInput;
  student: StudentCreateNestedOneWithoutPrivacyPolicyUpdatesInput;
};

export type PrivacyPolicyUpdateCreateManyPrivacyPolicyInput = {
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  studentId: Scalars["Int"]["input"];
};

export type PrivacyPolicyUpdateCreateManyPrivacyPolicyInputEnvelope = {
  data: Array<PrivacyPolicyUpdateCreateManyPrivacyPolicyInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type PrivacyPolicyUpdateCreateManyStudentInput = {
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  privacyPolicyId: Scalars["Int"]["input"];
};

export type PrivacyPolicyUpdateCreateManyStudentInputEnvelope = {
  data: Array<PrivacyPolicyUpdateCreateManyStudentInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type PrivacyPolicyUpdateCreateNestedManyWithoutPrivacyPolicyInput = {
  connect?: InputMaybe<Array<PrivacyPolicyUpdateWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PrivacyPolicyUpdateCreateOrConnectWithoutPrivacyPolicyInput>>;
  create?: InputMaybe<Array<PrivacyPolicyUpdateCreateWithoutPrivacyPolicyInput>>;
  createMany?: InputMaybe<PrivacyPolicyUpdateCreateManyPrivacyPolicyInputEnvelope>;
};

export type PrivacyPolicyUpdateCreateNestedManyWithoutStudentInput = {
  connect?: InputMaybe<Array<PrivacyPolicyUpdateWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PrivacyPolicyUpdateCreateOrConnectWithoutStudentInput>>;
  create?: InputMaybe<Array<PrivacyPolicyUpdateCreateWithoutStudentInput>>;
  createMany?: InputMaybe<PrivacyPolicyUpdateCreateManyStudentInputEnvelope>;
};

export type PrivacyPolicyUpdateCreateOrConnectWithoutPrivacyPolicyInput = {
  create: PrivacyPolicyUpdateCreateWithoutPrivacyPolicyInput;
  where: PrivacyPolicyUpdateWhereUniqueInput;
};

export type PrivacyPolicyUpdateCreateOrConnectWithoutStudentInput = {
  create: PrivacyPolicyUpdateCreateWithoutStudentInput;
  where: PrivacyPolicyUpdateWhereUniqueInput;
};

export type PrivacyPolicyUpdateCreateWithoutPrivacyPolicyInput = {
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  student: StudentCreateNestedOneWithoutPrivacyPolicyUpdatesInput;
};

export type PrivacyPolicyUpdateCreateWithoutStudentInput = {
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  privacyPolicy: PrivacyPolicyCreateNestedOneWithoutPrivacyPolicyUpdatesInput;
};

export type PrivacyPolicyUpdateListRelationFilter = {
  every?: InputMaybe<PrivacyPolicyUpdateWhereInput>;
  none?: InputMaybe<PrivacyPolicyUpdateWhereInput>;
  some?: InputMaybe<PrivacyPolicyUpdateWhereInput>;
};

export type PrivacyPolicyUpdateMaxAggregate = {
  __typename?: "PrivacyPolicyUpdateMaxAggregate";
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  privacyPolicyId?: Maybe<Scalars["Int"]["output"]>;
  studentId?: Maybe<Scalars["Int"]["output"]>;
};

export type PrivacyPolicyUpdateMinAggregate = {
  __typename?: "PrivacyPolicyUpdateMinAggregate";
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  privacyPolicyId?: Maybe<Scalars["Int"]["output"]>;
  studentId?: Maybe<Scalars["Int"]["output"]>;
};

export type PrivacyPolicyUpdateOneRequiredWithoutPrivacyPolicyUpdatesNestedInput = {
  connect?: InputMaybe<PrivacyPolicyWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PrivacyPolicyCreateOrConnectWithoutPrivacyPolicyUpdatesInput>;
  create?: InputMaybe<PrivacyPolicyCreateWithoutPrivacyPolicyUpdatesInput>;
  update?: InputMaybe<PrivacyPolicyUpdateWithoutPrivacyPolicyUpdatesInput>;
  upsert?: InputMaybe<PrivacyPolicyUpsertWithoutPrivacyPolicyUpdatesInput>;
};

export type PrivacyPolicyUpdateOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type PrivacyPolicyUpdateOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  privacyPolicy?: InputMaybe<PrivacyPolicyOrderByWithRelationInput>;
  privacyPolicyId?: InputMaybe<SortOrder>;
  student?: InputMaybe<StudentOrderByWithRelationInput>;
  studentId?: InputMaybe<SortOrder>;
};

export enum PrivacyPolicyUpdateScalarFieldEnum {
  CREATEDAT = "createdAt",
  ID = "id",
  PRIVACYPOLICYID = "privacyPolicyId",
  STUDENTID = "studentId"
}

export type PrivacyPolicyUpdateScalarWhereInput = {
  AND?: InputMaybe<Array<PrivacyPolicyUpdateScalarWhereInput>>;
  NOT?: InputMaybe<Array<PrivacyPolicyUpdateScalarWhereInput>>;
  OR?: InputMaybe<Array<PrivacyPolicyUpdateScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  privacyPolicyId?: InputMaybe<IntFilter>;
  studentId?: InputMaybe<IntFilter>;
};

export type PrivacyPolicyUpdateSumAggregate = {
  __typename?: "PrivacyPolicyUpdateSumAggregate";
  id?: Maybe<Scalars["Int"]["output"]>;
  privacyPolicyId?: Maybe<Scalars["Int"]["output"]>;
  studentId?: Maybe<Scalars["Int"]["output"]>;
};

export type PrivacyPolicyUpdateUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type PrivacyPolicyUpdateUpdateManyWithWhereWithoutStudentInput = {
  data: PrivacyPolicyUpdateUpdateManyMutationInput;
  where: PrivacyPolicyUpdateScalarWhereInput;
};

export type PrivacyPolicyUpdateUpdateManyWithoutStudentNestedInput = {
  connect?: InputMaybe<Array<PrivacyPolicyUpdateWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PrivacyPolicyUpdateCreateOrConnectWithoutStudentInput>>;
  create?: InputMaybe<Array<PrivacyPolicyUpdateCreateWithoutStudentInput>>;
  createMany?: InputMaybe<PrivacyPolicyUpdateCreateManyStudentInputEnvelope>;
  delete?: InputMaybe<Array<PrivacyPolicyUpdateWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PrivacyPolicyUpdateScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PrivacyPolicyUpdateWhereUniqueInput>>;
  set?: InputMaybe<Array<PrivacyPolicyUpdateWhereUniqueInput>>;
  update?: InputMaybe<Array<PrivacyPolicyUpdateUpdateWithWhereUniqueWithoutStudentInput>>;
  updateMany?: InputMaybe<Array<PrivacyPolicyUpdateUpdateManyWithWhereWithoutStudentInput>>;
  upsert?: InputMaybe<Array<PrivacyPolicyUpdateUpsertWithWhereUniqueWithoutStudentInput>>;
};

export type PrivacyPolicyUpdateUpdateWithWhereUniqueWithoutStudentInput = {
  data: PrivacyPolicyUpdateUpdateWithoutStudentInput;
  where: PrivacyPolicyUpdateWhereUniqueInput;
};

export type PrivacyPolicyUpdateUpdateWithoutStudentInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  privacyPolicy?: InputMaybe<PrivacyPolicyUpdateOneRequiredWithoutPrivacyPolicyUpdatesNestedInput>;
};

export type PrivacyPolicyUpdateUpsertWithWhereUniqueWithoutStudentInput = {
  create: PrivacyPolicyUpdateCreateWithoutStudentInput;
  update: PrivacyPolicyUpdateUpdateWithoutStudentInput;
  where: PrivacyPolicyUpdateWhereUniqueInput;
};

export type PrivacyPolicyUpdateWhereInput = {
  AND?: InputMaybe<Array<PrivacyPolicyUpdateWhereInput>>;
  NOT?: InputMaybe<Array<PrivacyPolicyUpdateWhereInput>>;
  OR?: InputMaybe<Array<PrivacyPolicyUpdateWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  privacyPolicy?: InputMaybe<PrivacyPolicyRelationFilter>;
  privacyPolicyId?: InputMaybe<IntFilter>;
  student?: InputMaybe<StudentRelationFilter>;
  studentId?: InputMaybe<IntFilter>;
};

export type PrivacyPolicyUpdateWhereUniqueInput = {
  id?: InputMaybe<Scalars["Int"]["input"]>;
};

export type PrivacyPolicyUpdateWithoutPrivacyPolicyUpdatesInput = {
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  required?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type PrivacyPolicyUpsertWithoutPrivacyPolicyUpdatesInput = {
  create: PrivacyPolicyCreateWithoutPrivacyPolicyUpdatesInput;
  update: PrivacyPolicyUpdateWithoutPrivacyPolicyUpdatesInput;
};

export type PrivacyPolicyWhereInput = {
  AND?: InputMaybe<Array<PrivacyPolicyWhereInput>>;
  NOT?: InputMaybe<Array<PrivacyPolicyWhereInput>>;
  OR?: InputMaybe<Array<PrivacyPolicyWhereInput>>;
  content?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  privacyPolicyUpdates?: InputMaybe<PrivacyPolicyUpdateListRelationFilter>;
  required?: InputMaybe<BoolFilter>;
};

export type PrivacyPolicyWhereUniqueInput = {
  id?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Product = {
  __typename?: "Product";
  _count?: Maybe<ProductCount>;
  age?: Maybe<Scalars["String"]["output"]>;
  coins?: Maybe<Scalars["Int"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  description?: Maybe<Scalars["String"]["output"]>;
  discountPrice?: Maybe<Scalars["Int"]["output"]>;
  featuredImage?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["Int"]["output"];
  images: Array<Scalars["String"]["output"]>;
  inCurrency: Scalars["Int"]["output"];
  isActive: Scalars["Boolean"]["output"];
  isBestSelling: Scalars["Boolean"]["output"];
  isNewReleased: Scalars["Boolean"]["output"];
  isVirtual: Scalars["Boolean"]["output"];
  isVisible: Scalars["Boolean"]["output"];
  metaTagData?: Maybe<Scalars["JSON"]["output"]>;
  name: Scalars["String"]["output"];
  orders: Array<Order>;
  price: Scalars["Int"]["output"];
  purchaseLink?: Maybe<Scalars["String"]["output"]>;
  relatedActivities: Array<Activity>;
  relatedStoryActivities: Array<StoryActivity>;
  shortDescription?: Maybe<Scalars["String"]["output"]>;
  skill?: Maybe<Skill>;
  skillId?: Maybe<Scalars["Int"]["output"]>;
  slug: Scalars["String"]["output"];
  standards: Array<Standard>;
  updatedAt: Scalars["DateTime"]["output"];
  video?: Maybe<Scalars["String"]["output"]>;
  workshops: Array<Workshop>;
};


export type ProductOrdersArgs = {
  cursor?: InputMaybe<OrderWhereUniqueInput>;
  distinct?: InputMaybe<Array<OrderScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<OrderOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<OrderWhereInput>;
};


export type ProductRelatedActivitiesArgs = {
  cursor?: InputMaybe<ActivityWhereUniqueInput>;
  distinct?: InputMaybe<Array<ActivityScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ActivityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ActivityWhereInput>;
};


export type ProductRelatedStoryActivitiesArgs = {
  cursor?: InputMaybe<StoryActivityWhereUniqueInput>;
  distinct?: InputMaybe<Array<StoryActivityScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StoryActivityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<StoryActivityWhereInput>;
};


export type ProductStandardsArgs = {
  cursor?: InputMaybe<StandardWhereUniqueInput>;
  distinct?: InputMaybe<Array<StandardScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StandardOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<StandardWhereInput>;
};


export type ProductWorkshopsArgs = {
  cursor?: InputMaybe<WorkshopWhereUniqueInput>;
  distinct?: InputMaybe<Array<WorkshopScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<WorkshopOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<WorkshopWhereInput>;
};

export type ProductAvgAggregate = {
  __typename?: "ProductAvgAggregate";
  coins?: Maybe<Scalars["Float"]["output"]>;
  discountPrice?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  inCurrency?: Maybe<Scalars["Float"]["output"]>;
  price?: Maybe<Scalars["Float"]["output"]>;
  skillId?: Maybe<Scalars["Float"]["output"]>;
};

export type ProductBanner = {
  __typename?: "ProductBanner";
  createdAt: Scalars["DateTime"]["output"];
  destinationUrl: Scalars["String"]["output"];
  displayOrder: Scalars["Int"]["output"];
  id: Scalars["Int"]["output"];
  image?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
};

export type ProductBannerCreateInput = {
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  destinationUrl: Scalars["String"]["input"];
  displayOrder?: InputMaybe<Scalars["Int"]["input"]>;
  image?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ProductBannerOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  destinationUrl?: InputMaybe<SortOrder>;
  displayOrder?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  image?: InputMaybe<SortOrderInput>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum ProductBannerScalarFieldEnum {
  CREATEDAT = "createdAt",
  DESTINATIONURL = "destinationUrl",
  DISPLAYORDER = "displayOrder",
  ID = "id",
  IMAGE = "image",
  UPDATEDAT = "updatedAt"
}

export type ProductBannerUpdateInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  destinationUrl?: InputMaybe<StringFieldUpdateOperationsInput>;
  displayOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ProductBannerWhereInput = {
  AND?: InputMaybe<Array<ProductBannerWhereInput>>;
  NOT?: InputMaybe<Array<ProductBannerWhereInput>>;
  OR?: InputMaybe<Array<ProductBannerWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  destinationUrl?: InputMaybe<StringFilter>;
  displayOrder?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  image?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ProductBannerWhereUniqueInput = {
  id?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ProductCount = {
  __typename?: "ProductCount";
  orders: Scalars["Int"]["output"];
  relatedActivities: Scalars["Int"]["output"];
  relatedStoryActivities: Scalars["Int"]["output"];
  standards: Scalars["Int"]["output"];
  workshops: Scalars["Int"]["output"];
};


export type ProductCountOrdersArgs = {
  where?: InputMaybe<OrderWhereInput>;
};


export type ProductCountRelatedActivitiesArgs = {
  where?: InputMaybe<ActivityWhereInput>;
};


export type ProductCountRelatedStoryActivitiesArgs = {
  where?: InputMaybe<StoryActivityWhereInput>;
};


export type ProductCountStandardsArgs = {
  where?: InputMaybe<StandardWhereInput>;
};


export type ProductCountWorkshopsArgs = {
  where?: InputMaybe<WorkshopWhereInput>;
};

export type ProductCountAggregate = {
  __typename?: "ProductCountAggregate";
  _all: Scalars["Int"]["output"];
  age: Scalars["Int"]["output"];
  coins: Scalars["Int"]["output"];
  createdAt: Scalars["Int"]["output"];
  description: Scalars["Int"]["output"];
  discountPrice: Scalars["Int"]["output"];
  featuredImage: Scalars["Int"]["output"];
  id: Scalars["Int"]["output"];
  images: Scalars["Int"]["output"];
  inCurrency: Scalars["Int"]["output"];
  isActive: Scalars["Int"]["output"];
  isBestSelling: Scalars["Int"]["output"];
  isNewReleased: Scalars["Int"]["output"];
  isVirtual: Scalars["Int"]["output"];
  isVisible: Scalars["Int"]["output"];
  metaTagData: Scalars["Int"]["output"];
  name: Scalars["Int"]["output"];
  price: Scalars["Int"]["output"];
  purchaseLink: Scalars["Int"]["output"];
  shortDescription: Scalars["Int"]["output"];
  skillId: Scalars["Int"]["output"];
  slug: Scalars["Int"]["output"];
  updatedAt: Scalars["Int"]["output"];
  video: Scalars["Int"]["output"];
};

export type ProductCreateInput = {
  age?: InputMaybe<Scalars["String"]["input"]>;
  coins?: InputMaybe<Scalars["Int"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  discountPrice?: InputMaybe<Scalars["Int"]["input"]>;
  featuredImage?: InputMaybe<Scalars["String"]["input"]>;
  images?: InputMaybe<ProductCreateimagesInput>;
  inCurrency: Scalars["Int"]["input"];
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  isBestSelling?: InputMaybe<Scalars["Boolean"]["input"]>;
  isNewReleased?: InputMaybe<Scalars["Boolean"]["input"]>;
  isVirtual?: InputMaybe<Scalars["Boolean"]["input"]>;
  isVisible?: InputMaybe<Scalars["Boolean"]["input"]>;
  metaTagData?: InputMaybe<Scalars["JSON"]["input"]>;
  name: Scalars["String"]["input"];
  orders?: InputMaybe<OrderCreateNestedManyWithoutProductInput>;
  price: Scalars["Int"]["input"];
  purchaseLink?: InputMaybe<Scalars["String"]["input"]>;
  relatedActivities?: InputMaybe<ActivityCreateNestedManyWithoutProductsInput>;
  relatedStoryActivities?: InputMaybe<StoryActivityCreateNestedManyWithoutProductsInput>;
  shortDescription?: InputMaybe<Scalars["String"]["input"]>;
  skill?: InputMaybe<SkillCreateNestedOneWithoutProductsInput>;
  slug: Scalars["String"]["input"];
  standards?: InputMaybe<StandardCreateNestedManyWithoutProductsInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  video?: InputMaybe<Scalars["String"]["input"]>;
  workshops?: InputMaybe<WorkshopCreateNestedManyWithoutProductInput>;
};

export type ProductCreateManySkillInput = {
  age?: InputMaybe<Scalars["String"]["input"]>;
  coins?: InputMaybe<Scalars["Int"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  discountPrice?: InputMaybe<Scalars["Int"]["input"]>;
  featuredImage?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  images?: InputMaybe<ProductCreateimagesInput>;
  inCurrency: Scalars["Int"]["input"];
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  isBestSelling?: InputMaybe<Scalars["Boolean"]["input"]>;
  isNewReleased?: InputMaybe<Scalars["Boolean"]["input"]>;
  isVirtual?: InputMaybe<Scalars["Boolean"]["input"]>;
  isVisible?: InputMaybe<Scalars["Boolean"]["input"]>;
  metaTagData?: InputMaybe<Scalars["JSON"]["input"]>;
  name: Scalars["String"]["input"];
  price: Scalars["Int"]["input"];
  purchaseLink?: InputMaybe<Scalars["String"]["input"]>;
  shortDescription?: InputMaybe<Scalars["String"]["input"]>;
  slug: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  video?: InputMaybe<Scalars["String"]["input"]>;
};

export type ProductCreateManySkillInputEnvelope = {
  data: Array<ProductCreateManySkillInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ProductCreateNestedManyWithoutRelatedActivitiesInput = {
  connect?: InputMaybe<Array<ProductWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductCreateOrConnectWithoutRelatedActivitiesInput>>;
  create?: InputMaybe<Array<ProductCreateWithoutRelatedActivitiesInput>>;
};

export type ProductCreateNestedManyWithoutRelatedStoryActivitiesInput = {
  connect?: InputMaybe<Array<ProductWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductCreateOrConnectWithoutRelatedStoryActivitiesInput>>;
  create?: InputMaybe<Array<ProductCreateWithoutRelatedStoryActivitiesInput>>;
};

export type ProductCreateNestedManyWithoutSkillInput = {
  connect?: InputMaybe<Array<ProductWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductCreateOrConnectWithoutSkillInput>>;
  create?: InputMaybe<Array<ProductCreateWithoutSkillInput>>;
  createMany?: InputMaybe<ProductCreateManySkillInputEnvelope>;
};

export type ProductCreateNestedManyWithoutStandardsInput = {
  connect?: InputMaybe<Array<ProductWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductCreateOrConnectWithoutStandardsInput>>;
  create?: InputMaybe<Array<ProductCreateWithoutStandardsInput>>;
};

export type ProductCreateNestedOneWithoutOrdersInput = {
  connect?: InputMaybe<ProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProductCreateOrConnectWithoutOrdersInput>;
  create?: InputMaybe<ProductCreateWithoutOrdersInput>;
};

export type ProductCreateNestedOneWithoutWorkshopsInput = {
  connect?: InputMaybe<ProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProductCreateOrConnectWithoutWorkshopsInput>;
  create?: InputMaybe<ProductCreateWithoutWorkshopsInput>;
};

export type ProductCreateOrConnectWithoutOrdersInput = {
  create: ProductCreateWithoutOrdersInput;
  where: ProductWhereUniqueInput;
};

export type ProductCreateOrConnectWithoutRelatedActivitiesInput = {
  create: ProductCreateWithoutRelatedActivitiesInput;
  where: ProductWhereUniqueInput;
};

export type ProductCreateOrConnectWithoutRelatedStoryActivitiesInput = {
  create: ProductCreateWithoutRelatedStoryActivitiesInput;
  where: ProductWhereUniqueInput;
};

export type ProductCreateOrConnectWithoutSkillInput = {
  create: ProductCreateWithoutSkillInput;
  where: ProductWhereUniqueInput;
};

export type ProductCreateOrConnectWithoutStandardsInput = {
  create: ProductCreateWithoutStandardsInput;
  where: ProductWhereUniqueInput;
};

export type ProductCreateOrConnectWithoutWorkshopsInput = {
  create: ProductCreateWithoutWorkshopsInput;
  where: ProductWhereUniqueInput;
};

export type ProductCreateWithoutOrdersInput = {
  age?: InputMaybe<Scalars["String"]["input"]>;
  coins?: InputMaybe<Scalars["Int"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  discountPrice?: InputMaybe<Scalars["Int"]["input"]>;
  featuredImage?: InputMaybe<Scalars["String"]["input"]>;
  images?: InputMaybe<ProductCreateimagesInput>;
  inCurrency: Scalars["Int"]["input"];
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  isBestSelling?: InputMaybe<Scalars["Boolean"]["input"]>;
  isNewReleased?: InputMaybe<Scalars["Boolean"]["input"]>;
  isVirtual?: InputMaybe<Scalars["Boolean"]["input"]>;
  isVisible?: InputMaybe<Scalars["Boolean"]["input"]>;
  metaTagData?: InputMaybe<Scalars["JSON"]["input"]>;
  name: Scalars["String"]["input"];
  price: Scalars["Int"]["input"];
  purchaseLink?: InputMaybe<Scalars["String"]["input"]>;
  relatedActivities?: InputMaybe<ActivityCreateNestedManyWithoutProductsInput>;
  relatedStoryActivities?: InputMaybe<StoryActivityCreateNestedManyWithoutProductsInput>;
  shortDescription?: InputMaybe<Scalars["String"]["input"]>;
  skill?: InputMaybe<SkillCreateNestedOneWithoutProductsInput>;
  slug: Scalars["String"]["input"];
  standards?: InputMaybe<StandardCreateNestedManyWithoutProductsInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  video?: InputMaybe<Scalars["String"]["input"]>;
  workshops?: InputMaybe<WorkshopCreateNestedManyWithoutProductInput>;
};

export type ProductCreateWithoutRelatedActivitiesInput = {
  age?: InputMaybe<Scalars["String"]["input"]>;
  coins?: InputMaybe<Scalars["Int"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  discountPrice?: InputMaybe<Scalars["Int"]["input"]>;
  featuredImage?: InputMaybe<Scalars["String"]["input"]>;
  images?: InputMaybe<ProductCreateimagesInput>;
  inCurrency: Scalars["Int"]["input"];
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  isBestSelling?: InputMaybe<Scalars["Boolean"]["input"]>;
  isNewReleased?: InputMaybe<Scalars["Boolean"]["input"]>;
  isVirtual?: InputMaybe<Scalars["Boolean"]["input"]>;
  isVisible?: InputMaybe<Scalars["Boolean"]["input"]>;
  metaTagData?: InputMaybe<Scalars["JSON"]["input"]>;
  name: Scalars["String"]["input"];
  orders?: InputMaybe<OrderCreateNestedManyWithoutProductInput>;
  price: Scalars["Int"]["input"];
  purchaseLink?: InputMaybe<Scalars["String"]["input"]>;
  relatedStoryActivities?: InputMaybe<StoryActivityCreateNestedManyWithoutProductsInput>;
  shortDescription?: InputMaybe<Scalars["String"]["input"]>;
  skill?: InputMaybe<SkillCreateNestedOneWithoutProductsInput>;
  slug: Scalars["String"]["input"];
  standards?: InputMaybe<StandardCreateNestedManyWithoutProductsInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  video?: InputMaybe<Scalars["String"]["input"]>;
  workshops?: InputMaybe<WorkshopCreateNestedManyWithoutProductInput>;
};

export type ProductCreateWithoutRelatedStoryActivitiesInput = {
  age?: InputMaybe<Scalars["String"]["input"]>;
  coins?: InputMaybe<Scalars["Int"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  discountPrice?: InputMaybe<Scalars["Int"]["input"]>;
  featuredImage?: InputMaybe<Scalars["String"]["input"]>;
  images?: InputMaybe<ProductCreateimagesInput>;
  inCurrency: Scalars["Int"]["input"];
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  isBestSelling?: InputMaybe<Scalars["Boolean"]["input"]>;
  isNewReleased?: InputMaybe<Scalars["Boolean"]["input"]>;
  isVirtual?: InputMaybe<Scalars["Boolean"]["input"]>;
  isVisible?: InputMaybe<Scalars["Boolean"]["input"]>;
  metaTagData?: InputMaybe<Scalars["JSON"]["input"]>;
  name: Scalars["String"]["input"];
  orders?: InputMaybe<OrderCreateNestedManyWithoutProductInput>;
  price: Scalars["Int"]["input"];
  purchaseLink?: InputMaybe<Scalars["String"]["input"]>;
  relatedActivities?: InputMaybe<ActivityCreateNestedManyWithoutProductsInput>;
  shortDescription?: InputMaybe<Scalars["String"]["input"]>;
  skill?: InputMaybe<SkillCreateNestedOneWithoutProductsInput>;
  slug: Scalars["String"]["input"];
  standards?: InputMaybe<StandardCreateNestedManyWithoutProductsInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  video?: InputMaybe<Scalars["String"]["input"]>;
  workshops?: InputMaybe<WorkshopCreateNestedManyWithoutProductInput>;
};

export type ProductCreateWithoutSkillInput = {
  age?: InputMaybe<Scalars["String"]["input"]>;
  coins?: InputMaybe<Scalars["Int"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  discountPrice?: InputMaybe<Scalars["Int"]["input"]>;
  featuredImage?: InputMaybe<Scalars["String"]["input"]>;
  images?: InputMaybe<ProductCreateimagesInput>;
  inCurrency: Scalars["Int"]["input"];
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  isBestSelling?: InputMaybe<Scalars["Boolean"]["input"]>;
  isNewReleased?: InputMaybe<Scalars["Boolean"]["input"]>;
  isVirtual?: InputMaybe<Scalars["Boolean"]["input"]>;
  isVisible?: InputMaybe<Scalars["Boolean"]["input"]>;
  metaTagData?: InputMaybe<Scalars["JSON"]["input"]>;
  name: Scalars["String"]["input"];
  orders?: InputMaybe<OrderCreateNestedManyWithoutProductInput>;
  price: Scalars["Int"]["input"];
  purchaseLink?: InputMaybe<Scalars["String"]["input"]>;
  relatedActivities?: InputMaybe<ActivityCreateNestedManyWithoutProductsInput>;
  relatedStoryActivities?: InputMaybe<StoryActivityCreateNestedManyWithoutProductsInput>;
  shortDescription?: InputMaybe<Scalars["String"]["input"]>;
  slug: Scalars["String"]["input"];
  standards?: InputMaybe<StandardCreateNestedManyWithoutProductsInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  video?: InputMaybe<Scalars["String"]["input"]>;
  workshops?: InputMaybe<WorkshopCreateNestedManyWithoutProductInput>;
};

export type ProductCreateWithoutStandardsInput = {
  age?: InputMaybe<Scalars["String"]["input"]>;
  coins?: InputMaybe<Scalars["Int"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  discountPrice?: InputMaybe<Scalars["Int"]["input"]>;
  featuredImage?: InputMaybe<Scalars["String"]["input"]>;
  images?: InputMaybe<ProductCreateimagesInput>;
  inCurrency: Scalars["Int"]["input"];
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  isBestSelling?: InputMaybe<Scalars["Boolean"]["input"]>;
  isNewReleased?: InputMaybe<Scalars["Boolean"]["input"]>;
  isVirtual?: InputMaybe<Scalars["Boolean"]["input"]>;
  isVisible?: InputMaybe<Scalars["Boolean"]["input"]>;
  metaTagData?: InputMaybe<Scalars["JSON"]["input"]>;
  name: Scalars["String"]["input"];
  orders?: InputMaybe<OrderCreateNestedManyWithoutProductInput>;
  price: Scalars["Int"]["input"];
  purchaseLink?: InputMaybe<Scalars["String"]["input"]>;
  relatedActivities?: InputMaybe<ActivityCreateNestedManyWithoutProductsInput>;
  relatedStoryActivities?: InputMaybe<StoryActivityCreateNestedManyWithoutProductsInput>;
  shortDescription?: InputMaybe<Scalars["String"]["input"]>;
  skill?: InputMaybe<SkillCreateNestedOneWithoutProductsInput>;
  slug: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  video?: InputMaybe<Scalars["String"]["input"]>;
  workshops?: InputMaybe<WorkshopCreateNestedManyWithoutProductInput>;
};

export type ProductCreateWithoutWorkshopsInput = {
  age?: InputMaybe<Scalars["String"]["input"]>;
  coins?: InputMaybe<Scalars["Int"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  discountPrice?: InputMaybe<Scalars["Int"]["input"]>;
  featuredImage?: InputMaybe<Scalars["String"]["input"]>;
  images?: InputMaybe<ProductCreateimagesInput>;
  inCurrency: Scalars["Int"]["input"];
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  isBestSelling?: InputMaybe<Scalars["Boolean"]["input"]>;
  isNewReleased?: InputMaybe<Scalars["Boolean"]["input"]>;
  isVirtual?: InputMaybe<Scalars["Boolean"]["input"]>;
  isVisible?: InputMaybe<Scalars["Boolean"]["input"]>;
  metaTagData?: InputMaybe<Scalars["JSON"]["input"]>;
  name: Scalars["String"]["input"];
  orders?: InputMaybe<OrderCreateNestedManyWithoutProductInput>;
  price: Scalars["Int"]["input"];
  purchaseLink?: InputMaybe<Scalars["String"]["input"]>;
  relatedActivities?: InputMaybe<ActivityCreateNestedManyWithoutProductsInput>;
  relatedStoryActivities?: InputMaybe<StoryActivityCreateNestedManyWithoutProductsInput>;
  shortDescription?: InputMaybe<Scalars["String"]["input"]>;
  skill?: InputMaybe<SkillCreateNestedOneWithoutProductsInput>;
  slug: Scalars["String"]["input"];
  standards?: InputMaybe<StandardCreateNestedManyWithoutProductsInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  video?: InputMaybe<Scalars["String"]["input"]>;
};

export type ProductCreateimagesInput = {
  set: Array<Scalars["String"]["input"]>;
};

export type ProductListRelationFilter = {
  every?: InputMaybe<ProductWhereInput>;
  none?: InputMaybe<ProductWhereInput>;
  some?: InputMaybe<ProductWhereInput>;
};

export type ProductMaxAggregate = {
  __typename?: "ProductMaxAggregate";
  age?: Maybe<Scalars["String"]["output"]>;
  coins?: Maybe<Scalars["Int"]["output"]>;
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  discountPrice?: Maybe<Scalars["Int"]["output"]>;
  featuredImage?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  inCurrency?: Maybe<Scalars["Int"]["output"]>;
  isActive?: Maybe<Scalars["Boolean"]["output"]>;
  isBestSelling?: Maybe<Scalars["Boolean"]["output"]>;
  isNewReleased?: Maybe<Scalars["Boolean"]["output"]>;
  isVirtual?: Maybe<Scalars["Boolean"]["output"]>;
  isVisible?: Maybe<Scalars["Boolean"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  price?: Maybe<Scalars["Int"]["output"]>;
  purchaseLink?: Maybe<Scalars["String"]["output"]>;
  shortDescription?: Maybe<Scalars["String"]["output"]>;
  skillId?: Maybe<Scalars["Int"]["output"]>;
  slug?: Maybe<Scalars["String"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
  video?: Maybe<Scalars["String"]["output"]>;
};

export type ProductMinAggregate = {
  __typename?: "ProductMinAggregate";
  age?: Maybe<Scalars["String"]["output"]>;
  coins?: Maybe<Scalars["Int"]["output"]>;
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  discountPrice?: Maybe<Scalars["Int"]["output"]>;
  featuredImage?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  inCurrency?: Maybe<Scalars["Int"]["output"]>;
  isActive?: Maybe<Scalars["Boolean"]["output"]>;
  isBestSelling?: Maybe<Scalars["Boolean"]["output"]>;
  isNewReleased?: Maybe<Scalars["Boolean"]["output"]>;
  isVirtual?: Maybe<Scalars["Boolean"]["output"]>;
  isVisible?: Maybe<Scalars["Boolean"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  price?: Maybe<Scalars["Int"]["output"]>;
  purchaseLink?: Maybe<Scalars["String"]["output"]>;
  shortDescription?: Maybe<Scalars["String"]["output"]>;
  skillId?: Maybe<Scalars["Int"]["output"]>;
  slug?: Maybe<Scalars["String"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
  video?: Maybe<Scalars["String"]["output"]>;
};

export type ProductOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ProductOrderByWithRelationInput = {
  age?: InputMaybe<SortOrderInput>;
  coins?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrderInput>;
  discountPrice?: InputMaybe<SortOrderInput>;
  featuredImage?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  images?: InputMaybe<SortOrder>;
  inCurrency?: InputMaybe<SortOrder>;
  isActive?: InputMaybe<SortOrder>;
  isBestSelling?: InputMaybe<SortOrder>;
  isNewReleased?: InputMaybe<SortOrder>;
  isVirtual?: InputMaybe<SortOrder>;
  isVisible?: InputMaybe<SortOrder>;
  metaTagData?: InputMaybe<SortOrderInput>;
  name?: InputMaybe<SortOrder>;
  orders?: InputMaybe<OrderOrderByRelationAggregateInput>;
  price?: InputMaybe<SortOrder>;
  purchaseLink?: InputMaybe<SortOrderInput>;
  relatedActivities?: InputMaybe<ActivityOrderByRelationAggregateInput>;
  relatedStoryActivities?: InputMaybe<StoryActivityOrderByRelationAggregateInput>;
  shortDescription?: InputMaybe<SortOrderInput>;
  skill?: InputMaybe<SkillOrderByWithRelationInput>;
  skillId?: InputMaybe<SortOrderInput>;
  slug?: InputMaybe<SortOrder>;
  standards?: InputMaybe<StandardOrderByRelationAggregateInput>;
  updatedAt?: InputMaybe<SortOrder>;
  video?: InputMaybe<SortOrderInput>;
  workshops?: InputMaybe<WorkshopOrderByRelationAggregateInput>;
};

export type ProductRelationFilter = {
  is?: InputMaybe<ProductWhereInput>;
  isNot?: InputMaybe<ProductWhereInput>;
};

export enum ProductScalarFieldEnum {
  AGE = "age",
  COINS = "coins",
  CREATEDAT = "createdAt",
  DESCRIPTION = "description",
  DISCOUNTPRICE = "discountPrice",
  FEATUREDIMAGE = "featuredImage",
  ID = "id",
  IMAGES = "images",
  INCURRENCY = "inCurrency",
  ISACTIVE = "isActive",
  ISBESTSELLING = "isBestSelling",
  ISNEWRELEASED = "isNewReleased",
  ISVIRTUAL = "isVirtual",
  ISVISIBLE = "isVisible",
  METATAGDATA = "metaTagData",
  NAME = "name",
  PRICE = "price",
  PURCHASELINK = "purchaseLink",
  SHORTDESCRIPTION = "shortDescription",
  SKILLID = "skillId",
  SLUG = "slug",
  UPDATEDAT = "updatedAt",
  VIDEO = "video"
}

export type ProductScalarWhereInput = {
  AND?: InputMaybe<Array<ProductScalarWhereInput>>;
  NOT?: InputMaybe<Array<ProductScalarWhereInput>>;
  OR?: InputMaybe<Array<ProductScalarWhereInput>>;
  age?: InputMaybe<StringNullableFilter>;
  coins?: InputMaybe<IntNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  discountPrice?: InputMaybe<IntNullableFilter>;
  featuredImage?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  images?: InputMaybe<StringNullableListFilter>;
  inCurrency?: InputMaybe<IntFilter>;
  isActive?: InputMaybe<BoolFilter>;
  isBestSelling?: InputMaybe<BoolFilter>;
  isNewReleased?: InputMaybe<BoolFilter>;
  isVirtual?: InputMaybe<BoolFilter>;
  isVisible?: InputMaybe<BoolFilter>;
  metaTagData?: InputMaybe<JsonNullableFilter>;
  name?: InputMaybe<StringFilter>;
  price?: InputMaybe<IntFilter>;
  purchaseLink?: InputMaybe<StringNullableFilter>;
  shortDescription?: InputMaybe<StringNullableFilter>;
  skillId?: InputMaybe<IntNullableFilter>;
  slug?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  video?: InputMaybe<StringNullableFilter>;
};

export type ProductSumAggregate = {
  __typename?: "ProductSumAggregate";
  coins?: Maybe<Scalars["Int"]["output"]>;
  discountPrice?: Maybe<Scalars["Int"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  inCurrency?: Maybe<Scalars["Int"]["output"]>;
  price?: Maybe<Scalars["Int"]["output"]>;
  skillId?: Maybe<Scalars["Int"]["output"]>;
};

export type ProductUpdateInput = {
  age?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  coins?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  discountPrice?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  images?: InputMaybe<ProductUpdateimagesInput>;
  inCurrency?: InputMaybe<IntFieldUpdateOperationsInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isBestSelling?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isNewReleased?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isVirtual?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isVisible?: InputMaybe<BoolFieldUpdateOperationsInput>;
  metaTagData?: InputMaybe<Scalars["JSON"]["input"]>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  orders?: InputMaybe<OrderUpdateManyWithoutProductNestedInput>;
  price?: InputMaybe<IntFieldUpdateOperationsInput>;
  purchaseLink?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  relatedActivities?: InputMaybe<ActivityUpdateManyWithoutProductsNestedInput>;
  relatedStoryActivities?: InputMaybe<StoryActivityUpdateManyWithoutProductsNestedInput>;
  shortDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  skill?: InputMaybe<SkillUpdateOneWithoutProductsNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  standards?: InputMaybe<StandardUpdateManyWithoutProductsNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  video?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  workshops?: InputMaybe<WorkshopUpdateManyWithoutProductNestedInput>;
};

export type ProductUpdateManyMutationInput = {
  age?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  coins?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  discountPrice?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  images?: InputMaybe<ProductUpdateimagesInput>;
  inCurrency?: InputMaybe<IntFieldUpdateOperationsInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isBestSelling?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isNewReleased?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isVirtual?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isVisible?: InputMaybe<BoolFieldUpdateOperationsInput>;
  metaTagData?: InputMaybe<Scalars["JSON"]["input"]>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  price?: InputMaybe<IntFieldUpdateOperationsInput>;
  purchaseLink?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shortDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  video?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type ProductUpdateManyWithWhereWithoutRelatedActivitiesInput = {
  data: ProductUpdateManyMutationInput;
  where: ProductScalarWhereInput;
};

export type ProductUpdateManyWithWhereWithoutRelatedStoryActivitiesInput = {
  data: ProductUpdateManyMutationInput;
  where: ProductScalarWhereInput;
};

export type ProductUpdateManyWithWhereWithoutSkillInput = {
  data: ProductUpdateManyMutationInput;
  where: ProductScalarWhereInput;
};

export type ProductUpdateManyWithWhereWithoutStandardsInput = {
  data: ProductUpdateManyMutationInput;
  where: ProductScalarWhereInput;
};

export type ProductUpdateManyWithoutRelatedActivitiesNestedInput = {
  connect?: InputMaybe<Array<ProductWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductCreateOrConnectWithoutRelatedActivitiesInput>>;
  create?: InputMaybe<Array<ProductCreateWithoutRelatedActivitiesInput>>;
  delete?: InputMaybe<Array<ProductWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ProductScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ProductWhereUniqueInput>>;
  set?: InputMaybe<Array<ProductWhereUniqueInput>>;
  update?: InputMaybe<Array<ProductUpdateWithWhereUniqueWithoutRelatedActivitiesInput>>;
  updateMany?: InputMaybe<Array<ProductUpdateManyWithWhereWithoutRelatedActivitiesInput>>;
  upsert?: InputMaybe<Array<ProductUpsertWithWhereUniqueWithoutRelatedActivitiesInput>>;
};

export type ProductUpdateManyWithoutRelatedStoryActivitiesNestedInput = {
  connect?: InputMaybe<Array<ProductWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductCreateOrConnectWithoutRelatedStoryActivitiesInput>>;
  create?: InputMaybe<Array<ProductCreateWithoutRelatedStoryActivitiesInput>>;
  delete?: InputMaybe<Array<ProductWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ProductScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ProductWhereUniqueInput>>;
  set?: InputMaybe<Array<ProductWhereUniqueInput>>;
  update?: InputMaybe<Array<ProductUpdateWithWhereUniqueWithoutRelatedStoryActivitiesInput>>;
  updateMany?: InputMaybe<Array<ProductUpdateManyWithWhereWithoutRelatedStoryActivitiesInput>>;
  upsert?: InputMaybe<Array<ProductUpsertWithWhereUniqueWithoutRelatedStoryActivitiesInput>>;
};

export type ProductUpdateManyWithoutSkillNestedInput = {
  connect?: InputMaybe<Array<ProductWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductCreateOrConnectWithoutSkillInput>>;
  create?: InputMaybe<Array<ProductCreateWithoutSkillInput>>;
  createMany?: InputMaybe<ProductCreateManySkillInputEnvelope>;
  delete?: InputMaybe<Array<ProductWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ProductScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ProductWhereUniqueInput>>;
  set?: InputMaybe<Array<ProductWhereUniqueInput>>;
  update?: InputMaybe<Array<ProductUpdateWithWhereUniqueWithoutSkillInput>>;
  updateMany?: InputMaybe<Array<ProductUpdateManyWithWhereWithoutSkillInput>>;
  upsert?: InputMaybe<Array<ProductUpsertWithWhereUniqueWithoutSkillInput>>;
};

export type ProductUpdateManyWithoutStandardsNestedInput = {
  connect?: InputMaybe<Array<ProductWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductCreateOrConnectWithoutStandardsInput>>;
  create?: InputMaybe<Array<ProductCreateWithoutStandardsInput>>;
  delete?: InputMaybe<Array<ProductWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ProductScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ProductWhereUniqueInput>>;
  set?: InputMaybe<Array<ProductWhereUniqueInput>>;
  update?: InputMaybe<Array<ProductUpdateWithWhereUniqueWithoutStandardsInput>>;
  updateMany?: InputMaybe<Array<ProductUpdateManyWithWhereWithoutStandardsInput>>;
  upsert?: InputMaybe<Array<ProductUpsertWithWhereUniqueWithoutStandardsInput>>;
};

export type ProductUpdateOneRequiredWithoutOrdersNestedInput = {
  connect?: InputMaybe<ProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProductCreateOrConnectWithoutOrdersInput>;
  create?: InputMaybe<ProductCreateWithoutOrdersInput>;
  update?: InputMaybe<ProductUpdateWithoutOrdersInput>;
  upsert?: InputMaybe<ProductUpsertWithoutOrdersInput>;
};

export type ProductUpdateOneWithoutWorkshopsNestedInput = {
  connect?: InputMaybe<ProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProductCreateOrConnectWithoutWorkshopsInput>;
  create?: InputMaybe<ProductCreateWithoutWorkshopsInput>;
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  update?: InputMaybe<ProductUpdateWithoutWorkshopsInput>;
  upsert?: InputMaybe<ProductUpsertWithoutWorkshopsInput>;
};

export type ProductUpdateWithWhereUniqueWithoutRelatedActivitiesInput = {
  data: ProductUpdateWithoutRelatedActivitiesInput;
  where: ProductWhereUniqueInput;
};

export type ProductUpdateWithWhereUniqueWithoutRelatedStoryActivitiesInput = {
  data: ProductUpdateWithoutRelatedStoryActivitiesInput;
  where: ProductWhereUniqueInput;
};

export type ProductUpdateWithWhereUniqueWithoutSkillInput = {
  data: ProductUpdateWithoutSkillInput;
  where: ProductWhereUniqueInput;
};

export type ProductUpdateWithWhereUniqueWithoutStandardsInput = {
  data: ProductUpdateWithoutStandardsInput;
  where: ProductWhereUniqueInput;
};

export type ProductUpdateWithoutOrdersInput = {
  age?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  coins?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  discountPrice?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  images?: InputMaybe<ProductUpdateimagesInput>;
  inCurrency?: InputMaybe<IntFieldUpdateOperationsInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isBestSelling?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isNewReleased?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isVirtual?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isVisible?: InputMaybe<BoolFieldUpdateOperationsInput>;
  metaTagData?: InputMaybe<Scalars["JSON"]["input"]>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  price?: InputMaybe<IntFieldUpdateOperationsInput>;
  purchaseLink?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  relatedActivities?: InputMaybe<ActivityUpdateManyWithoutProductsNestedInput>;
  relatedStoryActivities?: InputMaybe<StoryActivityUpdateManyWithoutProductsNestedInput>;
  shortDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  skill?: InputMaybe<SkillUpdateOneWithoutProductsNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  standards?: InputMaybe<StandardUpdateManyWithoutProductsNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  video?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  workshops?: InputMaybe<WorkshopUpdateManyWithoutProductNestedInput>;
};

export type ProductUpdateWithoutRelatedActivitiesInput = {
  age?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  coins?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  discountPrice?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  images?: InputMaybe<ProductUpdateimagesInput>;
  inCurrency?: InputMaybe<IntFieldUpdateOperationsInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isBestSelling?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isNewReleased?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isVirtual?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isVisible?: InputMaybe<BoolFieldUpdateOperationsInput>;
  metaTagData?: InputMaybe<Scalars["JSON"]["input"]>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  orders?: InputMaybe<OrderUpdateManyWithoutProductNestedInput>;
  price?: InputMaybe<IntFieldUpdateOperationsInput>;
  purchaseLink?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  relatedStoryActivities?: InputMaybe<StoryActivityUpdateManyWithoutProductsNestedInput>;
  shortDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  skill?: InputMaybe<SkillUpdateOneWithoutProductsNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  standards?: InputMaybe<StandardUpdateManyWithoutProductsNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  video?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  workshops?: InputMaybe<WorkshopUpdateManyWithoutProductNestedInput>;
};

export type ProductUpdateWithoutRelatedStoryActivitiesInput = {
  age?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  coins?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  discountPrice?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  images?: InputMaybe<ProductUpdateimagesInput>;
  inCurrency?: InputMaybe<IntFieldUpdateOperationsInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isBestSelling?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isNewReleased?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isVirtual?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isVisible?: InputMaybe<BoolFieldUpdateOperationsInput>;
  metaTagData?: InputMaybe<Scalars["JSON"]["input"]>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  orders?: InputMaybe<OrderUpdateManyWithoutProductNestedInput>;
  price?: InputMaybe<IntFieldUpdateOperationsInput>;
  purchaseLink?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  relatedActivities?: InputMaybe<ActivityUpdateManyWithoutProductsNestedInput>;
  shortDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  skill?: InputMaybe<SkillUpdateOneWithoutProductsNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  standards?: InputMaybe<StandardUpdateManyWithoutProductsNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  video?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  workshops?: InputMaybe<WorkshopUpdateManyWithoutProductNestedInput>;
};

export type ProductUpdateWithoutSkillInput = {
  age?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  coins?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  discountPrice?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  images?: InputMaybe<ProductUpdateimagesInput>;
  inCurrency?: InputMaybe<IntFieldUpdateOperationsInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isBestSelling?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isNewReleased?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isVirtual?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isVisible?: InputMaybe<BoolFieldUpdateOperationsInput>;
  metaTagData?: InputMaybe<Scalars["JSON"]["input"]>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  orders?: InputMaybe<OrderUpdateManyWithoutProductNestedInput>;
  price?: InputMaybe<IntFieldUpdateOperationsInput>;
  purchaseLink?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  relatedActivities?: InputMaybe<ActivityUpdateManyWithoutProductsNestedInput>;
  relatedStoryActivities?: InputMaybe<StoryActivityUpdateManyWithoutProductsNestedInput>;
  shortDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  standards?: InputMaybe<StandardUpdateManyWithoutProductsNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  video?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  workshops?: InputMaybe<WorkshopUpdateManyWithoutProductNestedInput>;
};

export type ProductUpdateWithoutStandardsInput = {
  age?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  coins?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  discountPrice?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  images?: InputMaybe<ProductUpdateimagesInput>;
  inCurrency?: InputMaybe<IntFieldUpdateOperationsInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isBestSelling?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isNewReleased?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isVirtual?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isVisible?: InputMaybe<BoolFieldUpdateOperationsInput>;
  metaTagData?: InputMaybe<Scalars["JSON"]["input"]>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  orders?: InputMaybe<OrderUpdateManyWithoutProductNestedInput>;
  price?: InputMaybe<IntFieldUpdateOperationsInput>;
  purchaseLink?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  relatedActivities?: InputMaybe<ActivityUpdateManyWithoutProductsNestedInput>;
  relatedStoryActivities?: InputMaybe<StoryActivityUpdateManyWithoutProductsNestedInput>;
  shortDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  skill?: InputMaybe<SkillUpdateOneWithoutProductsNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  video?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  workshops?: InputMaybe<WorkshopUpdateManyWithoutProductNestedInput>;
};

export type ProductUpdateWithoutWorkshopsInput = {
  age?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  coins?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  discountPrice?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  images?: InputMaybe<ProductUpdateimagesInput>;
  inCurrency?: InputMaybe<IntFieldUpdateOperationsInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isBestSelling?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isNewReleased?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isVirtual?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isVisible?: InputMaybe<BoolFieldUpdateOperationsInput>;
  metaTagData?: InputMaybe<Scalars["JSON"]["input"]>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  orders?: InputMaybe<OrderUpdateManyWithoutProductNestedInput>;
  price?: InputMaybe<IntFieldUpdateOperationsInput>;
  purchaseLink?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  relatedActivities?: InputMaybe<ActivityUpdateManyWithoutProductsNestedInput>;
  relatedStoryActivities?: InputMaybe<StoryActivityUpdateManyWithoutProductsNestedInput>;
  shortDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  skill?: InputMaybe<SkillUpdateOneWithoutProductsNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  standards?: InputMaybe<StandardUpdateManyWithoutProductsNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  video?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type ProductUpdateimagesInput = {
  push?: InputMaybe<Array<Scalars["String"]["input"]>>;
  set?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

export type ProductUpsertWithWhereUniqueWithoutRelatedActivitiesInput = {
  create: ProductCreateWithoutRelatedActivitiesInput;
  update: ProductUpdateWithoutRelatedActivitiesInput;
  where: ProductWhereUniqueInput;
};

export type ProductUpsertWithWhereUniqueWithoutRelatedStoryActivitiesInput = {
  create: ProductCreateWithoutRelatedStoryActivitiesInput;
  update: ProductUpdateWithoutRelatedStoryActivitiesInput;
  where: ProductWhereUniqueInput;
};

export type ProductUpsertWithWhereUniqueWithoutSkillInput = {
  create: ProductCreateWithoutSkillInput;
  update: ProductUpdateWithoutSkillInput;
  where: ProductWhereUniqueInput;
};

export type ProductUpsertWithWhereUniqueWithoutStandardsInput = {
  create: ProductCreateWithoutStandardsInput;
  update: ProductUpdateWithoutStandardsInput;
  where: ProductWhereUniqueInput;
};

export type ProductUpsertWithoutOrdersInput = {
  create: ProductCreateWithoutOrdersInput;
  update: ProductUpdateWithoutOrdersInput;
};

export type ProductUpsertWithoutWorkshopsInput = {
  create: ProductCreateWithoutWorkshopsInput;
  update: ProductUpdateWithoutWorkshopsInput;
};

export type ProductWhereInput = {
  AND?: InputMaybe<Array<ProductWhereInput>>;
  NOT?: InputMaybe<Array<ProductWhereInput>>;
  OR?: InputMaybe<Array<ProductWhereInput>>;
  age?: InputMaybe<StringNullableFilter>;
  coins?: InputMaybe<IntNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  discountPrice?: InputMaybe<IntNullableFilter>;
  featuredImage?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  images?: InputMaybe<StringNullableListFilter>;
  inCurrency?: InputMaybe<IntFilter>;
  isActive?: InputMaybe<BoolFilter>;
  isBestSelling?: InputMaybe<BoolFilter>;
  isNewReleased?: InputMaybe<BoolFilter>;
  isVirtual?: InputMaybe<BoolFilter>;
  isVisible?: InputMaybe<BoolFilter>;
  metaTagData?: InputMaybe<JsonNullableFilter>;
  name?: InputMaybe<StringFilter>;
  orders?: InputMaybe<OrderListRelationFilter>;
  price?: InputMaybe<IntFilter>;
  purchaseLink?: InputMaybe<StringNullableFilter>;
  relatedActivities?: InputMaybe<ActivityListRelationFilter>;
  relatedStoryActivities?: InputMaybe<StoryActivityListRelationFilter>;
  shortDescription?: InputMaybe<StringNullableFilter>;
  skill?: InputMaybe<SkillRelationFilter>;
  skillId?: InputMaybe<IntNullableFilter>;
  slug?: InputMaybe<StringFilter>;
  standards?: InputMaybe<StandardListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  video?: InputMaybe<StringNullableFilter>;
  workshops?: InputMaybe<WorkshopListRelationFilter>;
};

export type ProductWhereUniqueInput = {
  id?: InputMaybe<Scalars["Int"]["input"]>;
  slug?: InputMaybe<Scalars["String"]["input"]>;
};

export type PromoCode = {
  __typename?: "PromoCode";
  _count?: Maybe<PromoCodeCount>;
  activities: Array<Activity>;
  code: Scalars["String"]["output"];
  coins: Scalars["Int"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  expiresAt: Scalars["DateTime"]["output"];
  id: Scalars["Int"]["output"];
  isActive: Scalars["Boolean"]["output"];
  promoCodeHistories: Array<PromoCodeHistory>;
  promoCodeToPromoCodeGroups: Array<PromoCodeToPromoCodeGroup>;
  startsAt: Scalars["DateTime"]["output"];
  storyActivities: Array<StoryActivity>;
  updatedAt: Scalars["DateTime"]["output"];
  workshops: Array<Workshop>;
};


export type PromoCodeActivitiesArgs = {
  cursor?: InputMaybe<ActivityWhereUniqueInput>;
  distinct?: InputMaybe<Array<ActivityScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ActivityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ActivityWhereInput>;
};


export type PromoCodePromoCodeHistoriesArgs = {
  cursor?: InputMaybe<PromoCodeHistoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<PromoCodeHistoryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PromoCodeHistoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<PromoCodeHistoryWhereInput>;
};


export type PromoCodePromoCodeToPromoCodeGroupsArgs = {
  cursor?: InputMaybe<PromoCodeToPromoCodeGroupWhereUniqueInput>;
  distinct?: InputMaybe<Array<PromoCodeToPromoCodeGroupScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PromoCodeToPromoCodeGroupOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<PromoCodeToPromoCodeGroupWhereInput>;
};


export type PromoCodeStoryActivitiesArgs = {
  cursor?: InputMaybe<StoryActivityWhereUniqueInput>;
  distinct?: InputMaybe<Array<StoryActivityScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StoryActivityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<StoryActivityWhereInput>;
};


export type PromoCodeWorkshopsArgs = {
  cursor?: InputMaybe<WorkshopWhereUniqueInput>;
  distinct?: InputMaybe<Array<WorkshopScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<WorkshopOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<WorkshopWhereInput>;
};

export type PromoCodeAvgAggregate = {
  __typename?: "PromoCodeAvgAggregate";
  coins?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
};

export type PromoCodeCount = {
  __typename?: "PromoCodeCount";
  activities: Scalars["Int"]["output"];
  promoCodeHistories: Scalars["Int"]["output"];
  promoCodeToPromoCodeGroups: Scalars["Int"]["output"];
  storyActivities: Scalars["Int"]["output"];
  workshops: Scalars["Int"]["output"];
};


export type PromoCodeCountActivitiesArgs = {
  where?: InputMaybe<ActivityWhereInput>;
};


export type PromoCodeCountPromoCodeHistoriesArgs = {
  where?: InputMaybe<PromoCodeHistoryWhereInput>;
};


export type PromoCodeCountPromoCodeToPromoCodeGroupsArgs = {
  where?: InputMaybe<PromoCodeToPromoCodeGroupWhereInput>;
};


export type PromoCodeCountStoryActivitiesArgs = {
  where?: InputMaybe<StoryActivityWhereInput>;
};


export type PromoCodeCountWorkshopsArgs = {
  where?: InputMaybe<WorkshopWhereInput>;
};

export type PromoCodeCountAggregate = {
  __typename?: "PromoCodeCountAggregate";
  _all: Scalars["Int"]["output"];
  code: Scalars["Int"]["output"];
  coins: Scalars["Int"]["output"];
  createdAt: Scalars["Int"]["output"];
  expiresAt: Scalars["Int"]["output"];
  id: Scalars["Int"]["output"];
  isActive: Scalars["Int"]["output"];
  startsAt: Scalars["Int"]["output"];
  updatedAt: Scalars["Int"]["output"];
};

export type PromoCodeCreateInput = {
  activities?: InputMaybe<ActivityCreateNestedManyWithoutPromoCodesInput>;
  code: Scalars["String"]["input"];
  coins?: InputMaybe<Scalars["Int"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  expiresAt: Scalars["DateTime"]["input"];
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  promoCodeHistories?: InputMaybe<PromoCodeHistoryCreateNestedManyWithoutPromoCodeInput>;
  promoCodeToPromoCodeGroups?: InputMaybe<PromoCodeToPromoCodeGroupCreateNestedManyWithoutPromoCodeInput>;
  startsAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  storyActivities?: InputMaybe<StoryActivityCreateNestedManyWithoutPromoCodesInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  workshops?: InputMaybe<WorkshopCreateNestedManyWithoutPromoCodesInput>;
};

export type PromoCodeCreateNestedManyWithoutActivitiesInput = {
  connect?: InputMaybe<Array<PromoCodeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PromoCodeCreateOrConnectWithoutActivitiesInput>>;
  create?: InputMaybe<Array<PromoCodeCreateWithoutActivitiesInput>>;
};

export type PromoCodeCreateNestedManyWithoutStoryActivitiesInput = {
  connect?: InputMaybe<Array<PromoCodeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PromoCodeCreateOrConnectWithoutStoryActivitiesInput>>;
  create?: InputMaybe<Array<PromoCodeCreateWithoutStoryActivitiesInput>>;
};

export type PromoCodeCreateNestedManyWithoutWorkshopsInput = {
  connect?: InputMaybe<Array<PromoCodeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PromoCodeCreateOrConnectWithoutWorkshopsInput>>;
  create?: InputMaybe<Array<PromoCodeCreateWithoutWorkshopsInput>>;
};

export type PromoCodeCreateNestedOneWithoutPromoCodeHistoriesInput = {
  connect?: InputMaybe<PromoCodeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PromoCodeCreateOrConnectWithoutPromoCodeHistoriesInput>;
  create?: InputMaybe<PromoCodeCreateWithoutPromoCodeHistoriesInput>;
};

export type PromoCodeCreateNestedOneWithoutPromoCodeToPromoCodeGroupsInput = {
  connect?: InputMaybe<PromoCodeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PromoCodeCreateOrConnectWithoutPromoCodeToPromoCodeGroupsInput>;
  create?: InputMaybe<PromoCodeCreateWithoutPromoCodeToPromoCodeGroupsInput>;
};

export type PromoCodeCreateOrConnectWithoutActivitiesInput = {
  create: PromoCodeCreateWithoutActivitiesInput;
  where: PromoCodeWhereUniqueInput;
};

export type PromoCodeCreateOrConnectWithoutPromoCodeHistoriesInput = {
  create: PromoCodeCreateWithoutPromoCodeHistoriesInput;
  where: PromoCodeWhereUniqueInput;
};

export type PromoCodeCreateOrConnectWithoutPromoCodeToPromoCodeGroupsInput = {
  create: PromoCodeCreateWithoutPromoCodeToPromoCodeGroupsInput;
  where: PromoCodeWhereUniqueInput;
};

export type PromoCodeCreateOrConnectWithoutStoryActivitiesInput = {
  create: PromoCodeCreateWithoutStoryActivitiesInput;
  where: PromoCodeWhereUniqueInput;
};

export type PromoCodeCreateOrConnectWithoutWorkshopsInput = {
  create: PromoCodeCreateWithoutWorkshopsInput;
  where: PromoCodeWhereUniqueInput;
};

export type PromoCodeCreateWithoutActivitiesInput = {
  code: Scalars["String"]["input"];
  coins?: InputMaybe<Scalars["Int"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  expiresAt: Scalars["DateTime"]["input"];
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  promoCodeHistories?: InputMaybe<PromoCodeHistoryCreateNestedManyWithoutPromoCodeInput>;
  promoCodeToPromoCodeGroups?: InputMaybe<PromoCodeToPromoCodeGroupCreateNestedManyWithoutPromoCodeInput>;
  startsAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  storyActivities?: InputMaybe<StoryActivityCreateNestedManyWithoutPromoCodesInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  workshops?: InputMaybe<WorkshopCreateNestedManyWithoutPromoCodesInput>;
};

export type PromoCodeCreateWithoutPromoCodeHistoriesInput = {
  activities?: InputMaybe<ActivityCreateNestedManyWithoutPromoCodesInput>;
  code: Scalars["String"]["input"];
  coins?: InputMaybe<Scalars["Int"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  expiresAt: Scalars["DateTime"]["input"];
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  promoCodeToPromoCodeGroups?: InputMaybe<PromoCodeToPromoCodeGroupCreateNestedManyWithoutPromoCodeInput>;
  startsAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  storyActivities?: InputMaybe<StoryActivityCreateNestedManyWithoutPromoCodesInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  workshops?: InputMaybe<WorkshopCreateNestedManyWithoutPromoCodesInput>;
};

export type PromoCodeCreateWithoutPromoCodeToPromoCodeGroupsInput = {
  activities?: InputMaybe<ActivityCreateNestedManyWithoutPromoCodesInput>;
  code: Scalars["String"]["input"];
  coins?: InputMaybe<Scalars["Int"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  expiresAt: Scalars["DateTime"]["input"];
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  promoCodeHistories?: InputMaybe<PromoCodeHistoryCreateNestedManyWithoutPromoCodeInput>;
  startsAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  storyActivities?: InputMaybe<StoryActivityCreateNestedManyWithoutPromoCodesInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  workshops?: InputMaybe<WorkshopCreateNestedManyWithoutPromoCodesInput>;
};

export type PromoCodeCreateWithoutStoryActivitiesInput = {
  activities?: InputMaybe<ActivityCreateNestedManyWithoutPromoCodesInput>;
  code: Scalars["String"]["input"];
  coins?: InputMaybe<Scalars["Int"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  expiresAt: Scalars["DateTime"]["input"];
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  promoCodeHistories?: InputMaybe<PromoCodeHistoryCreateNestedManyWithoutPromoCodeInput>;
  promoCodeToPromoCodeGroups?: InputMaybe<PromoCodeToPromoCodeGroupCreateNestedManyWithoutPromoCodeInput>;
  startsAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  workshops?: InputMaybe<WorkshopCreateNestedManyWithoutPromoCodesInput>;
};

export type PromoCodeCreateWithoutWorkshopsInput = {
  activities?: InputMaybe<ActivityCreateNestedManyWithoutPromoCodesInput>;
  code: Scalars["String"]["input"];
  coins?: InputMaybe<Scalars["Int"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  expiresAt: Scalars["DateTime"]["input"];
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  promoCodeHistories?: InputMaybe<PromoCodeHistoryCreateNestedManyWithoutPromoCodeInput>;
  promoCodeToPromoCodeGroups?: InputMaybe<PromoCodeToPromoCodeGroupCreateNestedManyWithoutPromoCodeInput>;
  startsAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  storyActivities?: InputMaybe<StoryActivityCreateNestedManyWithoutPromoCodesInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type PromoCodeGroup = {
  __typename?: "PromoCodeGroup";
  _count?: Maybe<PromoCodeGroupCount>;
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["Int"]["output"];
  limit: Scalars["Int"]["output"];
  name: Scalars["String"]["output"];
  promoCodeToPromoCodeGroups: Array<PromoCodeToPromoCodeGroup>;
  updatedAt: Scalars["DateTime"]["output"];
};


export type PromoCodeGroupPromoCodeToPromoCodeGroupsArgs = {
  cursor?: InputMaybe<PromoCodeToPromoCodeGroupWhereUniqueInput>;
  distinct?: InputMaybe<Array<PromoCodeToPromoCodeGroupScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PromoCodeToPromoCodeGroupOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<PromoCodeToPromoCodeGroupWhereInput>;
};

export type PromoCodeGroupAvgAggregate = {
  __typename?: "PromoCodeGroupAvgAggregate";
  id?: Maybe<Scalars["Float"]["output"]>;
  limit?: Maybe<Scalars["Float"]["output"]>;
};

export type PromoCodeGroupAvgOrderByAggregateInput = {
  id?: InputMaybe<SortOrder>;
  limit?: InputMaybe<SortOrder>;
};

export type PromoCodeGroupCount = {
  __typename?: "PromoCodeGroupCount";
  promoCodeToPromoCodeGroups: Scalars["Int"]["output"];
};


export type PromoCodeGroupCountPromoCodeToPromoCodeGroupsArgs = {
  where?: InputMaybe<PromoCodeToPromoCodeGroupWhereInput>;
};

export type PromoCodeGroupCountAggregate = {
  __typename?: "PromoCodeGroupCountAggregate";
  _all: Scalars["Int"]["output"];
  createdAt: Scalars["Int"]["output"];
  id: Scalars["Int"]["output"];
  limit: Scalars["Int"]["output"];
  name: Scalars["Int"]["output"];
  updatedAt: Scalars["Int"]["output"];
};

export type PromoCodeGroupCountOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  limit?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type PromoCodeGroupCreateInput = {
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  name: Scalars["String"]["input"];
  promoCodeToPromoCodeGroups?: InputMaybe<PromoCodeToPromoCodeGroupCreateNestedManyWithoutPromoCodeGroupInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type PromoCodeGroupCreateNestedOneWithoutPromoCodeToPromoCodeGroupsInput = {
  connect?: InputMaybe<PromoCodeGroupWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PromoCodeGroupCreateOrConnectWithoutPromoCodeToPromoCodeGroupsInput>;
  create?: InputMaybe<PromoCodeGroupCreateWithoutPromoCodeToPromoCodeGroupsInput>;
};

export type PromoCodeGroupCreateOrConnectWithoutPromoCodeToPromoCodeGroupsInput = {
  create: PromoCodeGroupCreateWithoutPromoCodeToPromoCodeGroupsInput;
  where: PromoCodeGroupWhereUniqueInput;
};

export type PromoCodeGroupCreateWithoutPromoCodeToPromoCodeGroupsInput = {
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  name: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type PromoCodeGroupGroupBy = {
  __typename?: "PromoCodeGroupGroupBy";
  _avg?: Maybe<PromoCodeGroupAvgAggregate>;
  _count?: Maybe<PromoCodeGroupCountAggregate>;
  _max?: Maybe<PromoCodeGroupMaxAggregate>;
  _min?: Maybe<PromoCodeGroupMinAggregate>;
  _sum?: Maybe<PromoCodeGroupSumAggregate>;
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["Int"]["output"];
  limit: Scalars["Int"]["output"];
  name: Scalars["String"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export type PromoCodeGroupMaxAggregate = {
  __typename?: "PromoCodeGroupMaxAggregate";
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  limit?: Maybe<Scalars["Int"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type PromoCodeGroupMaxOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  limit?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type PromoCodeGroupMinAggregate = {
  __typename?: "PromoCodeGroupMinAggregate";
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  limit?: Maybe<Scalars["Int"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type PromoCodeGroupMinOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  limit?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type PromoCodeGroupOrderByWithAggregationInput = {
  _avg?: InputMaybe<PromoCodeGroupAvgOrderByAggregateInput>;
  _count?: InputMaybe<PromoCodeGroupCountOrderByAggregateInput>;
  _max?: InputMaybe<PromoCodeGroupMaxOrderByAggregateInput>;
  _min?: InputMaybe<PromoCodeGroupMinOrderByAggregateInput>;
  _sum?: InputMaybe<PromoCodeGroupSumOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  limit?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type PromoCodeGroupOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  limit?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  promoCodeToPromoCodeGroups?: InputMaybe<PromoCodeToPromoCodeGroupOrderByRelationAggregateInput>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type PromoCodeGroupRelationFilter = {
  is?: InputMaybe<PromoCodeGroupWhereInput>;
  isNot?: InputMaybe<PromoCodeGroupWhereInput>;
};

export enum PromoCodeGroupScalarFieldEnum {
  CREATEDAT = "createdAt",
  ID = "id",
  LIMIT = "limit",
  NAME = "name",
  UPDATEDAT = "updatedAt"
}

export type PromoCodeGroupScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<PromoCodeGroupScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<PromoCodeGroupScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<PromoCodeGroupScalarWhereWithAggregatesInput>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<IntWithAggregatesFilter>;
  limit?: InputMaybe<IntWithAggregatesFilter>;
  name?: InputMaybe<StringWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type PromoCodeGroupSumAggregate = {
  __typename?: "PromoCodeGroupSumAggregate";
  id?: Maybe<Scalars["Int"]["output"]>;
  limit?: Maybe<Scalars["Int"]["output"]>;
};

export type PromoCodeGroupSumOrderByAggregateInput = {
  id?: InputMaybe<SortOrder>;
  limit?: InputMaybe<SortOrder>;
};

export type PromoCodeGroupUpdateInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  limit?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  promoCodeToPromoCodeGroups?: InputMaybe<PromoCodeToPromoCodeGroupUpdateManyWithoutPromoCodeGroupNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type PromoCodeGroupUpdateOneRequiredWithoutPromoCodeToPromoCodeGroupsNestedInput = {
  connect?: InputMaybe<PromoCodeGroupWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PromoCodeGroupCreateOrConnectWithoutPromoCodeToPromoCodeGroupsInput>;
  create?: InputMaybe<PromoCodeGroupCreateWithoutPromoCodeToPromoCodeGroupsInput>;
  update?: InputMaybe<PromoCodeGroupUpdateWithoutPromoCodeToPromoCodeGroupsInput>;
  upsert?: InputMaybe<PromoCodeGroupUpsertWithoutPromoCodeToPromoCodeGroupsInput>;
};

export type PromoCodeGroupUpdateWithoutPromoCodeToPromoCodeGroupsInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  limit?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type PromoCodeGroupUpsertWithoutPromoCodeToPromoCodeGroupsInput = {
  create: PromoCodeGroupCreateWithoutPromoCodeToPromoCodeGroupsInput;
  update: PromoCodeGroupUpdateWithoutPromoCodeToPromoCodeGroupsInput;
};

export type PromoCodeGroupWhereInput = {
  AND?: InputMaybe<Array<PromoCodeGroupWhereInput>>;
  NOT?: InputMaybe<Array<PromoCodeGroupWhereInput>>;
  OR?: InputMaybe<Array<PromoCodeGroupWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  limit?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  promoCodeToPromoCodeGroups?: InputMaybe<PromoCodeToPromoCodeGroupListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type PromoCodeGroupWhereUniqueInput = {
  id?: InputMaybe<Scalars["Int"]["input"]>;
};

export type PromoCodeHistory = {
  __typename?: "PromoCodeHistory";
  coins: Scalars["Int"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["Int"]["output"];
  promoCode: PromoCode;
  promoCodeId: Scalars["Int"]["output"];
  student: Student;
  studentId: Scalars["Int"]["output"];
};

export type PromoCodeHistoryAvgAggregate = {
  __typename?: "PromoCodeHistoryAvgAggregate";
  coins?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  promoCodeId?: Maybe<Scalars["Float"]["output"]>;
  studentId?: Maybe<Scalars["Float"]["output"]>;
};

export type PromoCodeHistoryCountAggregate = {
  __typename?: "PromoCodeHistoryCountAggregate";
  _all: Scalars["Int"]["output"];
  coins: Scalars["Int"]["output"];
  createdAt: Scalars["Int"]["output"];
  id: Scalars["Int"]["output"];
  promoCodeId: Scalars["Int"]["output"];
  studentId: Scalars["Int"]["output"];
};

export type PromoCodeHistoryCreateInput = {
  coins?: InputMaybe<Scalars["Int"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  promoCode: PromoCodeCreateNestedOneWithoutPromoCodeHistoriesInput;
  student: StudentCreateNestedOneWithoutPromoCodeHistoryInput;
};

export type PromoCodeHistoryCreateManyPromoCodeInput = {
  coins?: InputMaybe<Scalars["Int"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  studentId: Scalars["Int"]["input"];
};

export type PromoCodeHistoryCreateManyPromoCodeInputEnvelope = {
  data: Array<PromoCodeHistoryCreateManyPromoCodeInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type PromoCodeHistoryCreateManyStudentInput = {
  coins?: InputMaybe<Scalars["Int"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  promoCodeId: Scalars["Int"]["input"];
};

export type PromoCodeHistoryCreateManyStudentInputEnvelope = {
  data: Array<PromoCodeHistoryCreateManyStudentInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type PromoCodeHistoryCreateNestedManyWithoutPromoCodeInput = {
  connect?: InputMaybe<Array<PromoCodeHistoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PromoCodeHistoryCreateOrConnectWithoutPromoCodeInput>>;
  create?: InputMaybe<Array<PromoCodeHistoryCreateWithoutPromoCodeInput>>;
  createMany?: InputMaybe<PromoCodeHistoryCreateManyPromoCodeInputEnvelope>;
};

export type PromoCodeHistoryCreateNestedManyWithoutStudentInput = {
  connect?: InputMaybe<Array<PromoCodeHistoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PromoCodeHistoryCreateOrConnectWithoutStudentInput>>;
  create?: InputMaybe<Array<PromoCodeHistoryCreateWithoutStudentInput>>;
  createMany?: InputMaybe<PromoCodeHistoryCreateManyStudentInputEnvelope>;
};

export type PromoCodeHistoryCreateOrConnectWithoutPromoCodeInput = {
  create: PromoCodeHistoryCreateWithoutPromoCodeInput;
  where: PromoCodeHistoryWhereUniqueInput;
};

export type PromoCodeHistoryCreateOrConnectWithoutStudentInput = {
  create: PromoCodeHistoryCreateWithoutStudentInput;
  where: PromoCodeHistoryWhereUniqueInput;
};

export type PromoCodeHistoryCreateWithoutPromoCodeInput = {
  coins?: InputMaybe<Scalars["Int"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  student: StudentCreateNestedOneWithoutPromoCodeHistoryInput;
};

export type PromoCodeHistoryCreateWithoutStudentInput = {
  coins?: InputMaybe<Scalars["Int"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  promoCode: PromoCodeCreateNestedOneWithoutPromoCodeHistoriesInput;
};

export type PromoCodeHistoryListRelationFilter = {
  every?: InputMaybe<PromoCodeHistoryWhereInput>;
  none?: InputMaybe<PromoCodeHistoryWhereInput>;
  some?: InputMaybe<PromoCodeHistoryWhereInput>;
};

export type PromoCodeHistoryMaxAggregate = {
  __typename?: "PromoCodeHistoryMaxAggregate";
  coins?: Maybe<Scalars["Int"]["output"]>;
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  promoCodeId?: Maybe<Scalars["Int"]["output"]>;
  studentId?: Maybe<Scalars["Int"]["output"]>;
};

export type PromoCodeHistoryMinAggregate = {
  __typename?: "PromoCodeHistoryMinAggregate";
  coins?: Maybe<Scalars["Int"]["output"]>;
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  promoCodeId?: Maybe<Scalars["Int"]["output"]>;
  studentId?: Maybe<Scalars["Int"]["output"]>;
};

export type PromoCodeHistoryOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type PromoCodeHistoryOrderByWithRelationInput = {
  coins?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  promoCode?: InputMaybe<PromoCodeOrderByWithRelationInput>;
  promoCodeId?: InputMaybe<SortOrder>;
  student?: InputMaybe<StudentOrderByWithRelationInput>;
  studentId?: InputMaybe<SortOrder>;
};

export enum PromoCodeHistoryScalarFieldEnum {
  COINS = "coins",
  CREATEDAT = "createdAt",
  ID = "id",
  PROMOCODEID = "promoCodeId",
  STUDENTID = "studentId"
}

export type PromoCodeHistoryScalarWhereInput = {
  AND?: InputMaybe<Array<PromoCodeHistoryScalarWhereInput>>;
  NOT?: InputMaybe<Array<PromoCodeHistoryScalarWhereInput>>;
  OR?: InputMaybe<Array<PromoCodeHistoryScalarWhereInput>>;
  coins?: InputMaybe<IntFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  promoCodeId?: InputMaybe<IntFilter>;
  studentId?: InputMaybe<IntFilter>;
};

export type PromoCodeHistorySumAggregate = {
  __typename?: "PromoCodeHistorySumAggregate";
  coins?: Maybe<Scalars["Int"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  promoCodeId?: Maybe<Scalars["Int"]["output"]>;
  studentId?: Maybe<Scalars["Int"]["output"]>;
};

export type PromoCodeHistoryUpdateInput = {
  coins?: InputMaybe<IntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  promoCode?: InputMaybe<PromoCodeUpdateOneRequiredWithoutPromoCodeHistoriesNestedInput>;
  student?: InputMaybe<StudentUpdateOneRequiredWithoutPromoCodeHistoryNestedInput>;
};

export type PromoCodeHistoryUpdateManyMutationInput = {
  coins?: InputMaybe<IntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type PromoCodeHistoryUpdateManyWithWhereWithoutPromoCodeInput = {
  data: PromoCodeHistoryUpdateManyMutationInput;
  where: PromoCodeHistoryScalarWhereInput;
};

export type PromoCodeHistoryUpdateManyWithWhereWithoutStudentInput = {
  data: PromoCodeHistoryUpdateManyMutationInput;
  where: PromoCodeHistoryScalarWhereInput;
};

export type PromoCodeHistoryUpdateManyWithoutPromoCodeNestedInput = {
  connect?: InputMaybe<Array<PromoCodeHistoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PromoCodeHistoryCreateOrConnectWithoutPromoCodeInput>>;
  create?: InputMaybe<Array<PromoCodeHistoryCreateWithoutPromoCodeInput>>;
  createMany?: InputMaybe<PromoCodeHistoryCreateManyPromoCodeInputEnvelope>;
  delete?: InputMaybe<Array<PromoCodeHistoryWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PromoCodeHistoryScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PromoCodeHistoryWhereUniqueInput>>;
  set?: InputMaybe<Array<PromoCodeHistoryWhereUniqueInput>>;
  update?: InputMaybe<Array<PromoCodeHistoryUpdateWithWhereUniqueWithoutPromoCodeInput>>;
  updateMany?: InputMaybe<Array<PromoCodeHistoryUpdateManyWithWhereWithoutPromoCodeInput>>;
  upsert?: InputMaybe<Array<PromoCodeHistoryUpsertWithWhereUniqueWithoutPromoCodeInput>>;
};

export type PromoCodeHistoryUpdateManyWithoutStudentNestedInput = {
  connect?: InputMaybe<Array<PromoCodeHistoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PromoCodeHistoryCreateOrConnectWithoutStudentInput>>;
  create?: InputMaybe<Array<PromoCodeHistoryCreateWithoutStudentInput>>;
  createMany?: InputMaybe<PromoCodeHistoryCreateManyStudentInputEnvelope>;
  delete?: InputMaybe<Array<PromoCodeHistoryWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PromoCodeHistoryScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PromoCodeHistoryWhereUniqueInput>>;
  set?: InputMaybe<Array<PromoCodeHistoryWhereUniqueInput>>;
  update?: InputMaybe<Array<PromoCodeHistoryUpdateWithWhereUniqueWithoutStudentInput>>;
  updateMany?: InputMaybe<Array<PromoCodeHistoryUpdateManyWithWhereWithoutStudentInput>>;
  upsert?: InputMaybe<Array<PromoCodeHistoryUpsertWithWhereUniqueWithoutStudentInput>>;
};

export type PromoCodeHistoryUpdateWithWhereUniqueWithoutPromoCodeInput = {
  data: PromoCodeHistoryUpdateWithoutPromoCodeInput;
  where: PromoCodeHistoryWhereUniqueInput;
};

export type PromoCodeHistoryUpdateWithWhereUniqueWithoutStudentInput = {
  data: PromoCodeHistoryUpdateWithoutStudentInput;
  where: PromoCodeHistoryWhereUniqueInput;
};

export type PromoCodeHistoryUpdateWithoutPromoCodeInput = {
  coins?: InputMaybe<IntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  student?: InputMaybe<StudentUpdateOneRequiredWithoutPromoCodeHistoryNestedInput>;
};

export type PromoCodeHistoryUpdateWithoutStudentInput = {
  coins?: InputMaybe<IntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  promoCode?: InputMaybe<PromoCodeUpdateOneRequiredWithoutPromoCodeHistoriesNestedInput>;
};

export type PromoCodeHistoryUpsertWithWhereUniqueWithoutPromoCodeInput = {
  create: PromoCodeHistoryCreateWithoutPromoCodeInput;
  update: PromoCodeHistoryUpdateWithoutPromoCodeInput;
  where: PromoCodeHistoryWhereUniqueInput;
};

export type PromoCodeHistoryUpsertWithWhereUniqueWithoutStudentInput = {
  create: PromoCodeHistoryCreateWithoutStudentInput;
  update: PromoCodeHistoryUpdateWithoutStudentInput;
  where: PromoCodeHistoryWhereUniqueInput;
};

export type PromoCodeHistoryWhereInput = {
  AND?: InputMaybe<Array<PromoCodeHistoryWhereInput>>;
  NOT?: InputMaybe<Array<PromoCodeHistoryWhereInput>>;
  OR?: InputMaybe<Array<PromoCodeHistoryWhereInput>>;
  coins?: InputMaybe<IntFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  promoCode?: InputMaybe<PromoCodeRelationFilter>;
  promoCodeId?: InputMaybe<IntFilter>;
  student?: InputMaybe<StudentRelationFilter>;
  studentId?: InputMaybe<IntFilter>;
};

export type PromoCodeHistoryWhereUniqueInput = {
  id?: InputMaybe<Scalars["Int"]["input"]>;
};

export type PromoCodeListRelationFilter = {
  every?: InputMaybe<PromoCodeWhereInput>;
  none?: InputMaybe<PromoCodeWhereInput>;
  some?: InputMaybe<PromoCodeWhereInput>;
};

export type PromoCodeMaxAggregate = {
  __typename?: "PromoCodeMaxAggregate";
  code?: Maybe<Scalars["String"]["output"]>;
  coins?: Maybe<Scalars["Int"]["output"]>;
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  expiresAt?: Maybe<Scalars["DateTime"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  isActive?: Maybe<Scalars["Boolean"]["output"]>;
  startsAt?: Maybe<Scalars["DateTime"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type PromoCodeMinAggregate = {
  __typename?: "PromoCodeMinAggregate";
  code?: Maybe<Scalars["String"]["output"]>;
  coins?: Maybe<Scalars["Int"]["output"]>;
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  expiresAt?: Maybe<Scalars["DateTime"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  isActive?: Maybe<Scalars["Boolean"]["output"]>;
  startsAt?: Maybe<Scalars["DateTime"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type PromoCodeOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type PromoCodeOrderByWithRelationInput = {
  activities?: InputMaybe<ActivityOrderByRelationAggregateInput>;
  code?: InputMaybe<SortOrder>;
  coins?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  expiresAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isActive?: InputMaybe<SortOrder>;
  promoCodeHistories?: InputMaybe<PromoCodeHistoryOrderByRelationAggregateInput>;
  promoCodeToPromoCodeGroups?: InputMaybe<PromoCodeToPromoCodeGroupOrderByRelationAggregateInput>;
  startsAt?: InputMaybe<SortOrder>;
  storyActivities?: InputMaybe<StoryActivityOrderByRelationAggregateInput>;
  updatedAt?: InputMaybe<SortOrder>;
  workshops?: InputMaybe<WorkshopOrderByRelationAggregateInput>;
};

export type PromoCodeRelationFilter = {
  is?: InputMaybe<PromoCodeWhereInput>;
  isNot?: InputMaybe<PromoCodeWhereInput>;
};

export enum PromoCodeScalarFieldEnum {
  CODE = "code",
  COINS = "coins",
  CREATEDAT = "createdAt",
  EXPIRESAT = "expiresAt",
  ID = "id",
  ISACTIVE = "isActive",
  STARTSAT = "startsAt",
  UPDATEDAT = "updatedAt"
}

export type PromoCodeScalarWhereInput = {
  AND?: InputMaybe<Array<PromoCodeScalarWhereInput>>;
  NOT?: InputMaybe<Array<PromoCodeScalarWhereInput>>;
  OR?: InputMaybe<Array<PromoCodeScalarWhereInput>>;
  code?: InputMaybe<StringFilter>;
  coins?: InputMaybe<IntFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  expiresAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  isActive?: InputMaybe<BoolFilter>;
  startsAt?: InputMaybe<DateTimeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type PromoCodeSumAggregate = {
  __typename?: "PromoCodeSumAggregate";
  coins?: Maybe<Scalars["Int"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
};

export type PromoCodeToPromoCodeGroup = {
  __typename?: "PromoCodeToPromoCodeGroup";
  A: Scalars["Int"]["output"];
  B: Scalars["Int"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  promoCode: PromoCode;
  promoCodeGroup: PromoCodeGroup;
};

export type PromoCodeToPromoCodeGroupAbCompoundUniqueInput = {
  A: Scalars["Int"]["input"];
  B: Scalars["Int"]["input"];
};

export type PromoCodeToPromoCodeGroupAvgAggregate = {
  __typename?: "PromoCodeToPromoCodeGroupAvgAggregate";
  A?: Maybe<Scalars["Float"]["output"]>;
  B?: Maybe<Scalars["Float"]["output"]>;
};

export type PromoCodeToPromoCodeGroupAvgOrderByAggregateInput = {
  A?: InputMaybe<SortOrder>;
  B?: InputMaybe<SortOrder>;
};

export type PromoCodeToPromoCodeGroupCountAggregate = {
  __typename?: "PromoCodeToPromoCodeGroupCountAggregate";
  A: Scalars["Int"]["output"];
  B: Scalars["Int"]["output"];
  _all: Scalars["Int"]["output"];
  createdAt: Scalars["Int"]["output"];
};

export type PromoCodeToPromoCodeGroupCountOrderByAggregateInput = {
  A?: InputMaybe<SortOrder>;
  B?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
};

export type PromoCodeToPromoCodeGroupCreateInput = {
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  promoCode: PromoCodeCreateNestedOneWithoutPromoCodeToPromoCodeGroupsInput;
  promoCodeGroup: PromoCodeGroupCreateNestedOneWithoutPromoCodeToPromoCodeGroupsInput;
};

export type PromoCodeToPromoCodeGroupCreateManyInput = {
  A: Scalars["Int"]["input"];
  B: Scalars["Int"]["input"];
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type PromoCodeToPromoCodeGroupCreateManyPromoCodeGroupInput = {
  A: Scalars["Int"]["input"];
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type PromoCodeToPromoCodeGroupCreateManyPromoCodeGroupInputEnvelope = {
  data: Array<PromoCodeToPromoCodeGroupCreateManyPromoCodeGroupInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type PromoCodeToPromoCodeGroupCreateManyPromoCodeInput = {
  B: Scalars["Int"]["input"];
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type PromoCodeToPromoCodeGroupCreateManyPromoCodeInputEnvelope = {
  data: Array<PromoCodeToPromoCodeGroupCreateManyPromoCodeInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type PromoCodeToPromoCodeGroupCreateNestedManyWithoutPromoCodeGroupInput = {
  connect?: InputMaybe<Array<PromoCodeToPromoCodeGroupWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PromoCodeToPromoCodeGroupCreateOrConnectWithoutPromoCodeGroupInput>>;
  create?: InputMaybe<Array<PromoCodeToPromoCodeGroupCreateWithoutPromoCodeGroupInput>>;
  createMany?: InputMaybe<PromoCodeToPromoCodeGroupCreateManyPromoCodeGroupInputEnvelope>;
};

export type PromoCodeToPromoCodeGroupCreateNestedManyWithoutPromoCodeInput = {
  connect?: InputMaybe<Array<PromoCodeToPromoCodeGroupWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PromoCodeToPromoCodeGroupCreateOrConnectWithoutPromoCodeInput>>;
  create?: InputMaybe<Array<PromoCodeToPromoCodeGroupCreateWithoutPromoCodeInput>>;
  createMany?: InputMaybe<PromoCodeToPromoCodeGroupCreateManyPromoCodeInputEnvelope>;
};

export type PromoCodeToPromoCodeGroupCreateOrConnectWithoutPromoCodeGroupInput = {
  create: PromoCodeToPromoCodeGroupCreateWithoutPromoCodeGroupInput;
  where: PromoCodeToPromoCodeGroupWhereUniqueInput;
};

export type PromoCodeToPromoCodeGroupCreateOrConnectWithoutPromoCodeInput = {
  create: PromoCodeToPromoCodeGroupCreateWithoutPromoCodeInput;
  where: PromoCodeToPromoCodeGroupWhereUniqueInput;
};

export type PromoCodeToPromoCodeGroupCreateWithoutPromoCodeGroupInput = {
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  promoCode: PromoCodeCreateNestedOneWithoutPromoCodeToPromoCodeGroupsInput;
};

export type PromoCodeToPromoCodeGroupCreateWithoutPromoCodeInput = {
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  promoCodeGroup: PromoCodeGroupCreateNestedOneWithoutPromoCodeToPromoCodeGroupsInput;
};

export type PromoCodeToPromoCodeGroupGroupBy = {
  __typename?: "PromoCodeToPromoCodeGroupGroupBy";
  A: Scalars["Int"]["output"];
  B: Scalars["Int"]["output"];
  _avg?: Maybe<PromoCodeToPromoCodeGroupAvgAggregate>;
  _count?: Maybe<PromoCodeToPromoCodeGroupCountAggregate>;
  _max?: Maybe<PromoCodeToPromoCodeGroupMaxAggregate>;
  _min?: Maybe<PromoCodeToPromoCodeGroupMinAggregate>;
  _sum?: Maybe<PromoCodeToPromoCodeGroupSumAggregate>;
  createdAt: Scalars["DateTime"]["output"];
};

export type PromoCodeToPromoCodeGroupListRelationFilter = {
  every?: InputMaybe<PromoCodeToPromoCodeGroupWhereInput>;
  none?: InputMaybe<PromoCodeToPromoCodeGroupWhereInput>;
  some?: InputMaybe<PromoCodeToPromoCodeGroupWhereInput>;
};

export type PromoCodeToPromoCodeGroupMaxAggregate = {
  __typename?: "PromoCodeToPromoCodeGroupMaxAggregate";
  A?: Maybe<Scalars["Int"]["output"]>;
  B?: Maybe<Scalars["Int"]["output"]>;
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type PromoCodeToPromoCodeGroupMaxOrderByAggregateInput = {
  A?: InputMaybe<SortOrder>;
  B?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
};

export type PromoCodeToPromoCodeGroupMinAggregate = {
  __typename?: "PromoCodeToPromoCodeGroupMinAggregate";
  A?: Maybe<Scalars["Int"]["output"]>;
  B?: Maybe<Scalars["Int"]["output"]>;
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type PromoCodeToPromoCodeGroupMinOrderByAggregateInput = {
  A?: InputMaybe<SortOrder>;
  B?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
};

export type PromoCodeToPromoCodeGroupOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type PromoCodeToPromoCodeGroupOrderByWithAggregationInput = {
  A?: InputMaybe<SortOrder>;
  B?: InputMaybe<SortOrder>;
  _avg?: InputMaybe<PromoCodeToPromoCodeGroupAvgOrderByAggregateInput>;
  _count?: InputMaybe<PromoCodeToPromoCodeGroupCountOrderByAggregateInput>;
  _max?: InputMaybe<PromoCodeToPromoCodeGroupMaxOrderByAggregateInput>;
  _min?: InputMaybe<PromoCodeToPromoCodeGroupMinOrderByAggregateInput>;
  _sum?: InputMaybe<PromoCodeToPromoCodeGroupSumOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
};

export type PromoCodeToPromoCodeGroupOrderByWithRelationInput = {
  A?: InputMaybe<SortOrder>;
  B?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  promoCode?: InputMaybe<PromoCodeOrderByWithRelationInput>;
  promoCodeGroup?: InputMaybe<PromoCodeGroupOrderByWithRelationInput>;
};

export enum PromoCodeToPromoCodeGroupScalarFieldEnum {
  A = "A",
  B = "B",
  CREATEDAT = "createdAt"
}

export type PromoCodeToPromoCodeGroupScalarWhereInput = {
  A?: InputMaybe<IntFilter>;
  AND?: InputMaybe<Array<PromoCodeToPromoCodeGroupScalarWhereInput>>;
  B?: InputMaybe<IntFilter>;
  NOT?: InputMaybe<Array<PromoCodeToPromoCodeGroupScalarWhereInput>>;
  OR?: InputMaybe<Array<PromoCodeToPromoCodeGroupScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
};

export type PromoCodeToPromoCodeGroupScalarWhereWithAggregatesInput = {
  A?: InputMaybe<IntWithAggregatesFilter>;
  AND?: InputMaybe<Array<PromoCodeToPromoCodeGroupScalarWhereWithAggregatesInput>>;
  B?: InputMaybe<IntWithAggregatesFilter>;
  NOT?: InputMaybe<Array<PromoCodeToPromoCodeGroupScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<PromoCodeToPromoCodeGroupScalarWhereWithAggregatesInput>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type PromoCodeToPromoCodeGroupSumAggregate = {
  __typename?: "PromoCodeToPromoCodeGroupSumAggregate";
  A?: Maybe<Scalars["Int"]["output"]>;
  B?: Maybe<Scalars["Int"]["output"]>;
};

export type PromoCodeToPromoCodeGroupSumOrderByAggregateInput = {
  A?: InputMaybe<SortOrder>;
  B?: InputMaybe<SortOrder>;
};

export type PromoCodeToPromoCodeGroupUpdateInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  promoCode?: InputMaybe<PromoCodeUpdateOneRequiredWithoutPromoCodeToPromoCodeGroupsNestedInput>;
  promoCodeGroup?: InputMaybe<PromoCodeGroupUpdateOneRequiredWithoutPromoCodeToPromoCodeGroupsNestedInput>;
};

export type PromoCodeToPromoCodeGroupUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type PromoCodeToPromoCodeGroupUpdateManyWithWhereWithoutPromoCodeGroupInput = {
  data: PromoCodeToPromoCodeGroupUpdateManyMutationInput;
  where: PromoCodeToPromoCodeGroupScalarWhereInput;
};

export type PromoCodeToPromoCodeGroupUpdateManyWithWhereWithoutPromoCodeInput = {
  data: PromoCodeToPromoCodeGroupUpdateManyMutationInput;
  where: PromoCodeToPromoCodeGroupScalarWhereInput;
};

export type PromoCodeToPromoCodeGroupUpdateManyWithoutPromoCodeGroupNestedInput = {
  connect?: InputMaybe<Array<PromoCodeToPromoCodeGroupWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PromoCodeToPromoCodeGroupCreateOrConnectWithoutPromoCodeGroupInput>>;
  create?: InputMaybe<Array<PromoCodeToPromoCodeGroupCreateWithoutPromoCodeGroupInput>>;
  createMany?: InputMaybe<PromoCodeToPromoCodeGroupCreateManyPromoCodeGroupInputEnvelope>;
  delete?: InputMaybe<Array<PromoCodeToPromoCodeGroupWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PromoCodeToPromoCodeGroupScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PromoCodeToPromoCodeGroupWhereUniqueInput>>;
  set?: InputMaybe<Array<PromoCodeToPromoCodeGroupWhereUniqueInput>>;
  update?: InputMaybe<Array<PromoCodeToPromoCodeGroupUpdateWithWhereUniqueWithoutPromoCodeGroupInput>>;
  updateMany?: InputMaybe<Array<PromoCodeToPromoCodeGroupUpdateManyWithWhereWithoutPromoCodeGroupInput>>;
  upsert?: InputMaybe<Array<PromoCodeToPromoCodeGroupUpsertWithWhereUniqueWithoutPromoCodeGroupInput>>;
};

export type PromoCodeToPromoCodeGroupUpdateManyWithoutPromoCodeNestedInput = {
  connect?: InputMaybe<Array<PromoCodeToPromoCodeGroupWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PromoCodeToPromoCodeGroupCreateOrConnectWithoutPromoCodeInput>>;
  create?: InputMaybe<Array<PromoCodeToPromoCodeGroupCreateWithoutPromoCodeInput>>;
  createMany?: InputMaybe<PromoCodeToPromoCodeGroupCreateManyPromoCodeInputEnvelope>;
  delete?: InputMaybe<Array<PromoCodeToPromoCodeGroupWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PromoCodeToPromoCodeGroupScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PromoCodeToPromoCodeGroupWhereUniqueInput>>;
  set?: InputMaybe<Array<PromoCodeToPromoCodeGroupWhereUniqueInput>>;
  update?: InputMaybe<Array<PromoCodeToPromoCodeGroupUpdateWithWhereUniqueWithoutPromoCodeInput>>;
  updateMany?: InputMaybe<Array<PromoCodeToPromoCodeGroupUpdateManyWithWhereWithoutPromoCodeInput>>;
  upsert?: InputMaybe<Array<PromoCodeToPromoCodeGroupUpsertWithWhereUniqueWithoutPromoCodeInput>>;
};

export type PromoCodeToPromoCodeGroupUpdateWithWhereUniqueWithoutPromoCodeGroupInput = {
  data: PromoCodeToPromoCodeGroupUpdateWithoutPromoCodeGroupInput;
  where: PromoCodeToPromoCodeGroupWhereUniqueInput;
};

export type PromoCodeToPromoCodeGroupUpdateWithWhereUniqueWithoutPromoCodeInput = {
  data: PromoCodeToPromoCodeGroupUpdateWithoutPromoCodeInput;
  where: PromoCodeToPromoCodeGroupWhereUniqueInput;
};

export type PromoCodeToPromoCodeGroupUpdateWithoutPromoCodeGroupInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  promoCode?: InputMaybe<PromoCodeUpdateOneRequiredWithoutPromoCodeToPromoCodeGroupsNestedInput>;
};

export type PromoCodeToPromoCodeGroupUpdateWithoutPromoCodeInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  promoCodeGroup?: InputMaybe<PromoCodeGroupUpdateOneRequiredWithoutPromoCodeToPromoCodeGroupsNestedInput>;
};

export type PromoCodeToPromoCodeGroupUpsertWithWhereUniqueWithoutPromoCodeGroupInput = {
  create: PromoCodeToPromoCodeGroupCreateWithoutPromoCodeGroupInput;
  update: PromoCodeToPromoCodeGroupUpdateWithoutPromoCodeGroupInput;
  where: PromoCodeToPromoCodeGroupWhereUniqueInput;
};

export type PromoCodeToPromoCodeGroupUpsertWithWhereUniqueWithoutPromoCodeInput = {
  create: PromoCodeToPromoCodeGroupCreateWithoutPromoCodeInput;
  update: PromoCodeToPromoCodeGroupUpdateWithoutPromoCodeInput;
  where: PromoCodeToPromoCodeGroupWhereUniqueInput;
};

export type PromoCodeToPromoCodeGroupWhereInput = {
  A?: InputMaybe<IntFilter>;
  AND?: InputMaybe<Array<PromoCodeToPromoCodeGroupWhereInput>>;
  B?: InputMaybe<IntFilter>;
  NOT?: InputMaybe<Array<PromoCodeToPromoCodeGroupWhereInput>>;
  OR?: InputMaybe<Array<PromoCodeToPromoCodeGroupWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  promoCode?: InputMaybe<PromoCodeRelationFilter>;
  promoCodeGroup?: InputMaybe<PromoCodeGroupRelationFilter>;
};

export type PromoCodeToPromoCodeGroupWhereUniqueInput = {
  A_B?: InputMaybe<PromoCodeToPromoCodeGroupAbCompoundUniqueInput>;
};

export type PromoCodeUpdateInput = {
  activities?: InputMaybe<ActivityUpdateManyWithoutPromoCodesNestedInput>;
  code?: InputMaybe<StringFieldUpdateOperationsInput>;
  coins?: InputMaybe<IntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  expiresAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  promoCodeHistories?: InputMaybe<PromoCodeHistoryUpdateManyWithoutPromoCodeNestedInput>;
  promoCodeToPromoCodeGroups?: InputMaybe<PromoCodeToPromoCodeGroupUpdateManyWithoutPromoCodeNestedInput>;
  startsAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  storyActivities?: InputMaybe<StoryActivityUpdateManyWithoutPromoCodesNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  workshops?: InputMaybe<WorkshopUpdateManyWithoutPromoCodesNestedInput>;
};

export type PromoCodeUpdateManyMutationInput = {
  code?: InputMaybe<StringFieldUpdateOperationsInput>;
  coins?: InputMaybe<IntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  expiresAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  startsAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type PromoCodeUpdateManyWithWhereWithoutActivitiesInput = {
  data: PromoCodeUpdateManyMutationInput;
  where: PromoCodeScalarWhereInput;
};

export type PromoCodeUpdateManyWithWhereWithoutStoryActivitiesInput = {
  data: PromoCodeUpdateManyMutationInput;
  where: PromoCodeScalarWhereInput;
};

export type PromoCodeUpdateManyWithWhereWithoutWorkshopsInput = {
  data: PromoCodeUpdateManyMutationInput;
  where: PromoCodeScalarWhereInput;
};

export type PromoCodeUpdateManyWithoutActivitiesNestedInput = {
  connect?: InputMaybe<Array<PromoCodeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PromoCodeCreateOrConnectWithoutActivitiesInput>>;
  create?: InputMaybe<Array<PromoCodeCreateWithoutActivitiesInput>>;
  delete?: InputMaybe<Array<PromoCodeWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PromoCodeScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PromoCodeWhereUniqueInput>>;
  set?: InputMaybe<Array<PromoCodeWhereUniqueInput>>;
  update?: InputMaybe<Array<PromoCodeUpdateWithWhereUniqueWithoutActivitiesInput>>;
  updateMany?: InputMaybe<Array<PromoCodeUpdateManyWithWhereWithoutActivitiesInput>>;
  upsert?: InputMaybe<Array<PromoCodeUpsertWithWhereUniqueWithoutActivitiesInput>>;
};

export type PromoCodeUpdateManyWithoutStoryActivitiesNestedInput = {
  connect?: InputMaybe<Array<PromoCodeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PromoCodeCreateOrConnectWithoutStoryActivitiesInput>>;
  create?: InputMaybe<Array<PromoCodeCreateWithoutStoryActivitiesInput>>;
  delete?: InputMaybe<Array<PromoCodeWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PromoCodeScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PromoCodeWhereUniqueInput>>;
  set?: InputMaybe<Array<PromoCodeWhereUniqueInput>>;
  update?: InputMaybe<Array<PromoCodeUpdateWithWhereUniqueWithoutStoryActivitiesInput>>;
  updateMany?: InputMaybe<Array<PromoCodeUpdateManyWithWhereWithoutStoryActivitiesInput>>;
  upsert?: InputMaybe<Array<PromoCodeUpsertWithWhereUniqueWithoutStoryActivitiesInput>>;
};

export type PromoCodeUpdateManyWithoutWorkshopsNestedInput = {
  connect?: InputMaybe<Array<PromoCodeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PromoCodeCreateOrConnectWithoutWorkshopsInput>>;
  create?: InputMaybe<Array<PromoCodeCreateWithoutWorkshopsInput>>;
  delete?: InputMaybe<Array<PromoCodeWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PromoCodeScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PromoCodeWhereUniqueInput>>;
  set?: InputMaybe<Array<PromoCodeWhereUniqueInput>>;
  update?: InputMaybe<Array<PromoCodeUpdateWithWhereUniqueWithoutWorkshopsInput>>;
  updateMany?: InputMaybe<Array<PromoCodeUpdateManyWithWhereWithoutWorkshopsInput>>;
  upsert?: InputMaybe<Array<PromoCodeUpsertWithWhereUniqueWithoutWorkshopsInput>>;
};

export type PromoCodeUpdateOneRequiredWithoutPromoCodeHistoriesNestedInput = {
  connect?: InputMaybe<PromoCodeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PromoCodeCreateOrConnectWithoutPromoCodeHistoriesInput>;
  create?: InputMaybe<PromoCodeCreateWithoutPromoCodeHistoriesInput>;
  update?: InputMaybe<PromoCodeUpdateWithoutPromoCodeHistoriesInput>;
  upsert?: InputMaybe<PromoCodeUpsertWithoutPromoCodeHistoriesInput>;
};

export type PromoCodeUpdateOneRequiredWithoutPromoCodeToPromoCodeGroupsNestedInput = {
  connect?: InputMaybe<PromoCodeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PromoCodeCreateOrConnectWithoutPromoCodeToPromoCodeGroupsInput>;
  create?: InputMaybe<PromoCodeCreateWithoutPromoCodeToPromoCodeGroupsInput>;
  update?: InputMaybe<PromoCodeUpdateWithoutPromoCodeToPromoCodeGroupsInput>;
  upsert?: InputMaybe<PromoCodeUpsertWithoutPromoCodeToPromoCodeGroupsInput>;
};

export type PromoCodeUpdateWithWhereUniqueWithoutActivitiesInput = {
  data: PromoCodeUpdateWithoutActivitiesInput;
  where: PromoCodeWhereUniqueInput;
};

export type PromoCodeUpdateWithWhereUniqueWithoutStoryActivitiesInput = {
  data: PromoCodeUpdateWithoutStoryActivitiesInput;
  where: PromoCodeWhereUniqueInput;
};

export type PromoCodeUpdateWithWhereUniqueWithoutWorkshopsInput = {
  data: PromoCodeUpdateWithoutWorkshopsInput;
  where: PromoCodeWhereUniqueInput;
};

export type PromoCodeUpdateWithoutActivitiesInput = {
  code?: InputMaybe<StringFieldUpdateOperationsInput>;
  coins?: InputMaybe<IntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  expiresAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  promoCodeHistories?: InputMaybe<PromoCodeHistoryUpdateManyWithoutPromoCodeNestedInput>;
  promoCodeToPromoCodeGroups?: InputMaybe<PromoCodeToPromoCodeGroupUpdateManyWithoutPromoCodeNestedInput>;
  startsAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  storyActivities?: InputMaybe<StoryActivityUpdateManyWithoutPromoCodesNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  workshops?: InputMaybe<WorkshopUpdateManyWithoutPromoCodesNestedInput>;
};

export type PromoCodeUpdateWithoutPromoCodeHistoriesInput = {
  activities?: InputMaybe<ActivityUpdateManyWithoutPromoCodesNestedInput>;
  code?: InputMaybe<StringFieldUpdateOperationsInput>;
  coins?: InputMaybe<IntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  expiresAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  promoCodeToPromoCodeGroups?: InputMaybe<PromoCodeToPromoCodeGroupUpdateManyWithoutPromoCodeNestedInput>;
  startsAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  storyActivities?: InputMaybe<StoryActivityUpdateManyWithoutPromoCodesNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  workshops?: InputMaybe<WorkshopUpdateManyWithoutPromoCodesNestedInput>;
};

export type PromoCodeUpdateWithoutPromoCodeToPromoCodeGroupsInput = {
  activities?: InputMaybe<ActivityUpdateManyWithoutPromoCodesNestedInput>;
  code?: InputMaybe<StringFieldUpdateOperationsInput>;
  coins?: InputMaybe<IntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  expiresAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  promoCodeHistories?: InputMaybe<PromoCodeHistoryUpdateManyWithoutPromoCodeNestedInput>;
  startsAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  storyActivities?: InputMaybe<StoryActivityUpdateManyWithoutPromoCodesNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  workshops?: InputMaybe<WorkshopUpdateManyWithoutPromoCodesNestedInput>;
};

export type PromoCodeUpdateWithoutStoryActivitiesInput = {
  activities?: InputMaybe<ActivityUpdateManyWithoutPromoCodesNestedInput>;
  code?: InputMaybe<StringFieldUpdateOperationsInput>;
  coins?: InputMaybe<IntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  expiresAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  promoCodeHistories?: InputMaybe<PromoCodeHistoryUpdateManyWithoutPromoCodeNestedInput>;
  promoCodeToPromoCodeGroups?: InputMaybe<PromoCodeToPromoCodeGroupUpdateManyWithoutPromoCodeNestedInput>;
  startsAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  workshops?: InputMaybe<WorkshopUpdateManyWithoutPromoCodesNestedInput>;
};

export type PromoCodeUpdateWithoutWorkshopsInput = {
  activities?: InputMaybe<ActivityUpdateManyWithoutPromoCodesNestedInput>;
  code?: InputMaybe<StringFieldUpdateOperationsInput>;
  coins?: InputMaybe<IntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  expiresAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  promoCodeHistories?: InputMaybe<PromoCodeHistoryUpdateManyWithoutPromoCodeNestedInput>;
  promoCodeToPromoCodeGroups?: InputMaybe<PromoCodeToPromoCodeGroupUpdateManyWithoutPromoCodeNestedInput>;
  startsAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  storyActivities?: InputMaybe<StoryActivityUpdateManyWithoutPromoCodesNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type PromoCodeUpsertWithWhereUniqueWithoutActivitiesInput = {
  create: PromoCodeCreateWithoutActivitiesInput;
  update: PromoCodeUpdateWithoutActivitiesInput;
  where: PromoCodeWhereUniqueInput;
};

export type PromoCodeUpsertWithWhereUniqueWithoutStoryActivitiesInput = {
  create: PromoCodeCreateWithoutStoryActivitiesInput;
  update: PromoCodeUpdateWithoutStoryActivitiesInput;
  where: PromoCodeWhereUniqueInput;
};

export type PromoCodeUpsertWithWhereUniqueWithoutWorkshopsInput = {
  create: PromoCodeCreateWithoutWorkshopsInput;
  update: PromoCodeUpdateWithoutWorkshopsInput;
  where: PromoCodeWhereUniqueInput;
};

export type PromoCodeUpsertWithoutPromoCodeHistoriesInput = {
  create: PromoCodeCreateWithoutPromoCodeHistoriesInput;
  update: PromoCodeUpdateWithoutPromoCodeHistoriesInput;
};

export type PromoCodeUpsertWithoutPromoCodeToPromoCodeGroupsInput = {
  create: PromoCodeCreateWithoutPromoCodeToPromoCodeGroupsInput;
  update: PromoCodeUpdateWithoutPromoCodeToPromoCodeGroupsInput;
};

export type PromoCodeWhereInput = {
  AND?: InputMaybe<Array<PromoCodeWhereInput>>;
  NOT?: InputMaybe<Array<PromoCodeWhereInput>>;
  OR?: InputMaybe<Array<PromoCodeWhereInput>>;
  activities?: InputMaybe<ActivityListRelationFilter>;
  code?: InputMaybe<StringFilter>;
  coins?: InputMaybe<IntFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  expiresAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  isActive?: InputMaybe<BoolFilter>;
  promoCodeHistories?: InputMaybe<PromoCodeHistoryListRelationFilter>;
  promoCodeToPromoCodeGroups?: InputMaybe<PromoCodeToPromoCodeGroupListRelationFilter>;
  startsAt?: InputMaybe<DateTimeFilter>;
  storyActivities?: InputMaybe<StoryActivityListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  workshops?: InputMaybe<WorkshopListRelationFilter>;
};

export type PromoCodeWhereUniqueInput = {
  code?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
};

export type PublicActivity = {
  __typename?: "PublicActivity";
  challengeStatement?: Maybe<Scalars["String"]["output"]>;
  commentsCount?: Maybe<Scalars["Int"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  difficultyLevel: Scalars["String"]["output"];
  featuredImage?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["Int"]["output"];
  image?: Maybe<Scalars["String"]["output"]>;
  instructionImage?: Maybe<Scalars["String"]["output"]>;
  instructionText?: Maybe<Scalars["String"]["output"]>;
  isIndexable: Scalars["Boolean"]["output"];
  jsonLD?: Maybe<Scalars["JSON"]["output"]>;
  materialsNeeded?: Maybe<Scalars["String"]["output"]>;
  metaTagData?: Maybe<Scalars["JSON"]["output"]>;
  shortDescription?: Maybe<Scalars["String"]["output"]>;
  skill?: Maybe<PublicSkill>;
  slug: Scalars["String"]["output"];
  tags?: Maybe<Array<PublicTag>>;
  timeRequired: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
  video?: Maybe<Scalars["String"]["output"]>;
  xps?: Maybe<Scalars["Int"]["output"]>;
};

export type PublicProduct = {
  __typename?: "PublicProduct";
  activities?: Maybe<Array<PublicActivity>>;
  age?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  discountPrice?: Maybe<Scalars["Int"]["output"]>;
  featuredImage?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["Int"]["output"];
  images?: Maybe<Array<Scalars["String"]["output"]>>;
  inCurrency: Scalars["Int"]["output"];
  isBestSelling: Scalars["Boolean"]["output"];
  isNewReleased: Scalars["Boolean"]["output"];
  isVirtual: Scalars["Boolean"]["output"];
  metaTagData?: Maybe<Scalars["JSON"]["output"]>;
  name: Scalars["String"]["output"];
  price: Scalars["Int"]["output"];
  purchaseLink?: Maybe<Scalars["String"]["output"]>;
  shortDescription?: Maybe<Scalars["String"]["output"]>;
  skill?: Maybe<PublicSkill>;
  slug: Scalars["String"]["output"];
  video?: Maybe<Scalars["String"]["output"]>;
};

export type PublicSkill = {
  __typename?: "PublicSkill";
  bundle?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  featuredImage?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["Int"]["output"];
  image?: Maybe<Scalars["String"]["output"]>;
  meta?: Maybe<Scalars["JSON"]["output"]>;
  metaTagData?: Maybe<Scalars["JSON"]["output"]>;
  name: Scalars["String"]["output"];
  slug: Scalars["String"]["output"];
};

export type PublicStoryActivity = {
  __typename?: "PublicStoryActivity";
  audio?: Maybe<Scalars["String"]["output"]>;
  challengeStatement: Scalars["String"]["output"];
  coinsToSpend: Scalars["Int"]["output"];
  content: Scalars["String"]["output"];
  featuredImage?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["Int"]["output"];
  image?: Maybe<Scalars["String"]["output"]>;
  skill: PublicSkill;
  slug: Scalars["String"]["output"];
  timeRequired: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
  video?: Maybe<Scalars["String"]["output"]>;
  xps: Scalars["Int"]["output"];
};

export type PublicTag = {
  __typename?: "PublicTag";
  buyScript?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["Int"]["output"];
  jsonLD?: Maybe<Scalars["JSON"]["output"]>;
  metaTagData?: Maybe<Scalars["JSON"]["output"]>;
  name: Scalars["String"]["output"];
  productScript?: Maybe<Scalars["String"]["output"]>;
  seoContent?: Maybe<Scalars["String"]["output"]>;
  slug: Scalars["String"]["output"];
};

export type PublicWorkshopOutput = {
  __typename?: "PublicWorkshopOutput";
  address?: Maybe<Scalars["String"]["output"]>;
  ageGroup?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  displayTime?: Maybe<Scalars["String"]["output"]>;
  endDate?: Maybe<Scalars["DateTime"]["output"]>;
  feesInCurrency?: Maybe<Scalars["Int"]["output"]>;
  feesWithKit?: Maybe<Scalars["Int"]["output"]>;
  feesWithoutKit?: Maybe<Scalars["Int"]["output"]>;
  footerImageLarge?: Maybe<Scalars["String"]["output"]>;
  footerImageSmall?: Maybe<Scalars["String"]["output"]>;
  headerImageLarge?: Maybe<Scalars["String"]["output"]>;
  headerImageSmall?: Maybe<Scalars["String"]["output"]>;
  headerText?: Maybe<Scalars["String"]["output"]>;
  headerVideo?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["Int"]["output"];
  image?: Maybe<Scalars["String"]["output"]>;
  insiderActivities?: Maybe<Array<PublicActivity>>;
  isOpen: Scalars["Boolean"]["output"];
  liveUrl?: Maybe<Scalars["String"]["output"]>;
  location?: Maybe<Scalars["String"]["output"]>;
  meta?: Maybe<Scalars["JSON"]["output"]>;
  metaTagData?: Maybe<Scalars["JSON"]["output"]>;
  mode: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  product?: Maybe<PublicProduct>;
  registrationUrl?: Maybe<Scalars["String"]["output"]>;
  relatedActivities?: Maybe<Array<PublicActivity>>;
  schoolId: Scalars["Int"]["output"];
  skillId: Scalars["Int"]["output"];
  slug: Scalars["String"]["output"];
  standards?: Maybe<Array<Standard>>;
  startDate: Scalars["DateTime"]["output"];
  tags?: Maybe<Array<PublicTag>>;
  trainer?: Maybe<User>;
  trainerId?: Maybe<Scalars["Int"]["output"]>;
  trainerImage?: Maybe<Scalars["String"]["output"]>;
  type: Scalars["String"]["output"];
};

export type PublicWorkshopsOutput = {
  __typename?: "PublicWorkshopsOutput";
  description?: Maybe<Scalars["String"]["output"]>;
  featuredImage?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["Int"]["output"];
  name: Scalars["String"]["output"];
  slug: Scalars["String"]["output"];
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type Query = {
  __typename?: "Query";
  activities: Array<Activity>;
  activity?: Maybe<Activity>;
  activityBanner: ActivityBanner;
  activityBanners: Array<ActivityBanner>;
  activityQuestion?: Maybe<ActivityQuestion>;
  activityQuestions: Array<ActivityQuestion>;
  adminLeaderboard?: Maybe<Array<AdminLeaderboardResponse>>;
  aggregateActivity: AggregateActivity;
  aggregateBadge: AggregateBadge;
  aggregateComment: AggregateComment;
  aggregateDailyActivity: AggregateDailyActivity;
  aggregateGamePointType: AggregateGamePointType;
  aggregateLevel: AggregateLevel;
  aggregateOrder: AggregateOrder;
  aggregatePrivacyPolicy: AggregatePrivacyPolicy;
  aggregatePrivacyPolicyUpdate: AggregatePrivacyPolicyUpdate;
  aggregateProduct: AggregateProduct;
  aggregatePromoCode: AggregatePromoCode;
  aggregatePromoCodeGroup: AggregatePromoCodeGroup;
  aggregatePromoCodeHistory: AggregatePromoCodeHistory;
  aggregatePromoCodeToPromoCodeGroup: AggregatePromoCodeToPromoCodeGroup;
  aggregateReferralCode: AggregateReferralCode;
  aggregateReferralCodeHistory: AggregateReferralCodeHistory;
  aggregateReport: AggregateReport;
  aggregateSamskara: AggregateSamskara;
  aggregateSamskaraCategory: AggregateSamskaraCategory;
  aggregateSchool: AggregateSchool;
  aggregateSchoolUpdate: AggregateSchoolUpdate;
  aggregateSkill: AggregateSkill;
  aggregateStandard: AggregateStandard;
  aggregateStory: AggregateStory;
  aggregateStoryActivity: AggregateStoryActivity;
  aggregateStoryTag: AggregateStoryTag;
  aggregateStudent: AggregateStudent;
  aggregateStudentGameLog: AggregateStudentGameLog;
  aggregateStudentRecord: AggregateStudentRecord;
  aggregateTag: AggregateTag;
  aggregateTrigger: AggregateTrigger;
  aggregateUser: AggregateUser;
  aggregateUserLog: AggregateUserLog;
  aggregateWorkshop: AggregateWorkshop;
  aggregateWorkshopRegistration: AggregateWorkshopRegistration;
  badge?: Maybe<Badge>;
  badges: Array<Badge>;
  comment?: Maybe<Comment>;
  comments: Array<Comment>;
  dailyActivities: Array<DailyActivity>;
  dailyActivity?: Maybe<DailyActivity>;
  exportStudentGameLogs: Scalars["JSON"]["output"];
  featuredStudentActivities: Array<PublicActivity>;
  featuredStudentStories: Array<StudentStoryOutput>;
  findFirstLevel?: Maybe<Level>;
  findFirstProductBanner?: Maybe<ProductBanner>;
  findFirstPromoCodeToPromoCodeGroup?: Maybe<PromoCodeToPromoCodeGroup>;
  findFirstStudentRecord?: Maybe<StudentRecord>;
  findFirstUserLog?: Maybe<UserLog>;
  findManyPrivacyPolicy: Array<PrivacyPolicy>;
  findManyPrivacyPolicyUpdate: Array<PrivacyPolicyUpdate>;
  gamePointType?: Maybe<GamePointType>;
  gamePointTypes: Array<GamePointType>;
  groupByDailyActivity: Array<DailyActivityGroupBy>;
  groupByPromoCodeGroup: Array<PromoCodeGroupGroupBy>;
  groupByPromoCodeToPromoCodeGroup: Array<PromoCodeToPromoCodeGroupGroupBy>;
  level?: Maybe<Level>;
  levels: Array<Level>;
  me?: Maybe<MeResponse>;
  order?: Maybe<Order>;
  orders: Array<Order>;
  privacyPolicy?: Maybe<PrivacyPolicy>;
  privacyPolicyUpdate?: Maybe<PrivacyPolicyUpdate>;
  product?: Maybe<Product>;
  productBanner?: Maybe<ProductBanner>;
  productBanners: Array<ProductBanner>;
  products: Array<Product>;
  promoCode?: Maybe<PromoCode>;
  promoCodeGroup?: Maybe<PromoCodeGroup>;
  promoCodeGroups: Array<PromoCodeGroup>;
  promoCodeHistories: Array<PromoCodeHistory>;
  promoCodeHistory?: Maybe<PromoCodeHistory>;
  promoCodeToPromoCodeGroup?: Maybe<PromoCodeToPromoCodeGroup>;
  promoCodeToPromoCodeGroups: Array<PromoCodeToPromoCodeGroup>;
  promoCodes: Array<PromoCode>;
  publicActivities: Array<PublicActivity>;
  publicActivity?: Maybe<PublicActivity>;
  publicActivityBanners: Array<ActivityBanner>;
  publicPastWorkshops: Array<PublicWorkshopsOutput>;
  publicProductBanners: Array<ProductBanner>;
  publicProducts: Array<PublicProduct>;
  publicSkill: PublicSkill;
  publicSkills: Array<PublicSkill>;
  publicStoryActivities: Array<PublicStoryActivity>;
  publicStoryActivity?: Maybe<PublicStoryActivity>;
  publicTag?: Maybe<PublicTag>;
  publicUpcomingWorkshops: Array<PublicWorkshopsOutput>;
  publicWorkshop?: Maybe<PublicWorkshopOutput>;
  referralCode?: Maybe<ReferralCode>;
  referralCodeHistories: Array<ReferralCodeHistory>;
  referralCodeHistory?: Maybe<ReferralCodeHistory>;
  referralCodes: Array<ReferralCode>;
  report?: Maybe<Report>;
  reports: Array<Report>;
  samskara?: Maybe<Samskara>;
  samskaraCategories: Array<SamskaraCategory>;
  samskaraCategory?: Maybe<SamskaraCategory>;
  samskaras: Array<Samskara>;
  school?: Maybe<School>;
  schoolUpdate?: Maybe<SchoolUpdate>;
  schoolUpdates: Array<SchoolUpdate>;
  schools: Array<School>;
  searchActivities: Array<SearchActivity>;
  sitemap: Array<Sitemap>;
  skill?: Maybe<Skill>;
  skills: Array<Skill>;
  standard?: Maybe<Standard>;
  standards: Array<Standard>;
  stories: Array<Story>;
  story?: Maybe<Story>;
  storyActivities: Array<StoryActivity>;
  storyActivity?: Maybe<StoryActivity>;
  storyBanner?: Maybe<StoryBanner>;
  storyBanners: Array<StoryBanner>;
  storyTag?: Maybe<StoryTag>;
  storyTags: Array<StoryTag>;
  student?: Maybe<Student>;
  studentActivities: Array<PublicActivity>;
  studentActivity?: Maybe<FindStudentUniqueActivityOutput>;
  studentActivityAccess?: Maybe<FindStudentActivityAccessOutput>;
  studentActivityComments: Array<FindStudentActivityCommentsOutput>;
  studentActivitySelfComments: Array<FindStudentActivityCommentsOutput>;
  studentCoinLogs?: Maybe<StudentCoinLogsResponse>;
  studentDailyActivity?: Maybe<StudentDailyActivity>;
  studentDailyChallenge?: Maybe<StudentDailyChallenge>;
  studentGameLog?: Maybe<StudentGameLog>;
  studentGameLogs: Array<StudentGameLog>;
  studentGamePoints?: Maybe<StudentGamePointsResponse>;
  studentLeaderboard?: Maybe<Array<StudentLeaderboardResponse>>;
  studentNextLevelRequirement?: Maybe<StudentNextLevelRequirementResponse>;
  studentNextRandomActivity?: Maybe<FindStudentUniqueActivityOutput>;
  studentPastWorkshops: Array<StudentWorkshopsOutput>;
  studentPendingActivities: Array<PublicActivity>;
  studentProduct?: Maybe<PublicProduct>;
  studentRecord?: Maybe<StudentRecord>;
  studentRecords: Array<StudentRecord>;
  studentReferralCode: StudentReferralCodeResponse;
  studentRegisteredWorkshops: Array<StudentWorkshopsOutput>;
  studentReward?: Maybe<StudentRewardResponse>;
  studentSkill?: Maybe<PublicSkill>;
  studentSkillXps?: Maybe<StudentSkillXpsResponse>;
  studentSkills: Array<PublicSkill>;
  studentStories: Array<StudentStoryOutput>;
  studentStory?: Maybe<StudentStoryOutput>;
  studentStoryActivities: Array<StudentStoryActivity>;
  studentStoryActivity?: Maybe<StoryActivity>;
  studentStoryActivityAccess?: Maybe<FindStudentStoryActivityAccessResponse>;
  studentStoryBanners: Array<StoryBanner>;
  studentStoryTags: Array<StoryTag>;
  studentTopPerformerMonthLeaderboard?: Maybe<Array<StudentLeaderboardResponse>>;
  studentTopPerformerWeekLeaderboard?: Maybe<Array<StudentLeaderboardResponse>>;
  studentUpcomingWorkshops: Array<StudentUpcomingWorkshopOutput>;
  studentWorkshopAccess?: Maybe<FindStudentWorkshopAccessResponse>;
  students: Array<Student>;
  tag?: Maybe<Tag>;
  tags: Array<Tag>;
  trigger?: Maybe<Trigger>;
  triggers: Array<Trigger>;
  user?: Maybe<User>;
  userLog?: Maybe<UserLog>;
  userLogs: Array<UserLog>;
  users: Array<User>;
  workshop?: Maybe<Workshop>;
  workshopRegistration?: Maybe<WorkshopRegistration>;
  workshopRegistrations: Array<WorkshopRegistration>;
  workshops: Array<Workshop>;
};


export type QueryActivitiesArgs = {
  cursor?: InputMaybe<ActivityWhereUniqueInput>;
  distinct?: InputMaybe<Array<ActivityScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ActivityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ActivityWhereInput>;
};


export type QueryActivityArgs = {
  where: ActivityWhereUniqueInput;
};


export type QueryActivityBannerArgs = {
  where: ActivityBannerWhereUniqueInput;
};


export type QueryActivityBannersArgs = {
  cursor?: InputMaybe<ActivityBannerWhereUniqueInput>;
  distinct?: InputMaybe<Array<ActivityBannerScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ActivityBannerOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ActivityBannerWhereInput>;
};


export type QueryActivityQuestionArgs = {
  where: ActivityQuestionWhereUniqueInput;
};


export type QueryActivityQuestionsArgs = {
  cursor?: InputMaybe<ActivityQuestionWhereUniqueInput>;
  distinct?: InputMaybe<Array<ActivityQuestionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ActivityQuestionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ActivityQuestionWhereInput>;
};


export type QueryAdminLeaderboardArgs = {
  skip: Scalars["Int"]["input"];
  starsOrderBy?: InputMaybe<SortOrder>;
  take: Scalars["Int"]["input"];
  where: AdminLeaderboardInput;
  xpsOrderBy?: InputMaybe<SortOrder>;
};


export type QueryAggregateActivityArgs = {
  cursor?: InputMaybe<ActivityWhereUniqueInput>;
  orderBy?: InputMaybe<Array<ActivityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ActivityWhereInput>;
};


export type QueryAggregateBadgeArgs = {
  cursor?: InputMaybe<BadgeWhereUniqueInput>;
  orderBy?: InputMaybe<Array<BadgeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<BadgeWhereInput>;
};


export type QueryAggregateCommentArgs = {
  cursor?: InputMaybe<CommentWhereUniqueInput>;
  orderBy?: InputMaybe<Array<CommentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<CommentWhereInput>;
};


export type QueryAggregateDailyActivityArgs = {
  cursor?: InputMaybe<DailyActivityWhereUniqueInput>;
  orderBy?: InputMaybe<Array<DailyActivityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<DailyActivityWhereInput>;
};


export type QueryAggregateGamePointTypeArgs = {
  cursor?: InputMaybe<GamePointTypeWhereUniqueInput>;
  orderBy?: InputMaybe<Array<GamePointTypeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<GamePointTypeWhereInput>;
};


export type QueryAggregateLevelArgs = {
  cursor?: InputMaybe<LevelWhereUniqueInput>;
  orderBy?: InputMaybe<Array<LevelOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<LevelWhereInput>;
};


export type QueryAggregateOrderArgs = {
  cursor?: InputMaybe<OrderWhereUniqueInput>;
  orderBy?: InputMaybe<Array<OrderOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<OrderWhereInput>;
};


export type QueryAggregatePrivacyPolicyArgs = {
  cursor?: InputMaybe<PrivacyPolicyWhereUniqueInput>;
  orderBy?: InputMaybe<Array<PrivacyPolicyOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<PrivacyPolicyWhereInput>;
};


export type QueryAggregatePrivacyPolicyUpdateArgs = {
  cursor?: InputMaybe<PrivacyPolicyUpdateWhereUniqueInput>;
  orderBy?: InputMaybe<Array<PrivacyPolicyUpdateOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<PrivacyPolicyUpdateWhereInput>;
};


export type QueryAggregateProductArgs = {
  cursor?: InputMaybe<ProductWhereUniqueInput>;
  orderBy?: InputMaybe<Array<ProductOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ProductWhereInput>;
};


export type QueryAggregatePromoCodeArgs = {
  cursor?: InputMaybe<PromoCodeWhereUniqueInput>;
  orderBy?: InputMaybe<Array<PromoCodeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<PromoCodeWhereInput>;
};


export type QueryAggregatePromoCodeGroupArgs = {
  cursor?: InputMaybe<PromoCodeGroupWhereUniqueInput>;
  orderBy?: InputMaybe<Array<PromoCodeGroupOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<PromoCodeGroupWhereInput>;
};


export type QueryAggregatePromoCodeHistoryArgs = {
  cursor?: InputMaybe<PromoCodeHistoryWhereUniqueInput>;
  orderBy?: InputMaybe<Array<PromoCodeHistoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<PromoCodeHistoryWhereInput>;
};


export type QueryAggregatePromoCodeToPromoCodeGroupArgs = {
  cursor?: InputMaybe<PromoCodeToPromoCodeGroupWhereUniqueInput>;
  orderBy?: InputMaybe<Array<PromoCodeToPromoCodeGroupOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<PromoCodeToPromoCodeGroupWhereInput>;
};


export type QueryAggregateReferralCodeArgs = {
  cursor?: InputMaybe<ReferralCodeWhereUniqueInput>;
  orderBy?: InputMaybe<Array<ReferralCodeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ReferralCodeWhereInput>;
};


export type QueryAggregateReferralCodeHistoryArgs = {
  cursor?: InputMaybe<ReferralCodeHistoryWhereUniqueInput>;
  orderBy?: InputMaybe<Array<ReferralCodeHistoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ReferralCodeHistoryWhereInput>;
};


export type QueryAggregateReportArgs = {
  cursor?: InputMaybe<ReportWhereUniqueInput>;
  orderBy?: InputMaybe<Array<ReportOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ReportWhereInput>;
};


export type QueryAggregateSamskaraArgs = {
  cursor?: InputMaybe<SamskaraWhereUniqueInput>;
  orderBy?: InputMaybe<Array<SamskaraOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<SamskaraWhereInput>;
};


export type QueryAggregateSamskaraCategoryArgs = {
  cursor?: InputMaybe<SamskaraCategoryWhereUniqueInput>;
  orderBy?: InputMaybe<Array<SamskaraCategoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<SamskaraCategoryWhereInput>;
};


export type QueryAggregateSchoolArgs = {
  cursor?: InputMaybe<SchoolWhereUniqueInput>;
  orderBy?: InputMaybe<Array<SchoolOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<SchoolWhereInput>;
};


export type QueryAggregateSchoolUpdateArgs = {
  cursor?: InputMaybe<SchoolUpdateWhereUniqueInput>;
  orderBy?: InputMaybe<Array<SchoolUpdateOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<SchoolUpdateWhereInput>;
};


export type QueryAggregateSkillArgs = {
  cursor?: InputMaybe<SkillWhereUniqueInput>;
  orderBy?: InputMaybe<Array<SkillOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<SkillWhereInput>;
};


export type QueryAggregateStandardArgs = {
  cursor?: InputMaybe<StandardWhereUniqueInput>;
  orderBy?: InputMaybe<Array<StandardOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<StandardWhereInput>;
};


export type QueryAggregateStoryArgs = {
  cursor?: InputMaybe<StoryWhereUniqueInput>;
  orderBy?: InputMaybe<Array<StoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<StoryWhereInput>;
};


export type QueryAggregateStoryActivityArgs = {
  cursor?: InputMaybe<StoryActivityWhereUniqueInput>;
  orderBy?: InputMaybe<Array<StoryActivityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<StoryActivityWhereInput>;
};


export type QueryAggregateStoryTagArgs = {
  cursor?: InputMaybe<StoryTagWhereUniqueInput>;
  orderBy?: InputMaybe<Array<StoryTagOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<StoryTagWhereInput>;
};


export type QueryAggregateStudentArgs = {
  cursor?: InputMaybe<StudentWhereUniqueInput>;
  orderBy?: InputMaybe<Array<StudentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<StudentWhereInput>;
};


export type QueryAggregateStudentGameLogArgs = {
  cursor?: InputMaybe<StudentGameLogWhereUniqueInput>;
  orderBy?: InputMaybe<Array<StudentGameLogOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<StudentGameLogWhereInput>;
};


export type QueryAggregateStudentRecordArgs = {
  cursor?: InputMaybe<StudentRecordWhereUniqueInput>;
  orderBy?: InputMaybe<Array<StudentRecordOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<StudentRecordWhereInput>;
};


export type QueryAggregateTagArgs = {
  cursor?: InputMaybe<TagWhereUniqueInput>;
  orderBy?: InputMaybe<Array<TagOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<TagWhereInput>;
};


export type QueryAggregateTriggerArgs = {
  cursor?: InputMaybe<TriggerWhereUniqueInput>;
  orderBy?: InputMaybe<Array<TriggerOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<TriggerWhereInput>;
};


export type QueryAggregateUserArgs = {
  cursor?: InputMaybe<UserWhereUniqueInput>;
  orderBy?: InputMaybe<Array<UserOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<UserWhereInput>;
};


export type QueryAggregateUserLogArgs = {
  cursor?: InputMaybe<UserLogWhereUniqueInput>;
  orderBy?: InputMaybe<Array<UserLogOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<UserLogWhereInput>;
};


export type QueryAggregateWorkshopArgs = {
  cursor?: InputMaybe<WorkshopWhereUniqueInput>;
  orderBy?: InputMaybe<Array<WorkshopOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<WorkshopWhereInput>;
};


export type QueryAggregateWorkshopRegistrationArgs = {
  cursor?: InputMaybe<WorkshopRegistrationWhereUniqueInput>;
  orderBy?: InputMaybe<Array<WorkshopRegistrationOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<WorkshopRegistrationWhereInput>;
};


export type QueryBadgeArgs = {
  where: BadgeWhereUniqueInput;
};


export type QueryBadgesArgs = {
  cursor?: InputMaybe<BadgeWhereUniqueInput>;
  distinct?: InputMaybe<Array<BadgeScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BadgeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<BadgeWhereInput>;
};


export type QueryCommentArgs = {
  where: CommentWhereUniqueInput;
};


export type QueryCommentsArgs = {
  cursor?: InputMaybe<CommentWhereUniqueInput>;
  distinct?: InputMaybe<Array<CommentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CommentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<CommentWhereInput>;
};


export type QueryDailyActivitiesArgs = {
  cursor?: InputMaybe<DailyActivityWhereUniqueInput>;
  distinct?: InputMaybe<Array<DailyActivityScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<DailyActivityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<DailyActivityWhereInput>;
};


export type QueryDailyActivityArgs = {
  where: DailyActivityWhereUniqueInput;
};


export type QueryExportStudentGameLogsArgs = {
  where: ExportGameLogsInput;
};


export type QueryFindFirstLevelArgs = {
  cursor?: InputMaybe<LevelWhereUniqueInput>;
  distinct?: InputMaybe<Array<LevelScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<LevelOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<LevelWhereInput>;
};


export type QueryFindFirstProductBannerArgs = {
  cursor?: InputMaybe<ProductBannerWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProductBannerScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProductBannerOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ProductBannerWhereInput>;
};


export type QueryFindFirstPromoCodeToPromoCodeGroupArgs = {
  cursor?: InputMaybe<PromoCodeToPromoCodeGroupWhereUniqueInput>;
  distinct?: InputMaybe<Array<PromoCodeToPromoCodeGroupScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PromoCodeToPromoCodeGroupOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<PromoCodeToPromoCodeGroupWhereInput>;
};


export type QueryFindFirstStudentRecordArgs = {
  cursor?: InputMaybe<StudentRecordWhereUniqueInput>;
  distinct?: InputMaybe<Array<StudentRecordScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StudentRecordOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<StudentRecordWhereInput>;
};


export type QueryFindFirstUserLogArgs = {
  cursor?: InputMaybe<UserLogWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserLogScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserLogOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<UserLogWhereInput>;
};


export type QueryFindManyPrivacyPolicyArgs = {
  cursor?: InputMaybe<PrivacyPolicyWhereUniqueInput>;
  distinct?: InputMaybe<Array<PrivacyPolicyScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PrivacyPolicyOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<PrivacyPolicyWhereInput>;
};


export type QueryFindManyPrivacyPolicyUpdateArgs = {
  cursor?: InputMaybe<PrivacyPolicyUpdateWhereUniqueInput>;
  distinct?: InputMaybe<Array<PrivacyPolicyUpdateScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PrivacyPolicyUpdateOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<PrivacyPolicyUpdateWhereInput>;
};


export type QueryGamePointTypeArgs = {
  where: GamePointTypeWhereUniqueInput;
};


export type QueryGamePointTypesArgs = {
  cursor?: InputMaybe<GamePointTypeWhereUniqueInput>;
  distinct?: InputMaybe<Array<GamePointTypeScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<GamePointTypeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<GamePointTypeWhereInput>;
};


export type QueryGroupByDailyActivityArgs = {
  by: Array<DailyActivityScalarFieldEnum>;
  having?: InputMaybe<DailyActivityScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<DailyActivityOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<DailyActivityWhereInput>;
};


export type QueryGroupByPromoCodeGroupArgs = {
  by: Array<PromoCodeGroupScalarFieldEnum>;
  having?: InputMaybe<PromoCodeGroupScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<PromoCodeGroupOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<PromoCodeGroupWhereInput>;
};


export type QueryGroupByPromoCodeToPromoCodeGroupArgs = {
  by: Array<PromoCodeToPromoCodeGroupScalarFieldEnum>;
  having?: InputMaybe<PromoCodeToPromoCodeGroupScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<PromoCodeToPromoCodeGroupOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<PromoCodeToPromoCodeGroupWhereInput>;
};


export type QueryLevelArgs = {
  where: LevelWhereUniqueInput;
};


export type QueryLevelsArgs = {
  cursor?: InputMaybe<LevelWhereUniqueInput>;
  distinct?: InputMaybe<Array<LevelScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<LevelOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<LevelWhereInput>;
};


export type QueryOrderArgs = {
  where: OrderWhereUniqueInput;
};


export type QueryOrdersArgs = {
  cursor?: InputMaybe<OrderWhereUniqueInput>;
  distinct?: InputMaybe<Array<OrderScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<OrderOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<OrderWhereInput>;
};


export type QueryPrivacyPolicyArgs = {
  where: PrivacyPolicyWhereUniqueInput;
};


export type QueryPrivacyPolicyUpdateArgs = {
  where: PrivacyPolicyUpdateWhereUniqueInput;
};


export type QueryProductArgs = {
  where: ProductWhereUniqueInput;
};


export type QueryProductBannerArgs = {
  where: ProductBannerWhereUniqueInput;
};


export type QueryProductBannersArgs = {
  cursor?: InputMaybe<ProductBannerWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProductBannerScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProductBannerOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ProductBannerWhereInput>;
};


export type QueryProductsArgs = {
  cursor?: InputMaybe<ProductWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProductScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProductOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ProductWhereInput>;
};


export type QueryPromoCodeArgs = {
  where: PromoCodeWhereUniqueInput;
};


export type QueryPromoCodeGroupArgs = {
  where: PromoCodeGroupWhereUniqueInput;
};


export type QueryPromoCodeGroupsArgs = {
  cursor?: InputMaybe<PromoCodeGroupWhereUniqueInput>;
  distinct?: InputMaybe<Array<PromoCodeGroupScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PromoCodeGroupOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<PromoCodeGroupWhereInput>;
};


export type QueryPromoCodeHistoriesArgs = {
  cursor?: InputMaybe<PromoCodeHistoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<PromoCodeHistoryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PromoCodeHistoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<PromoCodeHistoryWhereInput>;
};


export type QueryPromoCodeHistoryArgs = {
  where: PromoCodeHistoryWhereUniqueInput;
};


export type QueryPromoCodeToPromoCodeGroupArgs = {
  where: PromoCodeToPromoCodeGroupWhereUniqueInput;
};


export type QueryPromoCodeToPromoCodeGroupsArgs = {
  cursor?: InputMaybe<PromoCodeToPromoCodeGroupWhereUniqueInput>;
  distinct?: InputMaybe<Array<PromoCodeToPromoCodeGroupScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PromoCodeToPromoCodeGroupOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<PromoCodeToPromoCodeGroupWhereInput>;
};


export type QueryPromoCodesArgs = {
  cursor?: InputMaybe<PromoCodeWhereUniqueInput>;
  distinct?: InputMaybe<Array<PromoCodeScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PromoCodeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<PromoCodeWhereInput>;
};


export type QueryPublicActivitiesArgs = {
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<FindPublicManyActivityInput>;
};


export type QueryPublicActivityArgs = {
  where: ActivityWhereUniqueInput;
};


export type QueryPublicActivityBannersArgs = {
  cursor?: InputMaybe<ActivityBannerWhereUniqueInput>;
  distinct?: InputMaybe<Array<ActivityBannerScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ActivityBannerOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ActivityBannerWhereInput>;
};


export type QueryPublicPastWorkshopsArgs = {
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
};


export type QueryPublicProductsArgs = {
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<StudentProductWhereInput>;
};


export type QueryPublicSkillArgs = {
  where: SkillWhereUniqueInput;
};


export type QueryPublicSkillsArgs = {
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<FindStudentManySkillWhereInput>;
};


export type QueryPublicStoryActivitiesArgs = {
  cursor?: InputMaybe<StoryActivityWhereUniqueInput>;
  distinct?: InputMaybe<Array<StoryActivityScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StoryActivityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<StoryActivityWhereInput>;
};


export type QueryPublicStoryActivityArgs = {
  where: StoryActivityWhereUniqueInput;
};


export type QueryPublicTagArgs = {
  where: TagWhereUniqueInput;
};


export type QueryPublicUpcomingWorkshopsArgs = {
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<FindPublicManyWorkshopWhereInput>;
};


export type QueryPublicWorkshopArgs = {
  where: StudentWorkshopUniqueInput;
};


export type QueryReferralCodeArgs = {
  where: ReferralCodeWhereUniqueInput;
};


export type QueryReferralCodeHistoriesArgs = {
  cursor?: InputMaybe<ReferralCodeHistoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<ReferralCodeHistoryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ReferralCodeHistoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ReferralCodeHistoryWhereInput>;
};


export type QueryReferralCodeHistoryArgs = {
  where: ReferralCodeHistoryWhereUniqueInput;
};


export type QueryReferralCodesArgs = {
  cursor?: InputMaybe<ReferralCodeWhereUniqueInput>;
  distinct?: InputMaybe<Array<ReferralCodeScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ReferralCodeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ReferralCodeWhereInput>;
};


export type QueryReportArgs = {
  where: ReportWhereUniqueInput;
};


export type QueryReportsArgs = {
  cursor?: InputMaybe<ReportWhereUniqueInput>;
  distinct?: InputMaybe<Array<ReportScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ReportOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ReportWhereInput>;
};


export type QuerySamskaraArgs = {
  where: SamskaraWhereUniqueInput;
};


export type QuerySamskaraCategoriesArgs = {
  cursor?: InputMaybe<SamskaraCategoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<SamskaraCategoryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<SamskaraCategoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<SamskaraCategoryWhereInput>;
};


export type QuerySamskaraCategoryArgs = {
  where: SamskaraCategoryWhereUniqueInput;
};


export type QuerySamskarasArgs = {
  cursor?: InputMaybe<SamskaraWhereUniqueInput>;
  distinct?: InputMaybe<Array<SamskaraScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<SamskaraOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<SamskaraWhereInput>;
};


export type QuerySchoolArgs = {
  where: SchoolWhereUniqueInput;
};


export type QuerySchoolUpdateArgs = {
  where: SchoolUpdateWhereUniqueInput;
};


export type QuerySchoolUpdatesArgs = {
  cursor?: InputMaybe<SchoolUpdateWhereUniqueInput>;
  distinct?: InputMaybe<Array<SchoolUpdateScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<SchoolUpdateOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<SchoolUpdateWhereInput>;
};


export type QuerySchoolsArgs = {
  cursor?: InputMaybe<SchoolWhereUniqueInput>;
  distinct?: InputMaybe<Array<SchoolScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<SchoolOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<SchoolWhereInput>;
};


export type QuerySearchActivitiesArgs = {
  where: FindTriggerManyActivityInput;
};


export type QuerySkillArgs = {
  where: SkillWhereUniqueInput;
};


export type QuerySkillsArgs = {
  cursor?: InputMaybe<SkillWhereUniqueInput>;
  distinct?: InputMaybe<Array<SkillScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<SkillOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<SkillWhereInput>;
};


export type QueryStandardArgs = {
  where: StandardWhereUniqueInput;
};


export type QueryStandardsArgs = {
  cursor?: InputMaybe<StandardWhereUniqueInput>;
  distinct?: InputMaybe<Array<StandardScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StandardOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<StandardWhereInput>;
};


export type QueryStoriesArgs = {
  cursor?: InputMaybe<StoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<StoryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<StoryWhereInput>;
};


export type QueryStoryArgs = {
  where: StoryWhereUniqueInput;
};


export type QueryStoryActivitiesArgs = {
  cursor?: InputMaybe<StoryActivityWhereUniqueInput>;
  distinct?: InputMaybe<Array<StoryActivityScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StoryActivityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<StoryActivityWhereInput>;
};


export type QueryStoryActivityArgs = {
  where: StoryActivityWhereUniqueInput;
};


export type QueryStoryBannerArgs = {
  where: StoryBannerWhereUniqueInput;
};


export type QueryStoryBannersArgs = {
  cursor?: InputMaybe<StoryBannerWhereUniqueInput>;
  distinct?: InputMaybe<Array<StoryBannerScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StoryBannerOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<StoryBannerWhereInput>;
};


export type QueryStoryTagArgs = {
  where: StoryTagWhereUniqueInput;
};


export type QueryStoryTagsArgs = {
  cursor?: InputMaybe<StoryTagWhereUniqueInput>;
  distinct?: InputMaybe<Array<StoryTagScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StoryTagOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<StoryTagWhereInput>;
};


export type QueryStudentArgs = {
  where: StudentWhereUniqueInput;
};


export type QueryStudentActivitiesArgs = {
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where: FindStudentManyActivityInput;
};


export type QueryStudentActivityArgs = {
  where: ActivityWhereUniqueInput;
};


export type QueryStudentActivityAccessArgs = {
  where: ActivityWhereUniqueInput;
};


export type QueryStudentActivityCommentsArgs = {
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where: FindStudentActivityCommentsInput;
};


export type QueryStudentActivitySelfCommentsArgs = {
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where: FindStudentActivityCommentsInput;
};


export type QueryStudentDailyActivityArgs = {
  data: StudentDailyActivityInput;
};


export type QueryStudentDailyChallengeArgs = {
  data: StudentDailyChallengeInput;
};


export type QueryStudentGameLogArgs = {
  where: StudentGameLogWhereUniqueInput;
};


export type QueryStudentGameLogsArgs = {
  cursor?: InputMaybe<StudentGameLogWhereUniqueInput>;
  distinct?: InputMaybe<Array<StudentGameLogScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StudentGameLogOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<StudentGameLogWhereInput>;
};


export type QueryStudentGamePointsArgs = {
  where: StudentWhereUniqueInput;
};


export type QueryStudentNextRandomActivityArgs = {
  where?: InputMaybe<StudentNextRandomActivityInput>;
};


export type QueryStudentPastWorkshopsArgs = {
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
};


export type QueryStudentProductArgs = {
  where: ProductWhereUniqueInput;
};


export type QueryStudentRecordArgs = {
  where: StudentRecordWhereUniqueInput;
};


export type QueryStudentRecordsArgs = {
  cursor?: InputMaybe<StudentRecordWhereUniqueInput>;
  distinct?: InputMaybe<Array<StudentRecordScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StudentRecordOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<StudentRecordWhereInput>;
};


export type QueryStudentRegisteredWorkshopsArgs = {
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
};


export type QueryStudentSkillArgs = {
  where: SkillWhereUniqueInput;
};


export type QueryStudentSkillsArgs = {
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<FindStudentManySkillWhereInput>;
};


export type QueryStudentStoriesArgs = {
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  tag?: InputMaybe<StoryTagWhereUniqueInput>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
};


export type QueryStudentStoryArgs = {
  where: SkillWhereUniqueInput;
};


export type QueryStudentStoryActivitiesArgs = {
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<FindStudentStoryActivityInput>;
};


export type QueryStudentStoryActivityArgs = {
  where: StoryActivityWhereUniqueInput;
};


export type QueryStudentStoryActivityAccessArgs = {
  where: ActivityWhereUniqueInput;
};


export type QueryStudentUpcomingWorkshopsArgs = {
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
};


export type QueryStudentWorkshopAccessArgs = {
  where: ActivityWhereUniqueInput;
};


export type QueryStudentsArgs = {
  cursor?: InputMaybe<StudentWhereUniqueInput>;
  distinct?: InputMaybe<Array<StudentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StudentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<StudentWhereInput>;
};


export type QueryTagArgs = {
  where: TagWhereUniqueInput;
};


export type QueryTagsArgs = {
  cursor?: InputMaybe<TagWhereUniqueInput>;
  distinct?: InputMaybe<Array<TagScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TagOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<TagWhereInput>;
};


export type QueryTriggerArgs = {
  where: TriggerWhereUniqueInput;
};


export type QueryTriggersArgs = {
  cursor?: InputMaybe<TriggerWhereUniqueInput>;
  distinct?: InputMaybe<Array<TriggerScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TriggerOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<TriggerWhereInput>;
};


export type QueryUserArgs = {
  where: UserWhereUniqueInput;
};


export type QueryUserLogArgs = {
  where: UserLogWhereUniqueInput;
};


export type QueryUserLogsArgs = {
  cursor?: InputMaybe<UserLogWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserLogScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserLogOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<UserLogWhereInput>;
};


export type QueryUsersArgs = {
  cursor?: InputMaybe<UserWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<UserWhereInput>;
};


export type QueryWorkshopArgs = {
  where: WorkshopWhereUniqueInput;
};


export type QueryWorkshopRegistrationArgs = {
  where: WorkshopRegistrationWhereUniqueInput;
};


export type QueryWorkshopRegistrationsArgs = {
  cursor?: InputMaybe<WorkshopRegistrationWhereUniqueInput>;
  distinct?: InputMaybe<Array<WorkshopRegistrationScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<WorkshopRegistrationOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<WorkshopRegistrationWhereInput>;
};


export type QueryWorkshopsArgs = {
  cursor?: InputMaybe<WorkshopWhereUniqueInput>;
  distinct?: InputMaybe<Array<WorkshopScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<WorkshopOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<WorkshopWhereInput>;
};

export enum QueryMode {
  DEFAULT = "default",
  INSENSITIVE = "insensitive"
}

export type ReferralCode = {
  __typename?: "ReferralCode";
  code: Scalars["String"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["Int"]["output"];
  inviteeCoins: Scalars["Int"]["output"];
  isActive: Scalars["Boolean"]["output"];
  referralCoins: Scalars["Int"]["output"];
  student: Student;
  studentId: Scalars["Int"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export type ReferralCodeAvgAggregate = {
  __typename?: "ReferralCodeAvgAggregate";
  id?: Maybe<Scalars["Float"]["output"]>;
  inviteeCoins?: Maybe<Scalars["Float"]["output"]>;
  referralCoins?: Maybe<Scalars["Float"]["output"]>;
  studentId?: Maybe<Scalars["Float"]["output"]>;
};

export type ReferralCodeCountAggregate = {
  __typename?: "ReferralCodeCountAggregate";
  _all: Scalars["Int"]["output"];
  code: Scalars["Int"]["output"];
  createdAt: Scalars["Int"]["output"];
  id: Scalars["Int"]["output"];
  inviteeCoins: Scalars["Int"]["output"];
  isActive: Scalars["Int"]["output"];
  referralCoins: Scalars["Int"]["output"];
  studentId: Scalars["Int"]["output"];
  updatedAt: Scalars["Int"]["output"];
};

export type ReferralCodeCreateInput = {
  code: Scalars["String"]["input"];
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  inviteeCoins?: InputMaybe<Scalars["Int"]["input"]>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  referralCoins?: InputMaybe<Scalars["Int"]["input"]>;
  student: StudentCreateNestedOneWithoutReferralCodesInput;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ReferralCodeCreateManyStudentInput = {
  code: Scalars["String"]["input"];
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  inviteeCoins?: InputMaybe<Scalars["Int"]["input"]>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  referralCoins?: InputMaybe<Scalars["Int"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ReferralCodeCreateManyStudentInputEnvelope = {
  data: Array<ReferralCodeCreateManyStudentInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ReferralCodeCreateNestedManyWithoutStudentInput = {
  connect?: InputMaybe<Array<ReferralCodeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ReferralCodeCreateOrConnectWithoutStudentInput>>;
  create?: InputMaybe<Array<ReferralCodeCreateWithoutStudentInput>>;
  createMany?: InputMaybe<ReferralCodeCreateManyStudentInputEnvelope>;
};

export type ReferralCodeCreateOrConnectWithoutStudentInput = {
  create: ReferralCodeCreateWithoutStudentInput;
  where: ReferralCodeWhereUniqueInput;
};

export type ReferralCodeCreateWithoutStudentInput = {
  code: Scalars["String"]["input"];
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  inviteeCoins?: InputMaybe<Scalars["Int"]["input"]>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  referralCoins?: InputMaybe<Scalars["Int"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ReferralCodeHistory = {
  __typename?: "ReferralCodeHistory";
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["Int"]["output"];
  invitedStudent: Student;
  invitedStudentId: Scalars["Int"]["output"];
  inviteeCoins: Scalars["Int"]["output"];
  referralCode: Scalars["String"]["output"];
  referralCoins: Scalars["Int"]["output"];
  referralStudent: Student;
  referralStudentId: Scalars["Int"]["output"];
};

export type ReferralCodeHistoryAvgAggregate = {
  __typename?: "ReferralCodeHistoryAvgAggregate";
  id?: Maybe<Scalars["Float"]["output"]>;
  invitedStudentId?: Maybe<Scalars["Float"]["output"]>;
  inviteeCoins?: Maybe<Scalars["Float"]["output"]>;
  referralCoins?: Maybe<Scalars["Float"]["output"]>;
  referralStudentId?: Maybe<Scalars["Float"]["output"]>;
};

export type ReferralCodeHistoryCountAggregate = {
  __typename?: "ReferralCodeHistoryCountAggregate";
  _all: Scalars["Int"]["output"];
  createdAt: Scalars["Int"]["output"];
  id: Scalars["Int"]["output"];
  invitedStudentId: Scalars["Int"]["output"];
  inviteeCoins: Scalars["Int"]["output"];
  referralCode: Scalars["Int"]["output"];
  referralCoins: Scalars["Int"]["output"];
  referralStudentId: Scalars["Int"]["output"];
};

export type ReferralCodeHistoryCreateInput = {
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitedStudent: StudentCreateNestedOneWithoutInvitedStudentsInput;
  inviteeCoins?: InputMaybe<Scalars["Int"]["input"]>;
  referralCode: Scalars["String"]["input"];
  referralCoins?: InputMaybe<Scalars["Int"]["input"]>;
  referralStudent: StudentCreateNestedOneWithoutReferralStudentsInput;
};

export type ReferralCodeHistoryCreateNestedOneWithoutInvitedStudentInput = {
  connect?: InputMaybe<ReferralCodeHistoryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ReferralCodeHistoryCreateOrConnectWithoutInvitedStudentInput>;
  create?: InputMaybe<ReferralCodeHistoryCreateWithoutInvitedStudentInput>;
};

export type ReferralCodeHistoryCreateNestedOneWithoutReferralStudentInput = {
  connect?: InputMaybe<ReferralCodeHistoryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ReferralCodeHistoryCreateOrConnectWithoutReferralStudentInput>;
  create?: InputMaybe<ReferralCodeHistoryCreateWithoutReferralStudentInput>;
};

export type ReferralCodeHistoryCreateOrConnectWithoutInvitedStudentInput = {
  create: ReferralCodeHistoryCreateWithoutInvitedStudentInput;
  where: ReferralCodeHistoryWhereUniqueInput;
};

export type ReferralCodeHistoryCreateOrConnectWithoutReferralStudentInput = {
  create: ReferralCodeHistoryCreateWithoutReferralStudentInput;
  where: ReferralCodeHistoryWhereUniqueInput;
};

export type ReferralCodeHistoryCreateWithoutInvitedStudentInput = {
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  inviteeCoins?: InputMaybe<Scalars["Int"]["input"]>;
  referralCode: Scalars["String"]["input"];
  referralCoins?: InputMaybe<Scalars["Int"]["input"]>;
  referralStudent: StudentCreateNestedOneWithoutReferralStudentsInput;
};

export type ReferralCodeHistoryCreateWithoutReferralStudentInput = {
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  invitedStudent: StudentCreateNestedOneWithoutInvitedStudentsInput;
  inviteeCoins?: InputMaybe<Scalars["Int"]["input"]>;
  referralCode: Scalars["String"]["input"];
  referralCoins?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ReferralCodeHistoryMaxAggregate = {
  __typename?: "ReferralCodeHistoryMaxAggregate";
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  invitedStudentId?: Maybe<Scalars["Int"]["output"]>;
  inviteeCoins?: Maybe<Scalars["Int"]["output"]>;
  referralCode?: Maybe<Scalars["String"]["output"]>;
  referralCoins?: Maybe<Scalars["Int"]["output"]>;
  referralStudentId?: Maybe<Scalars["Int"]["output"]>;
};

export type ReferralCodeHistoryMinAggregate = {
  __typename?: "ReferralCodeHistoryMinAggregate";
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  invitedStudentId?: Maybe<Scalars["Int"]["output"]>;
  inviteeCoins?: Maybe<Scalars["Int"]["output"]>;
  referralCode?: Maybe<Scalars["String"]["output"]>;
  referralCoins?: Maybe<Scalars["Int"]["output"]>;
  referralStudentId?: Maybe<Scalars["Int"]["output"]>;
};

export type ReferralCodeHistoryOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  invitedStudent?: InputMaybe<StudentOrderByWithRelationInput>;
  invitedStudentId?: InputMaybe<SortOrder>;
  inviteeCoins?: InputMaybe<SortOrder>;
  referralCode?: InputMaybe<SortOrder>;
  referralCoins?: InputMaybe<SortOrder>;
  referralStudent?: InputMaybe<StudentOrderByWithRelationInput>;
  referralStudentId?: InputMaybe<SortOrder>;
};

export type ReferralCodeHistoryRelationFilter = {
  is?: InputMaybe<ReferralCodeHistoryWhereInput>;
  isNot?: InputMaybe<ReferralCodeHistoryWhereInput>;
};

export enum ReferralCodeHistoryScalarFieldEnum {
  CREATEDAT = "createdAt",
  ID = "id",
  INVITEDSTUDENTID = "invitedStudentId",
  INVITEECOINS = "inviteeCoins",
  REFERRALCODE = "referralCode",
  REFERRALCOINS = "referralCoins",
  REFERRALSTUDENTID = "referralStudentId"
}

export type ReferralCodeHistorySumAggregate = {
  __typename?: "ReferralCodeHistorySumAggregate";
  id?: Maybe<Scalars["Int"]["output"]>;
  invitedStudentId?: Maybe<Scalars["Int"]["output"]>;
  inviteeCoins?: Maybe<Scalars["Int"]["output"]>;
  referralCoins?: Maybe<Scalars["Int"]["output"]>;
  referralStudentId?: Maybe<Scalars["Int"]["output"]>;
};

export type ReferralCodeHistoryUpdateInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  invitedStudent?: InputMaybe<StudentUpdateOneRequiredWithoutInvitedStudentsNestedInput>;
  inviteeCoins?: InputMaybe<IntFieldUpdateOperationsInput>;
  referralCode?: InputMaybe<StringFieldUpdateOperationsInput>;
  referralCoins?: InputMaybe<IntFieldUpdateOperationsInput>;
  referralStudent?: InputMaybe<StudentUpdateOneRequiredWithoutReferralStudentsNestedInput>;
};

export type ReferralCodeHistoryUpdateOneWithoutInvitedStudentNestedInput = {
  connect?: InputMaybe<ReferralCodeHistoryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ReferralCodeHistoryCreateOrConnectWithoutInvitedStudentInput>;
  create?: InputMaybe<ReferralCodeHistoryCreateWithoutInvitedStudentInput>;
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  update?: InputMaybe<ReferralCodeHistoryUpdateWithoutInvitedStudentInput>;
  upsert?: InputMaybe<ReferralCodeHistoryUpsertWithoutInvitedStudentInput>;
};

export type ReferralCodeHistoryUpdateOneWithoutReferralStudentNestedInput = {
  connect?: InputMaybe<ReferralCodeHistoryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ReferralCodeHistoryCreateOrConnectWithoutReferralStudentInput>;
  create?: InputMaybe<ReferralCodeHistoryCreateWithoutReferralStudentInput>;
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  update?: InputMaybe<ReferralCodeHistoryUpdateWithoutReferralStudentInput>;
  upsert?: InputMaybe<ReferralCodeHistoryUpsertWithoutReferralStudentInput>;
};

export type ReferralCodeHistoryUpdateWithoutInvitedStudentInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  inviteeCoins?: InputMaybe<IntFieldUpdateOperationsInput>;
  referralCode?: InputMaybe<StringFieldUpdateOperationsInput>;
  referralCoins?: InputMaybe<IntFieldUpdateOperationsInput>;
  referralStudent?: InputMaybe<StudentUpdateOneRequiredWithoutReferralStudentsNestedInput>;
};

export type ReferralCodeHistoryUpdateWithoutReferralStudentInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  invitedStudent?: InputMaybe<StudentUpdateOneRequiredWithoutInvitedStudentsNestedInput>;
  inviteeCoins?: InputMaybe<IntFieldUpdateOperationsInput>;
  referralCode?: InputMaybe<StringFieldUpdateOperationsInput>;
  referralCoins?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type ReferralCodeHistoryUpsertWithoutInvitedStudentInput = {
  create: ReferralCodeHistoryCreateWithoutInvitedStudentInput;
  update: ReferralCodeHistoryUpdateWithoutInvitedStudentInput;
};

export type ReferralCodeHistoryUpsertWithoutReferralStudentInput = {
  create: ReferralCodeHistoryCreateWithoutReferralStudentInput;
  update: ReferralCodeHistoryUpdateWithoutReferralStudentInput;
};

export type ReferralCodeHistoryWhereInput = {
  AND?: InputMaybe<Array<ReferralCodeHistoryWhereInput>>;
  NOT?: InputMaybe<Array<ReferralCodeHistoryWhereInput>>;
  OR?: InputMaybe<Array<ReferralCodeHistoryWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  invitedStudent?: InputMaybe<StudentRelationFilter>;
  invitedStudentId?: InputMaybe<IntFilter>;
  inviteeCoins?: InputMaybe<IntFilter>;
  referralCode?: InputMaybe<StringFilter>;
  referralCoins?: InputMaybe<IntFilter>;
  referralStudent?: InputMaybe<StudentRelationFilter>;
  referralStudentId?: InputMaybe<IntFilter>;
};

export type ReferralCodeHistoryWhereUniqueInput = {
  id?: InputMaybe<Scalars["Int"]["input"]>;
  invitedStudentId?: InputMaybe<Scalars["Int"]["input"]>;
  referralStudentId?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ReferralCodeListRelationFilter = {
  every?: InputMaybe<ReferralCodeWhereInput>;
  none?: InputMaybe<ReferralCodeWhereInput>;
  some?: InputMaybe<ReferralCodeWhereInput>;
};

export type ReferralCodeMaxAggregate = {
  __typename?: "ReferralCodeMaxAggregate";
  code?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  inviteeCoins?: Maybe<Scalars["Int"]["output"]>;
  isActive?: Maybe<Scalars["Boolean"]["output"]>;
  referralCoins?: Maybe<Scalars["Int"]["output"]>;
  studentId?: Maybe<Scalars["Int"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type ReferralCodeMinAggregate = {
  __typename?: "ReferralCodeMinAggregate";
  code?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  inviteeCoins?: Maybe<Scalars["Int"]["output"]>;
  isActive?: Maybe<Scalars["Boolean"]["output"]>;
  referralCoins?: Maybe<Scalars["Int"]["output"]>;
  studentId?: Maybe<Scalars["Int"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type ReferralCodeOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ReferralCodeOrderByWithRelationInput = {
  code?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  inviteeCoins?: InputMaybe<SortOrder>;
  isActive?: InputMaybe<SortOrder>;
  referralCoins?: InputMaybe<SortOrder>;
  student?: InputMaybe<StudentOrderByWithRelationInput>;
  studentId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum ReferralCodeScalarFieldEnum {
  CODE = "code",
  CREATEDAT = "createdAt",
  ID = "id",
  INVITEECOINS = "inviteeCoins",
  ISACTIVE = "isActive",
  REFERRALCOINS = "referralCoins",
  STUDENTID = "studentId",
  UPDATEDAT = "updatedAt"
}

export type ReferralCodeScalarWhereInput = {
  AND?: InputMaybe<Array<ReferralCodeScalarWhereInput>>;
  NOT?: InputMaybe<Array<ReferralCodeScalarWhereInput>>;
  OR?: InputMaybe<Array<ReferralCodeScalarWhereInput>>;
  code?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  inviteeCoins?: InputMaybe<IntFilter>;
  isActive?: InputMaybe<BoolFilter>;
  referralCoins?: InputMaybe<IntFilter>;
  studentId?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ReferralCodeSumAggregate = {
  __typename?: "ReferralCodeSumAggregate";
  id?: Maybe<Scalars["Int"]["output"]>;
  inviteeCoins?: Maybe<Scalars["Int"]["output"]>;
  referralCoins?: Maybe<Scalars["Int"]["output"]>;
  studentId?: Maybe<Scalars["Int"]["output"]>;
};

export type ReferralCodeUpdateInput = {
  code?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  inviteeCoins?: InputMaybe<IntFieldUpdateOperationsInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  referralCoins?: InputMaybe<IntFieldUpdateOperationsInput>;
  student?: InputMaybe<StudentUpdateOneRequiredWithoutReferralCodesNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ReferralCodeUpdateManyMutationInput = {
  code?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  inviteeCoins?: InputMaybe<IntFieldUpdateOperationsInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  referralCoins?: InputMaybe<IntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ReferralCodeUpdateManyWithWhereWithoutStudentInput = {
  data: ReferralCodeUpdateManyMutationInput;
  where: ReferralCodeScalarWhereInput;
};

export type ReferralCodeUpdateManyWithoutStudentNestedInput = {
  connect?: InputMaybe<Array<ReferralCodeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ReferralCodeCreateOrConnectWithoutStudentInput>>;
  create?: InputMaybe<Array<ReferralCodeCreateWithoutStudentInput>>;
  createMany?: InputMaybe<ReferralCodeCreateManyStudentInputEnvelope>;
  delete?: InputMaybe<Array<ReferralCodeWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ReferralCodeScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ReferralCodeWhereUniqueInput>>;
  set?: InputMaybe<Array<ReferralCodeWhereUniqueInput>>;
  update?: InputMaybe<Array<ReferralCodeUpdateWithWhereUniqueWithoutStudentInput>>;
  updateMany?: InputMaybe<Array<ReferralCodeUpdateManyWithWhereWithoutStudentInput>>;
  upsert?: InputMaybe<Array<ReferralCodeUpsertWithWhereUniqueWithoutStudentInput>>;
};

export type ReferralCodeUpdateWithWhereUniqueWithoutStudentInput = {
  data: ReferralCodeUpdateWithoutStudentInput;
  where: ReferralCodeWhereUniqueInput;
};

export type ReferralCodeUpdateWithoutStudentInput = {
  code?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  inviteeCoins?: InputMaybe<IntFieldUpdateOperationsInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  referralCoins?: InputMaybe<IntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ReferralCodeUpsertWithWhereUniqueWithoutStudentInput = {
  create: ReferralCodeCreateWithoutStudentInput;
  update: ReferralCodeUpdateWithoutStudentInput;
  where: ReferralCodeWhereUniqueInput;
};

export type ReferralCodeWhereInput = {
  AND?: InputMaybe<Array<ReferralCodeWhereInput>>;
  NOT?: InputMaybe<Array<ReferralCodeWhereInput>>;
  OR?: InputMaybe<Array<ReferralCodeWhereInput>>;
  code?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  inviteeCoins?: InputMaybe<IntFilter>;
  isActive?: InputMaybe<BoolFilter>;
  referralCoins?: InputMaybe<IntFilter>;
  student?: InputMaybe<StudentRelationFilter>;
  studentId?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ReferralCodeWhereUniqueInput = {
  code?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
};

export type RefreshTokenResponse = {
  __typename?: "RefreshTokenResponse";
  authToken?: Maybe<Scalars["String"]["output"]>;
  expiresAt?: Maybe<Scalars["DateTime"]["output"]>;
  message: Scalars["String"]["output"];
  rules?: Maybe<Scalars["JSON"]["output"]>;
  success: Scalars["Boolean"]["output"];
  userId?: Maybe<Scalars["String"]["output"]>;
};

export type Report = {
  __typename?: "Report";
  attachment?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  description: Scalars["String"]["output"];
  displayOrder: Scalars["Int"]["output"];
  id: Scalars["Int"]["output"];
  school: School;
  schoolId: Scalars["Int"]["output"];
  standard: Standard;
  standardId: Scalars["Int"]["output"];
  status: Status;
  title: Scalars["String"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export type ReportAvgAggregate = {
  __typename?: "ReportAvgAggregate";
  displayOrder?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  schoolId?: Maybe<Scalars["Float"]["output"]>;
  standardId?: Maybe<Scalars["Float"]["output"]>;
};

export type ReportCountAggregate = {
  __typename?: "ReportCountAggregate";
  _all: Scalars["Int"]["output"];
  attachment: Scalars["Int"]["output"];
  createdAt: Scalars["Int"]["output"];
  description: Scalars["Int"]["output"];
  displayOrder: Scalars["Int"]["output"];
  id: Scalars["Int"]["output"];
  schoolId: Scalars["Int"]["output"];
  standardId: Scalars["Int"]["output"];
  status: Scalars["Int"]["output"];
  title: Scalars["Int"]["output"];
  updatedAt: Scalars["Int"]["output"];
};

export type ReportCreateInput = {
  attachment?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  description: Scalars["String"]["input"];
  displayOrder?: InputMaybe<Scalars["Int"]["input"]>;
  school: SchoolCreateNestedOneWithoutReportsInput;
  standard: StandardCreateNestedOneWithoutReportsInput;
  status?: InputMaybe<Status>;
  title: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ReportCreateManySchoolInput = {
  attachment?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  description: Scalars["String"]["input"];
  displayOrder?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  standardId: Scalars["Int"]["input"];
  status?: InputMaybe<Status>;
  title: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ReportCreateManySchoolInputEnvelope = {
  data: Array<ReportCreateManySchoolInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ReportCreateManyStandardInput = {
  attachment?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  description: Scalars["String"]["input"];
  displayOrder?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  schoolId: Scalars["Int"]["input"];
  status?: InputMaybe<Status>;
  title: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ReportCreateManyStandardInputEnvelope = {
  data: Array<ReportCreateManyStandardInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ReportCreateNestedManyWithoutSchoolInput = {
  connect?: InputMaybe<Array<ReportWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ReportCreateOrConnectWithoutSchoolInput>>;
  create?: InputMaybe<Array<ReportCreateWithoutSchoolInput>>;
  createMany?: InputMaybe<ReportCreateManySchoolInputEnvelope>;
};

export type ReportCreateNestedManyWithoutStandardInput = {
  connect?: InputMaybe<Array<ReportWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ReportCreateOrConnectWithoutStandardInput>>;
  create?: InputMaybe<Array<ReportCreateWithoutStandardInput>>;
  createMany?: InputMaybe<ReportCreateManyStandardInputEnvelope>;
};

export type ReportCreateOrConnectWithoutSchoolInput = {
  create: ReportCreateWithoutSchoolInput;
  where: ReportWhereUniqueInput;
};

export type ReportCreateOrConnectWithoutStandardInput = {
  create: ReportCreateWithoutStandardInput;
  where: ReportWhereUniqueInput;
};

export type ReportCreateWithoutSchoolInput = {
  attachment?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  description: Scalars["String"]["input"];
  displayOrder?: InputMaybe<Scalars["Int"]["input"]>;
  standard: StandardCreateNestedOneWithoutReportsInput;
  status?: InputMaybe<Status>;
  title: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ReportCreateWithoutStandardInput = {
  attachment?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  description: Scalars["String"]["input"];
  displayOrder?: InputMaybe<Scalars["Int"]["input"]>;
  school: SchoolCreateNestedOneWithoutReportsInput;
  status?: InputMaybe<Status>;
  title: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ReportListRelationFilter = {
  every?: InputMaybe<ReportWhereInput>;
  none?: InputMaybe<ReportWhereInput>;
  some?: InputMaybe<ReportWhereInput>;
};

export type ReportMaxAggregate = {
  __typename?: "ReportMaxAggregate";
  attachment?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  displayOrder?: Maybe<Scalars["Int"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  schoolId?: Maybe<Scalars["Int"]["output"]>;
  standardId?: Maybe<Scalars["Int"]["output"]>;
  status?: Maybe<Status>;
  title?: Maybe<Scalars["String"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type ReportMinAggregate = {
  __typename?: "ReportMinAggregate";
  attachment?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  displayOrder?: Maybe<Scalars["Int"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  schoolId?: Maybe<Scalars["Int"]["output"]>;
  standardId?: Maybe<Scalars["Int"]["output"]>;
  status?: Maybe<Status>;
  title?: Maybe<Scalars["String"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type ReportOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ReportOrderByWithRelationInput = {
  attachment?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  displayOrder?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  school?: InputMaybe<SchoolOrderByWithRelationInput>;
  schoolId?: InputMaybe<SortOrder>;
  standard?: InputMaybe<StandardOrderByWithRelationInput>;
  standardId?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum ReportScalarFieldEnum {
  ATTACHMENT = "attachment",
  CREATEDAT = "createdAt",
  DESCRIPTION = "description",
  DISPLAYORDER = "displayOrder",
  ID = "id",
  SCHOOLID = "schoolId",
  STANDARDID = "standardId",
  STATUS = "status",
  TITLE = "title",
  UPDATEDAT = "updatedAt"
}

export type ReportScalarWhereInput = {
  AND?: InputMaybe<Array<ReportScalarWhereInput>>;
  NOT?: InputMaybe<Array<ReportScalarWhereInput>>;
  OR?: InputMaybe<Array<ReportScalarWhereInput>>;
  attachment?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringFilter>;
  displayOrder?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  schoolId?: InputMaybe<IntFilter>;
  standardId?: InputMaybe<IntFilter>;
  status?: InputMaybe<EnumStatusFilter>;
  title?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ReportSumAggregate = {
  __typename?: "ReportSumAggregate";
  displayOrder?: Maybe<Scalars["Int"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  schoolId?: Maybe<Scalars["Int"]["output"]>;
  standardId?: Maybe<Scalars["Int"]["output"]>;
};

export type ReportUpdateInput = {
  attachment?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  displayOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  school?: InputMaybe<SchoolUpdateOneRequiredWithoutReportsNestedInput>;
  standard?: InputMaybe<StandardUpdateOneRequiredWithoutReportsNestedInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ReportUpdateManyMutationInput = {
  attachment?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  displayOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ReportUpdateManyWithWhereWithoutSchoolInput = {
  data: ReportUpdateManyMutationInput;
  where: ReportScalarWhereInput;
};

export type ReportUpdateManyWithWhereWithoutStandardInput = {
  data: ReportUpdateManyMutationInput;
  where: ReportScalarWhereInput;
};

export type ReportUpdateManyWithoutSchoolNestedInput = {
  connect?: InputMaybe<Array<ReportWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ReportCreateOrConnectWithoutSchoolInput>>;
  create?: InputMaybe<Array<ReportCreateWithoutSchoolInput>>;
  createMany?: InputMaybe<ReportCreateManySchoolInputEnvelope>;
  delete?: InputMaybe<Array<ReportWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ReportScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ReportWhereUniqueInput>>;
  set?: InputMaybe<Array<ReportWhereUniqueInput>>;
  update?: InputMaybe<Array<ReportUpdateWithWhereUniqueWithoutSchoolInput>>;
  updateMany?: InputMaybe<Array<ReportUpdateManyWithWhereWithoutSchoolInput>>;
  upsert?: InputMaybe<Array<ReportUpsertWithWhereUniqueWithoutSchoolInput>>;
};

export type ReportUpdateManyWithoutStandardNestedInput = {
  connect?: InputMaybe<Array<ReportWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ReportCreateOrConnectWithoutStandardInput>>;
  create?: InputMaybe<Array<ReportCreateWithoutStandardInput>>;
  createMany?: InputMaybe<ReportCreateManyStandardInputEnvelope>;
  delete?: InputMaybe<Array<ReportWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ReportScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ReportWhereUniqueInput>>;
  set?: InputMaybe<Array<ReportWhereUniqueInput>>;
  update?: InputMaybe<Array<ReportUpdateWithWhereUniqueWithoutStandardInput>>;
  updateMany?: InputMaybe<Array<ReportUpdateManyWithWhereWithoutStandardInput>>;
  upsert?: InputMaybe<Array<ReportUpsertWithWhereUniqueWithoutStandardInput>>;
};

export type ReportUpdateWithWhereUniqueWithoutSchoolInput = {
  data: ReportUpdateWithoutSchoolInput;
  where: ReportWhereUniqueInput;
};

export type ReportUpdateWithWhereUniqueWithoutStandardInput = {
  data: ReportUpdateWithoutStandardInput;
  where: ReportWhereUniqueInput;
};

export type ReportUpdateWithoutSchoolInput = {
  attachment?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  displayOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  standard?: InputMaybe<StandardUpdateOneRequiredWithoutReportsNestedInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ReportUpdateWithoutStandardInput = {
  attachment?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  displayOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  school?: InputMaybe<SchoolUpdateOneRequiredWithoutReportsNestedInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ReportUpsertWithWhereUniqueWithoutSchoolInput = {
  create: ReportCreateWithoutSchoolInput;
  update: ReportUpdateWithoutSchoolInput;
  where: ReportWhereUniqueInput;
};

export type ReportUpsertWithWhereUniqueWithoutStandardInput = {
  create: ReportCreateWithoutStandardInput;
  update: ReportUpdateWithoutStandardInput;
  where: ReportWhereUniqueInput;
};

export type ReportWhereInput = {
  AND?: InputMaybe<Array<ReportWhereInput>>;
  NOT?: InputMaybe<Array<ReportWhereInput>>;
  OR?: InputMaybe<Array<ReportWhereInput>>;
  attachment?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringFilter>;
  displayOrder?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  school?: InputMaybe<SchoolRelationFilter>;
  schoolId?: InputMaybe<IntFilter>;
  standard?: InputMaybe<StandardRelationFilter>;
  standardId?: InputMaybe<IntFilter>;
  status?: InputMaybe<EnumStatusFilter>;
  title?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ReportWhereUniqueInput = {
  id?: InputMaybe<Scalars["Int"]["input"]>;
};

export enum Role {
  ADMIN = "ADMIN",
  MODERATOR = "MODERATOR",
  STUDENT = "STUDENT",
  TEACHER = "TEACHER",
  TRAINER = "TRAINER"
}

export type Samskara = {
  __typename?: "Samskara";
  attachment?: Maybe<Scalars["String"]["output"]>;
  category: SamskaraCategory;
  categoryId: Scalars["Int"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  description: Scalars["String"]["output"];
  displayOrder: Scalars["Int"]["output"];
  id: Scalars["Int"]["output"];
  school: School;
  schoolId: Scalars["Int"]["output"];
  standard: Standard;
  standardId: Scalars["Int"]["output"];
  status: Status;
  title: Scalars["String"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
  video?: Maybe<Scalars["String"]["output"]>;
};

export type SamskaraAvgAggregate = {
  __typename?: "SamskaraAvgAggregate";
  categoryId?: Maybe<Scalars["Float"]["output"]>;
  displayOrder?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  schoolId?: Maybe<Scalars["Float"]["output"]>;
  standardId?: Maybe<Scalars["Float"]["output"]>;
};

export type SamskaraCategory = {
  __typename?: "SamskaraCategory";
  _count?: Maybe<SamskaraCategoryCount>;
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["Int"]["output"];
  image: Scalars["String"]["output"];
  key: Scalars["String"]["output"];
  samskaras: Array<Samskara>;
  value?: Maybe<Scalars["String"]["output"]>;
};


export type SamskaraCategorySamskarasArgs = {
  cursor?: InputMaybe<SamskaraWhereUniqueInput>;
  distinct?: InputMaybe<Array<SamskaraScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<SamskaraOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<SamskaraWhereInput>;
};

export type SamskaraCategoryAvgAggregate = {
  __typename?: "SamskaraCategoryAvgAggregate";
  id?: Maybe<Scalars["Float"]["output"]>;
};

export type SamskaraCategoryCount = {
  __typename?: "SamskaraCategoryCount";
  samskaras: Scalars["Int"]["output"];
};


export type SamskaraCategoryCountSamskarasArgs = {
  where?: InputMaybe<SamskaraWhereInput>;
};

export type SamskaraCategoryCountAggregate = {
  __typename?: "SamskaraCategoryCountAggregate";
  _all: Scalars["Int"]["output"];
  createdAt: Scalars["Int"]["output"];
  id: Scalars["Int"]["output"];
  image: Scalars["Int"]["output"];
  key: Scalars["Int"]["output"];
  value: Scalars["Int"]["output"];
};

export type SamskaraCategoryCreateInput = {
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  image: Scalars["String"]["input"];
  key: Scalars["String"]["input"];
  samskaras?: InputMaybe<SamskaraCreateNestedManyWithoutCategoryInput>;
  value?: InputMaybe<Scalars["String"]["input"]>;
};

export type SamskaraCategoryCreateNestedOneWithoutSamskarasInput = {
  connect?: InputMaybe<SamskaraCategoryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SamskaraCategoryCreateOrConnectWithoutSamskarasInput>;
  create?: InputMaybe<SamskaraCategoryCreateWithoutSamskarasInput>;
};

export type SamskaraCategoryCreateOrConnectWithoutSamskarasInput = {
  create: SamskaraCategoryCreateWithoutSamskarasInput;
  where: SamskaraCategoryWhereUniqueInput;
};

export type SamskaraCategoryCreateWithoutSamskarasInput = {
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  image: Scalars["String"]["input"];
  key: Scalars["String"]["input"];
  value?: InputMaybe<Scalars["String"]["input"]>;
};

export type SamskaraCategoryMaxAggregate = {
  __typename?: "SamskaraCategoryMaxAggregate";
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  image?: Maybe<Scalars["String"]["output"]>;
  key?: Maybe<Scalars["String"]["output"]>;
  value?: Maybe<Scalars["String"]["output"]>;
};

export type SamskaraCategoryMinAggregate = {
  __typename?: "SamskaraCategoryMinAggregate";
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  image?: Maybe<Scalars["String"]["output"]>;
  key?: Maybe<Scalars["String"]["output"]>;
  value?: Maybe<Scalars["String"]["output"]>;
};

export type SamskaraCategoryOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  image?: InputMaybe<SortOrder>;
  key?: InputMaybe<SortOrder>;
  samskaras?: InputMaybe<SamskaraOrderByRelationAggregateInput>;
  value?: InputMaybe<SortOrderInput>;
};

export type SamskaraCategoryRelationFilter = {
  is?: InputMaybe<SamskaraCategoryWhereInput>;
  isNot?: InputMaybe<SamskaraCategoryWhereInput>;
};

export enum SamskaraCategoryScalarFieldEnum {
  CREATEDAT = "createdAt",
  ID = "id",
  IMAGE = "image",
  KEY = "key",
  VALUE = "value"
}

export type SamskaraCategorySumAggregate = {
  __typename?: "SamskaraCategorySumAggregate";
  id?: Maybe<Scalars["Int"]["output"]>;
};

export type SamskaraCategoryUpdateInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  image?: InputMaybe<StringFieldUpdateOperationsInput>;
  key?: InputMaybe<StringFieldUpdateOperationsInput>;
  samskaras?: InputMaybe<SamskaraUpdateManyWithoutCategoryNestedInput>;
  value?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type SamskaraCategoryUpdateOneRequiredWithoutSamskarasNestedInput = {
  connect?: InputMaybe<SamskaraCategoryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SamskaraCategoryCreateOrConnectWithoutSamskarasInput>;
  create?: InputMaybe<SamskaraCategoryCreateWithoutSamskarasInput>;
  update?: InputMaybe<SamskaraCategoryUpdateWithoutSamskarasInput>;
  upsert?: InputMaybe<SamskaraCategoryUpsertWithoutSamskarasInput>;
};

export type SamskaraCategoryUpdateWithoutSamskarasInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  image?: InputMaybe<StringFieldUpdateOperationsInput>;
  key?: InputMaybe<StringFieldUpdateOperationsInput>;
  value?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type SamskaraCategoryUpsertWithoutSamskarasInput = {
  create: SamskaraCategoryCreateWithoutSamskarasInput;
  update: SamskaraCategoryUpdateWithoutSamskarasInput;
};

export type SamskaraCategoryWhereInput = {
  AND?: InputMaybe<Array<SamskaraCategoryWhereInput>>;
  NOT?: InputMaybe<Array<SamskaraCategoryWhereInput>>;
  OR?: InputMaybe<Array<SamskaraCategoryWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  image?: InputMaybe<StringFilter>;
  key?: InputMaybe<StringFilter>;
  samskaras?: InputMaybe<SamskaraListRelationFilter>;
  value?: InputMaybe<StringNullableFilter>;
};

export type SamskaraCategoryWhereUniqueInput = {
  id?: InputMaybe<Scalars["Int"]["input"]>;
  key?: InputMaybe<Scalars["String"]["input"]>;
};

export type SamskaraCountAggregate = {
  __typename?: "SamskaraCountAggregate";
  _all: Scalars["Int"]["output"];
  attachment: Scalars["Int"]["output"];
  categoryId: Scalars["Int"]["output"];
  createdAt: Scalars["Int"]["output"];
  description: Scalars["Int"]["output"];
  displayOrder: Scalars["Int"]["output"];
  id: Scalars["Int"]["output"];
  schoolId: Scalars["Int"]["output"];
  standardId: Scalars["Int"]["output"];
  status: Scalars["Int"]["output"];
  title: Scalars["Int"]["output"];
  updatedAt: Scalars["Int"]["output"];
  video: Scalars["Int"]["output"];
};

export type SamskaraCreateInput = {
  attachment?: InputMaybe<Scalars["String"]["input"]>;
  category: SamskaraCategoryCreateNestedOneWithoutSamskarasInput;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  description: Scalars["String"]["input"];
  displayOrder?: InputMaybe<Scalars["Int"]["input"]>;
  school: SchoolCreateNestedOneWithoutSamskarasInput;
  standard: StandardCreateNestedOneWithoutSamskarasInput;
  status?: InputMaybe<Status>;
  title: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  video?: InputMaybe<Scalars["String"]["input"]>;
};

export type SamskaraCreateManyCategoryInput = {
  attachment?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  description: Scalars["String"]["input"];
  displayOrder?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  schoolId: Scalars["Int"]["input"];
  standardId: Scalars["Int"]["input"];
  status?: InputMaybe<Status>;
  title: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  video?: InputMaybe<Scalars["String"]["input"]>;
};

export type SamskaraCreateManyCategoryInputEnvelope = {
  data: Array<SamskaraCreateManyCategoryInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type SamskaraCreateManySchoolInput = {
  attachment?: InputMaybe<Scalars["String"]["input"]>;
  categoryId: Scalars["Int"]["input"];
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  description: Scalars["String"]["input"];
  displayOrder?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  standardId: Scalars["Int"]["input"];
  status?: InputMaybe<Status>;
  title: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  video?: InputMaybe<Scalars["String"]["input"]>;
};

export type SamskaraCreateManySchoolInputEnvelope = {
  data: Array<SamskaraCreateManySchoolInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type SamskaraCreateManyStandardInput = {
  attachment?: InputMaybe<Scalars["String"]["input"]>;
  categoryId: Scalars["Int"]["input"];
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  description: Scalars["String"]["input"];
  displayOrder?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  schoolId: Scalars["Int"]["input"];
  status?: InputMaybe<Status>;
  title: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  video?: InputMaybe<Scalars["String"]["input"]>;
};

export type SamskaraCreateManyStandardInputEnvelope = {
  data: Array<SamskaraCreateManyStandardInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type SamskaraCreateNestedManyWithoutCategoryInput = {
  connect?: InputMaybe<Array<SamskaraWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SamskaraCreateOrConnectWithoutCategoryInput>>;
  create?: InputMaybe<Array<SamskaraCreateWithoutCategoryInput>>;
  createMany?: InputMaybe<SamskaraCreateManyCategoryInputEnvelope>;
};

export type SamskaraCreateNestedManyWithoutSchoolInput = {
  connect?: InputMaybe<Array<SamskaraWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SamskaraCreateOrConnectWithoutSchoolInput>>;
  create?: InputMaybe<Array<SamskaraCreateWithoutSchoolInput>>;
  createMany?: InputMaybe<SamskaraCreateManySchoolInputEnvelope>;
};

export type SamskaraCreateNestedManyWithoutStandardInput = {
  connect?: InputMaybe<Array<SamskaraWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SamskaraCreateOrConnectWithoutStandardInput>>;
  create?: InputMaybe<Array<SamskaraCreateWithoutStandardInput>>;
  createMany?: InputMaybe<SamskaraCreateManyStandardInputEnvelope>;
};

export type SamskaraCreateOrConnectWithoutCategoryInput = {
  create: SamskaraCreateWithoutCategoryInput;
  where: SamskaraWhereUniqueInput;
};

export type SamskaraCreateOrConnectWithoutSchoolInput = {
  create: SamskaraCreateWithoutSchoolInput;
  where: SamskaraWhereUniqueInput;
};

export type SamskaraCreateOrConnectWithoutStandardInput = {
  create: SamskaraCreateWithoutStandardInput;
  where: SamskaraWhereUniqueInput;
};

export type SamskaraCreateWithoutCategoryInput = {
  attachment?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  description: Scalars["String"]["input"];
  displayOrder?: InputMaybe<Scalars["Int"]["input"]>;
  school: SchoolCreateNestedOneWithoutSamskarasInput;
  standard: StandardCreateNestedOneWithoutSamskarasInput;
  status?: InputMaybe<Status>;
  title: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  video?: InputMaybe<Scalars["String"]["input"]>;
};

export type SamskaraCreateWithoutSchoolInput = {
  attachment?: InputMaybe<Scalars["String"]["input"]>;
  category: SamskaraCategoryCreateNestedOneWithoutSamskarasInput;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  description: Scalars["String"]["input"];
  displayOrder?: InputMaybe<Scalars["Int"]["input"]>;
  standard: StandardCreateNestedOneWithoutSamskarasInput;
  status?: InputMaybe<Status>;
  title: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  video?: InputMaybe<Scalars["String"]["input"]>;
};

export type SamskaraCreateWithoutStandardInput = {
  attachment?: InputMaybe<Scalars["String"]["input"]>;
  category: SamskaraCategoryCreateNestedOneWithoutSamskarasInput;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  description: Scalars["String"]["input"];
  displayOrder?: InputMaybe<Scalars["Int"]["input"]>;
  school: SchoolCreateNestedOneWithoutSamskarasInput;
  status?: InputMaybe<Status>;
  title: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  video?: InputMaybe<Scalars["String"]["input"]>;
};

export type SamskaraListRelationFilter = {
  every?: InputMaybe<SamskaraWhereInput>;
  none?: InputMaybe<SamskaraWhereInput>;
  some?: InputMaybe<SamskaraWhereInput>;
};

export type SamskaraMaxAggregate = {
  __typename?: "SamskaraMaxAggregate";
  attachment?: Maybe<Scalars["String"]["output"]>;
  categoryId?: Maybe<Scalars["Int"]["output"]>;
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  displayOrder?: Maybe<Scalars["Int"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  schoolId?: Maybe<Scalars["Int"]["output"]>;
  standardId?: Maybe<Scalars["Int"]["output"]>;
  status?: Maybe<Status>;
  title?: Maybe<Scalars["String"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
  video?: Maybe<Scalars["String"]["output"]>;
};

export type SamskaraMinAggregate = {
  __typename?: "SamskaraMinAggregate";
  attachment?: Maybe<Scalars["String"]["output"]>;
  categoryId?: Maybe<Scalars["Int"]["output"]>;
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  displayOrder?: Maybe<Scalars["Int"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  schoolId?: Maybe<Scalars["Int"]["output"]>;
  standardId?: Maybe<Scalars["Int"]["output"]>;
  status?: Maybe<Status>;
  title?: Maybe<Scalars["String"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
  video?: Maybe<Scalars["String"]["output"]>;
};

export type SamskaraOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type SamskaraOrderByWithRelationInput = {
  attachment?: InputMaybe<SortOrderInput>;
  category?: InputMaybe<SamskaraCategoryOrderByWithRelationInput>;
  categoryId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  displayOrder?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  school?: InputMaybe<SchoolOrderByWithRelationInput>;
  schoolId?: InputMaybe<SortOrder>;
  standard?: InputMaybe<StandardOrderByWithRelationInput>;
  standardId?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  video?: InputMaybe<SortOrderInput>;
};

export enum SamskaraScalarFieldEnum {
  ATTACHMENT = "attachment",
  CATEGORYID = "categoryId",
  CREATEDAT = "createdAt",
  DESCRIPTION = "description",
  DISPLAYORDER = "displayOrder",
  ID = "id",
  SCHOOLID = "schoolId",
  STANDARDID = "standardId",
  STATUS = "status",
  TITLE = "title",
  UPDATEDAT = "updatedAt",
  VIDEO = "video"
}

export type SamskaraScalarWhereInput = {
  AND?: InputMaybe<Array<SamskaraScalarWhereInput>>;
  NOT?: InputMaybe<Array<SamskaraScalarWhereInput>>;
  OR?: InputMaybe<Array<SamskaraScalarWhereInput>>;
  attachment?: InputMaybe<StringNullableFilter>;
  categoryId?: InputMaybe<IntFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringFilter>;
  displayOrder?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  schoolId?: InputMaybe<IntFilter>;
  standardId?: InputMaybe<IntFilter>;
  status?: InputMaybe<EnumStatusFilter>;
  title?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  video?: InputMaybe<StringNullableFilter>;
};

export type SamskaraSumAggregate = {
  __typename?: "SamskaraSumAggregate";
  categoryId?: Maybe<Scalars["Int"]["output"]>;
  displayOrder?: Maybe<Scalars["Int"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  schoolId?: Maybe<Scalars["Int"]["output"]>;
  standardId?: Maybe<Scalars["Int"]["output"]>;
};

export type SamskaraUpdateInput = {
  attachment?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  category?: InputMaybe<SamskaraCategoryUpdateOneRequiredWithoutSamskarasNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  displayOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  school?: InputMaybe<SchoolUpdateOneRequiredWithoutSamskarasNestedInput>;
  standard?: InputMaybe<StandardUpdateOneRequiredWithoutSamskarasNestedInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  video?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type SamskaraUpdateManyMutationInput = {
  attachment?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  displayOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  video?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type SamskaraUpdateManyWithWhereWithoutCategoryInput = {
  data: SamskaraUpdateManyMutationInput;
  where: SamskaraScalarWhereInput;
};

export type SamskaraUpdateManyWithWhereWithoutSchoolInput = {
  data: SamskaraUpdateManyMutationInput;
  where: SamskaraScalarWhereInput;
};

export type SamskaraUpdateManyWithWhereWithoutStandardInput = {
  data: SamskaraUpdateManyMutationInput;
  where: SamskaraScalarWhereInput;
};

export type SamskaraUpdateManyWithoutCategoryNestedInput = {
  connect?: InputMaybe<Array<SamskaraWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SamskaraCreateOrConnectWithoutCategoryInput>>;
  create?: InputMaybe<Array<SamskaraCreateWithoutCategoryInput>>;
  createMany?: InputMaybe<SamskaraCreateManyCategoryInputEnvelope>;
  delete?: InputMaybe<Array<SamskaraWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SamskaraScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SamskaraWhereUniqueInput>>;
  set?: InputMaybe<Array<SamskaraWhereUniqueInput>>;
  update?: InputMaybe<Array<SamskaraUpdateWithWhereUniqueWithoutCategoryInput>>;
  updateMany?: InputMaybe<Array<SamskaraUpdateManyWithWhereWithoutCategoryInput>>;
  upsert?: InputMaybe<Array<SamskaraUpsertWithWhereUniqueWithoutCategoryInput>>;
};

export type SamskaraUpdateManyWithoutSchoolNestedInput = {
  connect?: InputMaybe<Array<SamskaraWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SamskaraCreateOrConnectWithoutSchoolInput>>;
  create?: InputMaybe<Array<SamskaraCreateWithoutSchoolInput>>;
  createMany?: InputMaybe<SamskaraCreateManySchoolInputEnvelope>;
  delete?: InputMaybe<Array<SamskaraWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SamskaraScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SamskaraWhereUniqueInput>>;
  set?: InputMaybe<Array<SamskaraWhereUniqueInput>>;
  update?: InputMaybe<Array<SamskaraUpdateWithWhereUniqueWithoutSchoolInput>>;
  updateMany?: InputMaybe<Array<SamskaraUpdateManyWithWhereWithoutSchoolInput>>;
  upsert?: InputMaybe<Array<SamskaraUpsertWithWhereUniqueWithoutSchoolInput>>;
};

export type SamskaraUpdateManyWithoutStandardNestedInput = {
  connect?: InputMaybe<Array<SamskaraWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SamskaraCreateOrConnectWithoutStandardInput>>;
  create?: InputMaybe<Array<SamskaraCreateWithoutStandardInput>>;
  createMany?: InputMaybe<SamskaraCreateManyStandardInputEnvelope>;
  delete?: InputMaybe<Array<SamskaraWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SamskaraScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SamskaraWhereUniqueInput>>;
  set?: InputMaybe<Array<SamskaraWhereUniqueInput>>;
  update?: InputMaybe<Array<SamskaraUpdateWithWhereUniqueWithoutStandardInput>>;
  updateMany?: InputMaybe<Array<SamskaraUpdateManyWithWhereWithoutStandardInput>>;
  upsert?: InputMaybe<Array<SamskaraUpsertWithWhereUniqueWithoutStandardInput>>;
};

export type SamskaraUpdateWithWhereUniqueWithoutCategoryInput = {
  data: SamskaraUpdateWithoutCategoryInput;
  where: SamskaraWhereUniqueInput;
};

export type SamskaraUpdateWithWhereUniqueWithoutSchoolInput = {
  data: SamskaraUpdateWithoutSchoolInput;
  where: SamskaraWhereUniqueInput;
};

export type SamskaraUpdateWithWhereUniqueWithoutStandardInput = {
  data: SamskaraUpdateWithoutStandardInput;
  where: SamskaraWhereUniqueInput;
};

export type SamskaraUpdateWithoutCategoryInput = {
  attachment?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  displayOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  school?: InputMaybe<SchoolUpdateOneRequiredWithoutSamskarasNestedInput>;
  standard?: InputMaybe<StandardUpdateOneRequiredWithoutSamskarasNestedInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  video?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type SamskaraUpdateWithoutSchoolInput = {
  attachment?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  category?: InputMaybe<SamskaraCategoryUpdateOneRequiredWithoutSamskarasNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  displayOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  standard?: InputMaybe<StandardUpdateOneRequiredWithoutSamskarasNestedInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  video?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type SamskaraUpdateWithoutStandardInput = {
  attachment?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  category?: InputMaybe<SamskaraCategoryUpdateOneRequiredWithoutSamskarasNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  displayOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  school?: InputMaybe<SchoolUpdateOneRequiredWithoutSamskarasNestedInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  video?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type SamskaraUpsertWithWhereUniqueWithoutCategoryInput = {
  create: SamskaraCreateWithoutCategoryInput;
  update: SamskaraUpdateWithoutCategoryInput;
  where: SamskaraWhereUniqueInput;
};

export type SamskaraUpsertWithWhereUniqueWithoutSchoolInput = {
  create: SamskaraCreateWithoutSchoolInput;
  update: SamskaraUpdateWithoutSchoolInput;
  where: SamskaraWhereUniqueInput;
};

export type SamskaraUpsertWithWhereUniqueWithoutStandardInput = {
  create: SamskaraCreateWithoutStandardInput;
  update: SamskaraUpdateWithoutStandardInput;
  where: SamskaraWhereUniqueInput;
};

export type SamskaraWhereInput = {
  AND?: InputMaybe<Array<SamskaraWhereInput>>;
  NOT?: InputMaybe<Array<SamskaraWhereInput>>;
  OR?: InputMaybe<Array<SamskaraWhereInput>>;
  attachment?: InputMaybe<StringNullableFilter>;
  category?: InputMaybe<SamskaraCategoryRelationFilter>;
  categoryId?: InputMaybe<IntFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringFilter>;
  displayOrder?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  school?: InputMaybe<SchoolRelationFilter>;
  schoolId?: InputMaybe<IntFilter>;
  standard?: InputMaybe<StandardRelationFilter>;
  standardId?: InputMaybe<IntFilter>;
  status?: InputMaybe<EnumStatusFilter>;
  title?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  video?: InputMaybe<StringNullableFilter>;
};

export type SamskaraWhereUniqueInput = {
  id?: InputMaybe<Scalars["Int"]["input"]>;
};

export type School = {
  __typename?: "School";
  _count?: Maybe<SchoolCount>;
  area?: Maybe<Scalars["String"]["output"]>;
  city?: Maybe<Scalars["String"]["output"]>;
  code: Scalars["String"]["output"];
  country?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  group?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["Int"]["output"];
  name: Scalars["String"]["output"];
  reports: Array<Report>;
  samskaras: Array<Samskara>;
  schoolUpdates: Array<SchoolUpdate>;
  skills: Array<Skill>;
  state?: Maybe<Scalars["String"]["output"]>;
  stories: Array<Story>;
  studentRecords: Array<StudentRecord>;
  students: Array<Student>;
  updatedAt: Scalars["DateTime"]["output"];
  users: Array<User>;
  workshops: Array<Workshop>;
};


export type SchoolReportsArgs = {
  cursor?: InputMaybe<ReportWhereUniqueInput>;
  distinct?: InputMaybe<Array<ReportScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ReportOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ReportWhereInput>;
};


export type SchoolSamskarasArgs = {
  cursor?: InputMaybe<SamskaraWhereUniqueInput>;
  distinct?: InputMaybe<Array<SamskaraScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<SamskaraOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<SamskaraWhereInput>;
};


export type SchoolSchoolUpdatesArgs = {
  cursor?: InputMaybe<SchoolUpdateWhereUniqueInput>;
  distinct?: InputMaybe<Array<SchoolUpdateScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<SchoolUpdateOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<SchoolUpdateWhereInput>;
};


export type SchoolSkillsArgs = {
  cursor?: InputMaybe<SkillWhereUniqueInput>;
  distinct?: InputMaybe<Array<SkillScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<SkillOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<SkillWhereInput>;
};


export type SchoolStoriesArgs = {
  cursor?: InputMaybe<StoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<StoryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<StoryWhereInput>;
};


export type SchoolStudentRecordsArgs = {
  cursor?: InputMaybe<StudentRecordWhereUniqueInput>;
  distinct?: InputMaybe<Array<StudentRecordScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StudentRecordOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<StudentRecordWhereInput>;
};


export type SchoolStudentsArgs = {
  cursor?: InputMaybe<StudentWhereUniqueInput>;
  distinct?: InputMaybe<Array<StudentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StudentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<StudentWhereInput>;
};


export type SchoolUsersArgs = {
  cursor?: InputMaybe<UserWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<UserWhereInput>;
};


export type SchoolWorkshopsArgs = {
  cursor?: InputMaybe<WorkshopWhereUniqueInput>;
  distinct?: InputMaybe<Array<WorkshopScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<WorkshopOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<WorkshopWhereInput>;
};

export type SchoolAvgAggregate = {
  __typename?: "SchoolAvgAggregate";
  id?: Maybe<Scalars["Float"]["output"]>;
};

export type SchoolCount = {
  __typename?: "SchoolCount";
  reports: Scalars["Int"]["output"];
  samskaras: Scalars["Int"]["output"];
  schoolUpdates: Scalars["Int"]["output"];
  skills: Scalars["Int"]["output"];
  stories: Scalars["Int"]["output"];
  studentRecords: Scalars["Int"]["output"];
  students: Scalars["Int"]["output"];
  users: Scalars["Int"]["output"];
  workshops: Scalars["Int"]["output"];
};


export type SchoolCountReportsArgs = {
  where?: InputMaybe<ReportWhereInput>;
};


export type SchoolCountSamskarasArgs = {
  where?: InputMaybe<SamskaraWhereInput>;
};


export type SchoolCountSchoolUpdatesArgs = {
  where?: InputMaybe<SchoolUpdateWhereInput>;
};


export type SchoolCountSkillsArgs = {
  where?: InputMaybe<SkillWhereInput>;
};


export type SchoolCountStoriesArgs = {
  where?: InputMaybe<StoryWhereInput>;
};


export type SchoolCountStudentRecordsArgs = {
  where?: InputMaybe<StudentRecordWhereInput>;
};


export type SchoolCountStudentsArgs = {
  where?: InputMaybe<StudentWhereInput>;
};


export type SchoolCountUsersArgs = {
  where?: InputMaybe<UserWhereInput>;
};


export type SchoolCountWorkshopsArgs = {
  where?: InputMaybe<WorkshopWhereInput>;
};

export type SchoolCountAggregate = {
  __typename?: "SchoolCountAggregate";
  _all: Scalars["Int"]["output"];
  area: Scalars["Int"]["output"];
  city: Scalars["Int"]["output"];
  code: Scalars["Int"]["output"];
  country: Scalars["Int"]["output"];
  createdAt: Scalars["Int"]["output"];
  group: Scalars["Int"]["output"];
  id: Scalars["Int"]["output"];
  name: Scalars["Int"]["output"];
  state: Scalars["Int"]["output"];
  updatedAt: Scalars["Int"]["output"];
};

export type SchoolCreateInput = {
  area?: InputMaybe<Scalars["String"]["input"]>;
  city?: InputMaybe<Scalars["String"]["input"]>;
  code: Scalars["String"]["input"];
  country?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  group?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  reports?: InputMaybe<ReportCreateNestedManyWithoutSchoolInput>;
  samskaras?: InputMaybe<SamskaraCreateNestedManyWithoutSchoolInput>;
  schoolUpdates?: InputMaybe<SchoolUpdateCreateNestedManyWithoutSchoolInput>;
  skills?: InputMaybe<SkillCreateNestedManyWithoutSchoolInput>;
  state?: InputMaybe<Scalars["String"]["input"]>;
  stories?: InputMaybe<StoryCreateNestedManyWithoutSchoolInput>;
  studentRecords?: InputMaybe<StudentRecordCreateNestedManyWithoutSchoolInput>;
  students?: InputMaybe<StudentCreateNestedManyWithoutSchoolInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  users?: InputMaybe<UserCreateNestedManyWithoutSchoolsInput>;
  workshops?: InputMaybe<WorkshopCreateNestedManyWithoutSchoolInput>;
};

export type SchoolCreateNestedManyWithoutUsersInput = {
  connect?: InputMaybe<Array<SchoolWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SchoolCreateOrConnectWithoutUsersInput>>;
  create?: InputMaybe<Array<SchoolCreateWithoutUsersInput>>;
};

export type SchoolCreateNestedOneWithoutReportsInput = {
  connect?: InputMaybe<SchoolWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SchoolCreateOrConnectWithoutReportsInput>;
  create?: InputMaybe<SchoolCreateWithoutReportsInput>;
};

export type SchoolCreateNestedOneWithoutSamskarasInput = {
  connect?: InputMaybe<SchoolWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SchoolCreateOrConnectWithoutSamskarasInput>;
  create?: InputMaybe<SchoolCreateWithoutSamskarasInput>;
};

export type SchoolCreateNestedOneWithoutSchoolUpdatesInput = {
  connect?: InputMaybe<SchoolWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SchoolCreateOrConnectWithoutSchoolUpdatesInput>;
  create?: InputMaybe<SchoolCreateWithoutSchoolUpdatesInput>;
};

export type SchoolCreateNestedOneWithoutSkillsInput = {
  connect?: InputMaybe<SchoolWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SchoolCreateOrConnectWithoutSkillsInput>;
  create?: InputMaybe<SchoolCreateWithoutSkillsInput>;
};

export type SchoolCreateNestedOneWithoutStoriesInput = {
  connect?: InputMaybe<SchoolWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SchoolCreateOrConnectWithoutStoriesInput>;
  create?: InputMaybe<SchoolCreateWithoutStoriesInput>;
};

export type SchoolCreateNestedOneWithoutStudentRecordsInput = {
  connect?: InputMaybe<SchoolWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SchoolCreateOrConnectWithoutStudentRecordsInput>;
  create?: InputMaybe<SchoolCreateWithoutStudentRecordsInput>;
};

export type SchoolCreateNestedOneWithoutStudentsInput = {
  connect?: InputMaybe<SchoolWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SchoolCreateOrConnectWithoutStudentsInput>;
  create?: InputMaybe<SchoolCreateWithoutStudentsInput>;
};

export type SchoolCreateNestedOneWithoutWorkshopsInput = {
  connect?: InputMaybe<SchoolWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SchoolCreateOrConnectWithoutWorkshopsInput>;
  create?: InputMaybe<SchoolCreateWithoutWorkshopsInput>;
};

export type SchoolCreateOrConnectWithoutReportsInput = {
  create: SchoolCreateWithoutReportsInput;
  where: SchoolWhereUniqueInput;
};

export type SchoolCreateOrConnectWithoutSamskarasInput = {
  create: SchoolCreateWithoutSamskarasInput;
  where: SchoolWhereUniqueInput;
};

export type SchoolCreateOrConnectWithoutSchoolUpdatesInput = {
  create: SchoolCreateWithoutSchoolUpdatesInput;
  where: SchoolWhereUniqueInput;
};

export type SchoolCreateOrConnectWithoutSkillsInput = {
  create: SchoolCreateWithoutSkillsInput;
  where: SchoolWhereUniqueInput;
};

export type SchoolCreateOrConnectWithoutStoriesInput = {
  create: SchoolCreateWithoutStoriesInput;
  where: SchoolWhereUniqueInput;
};

export type SchoolCreateOrConnectWithoutStudentRecordsInput = {
  create: SchoolCreateWithoutStudentRecordsInput;
  where: SchoolWhereUniqueInput;
};

export type SchoolCreateOrConnectWithoutStudentsInput = {
  create: SchoolCreateWithoutStudentsInput;
  where: SchoolWhereUniqueInput;
};

export type SchoolCreateOrConnectWithoutUsersInput = {
  create: SchoolCreateWithoutUsersInput;
  where: SchoolWhereUniqueInput;
};

export type SchoolCreateOrConnectWithoutWorkshopsInput = {
  create: SchoolCreateWithoutWorkshopsInput;
  where: SchoolWhereUniqueInput;
};

export type SchoolCreateWithoutReportsInput = {
  area?: InputMaybe<Scalars["String"]["input"]>;
  city?: InputMaybe<Scalars["String"]["input"]>;
  code: Scalars["String"]["input"];
  country?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  group?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  samskaras?: InputMaybe<SamskaraCreateNestedManyWithoutSchoolInput>;
  schoolUpdates?: InputMaybe<SchoolUpdateCreateNestedManyWithoutSchoolInput>;
  skills?: InputMaybe<SkillCreateNestedManyWithoutSchoolInput>;
  state?: InputMaybe<Scalars["String"]["input"]>;
  stories?: InputMaybe<StoryCreateNestedManyWithoutSchoolInput>;
  studentRecords?: InputMaybe<StudentRecordCreateNestedManyWithoutSchoolInput>;
  students?: InputMaybe<StudentCreateNestedManyWithoutSchoolInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  users?: InputMaybe<UserCreateNestedManyWithoutSchoolsInput>;
  workshops?: InputMaybe<WorkshopCreateNestedManyWithoutSchoolInput>;
};

export type SchoolCreateWithoutSamskarasInput = {
  area?: InputMaybe<Scalars["String"]["input"]>;
  city?: InputMaybe<Scalars["String"]["input"]>;
  code: Scalars["String"]["input"];
  country?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  group?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  reports?: InputMaybe<ReportCreateNestedManyWithoutSchoolInput>;
  schoolUpdates?: InputMaybe<SchoolUpdateCreateNestedManyWithoutSchoolInput>;
  skills?: InputMaybe<SkillCreateNestedManyWithoutSchoolInput>;
  state?: InputMaybe<Scalars["String"]["input"]>;
  stories?: InputMaybe<StoryCreateNestedManyWithoutSchoolInput>;
  studentRecords?: InputMaybe<StudentRecordCreateNestedManyWithoutSchoolInput>;
  students?: InputMaybe<StudentCreateNestedManyWithoutSchoolInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  users?: InputMaybe<UserCreateNestedManyWithoutSchoolsInput>;
  workshops?: InputMaybe<WorkshopCreateNestedManyWithoutSchoolInput>;
};

export type SchoolCreateWithoutSchoolUpdatesInput = {
  area?: InputMaybe<Scalars["String"]["input"]>;
  city?: InputMaybe<Scalars["String"]["input"]>;
  code: Scalars["String"]["input"];
  country?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  group?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  reports?: InputMaybe<ReportCreateNestedManyWithoutSchoolInput>;
  samskaras?: InputMaybe<SamskaraCreateNestedManyWithoutSchoolInput>;
  skills?: InputMaybe<SkillCreateNestedManyWithoutSchoolInput>;
  state?: InputMaybe<Scalars["String"]["input"]>;
  stories?: InputMaybe<StoryCreateNestedManyWithoutSchoolInput>;
  studentRecords?: InputMaybe<StudentRecordCreateNestedManyWithoutSchoolInput>;
  students?: InputMaybe<StudentCreateNestedManyWithoutSchoolInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  users?: InputMaybe<UserCreateNestedManyWithoutSchoolsInput>;
  workshops?: InputMaybe<WorkshopCreateNestedManyWithoutSchoolInput>;
};

export type SchoolCreateWithoutSkillsInput = {
  area?: InputMaybe<Scalars["String"]["input"]>;
  city?: InputMaybe<Scalars["String"]["input"]>;
  code: Scalars["String"]["input"];
  country?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  group?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  reports?: InputMaybe<ReportCreateNestedManyWithoutSchoolInput>;
  samskaras?: InputMaybe<SamskaraCreateNestedManyWithoutSchoolInput>;
  schoolUpdates?: InputMaybe<SchoolUpdateCreateNestedManyWithoutSchoolInput>;
  state?: InputMaybe<Scalars["String"]["input"]>;
  stories?: InputMaybe<StoryCreateNestedManyWithoutSchoolInput>;
  studentRecords?: InputMaybe<StudentRecordCreateNestedManyWithoutSchoolInput>;
  students?: InputMaybe<StudentCreateNestedManyWithoutSchoolInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  users?: InputMaybe<UserCreateNestedManyWithoutSchoolsInput>;
  workshops?: InputMaybe<WorkshopCreateNestedManyWithoutSchoolInput>;
};

export type SchoolCreateWithoutStoriesInput = {
  area?: InputMaybe<Scalars["String"]["input"]>;
  city?: InputMaybe<Scalars["String"]["input"]>;
  code: Scalars["String"]["input"];
  country?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  group?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  reports?: InputMaybe<ReportCreateNestedManyWithoutSchoolInput>;
  samskaras?: InputMaybe<SamskaraCreateNestedManyWithoutSchoolInput>;
  schoolUpdates?: InputMaybe<SchoolUpdateCreateNestedManyWithoutSchoolInput>;
  skills?: InputMaybe<SkillCreateNestedManyWithoutSchoolInput>;
  state?: InputMaybe<Scalars["String"]["input"]>;
  studentRecords?: InputMaybe<StudentRecordCreateNestedManyWithoutSchoolInput>;
  students?: InputMaybe<StudentCreateNestedManyWithoutSchoolInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  users?: InputMaybe<UserCreateNestedManyWithoutSchoolsInput>;
  workshops?: InputMaybe<WorkshopCreateNestedManyWithoutSchoolInput>;
};

export type SchoolCreateWithoutStudentRecordsInput = {
  area?: InputMaybe<Scalars["String"]["input"]>;
  city?: InputMaybe<Scalars["String"]["input"]>;
  code: Scalars["String"]["input"];
  country?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  group?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  reports?: InputMaybe<ReportCreateNestedManyWithoutSchoolInput>;
  samskaras?: InputMaybe<SamskaraCreateNestedManyWithoutSchoolInput>;
  schoolUpdates?: InputMaybe<SchoolUpdateCreateNestedManyWithoutSchoolInput>;
  skills?: InputMaybe<SkillCreateNestedManyWithoutSchoolInput>;
  state?: InputMaybe<Scalars["String"]["input"]>;
  stories?: InputMaybe<StoryCreateNestedManyWithoutSchoolInput>;
  students?: InputMaybe<StudentCreateNestedManyWithoutSchoolInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  users?: InputMaybe<UserCreateNestedManyWithoutSchoolsInput>;
  workshops?: InputMaybe<WorkshopCreateNestedManyWithoutSchoolInput>;
};

export type SchoolCreateWithoutStudentsInput = {
  area?: InputMaybe<Scalars["String"]["input"]>;
  city?: InputMaybe<Scalars["String"]["input"]>;
  code: Scalars["String"]["input"];
  country?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  group?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  reports?: InputMaybe<ReportCreateNestedManyWithoutSchoolInput>;
  samskaras?: InputMaybe<SamskaraCreateNestedManyWithoutSchoolInput>;
  schoolUpdates?: InputMaybe<SchoolUpdateCreateNestedManyWithoutSchoolInput>;
  skills?: InputMaybe<SkillCreateNestedManyWithoutSchoolInput>;
  state?: InputMaybe<Scalars["String"]["input"]>;
  stories?: InputMaybe<StoryCreateNestedManyWithoutSchoolInput>;
  studentRecords?: InputMaybe<StudentRecordCreateNestedManyWithoutSchoolInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  users?: InputMaybe<UserCreateNestedManyWithoutSchoolsInput>;
  workshops?: InputMaybe<WorkshopCreateNestedManyWithoutSchoolInput>;
};

export type SchoolCreateWithoutUsersInput = {
  area?: InputMaybe<Scalars["String"]["input"]>;
  city?: InputMaybe<Scalars["String"]["input"]>;
  code: Scalars["String"]["input"];
  country?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  group?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  reports?: InputMaybe<ReportCreateNestedManyWithoutSchoolInput>;
  samskaras?: InputMaybe<SamskaraCreateNestedManyWithoutSchoolInput>;
  schoolUpdates?: InputMaybe<SchoolUpdateCreateNestedManyWithoutSchoolInput>;
  skills?: InputMaybe<SkillCreateNestedManyWithoutSchoolInput>;
  state?: InputMaybe<Scalars["String"]["input"]>;
  stories?: InputMaybe<StoryCreateNestedManyWithoutSchoolInput>;
  studentRecords?: InputMaybe<StudentRecordCreateNestedManyWithoutSchoolInput>;
  students?: InputMaybe<StudentCreateNestedManyWithoutSchoolInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  workshops?: InputMaybe<WorkshopCreateNestedManyWithoutSchoolInput>;
};

export type SchoolCreateWithoutWorkshopsInput = {
  area?: InputMaybe<Scalars["String"]["input"]>;
  city?: InputMaybe<Scalars["String"]["input"]>;
  code: Scalars["String"]["input"];
  country?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  group?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  reports?: InputMaybe<ReportCreateNestedManyWithoutSchoolInput>;
  samskaras?: InputMaybe<SamskaraCreateNestedManyWithoutSchoolInput>;
  schoolUpdates?: InputMaybe<SchoolUpdateCreateNestedManyWithoutSchoolInput>;
  skills?: InputMaybe<SkillCreateNestedManyWithoutSchoolInput>;
  state?: InputMaybe<Scalars["String"]["input"]>;
  stories?: InputMaybe<StoryCreateNestedManyWithoutSchoolInput>;
  studentRecords?: InputMaybe<StudentRecordCreateNestedManyWithoutSchoolInput>;
  students?: InputMaybe<StudentCreateNestedManyWithoutSchoolInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  users?: InputMaybe<UserCreateNestedManyWithoutSchoolsInput>;
};

export type SchoolListRelationFilter = {
  every?: InputMaybe<SchoolWhereInput>;
  none?: InputMaybe<SchoolWhereInput>;
  some?: InputMaybe<SchoolWhereInput>;
};

export type SchoolMaxAggregate = {
  __typename?: "SchoolMaxAggregate";
  area?: Maybe<Scalars["String"]["output"]>;
  city?: Maybe<Scalars["String"]["output"]>;
  code?: Maybe<Scalars["String"]["output"]>;
  country?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  group?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  state?: Maybe<Scalars["String"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type SchoolMinAggregate = {
  __typename?: "SchoolMinAggregate";
  area?: Maybe<Scalars["String"]["output"]>;
  city?: Maybe<Scalars["String"]["output"]>;
  code?: Maybe<Scalars["String"]["output"]>;
  country?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  group?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  state?: Maybe<Scalars["String"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type SchoolOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type SchoolOrderByWithRelationInput = {
  area?: InputMaybe<SortOrderInput>;
  city?: InputMaybe<SortOrderInput>;
  code?: InputMaybe<SortOrder>;
  country?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  group?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  reports?: InputMaybe<ReportOrderByRelationAggregateInput>;
  samskaras?: InputMaybe<SamskaraOrderByRelationAggregateInput>;
  schoolUpdates?: InputMaybe<SchoolUpdateOrderByRelationAggregateInput>;
  skills?: InputMaybe<SkillOrderByRelationAggregateInput>;
  state?: InputMaybe<SortOrderInput>;
  stories?: InputMaybe<StoryOrderByRelationAggregateInput>;
  studentRecords?: InputMaybe<StudentRecordOrderByRelationAggregateInput>;
  students?: InputMaybe<StudentOrderByRelationAggregateInput>;
  updatedAt?: InputMaybe<SortOrder>;
  users?: InputMaybe<UserOrderByRelationAggregateInput>;
  workshops?: InputMaybe<WorkshopOrderByRelationAggregateInput>;
};

export type SchoolRelationFilter = {
  is?: InputMaybe<SchoolWhereInput>;
  isNot?: InputMaybe<SchoolWhereInput>;
};

export enum SchoolScalarFieldEnum {
  AREA = "area",
  CITY = "city",
  CODE = "code",
  COUNTRY = "country",
  CREATEDAT = "createdAt",
  GROUP = "group",
  ID = "id",
  NAME = "name",
  STATE = "state",
  UPDATEDAT = "updatedAt"
}

export type SchoolScalarWhereInput = {
  AND?: InputMaybe<Array<SchoolScalarWhereInput>>;
  NOT?: InputMaybe<Array<SchoolScalarWhereInput>>;
  OR?: InputMaybe<Array<SchoolScalarWhereInput>>;
  area?: InputMaybe<StringNullableFilter>;
  city?: InputMaybe<StringNullableFilter>;
  code?: InputMaybe<StringFilter>;
  country?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  group?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  state?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type SchoolSumAggregate = {
  __typename?: "SchoolSumAggregate";
  id?: Maybe<Scalars["Int"]["output"]>;
};

export type SchoolUpdate = {
  __typename?: "SchoolUpdate";
  _count?: Maybe<SchoolUpdateCount>;
  asset?: Maybe<Scalars["String"]["output"]>;
  attachment?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  description?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["Int"]["output"];
  school: School;
  schoolId: Scalars["Int"]["output"];
  standards: Array<Standard>;
  tags: Scalars["JSON"]["output"];
  title: Scalars["String"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};


export type SchoolUpdateStandardsArgs = {
  cursor?: InputMaybe<StandardWhereUniqueInput>;
  distinct?: InputMaybe<Array<StandardScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StandardOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<StandardWhereInput>;
};

export type SchoolUpdateAvgAggregate = {
  __typename?: "SchoolUpdateAvgAggregate";
  id?: Maybe<Scalars["Float"]["output"]>;
  schoolId?: Maybe<Scalars["Float"]["output"]>;
};

export type SchoolUpdateCount = {
  __typename?: "SchoolUpdateCount";
  standards: Scalars["Int"]["output"];
};


export type SchoolUpdateCountStandardsArgs = {
  where?: InputMaybe<StandardWhereInput>;
};

export type SchoolUpdateCountAggregate = {
  __typename?: "SchoolUpdateCountAggregate";
  _all: Scalars["Int"]["output"];
  asset: Scalars["Int"]["output"];
  attachment: Scalars["Int"]["output"];
  createdAt: Scalars["Int"]["output"];
  description: Scalars["Int"]["output"];
  id: Scalars["Int"]["output"];
  schoolId: Scalars["Int"]["output"];
  tags: Scalars["Int"]["output"];
  title: Scalars["Int"]["output"];
  updatedAt: Scalars["Int"]["output"];
};

export type SchoolUpdateCreateInput = {
  asset?: InputMaybe<Scalars["String"]["input"]>;
  attachment?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  school: SchoolCreateNestedOneWithoutSchoolUpdatesInput;
  standards?: InputMaybe<StandardCreateNestedManyWithoutSchoolUpdatesInput>;
  tags: Scalars["JSON"]["input"];
  title: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolUpdateCreateManySchoolInput = {
  asset?: InputMaybe<Scalars["String"]["input"]>;
  attachment?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  tags: Scalars["JSON"]["input"];
  title: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolUpdateCreateManySchoolInputEnvelope = {
  data: Array<SchoolUpdateCreateManySchoolInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type SchoolUpdateCreateNestedManyWithoutSchoolInput = {
  connect?: InputMaybe<Array<SchoolUpdateWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SchoolUpdateCreateOrConnectWithoutSchoolInput>>;
  create?: InputMaybe<Array<SchoolUpdateCreateWithoutSchoolInput>>;
  createMany?: InputMaybe<SchoolUpdateCreateManySchoolInputEnvelope>;
};

export type SchoolUpdateCreateNestedManyWithoutStandardsInput = {
  connect?: InputMaybe<Array<SchoolUpdateWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SchoolUpdateCreateOrConnectWithoutStandardsInput>>;
  create?: InputMaybe<Array<SchoolUpdateCreateWithoutStandardsInput>>;
};

export type SchoolUpdateCreateOrConnectWithoutSchoolInput = {
  create: SchoolUpdateCreateWithoutSchoolInput;
  where: SchoolUpdateWhereUniqueInput;
};

export type SchoolUpdateCreateOrConnectWithoutStandardsInput = {
  create: SchoolUpdateCreateWithoutStandardsInput;
  where: SchoolUpdateWhereUniqueInput;
};

export type SchoolUpdateCreateWithoutSchoolInput = {
  asset?: InputMaybe<Scalars["String"]["input"]>;
  attachment?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  standards?: InputMaybe<StandardCreateNestedManyWithoutSchoolUpdatesInput>;
  tags: Scalars["JSON"]["input"];
  title: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolUpdateCreateWithoutStandardsInput = {
  asset?: InputMaybe<Scalars["String"]["input"]>;
  attachment?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  school: SchoolCreateNestedOneWithoutSchoolUpdatesInput;
  tags: Scalars["JSON"]["input"];
  title: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SchoolUpdateInput = {
  area?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  code?: InputMaybe<StringFieldUpdateOperationsInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  group?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  reports?: InputMaybe<ReportUpdateManyWithoutSchoolNestedInput>;
  samskaras?: InputMaybe<SamskaraUpdateManyWithoutSchoolNestedInput>;
  schoolUpdates?: InputMaybe<SchoolUpdateUpdateManyWithoutSchoolNestedInput>;
  skills?: InputMaybe<SkillUpdateManyWithoutSchoolNestedInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stories?: InputMaybe<StoryUpdateManyWithoutSchoolNestedInput>;
  studentRecords?: InputMaybe<StudentRecordUpdateManyWithoutSchoolNestedInput>;
  students?: InputMaybe<StudentUpdateManyWithoutSchoolNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  users?: InputMaybe<UserUpdateManyWithoutSchoolsNestedInput>;
  workshops?: InputMaybe<WorkshopUpdateManyWithoutSchoolNestedInput>;
};

export type SchoolUpdateListRelationFilter = {
  every?: InputMaybe<SchoolUpdateWhereInput>;
  none?: InputMaybe<SchoolUpdateWhereInput>;
  some?: InputMaybe<SchoolUpdateWhereInput>;
};

export type SchoolUpdateManyMutationInput = {
  area?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  code?: InputMaybe<StringFieldUpdateOperationsInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  group?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SchoolUpdateManyWithWhereWithoutUsersInput = {
  data: SchoolUpdateManyMutationInput;
  where: SchoolScalarWhereInput;
};

export type SchoolUpdateManyWithoutUsersNestedInput = {
  connect?: InputMaybe<Array<SchoolWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SchoolCreateOrConnectWithoutUsersInput>>;
  create?: InputMaybe<Array<SchoolCreateWithoutUsersInput>>;
  delete?: InputMaybe<Array<SchoolWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SchoolScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SchoolWhereUniqueInput>>;
  set?: InputMaybe<Array<SchoolWhereUniqueInput>>;
  update?: InputMaybe<Array<SchoolUpdateWithWhereUniqueWithoutUsersInput>>;
  updateMany?: InputMaybe<Array<SchoolUpdateManyWithWhereWithoutUsersInput>>;
  upsert?: InputMaybe<Array<SchoolUpsertWithWhereUniqueWithoutUsersInput>>;
};

export type SchoolUpdateMaxAggregate = {
  __typename?: "SchoolUpdateMaxAggregate";
  asset?: Maybe<Scalars["String"]["output"]>;
  attachment?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  schoolId?: Maybe<Scalars["Int"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type SchoolUpdateMinAggregate = {
  __typename?: "SchoolUpdateMinAggregate";
  asset?: Maybe<Scalars["String"]["output"]>;
  attachment?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  schoolId?: Maybe<Scalars["Int"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type SchoolUpdateOneRequiredWithoutReportsNestedInput = {
  connect?: InputMaybe<SchoolWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SchoolCreateOrConnectWithoutReportsInput>;
  create?: InputMaybe<SchoolCreateWithoutReportsInput>;
  update?: InputMaybe<SchoolUpdateWithoutReportsInput>;
  upsert?: InputMaybe<SchoolUpsertWithoutReportsInput>;
};

export type SchoolUpdateOneRequiredWithoutSamskarasNestedInput = {
  connect?: InputMaybe<SchoolWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SchoolCreateOrConnectWithoutSamskarasInput>;
  create?: InputMaybe<SchoolCreateWithoutSamskarasInput>;
  update?: InputMaybe<SchoolUpdateWithoutSamskarasInput>;
  upsert?: InputMaybe<SchoolUpsertWithoutSamskarasInput>;
};

export type SchoolUpdateOneRequiredWithoutSchoolUpdatesNestedInput = {
  connect?: InputMaybe<SchoolWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SchoolCreateOrConnectWithoutSchoolUpdatesInput>;
  create?: InputMaybe<SchoolCreateWithoutSchoolUpdatesInput>;
  update?: InputMaybe<SchoolUpdateWithoutSchoolUpdatesInput>;
  upsert?: InputMaybe<SchoolUpsertWithoutSchoolUpdatesInput>;
};

export type SchoolUpdateOneRequiredWithoutStudentRecordsNestedInput = {
  connect?: InputMaybe<SchoolWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SchoolCreateOrConnectWithoutStudentRecordsInput>;
  create?: InputMaybe<SchoolCreateWithoutStudentRecordsInput>;
  update?: InputMaybe<SchoolUpdateWithoutStudentRecordsInput>;
  upsert?: InputMaybe<SchoolUpsertWithoutStudentRecordsInput>;
};

export type SchoolUpdateOneRequiredWithoutStudentsNestedInput = {
  connect?: InputMaybe<SchoolWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SchoolCreateOrConnectWithoutStudentsInput>;
  create?: InputMaybe<SchoolCreateWithoutStudentsInput>;
  update?: InputMaybe<SchoolUpdateWithoutStudentsInput>;
  upsert?: InputMaybe<SchoolUpsertWithoutStudentsInput>;
};

export type SchoolUpdateOneRequiredWithoutWorkshopsNestedInput = {
  connect?: InputMaybe<SchoolWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SchoolCreateOrConnectWithoutWorkshopsInput>;
  create?: InputMaybe<SchoolCreateWithoutWorkshopsInput>;
  update?: InputMaybe<SchoolUpdateWithoutWorkshopsInput>;
  upsert?: InputMaybe<SchoolUpsertWithoutWorkshopsInput>;
};

export type SchoolUpdateOneWithoutSkillsNestedInput = {
  connect?: InputMaybe<SchoolWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SchoolCreateOrConnectWithoutSkillsInput>;
  create?: InputMaybe<SchoolCreateWithoutSkillsInput>;
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  update?: InputMaybe<SchoolUpdateWithoutSkillsInput>;
  upsert?: InputMaybe<SchoolUpsertWithoutSkillsInput>;
};

export type SchoolUpdateOneWithoutStoriesNestedInput = {
  connect?: InputMaybe<SchoolWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SchoolCreateOrConnectWithoutStoriesInput>;
  create?: InputMaybe<SchoolCreateWithoutStoriesInput>;
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  update?: InputMaybe<SchoolUpdateWithoutStoriesInput>;
  upsert?: InputMaybe<SchoolUpsertWithoutStoriesInput>;
};

export type SchoolUpdateOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type SchoolUpdateOrderByWithRelationInput = {
  asset?: InputMaybe<SortOrderInput>;
  attachment?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  school?: InputMaybe<SchoolOrderByWithRelationInput>;
  schoolId?: InputMaybe<SortOrder>;
  standards?: InputMaybe<StandardOrderByRelationAggregateInput>;
  tags?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum SchoolUpdateScalarFieldEnum {
  ASSET = "asset",
  ATTACHMENT = "attachment",
  CREATEDAT = "createdAt",
  DESCRIPTION = "description",
  ID = "id",
  SCHOOLID = "schoolId",
  TAGS = "tags",
  TITLE = "title",
  UPDATEDAT = "updatedAt"
}

export type SchoolUpdateScalarWhereInput = {
  AND?: InputMaybe<Array<SchoolUpdateScalarWhereInput>>;
  NOT?: InputMaybe<Array<SchoolUpdateScalarWhereInput>>;
  OR?: InputMaybe<Array<SchoolUpdateScalarWhereInput>>;
  asset?: InputMaybe<StringNullableFilter>;
  attachment?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  schoolId?: InputMaybe<IntFilter>;
  tags?: InputMaybe<JsonFilter>;
  title?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type SchoolUpdateSumAggregate = {
  __typename?: "SchoolUpdateSumAggregate";
  id?: Maybe<Scalars["Int"]["output"]>;
  schoolId?: Maybe<Scalars["Int"]["output"]>;
};

export type SchoolUpdateUpdateInput = {
  asset?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  attachment?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  school?: InputMaybe<SchoolUpdateOneRequiredWithoutSchoolUpdatesNestedInput>;
  standards?: InputMaybe<StandardUpdateManyWithoutSchoolUpdatesNestedInput>;
  tags?: InputMaybe<Scalars["JSON"]["input"]>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SchoolUpdateUpdateManyMutationInput = {
  asset?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  attachment?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  tags?: InputMaybe<Scalars["JSON"]["input"]>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SchoolUpdateUpdateManyWithWhereWithoutSchoolInput = {
  data: SchoolUpdateUpdateManyMutationInput;
  where: SchoolUpdateScalarWhereInput;
};

export type SchoolUpdateUpdateManyWithWhereWithoutStandardsInput = {
  data: SchoolUpdateUpdateManyMutationInput;
  where: SchoolUpdateScalarWhereInput;
};

export type SchoolUpdateUpdateManyWithoutSchoolNestedInput = {
  connect?: InputMaybe<Array<SchoolUpdateWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SchoolUpdateCreateOrConnectWithoutSchoolInput>>;
  create?: InputMaybe<Array<SchoolUpdateCreateWithoutSchoolInput>>;
  createMany?: InputMaybe<SchoolUpdateCreateManySchoolInputEnvelope>;
  delete?: InputMaybe<Array<SchoolUpdateWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SchoolUpdateScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SchoolUpdateWhereUniqueInput>>;
  set?: InputMaybe<Array<SchoolUpdateWhereUniqueInput>>;
  update?: InputMaybe<Array<SchoolUpdateUpdateWithWhereUniqueWithoutSchoolInput>>;
  updateMany?: InputMaybe<Array<SchoolUpdateUpdateManyWithWhereWithoutSchoolInput>>;
  upsert?: InputMaybe<Array<SchoolUpdateUpsertWithWhereUniqueWithoutSchoolInput>>;
};

export type SchoolUpdateUpdateManyWithoutStandardsNestedInput = {
  connect?: InputMaybe<Array<SchoolUpdateWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SchoolUpdateCreateOrConnectWithoutStandardsInput>>;
  create?: InputMaybe<Array<SchoolUpdateCreateWithoutStandardsInput>>;
  delete?: InputMaybe<Array<SchoolUpdateWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SchoolUpdateScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SchoolUpdateWhereUniqueInput>>;
  set?: InputMaybe<Array<SchoolUpdateWhereUniqueInput>>;
  update?: InputMaybe<Array<SchoolUpdateUpdateWithWhereUniqueWithoutStandardsInput>>;
  updateMany?: InputMaybe<Array<SchoolUpdateUpdateManyWithWhereWithoutStandardsInput>>;
  upsert?: InputMaybe<Array<SchoolUpdateUpsertWithWhereUniqueWithoutStandardsInput>>;
};

export type SchoolUpdateUpdateWithWhereUniqueWithoutSchoolInput = {
  data: SchoolUpdateUpdateWithoutSchoolInput;
  where: SchoolUpdateWhereUniqueInput;
};

export type SchoolUpdateUpdateWithWhereUniqueWithoutStandardsInput = {
  data: SchoolUpdateUpdateWithoutStandardsInput;
  where: SchoolUpdateWhereUniqueInput;
};

export type SchoolUpdateUpdateWithoutSchoolInput = {
  asset?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  attachment?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  standards?: InputMaybe<StandardUpdateManyWithoutSchoolUpdatesNestedInput>;
  tags?: InputMaybe<Scalars["JSON"]["input"]>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SchoolUpdateUpdateWithoutStandardsInput = {
  asset?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  attachment?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  school?: InputMaybe<SchoolUpdateOneRequiredWithoutSchoolUpdatesNestedInput>;
  tags?: InputMaybe<Scalars["JSON"]["input"]>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SchoolUpdateUpsertWithWhereUniqueWithoutSchoolInput = {
  create: SchoolUpdateCreateWithoutSchoolInput;
  update: SchoolUpdateUpdateWithoutSchoolInput;
  where: SchoolUpdateWhereUniqueInput;
};

export type SchoolUpdateUpsertWithWhereUniqueWithoutStandardsInput = {
  create: SchoolUpdateCreateWithoutStandardsInput;
  update: SchoolUpdateUpdateWithoutStandardsInput;
  where: SchoolUpdateWhereUniqueInput;
};

export type SchoolUpdateWhereInput = {
  AND?: InputMaybe<Array<SchoolUpdateWhereInput>>;
  NOT?: InputMaybe<Array<SchoolUpdateWhereInput>>;
  OR?: InputMaybe<Array<SchoolUpdateWhereInput>>;
  asset?: InputMaybe<StringNullableFilter>;
  attachment?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  school?: InputMaybe<SchoolRelationFilter>;
  schoolId?: InputMaybe<IntFilter>;
  standards?: InputMaybe<StandardListRelationFilter>;
  tags?: InputMaybe<JsonFilter>;
  title?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type SchoolUpdateWhereUniqueInput = {
  id?: InputMaybe<Scalars["Int"]["input"]>;
};

export type SchoolUpdateWithWhereUniqueWithoutUsersInput = {
  data: SchoolUpdateWithoutUsersInput;
  where: SchoolWhereUniqueInput;
};

export type SchoolUpdateWithoutReportsInput = {
  area?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  code?: InputMaybe<StringFieldUpdateOperationsInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  group?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  samskaras?: InputMaybe<SamskaraUpdateManyWithoutSchoolNestedInput>;
  schoolUpdates?: InputMaybe<SchoolUpdateUpdateManyWithoutSchoolNestedInput>;
  skills?: InputMaybe<SkillUpdateManyWithoutSchoolNestedInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stories?: InputMaybe<StoryUpdateManyWithoutSchoolNestedInput>;
  studentRecords?: InputMaybe<StudentRecordUpdateManyWithoutSchoolNestedInput>;
  students?: InputMaybe<StudentUpdateManyWithoutSchoolNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  users?: InputMaybe<UserUpdateManyWithoutSchoolsNestedInput>;
  workshops?: InputMaybe<WorkshopUpdateManyWithoutSchoolNestedInput>;
};

export type SchoolUpdateWithoutSamskarasInput = {
  area?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  code?: InputMaybe<StringFieldUpdateOperationsInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  group?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  reports?: InputMaybe<ReportUpdateManyWithoutSchoolNestedInput>;
  schoolUpdates?: InputMaybe<SchoolUpdateUpdateManyWithoutSchoolNestedInput>;
  skills?: InputMaybe<SkillUpdateManyWithoutSchoolNestedInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stories?: InputMaybe<StoryUpdateManyWithoutSchoolNestedInput>;
  studentRecords?: InputMaybe<StudentRecordUpdateManyWithoutSchoolNestedInput>;
  students?: InputMaybe<StudentUpdateManyWithoutSchoolNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  users?: InputMaybe<UserUpdateManyWithoutSchoolsNestedInput>;
  workshops?: InputMaybe<WorkshopUpdateManyWithoutSchoolNestedInput>;
};

export type SchoolUpdateWithoutSchoolUpdatesInput = {
  area?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  code?: InputMaybe<StringFieldUpdateOperationsInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  group?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  reports?: InputMaybe<ReportUpdateManyWithoutSchoolNestedInput>;
  samskaras?: InputMaybe<SamskaraUpdateManyWithoutSchoolNestedInput>;
  skills?: InputMaybe<SkillUpdateManyWithoutSchoolNestedInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stories?: InputMaybe<StoryUpdateManyWithoutSchoolNestedInput>;
  studentRecords?: InputMaybe<StudentRecordUpdateManyWithoutSchoolNestedInput>;
  students?: InputMaybe<StudentUpdateManyWithoutSchoolNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  users?: InputMaybe<UserUpdateManyWithoutSchoolsNestedInput>;
  workshops?: InputMaybe<WorkshopUpdateManyWithoutSchoolNestedInput>;
};

export type SchoolUpdateWithoutSkillsInput = {
  area?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  code?: InputMaybe<StringFieldUpdateOperationsInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  group?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  reports?: InputMaybe<ReportUpdateManyWithoutSchoolNestedInput>;
  samskaras?: InputMaybe<SamskaraUpdateManyWithoutSchoolNestedInput>;
  schoolUpdates?: InputMaybe<SchoolUpdateUpdateManyWithoutSchoolNestedInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stories?: InputMaybe<StoryUpdateManyWithoutSchoolNestedInput>;
  studentRecords?: InputMaybe<StudentRecordUpdateManyWithoutSchoolNestedInput>;
  students?: InputMaybe<StudentUpdateManyWithoutSchoolNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  users?: InputMaybe<UserUpdateManyWithoutSchoolsNestedInput>;
  workshops?: InputMaybe<WorkshopUpdateManyWithoutSchoolNestedInput>;
};

export type SchoolUpdateWithoutStoriesInput = {
  area?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  code?: InputMaybe<StringFieldUpdateOperationsInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  group?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  reports?: InputMaybe<ReportUpdateManyWithoutSchoolNestedInput>;
  samskaras?: InputMaybe<SamskaraUpdateManyWithoutSchoolNestedInput>;
  schoolUpdates?: InputMaybe<SchoolUpdateUpdateManyWithoutSchoolNestedInput>;
  skills?: InputMaybe<SkillUpdateManyWithoutSchoolNestedInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  studentRecords?: InputMaybe<StudentRecordUpdateManyWithoutSchoolNestedInput>;
  students?: InputMaybe<StudentUpdateManyWithoutSchoolNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  users?: InputMaybe<UserUpdateManyWithoutSchoolsNestedInput>;
  workshops?: InputMaybe<WorkshopUpdateManyWithoutSchoolNestedInput>;
};

export type SchoolUpdateWithoutStudentRecordsInput = {
  area?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  code?: InputMaybe<StringFieldUpdateOperationsInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  group?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  reports?: InputMaybe<ReportUpdateManyWithoutSchoolNestedInput>;
  samskaras?: InputMaybe<SamskaraUpdateManyWithoutSchoolNestedInput>;
  schoolUpdates?: InputMaybe<SchoolUpdateUpdateManyWithoutSchoolNestedInput>;
  skills?: InputMaybe<SkillUpdateManyWithoutSchoolNestedInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stories?: InputMaybe<StoryUpdateManyWithoutSchoolNestedInput>;
  students?: InputMaybe<StudentUpdateManyWithoutSchoolNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  users?: InputMaybe<UserUpdateManyWithoutSchoolsNestedInput>;
  workshops?: InputMaybe<WorkshopUpdateManyWithoutSchoolNestedInput>;
};

export type SchoolUpdateWithoutStudentsInput = {
  area?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  code?: InputMaybe<StringFieldUpdateOperationsInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  group?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  reports?: InputMaybe<ReportUpdateManyWithoutSchoolNestedInput>;
  samskaras?: InputMaybe<SamskaraUpdateManyWithoutSchoolNestedInput>;
  schoolUpdates?: InputMaybe<SchoolUpdateUpdateManyWithoutSchoolNestedInput>;
  skills?: InputMaybe<SkillUpdateManyWithoutSchoolNestedInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stories?: InputMaybe<StoryUpdateManyWithoutSchoolNestedInput>;
  studentRecords?: InputMaybe<StudentRecordUpdateManyWithoutSchoolNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  users?: InputMaybe<UserUpdateManyWithoutSchoolsNestedInput>;
  workshops?: InputMaybe<WorkshopUpdateManyWithoutSchoolNestedInput>;
};

export type SchoolUpdateWithoutUsersInput = {
  area?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  code?: InputMaybe<StringFieldUpdateOperationsInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  group?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  reports?: InputMaybe<ReportUpdateManyWithoutSchoolNestedInput>;
  samskaras?: InputMaybe<SamskaraUpdateManyWithoutSchoolNestedInput>;
  schoolUpdates?: InputMaybe<SchoolUpdateUpdateManyWithoutSchoolNestedInput>;
  skills?: InputMaybe<SkillUpdateManyWithoutSchoolNestedInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stories?: InputMaybe<StoryUpdateManyWithoutSchoolNestedInput>;
  studentRecords?: InputMaybe<StudentRecordUpdateManyWithoutSchoolNestedInput>;
  students?: InputMaybe<StudentUpdateManyWithoutSchoolNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  workshops?: InputMaybe<WorkshopUpdateManyWithoutSchoolNestedInput>;
};

export type SchoolUpdateWithoutWorkshopsInput = {
  area?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  code?: InputMaybe<StringFieldUpdateOperationsInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  group?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  reports?: InputMaybe<ReportUpdateManyWithoutSchoolNestedInput>;
  samskaras?: InputMaybe<SamskaraUpdateManyWithoutSchoolNestedInput>;
  schoolUpdates?: InputMaybe<SchoolUpdateUpdateManyWithoutSchoolNestedInput>;
  skills?: InputMaybe<SkillUpdateManyWithoutSchoolNestedInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stories?: InputMaybe<StoryUpdateManyWithoutSchoolNestedInput>;
  studentRecords?: InputMaybe<StudentRecordUpdateManyWithoutSchoolNestedInput>;
  students?: InputMaybe<StudentUpdateManyWithoutSchoolNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  users?: InputMaybe<UserUpdateManyWithoutSchoolsNestedInput>;
};

export type SchoolUpsertWithWhereUniqueWithoutUsersInput = {
  create: SchoolCreateWithoutUsersInput;
  update: SchoolUpdateWithoutUsersInput;
  where: SchoolWhereUniqueInput;
};

export type SchoolUpsertWithoutReportsInput = {
  create: SchoolCreateWithoutReportsInput;
  update: SchoolUpdateWithoutReportsInput;
};

export type SchoolUpsertWithoutSamskarasInput = {
  create: SchoolCreateWithoutSamskarasInput;
  update: SchoolUpdateWithoutSamskarasInput;
};

export type SchoolUpsertWithoutSchoolUpdatesInput = {
  create: SchoolCreateWithoutSchoolUpdatesInput;
  update: SchoolUpdateWithoutSchoolUpdatesInput;
};

export type SchoolUpsertWithoutSkillsInput = {
  create: SchoolCreateWithoutSkillsInput;
  update: SchoolUpdateWithoutSkillsInput;
};

export type SchoolUpsertWithoutStoriesInput = {
  create: SchoolCreateWithoutStoriesInput;
  update: SchoolUpdateWithoutStoriesInput;
};

export type SchoolUpsertWithoutStudentRecordsInput = {
  create: SchoolCreateWithoutStudentRecordsInput;
  update: SchoolUpdateWithoutStudentRecordsInput;
};

export type SchoolUpsertWithoutStudentsInput = {
  create: SchoolCreateWithoutStudentsInput;
  update: SchoolUpdateWithoutStudentsInput;
};

export type SchoolUpsertWithoutWorkshopsInput = {
  create: SchoolCreateWithoutWorkshopsInput;
  update: SchoolUpdateWithoutWorkshopsInput;
};

export type SchoolWhereInput = {
  AND?: InputMaybe<Array<SchoolWhereInput>>;
  NOT?: InputMaybe<Array<SchoolWhereInput>>;
  OR?: InputMaybe<Array<SchoolWhereInput>>;
  area?: InputMaybe<StringNullableFilter>;
  city?: InputMaybe<StringNullableFilter>;
  code?: InputMaybe<StringFilter>;
  country?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  group?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  reports?: InputMaybe<ReportListRelationFilter>;
  samskaras?: InputMaybe<SamskaraListRelationFilter>;
  schoolUpdates?: InputMaybe<SchoolUpdateListRelationFilter>;
  skills?: InputMaybe<SkillListRelationFilter>;
  state?: InputMaybe<StringNullableFilter>;
  stories?: InputMaybe<StoryListRelationFilter>;
  studentRecords?: InputMaybe<StudentRecordListRelationFilter>;
  students?: InputMaybe<StudentListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  users?: InputMaybe<UserListRelationFilter>;
  workshops?: InputMaybe<WorkshopListRelationFilter>;
};

export type SchoolWhereUniqueInput = {
  code?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
};

export type SearchActivity = {
  __typename?: "SearchActivity";
  id: Scalars["Int"]["output"];
  modelId: Scalars["Int"]["output"];
  title: Scalars["String"]["output"];
  type: ActivityType;
};

export type SignUpInput = {
  country: Scalars["String"]["input"];
  email?: InputMaybe<Scalars["String"]["input"]>;
  firstName: Scalars["String"]["input"];
  idToken: Scalars["String"]["input"];
  isHomeschooler?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastName: Scalars["String"]["input"];
  schoolText?: InputMaybe<Scalars["String"]["input"]>;
  signUpCode?: InputMaybe<Scalars["String"]["input"]>;
  standard: StandardWhereUniqueInput;
};

export type Sitemap = {
  __typename?: "Sitemap";
  lastmod?: Maybe<Scalars["DateTime"]["output"]>;
  loc: Scalars["String"]["output"];
};

export type Skill = {
  __typename?: "Skill";
  _count?: Maybe<SkillCount>;
  activities: Array<Activity>;
  bundle?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  description?: Maybe<Scalars["String"]["output"]>;
  displayOrder: Scalars["Int"]["output"];
  featuredImage?: Maybe<Scalars["String"]["output"]>;
  gamePointType?: Maybe<GamePointType>;
  id: Scalars["Int"]["output"];
  image?: Maybe<Scalars["String"]["output"]>;
  isActive: Scalars["Boolean"]["output"];
  meta?: Maybe<Scalars["JSON"]["output"]>;
  metaTagData?: Maybe<Scalars["JSON"]["output"]>;
  name: Scalars["String"]["output"];
  products: Array<Product>;
  school?: Maybe<School>;
  schoolId?: Maybe<Scalars["Int"]["output"]>;
  slug: Scalars["String"]["output"];
  standards: Array<Standard>;
  storyActivities: Array<StoryActivity>;
  type: SkillType;
  updatedAt: Scalars["DateTime"]["output"];
  weightage: Scalars["Float"]["output"];
  workshops: Array<Workshop>;
};


export type SkillActivitiesArgs = {
  cursor?: InputMaybe<ActivityWhereUniqueInput>;
  distinct?: InputMaybe<Array<ActivityScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ActivityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ActivityWhereInput>;
};


export type SkillProductsArgs = {
  cursor?: InputMaybe<ProductWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProductScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProductOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ProductWhereInput>;
};


export type SkillStandardsArgs = {
  cursor?: InputMaybe<StandardWhereUniqueInput>;
  distinct?: InputMaybe<Array<StandardScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StandardOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<StandardWhereInput>;
};


export type SkillStoryActivitiesArgs = {
  cursor?: InputMaybe<StoryActivityWhereUniqueInput>;
  distinct?: InputMaybe<Array<StoryActivityScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StoryActivityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<StoryActivityWhereInput>;
};


export type SkillWorkshopsArgs = {
  cursor?: InputMaybe<WorkshopWhereUniqueInput>;
  distinct?: InputMaybe<Array<WorkshopScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<WorkshopOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<WorkshopWhereInput>;
};

export type SkillAvgAggregate = {
  __typename?: "SkillAvgAggregate";
  displayOrder?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  schoolId?: Maybe<Scalars["Float"]["output"]>;
  weightage?: Maybe<Scalars["Float"]["output"]>;
};

export type SkillCount = {
  __typename?: "SkillCount";
  activities: Scalars["Int"]["output"];
  products: Scalars["Int"]["output"];
  standards: Scalars["Int"]["output"];
  storyActivities: Scalars["Int"]["output"];
  workshops: Scalars["Int"]["output"];
};


export type SkillCountActivitiesArgs = {
  where?: InputMaybe<ActivityWhereInput>;
};


export type SkillCountProductsArgs = {
  where?: InputMaybe<ProductWhereInput>;
};


export type SkillCountStandardsArgs = {
  where?: InputMaybe<StandardWhereInput>;
};


export type SkillCountStoryActivitiesArgs = {
  where?: InputMaybe<StoryActivityWhereInput>;
};


export type SkillCountWorkshopsArgs = {
  where?: InputMaybe<WorkshopWhereInput>;
};

export type SkillCountAggregate = {
  __typename?: "SkillCountAggregate";
  _all: Scalars["Int"]["output"];
  bundle: Scalars["Int"]["output"];
  createdAt: Scalars["Int"]["output"];
  description: Scalars["Int"]["output"];
  displayOrder: Scalars["Int"]["output"];
  featuredImage: Scalars["Int"]["output"];
  id: Scalars["Int"]["output"];
  image: Scalars["Int"]["output"];
  isActive: Scalars["Int"]["output"];
  meta: Scalars["Int"]["output"];
  metaTagData: Scalars["Int"]["output"];
  name: Scalars["Int"]["output"];
  schoolId: Scalars["Int"]["output"];
  slug: Scalars["Int"]["output"];
  type: Scalars["Int"]["output"];
  updatedAt: Scalars["Int"]["output"];
  weightage: Scalars["Int"]["output"];
};

export type SkillCreateInput = {
  activities?: InputMaybe<ActivityCreateNestedManyWithoutSkillInput>;
  bundle?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  displayOrder?: InputMaybe<Scalars["Int"]["input"]>;
  featuredImage?: InputMaybe<Scalars["String"]["input"]>;
  gamePointType?: InputMaybe<GamePointTypeCreateNestedOneWithoutSkillInput>;
  image?: InputMaybe<Scalars["String"]["input"]>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  metaTagData?: InputMaybe<Scalars["JSON"]["input"]>;
  name: Scalars["String"]["input"];
  products?: InputMaybe<ProductCreateNestedManyWithoutSkillInput>;
  school?: InputMaybe<SchoolCreateNestedOneWithoutSkillsInput>;
  slug: Scalars["String"]["input"];
  standards?: InputMaybe<StandardCreateNestedManyWithoutSkillsInput>;
  storyActivities?: InputMaybe<StoryActivityCreateNestedManyWithoutSkillInput>;
  type: SkillType;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  weightage?: InputMaybe<Scalars["Float"]["input"]>;
  workshops?: InputMaybe<WorkshopCreateNestedManyWithoutSkillInput>;
};

export type SkillCreateManySchoolInput = {
  bundle?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  displayOrder?: InputMaybe<Scalars["Int"]["input"]>;
  featuredImage?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  image?: InputMaybe<Scalars["String"]["input"]>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  metaTagData?: InputMaybe<Scalars["JSON"]["input"]>;
  name: Scalars["String"]["input"];
  slug: Scalars["String"]["input"];
  type: SkillType;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  weightage?: InputMaybe<Scalars["Float"]["input"]>;
};

export type SkillCreateManySchoolInputEnvelope = {
  data: Array<SkillCreateManySchoolInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type SkillCreateNestedManyWithoutSchoolInput = {
  connect?: InputMaybe<Array<SkillWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SkillCreateOrConnectWithoutSchoolInput>>;
  create?: InputMaybe<Array<SkillCreateWithoutSchoolInput>>;
  createMany?: InputMaybe<SkillCreateManySchoolInputEnvelope>;
};

export type SkillCreateNestedManyWithoutStandardsInput = {
  connect?: InputMaybe<Array<SkillWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SkillCreateOrConnectWithoutStandardsInput>>;
  create?: InputMaybe<Array<SkillCreateWithoutStandardsInput>>;
};

export type SkillCreateNestedOneWithoutActivitiesInput = {
  connect?: InputMaybe<SkillWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SkillCreateOrConnectWithoutActivitiesInput>;
  create?: InputMaybe<SkillCreateWithoutActivitiesInput>;
};

export type SkillCreateNestedOneWithoutGamePointTypeInput = {
  connect?: InputMaybe<SkillWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SkillCreateOrConnectWithoutGamePointTypeInput>;
  create?: InputMaybe<SkillCreateWithoutGamePointTypeInput>;
};

export type SkillCreateNestedOneWithoutProductsInput = {
  connect?: InputMaybe<SkillWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SkillCreateOrConnectWithoutProductsInput>;
  create?: InputMaybe<SkillCreateWithoutProductsInput>;
};

export type SkillCreateNestedOneWithoutStoryActivitiesInput = {
  connect?: InputMaybe<SkillWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SkillCreateOrConnectWithoutStoryActivitiesInput>;
  create?: InputMaybe<SkillCreateWithoutStoryActivitiesInput>;
};

export type SkillCreateNestedOneWithoutWorkshopsInput = {
  connect?: InputMaybe<SkillWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SkillCreateOrConnectWithoutWorkshopsInput>;
  create?: InputMaybe<SkillCreateWithoutWorkshopsInput>;
};

export type SkillCreateOrConnectWithoutActivitiesInput = {
  create: SkillCreateWithoutActivitiesInput;
  where: SkillWhereUniqueInput;
};

export type SkillCreateOrConnectWithoutGamePointTypeInput = {
  create: SkillCreateWithoutGamePointTypeInput;
  where: SkillWhereUniqueInput;
};

export type SkillCreateOrConnectWithoutProductsInput = {
  create: SkillCreateWithoutProductsInput;
  where: SkillWhereUniqueInput;
};

export type SkillCreateOrConnectWithoutSchoolInput = {
  create: SkillCreateWithoutSchoolInput;
  where: SkillWhereUniqueInput;
};

export type SkillCreateOrConnectWithoutStandardsInput = {
  create: SkillCreateWithoutStandardsInput;
  where: SkillWhereUniqueInput;
};

export type SkillCreateOrConnectWithoutStoryActivitiesInput = {
  create: SkillCreateWithoutStoryActivitiesInput;
  where: SkillWhereUniqueInput;
};

export type SkillCreateOrConnectWithoutWorkshopsInput = {
  create: SkillCreateWithoutWorkshopsInput;
  where: SkillWhereUniqueInput;
};

export type SkillCreateWithoutActivitiesInput = {
  bundle?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  displayOrder?: InputMaybe<Scalars["Int"]["input"]>;
  featuredImage?: InputMaybe<Scalars["String"]["input"]>;
  gamePointType?: InputMaybe<GamePointTypeCreateNestedOneWithoutSkillInput>;
  image?: InputMaybe<Scalars["String"]["input"]>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  metaTagData?: InputMaybe<Scalars["JSON"]["input"]>;
  name: Scalars["String"]["input"];
  products?: InputMaybe<ProductCreateNestedManyWithoutSkillInput>;
  school?: InputMaybe<SchoolCreateNestedOneWithoutSkillsInput>;
  slug: Scalars["String"]["input"];
  standards?: InputMaybe<StandardCreateNestedManyWithoutSkillsInput>;
  storyActivities?: InputMaybe<StoryActivityCreateNestedManyWithoutSkillInput>;
  type: SkillType;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  weightage?: InputMaybe<Scalars["Float"]["input"]>;
  workshops?: InputMaybe<WorkshopCreateNestedManyWithoutSkillInput>;
};

export type SkillCreateWithoutGamePointTypeInput = {
  activities?: InputMaybe<ActivityCreateNestedManyWithoutSkillInput>;
  bundle?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  displayOrder?: InputMaybe<Scalars["Int"]["input"]>;
  featuredImage?: InputMaybe<Scalars["String"]["input"]>;
  image?: InputMaybe<Scalars["String"]["input"]>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  metaTagData?: InputMaybe<Scalars["JSON"]["input"]>;
  name: Scalars["String"]["input"];
  products?: InputMaybe<ProductCreateNestedManyWithoutSkillInput>;
  school?: InputMaybe<SchoolCreateNestedOneWithoutSkillsInput>;
  slug: Scalars["String"]["input"];
  standards?: InputMaybe<StandardCreateNestedManyWithoutSkillsInput>;
  storyActivities?: InputMaybe<StoryActivityCreateNestedManyWithoutSkillInput>;
  type: SkillType;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  weightage?: InputMaybe<Scalars["Float"]["input"]>;
  workshops?: InputMaybe<WorkshopCreateNestedManyWithoutSkillInput>;
};

export type SkillCreateWithoutProductsInput = {
  activities?: InputMaybe<ActivityCreateNestedManyWithoutSkillInput>;
  bundle?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  displayOrder?: InputMaybe<Scalars["Int"]["input"]>;
  featuredImage?: InputMaybe<Scalars["String"]["input"]>;
  gamePointType?: InputMaybe<GamePointTypeCreateNestedOneWithoutSkillInput>;
  image?: InputMaybe<Scalars["String"]["input"]>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  metaTagData?: InputMaybe<Scalars["JSON"]["input"]>;
  name: Scalars["String"]["input"];
  school?: InputMaybe<SchoolCreateNestedOneWithoutSkillsInput>;
  slug: Scalars["String"]["input"];
  standards?: InputMaybe<StandardCreateNestedManyWithoutSkillsInput>;
  storyActivities?: InputMaybe<StoryActivityCreateNestedManyWithoutSkillInput>;
  type: SkillType;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  weightage?: InputMaybe<Scalars["Float"]["input"]>;
  workshops?: InputMaybe<WorkshopCreateNestedManyWithoutSkillInput>;
};

export type SkillCreateWithoutSchoolInput = {
  activities?: InputMaybe<ActivityCreateNestedManyWithoutSkillInput>;
  bundle?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  displayOrder?: InputMaybe<Scalars["Int"]["input"]>;
  featuredImage?: InputMaybe<Scalars["String"]["input"]>;
  gamePointType?: InputMaybe<GamePointTypeCreateNestedOneWithoutSkillInput>;
  image?: InputMaybe<Scalars["String"]["input"]>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  metaTagData?: InputMaybe<Scalars["JSON"]["input"]>;
  name: Scalars["String"]["input"];
  products?: InputMaybe<ProductCreateNestedManyWithoutSkillInput>;
  slug: Scalars["String"]["input"];
  standards?: InputMaybe<StandardCreateNestedManyWithoutSkillsInput>;
  storyActivities?: InputMaybe<StoryActivityCreateNestedManyWithoutSkillInput>;
  type: SkillType;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  weightage?: InputMaybe<Scalars["Float"]["input"]>;
  workshops?: InputMaybe<WorkshopCreateNestedManyWithoutSkillInput>;
};

export type SkillCreateWithoutStandardsInput = {
  activities?: InputMaybe<ActivityCreateNestedManyWithoutSkillInput>;
  bundle?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  displayOrder?: InputMaybe<Scalars["Int"]["input"]>;
  featuredImage?: InputMaybe<Scalars["String"]["input"]>;
  gamePointType?: InputMaybe<GamePointTypeCreateNestedOneWithoutSkillInput>;
  image?: InputMaybe<Scalars["String"]["input"]>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  metaTagData?: InputMaybe<Scalars["JSON"]["input"]>;
  name: Scalars["String"]["input"];
  products?: InputMaybe<ProductCreateNestedManyWithoutSkillInput>;
  school?: InputMaybe<SchoolCreateNestedOneWithoutSkillsInput>;
  slug: Scalars["String"]["input"];
  storyActivities?: InputMaybe<StoryActivityCreateNestedManyWithoutSkillInput>;
  type: SkillType;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  weightage?: InputMaybe<Scalars["Float"]["input"]>;
  workshops?: InputMaybe<WorkshopCreateNestedManyWithoutSkillInput>;
};

export type SkillCreateWithoutStoryActivitiesInput = {
  activities?: InputMaybe<ActivityCreateNestedManyWithoutSkillInput>;
  bundle?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  displayOrder?: InputMaybe<Scalars["Int"]["input"]>;
  featuredImage?: InputMaybe<Scalars["String"]["input"]>;
  gamePointType?: InputMaybe<GamePointTypeCreateNestedOneWithoutSkillInput>;
  image?: InputMaybe<Scalars["String"]["input"]>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  metaTagData?: InputMaybe<Scalars["JSON"]["input"]>;
  name: Scalars["String"]["input"];
  products?: InputMaybe<ProductCreateNestedManyWithoutSkillInput>;
  school?: InputMaybe<SchoolCreateNestedOneWithoutSkillsInput>;
  slug: Scalars["String"]["input"];
  standards?: InputMaybe<StandardCreateNestedManyWithoutSkillsInput>;
  type: SkillType;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  weightage?: InputMaybe<Scalars["Float"]["input"]>;
  workshops?: InputMaybe<WorkshopCreateNestedManyWithoutSkillInput>;
};

export type SkillCreateWithoutWorkshopsInput = {
  activities?: InputMaybe<ActivityCreateNestedManyWithoutSkillInput>;
  bundle?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  displayOrder?: InputMaybe<Scalars["Int"]["input"]>;
  featuredImage?: InputMaybe<Scalars["String"]["input"]>;
  gamePointType?: InputMaybe<GamePointTypeCreateNestedOneWithoutSkillInput>;
  image?: InputMaybe<Scalars["String"]["input"]>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  metaTagData?: InputMaybe<Scalars["JSON"]["input"]>;
  name: Scalars["String"]["input"];
  products?: InputMaybe<ProductCreateNestedManyWithoutSkillInput>;
  school?: InputMaybe<SchoolCreateNestedOneWithoutSkillsInput>;
  slug: Scalars["String"]["input"];
  standards?: InputMaybe<StandardCreateNestedManyWithoutSkillsInput>;
  storyActivities?: InputMaybe<StoryActivityCreateNestedManyWithoutSkillInput>;
  type: SkillType;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  weightage?: InputMaybe<Scalars["Float"]["input"]>;
};

export type SkillListRelationFilter = {
  every?: InputMaybe<SkillWhereInput>;
  none?: InputMaybe<SkillWhereInput>;
  some?: InputMaybe<SkillWhereInput>;
};

export type SkillMaxAggregate = {
  __typename?: "SkillMaxAggregate";
  bundle?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  displayOrder?: Maybe<Scalars["Int"]["output"]>;
  featuredImage?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  image?: Maybe<Scalars["String"]["output"]>;
  isActive?: Maybe<Scalars["Boolean"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  schoolId?: Maybe<Scalars["Int"]["output"]>;
  slug?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<SkillType>;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
  weightage?: Maybe<Scalars["Float"]["output"]>;
};

export type SkillMinAggregate = {
  __typename?: "SkillMinAggregate";
  bundle?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  displayOrder?: Maybe<Scalars["Int"]["output"]>;
  featuredImage?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  image?: Maybe<Scalars["String"]["output"]>;
  isActive?: Maybe<Scalars["Boolean"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  schoolId?: Maybe<Scalars["Int"]["output"]>;
  slug?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<SkillType>;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
  weightage?: Maybe<Scalars["Float"]["output"]>;
};

export type SkillOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type SkillOrderByWithRelationInput = {
  activities?: InputMaybe<ActivityOrderByRelationAggregateInput>;
  bundle?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrderInput>;
  displayOrder?: InputMaybe<SortOrder>;
  featuredImage?: InputMaybe<SortOrderInput>;
  gamePointType?: InputMaybe<GamePointTypeOrderByWithRelationInput>;
  id?: InputMaybe<SortOrder>;
  image?: InputMaybe<SortOrderInput>;
  isActive?: InputMaybe<SortOrder>;
  meta?: InputMaybe<SortOrderInput>;
  metaTagData?: InputMaybe<SortOrderInput>;
  name?: InputMaybe<SortOrder>;
  products?: InputMaybe<ProductOrderByRelationAggregateInput>;
  school?: InputMaybe<SchoolOrderByWithRelationInput>;
  schoolId?: InputMaybe<SortOrderInput>;
  slug?: InputMaybe<SortOrder>;
  standards?: InputMaybe<StandardOrderByRelationAggregateInput>;
  storyActivities?: InputMaybe<StoryActivityOrderByRelationAggregateInput>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  weightage?: InputMaybe<SortOrder>;
  workshops?: InputMaybe<WorkshopOrderByRelationAggregateInput>;
};

export type SkillRelationFilter = {
  is?: InputMaybe<SkillWhereInput>;
  isNot?: InputMaybe<SkillWhereInput>;
};

export enum SkillScalarFieldEnum {
  BUNDLE = "bundle",
  CREATEDAT = "createdAt",
  DESCRIPTION = "description",
  DISPLAYORDER = "displayOrder",
  FEATUREDIMAGE = "featuredImage",
  ID = "id",
  IMAGE = "image",
  ISACTIVE = "isActive",
  META = "meta",
  METATAGDATA = "metaTagData",
  NAME = "name",
  SCHOOLID = "schoolId",
  SLUG = "slug",
  TYPE = "type",
  UPDATEDAT = "updatedAt",
  WEIGHTAGE = "weightage"
}

export type SkillScalarWhereInput = {
  AND?: InputMaybe<Array<SkillScalarWhereInput>>;
  NOT?: InputMaybe<Array<SkillScalarWhereInput>>;
  OR?: InputMaybe<Array<SkillScalarWhereInput>>;
  bundle?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  displayOrder?: InputMaybe<IntFilter>;
  featuredImage?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  image?: InputMaybe<StringNullableFilter>;
  isActive?: InputMaybe<BoolFilter>;
  meta?: InputMaybe<JsonNullableFilter>;
  metaTagData?: InputMaybe<JsonNullableFilter>;
  name?: InputMaybe<StringFilter>;
  schoolId?: InputMaybe<IntNullableFilter>;
  slug?: InputMaybe<StringFilter>;
  type?: InputMaybe<EnumSkillTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  weightage?: InputMaybe<FloatFilter>;
};

export type SkillSumAggregate = {
  __typename?: "SkillSumAggregate";
  displayOrder?: Maybe<Scalars["Int"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  schoolId?: Maybe<Scalars["Int"]["output"]>;
  weightage?: Maybe<Scalars["Float"]["output"]>;
};

export enum SkillType {
  APPLIED_ACADEMICS = "APPLIED_ACADEMICS",
  MAKER = "MAKER",
  SOFT = "SOFT"
}

export type SkillUpdateInput = {
  activities?: InputMaybe<ActivityUpdateManyWithoutSkillNestedInput>;
  bundle?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  displayOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  gamePointType?: InputMaybe<GamePointTypeUpdateOneWithoutSkillNestedInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  metaTagData?: InputMaybe<Scalars["JSON"]["input"]>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  products?: InputMaybe<ProductUpdateManyWithoutSkillNestedInput>;
  school?: InputMaybe<SchoolUpdateOneWithoutSkillsNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  standards?: InputMaybe<StandardUpdateManyWithoutSkillsNestedInput>;
  storyActivities?: InputMaybe<StoryActivityUpdateManyWithoutSkillNestedInput>;
  type?: InputMaybe<EnumSkillTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  weightage?: InputMaybe<FloatFieldUpdateOperationsInput>;
  workshops?: InputMaybe<WorkshopUpdateManyWithoutSkillNestedInput>;
};

export type SkillUpdateManyMutationInput = {
  bundle?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  displayOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  metaTagData?: InputMaybe<Scalars["JSON"]["input"]>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumSkillTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  weightage?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type SkillUpdateManyWithWhereWithoutSchoolInput = {
  data: SkillUpdateManyMutationInput;
  where: SkillScalarWhereInput;
};

export type SkillUpdateManyWithWhereWithoutStandardsInput = {
  data: SkillUpdateManyMutationInput;
  where: SkillScalarWhereInput;
};

export type SkillUpdateManyWithoutSchoolNestedInput = {
  connect?: InputMaybe<Array<SkillWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SkillCreateOrConnectWithoutSchoolInput>>;
  create?: InputMaybe<Array<SkillCreateWithoutSchoolInput>>;
  createMany?: InputMaybe<SkillCreateManySchoolInputEnvelope>;
  delete?: InputMaybe<Array<SkillWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SkillScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SkillWhereUniqueInput>>;
  set?: InputMaybe<Array<SkillWhereUniqueInput>>;
  update?: InputMaybe<Array<SkillUpdateWithWhereUniqueWithoutSchoolInput>>;
  updateMany?: InputMaybe<Array<SkillUpdateManyWithWhereWithoutSchoolInput>>;
  upsert?: InputMaybe<Array<SkillUpsertWithWhereUniqueWithoutSchoolInput>>;
};

export type SkillUpdateManyWithoutStandardsNestedInput = {
  connect?: InputMaybe<Array<SkillWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SkillCreateOrConnectWithoutStandardsInput>>;
  create?: InputMaybe<Array<SkillCreateWithoutStandardsInput>>;
  delete?: InputMaybe<Array<SkillWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SkillScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SkillWhereUniqueInput>>;
  set?: InputMaybe<Array<SkillWhereUniqueInput>>;
  update?: InputMaybe<Array<SkillUpdateWithWhereUniqueWithoutStandardsInput>>;
  updateMany?: InputMaybe<Array<SkillUpdateManyWithWhereWithoutStandardsInput>>;
  upsert?: InputMaybe<Array<SkillUpsertWithWhereUniqueWithoutStandardsInput>>;
};

export type SkillUpdateOneRequiredWithoutStoryActivitiesNestedInput = {
  connect?: InputMaybe<SkillWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SkillCreateOrConnectWithoutStoryActivitiesInput>;
  create?: InputMaybe<SkillCreateWithoutStoryActivitiesInput>;
  update?: InputMaybe<SkillUpdateWithoutStoryActivitiesInput>;
  upsert?: InputMaybe<SkillUpsertWithoutStoryActivitiesInput>;
};

export type SkillUpdateOneRequiredWithoutWorkshopsNestedInput = {
  connect?: InputMaybe<SkillWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SkillCreateOrConnectWithoutWorkshopsInput>;
  create?: InputMaybe<SkillCreateWithoutWorkshopsInput>;
  update?: InputMaybe<SkillUpdateWithoutWorkshopsInput>;
  upsert?: InputMaybe<SkillUpsertWithoutWorkshopsInput>;
};

export type SkillUpdateOneWithoutActivitiesNestedInput = {
  connect?: InputMaybe<SkillWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SkillCreateOrConnectWithoutActivitiesInput>;
  create?: InputMaybe<SkillCreateWithoutActivitiesInput>;
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  update?: InputMaybe<SkillUpdateWithoutActivitiesInput>;
  upsert?: InputMaybe<SkillUpsertWithoutActivitiesInput>;
};

export type SkillUpdateOneWithoutGamePointTypeNestedInput = {
  connect?: InputMaybe<SkillWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SkillCreateOrConnectWithoutGamePointTypeInput>;
  create?: InputMaybe<SkillCreateWithoutGamePointTypeInput>;
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  update?: InputMaybe<SkillUpdateWithoutGamePointTypeInput>;
  upsert?: InputMaybe<SkillUpsertWithoutGamePointTypeInput>;
};

export type SkillUpdateOneWithoutProductsNestedInput = {
  connect?: InputMaybe<SkillWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SkillCreateOrConnectWithoutProductsInput>;
  create?: InputMaybe<SkillCreateWithoutProductsInput>;
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  update?: InputMaybe<SkillUpdateWithoutProductsInput>;
  upsert?: InputMaybe<SkillUpsertWithoutProductsInput>;
};

export type SkillUpdateWithWhereUniqueWithoutSchoolInput = {
  data: SkillUpdateWithoutSchoolInput;
  where: SkillWhereUniqueInput;
};

export type SkillUpdateWithWhereUniqueWithoutStandardsInput = {
  data: SkillUpdateWithoutStandardsInput;
  where: SkillWhereUniqueInput;
};

export type SkillUpdateWithoutActivitiesInput = {
  bundle?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  displayOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  gamePointType?: InputMaybe<GamePointTypeUpdateOneWithoutSkillNestedInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  metaTagData?: InputMaybe<Scalars["JSON"]["input"]>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  products?: InputMaybe<ProductUpdateManyWithoutSkillNestedInput>;
  school?: InputMaybe<SchoolUpdateOneWithoutSkillsNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  standards?: InputMaybe<StandardUpdateManyWithoutSkillsNestedInput>;
  storyActivities?: InputMaybe<StoryActivityUpdateManyWithoutSkillNestedInput>;
  type?: InputMaybe<EnumSkillTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  weightage?: InputMaybe<FloatFieldUpdateOperationsInput>;
  workshops?: InputMaybe<WorkshopUpdateManyWithoutSkillNestedInput>;
};

export type SkillUpdateWithoutGamePointTypeInput = {
  activities?: InputMaybe<ActivityUpdateManyWithoutSkillNestedInput>;
  bundle?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  displayOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  metaTagData?: InputMaybe<Scalars["JSON"]["input"]>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  products?: InputMaybe<ProductUpdateManyWithoutSkillNestedInput>;
  school?: InputMaybe<SchoolUpdateOneWithoutSkillsNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  standards?: InputMaybe<StandardUpdateManyWithoutSkillsNestedInput>;
  storyActivities?: InputMaybe<StoryActivityUpdateManyWithoutSkillNestedInput>;
  type?: InputMaybe<EnumSkillTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  weightage?: InputMaybe<FloatFieldUpdateOperationsInput>;
  workshops?: InputMaybe<WorkshopUpdateManyWithoutSkillNestedInput>;
};

export type SkillUpdateWithoutProductsInput = {
  activities?: InputMaybe<ActivityUpdateManyWithoutSkillNestedInput>;
  bundle?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  displayOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  gamePointType?: InputMaybe<GamePointTypeUpdateOneWithoutSkillNestedInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  metaTagData?: InputMaybe<Scalars["JSON"]["input"]>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  school?: InputMaybe<SchoolUpdateOneWithoutSkillsNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  standards?: InputMaybe<StandardUpdateManyWithoutSkillsNestedInput>;
  storyActivities?: InputMaybe<StoryActivityUpdateManyWithoutSkillNestedInput>;
  type?: InputMaybe<EnumSkillTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  weightage?: InputMaybe<FloatFieldUpdateOperationsInput>;
  workshops?: InputMaybe<WorkshopUpdateManyWithoutSkillNestedInput>;
};

export type SkillUpdateWithoutSchoolInput = {
  activities?: InputMaybe<ActivityUpdateManyWithoutSkillNestedInput>;
  bundle?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  displayOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  gamePointType?: InputMaybe<GamePointTypeUpdateOneWithoutSkillNestedInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  metaTagData?: InputMaybe<Scalars["JSON"]["input"]>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  products?: InputMaybe<ProductUpdateManyWithoutSkillNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  standards?: InputMaybe<StandardUpdateManyWithoutSkillsNestedInput>;
  storyActivities?: InputMaybe<StoryActivityUpdateManyWithoutSkillNestedInput>;
  type?: InputMaybe<EnumSkillTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  weightage?: InputMaybe<FloatFieldUpdateOperationsInput>;
  workshops?: InputMaybe<WorkshopUpdateManyWithoutSkillNestedInput>;
};

export type SkillUpdateWithoutStandardsInput = {
  activities?: InputMaybe<ActivityUpdateManyWithoutSkillNestedInput>;
  bundle?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  displayOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  gamePointType?: InputMaybe<GamePointTypeUpdateOneWithoutSkillNestedInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  metaTagData?: InputMaybe<Scalars["JSON"]["input"]>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  products?: InputMaybe<ProductUpdateManyWithoutSkillNestedInput>;
  school?: InputMaybe<SchoolUpdateOneWithoutSkillsNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  storyActivities?: InputMaybe<StoryActivityUpdateManyWithoutSkillNestedInput>;
  type?: InputMaybe<EnumSkillTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  weightage?: InputMaybe<FloatFieldUpdateOperationsInput>;
  workshops?: InputMaybe<WorkshopUpdateManyWithoutSkillNestedInput>;
};

export type SkillUpdateWithoutStoryActivitiesInput = {
  activities?: InputMaybe<ActivityUpdateManyWithoutSkillNestedInput>;
  bundle?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  displayOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  gamePointType?: InputMaybe<GamePointTypeUpdateOneWithoutSkillNestedInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  metaTagData?: InputMaybe<Scalars["JSON"]["input"]>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  products?: InputMaybe<ProductUpdateManyWithoutSkillNestedInput>;
  school?: InputMaybe<SchoolUpdateOneWithoutSkillsNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  standards?: InputMaybe<StandardUpdateManyWithoutSkillsNestedInput>;
  type?: InputMaybe<EnumSkillTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  weightage?: InputMaybe<FloatFieldUpdateOperationsInput>;
  workshops?: InputMaybe<WorkshopUpdateManyWithoutSkillNestedInput>;
};

export type SkillUpdateWithoutWorkshopsInput = {
  activities?: InputMaybe<ActivityUpdateManyWithoutSkillNestedInput>;
  bundle?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  displayOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  gamePointType?: InputMaybe<GamePointTypeUpdateOneWithoutSkillNestedInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  metaTagData?: InputMaybe<Scalars["JSON"]["input"]>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  products?: InputMaybe<ProductUpdateManyWithoutSkillNestedInput>;
  school?: InputMaybe<SchoolUpdateOneWithoutSkillsNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  standards?: InputMaybe<StandardUpdateManyWithoutSkillsNestedInput>;
  storyActivities?: InputMaybe<StoryActivityUpdateManyWithoutSkillNestedInput>;
  type?: InputMaybe<EnumSkillTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  weightage?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type SkillUpsertWithWhereUniqueWithoutSchoolInput = {
  create: SkillCreateWithoutSchoolInput;
  update: SkillUpdateWithoutSchoolInput;
  where: SkillWhereUniqueInput;
};

export type SkillUpsertWithWhereUniqueWithoutStandardsInput = {
  create: SkillCreateWithoutStandardsInput;
  update: SkillUpdateWithoutStandardsInput;
  where: SkillWhereUniqueInput;
};

export type SkillUpsertWithoutActivitiesInput = {
  create: SkillCreateWithoutActivitiesInput;
  update: SkillUpdateWithoutActivitiesInput;
};

export type SkillUpsertWithoutGamePointTypeInput = {
  create: SkillCreateWithoutGamePointTypeInput;
  update: SkillUpdateWithoutGamePointTypeInput;
};

export type SkillUpsertWithoutProductsInput = {
  create: SkillCreateWithoutProductsInput;
  update: SkillUpdateWithoutProductsInput;
};

export type SkillUpsertWithoutStoryActivitiesInput = {
  create: SkillCreateWithoutStoryActivitiesInput;
  update: SkillUpdateWithoutStoryActivitiesInput;
};

export type SkillUpsertWithoutWorkshopsInput = {
  create: SkillCreateWithoutWorkshopsInput;
  update: SkillUpdateWithoutWorkshopsInput;
};

export type SkillWhereInput = {
  AND?: InputMaybe<Array<SkillWhereInput>>;
  NOT?: InputMaybe<Array<SkillWhereInput>>;
  OR?: InputMaybe<Array<SkillWhereInput>>;
  activities?: InputMaybe<ActivityListRelationFilter>;
  bundle?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  displayOrder?: InputMaybe<IntFilter>;
  featuredImage?: InputMaybe<StringNullableFilter>;
  gamePointType?: InputMaybe<GamePointTypeRelationFilter>;
  id?: InputMaybe<IntFilter>;
  image?: InputMaybe<StringNullableFilter>;
  isActive?: InputMaybe<BoolFilter>;
  meta?: InputMaybe<JsonNullableFilter>;
  metaTagData?: InputMaybe<JsonNullableFilter>;
  name?: InputMaybe<StringFilter>;
  products?: InputMaybe<ProductListRelationFilter>;
  school?: InputMaybe<SchoolRelationFilter>;
  schoolId?: InputMaybe<IntNullableFilter>;
  slug?: InputMaybe<StringFilter>;
  standards?: InputMaybe<StandardListRelationFilter>;
  storyActivities?: InputMaybe<StoryActivityListRelationFilter>;
  type?: InputMaybe<EnumSkillTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  weightage?: InputMaybe<FloatFilter>;
  workshops?: InputMaybe<WorkshopListRelationFilter>;
};

export type SkillWhereUniqueInput = {
  id?: InputMaybe<Scalars["Int"]["input"]>;
  slug?: InputMaybe<Scalars["String"]["input"]>;
};

export enum SortOrder {
  ASC = "asc",
  DESC = "desc"
}

export type SortOrderInput = {
  nulls?: InputMaybe<NullsOrder>;
  sort: SortOrder;
};

export type Standard = {
  __typename?: "Standard";
  _count?: Maybe<StandardCount>;
  id: Scalars["Int"]["output"];
  key: Scalars["String"]["output"];
  value: Scalars["String"]["output"];
};

export type StandardAvgAggregate = {
  __typename?: "StandardAvgAggregate";
  id?: Maybe<Scalars["Float"]["output"]>;
};

export type StandardCount = {
  __typename?: "StandardCount";
  activities: Scalars["Int"]["output"];
  dailyActivities: Scalars["Int"]["output"];
  products: Scalars["Int"]["output"];
  reports: Scalars["Int"]["output"];
  samskaras: Scalars["Int"]["output"];
  schoolUpdates: Scalars["Int"]["output"];
  skills: Scalars["Int"]["output"];
  stories: Scalars["Int"]["output"];
  storyActivities: Scalars["Int"]["output"];
  studentRecords: Scalars["Int"]["output"];
  students: Scalars["Int"]["output"];
  users: Scalars["Int"]["output"];
  workshops: Scalars["Int"]["output"];
};


export type StandardCountActivitiesArgs = {
  where?: InputMaybe<ActivityWhereInput>;
};


export type StandardCountDailyActivitiesArgs = {
  where?: InputMaybe<DailyActivityWhereInput>;
};


export type StandardCountProductsArgs = {
  where?: InputMaybe<ProductWhereInput>;
};


export type StandardCountReportsArgs = {
  where?: InputMaybe<ReportWhereInput>;
};


export type StandardCountSamskarasArgs = {
  where?: InputMaybe<SamskaraWhereInput>;
};


export type StandardCountSchoolUpdatesArgs = {
  where?: InputMaybe<SchoolUpdateWhereInput>;
};


export type StandardCountSkillsArgs = {
  where?: InputMaybe<SkillWhereInput>;
};


export type StandardCountStoriesArgs = {
  where?: InputMaybe<StoryWhereInput>;
};


export type StandardCountStoryActivitiesArgs = {
  where?: InputMaybe<StoryActivityWhereInput>;
};


export type StandardCountStudentRecordsArgs = {
  where?: InputMaybe<StudentRecordWhereInput>;
};


export type StandardCountStudentsArgs = {
  where?: InputMaybe<StudentWhereInput>;
};


export type StandardCountUsersArgs = {
  where?: InputMaybe<UserWhereInput>;
};


export type StandardCountWorkshopsArgs = {
  where?: InputMaybe<WorkshopWhereInput>;
};

export type StandardCountAggregate = {
  __typename?: "StandardCountAggregate";
  _all: Scalars["Int"]["output"];
  id: Scalars["Int"]["output"];
  key: Scalars["Int"]["output"];
  value: Scalars["Int"]["output"];
};

export type StandardCreateInput = {
  activities?: InputMaybe<ActivityCreateNestedManyWithoutStandardsInput>;
  dailyActivities?: InputMaybe<DailyActivityCreateNestedManyWithoutStandardsInput>;
  key: Scalars["String"]["input"];
  products?: InputMaybe<ProductCreateNestedManyWithoutStandardsInput>;
  reports?: InputMaybe<ReportCreateNestedManyWithoutStandardInput>;
  samskaras?: InputMaybe<SamskaraCreateNestedManyWithoutStandardInput>;
  schoolUpdates?: InputMaybe<SchoolUpdateCreateNestedManyWithoutStandardsInput>;
  skills?: InputMaybe<SkillCreateNestedManyWithoutStandardsInput>;
  stories?: InputMaybe<StoryCreateNestedManyWithoutStandardsInput>;
  storyActivities?: InputMaybe<StoryActivityCreateNestedManyWithoutStandardsInput>;
  studentRecords?: InputMaybe<StudentRecordCreateNestedManyWithoutStandardInput>;
  students?: InputMaybe<StudentCreateNestedManyWithoutStandardInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutStandardsInput>;
  value: Scalars["String"]["input"];
  workshops?: InputMaybe<WorkshopCreateNestedManyWithoutStandardsInput>;
};

export type StandardCreateNestedManyWithoutActivitiesInput = {
  connect?: InputMaybe<Array<StandardWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StandardCreateOrConnectWithoutActivitiesInput>>;
  create?: InputMaybe<Array<StandardCreateWithoutActivitiesInput>>;
};

export type StandardCreateNestedManyWithoutDailyActivitiesInput = {
  connect?: InputMaybe<Array<StandardWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StandardCreateOrConnectWithoutDailyActivitiesInput>>;
  create?: InputMaybe<Array<StandardCreateWithoutDailyActivitiesInput>>;
};

export type StandardCreateNestedManyWithoutProductsInput = {
  connect?: InputMaybe<Array<StandardWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StandardCreateOrConnectWithoutProductsInput>>;
  create?: InputMaybe<Array<StandardCreateWithoutProductsInput>>;
};

export type StandardCreateNestedManyWithoutSchoolUpdatesInput = {
  connect?: InputMaybe<Array<StandardWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StandardCreateOrConnectWithoutSchoolUpdatesInput>>;
  create?: InputMaybe<Array<StandardCreateWithoutSchoolUpdatesInput>>;
};

export type StandardCreateNestedManyWithoutSkillsInput = {
  connect?: InputMaybe<Array<StandardWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StandardCreateOrConnectWithoutSkillsInput>>;
  create?: InputMaybe<Array<StandardCreateWithoutSkillsInput>>;
};

export type StandardCreateNestedManyWithoutStoriesInput = {
  connect?: InputMaybe<Array<StandardWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StandardCreateOrConnectWithoutStoriesInput>>;
  create?: InputMaybe<Array<StandardCreateWithoutStoriesInput>>;
};

export type StandardCreateNestedManyWithoutStoryActivitiesInput = {
  connect?: InputMaybe<Array<StandardWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StandardCreateOrConnectWithoutStoryActivitiesInput>>;
  create?: InputMaybe<Array<StandardCreateWithoutStoryActivitiesInput>>;
};

export type StandardCreateNestedManyWithoutUsersInput = {
  connect?: InputMaybe<Array<StandardWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StandardCreateOrConnectWithoutUsersInput>>;
  create?: InputMaybe<Array<StandardCreateWithoutUsersInput>>;
};

export type StandardCreateNestedManyWithoutWorkshopsInput = {
  connect?: InputMaybe<Array<StandardWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StandardCreateOrConnectWithoutWorkshopsInput>>;
  create?: InputMaybe<Array<StandardCreateWithoutWorkshopsInput>>;
};

export type StandardCreateNestedOneWithoutReportsInput = {
  connect?: InputMaybe<StandardWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StandardCreateOrConnectWithoutReportsInput>;
  create?: InputMaybe<StandardCreateWithoutReportsInput>;
};

export type StandardCreateNestedOneWithoutSamskarasInput = {
  connect?: InputMaybe<StandardWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StandardCreateOrConnectWithoutSamskarasInput>;
  create?: InputMaybe<StandardCreateWithoutSamskarasInput>;
};

export type StandardCreateNestedOneWithoutStudentRecordsInput = {
  connect?: InputMaybe<StandardWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StandardCreateOrConnectWithoutStudentRecordsInput>;
  create?: InputMaybe<StandardCreateWithoutStudentRecordsInput>;
};

export type StandardCreateNestedOneWithoutStudentsInput = {
  connect?: InputMaybe<StandardWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StandardCreateOrConnectWithoutStudentsInput>;
  create?: InputMaybe<StandardCreateWithoutStudentsInput>;
};

export type StandardCreateOrConnectWithoutActivitiesInput = {
  create: StandardCreateWithoutActivitiesInput;
  where: StandardWhereUniqueInput;
};

export type StandardCreateOrConnectWithoutDailyActivitiesInput = {
  create: StandardCreateWithoutDailyActivitiesInput;
  where: StandardWhereUniqueInput;
};

export type StandardCreateOrConnectWithoutProductsInput = {
  create: StandardCreateWithoutProductsInput;
  where: StandardWhereUniqueInput;
};

export type StandardCreateOrConnectWithoutReportsInput = {
  create: StandardCreateWithoutReportsInput;
  where: StandardWhereUniqueInput;
};

export type StandardCreateOrConnectWithoutSamskarasInput = {
  create: StandardCreateWithoutSamskarasInput;
  where: StandardWhereUniqueInput;
};

export type StandardCreateOrConnectWithoutSchoolUpdatesInput = {
  create: StandardCreateWithoutSchoolUpdatesInput;
  where: StandardWhereUniqueInput;
};

export type StandardCreateOrConnectWithoutSkillsInput = {
  create: StandardCreateWithoutSkillsInput;
  where: StandardWhereUniqueInput;
};

export type StandardCreateOrConnectWithoutStoriesInput = {
  create: StandardCreateWithoutStoriesInput;
  where: StandardWhereUniqueInput;
};

export type StandardCreateOrConnectWithoutStoryActivitiesInput = {
  create: StandardCreateWithoutStoryActivitiesInput;
  where: StandardWhereUniqueInput;
};

export type StandardCreateOrConnectWithoutStudentRecordsInput = {
  create: StandardCreateWithoutStudentRecordsInput;
  where: StandardWhereUniqueInput;
};

export type StandardCreateOrConnectWithoutStudentsInput = {
  create: StandardCreateWithoutStudentsInput;
  where: StandardWhereUniqueInput;
};

export type StandardCreateOrConnectWithoutUsersInput = {
  create: StandardCreateWithoutUsersInput;
  where: StandardWhereUniqueInput;
};

export type StandardCreateOrConnectWithoutWorkshopsInput = {
  create: StandardCreateWithoutWorkshopsInput;
  where: StandardWhereUniqueInput;
};

export type StandardCreateWithoutActivitiesInput = {
  dailyActivities?: InputMaybe<DailyActivityCreateNestedManyWithoutStandardsInput>;
  key: Scalars["String"]["input"];
  products?: InputMaybe<ProductCreateNestedManyWithoutStandardsInput>;
  reports?: InputMaybe<ReportCreateNestedManyWithoutStandardInput>;
  samskaras?: InputMaybe<SamskaraCreateNestedManyWithoutStandardInput>;
  schoolUpdates?: InputMaybe<SchoolUpdateCreateNestedManyWithoutStandardsInput>;
  skills?: InputMaybe<SkillCreateNestedManyWithoutStandardsInput>;
  stories?: InputMaybe<StoryCreateNestedManyWithoutStandardsInput>;
  storyActivities?: InputMaybe<StoryActivityCreateNestedManyWithoutStandardsInput>;
  studentRecords?: InputMaybe<StudentRecordCreateNestedManyWithoutStandardInput>;
  students?: InputMaybe<StudentCreateNestedManyWithoutStandardInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutStandardsInput>;
  value: Scalars["String"]["input"];
  workshops?: InputMaybe<WorkshopCreateNestedManyWithoutStandardsInput>;
};

export type StandardCreateWithoutDailyActivitiesInput = {
  activities?: InputMaybe<ActivityCreateNestedManyWithoutStandardsInput>;
  key: Scalars["String"]["input"];
  products?: InputMaybe<ProductCreateNestedManyWithoutStandardsInput>;
  reports?: InputMaybe<ReportCreateNestedManyWithoutStandardInput>;
  samskaras?: InputMaybe<SamskaraCreateNestedManyWithoutStandardInput>;
  schoolUpdates?: InputMaybe<SchoolUpdateCreateNestedManyWithoutStandardsInput>;
  skills?: InputMaybe<SkillCreateNestedManyWithoutStandardsInput>;
  stories?: InputMaybe<StoryCreateNestedManyWithoutStandardsInput>;
  storyActivities?: InputMaybe<StoryActivityCreateNestedManyWithoutStandardsInput>;
  studentRecords?: InputMaybe<StudentRecordCreateNestedManyWithoutStandardInput>;
  students?: InputMaybe<StudentCreateNestedManyWithoutStandardInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutStandardsInput>;
  value: Scalars["String"]["input"];
  workshops?: InputMaybe<WorkshopCreateNestedManyWithoutStandardsInput>;
};

export type StandardCreateWithoutProductsInput = {
  activities?: InputMaybe<ActivityCreateNestedManyWithoutStandardsInput>;
  dailyActivities?: InputMaybe<DailyActivityCreateNestedManyWithoutStandardsInput>;
  key: Scalars["String"]["input"];
  reports?: InputMaybe<ReportCreateNestedManyWithoutStandardInput>;
  samskaras?: InputMaybe<SamskaraCreateNestedManyWithoutStandardInput>;
  schoolUpdates?: InputMaybe<SchoolUpdateCreateNestedManyWithoutStandardsInput>;
  skills?: InputMaybe<SkillCreateNestedManyWithoutStandardsInput>;
  stories?: InputMaybe<StoryCreateNestedManyWithoutStandardsInput>;
  storyActivities?: InputMaybe<StoryActivityCreateNestedManyWithoutStandardsInput>;
  studentRecords?: InputMaybe<StudentRecordCreateNestedManyWithoutStandardInput>;
  students?: InputMaybe<StudentCreateNestedManyWithoutStandardInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutStandardsInput>;
  value: Scalars["String"]["input"];
  workshops?: InputMaybe<WorkshopCreateNestedManyWithoutStandardsInput>;
};

export type StandardCreateWithoutReportsInput = {
  activities?: InputMaybe<ActivityCreateNestedManyWithoutStandardsInput>;
  dailyActivities?: InputMaybe<DailyActivityCreateNestedManyWithoutStandardsInput>;
  key: Scalars["String"]["input"];
  products?: InputMaybe<ProductCreateNestedManyWithoutStandardsInput>;
  samskaras?: InputMaybe<SamskaraCreateNestedManyWithoutStandardInput>;
  schoolUpdates?: InputMaybe<SchoolUpdateCreateNestedManyWithoutStandardsInput>;
  skills?: InputMaybe<SkillCreateNestedManyWithoutStandardsInput>;
  stories?: InputMaybe<StoryCreateNestedManyWithoutStandardsInput>;
  storyActivities?: InputMaybe<StoryActivityCreateNestedManyWithoutStandardsInput>;
  studentRecords?: InputMaybe<StudentRecordCreateNestedManyWithoutStandardInput>;
  students?: InputMaybe<StudentCreateNestedManyWithoutStandardInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutStandardsInput>;
  value: Scalars["String"]["input"];
  workshops?: InputMaybe<WorkshopCreateNestedManyWithoutStandardsInput>;
};

export type StandardCreateWithoutSamskarasInput = {
  activities?: InputMaybe<ActivityCreateNestedManyWithoutStandardsInput>;
  dailyActivities?: InputMaybe<DailyActivityCreateNestedManyWithoutStandardsInput>;
  key: Scalars["String"]["input"];
  products?: InputMaybe<ProductCreateNestedManyWithoutStandardsInput>;
  reports?: InputMaybe<ReportCreateNestedManyWithoutStandardInput>;
  schoolUpdates?: InputMaybe<SchoolUpdateCreateNestedManyWithoutStandardsInput>;
  skills?: InputMaybe<SkillCreateNestedManyWithoutStandardsInput>;
  stories?: InputMaybe<StoryCreateNestedManyWithoutStandardsInput>;
  storyActivities?: InputMaybe<StoryActivityCreateNestedManyWithoutStandardsInput>;
  studentRecords?: InputMaybe<StudentRecordCreateNestedManyWithoutStandardInput>;
  students?: InputMaybe<StudentCreateNestedManyWithoutStandardInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutStandardsInput>;
  value: Scalars["String"]["input"];
  workshops?: InputMaybe<WorkshopCreateNestedManyWithoutStandardsInput>;
};

export type StandardCreateWithoutSchoolUpdatesInput = {
  activities?: InputMaybe<ActivityCreateNestedManyWithoutStandardsInput>;
  dailyActivities?: InputMaybe<DailyActivityCreateNestedManyWithoutStandardsInput>;
  key: Scalars["String"]["input"];
  products?: InputMaybe<ProductCreateNestedManyWithoutStandardsInput>;
  reports?: InputMaybe<ReportCreateNestedManyWithoutStandardInput>;
  samskaras?: InputMaybe<SamskaraCreateNestedManyWithoutStandardInput>;
  skills?: InputMaybe<SkillCreateNestedManyWithoutStandardsInput>;
  stories?: InputMaybe<StoryCreateNestedManyWithoutStandardsInput>;
  storyActivities?: InputMaybe<StoryActivityCreateNestedManyWithoutStandardsInput>;
  studentRecords?: InputMaybe<StudentRecordCreateNestedManyWithoutStandardInput>;
  students?: InputMaybe<StudentCreateNestedManyWithoutStandardInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutStandardsInput>;
  value: Scalars["String"]["input"];
  workshops?: InputMaybe<WorkshopCreateNestedManyWithoutStandardsInput>;
};

export type StandardCreateWithoutSkillsInput = {
  activities?: InputMaybe<ActivityCreateNestedManyWithoutStandardsInput>;
  dailyActivities?: InputMaybe<DailyActivityCreateNestedManyWithoutStandardsInput>;
  key: Scalars["String"]["input"];
  products?: InputMaybe<ProductCreateNestedManyWithoutStandardsInput>;
  reports?: InputMaybe<ReportCreateNestedManyWithoutStandardInput>;
  samskaras?: InputMaybe<SamskaraCreateNestedManyWithoutStandardInput>;
  schoolUpdates?: InputMaybe<SchoolUpdateCreateNestedManyWithoutStandardsInput>;
  stories?: InputMaybe<StoryCreateNestedManyWithoutStandardsInput>;
  storyActivities?: InputMaybe<StoryActivityCreateNestedManyWithoutStandardsInput>;
  studentRecords?: InputMaybe<StudentRecordCreateNestedManyWithoutStandardInput>;
  students?: InputMaybe<StudentCreateNestedManyWithoutStandardInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutStandardsInput>;
  value: Scalars["String"]["input"];
  workshops?: InputMaybe<WorkshopCreateNestedManyWithoutStandardsInput>;
};

export type StandardCreateWithoutStoriesInput = {
  activities?: InputMaybe<ActivityCreateNestedManyWithoutStandardsInput>;
  dailyActivities?: InputMaybe<DailyActivityCreateNestedManyWithoutStandardsInput>;
  key: Scalars["String"]["input"];
  products?: InputMaybe<ProductCreateNestedManyWithoutStandardsInput>;
  reports?: InputMaybe<ReportCreateNestedManyWithoutStandardInput>;
  samskaras?: InputMaybe<SamskaraCreateNestedManyWithoutStandardInput>;
  schoolUpdates?: InputMaybe<SchoolUpdateCreateNestedManyWithoutStandardsInput>;
  skills?: InputMaybe<SkillCreateNestedManyWithoutStandardsInput>;
  storyActivities?: InputMaybe<StoryActivityCreateNestedManyWithoutStandardsInput>;
  studentRecords?: InputMaybe<StudentRecordCreateNestedManyWithoutStandardInput>;
  students?: InputMaybe<StudentCreateNestedManyWithoutStandardInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutStandardsInput>;
  value: Scalars["String"]["input"];
  workshops?: InputMaybe<WorkshopCreateNestedManyWithoutStandardsInput>;
};

export type StandardCreateWithoutStoryActivitiesInput = {
  activities?: InputMaybe<ActivityCreateNestedManyWithoutStandardsInput>;
  dailyActivities?: InputMaybe<DailyActivityCreateNestedManyWithoutStandardsInput>;
  key: Scalars["String"]["input"];
  products?: InputMaybe<ProductCreateNestedManyWithoutStandardsInput>;
  reports?: InputMaybe<ReportCreateNestedManyWithoutStandardInput>;
  samskaras?: InputMaybe<SamskaraCreateNestedManyWithoutStandardInput>;
  schoolUpdates?: InputMaybe<SchoolUpdateCreateNestedManyWithoutStandardsInput>;
  skills?: InputMaybe<SkillCreateNestedManyWithoutStandardsInput>;
  stories?: InputMaybe<StoryCreateNestedManyWithoutStandardsInput>;
  studentRecords?: InputMaybe<StudentRecordCreateNestedManyWithoutStandardInput>;
  students?: InputMaybe<StudentCreateNestedManyWithoutStandardInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutStandardsInput>;
  value: Scalars["String"]["input"];
  workshops?: InputMaybe<WorkshopCreateNestedManyWithoutStandardsInput>;
};

export type StandardCreateWithoutStudentRecordsInput = {
  activities?: InputMaybe<ActivityCreateNestedManyWithoutStandardsInput>;
  dailyActivities?: InputMaybe<DailyActivityCreateNestedManyWithoutStandardsInput>;
  key: Scalars["String"]["input"];
  products?: InputMaybe<ProductCreateNestedManyWithoutStandardsInput>;
  reports?: InputMaybe<ReportCreateNestedManyWithoutStandardInput>;
  samskaras?: InputMaybe<SamskaraCreateNestedManyWithoutStandardInput>;
  schoolUpdates?: InputMaybe<SchoolUpdateCreateNestedManyWithoutStandardsInput>;
  skills?: InputMaybe<SkillCreateNestedManyWithoutStandardsInput>;
  stories?: InputMaybe<StoryCreateNestedManyWithoutStandardsInput>;
  storyActivities?: InputMaybe<StoryActivityCreateNestedManyWithoutStandardsInput>;
  students?: InputMaybe<StudentCreateNestedManyWithoutStandardInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutStandardsInput>;
  value: Scalars["String"]["input"];
  workshops?: InputMaybe<WorkshopCreateNestedManyWithoutStandardsInput>;
};

export type StandardCreateWithoutStudentsInput = {
  activities?: InputMaybe<ActivityCreateNestedManyWithoutStandardsInput>;
  dailyActivities?: InputMaybe<DailyActivityCreateNestedManyWithoutStandardsInput>;
  key: Scalars["String"]["input"];
  products?: InputMaybe<ProductCreateNestedManyWithoutStandardsInput>;
  reports?: InputMaybe<ReportCreateNestedManyWithoutStandardInput>;
  samskaras?: InputMaybe<SamskaraCreateNestedManyWithoutStandardInput>;
  schoolUpdates?: InputMaybe<SchoolUpdateCreateNestedManyWithoutStandardsInput>;
  skills?: InputMaybe<SkillCreateNestedManyWithoutStandardsInput>;
  stories?: InputMaybe<StoryCreateNestedManyWithoutStandardsInput>;
  storyActivities?: InputMaybe<StoryActivityCreateNestedManyWithoutStandardsInput>;
  studentRecords?: InputMaybe<StudentRecordCreateNestedManyWithoutStandardInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutStandardsInput>;
  value: Scalars["String"]["input"];
  workshops?: InputMaybe<WorkshopCreateNestedManyWithoutStandardsInput>;
};

export type StandardCreateWithoutUsersInput = {
  activities?: InputMaybe<ActivityCreateNestedManyWithoutStandardsInput>;
  dailyActivities?: InputMaybe<DailyActivityCreateNestedManyWithoutStandardsInput>;
  key: Scalars["String"]["input"];
  products?: InputMaybe<ProductCreateNestedManyWithoutStandardsInput>;
  reports?: InputMaybe<ReportCreateNestedManyWithoutStandardInput>;
  samskaras?: InputMaybe<SamskaraCreateNestedManyWithoutStandardInput>;
  schoolUpdates?: InputMaybe<SchoolUpdateCreateNestedManyWithoutStandardsInput>;
  skills?: InputMaybe<SkillCreateNestedManyWithoutStandardsInput>;
  stories?: InputMaybe<StoryCreateNestedManyWithoutStandardsInput>;
  storyActivities?: InputMaybe<StoryActivityCreateNestedManyWithoutStandardsInput>;
  studentRecords?: InputMaybe<StudentRecordCreateNestedManyWithoutStandardInput>;
  students?: InputMaybe<StudentCreateNestedManyWithoutStandardInput>;
  value: Scalars["String"]["input"];
  workshops?: InputMaybe<WorkshopCreateNestedManyWithoutStandardsInput>;
};

export type StandardCreateWithoutWorkshopsInput = {
  activities?: InputMaybe<ActivityCreateNestedManyWithoutStandardsInput>;
  dailyActivities?: InputMaybe<DailyActivityCreateNestedManyWithoutStandardsInput>;
  key: Scalars["String"]["input"];
  products?: InputMaybe<ProductCreateNestedManyWithoutStandardsInput>;
  reports?: InputMaybe<ReportCreateNestedManyWithoutStandardInput>;
  samskaras?: InputMaybe<SamskaraCreateNestedManyWithoutStandardInput>;
  schoolUpdates?: InputMaybe<SchoolUpdateCreateNestedManyWithoutStandardsInput>;
  skills?: InputMaybe<SkillCreateNestedManyWithoutStandardsInput>;
  stories?: InputMaybe<StoryCreateNestedManyWithoutStandardsInput>;
  storyActivities?: InputMaybe<StoryActivityCreateNestedManyWithoutStandardsInput>;
  studentRecords?: InputMaybe<StudentRecordCreateNestedManyWithoutStandardInput>;
  students?: InputMaybe<StudentCreateNestedManyWithoutStandardInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutStandardsInput>;
  value: Scalars["String"]["input"];
};

export type StandardListRelationFilter = {
  every?: InputMaybe<StandardWhereInput>;
  none?: InputMaybe<StandardWhereInput>;
  some?: InputMaybe<StandardWhereInput>;
};

export type StandardMaxAggregate = {
  __typename?: "StandardMaxAggregate";
  id?: Maybe<Scalars["Int"]["output"]>;
  key?: Maybe<Scalars["String"]["output"]>;
  value?: Maybe<Scalars["String"]["output"]>;
};

export type StandardMinAggregate = {
  __typename?: "StandardMinAggregate";
  id?: Maybe<Scalars["Int"]["output"]>;
  key?: Maybe<Scalars["String"]["output"]>;
  value?: Maybe<Scalars["String"]["output"]>;
};

export type StandardOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type StandardOrderByWithRelationInput = {
  activities?: InputMaybe<ActivityOrderByRelationAggregateInput>;
  dailyActivities?: InputMaybe<DailyActivityOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  key?: InputMaybe<SortOrder>;
  products?: InputMaybe<ProductOrderByRelationAggregateInput>;
  reports?: InputMaybe<ReportOrderByRelationAggregateInput>;
  samskaras?: InputMaybe<SamskaraOrderByRelationAggregateInput>;
  schoolUpdates?: InputMaybe<SchoolUpdateOrderByRelationAggregateInput>;
  skills?: InputMaybe<SkillOrderByRelationAggregateInput>;
  stories?: InputMaybe<StoryOrderByRelationAggregateInput>;
  storyActivities?: InputMaybe<StoryActivityOrderByRelationAggregateInput>;
  studentRecords?: InputMaybe<StudentRecordOrderByRelationAggregateInput>;
  students?: InputMaybe<StudentOrderByRelationAggregateInput>;
  users?: InputMaybe<UserOrderByRelationAggregateInput>;
  value?: InputMaybe<SortOrder>;
  workshops?: InputMaybe<WorkshopOrderByRelationAggregateInput>;
};

export type StandardRelationFilter = {
  is?: InputMaybe<StandardWhereInput>;
  isNot?: InputMaybe<StandardWhereInput>;
};

export enum StandardScalarFieldEnum {
  ID = "id",
  KEY = "key",
  VALUE = "value"
}

export type StandardScalarWhereInput = {
  AND?: InputMaybe<Array<StandardScalarWhereInput>>;
  NOT?: InputMaybe<Array<StandardScalarWhereInput>>;
  OR?: InputMaybe<Array<StandardScalarWhereInput>>;
  id?: InputMaybe<IntFilter>;
  key?: InputMaybe<StringFilter>;
  value?: InputMaybe<StringFilter>;
};

export type StandardSumAggregate = {
  __typename?: "StandardSumAggregate";
  id?: Maybe<Scalars["Int"]["output"]>;
};

export type StandardUpdateInput = {
  activities?: InputMaybe<ActivityUpdateManyWithoutStandardsNestedInput>;
  dailyActivities?: InputMaybe<DailyActivityUpdateManyWithoutStandardsNestedInput>;
  key?: InputMaybe<StringFieldUpdateOperationsInput>;
  products?: InputMaybe<ProductUpdateManyWithoutStandardsNestedInput>;
  reports?: InputMaybe<ReportUpdateManyWithoutStandardNestedInput>;
  samskaras?: InputMaybe<SamskaraUpdateManyWithoutStandardNestedInput>;
  schoolUpdates?: InputMaybe<SchoolUpdateUpdateManyWithoutStandardsNestedInput>;
  skills?: InputMaybe<SkillUpdateManyWithoutStandardsNestedInput>;
  stories?: InputMaybe<StoryUpdateManyWithoutStandardsNestedInput>;
  storyActivities?: InputMaybe<StoryActivityUpdateManyWithoutStandardsNestedInput>;
  studentRecords?: InputMaybe<StudentRecordUpdateManyWithoutStandardNestedInput>;
  students?: InputMaybe<StudentUpdateManyWithoutStandardNestedInput>;
  users?: InputMaybe<UserUpdateManyWithoutStandardsNestedInput>;
  value?: InputMaybe<StringFieldUpdateOperationsInput>;
  workshops?: InputMaybe<WorkshopUpdateManyWithoutStandardsNestedInput>;
};

export type StandardUpdateManyMutationInput = {
  key?: InputMaybe<StringFieldUpdateOperationsInput>;
  value?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type StandardUpdateManyWithWhereWithoutActivitiesInput = {
  data: StandardUpdateManyMutationInput;
  where: StandardScalarWhereInput;
};

export type StandardUpdateManyWithWhereWithoutDailyActivitiesInput = {
  data: StandardUpdateManyMutationInput;
  where: StandardScalarWhereInput;
};

export type StandardUpdateManyWithWhereWithoutProductsInput = {
  data: StandardUpdateManyMutationInput;
  where: StandardScalarWhereInput;
};

export type StandardUpdateManyWithWhereWithoutSchoolUpdatesInput = {
  data: StandardUpdateManyMutationInput;
  where: StandardScalarWhereInput;
};

export type StandardUpdateManyWithWhereWithoutSkillsInput = {
  data: StandardUpdateManyMutationInput;
  where: StandardScalarWhereInput;
};

export type StandardUpdateManyWithWhereWithoutStoriesInput = {
  data: StandardUpdateManyMutationInput;
  where: StandardScalarWhereInput;
};

export type StandardUpdateManyWithWhereWithoutStoryActivitiesInput = {
  data: StandardUpdateManyMutationInput;
  where: StandardScalarWhereInput;
};

export type StandardUpdateManyWithWhereWithoutUsersInput = {
  data: StandardUpdateManyMutationInput;
  where: StandardScalarWhereInput;
};

export type StandardUpdateManyWithWhereWithoutWorkshopsInput = {
  data: StandardUpdateManyMutationInput;
  where: StandardScalarWhereInput;
};

export type StandardUpdateManyWithoutActivitiesNestedInput = {
  connect?: InputMaybe<Array<StandardWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StandardCreateOrConnectWithoutActivitiesInput>>;
  create?: InputMaybe<Array<StandardCreateWithoutActivitiesInput>>;
  delete?: InputMaybe<Array<StandardWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<StandardScalarWhereInput>>;
  disconnect?: InputMaybe<Array<StandardWhereUniqueInput>>;
  set?: InputMaybe<Array<StandardWhereUniqueInput>>;
  update?: InputMaybe<Array<StandardUpdateWithWhereUniqueWithoutActivitiesInput>>;
  updateMany?: InputMaybe<Array<StandardUpdateManyWithWhereWithoutActivitiesInput>>;
  upsert?: InputMaybe<Array<StandardUpsertWithWhereUniqueWithoutActivitiesInput>>;
};

export type StandardUpdateManyWithoutDailyActivitiesNestedInput = {
  connect?: InputMaybe<Array<StandardWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StandardCreateOrConnectWithoutDailyActivitiesInput>>;
  create?: InputMaybe<Array<StandardCreateWithoutDailyActivitiesInput>>;
  delete?: InputMaybe<Array<StandardWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<StandardScalarWhereInput>>;
  disconnect?: InputMaybe<Array<StandardWhereUniqueInput>>;
  set?: InputMaybe<Array<StandardWhereUniqueInput>>;
  update?: InputMaybe<Array<StandardUpdateWithWhereUniqueWithoutDailyActivitiesInput>>;
  updateMany?: InputMaybe<Array<StandardUpdateManyWithWhereWithoutDailyActivitiesInput>>;
  upsert?: InputMaybe<Array<StandardUpsertWithWhereUniqueWithoutDailyActivitiesInput>>;
};

export type StandardUpdateManyWithoutProductsNestedInput = {
  connect?: InputMaybe<Array<StandardWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StandardCreateOrConnectWithoutProductsInput>>;
  create?: InputMaybe<Array<StandardCreateWithoutProductsInput>>;
  delete?: InputMaybe<Array<StandardWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<StandardScalarWhereInput>>;
  disconnect?: InputMaybe<Array<StandardWhereUniqueInput>>;
  set?: InputMaybe<Array<StandardWhereUniqueInput>>;
  update?: InputMaybe<Array<StandardUpdateWithWhereUniqueWithoutProductsInput>>;
  updateMany?: InputMaybe<Array<StandardUpdateManyWithWhereWithoutProductsInput>>;
  upsert?: InputMaybe<Array<StandardUpsertWithWhereUniqueWithoutProductsInput>>;
};

export type StandardUpdateManyWithoutSchoolUpdatesNestedInput = {
  connect?: InputMaybe<Array<StandardWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StandardCreateOrConnectWithoutSchoolUpdatesInput>>;
  create?: InputMaybe<Array<StandardCreateWithoutSchoolUpdatesInput>>;
  delete?: InputMaybe<Array<StandardWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<StandardScalarWhereInput>>;
  disconnect?: InputMaybe<Array<StandardWhereUniqueInput>>;
  set?: InputMaybe<Array<StandardWhereUniqueInput>>;
  update?: InputMaybe<Array<StandardUpdateWithWhereUniqueWithoutSchoolUpdatesInput>>;
  updateMany?: InputMaybe<Array<StandardUpdateManyWithWhereWithoutSchoolUpdatesInput>>;
  upsert?: InputMaybe<Array<StandardUpsertWithWhereUniqueWithoutSchoolUpdatesInput>>;
};

export type StandardUpdateManyWithoutSkillsNestedInput = {
  connect?: InputMaybe<Array<StandardWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StandardCreateOrConnectWithoutSkillsInput>>;
  create?: InputMaybe<Array<StandardCreateWithoutSkillsInput>>;
  delete?: InputMaybe<Array<StandardWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<StandardScalarWhereInput>>;
  disconnect?: InputMaybe<Array<StandardWhereUniqueInput>>;
  set?: InputMaybe<Array<StandardWhereUniqueInput>>;
  update?: InputMaybe<Array<StandardUpdateWithWhereUniqueWithoutSkillsInput>>;
  updateMany?: InputMaybe<Array<StandardUpdateManyWithWhereWithoutSkillsInput>>;
  upsert?: InputMaybe<Array<StandardUpsertWithWhereUniqueWithoutSkillsInput>>;
};

export type StandardUpdateManyWithoutStoriesNestedInput = {
  connect?: InputMaybe<Array<StandardWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StandardCreateOrConnectWithoutStoriesInput>>;
  create?: InputMaybe<Array<StandardCreateWithoutStoriesInput>>;
  delete?: InputMaybe<Array<StandardWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<StandardScalarWhereInput>>;
  disconnect?: InputMaybe<Array<StandardWhereUniqueInput>>;
  set?: InputMaybe<Array<StandardWhereUniqueInput>>;
  update?: InputMaybe<Array<StandardUpdateWithWhereUniqueWithoutStoriesInput>>;
  updateMany?: InputMaybe<Array<StandardUpdateManyWithWhereWithoutStoriesInput>>;
  upsert?: InputMaybe<Array<StandardUpsertWithWhereUniqueWithoutStoriesInput>>;
};

export type StandardUpdateManyWithoutStoryActivitiesNestedInput = {
  connect?: InputMaybe<Array<StandardWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StandardCreateOrConnectWithoutStoryActivitiesInput>>;
  create?: InputMaybe<Array<StandardCreateWithoutStoryActivitiesInput>>;
  delete?: InputMaybe<Array<StandardWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<StandardScalarWhereInput>>;
  disconnect?: InputMaybe<Array<StandardWhereUniqueInput>>;
  set?: InputMaybe<Array<StandardWhereUniqueInput>>;
  update?: InputMaybe<Array<StandardUpdateWithWhereUniqueWithoutStoryActivitiesInput>>;
  updateMany?: InputMaybe<Array<StandardUpdateManyWithWhereWithoutStoryActivitiesInput>>;
  upsert?: InputMaybe<Array<StandardUpsertWithWhereUniqueWithoutStoryActivitiesInput>>;
};

export type StandardUpdateManyWithoutUsersNestedInput = {
  connect?: InputMaybe<Array<StandardWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StandardCreateOrConnectWithoutUsersInput>>;
  create?: InputMaybe<Array<StandardCreateWithoutUsersInput>>;
  delete?: InputMaybe<Array<StandardWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<StandardScalarWhereInput>>;
  disconnect?: InputMaybe<Array<StandardWhereUniqueInput>>;
  set?: InputMaybe<Array<StandardWhereUniqueInput>>;
  update?: InputMaybe<Array<StandardUpdateWithWhereUniqueWithoutUsersInput>>;
  updateMany?: InputMaybe<Array<StandardUpdateManyWithWhereWithoutUsersInput>>;
  upsert?: InputMaybe<Array<StandardUpsertWithWhereUniqueWithoutUsersInput>>;
};

export type StandardUpdateManyWithoutWorkshopsNestedInput = {
  connect?: InputMaybe<Array<StandardWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StandardCreateOrConnectWithoutWorkshopsInput>>;
  create?: InputMaybe<Array<StandardCreateWithoutWorkshopsInput>>;
  delete?: InputMaybe<Array<StandardWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<StandardScalarWhereInput>>;
  disconnect?: InputMaybe<Array<StandardWhereUniqueInput>>;
  set?: InputMaybe<Array<StandardWhereUniqueInput>>;
  update?: InputMaybe<Array<StandardUpdateWithWhereUniqueWithoutWorkshopsInput>>;
  updateMany?: InputMaybe<Array<StandardUpdateManyWithWhereWithoutWorkshopsInput>>;
  upsert?: InputMaybe<Array<StandardUpsertWithWhereUniqueWithoutWorkshopsInput>>;
};

export type StandardUpdateOneRequiredWithoutReportsNestedInput = {
  connect?: InputMaybe<StandardWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StandardCreateOrConnectWithoutReportsInput>;
  create?: InputMaybe<StandardCreateWithoutReportsInput>;
  update?: InputMaybe<StandardUpdateWithoutReportsInput>;
  upsert?: InputMaybe<StandardUpsertWithoutReportsInput>;
};

export type StandardUpdateOneRequiredWithoutSamskarasNestedInput = {
  connect?: InputMaybe<StandardWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StandardCreateOrConnectWithoutSamskarasInput>;
  create?: InputMaybe<StandardCreateWithoutSamskarasInput>;
  update?: InputMaybe<StandardUpdateWithoutSamskarasInput>;
  upsert?: InputMaybe<StandardUpsertWithoutSamskarasInput>;
};

export type StandardUpdateOneRequiredWithoutStudentRecordsNestedInput = {
  connect?: InputMaybe<StandardWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StandardCreateOrConnectWithoutStudentRecordsInput>;
  create?: InputMaybe<StandardCreateWithoutStudentRecordsInput>;
  update?: InputMaybe<StandardUpdateWithoutStudentRecordsInput>;
  upsert?: InputMaybe<StandardUpsertWithoutStudentRecordsInput>;
};

export type StandardUpdateOneRequiredWithoutStudentsNestedInput = {
  connect?: InputMaybe<StandardWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StandardCreateOrConnectWithoutStudentsInput>;
  create?: InputMaybe<StandardCreateWithoutStudentsInput>;
  update?: InputMaybe<StandardUpdateWithoutStudentsInput>;
  upsert?: InputMaybe<StandardUpsertWithoutStudentsInput>;
};

export type StandardUpdateWithWhereUniqueWithoutActivitiesInput = {
  data: StandardUpdateWithoutActivitiesInput;
  where: StandardWhereUniqueInput;
};

export type StandardUpdateWithWhereUniqueWithoutDailyActivitiesInput = {
  data: StandardUpdateWithoutDailyActivitiesInput;
  where: StandardWhereUniqueInput;
};

export type StandardUpdateWithWhereUniqueWithoutProductsInput = {
  data: StandardUpdateWithoutProductsInput;
  where: StandardWhereUniqueInput;
};

export type StandardUpdateWithWhereUniqueWithoutSchoolUpdatesInput = {
  data: StandardUpdateWithoutSchoolUpdatesInput;
  where: StandardWhereUniqueInput;
};

export type StandardUpdateWithWhereUniqueWithoutSkillsInput = {
  data: StandardUpdateWithoutSkillsInput;
  where: StandardWhereUniqueInput;
};

export type StandardUpdateWithWhereUniqueWithoutStoriesInput = {
  data: StandardUpdateWithoutStoriesInput;
  where: StandardWhereUniqueInput;
};

export type StandardUpdateWithWhereUniqueWithoutStoryActivitiesInput = {
  data: StandardUpdateWithoutStoryActivitiesInput;
  where: StandardWhereUniqueInput;
};

export type StandardUpdateWithWhereUniqueWithoutUsersInput = {
  data: StandardUpdateWithoutUsersInput;
  where: StandardWhereUniqueInput;
};

export type StandardUpdateWithWhereUniqueWithoutWorkshopsInput = {
  data: StandardUpdateWithoutWorkshopsInput;
  where: StandardWhereUniqueInput;
};

export type StandardUpdateWithoutActivitiesInput = {
  dailyActivities?: InputMaybe<DailyActivityUpdateManyWithoutStandardsNestedInput>;
  key?: InputMaybe<StringFieldUpdateOperationsInput>;
  products?: InputMaybe<ProductUpdateManyWithoutStandardsNestedInput>;
  reports?: InputMaybe<ReportUpdateManyWithoutStandardNestedInput>;
  samskaras?: InputMaybe<SamskaraUpdateManyWithoutStandardNestedInput>;
  schoolUpdates?: InputMaybe<SchoolUpdateUpdateManyWithoutStandardsNestedInput>;
  skills?: InputMaybe<SkillUpdateManyWithoutStandardsNestedInput>;
  stories?: InputMaybe<StoryUpdateManyWithoutStandardsNestedInput>;
  storyActivities?: InputMaybe<StoryActivityUpdateManyWithoutStandardsNestedInput>;
  studentRecords?: InputMaybe<StudentRecordUpdateManyWithoutStandardNestedInput>;
  students?: InputMaybe<StudentUpdateManyWithoutStandardNestedInput>;
  users?: InputMaybe<UserUpdateManyWithoutStandardsNestedInput>;
  value?: InputMaybe<StringFieldUpdateOperationsInput>;
  workshops?: InputMaybe<WorkshopUpdateManyWithoutStandardsNestedInput>;
};

export type StandardUpdateWithoutDailyActivitiesInput = {
  activities?: InputMaybe<ActivityUpdateManyWithoutStandardsNestedInput>;
  key?: InputMaybe<StringFieldUpdateOperationsInput>;
  products?: InputMaybe<ProductUpdateManyWithoutStandardsNestedInput>;
  reports?: InputMaybe<ReportUpdateManyWithoutStandardNestedInput>;
  samskaras?: InputMaybe<SamskaraUpdateManyWithoutStandardNestedInput>;
  schoolUpdates?: InputMaybe<SchoolUpdateUpdateManyWithoutStandardsNestedInput>;
  skills?: InputMaybe<SkillUpdateManyWithoutStandardsNestedInput>;
  stories?: InputMaybe<StoryUpdateManyWithoutStandardsNestedInput>;
  storyActivities?: InputMaybe<StoryActivityUpdateManyWithoutStandardsNestedInput>;
  studentRecords?: InputMaybe<StudentRecordUpdateManyWithoutStandardNestedInput>;
  students?: InputMaybe<StudentUpdateManyWithoutStandardNestedInput>;
  users?: InputMaybe<UserUpdateManyWithoutStandardsNestedInput>;
  value?: InputMaybe<StringFieldUpdateOperationsInput>;
  workshops?: InputMaybe<WorkshopUpdateManyWithoutStandardsNestedInput>;
};

export type StandardUpdateWithoutProductsInput = {
  activities?: InputMaybe<ActivityUpdateManyWithoutStandardsNestedInput>;
  dailyActivities?: InputMaybe<DailyActivityUpdateManyWithoutStandardsNestedInput>;
  key?: InputMaybe<StringFieldUpdateOperationsInput>;
  reports?: InputMaybe<ReportUpdateManyWithoutStandardNestedInput>;
  samskaras?: InputMaybe<SamskaraUpdateManyWithoutStandardNestedInput>;
  schoolUpdates?: InputMaybe<SchoolUpdateUpdateManyWithoutStandardsNestedInput>;
  skills?: InputMaybe<SkillUpdateManyWithoutStandardsNestedInput>;
  stories?: InputMaybe<StoryUpdateManyWithoutStandardsNestedInput>;
  storyActivities?: InputMaybe<StoryActivityUpdateManyWithoutStandardsNestedInput>;
  studentRecords?: InputMaybe<StudentRecordUpdateManyWithoutStandardNestedInput>;
  students?: InputMaybe<StudentUpdateManyWithoutStandardNestedInput>;
  users?: InputMaybe<UserUpdateManyWithoutStandardsNestedInput>;
  value?: InputMaybe<StringFieldUpdateOperationsInput>;
  workshops?: InputMaybe<WorkshopUpdateManyWithoutStandardsNestedInput>;
};

export type StandardUpdateWithoutReportsInput = {
  activities?: InputMaybe<ActivityUpdateManyWithoutStandardsNestedInput>;
  dailyActivities?: InputMaybe<DailyActivityUpdateManyWithoutStandardsNestedInput>;
  key?: InputMaybe<StringFieldUpdateOperationsInput>;
  products?: InputMaybe<ProductUpdateManyWithoutStandardsNestedInput>;
  samskaras?: InputMaybe<SamskaraUpdateManyWithoutStandardNestedInput>;
  schoolUpdates?: InputMaybe<SchoolUpdateUpdateManyWithoutStandardsNestedInput>;
  skills?: InputMaybe<SkillUpdateManyWithoutStandardsNestedInput>;
  stories?: InputMaybe<StoryUpdateManyWithoutStandardsNestedInput>;
  storyActivities?: InputMaybe<StoryActivityUpdateManyWithoutStandardsNestedInput>;
  studentRecords?: InputMaybe<StudentRecordUpdateManyWithoutStandardNestedInput>;
  students?: InputMaybe<StudentUpdateManyWithoutStandardNestedInput>;
  users?: InputMaybe<UserUpdateManyWithoutStandardsNestedInput>;
  value?: InputMaybe<StringFieldUpdateOperationsInput>;
  workshops?: InputMaybe<WorkshopUpdateManyWithoutStandardsNestedInput>;
};

export type StandardUpdateWithoutSamskarasInput = {
  activities?: InputMaybe<ActivityUpdateManyWithoutStandardsNestedInput>;
  dailyActivities?: InputMaybe<DailyActivityUpdateManyWithoutStandardsNestedInput>;
  key?: InputMaybe<StringFieldUpdateOperationsInput>;
  products?: InputMaybe<ProductUpdateManyWithoutStandardsNestedInput>;
  reports?: InputMaybe<ReportUpdateManyWithoutStandardNestedInput>;
  schoolUpdates?: InputMaybe<SchoolUpdateUpdateManyWithoutStandardsNestedInput>;
  skills?: InputMaybe<SkillUpdateManyWithoutStandardsNestedInput>;
  stories?: InputMaybe<StoryUpdateManyWithoutStandardsNestedInput>;
  storyActivities?: InputMaybe<StoryActivityUpdateManyWithoutStandardsNestedInput>;
  studentRecords?: InputMaybe<StudentRecordUpdateManyWithoutStandardNestedInput>;
  students?: InputMaybe<StudentUpdateManyWithoutStandardNestedInput>;
  users?: InputMaybe<UserUpdateManyWithoutStandardsNestedInput>;
  value?: InputMaybe<StringFieldUpdateOperationsInput>;
  workshops?: InputMaybe<WorkshopUpdateManyWithoutStandardsNestedInput>;
};

export type StandardUpdateWithoutSchoolUpdatesInput = {
  activities?: InputMaybe<ActivityUpdateManyWithoutStandardsNestedInput>;
  dailyActivities?: InputMaybe<DailyActivityUpdateManyWithoutStandardsNestedInput>;
  key?: InputMaybe<StringFieldUpdateOperationsInput>;
  products?: InputMaybe<ProductUpdateManyWithoutStandardsNestedInput>;
  reports?: InputMaybe<ReportUpdateManyWithoutStandardNestedInput>;
  samskaras?: InputMaybe<SamskaraUpdateManyWithoutStandardNestedInput>;
  skills?: InputMaybe<SkillUpdateManyWithoutStandardsNestedInput>;
  stories?: InputMaybe<StoryUpdateManyWithoutStandardsNestedInput>;
  storyActivities?: InputMaybe<StoryActivityUpdateManyWithoutStandardsNestedInput>;
  studentRecords?: InputMaybe<StudentRecordUpdateManyWithoutStandardNestedInput>;
  students?: InputMaybe<StudentUpdateManyWithoutStandardNestedInput>;
  users?: InputMaybe<UserUpdateManyWithoutStandardsNestedInput>;
  value?: InputMaybe<StringFieldUpdateOperationsInput>;
  workshops?: InputMaybe<WorkshopUpdateManyWithoutStandardsNestedInput>;
};

export type StandardUpdateWithoutSkillsInput = {
  activities?: InputMaybe<ActivityUpdateManyWithoutStandardsNestedInput>;
  dailyActivities?: InputMaybe<DailyActivityUpdateManyWithoutStandardsNestedInput>;
  key?: InputMaybe<StringFieldUpdateOperationsInput>;
  products?: InputMaybe<ProductUpdateManyWithoutStandardsNestedInput>;
  reports?: InputMaybe<ReportUpdateManyWithoutStandardNestedInput>;
  samskaras?: InputMaybe<SamskaraUpdateManyWithoutStandardNestedInput>;
  schoolUpdates?: InputMaybe<SchoolUpdateUpdateManyWithoutStandardsNestedInput>;
  stories?: InputMaybe<StoryUpdateManyWithoutStandardsNestedInput>;
  storyActivities?: InputMaybe<StoryActivityUpdateManyWithoutStandardsNestedInput>;
  studentRecords?: InputMaybe<StudentRecordUpdateManyWithoutStandardNestedInput>;
  students?: InputMaybe<StudentUpdateManyWithoutStandardNestedInput>;
  users?: InputMaybe<UserUpdateManyWithoutStandardsNestedInput>;
  value?: InputMaybe<StringFieldUpdateOperationsInput>;
  workshops?: InputMaybe<WorkshopUpdateManyWithoutStandardsNestedInput>;
};

export type StandardUpdateWithoutStoriesInput = {
  activities?: InputMaybe<ActivityUpdateManyWithoutStandardsNestedInput>;
  dailyActivities?: InputMaybe<DailyActivityUpdateManyWithoutStandardsNestedInput>;
  key?: InputMaybe<StringFieldUpdateOperationsInput>;
  products?: InputMaybe<ProductUpdateManyWithoutStandardsNestedInput>;
  reports?: InputMaybe<ReportUpdateManyWithoutStandardNestedInput>;
  samskaras?: InputMaybe<SamskaraUpdateManyWithoutStandardNestedInput>;
  schoolUpdates?: InputMaybe<SchoolUpdateUpdateManyWithoutStandardsNestedInput>;
  skills?: InputMaybe<SkillUpdateManyWithoutStandardsNestedInput>;
  storyActivities?: InputMaybe<StoryActivityUpdateManyWithoutStandardsNestedInput>;
  studentRecords?: InputMaybe<StudentRecordUpdateManyWithoutStandardNestedInput>;
  students?: InputMaybe<StudentUpdateManyWithoutStandardNestedInput>;
  users?: InputMaybe<UserUpdateManyWithoutStandardsNestedInput>;
  value?: InputMaybe<StringFieldUpdateOperationsInput>;
  workshops?: InputMaybe<WorkshopUpdateManyWithoutStandardsNestedInput>;
};

export type StandardUpdateWithoutStoryActivitiesInput = {
  activities?: InputMaybe<ActivityUpdateManyWithoutStandardsNestedInput>;
  dailyActivities?: InputMaybe<DailyActivityUpdateManyWithoutStandardsNestedInput>;
  key?: InputMaybe<StringFieldUpdateOperationsInput>;
  products?: InputMaybe<ProductUpdateManyWithoutStandardsNestedInput>;
  reports?: InputMaybe<ReportUpdateManyWithoutStandardNestedInput>;
  samskaras?: InputMaybe<SamskaraUpdateManyWithoutStandardNestedInput>;
  schoolUpdates?: InputMaybe<SchoolUpdateUpdateManyWithoutStandardsNestedInput>;
  skills?: InputMaybe<SkillUpdateManyWithoutStandardsNestedInput>;
  stories?: InputMaybe<StoryUpdateManyWithoutStandardsNestedInput>;
  studentRecords?: InputMaybe<StudentRecordUpdateManyWithoutStandardNestedInput>;
  students?: InputMaybe<StudentUpdateManyWithoutStandardNestedInput>;
  users?: InputMaybe<UserUpdateManyWithoutStandardsNestedInput>;
  value?: InputMaybe<StringFieldUpdateOperationsInput>;
  workshops?: InputMaybe<WorkshopUpdateManyWithoutStandardsNestedInput>;
};

export type StandardUpdateWithoutStudentRecordsInput = {
  activities?: InputMaybe<ActivityUpdateManyWithoutStandardsNestedInput>;
  dailyActivities?: InputMaybe<DailyActivityUpdateManyWithoutStandardsNestedInput>;
  key?: InputMaybe<StringFieldUpdateOperationsInput>;
  products?: InputMaybe<ProductUpdateManyWithoutStandardsNestedInput>;
  reports?: InputMaybe<ReportUpdateManyWithoutStandardNestedInput>;
  samskaras?: InputMaybe<SamskaraUpdateManyWithoutStandardNestedInput>;
  schoolUpdates?: InputMaybe<SchoolUpdateUpdateManyWithoutStandardsNestedInput>;
  skills?: InputMaybe<SkillUpdateManyWithoutStandardsNestedInput>;
  stories?: InputMaybe<StoryUpdateManyWithoutStandardsNestedInput>;
  storyActivities?: InputMaybe<StoryActivityUpdateManyWithoutStandardsNestedInput>;
  students?: InputMaybe<StudentUpdateManyWithoutStandardNestedInput>;
  users?: InputMaybe<UserUpdateManyWithoutStandardsNestedInput>;
  value?: InputMaybe<StringFieldUpdateOperationsInput>;
  workshops?: InputMaybe<WorkshopUpdateManyWithoutStandardsNestedInput>;
};

export type StandardUpdateWithoutStudentsInput = {
  activities?: InputMaybe<ActivityUpdateManyWithoutStandardsNestedInput>;
  dailyActivities?: InputMaybe<DailyActivityUpdateManyWithoutStandardsNestedInput>;
  key?: InputMaybe<StringFieldUpdateOperationsInput>;
  products?: InputMaybe<ProductUpdateManyWithoutStandardsNestedInput>;
  reports?: InputMaybe<ReportUpdateManyWithoutStandardNestedInput>;
  samskaras?: InputMaybe<SamskaraUpdateManyWithoutStandardNestedInput>;
  schoolUpdates?: InputMaybe<SchoolUpdateUpdateManyWithoutStandardsNestedInput>;
  skills?: InputMaybe<SkillUpdateManyWithoutStandardsNestedInput>;
  stories?: InputMaybe<StoryUpdateManyWithoutStandardsNestedInput>;
  storyActivities?: InputMaybe<StoryActivityUpdateManyWithoutStandardsNestedInput>;
  studentRecords?: InputMaybe<StudentRecordUpdateManyWithoutStandardNestedInput>;
  users?: InputMaybe<UserUpdateManyWithoutStandardsNestedInput>;
  value?: InputMaybe<StringFieldUpdateOperationsInput>;
  workshops?: InputMaybe<WorkshopUpdateManyWithoutStandardsNestedInput>;
};

export type StandardUpdateWithoutUsersInput = {
  activities?: InputMaybe<ActivityUpdateManyWithoutStandardsNestedInput>;
  dailyActivities?: InputMaybe<DailyActivityUpdateManyWithoutStandardsNestedInput>;
  key?: InputMaybe<StringFieldUpdateOperationsInput>;
  products?: InputMaybe<ProductUpdateManyWithoutStandardsNestedInput>;
  reports?: InputMaybe<ReportUpdateManyWithoutStandardNestedInput>;
  samskaras?: InputMaybe<SamskaraUpdateManyWithoutStandardNestedInput>;
  schoolUpdates?: InputMaybe<SchoolUpdateUpdateManyWithoutStandardsNestedInput>;
  skills?: InputMaybe<SkillUpdateManyWithoutStandardsNestedInput>;
  stories?: InputMaybe<StoryUpdateManyWithoutStandardsNestedInput>;
  storyActivities?: InputMaybe<StoryActivityUpdateManyWithoutStandardsNestedInput>;
  studentRecords?: InputMaybe<StudentRecordUpdateManyWithoutStandardNestedInput>;
  students?: InputMaybe<StudentUpdateManyWithoutStandardNestedInput>;
  value?: InputMaybe<StringFieldUpdateOperationsInput>;
  workshops?: InputMaybe<WorkshopUpdateManyWithoutStandardsNestedInput>;
};

export type StandardUpdateWithoutWorkshopsInput = {
  activities?: InputMaybe<ActivityUpdateManyWithoutStandardsNestedInput>;
  dailyActivities?: InputMaybe<DailyActivityUpdateManyWithoutStandardsNestedInput>;
  key?: InputMaybe<StringFieldUpdateOperationsInput>;
  products?: InputMaybe<ProductUpdateManyWithoutStandardsNestedInput>;
  reports?: InputMaybe<ReportUpdateManyWithoutStandardNestedInput>;
  samskaras?: InputMaybe<SamskaraUpdateManyWithoutStandardNestedInput>;
  schoolUpdates?: InputMaybe<SchoolUpdateUpdateManyWithoutStandardsNestedInput>;
  skills?: InputMaybe<SkillUpdateManyWithoutStandardsNestedInput>;
  stories?: InputMaybe<StoryUpdateManyWithoutStandardsNestedInput>;
  storyActivities?: InputMaybe<StoryActivityUpdateManyWithoutStandardsNestedInput>;
  studentRecords?: InputMaybe<StudentRecordUpdateManyWithoutStandardNestedInput>;
  students?: InputMaybe<StudentUpdateManyWithoutStandardNestedInput>;
  users?: InputMaybe<UserUpdateManyWithoutStandardsNestedInput>;
  value?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type StandardUpsertWithWhereUniqueWithoutActivitiesInput = {
  create: StandardCreateWithoutActivitiesInput;
  update: StandardUpdateWithoutActivitiesInput;
  where: StandardWhereUniqueInput;
};

export type StandardUpsertWithWhereUniqueWithoutDailyActivitiesInput = {
  create: StandardCreateWithoutDailyActivitiesInput;
  update: StandardUpdateWithoutDailyActivitiesInput;
  where: StandardWhereUniqueInput;
};

export type StandardUpsertWithWhereUniqueWithoutProductsInput = {
  create: StandardCreateWithoutProductsInput;
  update: StandardUpdateWithoutProductsInput;
  where: StandardWhereUniqueInput;
};

export type StandardUpsertWithWhereUniqueWithoutSchoolUpdatesInput = {
  create: StandardCreateWithoutSchoolUpdatesInput;
  update: StandardUpdateWithoutSchoolUpdatesInput;
  where: StandardWhereUniqueInput;
};

export type StandardUpsertWithWhereUniqueWithoutSkillsInput = {
  create: StandardCreateWithoutSkillsInput;
  update: StandardUpdateWithoutSkillsInput;
  where: StandardWhereUniqueInput;
};

export type StandardUpsertWithWhereUniqueWithoutStoriesInput = {
  create: StandardCreateWithoutStoriesInput;
  update: StandardUpdateWithoutStoriesInput;
  where: StandardWhereUniqueInput;
};

export type StandardUpsertWithWhereUniqueWithoutStoryActivitiesInput = {
  create: StandardCreateWithoutStoryActivitiesInput;
  update: StandardUpdateWithoutStoryActivitiesInput;
  where: StandardWhereUniqueInput;
};

export type StandardUpsertWithWhereUniqueWithoutUsersInput = {
  create: StandardCreateWithoutUsersInput;
  update: StandardUpdateWithoutUsersInput;
  where: StandardWhereUniqueInput;
};

export type StandardUpsertWithWhereUniqueWithoutWorkshopsInput = {
  create: StandardCreateWithoutWorkshopsInput;
  update: StandardUpdateWithoutWorkshopsInput;
  where: StandardWhereUniqueInput;
};

export type StandardUpsertWithoutReportsInput = {
  create: StandardCreateWithoutReportsInput;
  update: StandardUpdateWithoutReportsInput;
};

export type StandardUpsertWithoutSamskarasInput = {
  create: StandardCreateWithoutSamskarasInput;
  update: StandardUpdateWithoutSamskarasInput;
};

export type StandardUpsertWithoutStudentRecordsInput = {
  create: StandardCreateWithoutStudentRecordsInput;
  update: StandardUpdateWithoutStudentRecordsInput;
};

export type StandardUpsertWithoutStudentsInput = {
  create: StandardCreateWithoutStudentsInput;
  update: StandardUpdateWithoutStudentsInput;
};

export type StandardWhereInput = {
  AND?: InputMaybe<Array<StandardWhereInput>>;
  NOT?: InputMaybe<Array<StandardWhereInput>>;
  OR?: InputMaybe<Array<StandardWhereInput>>;
  activities?: InputMaybe<ActivityListRelationFilter>;
  dailyActivities?: InputMaybe<DailyActivityListRelationFilter>;
  id?: InputMaybe<IntFilter>;
  key?: InputMaybe<StringFilter>;
  products?: InputMaybe<ProductListRelationFilter>;
  reports?: InputMaybe<ReportListRelationFilter>;
  samskaras?: InputMaybe<SamskaraListRelationFilter>;
  schoolUpdates?: InputMaybe<SchoolUpdateListRelationFilter>;
  skills?: InputMaybe<SkillListRelationFilter>;
  stories?: InputMaybe<StoryListRelationFilter>;
  storyActivities?: InputMaybe<StoryActivityListRelationFilter>;
  studentRecords?: InputMaybe<StudentRecordListRelationFilter>;
  students?: InputMaybe<StudentListRelationFilter>;
  users?: InputMaybe<UserListRelationFilter>;
  value?: InputMaybe<StringFilter>;
  workshops?: InputMaybe<WorkshopListRelationFilter>;
};

export type StandardWhereUniqueInput = {
  id?: InputMaybe<Scalars["Int"]["input"]>;
  key?: InputMaybe<Scalars["String"]["input"]>;
};

export enum Status {
  DRAFT = "DRAFT",
  PENDING_REVIEW = "PENDING_REVIEW",
  PUBLISHED = "PUBLISHED"
}

export type Story = {
  __typename?: "Story";
  _count?: Maybe<StoryCount>;
  activities: Array<StoryActivity>;
  createdAt: Scalars["DateTime"]["output"];
  description?: Maybe<Scalars["String"]["output"]>;
  displayOrder: Scalars["Int"]["output"];
  featuredImage?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["Int"]["output"];
  image?: Maybe<Scalars["String"]["output"]>;
  isActive: Scalars["Boolean"]["output"];
  school?: Maybe<School>;
  schoolId?: Maybe<Scalars["Int"]["output"]>;
  slug: Scalars["String"]["output"];
  standards: Array<Standard>;
  tag?: Maybe<StoryTag>;
  tagId?: Maybe<Scalars["Int"]["output"]>;
  title: Scalars["String"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};


export type StoryActivitiesArgs = {
  cursor?: InputMaybe<StoryActivityWhereUniqueInput>;
  distinct?: InputMaybe<Array<StoryActivityScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StoryActivityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<StoryActivityWhereInput>;
};


export type StoryStandardsArgs = {
  cursor?: InputMaybe<StandardWhereUniqueInput>;
  distinct?: InputMaybe<Array<StandardScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StandardOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<StandardWhereInput>;
};

export type StoryActivity = {
  __typename?: "StoryActivity";
  _count?: Maybe<StoryActivityCount>;
  audio?: Maybe<Scalars["String"]["output"]>;
  challengeStatement: Scalars["String"]["output"];
  coinsToSpend: Scalars["Int"]["output"];
  comments: Array<Comment>;
  content: Scalars["String"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  displayOrder: Scalars["Int"]["output"];
  featuredImage?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["Int"]["output"];
  image?: Maybe<Scalars["String"]["output"]>;
  isCommentEnabled: Scalars["Boolean"]["output"];
  isFeatured: Scalars["Boolean"]["output"];
  meta?: Maybe<Scalars["JSON"]["output"]>;
  products: Array<Product>;
  promoCodes: Array<PromoCode>;
  publishedDate?: Maybe<Scalars["DateTime"]["output"]>;
  questionXps: Scalars["Int"]["output"];
  questions: Array<ActivityQuestion>;
  skill: Skill;
  skillId: Scalars["Int"]["output"];
  slug: Scalars["String"]["output"];
  standards: Array<Standard>;
  status: ActivityStatus;
  story: Story;
  storyId: Scalars["Int"]["output"];
  timeRequired: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
  video?: Maybe<Scalars["String"]["output"]>;
  xps: Scalars["Int"]["output"];
};


export type StoryActivityCommentsArgs = {
  cursor?: InputMaybe<CommentWhereUniqueInput>;
  distinct?: InputMaybe<Array<CommentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CommentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<CommentWhereInput>;
};


export type StoryActivityProductsArgs = {
  cursor?: InputMaybe<ProductWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProductScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProductOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ProductWhereInput>;
};


export type StoryActivityPromoCodesArgs = {
  cursor?: InputMaybe<PromoCodeWhereUniqueInput>;
  distinct?: InputMaybe<Array<PromoCodeScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PromoCodeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<PromoCodeWhereInput>;
};


export type StoryActivityQuestionsArgs = {
  cursor?: InputMaybe<ActivityQuestionWhereUniqueInput>;
  distinct?: InputMaybe<Array<ActivityQuestionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ActivityQuestionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ActivityQuestionWhereInput>;
};


export type StoryActivityStandardsArgs = {
  cursor?: InputMaybe<StandardWhereUniqueInput>;
  distinct?: InputMaybe<Array<StandardScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StandardOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<StandardWhereInput>;
};

export type StoryActivityAvgAggregate = {
  __typename?: "StoryActivityAvgAggregate";
  coinsToSpend?: Maybe<Scalars["Float"]["output"]>;
  displayOrder?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  questionXps?: Maybe<Scalars["Float"]["output"]>;
  skillId?: Maybe<Scalars["Float"]["output"]>;
  storyId?: Maybe<Scalars["Float"]["output"]>;
  xps?: Maybe<Scalars["Float"]["output"]>;
};

export type StoryActivityCount = {
  __typename?: "StoryActivityCount";
  comments: Scalars["Int"]["output"];
  products: Scalars["Int"]["output"];
  promoCodes: Scalars["Int"]["output"];
  questions: Scalars["Int"]["output"];
  standards: Scalars["Int"]["output"];
};


export type StoryActivityCountCommentsArgs = {
  where?: InputMaybe<CommentWhereInput>;
};


export type StoryActivityCountProductsArgs = {
  where?: InputMaybe<ProductWhereInput>;
};


export type StoryActivityCountPromoCodesArgs = {
  where?: InputMaybe<PromoCodeWhereInput>;
};


export type StoryActivityCountQuestionsArgs = {
  where?: InputMaybe<ActivityQuestionWhereInput>;
};


export type StoryActivityCountStandardsArgs = {
  where?: InputMaybe<StandardWhereInput>;
};

export type StoryActivityCountAggregate = {
  __typename?: "StoryActivityCountAggregate";
  _all: Scalars["Int"]["output"];
  audio: Scalars["Int"]["output"];
  challengeStatement: Scalars["Int"]["output"];
  coinsToSpend: Scalars["Int"]["output"];
  content: Scalars["Int"]["output"];
  createdAt: Scalars["Int"]["output"];
  displayOrder: Scalars["Int"]["output"];
  featuredImage: Scalars["Int"]["output"];
  id: Scalars["Int"]["output"];
  image: Scalars["Int"]["output"];
  isCommentEnabled: Scalars["Int"]["output"];
  isFeatured: Scalars["Int"]["output"];
  meta: Scalars["Int"]["output"];
  publishedDate: Scalars["Int"]["output"];
  questionXps: Scalars["Int"]["output"];
  skillId: Scalars["Int"]["output"];
  slug: Scalars["Int"]["output"];
  status: Scalars["Int"]["output"];
  storyId: Scalars["Int"]["output"];
  timeRequired: Scalars["Int"]["output"];
  title: Scalars["Int"]["output"];
  updatedAt: Scalars["Int"]["output"];
  video: Scalars["Int"]["output"];
  xps: Scalars["Int"]["output"];
};

export type StoryActivityCreateInput = {
  audio?: InputMaybe<Scalars["String"]["input"]>;
  challengeStatement: Scalars["String"]["input"];
  coinsToSpend?: InputMaybe<Scalars["Int"]["input"]>;
  comments?: InputMaybe<CommentCreateNestedManyWithoutStoryActivityInput>;
  content: Scalars["String"]["input"];
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  displayOrder?: InputMaybe<Scalars["Int"]["input"]>;
  featuredImage?: InputMaybe<Scalars["String"]["input"]>;
  image?: InputMaybe<Scalars["String"]["input"]>;
  isCommentEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  isFeatured?: InputMaybe<Scalars["Boolean"]["input"]>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  products?: InputMaybe<ProductCreateNestedManyWithoutRelatedStoryActivitiesInput>;
  promoCodes?: InputMaybe<PromoCodeCreateNestedManyWithoutStoryActivitiesInput>;
  publishedDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  questionXps?: InputMaybe<Scalars["Int"]["input"]>;
  questions?: InputMaybe<ActivityQuestionCreateNestedManyWithoutStoryActivityInput>;
  skill: SkillCreateNestedOneWithoutStoryActivitiesInput;
  slug: Scalars["String"]["input"];
  standards?: InputMaybe<StandardCreateNestedManyWithoutStoryActivitiesInput>;
  status?: InputMaybe<ActivityStatus>;
  story: StoryCreateNestedOneWithoutActivitiesInput;
  timeRequired: Scalars["String"]["input"];
  title: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  video?: InputMaybe<Scalars["String"]["input"]>;
  xps?: InputMaybe<Scalars["Int"]["input"]>;
};

export type StoryActivityCreateManySkillInput = {
  audio?: InputMaybe<Scalars["String"]["input"]>;
  challengeStatement: Scalars["String"]["input"];
  coinsToSpend?: InputMaybe<Scalars["Int"]["input"]>;
  content: Scalars["String"]["input"];
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  displayOrder?: InputMaybe<Scalars["Int"]["input"]>;
  featuredImage?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  image?: InputMaybe<Scalars["String"]["input"]>;
  isCommentEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  isFeatured?: InputMaybe<Scalars["Boolean"]["input"]>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  publishedDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  questionXps?: InputMaybe<Scalars["Int"]["input"]>;
  slug: Scalars["String"]["input"];
  status?: InputMaybe<ActivityStatus>;
  storyId: Scalars["Int"]["input"];
  timeRequired: Scalars["String"]["input"];
  title: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  video?: InputMaybe<Scalars["String"]["input"]>;
  xps?: InputMaybe<Scalars["Int"]["input"]>;
};

export type StoryActivityCreateManySkillInputEnvelope = {
  data: Array<StoryActivityCreateManySkillInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type StoryActivityCreateManyStoryInput = {
  audio?: InputMaybe<Scalars["String"]["input"]>;
  challengeStatement: Scalars["String"]["input"];
  coinsToSpend?: InputMaybe<Scalars["Int"]["input"]>;
  content: Scalars["String"]["input"];
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  displayOrder?: InputMaybe<Scalars["Int"]["input"]>;
  featuredImage?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  image?: InputMaybe<Scalars["String"]["input"]>;
  isCommentEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  isFeatured?: InputMaybe<Scalars["Boolean"]["input"]>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  publishedDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  questionXps?: InputMaybe<Scalars["Int"]["input"]>;
  skillId: Scalars["Int"]["input"];
  slug: Scalars["String"]["input"];
  status?: InputMaybe<ActivityStatus>;
  timeRequired: Scalars["String"]["input"];
  title: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  video?: InputMaybe<Scalars["String"]["input"]>;
  xps?: InputMaybe<Scalars["Int"]["input"]>;
};

export type StoryActivityCreateManyStoryInputEnvelope = {
  data: Array<StoryActivityCreateManyStoryInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type StoryActivityCreateNestedManyWithoutProductsInput = {
  connect?: InputMaybe<Array<StoryActivityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StoryActivityCreateOrConnectWithoutProductsInput>>;
  create?: InputMaybe<Array<StoryActivityCreateWithoutProductsInput>>;
};

export type StoryActivityCreateNestedManyWithoutPromoCodesInput = {
  connect?: InputMaybe<Array<StoryActivityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StoryActivityCreateOrConnectWithoutPromoCodesInput>>;
  create?: InputMaybe<Array<StoryActivityCreateWithoutPromoCodesInput>>;
};

export type StoryActivityCreateNestedManyWithoutSkillInput = {
  connect?: InputMaybe<Array<StoryActivityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StoryActivityCreateOrConnectWithoutSkillInput>>;
  create?: InputMaybe<Array<StoryActivityCreateWithoutSkillInput>>;
  createMany?: InputMaybe<StoryActivityCreateManySkillInputEnvelope>;
};

export type StoryActivityCreateNestedManyWithoutStandardsInput = {
  connect?: InputMaybe<Array<StoryActivityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StoryActivityCreateOrConnectWithoutStandardsInput>>;
  create?: InputMaybe<Array<StoryActivityCreateWithoutStandardsInput>>;
};

export type StoryActivityCreateNestedManyWithoutStoryInput = {
  connect?: InputMaybe<Array<StoryActivityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StoryActivityCreateOrConnectWithoutStoryInput>>;
  create?: InputMaybe<Array<StoryActivityCreateWithoutStoryInput>>;
  createMany?: InputMaybe<StoryActivityCreateManyStoryInputEnvelope>;
};

export type StoryActivityCreateNestedOneWithoutCommentsInput = {
  connect?: InputMaybe<StoryActivityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StoryActivityCreateOrConnectWithoutCommentsInput>;
  create?: InputMaybe<StoryActivityCreateWithoutCommentsInput>;
};

export type StoryActivityCreateNestedOneWithoutQuestionsInput = {
  connect?: InputMaybe<StoryActivityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StoryActivityCreateOrConnectWithoutQuestionsInput>;
  create?: InputMaybe<StoryActivityCreateWithoutQuestionsInput>;
};

export type StoryActivityCreateOrConnectWithoutCommentsInput = {
  create: StoryActivityCreateWithoutCommentsInput;
  where: StoryActivityWhereUniqueInput;
};

export type StoryActivityCreateOrConnectWithoutProductsInput = {
  create: StoryActivityCreateWithoutProductsInput;
  where: StoryActivityWhereUniqueInput;
};

export type StoryActivityCreateOrConnectWithoutPromoCodesInput = {
  create: StoryActivityCreateWithoutPromoCodesInput;
  where: StoryActivityWhereUniqueInput;
};

export type StoryActivityCreateOrConnectWithoutQuestionsInput = {
  create: StoryActivityCreateWithoutQuestionsInput;
  where: StoryActivityWhereUniqueInput;
};

export type StoryActivityCreateOrConnectWithoutSkillInput = {
  create: StoryActivityCreateWithoutSkillInput;
  where: StoryActivityWhereUniqueInput;
};

export type StoryActivityCreateOrConnectWithoutStandardsInput = {
  create: StoryActivityCreateWithoutStandardsInput;
  where: StoryActivityWhereUniqueInput;
};

export type StoryActivityCreateOrConnectWithoutStoryInput = {
  create: StoryActivityCreateWithoutStoryInput;
  where: StoryActivityWhereUniqueInput;
};

export type StoryActivityCreateWithoutCommentsInput = {
  audio?: InputMaybe<Scalars["String"]["input"]>;
  challengeStatement: Scalars["String"]["input"];
  coinsToSpend?: InputMaybe<Scalars["Int"]["input"]>;
  content: Scalars["String"]["input"];
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  displayOrder?: InputMaybe<Scalars["Int"]["input"]>;
  featuredImage?: InputMaybe<Scalars["String"]["input"]>;
  image?: InputMaybe<Scalars["String"]["input"]>;
  isCommentEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  isFeatured?: InputMaybe<Scalars["Boolean"]["input"]>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  products?: InputMaybe<ProductCreateNestedManyWithoutRelatedStoryActivitiesInput>;
  promoCodes?: InputMaybe<PromoCodeCreateNestedManyWithoutStoryActivitiesInput>;
  publishedDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  questionXps?: InputMaybe<Scalars["Int"]["input"]>;
  questions?: InputMaybe<ActivityQuestionCreateNestedManyWithoutStoryActivityInput>;
  skill: SkillCreateNestedOneWithoutStoryActivitiesInput;
  slug: Scalars["String"]["input"];
  standards?: InputMaybe<StandardCreateNestedManyWithoutStoryActivitiesInput>;
  status?: InputMaybe<ActivityStatus>;
  story: StoryCreateNestedOneWithoutActivitiesInput;
  timeRequired: Scalars["String"]["input"];
  title: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  video?: InputMaybe<Scalars["String"]["input"]>;
  xps?: InputMaybe<Scalars["Int"]["input"]>;
};

export type StoryActivityCreateWithoutProductsInput = {
  audio?: InputMaybe<Scalars["String"]["input"]>;
  challengeStatement: Scalars["String"]["input"];
  coinsToSpend?: InputMaybe<Scalars["Int"]["input"]>;
  comments?: InputMaybe<CommentCreateNestedManyWithoutStoryActivityInput>;
  content: Scalars["String"]["input"];
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  displayOrder?: InputMaybe<Scalars["Int"]["input"]>;
  featuredImage?: InputMaybe<Scalars["String"]["input"]>;
  image?: InputMaybe<Scalars["String"]["input"]>;
  isCommentEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  isFeatured?: InputMaybe<Scalars["Boolean"]["input"]>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  promoCodes?: InputMaybe<PromoCodeCreateNestedManyWithoutStoryActivitiesInput>;
  publishedDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  questionXps?: InputMaybe<Scalars["Int"]["input"]>;
  questions?: InputMaybe<ActivityQuestionCreateNestedManyWithoutStoryActivityInput>;
  skill: SkillCreateNestedOneWithoutStoryActivitiesInput;
  slug: Scalars["String"]["input"];
  standards?: InputMaybe<StandardCreateNestedManyWithoutStoryActivitiesInput>;
  status?: InputMaybe<ActivityStatus>;
  story: StoryCreateNestedOneWithoutActivitiesInput;
  timeRequired: Scalars["String"]["input"];
  title: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  video?: InputMaybe<Scalars["String"]["input"]>;
  xps?: InputMaybe<Scalars["Int"]["input"]>;
};

export type StoryActivityCreateWithoutPromoCodesInput = {
  audio?: InputMaybe<Scalars["String"]["input"]>;
  challengeStatement: Scalars["String"]["input"];
  coinsToSpend?: InputMaybe<Scalars["Int"]["input"]>;
  comments?: InputMaybe<CommentCreateNestedManyWithoutStoryActivityInput>;
  content: Scalars["String"]["input"];
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  displayOrder?: InputMaybe<Scalars["Int"]["input"]>;
  featuredImage?: InputMaybe<Scalars["String"]["input"]>;
  image?: InputMaybe<Scalars["String"]["input"]>;
  isCommentEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  isFeatured?: InputMaybe<Scalars["Boolean"]["input"]>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  products?: InputMaybe<ProductCreateNestedManyWithoutRelatedStoryActivitiesInput>;
  publishedDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  questionXps?: InputMaybe<Scalars["Int"]["input"]>;
  questions?: InputMaybe<ActivityQuestionCreateNestedManyWithoutStoryActivityInput>;
  skill: SkillCreateNestedOneWithoutStoryActivitiesInput;
  slug: Scalars["String"]["input"];
  standards?: InputMaybe<StandardCreateNestedManyWithoutStoryActivitiesInput>;
  status?: InputMaybe<ActivityStatus>;
  story: StoryCreateNestedOneWithoutActivitiesInput;
  timeRequired: Scalars["String"]["input"];
  title: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  video?: InputMaybe<Scalars["String"]["input"]>;
  xps?: InputMaybe<Scalars["Int"]["input"]>;
};

export type StoryActivityCreateWithoutQuestionsInput = {
  audio?: InputMaybe<Scalars["String"]["input"]>;
  challengeStatement: Scalars["String"]["input"];
  coinsToSpend?: InputMaybe<Scalars["Int"]["input"]>;
  comments?: InputMaybe<CommentCreateNestedManyWithoutStoryActivityInput>;
  content: Scalars["String"]["input"];
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  displayOrder?: InputMaybe<Scalars["Int"]["input"]>;
  featuredImage?: InputMaybe<Scalars["String"]["input"]>;
  image?: InputMaybe<Scalars["String"]["input"]>;
  isCommentEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  isFeatured?: InputMaybe<Scalars["Boolean"]["input"]>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  products?: InputMaybe<ProductCreateNestedManyWithoutRelatedStoryActivitiesInput>;
  promoCodes?: InputMaybe<PromoCodeCreateNestedManyWithoutStoryActivitiesInput>;
  publishedDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  questionXps?: InputMaybe<Scalars["Int"]["input"]>;
  skill: SkillCreateNestedOneWithoutStoryActivitiesInput;
  slug: Scalars["String"]["input"];
  standards?: InputMaybe<StandardCreateNestedManyWithoutStoryActivitiesInput>;
  status?: InputMaybe<ActivityStatus>;
  story: StoryCreateNestedOneWithoutActivitiesInput;
  timeRequired: Scalars["String"]["input"];
  title: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  video?: InputMaybe<Scalars["String"]["input"]>;
  xps?: InputMaybe<Scalars["Int"]["input"]>;
};

export type StoryActivityCreateWithoutSkillInput = {
  audio?: InputMaybe<Scalars["String"]["input"]>;
  challengeStatement: Scalars["String"]["input"];
  coinsToSpend?: InputMaybe<Scalars["Int"]["input"]>;
  comments?: InputMaybe<CommentCreateNestedManyWithoutStoryActivityInput>;
  content: Scalars["String"]["input"];
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  displayOrder?: InputMaybe<Scalars["Int"]["input"]>;
  featuredImage?: InputMaybe<Scalars["String"]["input"]>;
  image?: InputMaybe<Scalars["String"]["input"]>;
  isCommentEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  isFeatured?: InputMaybe<Scalars["Boolean"]["input"]>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  products?: InputMaybe<ProductCreateNestedManyWithoutRelatedStoryActivitiesInput>;
  promoCodes?: InputMaybe<PromoCodeCreateNestedManyWithoutStoryActivitiesInput>;
  publishedDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  questionXps?: InputMaybe<Scalars["Int"]["input"]>;
  questions?: InputMaybe<ActivityQuestionCreateNestedManyWithoutStoryActivityInput>;
  slug: Scalars["String"]["input"];
  standards?: InputMaybe<StandardCreateNestedManyWithoutStoryActivitiesInput>;
  status?: InputMaybe<ActivityStatus>;
  story: StoryCreateNestedOneWithoutActivitiesInput;
  timeRequired: Scalars["String"]["input"];
  title: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  video?: InputMaybe<Scalars["String"]["input"]>;
  xps?: InputMaybe<Scalars["Int"]["input"]>;
};

export type StoryActivityCreateWithoutStandardsInput = {
  audio?: InputMaybe<Scalars["String"]["input"]>;
  challengeStatement: Scalars["String"]["input"];
  coinsToSpend?: InputMaybe<Scalars["Int"]["input"]>;
  comments?: InputMaybe<CommentCreateNestedManyWithoutStoryActivityInput>;
  content: Scalars["String"]["input"];
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  displayOrder?: InputMaybe<Scalars["Int"]["input"]>;
  featuredImage?: InputMaybe<Scalars["String"]["input"]>;
  image?: InputMaybe<Scalars["String"]["input"]>;
  isCommentEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  isFeatured?: InputMaybe<Scalars["Boolean"]["input"]>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  products?: InputMaybe<ProductCreateNestedManyWithoutRelatedStoryActivitiesInput>;
  promoCodes?: InputMaybe<PromoCodeCreateNestedManyWithoutStoryActivitiesInput>;
  publishedDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  questionXps?: InputMaybe<Scalars["Int"]["input"]>;
  questions?: InputMaybe<ActivityQuestionCreateNestedManyWithoutStoryActivityInput>;
  skill: SkillCreateNestedOneWithoutStoryActivitiesInput;
  slug: Scalars["String"]["input"];
  status?: InputMaybe<ActivityStatus>;
  story: StoryCreateNestedOneWithoutActivitiesInput;
  timeRequired: Scalars["String"]["input"];
  title: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  video?: InputMaybe<Scalars["String"]["input"]>;
  xps?: InputMaybe<Scalars["Int"]["input"]>;
};

export type StoryActivityCreateWithoutStoryInput = {
  audio?: InputMaybe<Scalars["String"]["input"]>;
  challengeStatement: Scalars["String"]["input"];
  coinsToSpend?: InputMaybe<Scalars["Int"]["input"]>;
  comments?: InputMaybe<CommentCreateNestedManyWithoutStoryActivityInput>;
  content: Scalars["String"]["input"];
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  displayOrder?: InputMaybe<Scalars["Int"]["input"]>;
  featuredImage?: InputMaybe<Scalars["String"]["input"]>;
  image?: InputMaybe<Scalars["String"]["input"]>;
  isCommentEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  isFeatured?: InputMaybe<Scalars["Boolean"]["input"]>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  products?: InputMaybe<ProductCreateNestedManyWithoutRelatedStoryActivitiesInput>;
  promoCodes?: InputMaybe<PromoCodeCreateNestedManyWithoutStoryActivitiesInput>;
  publishedDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  questionXps?: InputMaybe<Scalars["Int"]["input"]>;
  questions?: InputMaybe<ActivityQuestionCreateNestedManyWithoutStoryActivityInput>;
  skill: SkillCreateNestedOneWithoutStoryActivitiesInput;
  slug: Scalars["String"]["input"];
  standards?: InputMaybe<StandardCreateNestedManyWithoutStoryActivitiesInput>;
  status?: InputMaybe<ActivityStatus>;
  timeRequired: Scalars["String"]["input"];
  title: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  video?: InputMaybe<Scalars["String"]["input"]>;
  xps?: InputMaybe<Scalars["Int"]["input"]>;
};

export type StoryActivityListRelationFilter = {
  every?: InputMaybe<StoryActivityWhereInput>;
  none?: InputMaybe<StoryActivityWhereInput>;
  some?: InputMaybe<StoryActivityWhereInput>;
};

export type StoryActivityMaxAggregate = {
  __typename?: "StoryActivityMaxAggregate";
  audio?: Maybe<Scalars["String"]["output"]>;
  challengeStatement?: Maybe<Scalars["String"]["output"]>;
  coinsToSpend?: Maybe<Scalars["Int"]["output"]>;
  content?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  displayOrder?: Maybe<Scalars["Int"]["output"]>;
  featuredImage?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  image?: Maybe<Scalars["String"]["output"]>;
  isCommentEnabled?: Maybe<Scalars["Boolean"]["output"]>;
  isFeatured?: Maybe<Scalars["Boolean"]["output"]>;
  publishedDate?: Maybe<Scalars["DateTime"]["output"]>;
  questionXps?: Maybe<Scalars["Int"]["output"]>;
  skillId?: Maybe<Scalars["Int"]["output"]>;
  slug?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<ActivityStatus>;
  storyId?: Maybe<Scalars["Int"]["output"]>;
  timeRequired?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
  video?: Maybe<Scalars["String"]["output"]>;
  xps?: Maybe<Scalars["Int"]["output"]>;
};

export type StoryActivityMinAggregate = {
  __typename?: "StoryActivityMinAggregate";
  audio?: Maybe<Scalars["String"]["output"]>;
  challengeStatement?: Maybe<Scalars["String"]["output"]>;
  coinsToSpend?: Maybe<Scalars["Int"]["output"]>;
  content?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  displayOrder?: Maybe<Scalars["Int"]["output"]>;
  featuredImage?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  image?: Maybe<Scalars["String"]["output"]>;
  isCommentEnabled?: Maybe<Scalars["Boolean"]["output"]>;
  isFeatured?: Maybe<Scalars["Boolean"]["output"]>;
  publishedDate?: Maybe<Scalars["DateTime"]["output"]>;
  questionXps?: Maybe<Scalars["Int"]["output"]>;
  skillId?: Maybe<Scalars["Int"]["output"]>;
  slug?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<ActivityStatus>;
  storyId?: Maybe<Scalars["Int"]["output"]>;
  timeRequired?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
  video?: Maybe<Scalars["String"]["output"]>;
  xps?: Maybe<Scalars["Int"]["output"]>;
};

export type StoryActivityOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type StoryActivityOrderByWithRelationInput = {
  audio?: InputMaybe<SortOrderInput>;
  challengeStatement?: InputMaybe<SortOrder>;
  coinsToSpend?: InputMaybe<SortOrder>;
  comments?: InputMaybe<CommentOrderByRelationAggregateInput>;
  content?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  displayOrder?: InputMaybe<SortOrder>;
  featuredImage?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  image?: InputMaybe<SortOrderInput>;
  isCommentEnabled?: InputMaybe<SortOrder>;
  isFeatured?: InputMaybe<SortOrder>;
  meta?: InputMaybe<SortOrderInput>;
  products?: InputMaybe<ProductOrderByRelationAggregateInput>;
  promoCodes?: InputMaybe<PromoCodeOrderByRelationAggregateInput>;
  publishedDate?: InputMaybe<SortOrderInput>;
  questionXps?: InputMaybe<SortOrder>;
  questions?: InputMaybe<ActivityQuestionOrderByRelationAggregateInput>;
  skill?: InputMaybe<SkillOrderByWithRelationInput>;
  skillId?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  standards?: InputMaybe<StandardOrderByRelationAggregateInput>;
  status?: InputMaybe<SortOrder>;
  story?: InputMaybe<StoryOrderByWithRelationInput>;
  storyId?: InputMaybe<SortOrder>;
  timeRequired?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  video?: InputMaybe<SortOrderInput>;
  xps?: InputMaybe<SortOrder>;
};

export type StoryActivityRelationFilter = {
  is?: InputMaybe<StoryActivityWhereInput>;
  isNot?: InputMaybe<StoryActivityWhereInput>;
};

export enum StoryActivityScalarFieldEnum {
  AUDIO = "audio",
  CHALLENGESTATEMENT = "challengeStatement",
  COINSTOSPEND = "coinsToSpend",
  CONTENT = "content",
  CREATEDAT = "createdAt",
  DISPLAYORDER = "displayOrder",
  FEATUREDIMAGE = "featuredImage",
  ID = "id",
  IMAGE = "image",
  ISCOMMENTENABLED = "isCommentEnabled",
  ISFEATURED = "isFeatured",
  META = "meta",
  PUBLISHEDDATE = "publishedDate",
  QUESTIONXPS = "questionXps",
  SKILLID = "skillId",
  SLUG = "slug",
  STATUS = "status",
  STORYID = "storyId",
  TIMEREQUIRED = "timeRequired",
  TITLE = "title",
  UPDATEDAT = "updatedAt",
  VIDEO = "video",
  XPS = "xps"
}

export type StoryActivityScalarWhereInput = {
  AND?: InputMaybe<Array<StoryActivityScalarWhereInput>>;
  NOT?: InputMaybe<Array<StoryActivityScalarWhereInput>>;
  OR?: InputMaybe<Array<StoryActivityScalarWhereInput>>;
  audio?: InputMaybe<StringNullableFilter>;
  challengeStatement?: InputMaybe<StringFilter>;
  coinsToSpend?: InputMaybe<IntFilter>;
  content?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  displayOrder?: InputMaybe<IntFilter>;
  featuredImage?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  image?: InputMaybe<StringNullableFilter>;
  isCommentEnabled?: InputMaybe<BoolFilter>;
  isFeatured?: InputMaybe<BoolFilter>;
  meta?: InputMaybe<JsonNullableFilter>;
  publishedDate?: InputMaybe<DateTimeNullableFilter>;
  questionXps?: InputMaybe<IntFilter>;
  skillId?: InputMaybe<IntFilter>;
  slug?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumActivityStatusFilter>;
  storyId?: InputMaybe<IntFilter>;
  timeRequired?: InputMaybe<StringFilter>;
  title?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  video?: InputMaybe<StringNullableFilter>;
  xps?: InputMaybe<IntFilter>;
};

export type StoryActivitySumAggregate = {
  __typename?: "StoryActivitySumAggregate";
  coinsToSpend?: Maybe<Scalars["Int"]["output"]>;
  displayOrder?: Maybe<Scalars["Int"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  questionXps?: Maybe<Scalars["Int"]["output"]>;
  skillId?: Maybe<Scalars["Int"]["output"]>;
  storyId?: Maybe<Scalars["Int"]["output"]>;
  xps?: Maybe<Scalars["Int"]["output"]>;
};

export type StoryActivityUpdateInput = {
  audio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  challengeStatement?: InputMaybe<StringFieldUpdateOperationsInput>;
  coinsToSpend?: InputMaybe<IntFieldUpdateOperationsInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutStoryActivityNestedInput>;
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  displayOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isCommentEnabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isFeatured?: InputMaybe<BoolFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  products?: InputMaybe<ProductUpdateManyWithoutRelatedStoryActivitiesNestedInput>;
  promoCodes?: InputMaybe<PromoCodeUpdateManyWithoutStoryActivitiesNestedInput>;
  publishedDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  questionXps?: InputMaybe<IntFieldUpdateOperationsInput>;
  questions?: InputMaybe<ActivityQuestionUpdateManyWithoutStoryActivityNestedInput>;
  skill?: InputMaybe<SkillUpdateOneRequiredWithoutStoryActivitiesNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  standards?: InputMaybe<StandardUpdateManyWithoutStoryActivitiesNestedInput>;
  status?: InputMaybe<EnumActivityStatusFieldUpdateOperationsInput>;
  story?: InputMaybe<StoryUpdateOneRequiredWithoutActivitiesNestedInput>;
  timeRequired?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  video?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  xps?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type StoryActivityUpdateManyMutationInput = {
  audio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  challengeStatement?: InputMaybe<StringFieldUpdateOperationsInput>;
  coinsToSpend?: InputMaybe<IntFieldUpdateOperationsInput>;
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  displayOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isCommentEnabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isFeatured?: InputMaybe<BoolFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  publishedDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  questionXps?: InputMaybe<IntFieldUpdateOperationsInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumActivityStatusFieldUpdateOperationsInput>;
  timeRequired?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  video?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  xps?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type StoryActivityUpdateManyWithWhereWithoutProductsInput = {
  data: StoryActivityUpdateManyMutationInput;
  where: StoryActivityScalarWhereInput;
};

export type StoryActivityUpdateManyWithWhereWithoutPromoCodesInput = {
  data: StoryActivityUpdateManyMutationInput;
  where: StoryActivityScalarWhereInput;
};

export type StoryActivityUpdateManyWithWhereWithoutSkillInput = {
  data: StoryActivityUpdateManyMutationInput;
  where: StoryActivityScalarWhereInput;
};

export type StoryActivityUpdateManyWithWhereWithoutStandardsInput = {
  data: StoryActivityUpdateManyMutationInput;
  where: StoryActivityScalarWhereInput;
};

export type StoryActivityUpdateManyWithWhereWithoutStoryInput = {
  data: StoryActivityUpdateManyMutationInput;
  where: StoryActivityScalarWhereInput;
};

export type StoryActivityUpdateManyWithoutProductsNestedInput = {
  connect?: InputMaybe<Array<StoryActivityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StoryActivityCreateOrConnectWithoutProductsInput>>;
  create?: InputMaybe<Array<StoryActivityCreateWithoutProductsInput>>;
  delete?: InputMaybe<Array<StoryActivityWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<StoryActivityScalarWhereInput>>;
  disconnect?: InputMaybe<Array<StoryActivityWhereUniqueInput>>;
  set?: InputMaybe<Array<StoryActivityWhereUniqueInput>>;
  update?: InputMaybe<Array<StoryActivityUpdateWithWhereUniqueWithoutProductsInput>>;
  updateMany?: InputMaybe<Array<StoryActivityUpdateManyWithWhereWithoutProductsInput>>;
  upsert?: InputMaybe<Array<StoryActivityUpsertWithWhereUniqueWithoutProductsInput>>;
};

export type StoryActivityUpdateManyWithoutPromoCodesNestedInput = {
  connect?: InputMaybe<Array<StoryActivityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StoryActivityCreateOrConnectWithoutPromoCodesInput>>;
  create?: InputMaybe<Array<StoryActivityCreateWithoutPromoCodesInput>>;
  delete?: InputMaybe<Array<StoryActivityWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<StoryActivityScalarWhereInput>>;
  disconnect?: InputMaybe<Array<StoryActivityWhereUniqueInput>>;
  set?: InputMaybe<Array<StoryActivityWhereUniqueInput>>;
  update?: InputMaybe<Array<StoryActivityUpdateWithWhereUniqueWithoutPromoCodesInput>>;
  updateMany?: InputMaybe<Array<StoryActivityUpdateManyWithWhereWithoutPromoCodesInput>>;
  upsert?: InputMaybe<Array<StoryActivityUpsertWithWhereUniqueWithoutPromoCodesInput>>;
};

export type StoryActivityUpdateManyWithoutSkillNestedInput = {
  connect?: InputMaybe<Array<StoryActivityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StoryActivityCreateOrConnectWithoutSkillInput>>;
  create?: InputMaybe<Array<StoryActivityCreateWithoutSkillInput>>;
  createMany?: InputMaybe<StoryActivityCreateManySkillInputEnvelope>;
  delete?: InputMaybe<Array<StoryActivityWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<StoryActivityScalarWhereInput>>;
  disconnect?: InputMaybe<Array<StoryActivityWhereUniqueInput>>;
  set?: InputMaybe<Array<StoryActivityWhereUniqueInput>>;
  update?: InputMaybe<Array<StoryActivityUpdateWithWhereUniqueWithoutSkillInput>>;
  updateMany?: InputMaybe<Array<StoryActivityUpdateManyWithWhereWithoutSkillInput>>;
  upsert?: InputMaybe<Array<StoryActivityUpsertWithWhereUniqueWithoutSkillInput>>;
};

export type StoryActivityUpdateManyWithoutStandardsNestedInput = {
  connect?: InputMaybe<Array<StoryActivityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StoryActivityCreateOrConnectWithoutStandardsInput>>;
  create?: InputMaybe<Array<StoryActivityCreateWithoutStandardsInput>>;
  delete?: InputMaybe<Array<StoryActivityWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<StoryActivityScalarWhereInput>>;
  disconnect?: InputMaybe<Array<StoryActivityWhereUniqueInput>>;
  set?: InputMaybe<Array<StoryActivityWhereUniqueInput>>;
  update?: InputMaybe<Array<StoryActivityUpdateWithWhereUniqueWithoutStandardsInput>>;
  updateMany?: InputMaybe<Array<StoryActivityUpdateManyWithWhereWithoutStandardsInput>>;
  upsert?: InputMaybe<Array<StoryActivityUpsertWithWhereUniqueWithoutStandardsInput>>;
};

export type StoryActivityUpdateManyWithoutStoryNestedInput = {
  connect?: InputMaybe<Array<StoryActivityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StoryActivityCreateOrConnectWithoutStoryInput>>;
  create?: InputMaybe<Array<StoryActivityCreateWithoutStoryInput>>;
  createMany?: InputMaybe<StoryActivityCreateManyStoryInputEnvelope>;
  delete?: InputMaybe<Array<StoryActivityWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<StoryActivityScalarWhereInput>>;
  disconnect?: InputMaybe<Array<StoryActivityWhereUniqueInput>>;
  set?: InputMaybe<Array<StoryActivityWhereUniqueInput>>;
  update?: InputMaybe<Array<StoryActivityUpdateWithWhereUniqueWithoutStoryInput>>;
  updateMany?: InputMaybe<Array<StoryActivityUpdateManyWithWhereWithoutStoryInput>>;
  upsert?: InputMaybe<Array<StoryActivityUpsertWithWhereUniqueWithoutStoryInput>>;
};

export type StoryActivityUpdateOneWithoutCommentsNestedInput = {
  connect?: InputMaybe<StoryActivityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StoryActivityCreateOrConnectWithoutCommentsInput>;
  create?: InputMaybe<StoryActivityCreateWithoutCommentsInput>;
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  update?: InputMaybe<StoryActivityUpdateWithoutCommentsInput>;
  upsert?: InputMaybe<StoryActivityUpsertWithoutCommentsInput>;
};

export type StoryActivityUpdateOneWithoutQuestionsNestedInput = {
  connect?: InputMaybe<StoryActivityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StoryActivityCreateOrConnectWithoutQuestionsInput>;
  create?: InputMaybe<StoryActivityCreateWithoutQuestionsInput>;
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  update?: InputMaybe<StoryActivityUpdateWithoutQuestionsInput>;
  upsert?: InputMaybe<StoryActivityUpsertWithoutQuestionsInput>;
};

export type StoryActivityUpdateWithWhereUniqueWithoutProductsInput = {
  data: StoryActivityUpdateWithoutProductsInput;
  where: StoryActivityWhereUniqueInput;
};

export type StoryActivityUpdateWithWhereUniqueWithoutPromoCodesInput = {
  data: StoryActivityUpdateWithoutPromoCodesInput;
  where: StoryActivityWhereUniqueInput;
};

export type StoryActivityUpdateWithWhereUniqueWithoutSkillInput = {
  data: StoryActivityUpdateWithoutSkillInput;
  where: StoryActivityWhereUniqueInput;
};

export type StoryActivityUpdateWithWhereUniqueWithoutStandardsInput = {
  data: StoryActivityUpdateWithoutStandardsInput;
  where: StoryActivityWhereUniqueInput;
};

export type StoryActivityUpdateWithWhereUniqueWithoutStoryInput = {
  data: StoryActivityUpdateWithoutStoryInput;
  where: StoryActivityWhereUniqueInput;
};

export type StoryActivityUpdateWithoutCommentsInput = {
  audio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  challengeStatement?: InputMaybe<StringFieldUpdateOperationsInput>;
  coinsToSpend?: InputMaybe<IntFieldUpdateOperationsInput>;
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  displayOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isCommentEnabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isFeatured?: InputMaybe<BoolFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  products?: InputMaybe<ProductUpdateManyWithoutRelatedStoryActivitiesNestedInput>;
  promoCodes?: InputMaybe<PromoCodeUpdateManyWithoutStoryActivitiesNestedInput>;
  publishedDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  questionXps?: InputMaybe<IntFieldUpdateOperationsInput>;
  questions?: InputMaybe<ActivityQuestionUpdateManyWithoutStoryActivityNestedInput>;
  skill?: InputMaybe<SkillUpdateOneRequiredWithoutStoryActivitiesNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  standards?: InputMaybe<StandardUpdateManyWithoutStoryActivitiesNestedInput>;
  status?: InputMaybe<EnumActivityStatusFieldUpdateOperationsInput>;
  story?: InputMaybe<StoryUpdateOneRequiredWithoutActivitiesNestedInput>;
  timeRequired?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  video?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  xps?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type StoryActivityUpdateWithoutProductsInput = {
  audio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  challengeStatement?: InputMaybe<StringFieldUpdateOperationsInput>;
  coinsToSpend?: InputMaybe<IntFieldUpdateOperationsInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutStoryActivityNestedInput>;
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  displayOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isCommentEnabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isFeatured?: InputMaybe<BoolFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  promoCodes?: InputMaybe<PromoCodeUpdateManyWithoutStoryActivitiesNestedInput>;
  publishedDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  questionXps?: InputMaybe<IntFieldUpdateOperationsInput>;
  questions?: InputMaybe<ActivityQuestionUpdateManyWithoutStoryActivityNestedInput>;
  skill?: InputMaybe<SkillUpdateOneRequiredWithoutStoryActivitiesNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  standards?: InputMaybe<StandardUpdateManyWithoutStoryActivitiesNestedInput>;
  status?: InputMaybe<EnumActivityStatusFieldUpdateOperationsInput>;
  story?: InputMaybe<StoryUpdateOneRequiredWithoutActivitiesNestedInput>;
  timeRequired?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  video?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  xps?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type StoryActivityUpdateWithoutPromoCodesInput = {
  audio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  challengeStatement?: InputMaybe<StringFieldUpdateOperationsInput>;
  coinsToSpend?: InputMaybe<IntFieldUpdateOperationsInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutStoryActivityNestedInput>;
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  displayOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isCommentEnabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isFeatured?: InputMaybe<BoolFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  products?: InputMaybe<ProductUpdateManyWithoutRelatedStoryActivitiesNestedInput>;
  publishedDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  questionXps?: InputMaybe<IntFieldUpdateOperationsInput>;
  questions?: InputMaybe<ActivityQuestionUpdateManyWithoutStoryActivityNestedInput>;
  skill?: InputMaybe<SkillUpdateOneRequiredWithoutStoryActivitiesNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  standards?: InputMaybe<StandardUpdateManyWithoutStoryActivitiesNestedInput>;
  status?: InputMaybe<EnumActivityStatusFieldUpdateOperationsInput>;
  story?: InputMaybe<StoryUpdateOneRequiredWithoutActivitiesNestedInput>;
  timeRequired?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  video?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  xps?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type StoryActivityUpdateWithoutQuestionsInput = {
  audio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  challengeStatement?: InputMaybe<StringFieldUpdateOperationsInput>;
  coinsToSpend?: InputMaybe<IntFieldUpdateOperationsInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutStoryActivityNestedInput>;
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  displayOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isCommentEnabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isFeatured?: InputMaybe<BoolFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  products?: InputMaybe<ProductUpdateManyWithoutRelatedStoryActivitiesNestedInput>;
  promoCodes?: InputMaybe<PromoCodeUpdateManyWithoutStoryActivitiesNestedInput>;
  publishedDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  questionXps?: InputMaybe<IntFieldUpdateOperationsInput>;
  skill?: InputMaybe<SkillUpdateOneRequiredWithoutStoryActivitiesNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  standards?: InputMaybe<StandardUpdateManyWithoutStoryActivitiesNestedInput>;
  status?: InputMaybe<EnumActivityStatusFieldUpdateOperationsInput>;
  story?: InputMaybe<StoryUpdateOneRequiredWithoutActivitiesNestedInput>;
  timeRequired?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  video?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  xps?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type StoryActivityUpdateWithoutSkillInput = {
  audio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  challengeStatement?: InputMaybe<StringFieldUpdateOperationsInput>;
  coinsToSpend?: InputMaybe<IntFieldUpdateOperationsInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutStoryActivityNestedInput>;
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  displayOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isCommentEnabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isFeatured?: InputMaybe<BoolFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  products?: InputMaybe<ProductUpdateManyWithoutRelatedStoryActivitiesNestedInput>;
  promoCodes?: InputMaybe<PromoCodeUpdateManyWithoutStoryActivitiesNestedInput>;
  publishedDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  questionXps?: InputMaybe<IntFieldUpdateOperationsInput>;
  questions?: InputMaybe<ActivityQuestionUpdateManyWithoutStoryActivityNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  standards?: InputMaybe<StandardUpdateManyWithoutStoryActivitiesNestedInput>;
  status?: InputMaybe<EnumActivityStatusFieldUpdateOperationsInput>;
  story?: InputMaybe<StoryUpdateOneRequiredWithoutActivitiesNestedInput>;
  timeRequired?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  video?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  xps?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type StoryActivityUpdateWithoutStandardsInput = {
  audio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  challengeStatement?: InputMaybe<StringFieldUpdateOperationsInput>;
  coinsToSpend?: InputMaybe<IntFieldUpdateOperationsInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutStoryActivityNestedInput>;
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  displayOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isCommentEnabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isFeatured?: InputMaybe<BoolFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  products?: InputMaybe<ProductUpdateManyWithoutRelatedStoryActivitiesNestedInput>;
  promoCodes?: InputMaybe<PromoCodeUpdateManyWithoutStoryActivitiesNestedInput>;
  publishedDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  questionXps?: InputMaybe<IntFieldUpdateOperationsInput>;
  questions?: InputMaybe<ActivityQuestionUpdateManyWithoutStoryActivityNestedInput>;
  skill?: InputMaybe<SkillUpdateOneRequiredWithoutStoryActivitiesNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumActivityStatusFieldUpdateOperationsInput>;
  story?: InputMaybe<StoryUpdateOneRequiredWithoutActivitiesNestedInput>;
  timeRequired?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  video?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  xps?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type StoryActivityUpdateWithoutStoryInput = {
  audio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  challengeStatement?: InputMaybe<StringFieldUpdateOperationsInput>;
  coinsToSpend?: InputMaybe<IntFieldUpdateOperationsInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutStoryActivityNestedInput>;
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  displayOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isCommentEnabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isFeatured?: InputMaybe<BoolFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  products?: InputMaybe<ProductUpdateManyWithoutRelatedStoryActivitiesNestedInput>;
  promoCodes?: InputMaybe<PromoCodeUpdateManyWithoutStoryActivitiesNestedInput>;
  publishedDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  questionXps?: InputMaybe<IntFieldUpdateOperationsInput>;
  questions?: InputMaybe<ActivityQuestionUpdateManyWithoutStoryActivityNestedInput>;
  skill?: InputMaybe<SkillUpdateOneRequiredWithoutStoryActivitiesNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  standards?: InputMaybe<StandardUpdateManyWithoutStoryActivitiesNestedInput>;
  status?: InputMaybe<EnumActivityStatusFieldUpdateOperationsInput>;
  timeRequired?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  video?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  xps?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type StoryActivityUpsertWithWhereUniqueWithoutProductsInput = {
  create: StoryActivityCreateWithoutProductsInput;
  update: StoryActivityUpdateWithoutProductsInput;
  where: StoryActivityWhereUniqueInput;
};

export type StoryActivityUpsertWithWhereUniqueWithoutPromoCodesInput = {
  create: StoryActivityCreateWithoutPromoCodesInput;
  update: StoryActivityUpdateWithoutPromoCodesInput;
  where: StoryActivityWhereUniqueInput;
};

export type StoryActivityUpsertWithWhereUniqueWithoutSkillInput = {
  create: StoryActivityCreateWithoutSkillInput;
  update: StoryActivityUpdateWithoutSkillInput;
  where: StoryActivityWhereUniqueInput;
};

export type StoryActivityUpsertWithWhereUniqueWithoutStandardsInput = {
  create: StoryActivityCreateWithoutStandardsInput;
  update: StoryActivityUpdateWithoutStandardsInput;
  where: StoryActivityWhereUniqueInput;
};

export type StoryActivityUpsertWithWhereUniqueWithoutStoryInput = {
  create: StoryActivityCreateWithoutStoryInput;
  update: StoryActivityUpdateWithoutStoryInput;
  where: StoryActivityWhereUniqueInput;
};

export type StoryActivityUpsertWithoutCommentsInput = {
  create: StoryActivityCreateWithoutCommentsInput;
  update: StoryActivityUpdateWithoutCommentsInput;
};

export type StoryActivityUpsertWithoutQuestionsInput = {
  create: StoryActivityCreateWithoutQuestionsInput;
  update: StoryActivityUpdateWithoutQuestionsInput;
};

export type StoryActivityWhereInput = {
  AND?: InputMaybe<Array<StoryActivityWhereInput>>;
  NOT?: InputMaybe<Array<StoryActivityWhereInput>>;
  OR?: InputMaybe<Array<StoryActivityWhereInput>>;
  audio?: InputMaybe<StringNullableFilter>;
  challengeStatement?: InputMaybe<StringFilter>;
  coinsToSpend?: InputMaybe<IntFilter>;
  comments?: InputMaybe<CommentListRelationFilter>;
  content?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  displayOrder?: InputMaybe<IntFilter>;
  featuredImage?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  image?: InputMaybe<StringNullableFilter>;
  isCommentEnabled?: InputMaybe<BoolFilter>;
  isFeatured?: InputMaybe<BoolFilter>;
  meta?: InputMaybe<JsonNullableFilter>;
  products?: InputMaybe<ProductListRelationFilter>;
  promoCodes?: InputMaybe<PromoCodeListRelationFilter>;
  publishedDate?: InputMaybe<DateTimeNullableFilter>;
  questionXps?: InputMaybe<IntFilter>;
  questions?: InputMaybe<ActivityQuestionListRelationFilter>;
  skill?: InputMaybe<SkillRelationFilter>;
  skillId?: InputMaybe<IntFilter>;
  slug?: InputMaybe<StringFilter>;
  standards?: InputMaybe<StandardListRelationFilter>;
  status?: InputMaybe<EnumActivityStatusFilter>;
  story?: InputMaybe<StoryRelationFilter>;
  storyId?: InputMaybe<IntFilter>;
  timeRequired?: InputMaybe<StringFilter>;
  title?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  video?: InputMaybe<StringNullableFilter>;
  xps?: InputMaybe<IntFilter>;
};

export type StoryActivityWhereUniqueInput = {
  id?: InputMaybe<Scalars["Int"]["input"]>;
  slug?: InputMaybe<Scalars["String"]["input"]>;
};

export type StoryAvgAggregate = {
  __typename?: "StoryAvgAggregate";
  displayOrder?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  schoolId?: Maybe<Scalars["Float"]["output"]>;
  tagId?: Maybe<Scalars["Float"]["output"]>;
};

export type StoryBanner = {
  __typename?: "StoryBanner";
  createdAt: Scalars["DateTime"]["output"];
  destinationUrl: Scalars["String"]["output"];
  displayOrder: Scalars["Int"]["output"];
  id: Scalars["Int"]["output"];
  image?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
};

export type StoryBannerCreateInput = {
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  destinationUrl: Scalars["String"]["input"];
  displayOrder?: InputMaybe<Scalars["Int"]["input"]>;
  image?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type StoryBannerOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  destinationUrl?: InputMaybe<SortOrder>;
  displayOrder?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  image?: InputMaybe<SortOrderInput>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum StoryBannerScalarFieldEnum {
  CREATEDAT = "createdAt",
  DESTINATIONURL = "destinationUrl",
  DISPLAYORDER = "displayOrder",
  ID = "id",
  IMAGE = "image",
  UPDATEDAT = "updatedAt"
}

export type StoryBannerUpdateInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  destinationUrl?: InputMaybe<StringFieldUpdateOperationsInput>;
  displayOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type StoryBannerWhereInput = {
  AND?: InputMaybe<Array<StoryBannerWhereInput>>;
  NOT?: InputMaybe<Array<StoryBannerWhereInput>>;
  OR?: InputMaybe<Array<StoryBannerWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  destinationUrl?: InputMaybe<StringFilter>;
  displayOrder?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  image?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type StoryBannerWhereUniqueInput = {
  id?: InputMaybe<Scalars["Int"]["input"]>;
};

export type StoryCount = {
  __typename?: "StoryCount";
  activities: Scalars["Int"]["output"];
  standards: Scalars["Int"]["output"];
};


export type StoryCountActivitiesArgs = {
  where?: InputMaybe<StoryActivityWhereInput>;
};


export type StoryCountStandardsArgs = {
  where?: InputMaybe<StandardWhereInput>;
};

export type StoryCountAggregate = {
  __typename?: "StoryCountAggregate";
  _all: Scalars["Int"]["output"];
  createdAt: Scalars["Int"]["output"];
  description: Scalars["Int"]["output"];
  displayOrder: Scalars["Int"]["output"];
  featuredImage: Scalars["Int"]["output"];
  id: Scalars["Int"]["output"];
  image: Scalars["Int"]["output"];
  isActive: Scalars["Int"]["output"];
  schoolId: Scalars["Int"]["output"];
  slug: Scalars["Int"]["output"];
  tagId: Scalars["Int"]["output"];
  title: Scalars["Int"]["output"];
  updatedAt: Scalars["Int"]["output"];
};

export type StoryCreateInput = {
  activities?: InputMaybe<StoryActivityCreateNestedManyWithoutStoryInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  displayOrder?: InputMaybe<Scalars["Int"]["input"]>;
  featuredImage?: InputMaybe<Scalars["String"]["input"]>;
  image?: InputMaybe<Scalars["String"]["input"]>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  school?: InputMaybe<SchoolCreateNestedOneWithoutStoriesInput>;
  slug: Scalars["String"]["input"];
  standards?: InputMaybe<StandardCreateNestedManyWithoutStoriesInput>;
  tag?: InputMaybe<StoryTagCreateNestedOneWithoutStoriesInput>;
  title: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type StoryCreateManySchoolInput = {
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  displayOrder?: InputMaybe<Scalars["Int"]["input"]>;
  featuredImage?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  image?: InputMaybe<Scalars["String"]["input"]>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  slug: Scalars["String"]["input"];
  tagId?: InputMaybe<Scalars["Int"]["input"]>;
  title: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type StoryCreateManySchoolInputEnvelope = {
  data: Array<StoryCreateManySchoolInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type StoryCreateManyTagInput = {
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  displayOrder?: InputMaybe<Scalars["Int"]["input"]>;
  featuredImage?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  image?: InputMaybe<Scalars["String"]["input"]>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  schoolId?: InputMaybe<Scalars["Int"]["input"]>;
  slug: Scalars["String"]["input"];
  title: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type StoryCreateManyTagInputEnvelope = {
  data: Array<StoryCreateManyTagInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type StoryCreateNestedManyWithoutSchoolInput = {
  connect?: InputMaybe<Array<StoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StoryCreateOrConnectWithoutSchoolInput>>;
  create?: InputMaybe<Array<StoryCreateWithoutSchoolInput>>;
  createMany?: InputMaybe<StoryCreateManySchoolInputEnvelope>;
};

export type StoryCreateNestedManyWithoutStandardsInput = {
  connect?: InputMaybe<Array<StoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StoryCreateOrConnectWithoutStandardsInput>>;
  create?: InputMaybe<Array<StoryCreateWithoutStandardsInput>>;
};

export type StoryCreateNestedManyWithoutTagInput = {
  connect?: InputMaybe<Array<StoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StoryCreateOrConnectWithoutTagInput>>;
  create?: InputMaybe<Array<StoryCreateWithoutTagInput>>;
  createMany?: InputMaybe<StoryCreateManyTagInputEnvelope>;
};

export type StoryCreateNestedOneWithoutActivitiesInput = {
  connect?: InputMaybe<StoryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StoryCreateOrConnectWithoutActivitiesInput>;
  create?: InputMaybe<StoryCreateWithoutActivitiesInput>;
};

export type StoryCreateOrConnectWithoutActivitiesInput = {
  create: StoryCreateWithoutActivitiesInput;
  where: StoryWhereUniqueInput;
};

export type StoryCreateOrConnectWithoutSchoolInput = {
  create: StoryCreateWithoutSchoolInput;
  where: StoryWhereUniqueInput;
};

export type StoryCreateOrConnectWithoutStandardsInput = {
  create: StoryCreateWithoutStandardsInput;
  where: StoryWhereUniqueInput;
};

export type StoryCreateOrConnectWithoutTagInput = {
  create: StoryCreateWithoutTagInput;
  where: StoryWhereUniqueInput;
};

export type StoryCreateWithoutActivitiesInput = {
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  displayOrder?: InputMaybe<Scalars["Int"]["input"]>;
  featuredImage?: InputMaybe<Scalars["String"]["input"]>;
  image?: InputMaybe<Scalars["String"]["input"]>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  school?: InputMaybe<SchoolCreateNestedOneWithoutStoriesInput>;
  slug: Scalars["String"]["input"];
  standards?: InputMaybe<StandardCreateNestedManyWithoutStoriesInput>;
  tag?: InputMaybe<StoryTagCreateNestedOneWithoutStoriesInput>;
  title: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type StoryCreateWithoutSchoolInput = {
  activities?: InputMaybe<StoryActivityCreateNestedManyWithoutStoryInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  displayOrder?: InputMaybe<Scalars["Int"]["input"]>;
  featuredImage?: InputMaybe<Scalars["String"]["input"]>;
  image?: InputMaybe<Scalars["String"]["input"]>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  slug: Scalars["String"]["input"];
  standards?: InputMaybe<StandardCreateNestedManyWithoutStoriesInput>;
  tag?: InputMaybe<StoryTagCreateNestedOneWithoutStoriesInput>;
  title: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type StoryCreateWithoutStandardsInput = {
  activities?: InputMaybe<StoryActivityCreateNestedManyWithoutStoryInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  displayOrder?: InputMaybe<Scalars["Int"]["input"]>;
  featuredImage?: InputMaybe<Scalars["String"]["input"]>;
  image?: InputMaybe<Scalars["String"]["input"]>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  school?: InputMaybe<SchoolCreateNestedOneWithoutStoriesInput>;
  slug: Scalars["String"]["input"];
  tag?: InputMaybe<StoryTagCreateNestedOneWithoutStoriesInput>;
  title: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type StoryCreateWithoutTagInput = {
  activities?: InputMaybe<StoryActivityCreateNestedManyWithoutStoryInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  displayOrder?: InputMaybe<Scalars["Int"]["input"]>;
  featuredImage?: InputMaybe<Scalars["String"]["input"]>;
  image?: InputMaybe<Scalars["String"]["input"]>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  school?: InputMaybe<SchoolCreateNestedOneWithoutStoriesInput>;
  slug: Scalars["String"]["input"];
  standards?: InputMaybe<StandardCreateNestedManyWithoutStoriesInput>;
  title: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type StoryListRelationFilter = {
  every?: InputMaybe<StoryWhereInput>;
  none?: InputMaybe<StoryWhereInput>;
  some?: InputMaybe<StoryWhereInput>;
};

export type StoryMaxAggregate = {
  __typename?: "StoryMaxAggregate";
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  displayOrder?: Maybe<Scalars["Int"]["output"]>;
  featuredImage?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  image?: Maybe<Scalars["String"]["output"]>;
  isActive?: Maybe<Scalars["Boolean"]["output"]>;
  schoolId?: Maybe<Scalars["Int"]["output"]>;
  slug?: Maybe<Scalars["String"]["output"]>;
  tagId?: Maybe<Scalars["Int"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type StoryMinAggregate = {
  __typename?: "StoryMinAggregate";
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  displayOrder?: Maybe<Scalars["Int"]["output"]>;
  featuredImage?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  image?: Maybe<Scalars["String"]["output"]>;
  isActive?: Maybe<Scalars["Boolean"]["output"]>;
  schoolId?: Maybe<Scalars["Int"]["output"]>;
  slug?: Maybe<Scalars["String"]["output"]>;
  tagId?: Maybe<Scalars["Int"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type StoryOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type StoryOrderByWithRelationInput = {
  activities?: InputMaybe<StoryActivityOrderByRelationAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrderInput>;
  displayOrder?: InputMaybe<SortOrder>;
  featuredImage?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  image?: InputMaybe<SortOrderInput>;
  isActive?: InputMaybe<SortOrder>;
  school?: InputMaybe<SchoolOrderByWithRelationInput>;
  schoolId?: InputMaybe<SortOrderInput>;
  slug?: InputMaybe<SortOrder>;
  standards?: InputMaybe<StandardOrderByRelationAggregateInput>;
  tag?: InputMaybe<StoryTagOrderByWithRelationInput>;
  tagId?: InputMaybe<SortOrderInput>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type StoryRelationFilter = {
  is?: InputMaybe<StoryWhereInput>;
  isNot?: InputMaybe<StoryWhereInput>;
};

export enum StoryScalarFieldEnum {
  CREATEDAT = "createdAt",
  DESCRIPTION = "description",
  DISPLAYORDER = "displayOrder",
  FEATUREDIMAGE = "featuredImage",
  ID = "id",
  IMAGE = "image",
  ISACTIVE = "isActive",
  SCHOOLID = "schoolId",
  SLUG = "slug",
  TAGID = "tagId",
  TITLE = "title",
  UPDATEDAT = "updatedAt"
}

export type StoryScalarWhereInput = {
  AND?: InputMaybe<Array<StoryScalarWhereInput>>;
  NOT?: InputMaybe<Array<StoryScalarWhereInput>>;
  OR?: InputMaybe<Array<StoryScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  displayOrder?: InputMaybe<IntFilter>;
  featuredImage?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  image?: InputMaybe<StringNullableFilter>;
  isActive?: InputMaybe<BoolFilter>;
  schoolId?: InputMaybe<IntNullableFilter>;
  slug?: InputMaybe<StringFilter>;
  tagId?: InputMaybe<IntNullableFilter>;
  title?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type StorySumAggregate = {
  __typename?: "StorySumAggregate";
  displayOrder?: Maybe<Scalars["Int"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  schoolId?: Maybe<Scalars["Int"]["output"]>;
  tagId?: Maybe<Scalars["Int"]["output"]>;
};

export type StoryTag = {
  __typename?: "StoryTag";
  _count?: Maybe<StoryTagCount>;
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["Int"]["output"];
  image?: Maybe<Scalars["String"]["output"]>;
  key: Scalars["String"]["output"];
  stories: Array<Story>;
  text?: Maybe<Scalars["String"]["output"]>;
};


export type StoryTagStoriesArgs = {
  cursor?: InputMaybe<StoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<StoryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<StoryWhereInput>;
};

export type StoryTagAvgAggregate = {
  __typename?: "StoryTagAvgAggregate";
  id?: Maybe<Scalars["Float"]["output"]>;
};

export type StoryTagCount = {
  __typename?: "StoryTagCount";
  stories: Scalars["Int"]["output"];
};


export type StoryTagCountStoriesArgs = {
  where?: InputMaybe<StoryWhereInput>;
};

export type StoryTagCountAggregate = {
  __typename?: "StoryTagCountAggregate";
  _all: Scalars["Int"]["output"];
  createdAt: Scalars["Int"]["output"];
  id: Scalars["Int"]["output"];
  image: Scalars["Int"]["output"];
  key: Scalars["Int"]["output"];
  text: Scalars["Int"]["output"];
};

export type StoryTagCreateInput = {
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  image?: InputMaybe<Scalars["String"]["input"]>;
  key: Scalars["String"]["input"];
  stories?: InputMaybe<StoryCreateNestedManyWithoutTagInput>;
  text?: InputMaybe<Scalars["String"]["input"]>;
};

export type StoryTagCreateNestedOneWithoutStoriesInput = {
  connect?: InputMaybe<StoryTagWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StoryTagCreateOrConnectWithoutStoriesInput>;
  create?: InputMaybe<StoryTagCreateWithoutStoriesInput>;
};

export type StoryTagCreateOrConnectWithoutStoriesInput = {
  create: StoryTagCreateWithoutStoriesInput;
  where: StoryTagWhereUniqueInput;
};

export type StoryTagCreateWithoutStoriesInput = {
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  image?: InputMaybe<Scalars["String"]["input"]>;
  key: Scalars["String"]["input"];
  text?: InputMaybe<Scalars["String"]["input"]>;
};

export type StoryTagMaxAggregate = {
  __typename?: "StoryTagMaxAggregate";
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  image?: Maybe<Scalars["String"]["output"]>;
  key?: Maybe<Scalars["String"]["output"]>;
  text?: Maybe<Scalars["String"]["output"]>;
};

export type StoryTagMinAggregate = {
  __typename?: "StoryTagMinAggregate";
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  image?: Maybe<Scalars["String"]["output"]>;
  key?: Maybe<Scalars["String"]["output"]>;
  text?: Maybe<Scalars["String"]["output"]>;
};

export type StoryTagOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  image?: InputMaybe<SortOrderInput>;
  key?: InputMaybe<SortOrder>;
  stories?: InputMaybe<StoryOrderByRelationAggregateInput>;
  text?: InputMaybe<SortOrderInput>;
};

export type StoryTagRelationFilter = {
  is?: InputMaybe<StoryTagWhereInput>;
  isNot?: InputMaybe<StoryTagWhereInput>;
};

export enum StoryTagScalarFieldEnum {
  CREATEDAT = "createdAt",
  ID = "id",
  IMAGE = "image",
  KEY = "key",
  TEXT = "text"
}

export type StoryTagSumAggregate = {
  __typename?: "StoryTagSumAggregate";
  id?: Maybe<Scalars["Int"]["output"]>;
};

export type StoryTagUpdateInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  key?: InputMaybe<StringFieldUpdateOperationsInput>;
  stories?: InputMaybe<StoryUpdateManyWithoutTagNestedInput>;
  text?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type StoryTagUpdateOneWithoutStoriesNestedInput = {
  connect?: InputMaybe<StoryTagWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StoryTagCreateOrConnectWithoutStoriesInput>;
  create?: InputMaybe<StoryTagCreateWithoutStoriesInput>;
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  update?: InputMaybe<StoryTagUpdateWithoutStoriesInput>;
  upsert?: InputMaybe<StoryTagUpsertWithoutStoriesInput>;
};

export type StoryTagUpdateWithoutStoriesInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  key?: InputMaybe<StringFieldUpdateOperationsInput>;
  text?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type StoryTagUpsertWithoutStoriesInput = {
  create: StoryTagCreateWithoutStoriesInput;
  update: StoryTagUpdateWithoutStoriesInput;
};

export type StoryTagWhereInput = {
  AND?: InputMaybe<Array<StoryTagWhereInput>>;
  NOT?: InputMaybe<Array<StoryTagWhereInput>>;
  OR?: InputMaybe<Array<StoryTagWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  image?: InputMaybe<StringNullableFilter>;
  key?: InputMaybe<StringFilter>;
  stories?: InputMaybe<StoryListRelationFilter>;
  text?: InputMaybe<StringNullableFilter>;
};

export type StoryTagWhereUniqueInput = {
  id?: InputMaybe<Scalars["Int"]["input"]>;
  key?: InputMaybe<Scalars["String"]["input"]>;
};

export type StoryUpdateInput = {
  activities?: InputMaybe<StoryActivityUpdateManyWithoutStoryNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  displayOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  school?: InputMaybe<SchoolUpdateOneWithoutStoriesNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  standards?: InputMaybe<StandardUpdateManyWithoutStoriesNestedInput>;
  tag?: InputMaybe<StoryTagUpdateOneWithoutStoriesNestedInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type StoryUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  displayOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type StoryUpdateManyWithWhereWithoutSchoolInput = {
  data: StoryUpdateManyMutationInput;
  where: StoryScalarWhereInput;
};

export type StoryUpdateManyWithWhereWithoutStandardsInput = {
  data: StoryUpdateManyMutationInput;
  where: StoryScalarWhereInput;
};

export type StoryUpdateManyWithWhereWithoutTagInput = {
  data: StoryUpdateManyMutationInput;
  where: StoryScalarWhereInput;
};

export type StoryUpdateManyWithoutSchoolNestedInput = {
  connect?: InputMaybe<Array<StoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StoryCreateOrConnectWithoutSchoolInput>>;
  create?: InputMaybe<Array<StoryCreateWithoutSchoolInput>>;
  createMany?: InputMaybe<StoryCreateManySchoolInputEnvelope>;
  delete?: InputMaybe<Array<StoryWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<StoryScalarWhereInput>>;
  disconnect?: InputMaybe<Array<StoryWhereUniqueInput>>;
  set?: InputMaybe<Array<StoryWhereUniqueInput>>;
  update?: InputMaybe<Array<StoryUpdateWithWhereUniqueWithoutSchoolInput>>;
  updateMany?: InputMaybe<Array<StoryUpdateManyWithWhereWithoutSchoolInput>>;
  upsert?: InputMaybe<Array<StoryUpsertWithWhereUniqueWithoutSchoolInput>>;
};

export type StoryUpdateManyWithoutStandardsNestedInput = {
  connect?: InputMaybe<Array<StoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StoryCreateOrConnectWithoutStandardsInput>>;
  create?: InputMaybe<Array<StoryCreateWithoutStandardsInput>>;
  delete?: InputMaybe<Array<StoryWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<StoryScalarWhereInput>>;
  disconnect?: InputMaybe<Array<StoryWhereUniqueInput>>;
  set?: InputMaybe<Array<StoryWhereUniqueInput>>;
  update?: InputMaybe<Array<StoryUpdateWithWhereUniqueWithoutStandardsInput>>;
  updateMany?: InputMaybe<Array<StoryUpdateManyWithWhereWithoutStandardsInput>>;
  upsert?: InputMaybe<Array<StoryUpsertWithWhereUniqueWithoutStandardsInput>>;
};

export type StoryUpdateManyWithoutTagNestedInput = {
  connect?: InputMaybe<Array<StoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StoryCreateOrConnectWithoutTagInput>>;
  create?: InputMaybe<Array<StoryCreateWithoutTagInput>>;
  createMany?: InputMaybe<StoryCreateManyTagInputEnvelope>;
  delete?: InputMaybe<Array<StoryWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<StoryScalarWhereInput>>;
  disconnect?: InputMaybe<Array<StoryWhereUniqueInput>>;
  set?: InputMaybe<Array<StoryWhereUniqueInput>>;
  update?: InputMaybe<Array<StoryUpdateWithWhereUniqueWithoutTagInput>>;
  updateMany?: InputMaybe<Array<StoryUpdateManyWithWhereWithoutTagInput>>;
  upsert?: InputMaybe<Array<StoryUpsertWithWhereUniqueWithoutTagInput>>;
};

export type StoryUpdateOneRequiredWithoutActivitiesNestedInput = {
  connect?: InputMaybe<StoryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StoryCreateOrConnectWithoutActivitiesInput>;
  create?: InputMaybe<StoryCreateWithoutActivitiesInput>;
  update?: InputMaybe<StoryUpdateWithoutActivitiesInput>;
  upsert?: InputMaybe<StoryUpsertWithoutActivitiesInput>;
};

export type StoryUpdateWithWhereUniqueWithoutSchoolInput = {
  data: StoryUpdateWithoutSchoolInput;
  where: StoryWhereUniqueInput;
};

export type StoryUpdateWithWhereUniqueWithoutStandardsInput = {
  data: StoryUpdateWithoutStandardsInput;
  where: StoryWhereUniqueInput;
};

export type StoryUpdateWithWhereUniqueWithoutTagInput = {
  data: StoryUpdateWithoutTagInput;
  where: StoryWhereUniqueInput;
};

export type StoryUpdateWithoutActivitiesInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  displayOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  school?: InputMaybe<SchoolUpdateOneWithoutStoriesNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  standards?: InputMaybe<StandardUpdateManyWithoutStoriesNestedInput>;
  tag?: InputMaybe<StoryTagUpdateOneWithoutStoriesNestedInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type StoryUpdateWithoutSchoolInput = {
  activities?: InputMaybe<StoryActivityUpdateManyWithoutStoryNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  displayOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  standards?: InputMaybe<StandardUpdateManyWithoutStoriesNestedInput>;
  tag?: InputMaybe<StoryTagUpdateOneWithoutStoriesNestedInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type StoryUpdateWithoutStandardsInput = {
  activities?: InputMaybe<StoryActivityUpdateManyWithoutStoryNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  displayOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  school?: InputMaybe<SchoolUpdateOneWithoutStoriesNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  tag?: InputMaybe<StoryTagUpdateOneWithoutStoriesNestedInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type StoryUpdateWithoutTagInput = {
  activities?: InputMaybe<StoryActivityUpdateManyWithoutStoryNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  displayOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  school?: InputMaybe<SchoolUpdateOneWithoutStoriesNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  standards?: InputMaybe<StandardUpdateManyWithoutStoriesNestedInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type StoryUpsertWithWhereUniqueWithoutSchoolInput = {
  create: StoryCreateWithoutSchoolInput;
  update: StoryUpdateWithoutSchoolInput;
  where: StoryWhereUniqueInput;
};

export type StoryUpsertWithWhereUniqueWithoutStandardsInput = {
  create: StoryCreateWithoutStandardsInput;
  update: StoryUpdateWithoutStandardsInput;
  where: StoryWhereUniqueInput;
};

export type StoryUpsertWithWhereUniqueWithoutTagInput = {
  create: StoryCreateWithoutTagInput;
  update: StoryUpdateWithoutTagInput;
  where: StoryWhereUniqueInput;
};

export type StoryUpsertWithoutActivitiesInput = {
  create: StoryCreateWithoutActivitiesInput;
  update: StoryUpdateWithoutActivitiesInput;
};

export type StoryWhereInput = {
  AND?: InputMaybe<Array<StoryWhereInput>>;
  NOT?: InputMaybe<Array<StoryWhereInput>>;
  OR?: InputMaybe<Array<StoryWhereInput>>;
  activities?: InputMaybe<StoryActivityListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  displayOrder?: InputMaybe<IntFilter>;
  featuredImage?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  image?: InputMaybe<StringNullableFilter>;
  isActive?: InputMaybe<BoolFilter>;
  school?: InputMaybe<SchoolRelationFilter>;
  schoolId?: InputMaybe<IntNullableFilter>;
  slug?: InputMaybe<StringFilter>;
  standards?: InputMaybe<StandardListRelationFilter>;
  tag?: InputMaybe<StoryTagRelationFilter>;
  tagId?: InputMaybe<IntNullableFilter>;
  title?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type StoryWhereUniqueInput = {
  id?: InputMaybe<Scalars["Int"]["input"]>;
  slug?: InputMaybe<Scalars["String"]["input"]>;
};

export type StringFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars["String"]["input"]>;
};

export type StringFilter = {
  contains?: InputMaybe<Scalars["String"]["input"]>;
  endsWith?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  gt?: InputMaybe<Scalars["String"]["input"]>;
  gte?: InputMaybe<Scalars["String"]["input"]>;
  in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  lt?: InputMaybe<Scalars["String"]["input"]>;
  lte?: InputMaybe<Scalars["String"]["input"]>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars["String"]["input"]>>;
  startsWith?: InputMaybe<Scalars["String"]["input"]>;
};

export type StringNullableFilter = {
  contains?: InputMaybe<Scalars["String"]["input"]>;
  endsWith?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  gt?: InputMaybe<Scalars["String"]["input"]>;
  gte?: InputMaybe<Scalars["String"]["input"]>;
  in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  lt?: InputMaybe<Scalars["String"]["input"]>;
  lte?: InputMaybe<Scalars["String"]["input"]>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars["String"]["input"]>>;
  startsWith?: InputMaybe<Scalars["String"]["input"]>;
};

export type StringNullableListFilter = {
  equals?: InputMaybe<Array<Scalars["String"]["input"]>>;
  has?: InputMaybe<Scalars["String"]["input"]>;
  hasEvery?: InputMaybe<Array<Scalars["String"]["input"]>>;
  hasSome?: InputMaybe<Array<Scalars["String"]["input"]>>;
  isEmpty?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type StringNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedStringNullableFilter>;
  _min?: InputMaybe<NestedStringNullableFilter>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  endsWith?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  gt?: InputMaybe<Scalars["String"]["input"]>;
  gte?: InputMaybe<Scalars["String"]["input"]>;
  in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  lt?: InputMaybe<Scalars["String"]["input"]>;
  lte?: InputMaybe<Scalars["String"]["input"]>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars["String"]["input"]>>;
  startsWith?: InputMaybe<Scalars["String"]["input"]>;
};

export type StringWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedStringFilter>;
  _min?: InputMaybe<NestedStringFilter>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  endsWith?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  gt?: InputMaybe<Scalars["String"]["input"]>;
  gte?: InputMaybe<Scalars["String"]["input"]>;
  in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  lt?: InputMaybe<Scalars["String"]["input"]>;
  lte?: InputMaybe<Scalars["String"]["input"]>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars["String"]["input"]>>;
  startsWith?: InputMaybe<Scalars["String"]["input"]>;
};

export type Student = {
  __typename?: "Student";
  _count?: Maybe<StudentCount>;
  address1?: Maybe<Scalars["String"]["output"]>;
  address2?: Maybe<Scalars["String"]["output"]>;
  badges: Array<StudentBadge>;
  city?: Maybe<Scalars["String"]["output"]>;
  comments: Array<Comment>;
  country?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  displayName: Scalars["String"]["output"];
  dob?: Maybe<Scalars["String"]["output"]>;
  email?: Maybe<Scalars["String"]["output"]>;
  firstName: Scalars["String"]["output"];
  gameLogs: Array<StudentGameLog>;
  id: Scalars["Int"]["output"];
  invitedStudents?: Maybe<ReferralCodeHistory>;
  isActive: Scalars["Boolean"]["output"];
  isHomeschooler: Scalars["Boolean"]["output"];
  lastLoginAt?: Maybe<Scalars["DateTime"]["output"]>;
  lastName: Scalars["String"]["output"];
  level: Scalars["Int"]["output"];
  meta?: Maybe<Scalars["JSON"]["output"]>;
  notificationTokens: Array<StudentNotifications>;
  orders: Array<Order>;
  phoneNumber?: Maybe<Scalars["String"]["output"]>;
  postcode?: Maybe<Scalars["String"]["output"]>;
  privacyPolicyUpdates: Array<PrivacyPolicyUpdate>;
  profileImage?: Maybe<Scalars["String"]["output"]>;
  promoCodeHistory: Array<PromoCodeHistory>;
  records: Array<StudentRecord>;
  referralCodes: Array<ReferralCode>;
  referralStudents?: Maybe<ReferralCodeHistory>;
  role: Role;
  school: School;
  schoolId: Scalars["Int"]["output"];
  schoolText?: Maybe<Scalars["String"]["output"]>;
  standard: Standard;
  standardId: Scalars["Int"]["output"];
  state?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
  workshopRegistrations: Array<WorkshopRegistration>;
};


export type StudentBadgesArgs = {
  cursor?: InputMaybe<StudentBadgeWhereUniqueInput>;
  distinct?: InputMaybe<Array<StudentBadgeScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StudentBadgeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<StudentBadgeWhereInput>;
};


export type StudentCommentsArgs = {
  cursor?: InputMaybe<CommentWhereUniqueInput>;
  distinct?: InputMaybe<Array<CommentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CommentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<CommentWhereInput>;
};


export type StudentGameLogsArgs = {
  cursor?: InputMaybe<StudentGameLogWhereUniqueInput>;
  distinct?: InputMaybe<Array<StudentGameLogScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StudentGameLogOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<StudentGameLogWhereInput>;
};


export type StudentNotificationTokensArgs = {
  cursor?: InputMaybe<StudentNotificationsWhereUniqueInput>;
  distinct?: InputMaybe<Array<StudentNotificationsScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StudentNotificationsOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<StudentNotificationsWhereInput>;
};


export type StudentOrdersArgs = {
  cursor?: InputMaybe<OrderWhereUniqueInput>;
  distinct?: InputMaybe<Array<OrderScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<OrderOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<OrderWhereInput>;
};


export type StudentPrivacyPolicyUpdatesArgs = {
  cursor?: InputMaybe<PrivacyPolicyUpdateWhereUniqueInput>;
  distinct?: InputMaybe<Array<PrivacyPolicyUpdateScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PrivacyPolicyUpdateOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<PrivacyPolicyUpdateWhereInput>;
};


export type StudentPromoCodeHistoryArgs = {
  cursor?: InputMaybe<PromoCodeHistoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<PromoCodeHistoryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PromoCodeHistoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<PromoCodeHistoryWhereInput>;
};


export type StudentRecordsArgs = {
  cursor?: InputMaybe<StudentRecordWhereUniqueInput>;
  distinct?: InputMaybe<Array<StudentRecordScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StudentRecordOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<StudentRecordWhereInput>;
};


export type StudentReferralCodesArgs = {
  cursor?: InputMaybe<ReferralCodeWhereUniqueInput>;
  distinct?: InputMaybe<Array<ReferralCodeScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ReferralCodeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ReferralCodeWhereInput>;
};


export type StudentWorkshopRegistrationsArgs = {
  cursor?: InputMaybe<WorkshopRegistrationWhereUniqueInput>;
  distinct?: InputMaybe<Array<WorkshopRegistrationScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<WorkshopRegistrationOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<WorkshopRegistrationWhereInput>;
};

export type StudentAssignBadgeInput = {
  badge: BadgeWhereUniqueInput;
  student: StudentWhereUniqueInput;
};

export type StudentAssignBadgeResponse = {
  __typename?: "StudentAssignBadgeResponse";
  message: Scalars["String"]["output"];
  success: Scalars["Boolean"]["output"];
};

export type StudentAvgAggregate = {
  __typename?: "StudentAvgAggregate";
  id?: Maybe<Scalars["Float"]["output"]>;
  level?: Maybe<Scalars["Float"]["output"]>;
  schoolId?: Maybe<Scalars["Float"]["output"]>;
  standardId?: Maybe<Scalars["Float"]["output"]>;
};

export type StudentBadge = {
  __typename?: "StudentBadge";
  badgeId: Scalars["Int"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["Int"]["output"];
  studentId: Scalars["Int"]["output"];
};

export type StudentBadgeCreateManyBadgeInput = {
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  studentId: Scalars["Int"]["input"];
};

export type StudentBadgeCreateManyBadgeInputEnvelope = {
  data: Array<StudentBadgeCreateManyBadgeInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type StudentBadgeCreateManyStudentInput = {
  badgeId: Scalars["Int"]["input"];
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
};

export type StudentBadgeCreateManyStudentInputEnvelope = {
  data: Array<StudentBadgeCreateManyStudentInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type StudentBadgeCreateNestedManyWithoutBadgeInput = {
  connect?: InputMaybe<Array<StudentBadgeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StudentBadgeCreateOrConnectWithoutBadgeInput>>;
  create?: InputMaybe<Array<StudentBadgeCreateWithoutBadgeInput>>;
  createMany?: InputMaybe<StudentBadgeCreateManyBadgeInputEnvelope>;
};

export type StudentBadgeCreateNestedManyWithoutStudentInput = {
  connect?: InputMaybe<Array<StudentBadgeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StudentBadgeCreateOrConnectWithoutStudentInput>>;
  create?: InputMaybe<Array<StudentBadgeCreateWithoutStudentInput>>;
  createMany?: InputMaybe<StudentBadgeCreateManyStudentInputEnvelope>;
};

export type StudentBadgeCreateOrConnectWithoutBadgeInput = {
  create: StudentBadgeCreateWithoutBadgeInput;
  where: StudentBadgeWhereUniqueInput;
};

export type StudentBadgeCreateOrConnectWithoutStudentInput = {
  create: StudentBadgeCreateWithoutStudentInput;
  where: StudentBadgeWhereUniqueInput;
};

export type StudentBadgeCreateWithoutBadgeInput = {
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  student: StudentCreateNestedOneWithoutBadgesInput;
};

export type StudentBadgeCreateWithoutStudentInput = {
  badge: BadgeCreateNestedOneWithoutStudentBadgesInput;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type StudentBadgeListRelationFilter = {
  every?: InputMaybe<StudentBadgeWhereInput>;
  none?: InputMaybe<StudentBadgeWhereInput>;
  some?: InputMaybe<StudentBadgeWhereInput>;
};

export type StudentBadgeOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type StudentBadgeOrderByWithRelationInput = {
  badge?: InputMaybe<BadgeOrderByWithRelationInput>;
  badgeId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  student?: InputMaybe<StudentOrderByWithRelationInput>;
  studentId?: InputMaybe<SortOrder>;
};

export enum StudentBadgeScalarFieldEnum {
  BADGEID = "badgeId",
  CREATEDAT = "createdAt",
  ID = "id",
  STUDENTID = "studentId"
}

export type StudentBadgeScalarWhereInput = {
  AND?: InputMaybe<Array<StudentBadgeScalarWhereInput>>;
  NOT?: InputMaybe<Array<StudentBadgeScalarWhereInput>>;
  OR?: InputMaybe<Array<StudentBadgeScalarWhereInput>>;
  badgeId?: InputMaybe<IntFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  studentId?: InputMaybe<IntFilter>;
};

export type StudentBadgeUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type StudentBadgeUpdateManyWithWhereWithoutBadgeInput = {
  data: StudentBadgeUpdateManyMutationInput;
  where: StudentBadgeScalarWhereInput;
};

export type StudentBadgeUpdateManyWithWhereWithoutStudentInput = {
  data: StudentBadgeUpdateManyMutationInput;
  where: StudentBadgeScalarWhereInput;
};

export type StudentBadgeUpdateManyWithoutBadgeNestedInput = {
  connect?: InputMaybe<Array<StudentBadgeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StudentBadgeCreateOrConnectWithoutBadgeInput>>;
  create?: InputMaybe<Array<StudentBadgeCreateWithoutBadgeInput>>;
  createMany?: InputMaybe<StudentBadgeCreateManyBadgeInputEnvelope>;
  delete?: InputMaybe<Array<StudentBadgeWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<StudentBadgeScalarWhereInput>>;
  disconnect?: InputMaybe<Array<StudentBadgeWhereUniqueInput>>;
  set?: InputMaybe<Array<StudentBadgeWhereUniqueInput>>;
  update?: InputMaybe<Array<StudentBadgeUpdateWithWhereUniqueWithoutBadgeInput>>;
  updateMany?: InputMaybe<Array<StudentBadgeUpdateManyWithWhereWithoutBadgeInput>>;
  upsert?: InputMaybe<Array<StudentBadgeUpsertWithWhereUniqueWithoutBadgeInput>>;
};

export type StudentBadgeUpdateManyWithoutStudentNestedInput = {
  connect?: InputMaybe<Array<StudentBadgeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StudentBadgeCreateOrConnectWithoutStudentInput>>;
  create?: InputMaybe<Array<StudentBadgeCreateWithoutStudentInput>>;
  createMany?: InputMaybe<StudentBadgeCreateManyStudentInputEnvelope>;
  delete?: InputMaybe<Array<StudentBadgeWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<StudentBadgeScalarWhereInput>>;
  disconnect?: InputMaybe<Array<StudentBadgeWhereUniqueInput>>;
  set?: InputMaybe<Array<StudentBadgeWhereUniqueInput>>;
  update?: InputMaybe<Array<StudentBadgeUpdateWithWhereUniqueWithoutStudentInput>>;
  updateMany?: InputMaybe<Array<StudentBadgeUpdateManyWithWhereWithoutStudentInput>>;
  upsert?: InputMaybe<Array<StudentBadgeUpsertWithWhereUniqueWithoutStudentInput>>;
};

export type StudentBadgeUpdateWithWhereUniqueWithoutBadgeInput = {
  data: StudentBadgeUpdateWithoutBadgeInput;
  where: StudentBadgeWhereUniqueInput;
};

export type StudentBadgeUpdateWithWhereUniqueWithoutStudentInput = {
  data: StudentBadgeUpdateWithoutStudentInput;
  where: StudentBadgeWhereUniqueInput;
};

export type StudentBadgeUpdateWithoutBadgeInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  student?: InputMaybe<StudentUpdateOneRequiredWithoutBadgesNestedInput>;
};

export type StudentBadgeUpdateWithoutStudentInput = {
  badge?: InputMaybe<BadgeUpdateOneRequiredWithoutStudentBadgesNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type StudentBadgeUpsertWithWhereUniqueWithoutBadgeInput = {
  create: StudentBadgeCreateWithoutBadgeInput;
  update: StudentBadgeUpdateWithoutBadgeInput;
  where: StudentBadgeWhereUniqueInput;
};

export type StudentBadgeUpsertWithWhereUniqueWithoutStudentInput = {
  create: StudentBadgeCreateWithoutStudentInput;
  update: StudentBadgeUpdateWithoutStudentInput;
  where: StudentBadgeWhereUniqueInput;
};

export type StudentBadgeWhereInput = {
  AND?: InputMaybe<Array<StudentBadgeWhereInput>>;
  NOT?: InputMaybe<Array<StudentBadgeWhereInput>>;
  OR?: InputMaybe<Array<StudentBadgeWhereInput>>;
  badge?: InputMaybe<BadgeRelationFilter>;
  badgeId?: InputMaybe<IntFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  student?: InputMaybe<StudentRelationFilter>;
  studentId?: InputMaybe<IntFilter>;
};

export type StudentBadgeWhereUniqueInput = {
  id?: InputMaybe<Scalars["Int"]["input"]>;
};

export type StudentCoinLogsResponse = {
  __typename?: "StudentCoinLogsResponse";
  data?: Maybe<Scalars["JSON"]["output"]>;
};

export type StudentCount = {
  __typename?: "StudentCount";
  badges: Scalars["Int"]["output"];
  comments: Scalars["Int"]["output"];
  gameLogs: Scalars["Int"]["output"];
  notificationTokens: Scalars["Int"]["output"];
  orders: Scalars["Int"]["output"];
  privacyPolicyUpdates: Scalars["Int"]["output"];
  promoCodeHistory: Scalars["Int"]["output"];
  records: Scalars["Int"]["output"];
  referralCodes: Scalars["Int"]["output"];
  workshopRegistrations: Scalars["Int"]["output"];
};


export type StudentCountBadgesArgs = {
  where?: InputMaybe<StudentBadgeWhereInput>;
};


export type StudentCountCommentsArgs = {
  where?: InputMaybe<CommentWhereInput>;
};


export type StudentCountGameLogsArgs = {
  where?: InputMaybe<StudentGameLogWhereInput>;
};


export type StudentCountNotificationTokensArgs = {
  where?: InputMaybe<StudentNotificationsWhereInput>;
};


export type StudentCountOrdersArgs = {
  where?: InputMaybe<OrderWhereInput>;
};


export type StudentCountPrivacyPolicyUpdatesArgs = {
  where?: InputMaybe<PrivacyPolicyUpdateWhereInput>;
};


export type StudentCountPromoCodeHistoryArgs = {
  where?: InputMaybe<PromoCodeHistoryWhereInput>;
};


export type StudentCountRecordsArgs = {
  where?: InputMaybe<StudentRecordWhereInput>;
};


export type StudentCountReferralCodesArgs = {
  where?: InputMaybe<ReferralCodeWhereInput>;
};


export type StudentCountWorkshopRegistrationsArgs = {
  where?: InputMaybe<WorkshopRegistrationWhereInput>;
};

export type StudentCountAggregate = {
  __typename?: "StudentCountAggregate";
  _all: Scalars["Int"]["output"];
  address1: Scalars["Int"]["output"];
  address2: Scalars["Int"]["output"];
  city: Scalars["Int"]["output"];
  country: Scalars["Int"]["output"];
  createdAt: Scalars["Int"]["output"];
  displayName: Scalars["Int"]["output"];
  dob: Scalars["Int"]["output"];
  email: Scalars["Int"]["output"];
  firstName: Scalars["Int"]["output"];
  id: Scalars["Int"]["output"];
  isActive: Scalars["Int"]["output"];
  isHomeschooler: Scalars["Int"]["output"];
  lastLoginAt: Scalars["Int"]["output"];
  lastName: Scalars["Int"]["output"];
  level: Scalars["Int"]["output"];
  meta: Scalars["Int"]["output"];
  phoneNumber: Scalars["Int"]["output"];
  postcode: Scalars["Int"]["output"];
  profileImage: Scalars["Int"]["output"];
  role: Scalars["Int"]["output"];
  schoolId: Scalars["Int"]["output"];
  schoolText: Scalars["Int"]["output"];
  standardId: Scalars["Int"]["output"];
  state: Scalars["Int"]["output"];
  updatedAt: Scalars["Int"]["output"];
};

export type StudentCreateCommentInput = {
  activity?: InputMaybe<ActivityWhereUniqueInput>;
  attachmentUrl?: InputMaybe<Scalars["String"]["input"]>;
  isDailyChallenge?: InputMaybe<Scalars["Boolean"]["input"]>;
  storyActivity?: InputMaybe<StoryActivityWhereUniqueInput>;
  text: Scalars["String"]["input"];
};

export type StudentCreateInput = {
  address1?: InputMaybe<Scalars["String"]["input"]>;
  address2?: InputMaybe<Scalars["String"]["input"]>;
  badges?: InputMaybe<StudentBadgeCreateNestedManyWithoutStudentInput>;
  city?: InputMaybe<Scalars["String"]["input"]>;
  comments?: InputMaybe<CommentCreateNestedManyWithoutCreatedByInput>;
  country?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  displayName: Scalars["String"]["input"];
  dob?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  firstName: Scalars["String"]["input"];
  gameLogs?: InputMaybe<StudentGameLogCreateNestedManyWithoutStudentInput>;
  invitedStudents?: InputMaybe<ReferralCodeHistoryCreateNestedOneWithoutInvitedStudentInput>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  isHomeschooler?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastLoginAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastName: Scalars["String"]["input"];
  level?: InputMaybe<Scalars["Int"]["input"]>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  notificationTokens?: InputMaybe<StudentNotificationsCreateNestedManyWithoutStudentInput>;
  orders?: InputMaybe<OrderCreateNestedManyWithoutCustomerInput>;
  phoneNumber?: InputMaybe<Scalars["String"]["input"]>;
  postcode?: InputMaybe<Scalars["String"]["input"]>;
  privacyPolicyUpdates?: InputMaybe<PrivacyPolicyUpdateCreateNestedManyWithoutStudentInput>;
  profileImage?: InputMaybe<Scalars["String"]["input"]>;
  promoCodeHistory?: InputMaybe<PromoCodeHistoryCreateNestedManyWithoutStudentInput>;
  records?: InputMaybe<StudentRecordCreateNestedManyWithoutStudentInput>;
  referralCodes?: InputMaybe<ReferralCodeCreateNestedManyWithoutStudentInput>;
  referralStudents?: InputMaybe<ReferralCodeHistoryCreateNestedOneWithoutReferralStudentInput>;
  role?: InputMaybe<Role>;
  school: SchoolCreateNestedOneWithoutStudentsInput;
  schoolText?: InputMaybe<Scalars["String"]["input"]>;
  standard: StandardCreateNestedOneWithoutStudentsInput;
  state?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  workshopRegistrations?: InputMaybe<WorkshopRegistrationCreateNestedManyWithoutStudentInput>;
};

export type StudentCreateManySchoolInput = {
  address1?: InputMaybe<Scalars["String"]["input"]>;
  address2?: InputMaybe<Scalars["String"]["input"]>;
  city?: InputMaybe<Scalars["String"]["input"]>;
  country?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  displayName: Scalars["String"]["input"];
  dob?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  firstName: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["Int"]["input"]>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  isHomeschooler?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastLoginAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastName: Scalars["String"]["input"];
  level?: InputMaybe<Scalars["Int"]["input"]>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  phoneNumber?: InputMaybe<Scalars["String"]["input"]>;
  postcode?: InputMaybe<Scalars["String"]["input"]>;
  profileImage?: InputMaybe<Scalars["String"]["input"]>;
  role?: InputMaybe<Role>;
  schoolText?: InputMaybe<Scalars["String"]["input"]>;
  standardId: Scalars["Int"]["input"];
  state?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type StudentCreateManySchoolInputEnvelope = {
  data: Array<StudentCreateManySchoolInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type StudentCreateManyStandardInput = {
  address1?: InputMaybe<Scalars["String"]["input"]>;
  address2?: InputMaybe<Scalars["String"]["input"]>;
  city?: InputMaybe<Scalars["String"]["input"]>;
  country?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  displayName: Scalars["String"]["input"];
  dob?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  firstName: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["Int"]["input"]>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  isHomeschooler?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastLoginAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastName: Scalars["String"]["input"];
  level?: InputMaybe<Scalars["Int"]["input"]>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  phoneNumber?: InputMaybe<Scalars["String"]["input"]>;
  postcode?: InputMaybe<Scalars["String"]["input"]>;
  profileImage?: InputMaybe<Scalars["String"]["input"]>;
  role?: InputMaybe<Role>;
  schoolId: Scalars["Int"]["input"];
  schoolText?: InputMaybe<Scalars["String"]["input"]>;
  state?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type StudentCreateManyStandardInputEnvelope = {
  data: Array<StudentCreateManyStandardInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type StudentCreateNestedManyWithoutSchoolInput = {
  connect?: InputMaybe<Array<StudentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StudentCreateOrConnectWithoutSchoolInput>>;
  create?: InputMaybe<Array<StudentCreateWithoutSchoolInput>>;
  createMany?: InputMaybe<StudentCreateManySchoolInputEnvelope>;
};

export type StudentCreateNestedManyWithoutStandardInput = {
  connect?: InputMaybe<Array<StudentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StudentCreateOrConnectWithoutStandardInput>>;
  create?: InputMaybe<Array<StudentCreateWithoutStandardInput>>;
  createMany?: InputMaybe<StudentCreateManyStandardInputEnvelope>;
};

export type StudentCreateNestedOneWithoutBadgesInput = {
  connect?: InputMaybe<StudentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StudentCreateOrConnectWithoutBadgesInput>;
  create?: InputMaybe<StudentCreateWithoutBadgesInput>;
};

export type StudentCreateNestedOneWithoutCommentsInput = {
  connect?: InputMaybe<StudentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StudentCreateOrConnectWithoutCommentsInput>;
  create?: InputMaybe<StudentCreateWithoutCommentsInput>;
};

export type StudentCreateNestedOneWithoutGameLogsInput = {
  connect?: InputMaybe<StudentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StudentCreateOrConnectWithoutGameLogsInput>;
  create?: InputMaybe<StudentCreateWithoutGameLogsInput>;
};

export type StudentCreateNestedOneWithoutInvitedStudentsInput = {
  connect?: InputMaybe<StudentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StudentCreateOrConnectWithoutInvitedStudentsInput>;
  create?: InputMaybe<StudentCreateWithoutInvitedStudentsInput>;
};

export type StudentCreateNestedOneWithoutOrdersInput = {
  connect?: InputMaybe<StudentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StudentCreateOrConnectWithoutOrdersInput>;
  create?: InputMaybe<StudentCreateWithoutOrdersInput>;
};

export type StudentCreateNestedOneWithoutPrivacyPolicyUpdatesInput = {
  connect?: InputMaybe<StudentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StudentCreateOrConnectWithoutPrivacyPolicyUpdatesInput>;
  create?: InputMaybe<StudentCreateWithoutPrivacyPolicyUpdatesInput>;
};

export type StudentCreateNestedOneWithoutPromoCodeHistoryInput = {
  connect?: InputMaybe<StudentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StudentCreateOrConnectWithoutPromoCodeHistoryInput>;
  create?: InputMaybe<StudentCreateWithoutPromoCodeHistoryInput>;
};

export type StudentCreateNestedOneWithoutRecordsInput = {
  connect?: InputMaybe<StudentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StudentCreateOrConnectWithoutRecordsInput>;
  create?: InputMaybe<StudentCreateWithoutRecordsInput>;
};

export type StudentCreateNestedOneWithoutReferralCodesInput = {
  connect?: InputMaybe<StudentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StudentCreateOrConnectWithoutReferralCodesInput>;
  create?: InputMaybe<StudentCreateWithoutReferralCodesInput>;
};

export type StudentCreateNestedOneWithoutReferralStudentsInput = {
  connect?: InputMaybe<StudentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StudentCreateOrConnectWithoutReferralStudentsInput>;
  create?: InputMaybe<StudentCreateWithoutReferralStudentsInput>;
};

export type StudentCreateNestedOneWithoutWorkshopRegistrationsInput = {
  connect?: InputMaybe<StudentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StudentCreateOrConnectWithoutWorkshopRegistrationsInput>;
  create?: InputMaybe<StudentCreateWithoutWorkshopRegistrationsInput>;
};

export type StudentCreateOrConnectWithoutBadgesInput = {
  create: StudentCreateWithoutBadgesInput;
  where: StudentWhereUniqueInput;
};

export type StudentCreateOrConnectWithoutCommentsInput = {
  create: StudentCreateWithoutCommentsInput;
  where: StudentWhereUniqueInput;
};

export type StudentCreateOrConnectWithoutGameLogsInput = {
  create: StudentCreateWithoutGameLogsInput;
  where: StudentWhereUniqueInput;
};

export type StudentCreateOrConnectWithoutInvitedStudentsInput = {
  create: StudentCreateWithoutInvitedStudentsInput;
  where: StudentWhereUniqueInput;
};

export type StudentCreateOrConnectWithoutOrdersInput = {
  create: StudentCreateWithoutOrdersInput;
  where: StudentWhereUniqueInput;
};

export type StudentCreateOrConnectWithoutPrivacyPolicyUpdatesInput = {
  create: StudentCreateWithoutPrivacyPolicyUpdatesInput;
  where: StudentWhereUniqueInput;
};

export type StudentCreateOrConnectWithoutPromoCodeHistoryInput = {
  create: StudentCreateWithoutPromoCodeHistoryInput;
  where: StudentWhereUniqueInput;
};

export type StudentCreateOrConnectWithoutRecordsInput = {
  create: StudentCreateWithoutRecordsInput;
  where: StudentWhereUniqueInput;
};

export type StudentCreateOrConnectWithoutReferralCodesInput = {
  create: StudentCreateWithoutReferralCodesInput;
  where: StudentWhereUniqueInput;
};

export type StudentCreateOrConnectWithoutReferralStudentsInput = {
  create: StudentCreateWithoutReferralStudentsInput;
  where: StudentWhereUniqueInput;
};

export type StudentCreateOrConnectWithoutSchoolInput = {
  create: StudentCreateWithoutSchoolInput;
  where: StudentWhereUniqueInput;
};

export type StudentCreateOrConnectWithoutStandardInput = {
  create: StudentCreateWithoutStandardInput;
  where: StudentWhereUniqueInput;
};

export type StudentCreateOrConnectWithoutWorkshopRegistrationsInput = {
  create: StudentCreateWithoutWorkshopRegistrationsInput;
  where: StudentWhereUniqueInput;
};

export type StudentCreateOrderOutput = {
  __typename?: "StudentCreateOrderOutput";
  message: Scalars["String"]["output"];
  paymentUrl?: Maybe<Scalars["String"]["output"]>;
  success: Scalars["Boolean"]["output"];
};

export type StudentCreateOrderProductInput = {
  paymentMethod: PaymentMethod;
  products: Array<Scalars["Int"]["input"]>;
  shippingAddress1?: InputMaybe<Scalars["String"]["input"]>;
  shippingAddress2?: InputMaybe<Scalars["String"]["input"]>;
  shippingCity?: InputMaybe<Scalars["String"]["input"]>;
  shippingCountry?: InputMaybe<Scalars["String"]["input"]>;
  shippingFirstName?: InputMaybe<Scalars["String"]["input"]>;
  shippingLastName?: InputMaybe<Scalars["String"]["input"]>;
  shippingPhone?: InputMaybe<Scalars["String"]["input"]>;
  shippingPostcode?: InputMaybe<Scalars["String"]["input"]>;
  shippingState?: InputMaybe<Scalars["String"]["input"]>;
  workshop?: InputMaybe<StudentWorkshopUniqueInput>;
};

export type StudentCreateWithoutBadgesInput = {
  address1?: InputMaybe<Scalars["String"]["input"]>;
  address2?: InputMaybe<Scalars["String"]["input"]>;
  city?: InputMaybe<Scalars["String"]["input"]>;
  comments?: InputMaybe<CommentCreateNestedManyWithoutCreatedByInput>;
  country?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  displayName: Scalars["String"]["input"];
  dob?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  firstName: Scalars["String"]["input"];
  gameLogs?: InputMaybe<StudentGameLogCreateNestedManyWithoutStudentInput>;
  invitedStudents?: InputMaybe<ReferralCodeHistoryCreateNestedOneWithoutInvitedStudentInput>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  isHomeschooler?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastLoginAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastName: Scalars["String"]["input"];
  level?: InputMaybe<Scalars["Int"]["input"]>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  notificationTokens?: InputMaybe<StudentNotificationsCreateNestedManyWithoutStudentInput>;
  orders?: InputMaybe<OrderCreateNestedManyWithoutCustomerInput>;
  phoneNumber?: InputMaybe<Scalars["String"]["input"]>;
  postcode?: InputMaybe<Scalars["String"]["input"]>;
  privacyPolicyUpdates?: InputMaybe<PrivacyPolicyUpdateCreateNestedManyWithoutStudentInput>;
  profileImage?: InputMaybe<Scalars["String"]["input"]>;
  promoCodeHistory?: InputMaybe<PromoCodeHistoryCreateNestedManyWithoutStudentInput>;
  records?: InputMaybe<StudentRecordCreateNestedManyWithoutStudentInput>;
  referralCodes?: InputMaybe<ReferralCodeCreateNestedManyWithoutStudentInput>;
  referralStudents?: InputMaybe<ReferralCodeHistoryCreateNestedOneWithoutReferralStudentInput>;
  role?: InputMaybe<Role>;
  school: SchoolCreateNestedOneWithoutStudentsInput;
  schoolText?: InputMaybe<Scalars["String"]["input"]>;
  standard: StandardCreateNestedOneWithoutStudentsInput;
  state?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  workshopRegistrations?: InputMaybe<WorkshopRegistrationCreateNestedManyWithoutStudentInput>;
};

export type StudentCreateWithoutCommentsInput = {
  address1?: InputMaybe<Scalars["String"]["input"]>;
  address2?: InputMaybe<Scalars["String"]["input"]>;
  badges?: InputMaybe<StudentBadgeCreateNestedManyWithoutStudentInput>;
  city?: InputMaybe<Scalars["String"]["input"]>;
  country?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  displayName: Scalars["String"]["input"];
  dob?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  firstName: Scalars["String"]["input"];
  gameLogs?: InputMaybe<StudentGameLogCreateNestedManyWithoutStudentInput>;
  invitedStudents?: InputMaybe<ReferralCodeHistoryCreateNestedOneWithoutInvitedStudentInput>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  isHomeschooler?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastLoginAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastName: Scalars["String"]["input"];
  level?: InputMaybe<Scalars["Int"]["input"]>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  notificationTokens?: InputMaybe<StudentNotificationsCreateNestedManyWithoutStudentInput>;
  orders?: InputMaybe<OrderCreateNestedManyWithoutCustomerInput>;
  phoneNumber?: InputMaybe<Scalars["String"]["input"]>;
  postcode?: InputMaybe<Scalars["String"]["input"]>;
  privacyPolicyUpdates?: InputMaybe<PrivacyPolicyUpdateCreateNestedManyWithoutStudentInput>;
  profileImage?: InputMaybe<Scalars["String"]["input"]>;
  promoCodeHistory?: InputMaybe<PromoCodeHistoryCreateNestedManyWithoutStudentInput>;
  records?: InputMaybe<StudentRecordCreateNestedManyWithoutStudentInput>;
  referralCodes?: InputMaybe<ReferralCodeCreateNestedManyWithoutStudentInput>;
  referralStudents?: InputMaybe<ReferralCodeHistoryCreateNestedOneWithoutReferralStudentInput>;
  role?: InputMaybe<Role>;
  school: SchoolCreateNestedOneWithoutStudentsInput;
  schoolText?: InputMaybe<Scalars["String"]["input"]>;
  standard: StandardCreateNestedOneWithoutStudentsInput;
  state?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  workshopRegistrations?: InputMaybe<WorkshopRegistrationCreateNestedManyWithoutStudentInput>;
};

export type StudentCreateWithoutGameLogsInput = {
  address1?: InputMaybe<Scalars["String"]["input"]>;
  address2?: InputMaybe<Scalars["String"]["input"]>;
  badges?: InputMaybe<StudentBadgeCreateNestedManyWithoutStudentInput>;
  city?: InputMaybe<Scalars["String"]["input"]>;
  comments?: InputMaybe<CommentCreateNestedManyWithoutCreatedByInput>;
  country?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  displayName: Scalars["String"]["input"];
  dob?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  firstName: Scalars["String"]["input"];
  invitedStudents?: InputMaybe<ReferralCodeHistoryCreateNestedOneWithoutInvitedStudentInput>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  isHomeschooler?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastLoginAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastName: Scalars["String"]["input"];
  level?: InputMaybe<Scalars["Int"]["input"]>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  notificationTokens?: InputMaybe<StudentNotificationsCreateNestedManyWithoutStudentInput>;
  orders?: InputMaybe<OrderCreateNestedManyWithoutCustomerInput>;
  phoneNumber?: InputMaybe<Scalars["String"]["input"]>;
  postcode?: InputMaybe<Scalars["String"]["input"]>;
  privacyPolicyUpdates?: InputMaybe<PrivacyPolicyUpdateCreateNestedManyWithoutStudentInput>;
  profileImage?: InputMaybe<Scalars["String"]["input"]>;
  promoCodeHistory?: InputMaybe<PromoCodeHistoryCreateNestedManyWithoutStudentInput>;
  records?: InputMaybe<StudentRecordCreateNestedManyWithoutStudentInput>;
  referralCodes?: InputMaybe<ReferralCodeCreateNestedManyWithoutStudentInput>;
  referralStudents?: InputMaybe<ReferralCodeHistoryCreateNestedOneWithoutReferralStudentInput>;
  role?: InputMaybe<Role>;
  school: SchoolCreateNestedOneWithoutStudentsInput;
  schoolText?: InputMaybe<Scalars["String"]["input"]>;
  standard: StandardCreateNestedOneWithoutStudentsInput;
  state?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  workshopRegistrations?: InputMaybe<WorkshopRegistrationCreateNestedManyWithoutStudentInput>;
};

export type StudentCreateWithoutInvitedStudentsInput = {
  address1?: InputMaybe<Scalars["String"]["input"]>;
  address2?: InputMaybe<Scalars["String"]["input"]>;
  badges?: InputMaybe<StudentBadgeCreateNestedManyWithoutStudentInput>;
  city?: InputMaybe<Scalars["String"]["input"]>;
  comments?: InputMaybe<CommentCreateNestedManyWithoutCreatedByInput>;
  country?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  displayName: Scalars["String"]["input"];
  dob?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  firstName: Scalars["String"]["input"];
  gameLogs?: InputMaybe<StudentGameLogCreateNestedManyWithoutStudentInput>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  isHomeschooler?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastLoginAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastName: Scalars["String"]["input"];
  level?: InputMaybe<Scalars["Int"]["input"]>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  notificationTokens?: InputMaybe<StudentNotificationsCreateNestedManyWithoutStudentInput>;
  orders?: InputMaybe<OrderCreateNestedManyWithoutCustomerInput>;
  phoneNumber?: InputMaybe<Scalars["String"]["input"]>;
  postcode?: InputMaybe<Scalars["String"]["input"]>;
  privacyPolicyUpdates?: InputMaybe<PrivacyPolicyUpdateCreateNestedManyWithoutStudentInput>;
  profileImage?: InputMaybe<Scalars["String"]["input"]>;
  promoCodeHistory?: InputMaybe<PromoCodeHistoryCreateNestedManyWithoutStudentInput>;
  records?: InputMaybe<StudentRecordCreateNestedManyWithoutStudentInput>;
  referralCodes?: InputMaybe<ReferralCodeCreateNestedManyWithoutStudentInput>;
  referralStudents?: InputMaybe<ReferralCodeHistoryCreateNestedOneWithoutReferralStudentInput>;
  role?: InputMaybe<Role>;
  school: SchoolCreateNestedOneWithoutStudentsInput;
  schoolText?: InputMaybe<Scalars["String"]["input"]>;
  standard: StandardCreateNestedOneWithoutStudentsInput;
  state?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  workshopRegistrations?: InputMaybe<WorkshopRegistrationCreateNestedManyWithoutStudentInput>;
};

export type StudentCreateWithoutOrdersInput = {
  address1?: InputMaybe<Scalars["String"]["input"]>;
  address2?: InputMaybe<Scalars["String"]["input"]>;
  badges?: InputMaybe<StudentBadgeCreateNestedManyWithoutStudentInput>;
  city?: InputMaybe<Scalars["String"]["input"]>;
  comments?: InputMaybe<CommentCreateNestedManyWithoutCreatedByInput>;
  country?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  displayName: Scalars["String"]["input"];
  dob?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  firstName: Scalars["String"]["input"];
  gameLogs?: InputMaybe<StudentGameLogCreateNestedManyWithoutStudentInput>;
  invitedStudents?: InputMaybe<ReferralCodeHistoryCreateNestedOneWithoutInvitedStudentInput>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  isHomeschooler?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastLoginAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastName: Scalars["String"]["input"];
  level?: InputMaybe<Scalars["Int"]["input"]>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  notificationTokens?: InputMaybe<StudentNotificationsCreateNestedManyWithoutStudentInput>;
  phoneNumber?: InputMaybe<Scalars["String"]["input"]>;
  postcode?: InputMaybe<Scalars["String"]["input"]>;
  privacyPolicyUpdates?: InputMaybe<PrivacyPolicyUpdateCreateNestedManyWithoutStudentInput>;
  profileImage?: InputMaybe<Scalars["String"]["input"]>;
  promoCodeHistory?: InputMaybe<PromoCodeHistoryCreateNestedManyWithoutStudentInput>;
  records?: InputMaybe<StudentRecordCreateNestedManyWithoutStudentInput>;
  referralCodes?: InputMaybe<ReferralCodeCreateNestedManyWithoutStudentInput>;
  referralStudents?: InputMaybe<ReferralCodeHistoryCreateNestedOneWithoutReferralStudentInput>;
  role?: InputMaybe<Role>;
  school: SchoolCreateNestedOneWithoutStudentsInput;
  schoolText?: InputMaybe<Scalars["String"]["input"]>;
  standard: StandardCreateNestedOneWithoutStudentsInput;
  state?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  workshopRegistrations?: InputMaybe<WorkshopRegistrationCreateNestedManyWithoutStudentInput>;
};

export type StudentCreateWithoutPrivacyPolicyUpdatesInput = {
  address1?: InputMaybe<Scalars["String"]["input"]>;
  address2?: InputMaybe<Scalars["String"]["input"]>;
  badges?: InputMaybe<StudentBadgeCreateNestedManyWithoutStudentInput>;
  city?: InputMaybe<Scalars["String"]["input"]>;
  comments?: InputMaybe<CommentCreateNestedManyWithoutCreatedByInput>;
  country?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  displayName: Scalars["String"]["input"];
  dob?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  firstName: Scalars["String"]["input"];
  gameLogs?: InputMaybe<StudentGameLogCreateNestedManyWithoutStudentInput>;
  invitedStudents?: InputMaybe<ReferralCodeHistoryCreateNestedOneWithoutInvitedStudentInput>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  isHomeschooler?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastLoginAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastName: Scalars["String"]["input"];
  level?: InputMaybe<Scalars["Int"]["input"]>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  notificationTokens?: InputMaybe<StudentNotificationsCreateNestedManyWithoutStudentInput>;
  orders?: InputMaybe<OrderCreateNestedManyWithoutCustomerInput>;
  phoneNumber?: InputMaybe<Scalars["String"]["input"]>;
  postcode?: InputMaybe<Scalars["String"]["input"]>;
  profileImage?: InputMaybe<Scalars["String"]["input"]>;
  promoCodeHistory?: InputMaybe<PromoCodeHistoryCreateNestedManyWithoutStudentInput>;
  records?: InputMaybe<StudentRecordCreateNestedManyWithoutStudentInput>;
  referralCodes?: InputMaybe<ReferralCodeCreateNestedManyWithoutStudentInput>;
  referralStudents?: InputMaybe<ReferralCodeHistoryCreateNestedOneWithoutReferralStudentInput>;
  role?: InputMaybe<Role>;
  school: SchoolCreateNestedOneWithoutStudentsInput;
  schoolText?: InputMaybe<Scalars["String"]["input"]>;
  standard: StandardCreateNestedOneWithoutStudentsInput;
  state?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  workshopRegistrations?: InputMaybe<WorkshopRegistrationCreateNestedManyWithoutStudentInput>;
};

export type StudentCreateWithoutPromoCodeHistoryInput = {
  address1?: InputMaybe<Scalars["String"]["input"]>;
  address2?: InputMaybe<Scalars["String"]["input"]>;
  badges?: InputMaybe<StudentBadgeCreateNestedManyWithoutStudentInput>;
  city?: InputMaybe<Scalars["String"]["input"]>;
  comments?: InputMaybe<CommentCreateNestedManyWithoutCreatedByInput>;
  country?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  displayName: Scalars["String"]["input"];
  dob?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  firstName: Scalars["String"]["input"];
  gameLogs?: InputMaybe<StudentGameLogCreateNestedManyWithoutStudentInput>;
  invitedStudents?: InputMaybe<ReferralCodeHistoryCreateNestedOneWithoutInvitedStudentInput>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  isHomeschooler?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastLoginAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastName: Scalars["String"]["input"];
  level?: InputMaybe<Scalars["Int"]["input"]>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  notificationTokens?: InputMaybe<StudentNotificationsCreateNestedManyWithoutStudentInput>;
  orders?: InputMaybe<OrderCreateNestedManyWithoutCustomerInput>;
  phoneNumber?: InputMaybe<Scalars["String"]["input"]>;
  postcode?: InputMaybe<Scalars["String"]["input"]>;
  privacyPolicyUpdates?: InputMaybe<PrivacyPolicyUpdateCreateNestedManyWithoutStudentInput>;
  profileImage?: InputMaybe<Scalars["String"]["input"]>;
  records?: InputMaybe<StudentRecordCreateNestedManyWithoutStudentInput>;
  referralCodes?: InputMaybe<ReferralCodeCreateNestedManyWithoutStudentInput>;
  referralStudents?: InputMaybe<ReferralCodeHistoryCreateNestedOneWithoutReferralStudentInput>;
  role?: InputMaybe<Role>;
  school: SchoolCreateNestedOneWithoutStudentsInput;
  schoolText?: InputMaybe<Scalars["String"]["input"]>;
  standard: StandardCreateNestedOneWithoutStudentsInput;
  state?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  workshopRegistrations?: InputMaybe<WorkshopRegistrationCreateNestedManyWithoutStudentInput>;
};

export type StudentCreateWithoutRecordsInput = {
  address1?: InputMaybe<Scalars["String"]["input"]>;
  address2?: InputMaybe<Scalars["String"]["input"]>;
  badges?: InputMaybe<StudentBadgeCreateNestedManyWithoutStudentInput>;
  city?: InputMaybe<Scalars["String"]["input"]>;
  comments?: InputMaybe<CommentCreateNestedManyWithoutCreatedByInput>;
  country?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  displayName: Scalars["String"]["input"];
  dob?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  firstName: Scalars["String"]["input"];
  gameLogs?: InputMaybe<StudentGameLogCreateNestedManyWithoutStudentInput>;
  invitedStudents?: InputMaybe<ReferralCodeHistoryCreateNestedOneWithoutInvitedStudentInput>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  isHomeschooler?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastLoginAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastName: Scalars["String"]["input"];
  level?: InputMaybe<Scalars["Int"]["input"]>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  notificationTokens?: InputMaybe<StudentNotificationsCreateNestedManyWithoutStudentInput>;
  orders?: InputMaybe<OrderCreateNestedManyWithoutCustomerInput>;
  phoneNumber?: InputMaybe<Scalars["String"]["input"]>;
  postcode?: InputMaybe<Scalars["String"]["input"]>;
  privacyPolicyUpdates?: InputMaybe<PrivacyPolicyUpdateCreateNestedManyWithoutStudentInput>;
  profileImage?: InputMaybe<Scalars["String"]["input"]>;
  promoCodeHistory?: InputMaybe<PromoCodeHistoryCreateNestedManyWithoutStudentInput>;
  referralCodes?: InputMaybe<ReferralCodeCreateNestedManyWithoutStudentInput>;
  referralStudents?: InputMaybe<ReferralCodeHistoryCreateNestedOneWithoutReferralStudentInput>;
  role?: InputMaybe<Role>;
  school: SchoolCreateNestedOneWithoutStudentsInput;
  schoolText?: InputMaybe<Scalars["String"]["input"]>;
  standard: StandardCreateNestedOneWithoutStudentsInput;
  state?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  workshopRegistrations?: InputMaybe<WorkshopRegistrationCreateNestedManyWithoutStudentInput>;
};

export type StudentCreateWithoutReferralCodesInput = {
  address1?: InputMaybe<Scalars["String"]["input"]>;
  address2?: InputMaybe<Scalars["String"]["input"]>;
  badges?: InputMaybe<StudentBadgeCreateNestedManyWithoutStudentInput>;
  city?: InputMaybe<Scalars["String"]["input"]>;
  comments?: InputMaybe<CommentCreateNestedManyWithoutCreatedByInput>;
  country?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  displayName: Scalars["String"]["input"];
  dob?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  firstName: Scalars["String"]["input"];
  gameLogs?: InputMaybe<StudentGameLogCreateNestedManyWithoutStudentInput>;
  invitedStudents?: InputMaybe<ReferralCodeHistoryCreateNestedOneWithoutInvitedStudentInput>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  isHomeschooler?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastLoginAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastName: Scalars["String"]["input"];
  level?: InputMaybe<Scalars["Int"]["input"]>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  notificationTokens?: InputMaybe<StudentNotificationsCreateNestedManyWithoutStudentInput>;
  orders?: InputMaybe<OrderCreateNestedManyWithoutCustomerInput>;
  phoneNumber?: InputMaybe<Scalars["String"]["input"]>;
  postcode?: InputMaybe<Scalars["String"]["input"]>;
  privacyPolicyUpdates?: InputMaybe<PrivacyPolicyUpdateCreateNestedManyWithoutStudentInput>;
  profileImage?: InputMaybe<Scalars["String"]["input"]>;
  promoCodeHistory?: InputMaybe<PromoCodeHistoryCreateNestedManyWithoutStudentInput>;
  records?: InputMaybe<StudentRecordCreateNestedManyWithoutStudentInput>;
  referralStudents?: InputMaybe<ReferralCodeHistoryCreateNestedOneWithoutReferralStudentInput>;
  role?: InputMaybe<Role>;
  school: SchoolCreateNestedOneWithoutStudentsInput;
  schoolText?: InputMaybe<Scalars["String"]["input"]>;
  standard: StandardCreateNestedOneWithoutStudentsInput;
  state?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  workshopRegistrations?: InputMaybe<WorkshopRegistrationCreateNestedManyWithoutStudentInput>;
};

export type StudentCreateWithoutReferralStudentsInput = {
  address1?: InputMaybe<Scalars["String"]["input"]>;
  address2?: InputMaybe<Scalars["String"]["input"]>;
  badges?: InputMaybe<StudentBadgeCreateNestedManyWithoutStudentInput>;
  city?: InputMaybe<Scalars["String"]["input"]>;
  comments?: InputMaybe<CommentCreateNestedManyWithoutCreatedByInput>;
  country?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  displayName: Scalars["String"]["input"];
  dob?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  firstName: Scalars["String"]["input"];
  gameLogs?: InputMaybe<StudentGameLogCreateNestedManyWithoutStudentInput>;
  invitedStudents?: InputMaybe<ReferralCodeHistoryCreateNestedOneWithoutInvitedStudentInput>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  isHomeschooler?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastLoginAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastName: Scalars["String"]["input"];
  level?: InputMaybe<Scalars["Int"]["input"]>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  notificationTokens?: InputMaybe<StudentNotificationsCreateNestedManyWithoutStudentInput>;
  orders?: InputMaybe<OrderCreateNestedManyWithoutCustomerInput>;
  phoneNumber?: InputMaybe<Scalars["String"]["input"]>;
  postcode?: InputMaybe<Scalars["String"]["input"]>;
  privacyPolicyUpdates?: InputMaybe<PrivacyPolicyUpdateCreateNestedManyWithoutStudentInput>;
  profileImage?: InputMaybe<Scalars["String"]["input"]>;
  promoCodeHistory?: InputMaybe<PromoCodeHistoryCreateNestedManyWithoutStudentInput>;
  records?: InputMaybe<StudentRecordCreateNestedManyWithoutStudentInput>;
  referralCodes?: InputMaybe<ReferralCodeCreateNestedManyWithoutStudentInput>;
  role?: InputMaybe<Role>;
  school: SchoolCreateNestedOneWithoutStudentsInput;
  schoolText?: InputMaybe<Scalars["String"]["input"]>;
  standard: StandardCreateNestedOneWithoutStudentsInput;
  state?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  workshopRegistrations?: InputMaybe<WorkshopRegistrationCreateNestedManyWithoutStudentInput>;
};

export type StudentCreateWithoutSchoolInput = {
  address1?: InputMaybe<Scalars["String"]["input"]>;
  address2?: InputMaybe<Scalars["String"]["input"]>;
  badges?: InputMaybe<StudentBadgeCreateNestedManyWithoutStudentInput>;
  city?: InputMaybe<Scalars["String"]["input"]>;
  comments?: InputMaybe<CommentCreateNestedManyWithoutCreatedByInput>;
  country?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  displayName: Scalars["String"]["input"];
  dob?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  firstName: Scalars["String"]["input"];
  gameLogs?: InputMaybe<StudentGameLogCreateNestedManyWithoutStudentInput>;
  invitedStudents?: InputMaybe<ReferralCodeHistoryCreateNestedOneWithoutInvitedStudentInput>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  isHomeschooler?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastLoginAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastName: Scalars["String"]["input"];
  level?: InputMaybe<Scalars["Int"]["input"]>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  notificationTokens?: InputMaybe<StudentNotificationsCreateNestedManyWithoutStudentInput>;
  orders?: InputMaybe<OrderCreateNestedManyWithoutCustomerInput>;
  phoneNumber?: InputMaybe<Scalars["String"]["input"]>;
  postcode?: InputMaybe<Scalars["String"]["input"]>;
  privacyPolicyUpdates?: InputMaybe<PrivacyPolicyUpdateCreateNestedManyWithoutStudentInput>;
  profileImage?: InputMaybe<Scalars["String"]["input"]>;
  promoCodeHistory?: InputMaybe<PromoCodeHistoryCreateNestedManyWithoutStudentInput>;
  records?: InputMaybe<StudentRecordCreateNestedManyWithoutStudentInput>;
  referralCodes?: InputMaybe<ReferralCodeCreateNestedManyWithoutStudentInput>;
  referralStudents?: InputMaybe<ReferralCodeHistoryCreateNestedOneWithoutReferralStudentInput>;
  role?: InputMaybe<Role>;
  schoolText?: InputMaybe<Scalars["String"]["input"]>;
  standard: StandardCreateNestedOneWithoutStudentsInput;
  state?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  workshopRegistrations?: InputMaybe<WorkshopRegistrationCreateNestedManyWithoutStudentInput>;
};

export type StudentCreateWithoutStandardInput = {
  address1?: InputMaybe<Scalars["String"]["input"]>;
  address2?: InputMaybe<Scalars["String"]["input"]>;
  badges?: InputMaybe<StudentBadgeCreateNestedManyWithoutStudentInput>;
  city?: InputMaybe<Scalars["String"]["input"]>;
  comments?: InputMaybe<CommentCreateNestedManyWithoutCreatedByInput>;
  country?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  displayName: Scalars["String"]["input"];
  dob?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  firstName: Scalars["String"]["input"];
  gameLogs?: InputMaybe<StudentGameLogCreateNestedManyWithoutStudentInput>;
  invitedStudents?: InputMaybe<ReferralCodeHistoryCreateNestedOneWithoutInvitedStudentInput>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  isHomeschooler?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastLoginAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastName: Scalars["String"]["input"];
  level?: InputMaybe<Scalars["Int"]["input"]>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  notificationTokens?: InputMaybe<StudentNotificationsCreateNestedManyWithoutStudentInput>;
  orders?: InputMaybe<OrderCreateNestedManyWithoutCustomerInput>;
  phoneNumber?: InputMaybe<Scalars["String"]["input"]>;
  postcode?: InputMaybe<Scalars["String"]["input"]>;
  privacyPolicyUpdates?: InputMaybe<PrivacyPolicyUpdateCreateNestedManyWithoutStudentInput>;
  profileImage?: InputMaybe<Scalars["String"]["input"]>;
  promoCodeHistory?: InputMaybe<PromoCodeHistoryCreateNestedManyWithoutStudentInput>;
  records?: InputMaybe<StudentRecordCreateNestedManyWithoutStudentInput>;
  referralCodes?: InputMaybe<ReferralCodeCreateNestedManyWithoutStudentInput>;
  referralStudents?: InputMaybe<ReferralCodeHistoryCreateNestedOneWithoutReferralStudentInput>;
  role?: InputMaybe<Role>;
  school: SchoolCreateNestedOneWithoutStudentsInput;
  schoolText?: InputMaybe<Scalars["String"]["input"]>;
  state?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  workshopRegistrations?: InputMaybe<WorkshopRegistrationCreateNestedManyWithoutStudentInput>;
};

export type StudentCreateWithoutWorkshopRegistrationsInput = {
  address1?: InputMaybe<Scalars["String"]["input"]>;
  address2?: InputMaybe<Scalars["String"]["input"]>;
  badges?: InputMaybe<StudentBadgeCreateNestedManyWithoutStudentInput>;
  city?: InputMaybe<Scalars["String"]["input"]>;
  comments?: InputMaybe<CommentCreateNestedManyWithoutCreatedByInput>;
  country?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  displayName: Scalars["String"]["input"];
  dob?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  firstName: Scalars["String"]["input"];
  gameLogs?: InputMaybe<StudentGameLogCreateNestedManyWithoutStudentInput>;
  invitedStudents?: InputMaybe<ReferralCodeHistoryCreateNestedOneWithoutInvitedStudentInput>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  isHomeschooler?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastLoginAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastName: Scalars["String"]["input"];
  level?: InputMaybe<Scalars["Int"]["input"]>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  notificationTokens?: InputMaybe<StudentNotificationsCreateNestedManyWithoutStudentInput>;
  orders?: InputMaybe<OrderCreateNestedManyWithoutCustomerInput>;
  phoneNumber?: InputMaybe<Scalars["String"]["input"]>;
  postcode?: InputMaybe<Scalars["String"]["input"]>;
  privacyPolicyUpdates?: InputMaybe<PrivacyPolicyUpdateCreateNestedManyWithoutStudentInput>;
  profileImage?: InputMaybe<Scalars["String"]["input"]>;
  promoCodeHistory?: InputMaybe<PromoCodeHistoryCreateNestedManyWithoutStudentInput>;
  records?: InputMaybe<StudentRecordCreateNestedManyWithoutStudentInput>;
  referralCodes?: InputMaybe<ReferralCodeCreateNestedManyWithoutStudentInput>;
  referralStudents?: InputMaybe<ReferralCodeHistoryCreateNestedOneWithoutReferralStudentInput>;
  role?: InputMaybe<Role>;
  school: SchoolCreateNestedOneWithoutStudentsInput;
  schoolText?: InputMaybe<Scalars["String"]["input"]>;
  standard: StandardCreateNestedOneWithoutStudentsInput;
  state?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type StudentDailyActivity = {
  __typename?: "StudentDailyActivity";
  answer?: Maybe<Scalars["Int"]["output"]>;
  answerText?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["Int"]["output"];
  options: Scalars["JSON"]["output"];
  question: Scalars["String"]["output"];
  questionImage?: Maybe<Scalars["String"]["output"]>;
  questionVideo?: Maybe<Scalars["String"]["output"]>;
};

export type StudentDailyActivityInput = {
  currentDate: Scalars["String"]["input"];
  timeDiff: Scalars["Int"]["input"];
};

export type StudentDailyChallenge = {
  __typename?: "StudentDailyChallenge";
  challengeStatement?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["Int"]["output"];
  image?: Maybe<Scalars["String"]["output"]>;
  title: Scalars["String"]["output"];
  type: Scalars["String"]["output"];
  video?: Maybe<Scalars["String"]["output"]>;
};

export type StudentDailyChallengeInput = {
  timeDiff: Scalars["Int"]["input"];
};

export type StudentGameLog = {
  __typename?: "StudentGameLog";
  access: LogAccessType;
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["Int"]["output"];
  model?: Maybe<Scalars["String"]["output"]>;
  modelId?: Maybe<Scalars["Int"]["output"]>;
  pointType?: Maybe<GamePointType>;
  pointTypeId?: Maybe<Scalars["Int"]["output"]>;
  points: Scalars["Int"]["output"];
  student: Student;
  studentId: Scalars["Int"]["output"];
  title: Scalars["String"]["output"];
  trigger?: Maybe<Trigger>;
  triggerId?: Maybe<Scalars["Int"]["output"]>;
  triggerType: TriggerType;
};

export type StudentGameLogAvgAggregate = {
  __typename?: "StudentGameLogAvgAggregate";
  id?: Maybe<Scalars["Float"]["output"]>;
  modelId?: Maybe<Scalars["Float"]["output"]>;
  pointTypeId?: Maybe<Scalars["Float"]["output"]>;
  points?: Maybe<Scalars["Float"]["output"]>;
  studentId?: Maybe<Scalars["Float"]["output"]>;
  triggerId?: Maybe<Scalars["Float"]["output"]>;
};

export type StudentGameLogCountAggregate = {
  __typename?: "StudentGameLogCountAggregate";
  _all: Scalars["Int"]["output"];
  access: Scalars["Int"]["output"];
  createdAt: Scalars["Int"]["output"];
  id: Scalars["Int"]["output"];
  model: Scalars["Int"]["output"];
  modelId: Scalars["Int"]["output"];
  pointTypeId: Scalars["Int"]["output"];
  points: Scalars["Int"]["output"];
  studentId: Scalars["Int"]["output"];
  title: Scalars["Int"]["output"];
  triggerId: Scalars["Int"]["output"];
  triggerType: Scalars["Int"]["output"];
};

export type StudentGameLogCreateInput = {
  access?: InputMaybe<LogAccessType>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  model?: InputMaybe<Scalars["String"]["input"]>;
  modelId?: InputMaybe<Scalars["Int"]["input"]>;
  pointType?: InputMaybe<GamePointTypeCreateNestedOneWithoutStudentGameLogsInput>;
  points?: InputMaybe<Scalars["Int"]["input"]>;
  student: StudentCreateNestedOneWithoutGameLogsInput;
  title: Scalars["String"]["input"];
  trigger?: InputMaybe<TriggerCreateNestedOneWithoutStudentGameLogsInput>;
  triggerType: TriggerType;
};

export type StudentGameLogCreateManyPointTypeInput = {
  access?: InputMaybe<LogAccessType>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  model?: InputMaybe<Scalars["String"]["input"]>;
  modelId?: InputMaybe<Scalars["Int"]["input"]>;
  points?: InputMaybe<Scalars["Int"]["input"]>;
  studentId: Scalars["Int"]["input"];
  title: Scalars["String"]["input"];
  triggerId?: InputMaybe<Scalars["Int"]["input"]>;
  triggerType: TriggerType;
};

export type StudentGameLogCreateManyPointTypeInputEnvelope = {
  data: Array<StudentGameLogCreateManyPointTypeInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type StudentGameLogCreateManyStudentInput = {
  access?: InputMaybe<LogAccessType>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  model?: InputMaybe<Scalars["String"]["input"]>;
  modelId?: InputMaybe<Scalars["Int"]["input"]>;
  pointTypeId?: InputMaybe<Scalars["Int"]["input"]>;
  points?: InputMaybe<Scalars["Int"]["input"]>;
  title: Scalars["String"]["input"];
  triggerId?: InputMaybe<Scalars["Int"]["input"]>;
  triggerType: TriggerType;
};

export type StudentGameLogCreateManyStudentInputEnvelope = {
  data: Array<StudentGameLogCreateManyStudentInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type StudentGameLogCreateManyTriggerInput = {
  access?: InputMaybe<LogAccessType>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  model?: InputMaybe<Scalars["String"]["input"]>;
  modelId?: InputMaybe<Scalars["Int"]["input"]>;
  pointTypeId?: InputMaybe<Scalars["Int"]["input"]>;
  points?: InputMaybe<Scalars["Int"]["input"]>;
  studentId: Scalars["Int"]["input"];
  title: Scalars["String"]["input"];
  triggerType: TriggerType;
};

export type StudentGameLogCreateManyTriggerInputEnvelope = {
  data: Array<StudentGameLogCreateManyTriggerInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type StudentGameLogCreateNestedManyWithoutPointTypeInput = {
  connect?: InputMaybe<Array<StudentGameLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StudentGameLogCreateOrConnectWithoutPointTypeInput>>;
  create?: InputMaybe<Array<StudentGameLogCreateWithoutPointTypeInput>>;
  createMany?: InputMaybe<StudentGameLogCreateManyPointTypeInputEnvelope>;
};

export type StudentGameLogCreateNestedManyWithoutStudentInput = {
  connect?: InputMaybe<Array<StudentGameLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StudentGameLogCreateOrConnectWithoutStudentInput>>;
  create?: InputMaybe<Array<StudentGameLogCreateWithoutStudentInput>>;
  createMany?: InputMaybe<StudentGameLogCreateManyStudentInputEnvelope>;
};

export type StudentGameLogCreateNestedManyWithoutTriggerInput = {
  connect?: InputMaybe<Array<StudentGameLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StudentGameLogCreateOrConnectWithoutTriggerInput>>;
  create?: InputMaybe<Array<StudentGameLogCreateWithoutTriggerInput>>;
  createMany?: InputMaybe<StudentGameLogCreateManyTriggerInputEnvelope>;
};

export type StudentGameLogCreateOrConnectWithoutPointTypeInput = {
  create: StudentGameLogCreateWithoutPointTypeInput;
  where: StudentGameLogWhereUniqueInput;
};

export type StudentGameLogCreateOrConnectWithoutStudentInput = {
  create: StudentGameLogCreateWithoutStudentInput;
  where: StudentGameLogWhereUniqueInput;
};

export type StudentGameLogCreateOrConnectWithoutTriggerInput = {
  create: StudentGameLogCreateWithoutTriggerInput;
  where: StudentGameLogWhereUniqueInput;
};

export type StudentGameLogCreateWithoutPointTypeInput = {
  access?: InputMaybe<LogAccessType>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  model?: InputMaybe<Scalars["String"]["input"]>;
  modelId?: InputMaybe<Scalars["Int"]["input"]>;
  points?: InputMaybe<Scalars["Int"]["input"]>;
  student: StudentCreateNestedOneWithoutGameLogsInput;
  title: Scalars["String"]["input"];
  trigger?: InputMaybe<TriggerCreateNestedOneWithoutStudentGameLogsInput>;
  triggerType: TriggerType;
};

export type StudentGameLogCreateWithoutStudentInput = {
  access?: InputMaybe<LogAccessType>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  model?: InputMaybe<Scalars["String"]["input"]>;
  modelId?: InputMaybe<Scalars["Int"]["input"]>;
  pointType?: InputMaybe<GamePointTypeCreateNestedOneWithoutStudentGameLogsInput>;
  points?: InputMaybe<Scalars["Int"]["input"]>;
  title: Scalars["String"]["input"];
  trigger?: InputMaybe<TriggerCreateNestedOneWithoutStudentGameLogsInput>;
  triggerType: TriggerType;
};

export type StudentGameLogCreateWithoutTriggerInput = {
  access?: InputMaybe<LogAccessType>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  model?: InputMaybe<Scalars["String"]["input"]>;
  modelId?: InputMaybe<Scalars["Int"]["input"]>;
  pointType?: InputMaybe<GamePointTypeCreateNestedOneWithoutStudentGameLogsInput>;
  points?: InputMaybe<Scalars["Int"]["input"]>;
  student: StudentCreateNestedOneWithoutGameLogsInput;
  title: Scalars["String"]["input"];
  triggerType: TriggerType;
};

export type StudentGameLogListRelationFilter = {
  every?: InputMaybe<StudentGameLogWhereInput>;
  none?: InputMaybe<StudentGameLogWhereInput>;
  some?: InputMaybe<StudentGameLogWhereInput>;
};

export type StudentGameLogMaxAggregate = {
  __typename?: "StudentGameLogMaxAggregate";
  access?: Maybe<LogAccessType>;
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  model?: Maybe<Scalars["String"]["output"]>;
  modelId?: Maybe<Scalars["Int"]["output"]>;
  pointTypeId?: Maybe<Scalars["Int"]["output"]>;
  points?: Maybe<Scalars["Int"]["output"]>;
  studentId?: Maybe<Scalars["Int"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
  triggerId?: Maybe<Scalars["Int"]["output"]>;
  triggerType?: Maybe<TriggerType>;
};

export type StudentGameLogMinAggregate = {
  __typename?: "StudentGameLogMinAggregate";
  access?: Maybe<LogAccessType>;
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  model?: Maybe<Scalars["String"]["output"]>;
  modelId?: Maybe<Scalars["Int"]["output"]>;
  pointTypeId?: Maybe<Scalars["Int"]["output"]>;
  points?: Maybe<Scalars["Int"]["output"]>;
  studentId?: Maybe<Scalars["Int"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
  triggerId?: Maybe<Scalars["Int"]["output"]>;
  triggerType?: Maybe<TriggerType>;
};

export type StudentGameLogOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type StudentGameLogOrderByWithRelationInput = {
  access?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  model?: InputMaybe<SortOrderInput>;
  modelId?: InputMaybe<SortOrderInput>;
  pointType?: InputMaybe<GamePointTypeOrderByWithRelationInput>;
  pointTypeId?: InputMaybe<SortOrderInput>;
  points?: InputMaybe<SortOrder>;
  student?: InputMaybe<StudentOrderByWithRelationInput>;
  studentId?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  trigger?: InputMaybe<TriggerOrderByWithRelationInput>;
  triggerId?: InputMaybe<SortOrderInput>;
  triggerType?: InputMaybe<SortOrder>;
};

export enum StudentGameLogScalarFieldEnum {
  ACCESS = "access",
  CREATEDAT = "createdAt",
  ID = "id",
  MODEL = "model",
  MODELID = "modelId",
  POINTTYPEID = "pointTypeId",
  POINTS = "points",
  STUDENTID = "studentId",
  TITLE = "title",
  TRIGGERID = "triggerId",
  TRIGGERTYPE = "triggerType"
}

export type StudentGameLogScalarWhereInput = {
  AND?: InputMaybe<Array<StudentGameLogScalarWhereInput>>;
  NOT?: InputMaybe<Array<StudentGameLogScalarWhereInput>>;
  OR?: InputMaybe<Array<StudentGameLogScalarWhereInput>>;
  access?: InputMaybe<EnumLogAccessTypeFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  model?: InputMaybe<StringNullableFilter>;
  modelId?: InputMaybe<IntNullableFilter>;
  pointTypeId?: InputMaybe<IntNullableFilter>;
  points?: InputMaybe<IntFilter>;
  studentId?: InputMaybe<IntFilter>;
  title?: InputMaybe<StringFilter>;
  triggerId?: InputMaybe<IntNullableFilter>;
  triggerType?: InputMaybe<EnumTriggerTypeFilter>;
};

export type StudentGameLogSumAggregate = {
  __typename?: "StudentGameLogSumAggregate";
  id?: Maybe<Scalars["Int"]["output"]>;
  modelId?: Maybe<Scalars["Int"]["output"]>;
  pointTypeId?: Maybe<Scalars["Int"]["output"]>;
  points?: Maybe<Scalars["Int"]["output"]>;
  studentId?: Maybe<Scalars["Int"]["output"]>;
  triggerId?: Maybe<Scalars["Int"]["output"]>;
};

export type StudentGameLogUpdateManyMutationInput = {
  access?: InputMaybe<EnumLogAccessTypeFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  model?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  modelId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  points?: InputMaybe<IntFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  triggerType?: InputMaybe<EnumTriggerTypeFieldUpdateOperationsInput>;
};

export type StudentGameLogUpdateManyWithWhereWithoutPointTypeInput = {
  data: StudentGameLogUpdateManyMutationInput;
  where: StudentGameLogScalarWhereInput;
};

export type StudentGameLogUpdateManyWithWhereWithoutStudentInput = {
  data: StudentGameLogUpdateManyMutationInput;
  where: StudentGameLogScalarWhereInput;
};

export type StudentGameLogUpdateManyWithWhereWithoutTriggerInput = {
  data: StudentGameLogUpdateManyMutationInput;
  where: StudentGameLogScalarWhereInput;
};

export type StudentGameLogUpdateManyWithoutPointTypeNestedInput = {
  connect?: InputMaybe<Array<StudentGameLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StudentGameLogCreateOrConnectWithoutPointTypeInput>>;
  create?: InputMaybe<Array<StudentGameLogCreateWithoutPointTypeInput>>;
  createMany?: InputMaybe<StudentGameLogCreateManyPointTypeInputEnvelope>;
  delete?: InputMaybe<Array<StudentGameLogWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<StudentGameLogScalarWhereInput>>;
  disconnect?: InputMaybe<Array<StudentGameLogWhereUniqueInput>>;
  set?: InputMaybe<Array<StudentGameLogWhereUniqueInput>>;
  update?: InputMaybe<Array<StudentGameLogUpdateWithWhereUniqueWithoutPointTypeInput>>;
  updateMany?: InputMaybe<Array<StudentGameLogUpdateManyWithWhereWithoutPointTypeInput>>;
  upsert?: InputMaybe<Array<StudentGameLogUpsertWithWhereUniqueWithoutPointTypeInput>>;
};

export type StudentGameLogUpdateManyWithoutStudentNestedInput = {
  connect?: InputMaybe<Array<StudentGameLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StudentGameLogCreateOrConnectWithoutStudentInput>>;
  create?: InputMaybe<Array<StudentGameLogCreateWithoutStudentInput>>;
  createMany?: InputMaybe<StudentGameLogCreateManyStudentInputEnvelope>;
  delete?: InputMaybe<Array<StudentGameLogWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<StudentGameLogScalarWhereInput>>;
  disconnect?: InputMaybe<Array<StudentGameLogWhereUniqueInput>>;
  set?: InputMaybe<Array<StudentGameLogWhereUniqueInput>>;
  update?: InputMaybe<Array<StudentGameLogUpdateWithWhereUniqueWithoutStudentInput>>;
  updateMany?: InputMaybe<Array<StudentGameLogUpdateManyWithWhereWithoutStudentInput>>;
  upsert?: InputMaybe<Array<StudentGameLogUpsertWithWhereUniqueWithoutStudentInput>>;
};

export type StudentGameLogUpdateManyWithoutTriggerNestedInput = {
  connect?: InputMaybe<Array<StudentGameLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StudentGameLogCreateOrConnectWithoutTriggerInput>>;
  create?: InputMaybe<Array<StudentGameLogCreateWithoutTriggerInput>>;
  createMany?: InputMaybe<StudentGameLogCreateManyTriggerInputEnvelope>;
  delete?: InputMaybe<Array<StudentGameLogWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<StudentGameLogScalarWhereInput>>;
  disconnect?: InputMaybe<Array<StudentGameLogWhereUniqueInput>>;
  set?: InputMaybe<Array<StudentGameLogWhereUniqueInput>>;
  update?: InputMaybe<Array<StudentGameLogUpdateWithWhereUniqueWithoutTriggerInput>>;
  updateMany?: InputMaybe<Array<StudentGameLogUpdateManyWithWhereWithoutTriggerInput>>;
  upsert?: InputMaybe<Array<StudentGameLogUpsertWithWhereUniqueWithoutTriggerInput>>;
};

export type StudentGameLogUpdateWithWhereUniqueWithoutPointTypeInput = {
  data: StudentGameLogUpdateWithoutPointTypeInput;
  where: StudentGameLogWhereUniqueInput;
};

export type StudentGameLogUpdateWithWhereUniqueWithoutStudentInput = {
  data: StudentGameLogUpdateWithoutStudentInput;
  where: StudentGameLogWhereUniqueInput;
};

export type StudentGameLogUpdateWithWhereUniqueWithoutTriggerInput = {
  data: StudentGameLogUpdateWithoutTriggerInput;
  where: StudentGameLogWhereUniqueInput;
};

export type StudentGameLogUpdateWithoutPointTypeInput = {
  access?: InputMaybe<EnumLogAccessTypeFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  model?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  modelId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  points?: InputMaybe<IntFieldUpdateOperationsInput>;
  student?: InputMaybe<StudentUpdateOneRequiredWithoutGameLogsNestedInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  trigger?: InputMaybe<TriggerUpdateOneWithoutStudentGameLogsNestedInput>;
  triggerType?: InputMaybe<EnumTriggerTypeFieldUpdateOperationsInput>;
};

export type StudentGameLogUpdateWithoutStudentInput = {
  access?: InputMaybe<EnumLogAccessTypeFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  model?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  modelId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  pointType?: InputMaybe<GamePointTypeUpdateOneWithoutStudentGameLogsNestedInput>;
  points?: InputMaybe<IntFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  trigger?: InputMaybe<TriggerUpdateOneWithoutStudentGameLogsNestedInput>;
  triggerType?: InputMaybe<EnumTriggerTypeFieldUpdateOperationsInput>;
};

export type StudentGameLogUpdateWithoutTriggerInput = {
  access?: InputMaybe<EnumLogAccessTypeFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  model?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  modelId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  pointType?: InputMaybe<GamePointTypeUpdateOneWithoutStudentGameLogsNestedInput>;
  points?: InputMaybe<IntFieldUpdateOperationsInput>;
  student?: InputMaybe<StudentUpdateOneRequiredWithoutGameLogsNestedInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  triggerType?: InputMaybe<EnumTriggerTypeFieldUpdateOperationsInput>;
};

export type StudentGameLogUpsertWithWhereUniqueWithoutPointTypeInput = {
  create: StudentGameLogCreateWithoutPointTypeInput;
  update: StudentGameLogUpdateWithoutPointTypeInput;
  where: StudentGameLogWhereUniqueInput;
};

export type StudentGameLogUpsertWithWhereUniqueWithoutStudentInput = {
  create: StudentGameLogCreateWithoutStudentInput;
  update: StudentGameLogUpdateWithoutStudentInput;
  where: StudentGameLogWhereUniqueInput;
};

export type StudentGameLogUpsertWithWhereUniqueWithoutTriggerInput = {
  create: StudentGameLogCreateWithoutTriggerInput;
  update: StudentGameLogUpdateWithoutTriggerInput;
  where: StudentGameLogWhereUniqueInput;
};

export type StudentGameLogWhereInput = {
  AND?: InputMaybe<Array<StudentGameLogWhereInput>>;
  NOT?: InputMaybe<Array<StudentGameLogWhereInput>>;
  OR?: InputMaybe<Array<StudentGameLogWhereInput>>;
  access?: InputMaybe<EnumLogAccessTypeFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  model?: InputMaybe<StringNullableFilter>;
  modelId?: InputMaybe<IntNullableFilter>;
  pointType?: InputMaybe<GamePointTypeRelationFilter>;
  pointTypeId?: InputMaybe<IntNullableFilter>;
  points?: InputMaybe<IntFilter>;
  student?: InputMaybe<StudentRelationFilter>;
  studentId?: InputMaybe<IntFilter>;
  title?: InputMaybe<StringFilter>;
  trigger?: InputMaybe<TriggerRelationFilter>;
  triggerId?: InputMaybe<IntNullableFilter>;
  triggerType?: InputMaybe<EnumTriggerTypeFilter>;
};

export type StudentGameLogWhereUniqueInput = {
  id?: InputMaybe<Scalars["Int"]["input"]>;
};

export type StudentGamePointsResponse = {
  __typename?: "StudentGamePointsResponse";
  data?: Maybe<Scalars["JSON"]["output"]>;
};

export type StudentLeaderboardResponse = {
  __typename?: "StudentLeaderboardResponse";
  level: Scalars["Int"]["output"];
  name: Scalars["String"]["output"];
  profileImage?: Maybe<Scalars["String"]["output"]>;
  stars: Scalars["Int"]["output"];
  xps: Scalars["Int"]["output"];
};

export type StudentListRelationFilter = {
  every?: InputMaybe<StudentWhereInput>;
  none?: InputMaybe<StudentWhereInput>;
  some?: InputMaybe<StudentWhereInput>;
};

export type StudentMaxAggregate = {
  __typename?: "StudentMaxAggregate";
  address1?: Maybe<Scalars["String"]["output"]>;
  address2?: Maybe<Scalars["String"]["output"]>;
  city?: Maybe<Scalars["String"]["output"]>;
  country?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  displayName?: Maybe<Scalars["String"]["output"]>;
  dob?: Maybe<Scalars["String"]["output"]>;
  email?: Maybe<Scalars["String"]["output"]>;
  firstName?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  isActive?: Maybe<Scalars["Boolean"]["output"]>;
  isHomeschooler?: Maybe<Scalars["Boolean"]["output"]>;
  lastLoginAt?: Maybe<Scalars["DateTime"]["output"]>;
  lastName?: Maybe<Scalars["String"]["output"]>;
  level?: Maybe<Scalars["Int"]["output"]>;
  phoneNumber?: Maybe<Scalars["String"]["output"]>;
  postcode?: Maybe<Scalars["String"]["output"]>;
  profileImage?: Maybe<Scalars["String"]["output"]>;
  role?: Maybe<Role>;
  schoolId?: Maybe<Scalars["Int"]["output"]>;
  schoolText?: Maybe<Scalars["String"]["output"]>;
  standardId?: Maybe<Scalars["Int"]["output"]>;
  state?: Maybe<Scalars["String"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type StudentMinAggregate = {
  __typename?: "StudentMinAggregate";
  address1?: Maybe<Scalars["String"]["output"]>;
  address2?: Maybe<Scalars["String"]["output"]>;
  city?: Maybe<Scalars["String"]["output"]>;
  country?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  displayName?: Maybe<Scalars["String"]["output"]>;
  dob?: Maybe<Scalars["String"]["output"]>;
  email?: Maybe<Scalars["String"]["output"]>;
  firstName?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  isActive?: Maybe<Scalars["Boolean"]["output"]>;
  isHomeschooler?: Maybe<Scalars["Boolean"]["output"]>;
  lastLoginAt?: Maybe<Scalars["DateTime"]["output"]>;
  lastName?: Maybe<Scalars["String"]["output"]>;
  level?: Maybe<Scalars["Int"]["output"]>;
  phoneNumber?: Maybe<Scalars["String"]["output"]>;
  postcode?: Maybe<Scalars["String"]["output"]>;
  profileImage?: Maybe<Scalars["String"]["output"]>;
  role?: Maybe<Role>;
  schoolId?: Maybe<Scalars["Int"]["output"]>;
  schoolText?: Maybe<Scalars["String"]["output"]>;
  standardId?: Maybe<Scalars["Int"]["output"]>;
  state?: Maybe<Scalars["String"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type StudentNextLevelRequirementResponse = {
  __typename?: "StudentNextLevelRequirementResponse";
  data?: Maybe<Scalars["JSON"]["output"]>;
};

export type StudentNextRandomActivityInput = {
  skillId?: InputMaybe<Scalars["Int"]["input"]>;
};

export type StudentNotifications = {
  __typename?: "StudentNotifications";
  createdAt: Scalars["DateTime"]["output"];
  device: Scalars["String"]["output"];
  id: Scalars["Int"]["output"];
  studentId: Scalars["Int"]["output"];
  token: Scalars["String"]["output"];
};

export type StudentNotificationsCreateManyStudentInput = {
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  device: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["Int"]["input"]>;
  token: Scalars["String"]["input"];
};

export type StudentNotificationsCreateManyStudentInputEnvelope = {
  data: Array<StudentNotificationsCreateManyStudentInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type StudentNotificationsCreateNestedManyWithoutStudentInput = {
  connect?: InputMaybe<Array<StudentNotificationsWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StudentNotificationsCreateOrConnectWithoutStudentInput>>;
  create?: InputMaybe<Array<StudentNotificationsCreateWithoutStudentInput>>;
  createMany?: InputMaybe<StudentNotificationsCreateManyStudentInputEnvelope>;
};

export type StudentNotificationsCreateOrConnectWithoutStudentInput = {
  create: StudentNotificationsCreateWithoutStudentInput;
  where: StudentNotificationsWhereUniqueInput;
};

export type StudentNotificationsCreateWithoutStudentInput = {
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  device: Scalars["String"]["input"];
  token: Scalars["String"]["input"];
};

export type StudentNotificationsListRelationFilter = {
  every?: InputMaybe<StudentNotificationsWhereInput>;
  none?: InputMaybe<StudentNotificationsWhereInput>;
  some?: InputMaybe<StudentNotificationsWhereInput>;
};

export type StudentNotificationsOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type StudentNotificationsOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  device?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  student?: InputMaybe<StudentOrderByWithRelationInput>;
  studentId?: InputMaybe<SortOrder>;
  token?: InputMaybe<SortOrder>;
};

export enum StudentNotificationsScalarFieldEnum {
  CREATEDAT = "createdAt",
  DEVICE = "device",
  ID = "id",
  STUDENTID = "studentId",
  TOKEN = "token"
}

export type StudentNotificationsScalarWhereInput = {
  AND?: InputMaybe<Array<StudentNotificationsScalarWhereInput>>;
  NOT?: InputMaybe<Array<StudentNotificationsScalarWhereInput>>;
  OR?: InputMaybe<Array<StudentNotificationsScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  device?: InputMaybe<StringFilter>;
  id?: InputMaybe<IntFilter>;
  studentId?: InputMaybe<IntFilter>;
  token?: InputMaybe<StringFilter>;
};

export type StudentNotificationsUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  device?: InputMaybe<StringFieldUpdateOperationsInput>;
  token?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type StudentNotificationsUpdateManyWithWhereWithoutStudentInput = {
  data: StudentNotificationsUpdateManyMutationInput;
  where: StudentNotificationsScalarWhereInput;
};

export type StudentNotificationsUpdateManyWithoutStudentNestedInput = {
  connect?: InputMaybe<Array<StudentNotificationsWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StudentNotificationsCreateOrConnectWithoutStudentInput>>;
  create?: InputMaybe<Array<StudentNotificationsCreateWithoutStudentInput>>;
  createMany?: InputMaybe<StudentNotificationsCreateManyStudentInputEnvelope>;
  delete?: InputMaybe<Array<StudentNotificationsWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<StudentNotificationsScalarWhereInput>>;
  disconnect?: InputMaybe<Array<StudentNotificationsWhereUniqueInput>>;
  set?: InputMaybe<Array<StudentNotificationsWhereUniqueInput>>;
  update?: InputMaybe<Array<StudentNotificationsUpdateWithWhereUniqueWithoutStudentInput>>;
  updateMany?: InputMaybe<Array<StudentNotificationsUpdateManyWithWhereWithoutStudentInput>>;
  upsert?: InputMaybe<Array<StudentNotificationsUpsertWithWhereUniqueWithoutStudentInput>>;
};

export type StudentNotificationsUpdateWithWhereUniqueWithoutStudentInput = {
  data: StudentNotificationsUpdateWithoutStudentInput;
  where: StudentNotificationsWhereUniqueInput;
};

export type StudentNotificationsUpdateWithoutStudentInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  device?: InputMaybe<StringFieldUpdateOperationsInput>;
  token?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type StudentNotificationsUpsertWithWhereUniqueWithoutStudentInput = {
  create: StudentNotificationsCreateWithoutStudentInput;
  update: StudentNotificationsUpdateWithoutStudentInput;
  where: StudentNotificationsWhereUniqueInput;
};

export type StudentNotificationsWhereInput = {
  AND?: InputMaybe<Array<StudentNotificationsWhereInput>>;
  NOT?: InputMaybe<Array<StudentNotificationsWhereInput>>;
  OR?: InputMaybe<Array<StudentNotificationsWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  device?: InputMaybe<StringFilter>;
  id?: InputMaybe<IntFilter>;
  student?: InputMaybe<StudentRelationFilter>;
  studentId?: InputMaybe<IntFilter>;
  token?: InputMaybe<StringFilter>;
};

export type StudentNotificationsWhereUniqueInput = {
  id?: InputMaybe<Scalars["Int"]["input"]>;
};

export type StudentOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type StudentOrderByWithRelationInput = {
  address1?: InputMaybe<SortOrderInput>;
  address2?: InputMaybe<SortOrderInput>;
  badges?: InputMaybe<StudentBadgeOrderByRelationAggregateInput>;
  city?: InputMaybe<SortOrderInput>;
  comments?: InputMaybe<CommentOrderByRelationAggregateInput>;
  country?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  displayName?: InputMaybe<SortOrder>;
  dob?: InputMaybe<SortOrderInput>;
  email?: InputMaybe<SortOrderInput>;
  firstName?: InputMaybe<SortOrder>;
  gameLogs?: InputMaybe<StudentGameLogOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  invitedStudents?: InputMaybe<ReferralCodeHistoryOrderByWithRelationInput>;
  isActive?: InputMaybe<SortOrder>;
  isHomeschooler?: InputMaybe<SortOrder>;
  lastLoginAt?: InputMaybe<SortOrderInput>;
  lastName?: InputMaybe<SortOrder>;
  level?: InputMaybe<SortOrder>;
  meta?: InputMaybe<SortOrderInput>;
  notificationTokens?: InputMaybe<StudentNotificationsOrderByRelationAggregateInput>;
  orders?: InputMaybe<OrderOrderByRelationAggregateInput>;
  phoneNumber?: InputMaybe<SortOrderInput>;
  postcode?: InputMaybe<SortOrderInput>;
  privacyPolicyUpdates?: InputMaybe<PrivacyPolicyUpdateOrderByRelationAggregateInput>;
  profileImage?: InputMaybe<SortOrderInput>;
  promoCodeHistory?: InputMaybe<PromoCodeHistoryOrderByRelationAggregateInput>;
  records?: InputMaybe<StudentRecordOrderByRelationAggregateInput>;
  referralCodes?: InputMaybe<ReferralCodeOrderByRelationAggregateInput>;
  referralStudents?: InputMaybe<ReferralCodeHistoryOrderByWithRelationInput>;
  role?: InputMaybe<SortOrder>;
  school?: InputMaybe<SchoolOrderByWithRelationInput>;
  schoolId?: InputMaybe<SortOrder>;
  schoolText?: InputMaybe<SortOrderInput>;
  standard?: InputMaybe<StandardOrderByWithRelationInput>;
  standardId?: InputMaybe<SortOrder>;
  state?: InputMaybe<SortOrderInput>;
  updatedAt?: InputMaybe<SortOrder>;
  workshopRegistrations?: InputMaybe<WorkshopRegistrationOrderByRelationAggregateInput>;
};

export type StudentProductWhereInput = {
  isBestSelling?: InputMaybe<BoolFilter>;
  isNewReleased?: InputMaybe<BoolFilter>;
  isVirtual?: InputMaybe<BoolFilter>;
  skill?: InputMaybe<SkillWhereUniqueInput>;
};

export type StudentRecord = {
  __typename?: "StudentRecord";
  createdAt: Scalars["DateTime"]["output"];
  school: School;
  schoolId: Scalars["Int"]["output"];
  standard: Standard;
  standardId: Scalars["Int"]["output"];
  student: Student;
  studentId: Scalars["Int"]["output"];
};

export type StudentRecordAvgAggregate = {
  __typename?: "StudentRecordAvgAggregate";
  schoolId?: Maybe<Scalars["Float"]["output"]>;
  standardId?: Maybe<Scalars["Float"]["output"]>;
  studentId?: Maybe<Scalars["Float"]["output"]>;
};

export type StudentRecordCountAggregate = {
  __typename?: "StudentRecordCountAggregate";
  _all: Scalars["Int"]["output"];
  createdAt: Scalars["Int"]["output"];
  schoolId: Scalars["Int"]["output"];
  standardId: Scalars["Int"]["output"];
  studentId: Scalars["Int"]["output"];
};

export type StudentRecordCreateInput = {
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  school: SchoolCreateNestedOneWithoutStudentRecordsInput;
  standard: StandardCreateNestedOneWithoutStudentRecordsInput;
  student: StudentCreateNestedOneWithoutRecordsInput;
};

export type StudentRecordCreateManySchoolInput = {
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  standardId: Scalars["Int"]["input"];
  studentId: Scalars["Int"]["input"];
};

export type StudentRecordCreateManySchoolInputEnvelope = {
  data: Array<StudentRecordCreateManySchoolInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type StudentRecordCreateManyStandardInput = {
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  schoolId: Scalars["Int"]["input"];
  studentId: Scalars["Int"]["input"];
};

export type StudentRecordCreateManyStandardInputEnvelope = {
  data: Array<StudentRecordCreateManyStandardInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type StudentRecordCreateManyStudentInput = {
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  schoolId: Scalars["Int"]["input"];
  standardId: Scalars["Int"]["input"];
};

export type StudentRecordCreateManyStudentInputEnvelope = {
  data: Array<StudentRecordCreateManyStudentInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type StudentRecordCreateNestedManyWithoutSchoolInput = {
  connect?: InputMaybe<Array<StudentRecordWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StudentRecordCreateOrConnectWithoutSchoolInput>>;
  create?: InputMaybe<Array<StudentRecordCreateWithoutSchoolInput>>;
  createMany?: InputMaybe<StudentRecordCreateManySchoolInputEnvelope>;
};

export type StudentRecordCreateNestedManyWithoutStandardInput = {
  connect?: InputMaybe<Array<StudentRecordWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StudentRecordCreateOrConnectWithoutStandardInput>>;
  create?: InputMaybe<Array<StudentRecordCreateWithoutStandardInput>>;
  createMany?: InputMaybe<StudentRecordCreateManyStandardInputEnvelope>;
};

export type StudentRecordCreateNestedManyWithoutStudentInput = {
  connect?: InputMaybe<Array<StudentRecordWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StudentRecordCreateOrConnectWithoutStudentInput>>;
  create?: InputMaybe<Array<StudentRecordCreateWithoutStudentInput>>;
  createMany?: InputMaybe<StudentRecordCreateManyStudentInputEnvelope>;
};

export type StudentRecordCreateOrConnectWithoutSchoolInput = {
  create: StudentRecordCreateWithoutSchoolInput;
  where: StudentRecordWhereUniqueInput;
};

export type StudentRecordCreateOrConnectWithoutStandardInput = {
  create: StudentRecordCreateWithoutStandardInput;
  where: StudentRecordWhereUniqueInput;
};

export type StudentRecordCreateOrConnectWithoutStudentInput = {
  create: StudentRecordCreateWithoutStudentInput;
  where: StudentRecordWhereUniqueInput;
};

export type StudentRecordCreateWithoutSchoolInput = {
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  standard: StandardCreateNestedOneWithoutStudentRecordsInput;
  student: StudentCreateNestedOneWithoutRecordsInput;
};

export type StudentRecordCreateWithoutStandardInput = {
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  school: SchoolCreateNestedOneWithoutStudentRecordsInput;
  student: StudentCreateNestedOneWithoutRecordsInput;
};

export type StudentRecordCreateWithoutStudentInput = {
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  school: SchoolCreateNestedOneWithoutStudentRecordsInput;
  standard: StandardCreateNestedOneWithoutStudentRecordsInput;
};

export type StudentRecordListRelationFilter = {
  every?: InputMaybe<StudentRecordWhereInput>;
  none?: InputMaybe<StudentRecordWhereInput>;
  some?: InputMaybe<StudentRecordWhereInput>;
};

export type StudentRecordMaxAggregate = {
  __typename?: "StudentRecordMaxAggregate";
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  schoolId?: Maybe<Scalars["Int"]["output"]>;
  standardId?: Maybe<Scalars["Int"]["output"]>;
  studentId?: Maybe<Scalars["Int"]["output"]>;
};

export type StudentRecordMinAggregate = {
  __typename?: "StudentRecordMinAggregate";
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  schoolId?: Maybe<Scalars["Int"]["output"]>;
  standardId?: Maybe<Scalars["Int"]["output"]>;
  studentId?: Maybe<Scalars["Int"]["output"]>;
};

export type StudentRecordOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type StudentRecordOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  school?: InputMaybe<SchoolOrderByWithRelationInput>;
  schoolId?: InputMaybe<SortOrder>;
  standard?: InputMaybe<StandardOrderByWithRelationInput>;
  standardId?: InputMaybe<SortOrder>;
  student?: InputMaybe<StudentOrderByWithRelationInput>;
  studentId?: InputMaybe<SortOrder>;
};

export enum StudentRecordScalarFieldEnum {
  CREATEDAT = "createdAt",
  SCHOOLID = "schoolId",
  STANDARDID = "standardId",
  STUDENTID = "studentId"
}

export type StudentRecordScalarWhereInput = {
  AND?: InputMaybe<Array<StudentRecordScalarWhereInput>>;
  NOT?: InputMaybe<Array<StudentRecordScalarWhereInput>>;
  OR?: InputMaybe<Array<StudentRecordScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  schoolId?: InputMaybe<IntFilter>;
  standardId?: InputMaybe<IntFilter>;
  studentId?: InputMaybe<IntFilter>;
};

export type StudentRecordStudentIdStandardIdSchoolIdCompoundUniqueInput = {
  schoolId: Scalars["Int"]["input"];
  standardId: Scalars["Int"]["input"];
  studentId: Scalars["Int"]["input"];
};

export type StudentRecordSumAggregate = {
  __typename?: "StudentRecordSumAggregate";
  schoolId?: Maybe<Scalars["Int"]["output"]>;
  standardId?: Maybe<Scalars["Int"]["output"]>;
  studentId?: Maybe<Scalars["Int"]["output"]>;
};

export type StudentRecordUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type StudentRecordUpdateManyWithWhereWithoutSchoolInput = {
  data: StudentRecordUpdateManyMutationInput;
  where: StudentRecordScalarWhereInput;
};

export type StudentRecordUpdateManyWithWhereWithoutStandardInput = {
  data: StudentRecordUpdateManyMutationInput;
  where: StudentRecordScalarWhereInput;
};

export type StudentRecordUpdateManyWithWhereWithoutStudentInput = {
  data: StudentRecordUpdateManyMutationInput;
  where: StudentRecordScalarWhereInput;
};

export type StudentRecordUpdateManyWithoutSchoolNestedInput = {
  connect?: InputMaybe<Array<StudentRecordWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StudentRecordCreateOrConnectWithoutSchoolInput>>;
  create?: InputMaybe<Array<StudentRecordCreateWithoutSchoolInput>>;
  createMany?: InputMaybe<StudentRecordCreateManySchoolInputEnvelope>;
  delete?: InputMaybe<Array<StudentRecordWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<StudentRecordScalarWhereInput>>;
  disconnect?: InputMaybe<Array<StudentRecordWhereUniqueInput>>;
  set?: InputMaybe<Array<StudentRecordWhereUniqueInput>>;
  update?: InputMaybe<Array<StudentRecordUpdateWithWhereUniqueWithoutSchoolInput>>;
  updateMany?: InputMaybe<Array<StudentRecordUpdateManyWithWhereWithoutSchoolInput>>;
  upsert?: InputMaybe<Array<StudentRecordUpsertWithWhereUniqueWithoutSchoolInput>>;
};

export type StudentRecordUpdateManyWithoutStandardNestedInput = {
  connect?: InputMaybe<Array<StudentRecordWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StudentRecordCreateOrConnectWithoutStandardInput>>;
  create?: InputMaybe<Array<StudentRecordCreateWithoutStandardInput>>;
  createMany?: InputMaybe<StudentRecordCreateManyStandardInputEnvelope>;
  delete?: InputMaybe<Array<StudentRecordWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<StudentRecordScalarWhereInput>>;
  disconnect?: InputMaybe<Array<StudentRecordWhereUniqueInput>>;
  set?: InputMaybe<Array<StudentRecordWhereUniqueInput>>;
  update?: InputMaybe<Array<StudentRecordUpdateWithWhereUniqueWithoutStandardInput>>;
  updateMany?: InputMaybe<Array<StudentRecordUpdateManyWithWhereWithoutStandardInput>>;
  upsert?: InputMaybe<Array<StudentRecordUpsertWithWhereUniqueWithoutStandardInput>>;
};

export type StudentRecordUpdateManyWithoutStudentNestedInput = {
  connect?: InputMaybe<Array<StudentRecordWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StudentRecordCreateOrConnectWithoutStudentInput>>;
  create?: InputMaybe<Array<StudentRecordCreateWithoutStudentInput>>;
  createMany?: InputMaybe<StudentRecordCreateManyStudentInputEnvelope>;
  delete?: InputMaybe<Array<StudentRecordWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<StudentRecordScalarWhereInput>>;
  disconnect?: InputMaybe<Array<StudentRecordWhereUniqueInput>>;
  set?: InputMaybe<Array<StudentRecordWhereUniqueInput>>;
  update?: InputMaybe<Array<StudentRecordUpdateWithWhereUniqueWithoutStudentInput>>;
  updateMany?: InputMaybe<Array<StudentRecordUpdateManyWithWhereWithoutStudentInput>>;
  upsert?: InputMaybe<Array<StudentRecordUpsertWithWhereUniqueWithoutStudentInput>>;
};

export type StudentRecordUpdateWithWhereUniqueWithoutSchoolInput = {
  data: StudentRecordUpdateWithoutSchoolInput;
  where: StudentRecordWhereUniqueInput;
};

export type StudentRecordUpdateWithWhereUniqueWithoutStandardInput = {
  data: StudentRecordUpdateWithoutStandardInput;
  where: StudentRecordWhereUniqueInput;
};

export type StudentRecordUpdateWithWhereUniqueWithoutStudentInput = {
  data: StudentRecordUpdateWithoutStudentInput;
  where: StudentRecordWhereUniqueInput;
};

export type StudentRecordUpdateWithoutSchoolInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  standard?: InputMaybe<StandardUpdateOneRequiredWithoutStudentRecordsNestedInput>;
  student?: InputMaybe<StudentUpdateOneRequiredWithoutRecordsNestedInput>;
};

export type StudentRecordUpdateWithoutStandardInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  school?: InputMaybe<SchoolUpdateOneRequiredWithoutStudentRecordsNestedInput>;
  student?: InputMaybe<StudentUpdateOneRequiredWithoutRecordsNestedInput>;
};

export type StudentRecordUpdateWithoutStudentInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  school?: InputMaybe<SchoolUpdateOneRequiredWithoutStudentRecordsNestedInput>;
  standard?: InputMaybe<StandardUpdateOneRequiredWithoutStudentRecordsNestedInput>;
};

export type StudentRecordUpsertWithWhereUniqueWithoutSchoolInput = {
  create: StudentRecordCreateWithoutSchoolInput;
  update: StudentRecordUpdateWithoutSchoolInput;
  where: StudentRecordWhereUniqueInput;
};

export type StudentRecordUpsertWithWhereUniqueWithoutStandardInput = {
  create: StudentRecordCreateWithoutStandardInput;
  update: StudentRecordUpdateWithoutStandardInput;
  where: StudentRecordWhereUniqueInput;
};

export type StudentRecordUpsertWithWhereUniqueWithoutStudentInput = {
  create: StudentRecordCreateWithoutStudentInput;
  update: StudentRecordUpdateWithoutStudentInput;
  where: StudentRecordWhereUniqueInput;
};

export type StudentRecordWhereInput = {
  AND?: InputMaybe<Array<StudentRecordWhereInput>>;
  NOT?: InputMaybe<Array<StudentRecordWhereInput>>;
  OR?: InputMaybe<Array<StudentRecordWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  school?: InputMaybe<SchoolRelationFilter>;
  schoolId?: InputMaybe<IntFilter>;
  standard?: InputMaybe<StandardRelationFilter>;
  standardId?: InputMaybe<IntFilter>;
  student?: InputMaybe<StudentRelationFilter>;
  studentId?: InputMaybe<IntFilter>;
};

export type StudentRecordWhereUniqueInput = {
  studentId_standardId_schoolId?: InputMaybe<StudentRecordStudentIdStandardIdSchoolIdCompoundUniqueInput>;
};

export type StudentReferralCodeResponse = {
  __typename?: "StudentReferralCodeResponse";
  code?: Maybe<Scalars["String"]["output"]>;
  message: Scalars["String"]["output"];
  success: Scalars["Boolean"]["output"];
};

export type StudentRegisterWorkshopOutput = {
  __typename?: "StudentRegisterWorkshopOutput";
  message: Scalars["String"]["output"];
  success: Scalars["Boolean"]["output"];
};

export type StudentRelationFilter = {
  is?: InputMaybe<StudentWhereInput>;
  isNot?: InputMaybe<StudentWhereInput>;
};

export type StudentRewardResponse = {
  __typename?: "StudentRewardResponse";
  badges?: Maybe<Array<Badge>>;
  coins: Scalars["Float"]["output"];
  level: Scalars["Float"]["output"];
  stars: Scalars["Float"]["output"];
  totalXps: Scalars["Float"]["output"];
};

export enum StudentScalarFieldEnum {
  ADDRESS1 = "address1",
  ADDRESS2 = "address2",
  CITY = "city",
  COUNTRY = "country",
  CREATEDAT = "createdAt",
  DISPLAYNAME = "displayName",
  DOB = "dob",
  EMAIL = "email",
  FIRSTNAME = "firstName",
  ID = "id",
  ISACTIVE = "isActive",
  ISHOMESCHOOLER = "isHomeschooler",
  LASTLOGINAT = "lastLoginAt",
  LASTNAME = "lastName",
  LEVEL = "level",
  META = "meta",
  PHONENUMBER = "phoneNumber",
  POSTCODE = "postcode",
  PROFILEIMAGE = "profileImage",
  ROLE = "role",
  SCHOOLID = "schoolId",
  SCHOOLTEXT = "schoolText",
  STANDARDID = "standardId",
  STATE = "state",
  UPDATEDAT = "updatedAt"
}

export type StudentScalarWhereInput = {
  AND?: InputMaybe<Array<StudentScalarWhereInput>>;
  NOT?: InputMaybe<Array<StudentScalarWhereInput>>;
  OR?: InputMaybe<Array<StudentScalarWhereInput>>;
  address1?: InputMaybe<StringNullableFilter>;
  address2?: InputMaybe<StringNullableFilter>;
  city?: InputMaybe<StringNullableFilter>;
  country?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  displayName?: InputMaybe<StringFilter>;
  dob?: InputMaybe<StringNullableFilter>;
  email?: InputMaybe<StringNullableFilter>;
  firstName?: InputMaybe<StringFilter>;
  id?: InputMaybe<IntFilter>;
  isActive?: InputMaybe<BoolFilter>;
  isHomeschooler?: InputMaybe<BoolFilter>;
  lastLoginAt?: InputMaybe<DateTimeNullableFilter>;
  lastName?: InputMaybe<StringFilter>;
  level?: InputMaybe<IntFilter>;
  meta?: InputMaybe<JsonNullableFilter>;
  phoneNumber?: InputMaybe<StringNullableFilter>;
  postcode?: InputMaybe<StringNullableFilter>;
  profileImage?: InputMaybe<StringNullableFilter>;
  role?: InputMaybe<EnumRoleFilter>;
  schoolId?: InputMaybe<IntFilter>;
  schoolText?: InputMaybe<StringNullableFilter>;
  standardId?: InputMaybe<IntFilter>;
  state?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type StudentSkillXpsResponse = {
  __typename?: "StudentSkillXpsResponse";
  data?: Maybe<Scalars["JSON"]["output"]>;
};

export type StudentStoryActivity = {
  __typename?: "StudentStoryActivity";
  commentsCount: Scalars["Int"]["output"];
  difficultyLevel: Scalars["String"]["output"];
  featuredImage?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["Int"]["output"];
  meta?: Maybe<Scalars["JSON"]["output"]>;
  skill: PublicSkill;
  slug: Scalars["String"]["output"];
  story: StudentStoryOutput;
  timeRequired: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type StudentStoryOutput = {
  __typename?: "StudentStoryOutput";
  description?: Maybe<Scalars["String"]["output"]>;
  featuredImage?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["Int"]["output"];
  image?: Maybe<Scalars["String"]["output"]>;
  meta?: Maybe<Scalars["JSON"]["output"]>;
  slug: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type StudentSumAggregate = {
  __typename?: "StudentSumAggregate";
  id?: Maybe<Scalars["Int"]["output"]>;
  level?: Maybe<Scalars["Int"]["output"]>;
  schoolId?: Maybe<Scalars["Int"]["output"]>;
  standardId?: Maybe<Scalars["Int"]["output"]>;
};

export type StudentUpcomingWorkshopOutput = {
  __typename?: "StudentUpcomingWorkshopOutput";
  featuredImage?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["Int"]["output"];
  name: Scalars["String"]["output"];
  slug: Scalars["String"]["output"];
};

export type StudentUpdateInput = {
  address1?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  badges?: InputMaybe<StudentBadgeUpdateManyWithoutStudentNestedInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutCreatedByNestedInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  displayName?: InputMaybe<StringFieldUpdateOperationsInput>;
  dob?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: InputMaybe<StringFieldUpdateOperationsInput>;
  gameLogs?: InputMaybe<StudentGameLogUpdateManyWithoutStudentNestedInput>;
  invitedStudents?: InputMaybe<ReferralCodeHistoryUpdateOneWithoutInvitedStudentNestedInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isHomeschooler?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lastLoginAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lastName?: InputMaybe<StringFieldUpdateOperationsInput>;
  level?: InputMaybe<IntFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  notificationTokens?: InputMaybe<StudentNotificationsUpdateManyWithoutStudentNestedInput>;
  orders?: InputMaybe<OrderUpdateManyWithoutCustomerNestedInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  privacyPolicyUpdates?: InputMaybe<PrivacyPolicyUpdateUpdateManyWithoutStudentNestedInput>;
  profileImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  promoCodeHistory?: InputMaybe<PromoCodeHistoryUpdateManyWithoutStudentNestedInput>;
  records?: InputMaybe<StudentRecordUpdateManyWithoutStudentNestedInput>;
  referralCodes?: InputMaybe<ReferralCodeUpdateManyWithoutStudentNestedInput>;
  referralStudents?: InputMaybe<ReferralCodeHistoryUpdateOneWithoutReferralStudentNestedInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  school?: InputMaybe<SchoolUpdateOneRequiredWithoutStudentsNestedInput>;
  schoolText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  standard?: InputMaybe<StandardUpdateOneRequiredWithoutStudentsNestedInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  workshopRegistrations?: InputMaybe<WorkshopRegistrationUpdateManyWithoutStudentNestedInput>;
};

export type StudentUpdateManyMutationInput = {
  address1?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  displayName?: InputMaybe<StringFieldUpdateOperationsInput>;
  dob?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: InputMaybe<StringFieldUpdateOperationsInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isHomeschooler?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lastLoginAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lastName?: InputMaybe<StringFieldUpdateOperationsInput>;
  level?: InputMaybe<IntFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  profileImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  schoolText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type StudentUpdateManyWithWhereWithoutSchoolInput = {
  data: StudentUpdateManyMutationInput;
  where: StudentScalarWhereInput;
};

export type StudentUpdateManyWithWhereWithoutStandardInput = {
  data: StudentUpdateManyMutationInput;
  where: StudentScalarWhereInput;
};

export type StudentUpdateManyWithoutSchoolNestedInput = {
  connect?: InputMaybe<Array<StudentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StudentCreateOrConnectWithoutSchoolInput>>;
  create?: InputMaybe<Array<StudentCreateWithoutSchoolInput>>;
  createMany?: InputMaybe<StudentCreateManySchoolInputEnvelope>;
  delete?: InputMaybe<Array<StudentWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<StudentScalarWhereInput>>;
  disconnect?: InputMaybe<Array<StudentWhereUniqueInput>>;
  set?: InputMaybe<Array<StudentWhereUniqueInput>>;
  update?: InputMaybe<Array<StudentUpdateWithWhereUniqueWithoutSchoolInput>>;
  updateMany?: InputMaybe<Array<StudentUpdateManyWithWhereWithoutSchoolInput>>;
  upsert?: InputMaybe<Array<StudentUpsertWithWhereUniqueWithoutSchoolInput>>;
};

export type StudentUpdateManyWithoutStandardNestedInput = {
  connect?: InputMaybe<Array<StudentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StudentCreateOrConnectWithoutStandardInput>>;
  create?: InputMaybe<Array<StudentCreateWithoutStandardInput>>;
  createMany?: InputMaybe<StudentCreateManyStandardInputEnvelope>;
  delete?: InputMaybe<Array<StudentWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<StudentScalarWhereInput>>;
  disconnect?: InputMaybe<Array<StudentWhereUniqueInput>>;
  set?: InputMaybe<Array<StudentWhereUniqueInput>>;
  update?: InputMaybe<Array<StudentUpdateWithWhereUniqueWithoutStandardInput>>;
  updateMany?: InputMaybe<Array<StudentUpdateManyWithWhereWithoutStandardInput>>;
  upsert?: InputMaybe<Array<StudentUpsertWithWhereUniqueWithoutStandardInput>>;
};

export type StudentUpdateOneRequiredWithoutBadgesNestedInput = {
  connect?: InputMaybe<StudentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StudentCreateOrConnectWithoutBadgesInput>;
  create?: InputMaybe<StudentCreateWithoutBadgesInput>;
  update?: InputMaybe<StudentUpdateWithoutBadgesInput>;
  upsert?: InputMaybe<StudentUpsertWithoutBadgesInput>;
};

export type StudentUpdateOneRequiredWithoutGameLogsNestedInput = {
  connect?: InputMaybe<StudentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StudentCreateOrConnectWithoutGameLogsInput>;
  create?: InputMaybe<StudentCreateWithoutGameLogsInput>;
  update?: InputMaybe<StudentUpdateWithoutGameLogsInput>;
  upsert?: InputMaybe<StudentUpsertWithoutGameLogsInput>;
};

export type StudentUpdateOneRequiredWithoutInvitedStudentsNestedInput = {
  connect?: InputMaybe<StudentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StudentCreateOrConnectWithoutInvitedStudentsInput>;
  create?: InputMaybe<StudentCreateWithoutInvitedStudentsInput>;
  update?: InputMaybe<StudentUpdateWithoutInvitedStudentsInput>;
  upsert?: InputMaybe<StudentUpsertWithoutInvitedStudentsInput>;
};

export type StudentUpdateOneRequiredWithoutOrdersNestedInput = {
  connect?: InputMaybe<StudentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StudentCreateOrConnectWithoutOrdersInput>;
  create?: InputMaybe<StudentCreateWithoutOrdersInput>;
  update?: InputMaybe<StudentUpdateWithoutOrdersInput>;
  upsert?: InputMaybe<StudentUpsertWithoutOrdersInput>;
};

export type StudentUpdateOneRequiredWithoutPromoCodeHistoryNestedInput = {
  connect?: InputMaybe<StudentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StudentCreateOrConnectWithoutPromoCodeHistoryInput>;
  create?: InputMaybe<StudentCreateWithoutPromoCodeHistoryInput>;
  update?: InputMaybe<StudentUpdateWithoutPromoCodeHistoryInput>;
  upsert?: InputMaybe<StudentUpsertWithoutPromoCodeHistoryInput>;
};

export type StudentUpdateOneRequiredWithoutRecordsNestedInput = {
  connect?: InputMaybe<StudentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StudentCreateOrConnectWithoutRecordsInput>;
  create?: InputMaybe<StudentCreateWithoutRecordsInput>;
  update?: InputMaybe<StudentUpdateWithoutRecordsInput>;
  upsert?: InputMaybe<StudentUpsertWithoutRecordsInput>;
};

export type StudentUpdateOneRequiredWithoutReferralCodesNestedInput = {
  connect?: InputMaybe<StudentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StudentCreateOrConnectWithoutReferralCodesInput>;
  create?: InputMaybe<StudentCreateWithoutReferralCodesInput>;
  update?: InputMaybe<StudentUpdateWithoutReferralCodesInput>;
  upsert?: InputMaybe<StudentUpsertWithoutReferralCodesInput>;
};

export type StudentUpdateOneRequiredWithoutReferralStudentsNestedInput = {
  connect?: InputMaybe<StudentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StudentCreateOrConnectWithoutReferralStudentsInput>;
  create?: InputMaybe<StudentCreateWithoutReferralStudentsInput>;
  update?: InputMaybe<StudentUpdateWithoutReferralStudentsInput>;
  upsert?: InputMaybe<StudentUpsertWithoutReferralStudentsInput>;
};

export type StudentUpdateOneRequiredWithoutWorkshopRegistrationsNestedInput = {
  connect?: InputMaybe<StudentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StudentCreateOrConnectWithoutWorkshopRegistrationsInput>;
  create?: InputMaybe<StudentCreateWithoutWorkshopRegistrationsInput>;
  update?: InputMaybe<StudentUpdateWithoutWorkshopRegistrationsInput>;
  upsert?: InputMaybe<StudentUpsertWithoutWorkshopRegistrationsInput>;
};

export type StudentUpdateOneWithoutCommentsNestedInput = {
  connect?: InputMaybe<StudentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StudentCreateOrConnectWithoutCommentsInput>;
  create?: InputMaybe<StudentCreateWithoutCommentsInput>;
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  update?: InputMaybe<StudentUpdateWithoutCommentsInput>;
  upsert?: InputMaybe<StudentUpsertWithoutCommentsInput>;
};

export type StudentUpdateWithWhereUniqueWithoutSchoolInput = {
  data: StudentUpdateWithoutSchoolInput;
  where: StudentWhereUniqueInput;
};

export type StudentUpdateWithWhereUniqueWithoutStandardInput = {
  data: StudentUpdateWithoutStandardInput;
  where: StudentWhereUniqueInput;
};

export type StudentUpdateWithoutBadgesInput = {
  address1?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutCreatedByNestedInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  displayName?: InputMaybe<StringFieldUpdateOperationsInput>;
  dob?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: InputMaybe<StringFieldUpdateOperationsInput>;
  gameLogs?: InputMaybe<StudentGameLogUpdateManyWithoutStudentNestedInput>;
  invitedStudents?: InputMaybe<ReferralCodeHistoryUpdateOneWithoutInvitedStudentNestedInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isHomeschooler?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lastLoginAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lastName?: InputMaybe<StringFieldUpdateOperationsInput>;
  level?: InputMaybe<IntFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  notificationTokens?: InputMaybe<StudentNotificationsUpdateManyWithoutStudentNestedInput>;
  orders?: InputMaybe<OrderUpdateManyWithoutCustomerNestedInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  privacyPolicyUpdates?: InputMaybe<PrivacyPolicyUpdateUpdateManyWithoutStudentNestedInput>;
  profileImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  promoCodeHistory?: InputMaybe<PromoCodeHistoryUpdateManyWithoutStudentNestedInput>;
  records?: InputMaybe<StudentRecordUpdateManyWithoutStudentNestedInput>;
  referralCodes?: InputMaybe<ReferralCodeUpdateManyWithoutStudentNestedInput>;
  referralStudents?: InputMaybe<ReferralCodeHistoryUpdateOneWithoutReferralStudentNestedInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  school?: InputMaybe<SchoolUpdateOneRequiredWithoutStudentsNestedInput>;
  schoolText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  standard?: InputMaybe<StandardUpdateOneRequiredWithoutStudentsNestedInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  workshopRegistrations?: InputMaybe<WorkshopRegistrationUpdateManyWithoutStudentNestedInput>;
};

export type StudentUpdateWithoutCommentsInput = {
  address1?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  badges?: InputMaybe<StudentBadgeUpdateManyWithoutStudentNestedInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  displayName?: InputMaybe<StringFieldUpdateOperationsInput>;
  dob?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: InputMaybe<StringFieldUpdateOperationsInput>;
  gameLogs?: InputMaybe<StudentGameLogUpdateManyWithoutStudentNestedInput>;
  invitedStudents?: InputMaybe<ReferralCodeHistoryUpdateOneWithoutInvitedStudentNestedInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isHomeschooler?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lastLoginAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lastName?: InputMaybe<StringFieldUpdateOperationsInput>;
  level?: InputMaybe<IntFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  notificationTokens?: InputMaybe<StudentNotificationsUpdateManyWithoutStudentNestedInput>;
  orders?: InputMaybe<OrderUpdateManyWithoutCustomerNestedInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  privacyPolicyUpdates?: InputMaybe<PrivacyPolicyUpdateUpdateManyWithoutStudentNestedInput>;
  profileImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  promoCodeHistory?: InputMaybe<PromoCodeHistoryUpdateManyWithoutStudentNestedInput>;
  records?: InputMaybe<StudentRecordUpdateManyWithoutStudentNestedInput>;
  referralCodes?: InputMaybe<ReferralCodeUpdateManyWithoutStudentNestedInput>;
  referralStudents?: InputMaybe<ReferralCodeHistoryUpdateOneWithoutReferralStudentNestedInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  school?: InputMaybe<SchoolUpdateOneRequiredWithoutStudentsNestedInput>;
  schoolText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  standard?: InputMaybe<StandardUpdateOneRequiredWithoutStudentsNestedInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  workshopRegistrations?: InputMaybe<WorkshopRegistrationUpdateManyWithoutStudentNestedInput>;
};

export type StudentUpdateWithoutGameLogsInput = {
  address1?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  badges?: InputMaybe<StudentBadgeUpdateManyWithoutStudentNestedInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutCreatedByNestedInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  displayName?: InputMaybe<StringFieldUpdateOperationsInput>;
  dob?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: InputMaybe<StringFieldUpdateOperationsInput>;
  invitedStudents?: InputMaybe<ReferralCodeHistoryUpdateOneWithoutInvitedStudentNestedInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isHomeschooler?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lastLoginAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lastName?: InputMaybe<StringFieldUpdateOperationsInput>;
  level?: InputMaybe<IntFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  notificationTokens?: InputMaybe<StudentNotificationsUpdateManyWithoutStudentNestedInput>;
  orders?: InputMaybe<OrderUpdateManyWithoutCustomerNestedInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  privacyPolicyUpdates?: InputMaybe<PrivacyPolicyUpdateUpdateManyWithoutStudentNestedInput>;
  profileImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  promoCodeHistory?: InputMaybe<PromoCodeHistoryUpdateManyWithoutStudentNestedInput>;
  records?: InputMaybe<StudentRecordUpdateManyWithoutStudentNestedInput>;
  referralCodes?: InputMaybe<ReferralCodeUpdateManyWithoutStudentNestedInput>;
  referralStudents?: InputMaybe<ReferralCodeHistoryUpdateOneWithoutReferralStudentNestedInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  school?: InputMaybe<SchoolUpdateOneRequiredWithoutStudentsNestedInput>;
  schoolText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  standard?: InputMaybe<StandardUpdateOneRequiredWithoutStudentsNestedInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  workshopRegistrations?: InputMaybe<WorkshopRegistrationUpdateManyWithoutStudentNestedInput>;
};

export type StudentUpdateWithoutInvitedStudentsInput = {
  address1?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  badges?: InputMaybe<StudentBadgeUpdateManyWithoutStudentNestedInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutCreatedByNestedInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  displayName?: InputMaybe<StringFieldUpdateOperationsInput>;
  dob?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: InputMaybe<StringFieldUpdateOperationsInput>;
  gameLogs?: InputMaybe<StudentGameLogUpdateManyWithoutStudentNestedInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isHomeschooler?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lastLoginAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lastName?: InputMaybe<StringFieldUpdateOperationsInput>;
  level?: InputMaybe<IntFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  notificationTokens?: InputMaybe<StudentNotificationsUpdateManyWithoutStudentNestedInput>;
  orders?: InputMaybe<OrderUpdateManyWithoutCustomerNestedInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  privacyPolicyUpdates?: InputMaybe<PrivacyPolicyUpdateUpdateManyWithoutStudentNestedInput>;
  profileImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  promoCodeHistory?: InputMaybe<PromoCodeHistoryUpdateManyWithoutStudentNestedInput>;
  records?: InputMaybe<StudentRecordUpdateManyWithoutStudentNestedInput>;
  referralCodes?: InputMaybe<ReferralCodeUpdateManyWithoutStudentNestedInput>;
  referralStudents?: InputMaybe<ReferralCodeHistoryUpdateOneWithoutReferralStudentNestedInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  school?: InputMaybe<SchoolUpdateOneRequiredWithoutStudentsNestedInput>;
  schoolText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  standard?: InputMaybe<StandardUpdateOneRequiredWithoutStudentsNestedInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  workshopRegistrations?: InputMaybe<WorkshopRegistrationUpdateManyWithoutStudentNestedInput>;
};

export type StudentUpdateWithoutOrdersInput = {
  address1?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  badges?: InputMaybe<StudentBadgeUpdateManyWithoutStudentNestedInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutCreatedByNestedInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  displayName?: InputMaybe<StringFieldUpdateOperationsInput>;
  dob?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: InputMaybe<StringFieldUpdateOperationsInput>;
  gameLogs?: InputMaybe<StudentGameLogUpdateManyWithoutStudentNestedInput>;
  invitedStudents?: InputMaybe<ReferralCodeHistoryUpdateOneWithoutInvitedStudentNestedInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isHomeschooler?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lastLoginAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lastName?: InputMaybe<StringFieldUpdateOperationsInput>;
  level?: InputMaybe<IntFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  notificationTokens?: InputMaybe<StudentNotificationsUpdateManyWithoutStudentNestedInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  privacyPolicyUpdates?: InputMaybe<PrivacyPolicyUpdateUpdateManyWithoutStudentNestedInput>;
  profileImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  promoCodeHistory?: InputMaybe<PromoCodeHistoryUpdateManyWithoutStudentNestedInput>;
  records?: InputMaybe<StudentRecordUpdateManyWithoutStudentNestedInput>;
  referralCodes?: InputMaybe<ReferralCodeUpdateManyWithoutStudentNestedInput>;
  referralStudents?: InputMaybe<ReferralCodeHistoryUpdateOneWithoutReferralStudentNestedInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  school?: InputMaybe<SchoolUpdateOneRequiredWithoutStudentsNestedInput>;
  schoolText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  standard?: InputMaybe<StandardUpdateOneRequiredWithoutStudentsNestedInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  workshopRegistrations?: InputMaybe<WorkshopRegistrationUpdateManyWithoutStudentNestedInput>;
};

export type StudentUpdateWithoutPromoCodeHistoryInput = {
  address1?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  badges?: InputMaybe<StudentBadgeUpdateManyWithoutStudentNestedInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutCreatedByNestedInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  displayName?: InputMaybe<StringFieldUpdateOperationsInput>;
  dob?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: InputMaybe<StringFieldUpdateOperationsInput>;
  gameLogs?: InputMaybe<StudentGameLogUpdateManyWithoutStudentNestedInput>;
  invitedStudents?: InputMaybe<ReferralCodeHistoryUpdateOneWithoutInvitedStudentNestedInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isHomeschooler?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lastLoginAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lastName?: InputMaybe<StringFieldUpdateOperationsInput>;
  level?: InputMaybe<IntFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  notificationTokens?: InputMaybe<StudentNotificationsUpdateManyWithoutStudentNestedInput>;
  orders?: InputMaybe<OrderUpdateManyWithoutCustomerNestedInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  privacyPolicyUpdates?: InputMaybe<PrivacyPolicyUpdateUpdateManyWithoutStudentNestedInput>;
  profileImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  records?: InputMaybe<StudentRecordUpdateManyWithoutStudentNestedInput>;
  referralCodes?: InputMaybe<ReferralCodeUpdateManyWithoutStudentNestedInput>;
  referralStudents?: InputMaybe<ReferralCodeHistoryUpdateOneWithoutReferralStudentNestedInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  school?: InputMaybe<SchoolUpdateOneRequiredWithoutStudentsNestedInput>;
  schoolText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  standard?: InputMaybe<StandardUpdateOneRequiredWithoutStudentsNestedInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  workshopRegistrations?: InputMaybe<WorkshopRegistrationUpdateManyWithoutStudentNestedInput>;
};

export type StudentUpdateWithoutRecordsInput = {
  address1?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  badges?: InputMaybe<StudentBadgeUpdateManyWithoutStudentNestedInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutCreatedByNestedInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  displayName?: InputMaybe<StringFieldUpdateOperationsInput>;
  dob?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: InputMaybe<StringFieldUpdateOperationsInput>;
  gameLogs?: InputMaybe<StudentGameLogUpdateManyWithoutStudentNestedInput>;
  invitedStudents?: InputMaybe<ReferralCodeHistoryUpdateOneWithoutInvitedStudentNestedInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isHomeschooler?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lastLoginAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lastName?: InputMaybe<StringFieldUpdateOperationsInput>;
  level?: InputMaybe<IntFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  notificationTokens?: InputMaybe<StudentNotificationsUpdateManyWithoutStudentNestedInput>;
  orders?: InputMaybe<OrderUpdateManyWithoutCustomerNestedInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  privacyPolicyUpdates?: InputMaybe<PrivacyPolicyUpdateUpdateManyWithoutStudentNestedInput>;
  profileImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  promoCodeHistory?: InputMaybe<PromoCodeHistoryUpdateManyWithoutStudentNestedInput>;
  referralCodes?: InputMaybe<ReferralCodeUpdateManyWithoutStudentNestedInput>;
  referralStudents?: InputMaybe<ReferralCodeHistoryUpdateOneWithoutReferralStudentNestedInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  school?: InputMaybe<SchoolUpdateOneRequiredWithoutStudentsNestedInput>;
  schoolText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  standard?: InputMaybe<StandardUpdateOneRequiredWithoutStudentsNestedInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  workshopRegistrations?: InputMaybe<WorkshopRegistrationUpdateManyWithoutStudentNestedInput>;
};

export type StudentUpdateWithoutReferralCodesInput = {
  address1?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  badges?: InputMaybe<StudentBadgeUpdateManyWithoutStudentNestedInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutCreatedByNestedInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  displayName?: InputMaybe<StringFieldUpdateOperationsInput>;
  dob?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: InputMaybe<StringFieldUpdateOperationsInput>;
  gameLogs?: InputMaybe<StudentGameLogUpdateManyWithoutStudentNestedInput>;
  invitedStudents?: InputMaybe<ReferralCodeHistoryUpdateOneWithoutInvitedStudentNestedInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isHomeschooler?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lastLoginAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lastName?: InputMaybe<StringFieldUpdateOperationsInput>;
  level?: InputMaybe<IntFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  notificationTokens?: InputMaybe<StudentNotificationsUpdateManyWithoutStudentNestedInput>;
  orders?: InputMaybe<OrderUpdateManyWithoutCustomerNestedInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  privacyPolicyUpdates?: InputMaybe<PrivacyPolicyUpdateUpdateManyWithoutStudentNestedInput>;
  profileImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  promoCodeHistory?: InputMaybe<PromoCodeHistoryUpdateManyWithoutStudentNestedInput>;
  records?: InputMaybe<StudentRecordUpdateManyWithoutStudentNestedInput>;
  referralStudents?: InputMaybe<ReferralCodeHistoryUpdateOneWithoutReferralStudentNestedInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  school?: InputMaybe<SchoolUpdateOneRequiredWithoutStudentsNestedInput>;
  schoolText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  standard?: InputMaybe<StandardUpdateOneRequiredWithoutStudentsNestedInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  workshopRegistrations?: InputMaybe<WorkshopRegistrationUpdateManyWithoutStudentNestedInput>;
};

export type StudentUpdateWithoutReferralStudentsInput = {
  address1?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  badges?: InputMaybe<StudentBadgeUpdateManyWithoutStudentNestedInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutCreatedByNestedInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  displayName?: InputMaybe<StringFieldUpdateOperationsInput>;
  dob?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: InputMaybe<StringFieldUpdateOperationsInput>;
  gameLogs?: InputMaybe<StudentGameLogUpdateManyWithoutStudentNestedInput>;
  invitedStudents?: InputMaybe<ReferralCodeHistoryUpdateOneWithoutInvitedStudentNestedInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isHomeschooler?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lastLoginAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lastName?: InputMaybe<StringFieldUpdateOperationsInput>;
  level?: InputMaybe<IntFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  notificationTokens?: InputMaybe<StudentNotificationsUpdateManyWithoutStudentNestedInput>;
  orders?: InputMaybe<OrderUpdateManyWithoutCustomerNestedInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  privacyPolicyUpdates?: InputMaybe<PrivacyPolicyUpdateUpdateManyWithoutStudentNestedInput>;
  profileImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  promoCodeHistory?: InputMaybe<PromoCodeHistoryUpdateManyWithoutStudentNestedInput>;
  records?: InputMaybe<StudentRecordUpdateManyWithoutStudentNestedInput>;
  referralCodes?: InputMaybe<ReferralCodeUpdateManyWithoutStudentNestedInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  school?: InputMaybe<SchoolUpdateOneRequiredWithoutStudentsNestedInput>;
  schoolText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  standard?: InputMaybe<StandardUpdateOneRequiredWithoutStudentsNestedInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  workshopRegistrations?: InputMaybe<WorkshopRegistrationUpdateManyWithoutStudentNestedInput>;
};

export type StudentUpdateWithoutSchoolInput = {
  address1?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  badges?: InputMaybe<StudentBadgeUpdateManyWithoutStudentNestedInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutCreatedByNestedInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  displayName?: InputMaybe<StringFieldUpdateOperationsInput>;
  dob?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: InputMaybe<StringFieldUpdateOperationsInput>;
  gameLogs?: InputMaybe<StudentGameLogUpdateManyWithoutStudentNestedInput>;
  invitedStudents?: InputMaybe<ReferralCodeHistoryUpdateOneWithoutInvitedStudentNestedInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isHomeschooler?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lastLoginAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lastName?: InputMaybe<StringFieldUpdateOperationsInput>;
  level?: InputMaybe<IntFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  notificationTokens?: InputMaybe<StudentNotificationsUpdateManyWithoutStudentNestedInput>;
  orders?: InputMaybe<OrderUpdateManyWithoutCustomerNestedInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  privacyPolicyUpdates?: InputMaybe<PrivacyPolicyUpdateUpdateManyWithoutStudentNestedInput>;
  profileImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  promoCodeHistory?: InputMaybe<PromoCodeHistoryUpdateManyWithoutStudentNestedInput>;
  records?: InputMaybe<StudentRecordUpdateManyWithoutStudentNestedInput>;
  referralCodes?: InputMaybe<ReferralCodeUpdateManyWithoutStudentNestedInput>;
  referralStudents?: InputMaybe<ReferralCodeHistoryUpdateOneWithoutReferralStudentNestedInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  schoolText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  standard?: InputMaybe<StandardUpdateOneRequiredWithoutStudentsNestedInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  workshopRegistrations?: InputMaybe<WorkshopRegistrationUpdateManyWithoutStudentNestedInput>;
};

export type StudentUpdateWithoutStandardInput = {
  address1?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  badges?: InputMaybe<StudentBadgeUpdateManyWithoutStudentNestedInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutCreatedByNestedInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  displayName?: InputMaybe<StringFieldUpdateOperationsInput>;
  dob?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: InputMaybe<StringFieldUpdateOperationsInput>;
  gameLogs?: InputMaybe<StudentGameLogUpdateManyWithoutStudentNestedInput>;
  invitedStudents?: InputMaybe<ReferralCodeHistoryUpdateOneWithoutInvitedStudentNestedInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isHomeschooler?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lastLoginAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lastName?: InputMaybe<StringFieldUpdateOperationsInput>;
  level?: InputMaybe<IntFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  notificationTokens?: InputMaybe<StudentNotificationsUpdateManyWithoutStudentNestedInput>;
  orders?: InputMaybe<OrderUpdateManyWithoutCustomerNestedInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  privacyPolicyUpdates?: InputMaybe<PrivacyPolicyUpdateUpdateManyWithoutStudentNestedInput>;
  profileImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  promoCodeHistory?: InputMaybe<PromoCodeHistoryUpdateManyWithoutStudentNestedInput>;
  records?: InputMaybe<StudentRecordUpdateManyWithoutStudentNestedInput>;
  referralCodes?: InputMaybe<ReferralCodeUpdateManyWithoutStudentNestedInput>;
  referralStudents?: InputMaybe<ReferralCodeHistoryUpdateOneWithoutReferralStudentNestedInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  school?: InputMaybe<SchoolUpdateOneRequiredWithoutStudentsNestedInput>;
  schoolText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  workshopRegistrations?: InputMaybe<WorkshopRegistrationUpdateManyWithoutStudentNestedInput>;
};

export type StudentUpdateWithoutWorkshopRegistrationsInput = {
  address1?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  badges?: InputMaybe<StudentBadgeUpdateManyWithoutStudentNestedInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutCreatedByNestedInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  displayName?: InputMaybe<StringFieldUpdateOperationsInput>;
  dob?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: InputMaybe<StringFieldUpdateOperationsInput>;
  gameLogs?: InputMaybe<StudentGameLogUpdateManyWithoutStudentNestedInput>;
  invitedStudents?: InputMaybe<ReferralCodeHistoryUpdateOneWithoutInvitedStudentNestedInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isHomeschooler?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lastLoginAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lastName?: InputMaybe<StringFieldUpdateOperationsInput>;
  level?: InputMaybe<IntFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  notificationTokens?: InputMaybe<StudentNotificationsUpdateManyWithoutStudentNestedInput>;
  orders?: InputMaybe<OrderUpdateManyWithoutCustomerNestedInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  privacyPolicyUpdates?: InputMaybe<PrivacyPolicyUpdateUpdateManyWithoutStudentNestedInput>;
  profileImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  promoCodeHistory?: InputMaybe<PromoCodeHistoryUpdateManyWithoutStudentNestedInput>;
  records?: InputMaybe<StudentRecordUpdateManyWithoutStudentNestedInput>;
  referralCodes?: InputMaybe<ReferralCodeUpdateManyWithoutStudentNestedInput>;
  referralStudents?: InputMaybe<ReferralCodeHistoryUpdateOneWithoutReferralStudentNestedInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  school?: InputMaybe<SchoolUpdateOneRequiredWithoutStudentsNestedInput>;
  schoolText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  standard?: InputMaybe<StandardUpdateOneRequiredWithoutStudentsNestedInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type StudentUpsertWithWhereUniqueWithoutSchoolInput = {
  create: StudentCreateWithoutSchoolInput;
  update: StudentUpdateWithoutSchoolInput;
  where: StudentWhereUniqueInput;
};

export type StudentUpsertWithWhereUniqueWithoutStandardInput = {
  create: StudentCreateWithoutStandardInput;
  update: StudentUpdateWithoutStandardInput;
  where: StudentWhereUniqueInput;
};

export type StudentUpsertWithoutBadgesInput = {
  create: StudentCreateWithoutBadgesInput;
  update: StudentUpdateWithoutBadgesInput;
};

export type StudentUpsertWithoutCommentsInput = {
  create: StudentCreateWithoutCommentsInput;
  update: StudentUpdateWithoutCommentsInput;
};

export type StudentUpsertWithoutGameLogsInput = {
  create: StudentCreateWithoutGameLogsInput;
  update: StudentUpdateWithoutGameLogsInput;
};

export type StudentUpsertWithoutInvitedStudentsInput = {
  create: StudentCreateWithoutInvitedStudentsInput;
  update: StudentUpdateWithoutInvitedStudentsInput;
};

export type StudentUpsertWithoutOrdersInput = {
  create: StudentCreateWithoutOrdersInput;
  update: StudentUpdateWithoutOrdersInput;
};

export type StudentUpsertWithoutPromoCodeHistoryInput = {
  create: StudentCreateWithoutPromoCodeHistoryInput;
  update: StudentUpdateWithoutPromoCodeHistoryInput;
};

export type StudentUpsertWithoutRecordsInput = {
  create: StudentCreateWithoutRecordsInput;
  update: StudentUpdateWithoutRecordsInput;
};

export type StudentUpsertWithoutReferralCodesInput = {
  create: StudentCreateWithoutReferralCodesInput;
  update: StudentUpdateWithoutReferralCodesInput;
};

export type StudentUpsertWithoutReferralStudentsInput = {
  create: StudentCreateWithoutReferralStudentsInput;
  update: StudentUpdateWithoutReferralStudentsInput;
};

export type StudentUpsertWithoutWorkshopRegistrationsInput = {
  create: StudentCreateWithoutWorkshopRegistrationsInput;
  update: StudentUpdateWithoutWorkshopRegistrationsInput;
};

export type StudentWhereInput = {
  AND?: InputMaybe<Array<StudentWhereInput>>;
  NOT?: InputMaybe<Array<StudentWhereInput>>;
  OR?: InputMaybe<Array<StudentWhereInput>>;
  address1?: InputMaybe<StringNullableFilter>;
  address2?: InputMaybe<StringNullableFilter>;
  badges?: InputMaybe<StudentBadgeListRelationFilter>;
  city?: InputMaybe<StringNullableFilter>;
  comments?: InputMaybe<CommentListRelationFilter>;
  country?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  displayName?: InputMaybe<StringFilter>;
  dob?: InputMaybe<StringNullableFilter>;
  email?: InputMaybe<StringNullableFilter>;
  firstName?: InputMaybe<StringFilter>;
  gameLogs?: InputMaybe<StudentGameLogListRelationFilter>;
  id?: InputMaybe<IntFilter>;
  invitedStudents?: InputMaybe<ReferralCodeHistoryRelationFilter>;
  isActive?: InputMaybe<BoolFilter>;
  isHomeschooler?: InputMaybe<BoolFilter>;
  lastLoginAt?: InputMaybe<DateTimeNullableFilter>;
  lastName?: InputMaybe<StringFilter>;
  level?: InputMaybe<IntFilter>;
  meta?: InputMaybe<JsonNullableFilter>;
  notificationTokens?: InputMaybe<StudentNotificationsListRelationFilter>;
  orders?: InputMaybe<OrderListRelationFilter>;
  phoneNumber?: InputMaybe<StringNullableFilter>;
  postcode?: InputMaybe<StringNullableFilter>;
  privacyPolicyUpdates?: InputMaybe<PrivacyPolicyUpdateListRelationFilter>;
  profileImage?: InputMaybe<StringNullableFilter>;
  promoCodeHistory?: InputMaybe<PromoCodeHistoryListRelationFilter>;
  records?: InputMaybe<StudentRecordListRelationFilter>;
  referralCodes?: InputMaybe<ReferralCodeListRelationFilter>;
  referralStudents?: InputMaybe<ReferralCodeHistoryRelationFilter>;
  role?: InputMaybe<EnumRoleFilter>;
  school?: InputMaybe<SchoolRelationFilter>;
  schoolId?: InputMaybe<IntFilter>;
  schoolText?: InputMaybe<StringNullableFilter>;
  standard?: InputMaybe<StandardRelationFilter>;
  standardId?: InputMaybe<IntFilter>;
  state?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  workshopRegistrations?: InputMaybe<WorkshopRegistrationListRelationFilter>;
};

export type StudentWhereUniqueInput = {
  email?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber?: InputMaybe<Scalars["String"]["input"]>;
};

export type StudentWorkshopUniqueInput = {
  id?: InputMaybe<Scalars["Int"]["input"]>;
  slug?: InputMaybe<Scalars["String"]["input"]>;
};

export type StudentWorkshopsOutput = {
  __typename?: "StudentWorkshopsOutput";
  featuredImage?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["Int"]["output"];
  name: Scalars["String"]["output"];
  slug: Scalars["String"]["output"];
};

export type Tag = {
  __typename?: "Tag";
  _count?: Maybe<TagCount>;
  activities: Array<Activity>;
  buyScript?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  description?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["Int"]["output"];
  jsonLD?: Maybe<Scalars["JSON"]["output"]>;
  metaTagData?: Maybe<Scalars["JSON"]["output"]>;
  name: Scalars["String"]["output"];
  productScript?: Maybe<Scalars["String"]["output"]>;
  seoContent?: Maybe<Scalars["String"]["output"]>;
  slug: Scalars["String"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
  workshops: Array<Workshop>;
};


export type TagActivitiesArgs = {
  cursor?: InputMaybe<ActivityWhereUniqueInput>;
  distinct?: InputMaybe<Array<ActivityScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ActivityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ActivityWhereInput>;
};


export type TagWorkshopsArgs = {
  cursor?: InputMaybe<WorkshopWhereUniqueInput>;
  distinct?: InputMaybe<Array<WorkshopScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<WorkshopOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<WorkshopWhereInput>;
};

export type TagAvgAggregate = {
  __typename?: "TagAvgAggregate";
  id?: Maybe<Scalars["Float"]["output"]>;
};

export type TagCount = {
  __typename?: "TagCount";
  activities: Scalars["Int"]["output"];
  workshops: Scalars["Int"]["output"];
};


export type TagCountActivitiesArgs = {
  where?: InputMaybe<ActivityWhereInput>;
};


export type TagCountWorkshopsArgs = {
  where?: InputMaybe<WorkshopWhereInput>;
};

export type TagCountAggregate = {
  __typename?: "TagCountAggregate";
  _all: Scalars["Int"]["output"];
  buyScript: Scalars["Int"]["output"];
  createdAt: Scalars["Int"]["output"];
  description: Scalars["Int"]["output"];
  id: Scalars["Int"]["output"];
  jsonLD: Scalars["Int"]["output"];
  metaTagData: Scalars["Int"]["output"];
  name: Scalars["Int"]["output"];
  productScript: Scalars["Int"]["output"];
  seoContent: Scalars["Int"]["output"];
  slug: Scalars["Int"]["output"];
  updatedAt: Scalars["Int"]["output"];
};

export type TagCreateInput = {
  activities?: InputMaybe<ActivityCreateNestedManyWithoutTagsInput>;
  buyScript?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  jsonLD?: InputMaybe<Scalars["JSON"]["input"]>;
  metaTagData?: InputMaybe<Scalars["JSON"]["input"]>;
  name: Scalars["String"]["input"];
  productScript?: InputMaybe<Scalars["String"]["input"]>;
  seoContent?: InputMaybe<Scalars["String"]["input"]>;
  slug: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  workshops?: InputMaybe<WorkshopCreateNestedManyWithoutTagsInput>;
};

export type TagCreateNestedManyWithoutActivitiesInput = {
  connect?: InputMaybe<Array<TagWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TagCreateOrConnectWithoutActivitiesInput>>;
  create?: InputMaybe<Array<TagCreateWithoutActivitiesInput>>;
};

export type TagCreateNestedManyWithoutWorkshopsInput = {
  connect?: InputMaybe<Array<TagWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TagCreateOrConnectWithoutWorkshopsInput>>;
  create?: InputMaybe<Array<TagCreateWithoutWorkshopsInput>>;
};

export type TagCreateOrConnectWithoutActivitiesInput = {
  create: TagCreateWithoutActivitiesInput;
  where: TagWhereUniqueInput;
};

export type TagCreateOrConnectWithoutWorkshopsInput = {
  create: TagCreateWithoutWorkshopsInput;
  where: TagWhereUniqueInput;
};

export type TagCreateWithoutActivitiesInput = {
  buyScript?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  jsonLD?: InputMaybe<Scalars["JSON"]["input"]>;
  metaTagData?: InputMaybe<Scalars["JSON"]["input"]>;
  name: Scalars["String"]["input"];
  productScript?: InputMaybe<Scalars["String"]["input"]>;
  seoContent?: InputMaybe<Scalars["String"]["input"]>;
  slug: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  workshops?: InputMaybe<WorkshopCreateNestedManyWithoutTagsInput>;
};

export type TagCreateWithoutWorkshopsInput = {
  activities?: InputMaybe<ActivityCreateNestedManyWithoutTagsInput>;
  buyScript?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  jsonLD?: InputMaybe<Scalars["JSON"]["input"]>;
  metaTagData?: InputMaybe<Scalars["JSON"]["input"]>;
  name: Scalars["String"]["input"];
  productScript?: InputMaybe<Scalars["String"]["input"]>;
  seoContent?: InputMaybe<Scalars["String"]["input"]>;
  slug: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type TagListRelationFilter = {
  every?: InputMaybe<TagWhereInput>;
  none?: InputMaybe<TagWhereInput>;
  some?: InputMaybe<TagWhereInput>;
};

export type TagMaxAggregate = {
  __typename?: "TagMaxAggregate";
  buyScript?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  productScript?: Maybe<Scalars["String"]["output"]>;
  seoContent?: Maybe<Scalars["String"]["output"]>;
  slug?: Maybe<Scalars["String"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type TagMinAggregate = {
  __typename?: "TagMinAggregate";
  buyScript?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  productScript?: Maybe<Scalars["String"]["output"]>;
  seoContent?: Maybe<Scalars["String"]["output"]>;
  slug?: Maybe<Scalars["String"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type TagOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type TagOrderByWithRelationInput = {
  activities?: InputMaybe<ActivityOrderByRelationAggregateInput>;
  buyScript?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  jsonLD?: InputMaybe<SortOrderInput>;
  metaTagData?: InputMaybe<SortOrderInput>;
  name?: InputMaybe<SortOrder>;
  productScript?: InputMaybe<SortOrderInput>;
  seoContent?: InputMaybe<SortOrderInput>;
  slug?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  workshops?: InputMaybe<WorkshopOrderByRelationAggregateInput>;
};

export enum TagScalarFieldEnum {
  BUYSCRIPT = "buyScript",
  CREATEDAT = "createdAt",
  DESCRIPTION = "description",
  ID = "id",
  JSONLD = "jsonLD",
  METATAGDATA = "metaTagData",
  NAME = "name",
  PRODUCTSCRIPT = "productScript",
  SEOCONTENT = "seoContent",
  SLUG = "slug",
  UPDATEDAT = "updatedAt"
}

export type TagScalarWhereInput = {
  AND?: InputMaybe<Array<TagScalarWhereInput>>;
  NOT?: InputMaybe<Array<TagScalarWhereInput>>;
  OR?: InputMaybe<Array<TagScalarWhereInput>>;
  buyScript?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  jsonLD?: InputMaybe<JsonNullableFilter>;
  metaTagData?: InputMaybe<JsonNullableFilter>;
  name?: InputMaybe<StringFilter>;
  productScript?: InputMaybe<StringNullableFilter>;
  seoContent?: InputMaybe<StringNullableFilter>;
  slug?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type TagSumAggregate = {
  __typename?: "TagSumAggregate";
  id?: Maybe<Scalars["Int"]["output"]>;
};

export type TagUpdateInput = {
  activities?: InputMaybe<ActivityUpdateManyWithoutTagsNestedInput>;
  buyScript?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  jsonLD?: InputMaybe<Scalars["JSON"]["input"]>;
  metaTagData?: InputMaybe<Scalars["JSON"]["input"]>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  productScript?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  seoContent?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  workshops?: InputMaybe<WorkshopUpdateManyWithoutTagsNestedInput>;
};

export type TagUpdateManyMutationInput = {
  buyScript?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  jsonLD?: InputMaybe<Scalars["JSON"]["input"]>;
  metaTagData?: InputMaybe<Scalars["JSON"]["input"]>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  productScript?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  seoContent?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TagUpdateManyWithWhereWithoutActivitiesInput = {
  data: TagUpdateManyMutationInput;
  where: TagScalarWhereInput;
};

export type TagUpdateManyWithWhereWithoutWorkshopsInput = {
  data: TagUpdateManyMutationInput;
  where: TagScalarWhereInput;
};

export type TagUpdateManyWithoutActivitiesNestedInput = {
  connect?: InputMaybe<Array<TagWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TagCreateOrConnectWithoutActivitiesInput>>;
  create?: InputMaybe<Array<TagCreateWithoutActivitiesInput>>;
  delete?: InputMaybe<Array<TagWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TagScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TagWhereUniqueInput>>;
  set?: InputMaybe<Array<TagWhereUniqueInput>>;
  update?: InputMaybe<Array<TagUpdateWithWhereUniqueWithoutActivitiesInput>>;
  updateMany?: InputMaybe<Array<TagUpdateManyWithWhereWithoutActivitiesInput>>;
  upsert?: InputMaybe<Array<TagUpsertWithWhereUniqueWithoutActivitiesInput>>;
};

export type TagUpdateManyWithoutWorkshopsNestedInput = {
  connect?: InputMaybe<Array<TagWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TagCreateOrConnectWithoutWorkshopsInput>>;
  create?: InputMaybe<Array<TagCreateWithoutWorkshopsInput>>;
  delete?: InputMaybe<Array<TagWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TagScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TagWhereUniqueInput>>;
  set?: InputMaybe<Array<TagWhereUniqueInput>>;
  update?: InputMaybe<Array<TagUpdateWithWhereUniqueWithoutWorkshopsInput>>;
  updateMany?: InputMaybe<Array<TagUpdateManyWithWhereWithoutWorkshopsInput>>;
  upsert?: InputMaybe<Array<TagUpsertWithWhereUniqueWithoutWorkshopsInput>>;
};

export type TagUpdateWithWhereUniqueWithoutActivitiesInput = {
  data: TagUpdateWithoutActivitiesInput;
  where: TagWhereUniqueInput;
};

export type TagUpdateWithWhereUniqueWithoutWorkshopsInput = {
  data: TagUpdateWithoutWorkshopsInput;
  where: TagWhereUniqueInput;
};

export type TagUpdateWithoutActivitiesInput = {
  buyScript?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  jsonLD?: InputMaybe<Scalars["JSON"]["input"]>;
  metaTagData?: InputMaybe<Scalars["JSON"]["input"]>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  productScript?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  seoContent?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  workshops?: InputMaybe<WorkshopUpdateManyWithoutTagsNestedInput>;
};

export type TagUpdateWithoutWorkshopsInput = {
  activities?: InputMaybe<ActivityUpdateManyWithoutTagsNestedInput>;
  buyScript?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  jsonLD?: InputMaybe<Scalars["JSON"]["input"]>;
  metaTagData?: InputMaybe<Scalars["JSON"]["input"]>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  productScript?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  seoContent?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TagUpsertWithWhereUniqueWithoutActivitiesInput = {
  create: TagCreateWithoutActivitiesInput;
  update: TagUpdateWithoutActivitiesInput;
  where: TagWhereUniqueInput;
};

export type TagUpsertWithWhereUniqueWithoutWorkshopsInput = {
  create: TagCreateWithoutWorkshopsInput;
  update: TagUpdateWithoutWorkshopsInput;
  where: TagWhereUniqueInput;
};

export type TagWhereInput = {
  AND?: InputMaybe<Array<TagWhereInput>>;
  NOT?: InputMaybe<Array<TagWhereInput>>;
  OR?: InputMaybe<Array<TagWhereInput>>;
  activities?: InputMaybe<ActivityListRelationFilter>;
  buyScript?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  jsonLD?: InputMaybe<JsonNullableFilter>;
  metaTagData?: InputMaybe<JsonNullableFilter>;
  name?: InputMaybe<StringFilter>;
  productScript?: InputMaybe<StringNullableFilter>;
  seoContent?: InputMaybe<StringNullableFilter>;
  slug?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  workshops?: InputMaybe<WorkshopListRelationFilter>;
};

export type TagWhereUniqueInput = {
  id?: InputMaybe<Scalars["Int"]["input"]>;
  slug?: InputMaybe<Scalars["String"]["input"]>;
};

export type Trigger = {
  __typename?: "Trigger";
  _count?: Maybe<TriggerCount>;
  badge?: Maybe<Badge>;
  badgeId?: Maybe<Scalars["Int"]["output"]>;
  countDuration?: Maybe<Scalars["Int"]["output"]>;
  countPeriod?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  earn: Scalars["Int"]["output"];
  gamePointType?: Maybe<GamePointType>;
  gamePointTypeId?: Maybe<Scalars["Int"]["output"]>;
  id: Scalars["Int"]["output"];
  isActive: Scalars["Boolean"]["output"];
  key: Scalars["String"]["output"];
  label?: Maybe<Scalars["String"]["output"]>;
  level?: Maybe<Level>;
  levelId?: Maybe<Scalars["Int"]["output"]>;
  maximumEarningsPerUser: Scalars["Int"]["output"];
  model?: Maybe<Scalars["String"]["output"]>;
  modelId?: Maybe<Scalars["Int"]["output"]>;
  studentGameLogs: Array<StudentGameLog>;
  text: Scalars["String"]["output"];
  totalCount: Scalars["Int"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};


export type TriggerStudentGameLogsArgs = {
  cursor?: InputMaybe<StudentGameLogWhereUniqueInput>;
  distinct?: InputMaybe<Array<StudentGameLogScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StudentGameLogOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<StudentGameLogWhereInput>;
};

export type TriggerAvgAggregate = {
  __typename?: "TriggerAvgAggregate";
  badgeId?: Maybe<Scalars["Float"]["output"]>;
  countDuration?: Maybe<Scalars["Float"]["output"]>;
  earn?: Maybe<Scalars["Float"]["output"]>;
  gamePointTypeId?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  levelId?: Maybe<Scalars["Float"]["output"]>;
  maximumEarningsPerUser?: Maybe<Scalars["Float"]["output"]>;
  modelId?: Maybe<Scalars["Float"]["output"]>;
  totalCount?: Maybe<Scalars["Float"]["output"]>;
};

export type TriggerCount = {
  __typename?: "TriggerCount";
  studentGameLogs: Scalars["Int"]["output"];
};


export type TriggerCountStudentGameLogsArgs = {
  where?: InputMaybe<StudentGameLogWhereInput>;
};

export type TriggerCountAggregate = {
  __typename?: "TriggerCountAggregate";
  _all: Scalars["Int"]["output"];
  badgeId: Scalars["Int"]["output"];
  countDuration: Scalars["Int"]["output"];
  countPeriod: Scalars["Int"]["output"];
  createdAt: Scalars["Int"]["output"];
  earn: Scalars["Int"]["output"];
  gamePointTypeId: Scalars["Int"]["output"];
  id: Scalars["Int"]["output"];
  isActive: Scalars["Int"]["output"];
  key: Scalars["Int"]["output"];
  label: Scalars["Int"]["output"];
  levelId: Scalars["Int"]["output"];
  maximumEarningsPerUser: Scalars["Int"]["output"];
  model: Scalars["Int"]["output"];
  modelId: Scalars["Int"]["output"];
  text: Scalars["Int"]["output"];
  totalCount: Scalars["Int"]["output"];
  updatedAt: Scalars["Int"]["output"];
};

export type TriggerCreateInput = {
  badge?: InputMaybe<BadgeCreateNestedOneWithoutTriggersInput>;
  countDuration?: InputMaybe<Scalars["Int"]["input"]>;
  countPeriod?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  earn: Scalars["Int"]["input"];
  gamePointType?: InputMaybe<GamePointTypeCreateNestedOneWithoutTriggersInput>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  key: Scalars["String"]["input"];
  label?: InputMaybe<Scalars["String"]["input"]>;
  level?: InputMaybe<LevelCreateNestedOneWithoutTriggersInput>;
  maximumEarningsPerUser: Scalars["Int"]["input"];
  model?: InputMaybe<Scalars["String"]["input"]>;
  modelId?: InputMaybe<Scalars["Int"]["input"]>;
  studentGameLogs?: InputMaybe<StudentGameLogCreateNestedManyWithoutTriggerInput>;
  text: Scalars["String"]["input"];
  totalCount: Scalars["Int"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type TriggerCreateManyBadgeInput = {
  countDuration?: InputMaybe<Scalars["Int"]["input"]>;
  countPeriod?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  earn: Scalars["Int"]["input"];
  gamePointTypeId?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  key: Scalars["String"]["input"];
  label?: InputMaybe<Scalars["String"]["input"]>;
  levelId?: InputMaybe<Scalars["Int"]["input"]>;
  maximumEarningsPerUser: Scalars["Int"]["input"];
  model?: InputMaybe<Scalars["String"]["input"]>;
  modelId?: InputMaybe<Scalars["Int"]["input"]>;
  text: Scalars["String"]["input"];
  totalCount: Scalars["Int"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type TriggerCreateManyBadgeInputEnvelope = {
  data: Array<TriggerCreateManyBadgeInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type TriggerCreateManyGamePointTypeInput = {
  badgeId?: InputMaybe<Scalars["Int"]["input"]>;
  countDuration?: InputMaybe<Scalars["Int"]["input"]>;
  countPeriod?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  earn: Scalars["Int"]["input"];
  id?: InputMaybe<Scalars["Int"]["input"]>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  key: Scalars["String"]["input"];
  label?: InputMaybe<Scalars["String"]["input"]>;
  levelId?: InputMaybe<Scalars["Int"]["input"]>;
  maximumEarningsPerUser: Scalars["Int"]["input"];
  model?: InputMaybe<Scalars["String"]["input"]>;
  modelId?: InputMaybe<Scalars["Int"]["input"]>;
  text: Scalars["String"]["input"];
  totalCount: Scalars["Int"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type TriggerCreateManyGamePointTypeInputEnvelope = {
  data: Array<TriggerCreateManyGamePointTypeInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type TriggerCreateManyLevelInput = {
  badgeId?: InputMaybe<Scalars["Int"]["input"]>;
  countDuration?: InputMaybe<Scalars["Int"]["input"]>;
  countPeriod?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  earn: Scalars["Int"]["input"];
  gamePointTypeId?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  key: Scalars["String"]["input"];
  label?: InputMaybe<Scalars["String"]["input"]>;
  maximumEarningsPerUser: Scalars["Int"]["input"];
  model?: InputMaybe<Scalars["String"]["input"]>;
  modelId?: InputMaybe<Scalars["Int"]["input"]>;
  text: Scalars["String"]["input"];
  totalCount: Scalars["Int"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type TriggerCreateManyLevelInputEnvelope = {
  data: Array<TriggerCreateManyLevelInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type TriggerCreateNestedManyWithoutBadgeInput = {
  connect?: InputMaybe<Array<TriggerWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TriggerCreateOrConnectWithoutBadgeInput>>;
  create?: InputMaybe<Array<TriggerCreateWithoutBadgeInput>>;
  createMany?: InputMaybe<TriggerCreateManyBadgeInputEnvelope>;
};

export type TriggerCreateNestedManyWithoutGamePointTypeInput = {
  connect?: InputMaybe<Array<TriggerWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TriggerCreateOrConnectWithoutGamePointTypeInput>>;
  create?: InputMaybe<Array<TriggerCreateWithoutGamePointTypeInput>>;
  createMany?: InputMaybe<TriggerCreateManyGamePointTypeInputEnvelope>;
};

export type TriggerCreateNestedManyWithoutLevelInput = {
  connect?: InputMaybe<Array<TriggerWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TriggerCreateOrConnectWithoutLevelInput>>;
  create?: InputMaybe<Array<TriggerCreateWithoutLevelInput>>;
  createMany?: InputMaybe<TriggerCreateManyLevelInputEnvelope>;
};

export type TriggerCreateNestedOneWithoutStudentGameLogsInput = {
  connect?: InputMaybe<TriggerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TriggerCreateOrConnectWithoutStudentGameLogsInput>;
  create?: InputMaybe<TriggerCreateWithoutStudentGameLogsInput>;
};

export type TriggerCreateOrConnectWithoutBadgeInput = {
  create: TriggerCreateWithoutBadgeInput;
  where: TriggerWhereUniqueInput;
};

export type TriggerCreateOrConnectWithoutGamePointTypeInput = {
  create: TriggerCreateWithoutGamePointTypeInput;
  where: TriggerWhereUniqueInput;
};

export type TriggerCreateOrConnectWithoutLevelInput = {
  create: TriggerCreateWithoutLevelInput;
  where: TriggerWhereUniqueInput;
};

export type TriggerCreateOrConnectWithoutStudentGameLogsInput = {
  create: TriggerCreateWithoutStudentGameLogsInput;
  where: TriggerWhereUniqueInput;
};

export type TriggerCreateWithoutBadgeInput = {
  countDuration?: InputMaybe<Scalars["Int"]["input"]>;
  countPeriod?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  earn: Scalars["Int"]["input"];
  gamePointType?: InputMaybe<GamePointTypeCreateNestedOneWithoutTriggersInput>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  key: Scalars["String"]["input"];
  label?: InputMaybe<Scalars["String"]["input"]>;
  level?: InputMaybe<LevelCreateNestedOneWithoutTriggersInput>;
  maximumEarningsPerUser: Scalars["Int"]["input"];
  model?: InputMaybe<Scalars["String"]["input"]>;
  modelId?: InputMaybe<Scalars["Int"]["input"]>;
  studentGameLogs?: InputMaybe<StudentGameLogCreateNestedManyWithoutTriggerInput>;
  text: Scalars["String"]["input"];
  totalCount: Scalars["Int"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type TriggerCreateWithoutGamePointTypeInput = {
  badge?: InputMaybe<BadgeCreateNestedOneWithoutTriggersInput>;
  countDuration?: InputMaybe<Scalars["Int"]["input"]>;
  countPeriod?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  earn: Scalars["Int"]["input"];
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  key: Scalars["String"]["input"];
  label?: InputMaybe<Scalars["String"]["input"]>;
  level?: InputMaybe<LevelCreateNestedOneWithoutTriggersInput>;
  maximumEarningsPerUser: Scalars["Int"]["input"];
  model?: InputMaybe<Scalars["String"]["input"]>;
  modelId?: InputMaybe<Scalars["Int"]["input"]>;
  studentGameLogs?: InputMaybe<StudentGameLogCreateNestedManyWithoutTriggerInput>;
  text: Scalars["String"]["input"];
  totalCount: Scalars["Int"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type TriggerCreateWithoutLevelInput = {
  badge?: InputMaybe<BadgeCreateNestedOneWithoutTriggersInput>;
  countDuration?: InputMaybe<Scalars["Int"]["input"]>;
  countPeriod?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  earn: Scalars["Int"]["input"];
  gamePointType?: InputMaybe<GamePointTypeCreateNestedOneWithoutTriggersInput>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  key: Scalars["String"]["input"];
  label?: InputMaybe<Scalars["String"]["input"]>;
  maximumEarningsPerUser: Scalars["Int"]["input"];
  model?: InputMaybe<Scalars["String"]["input"]>;
  modelId?: InputMaybe<Scalars["Int"]["input"]>;
  studentGameLogs?: InputMaybe<StudentGameLogCreateNestedManyWithoutTriggerInput>;
  text: Scalars["String"]["input"];
  totalCount: Scalars["Int"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type TriggerCreateWithoutStudentGameLogsInput = {
  badge?: InputMaybe<BadgeCreateNestedOneWithoutTriggersInput>;
  countDuration?: InputMaybe<Scalars["Int"]["input"]>;
  countPeriod?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  earn: Scalars["Int"]["input"];
  gamePointType?: InputMaybe<GamePointTypeCreateNestedOneWithoutTriggersInput>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  key: Scalars["String"]["input"];
  label?: InputMaybe<Scalars["String"]["input"]>;
  level?: InputMaybe<LevelCreateNestedOneWithoutTriggersInput>;
  maximumEarningsPerUser: Scalars["Int"]["input"];
  model?: InputMaybe<Scalars["String"]["input"]>;
  modelId?: InputMaybe<Scalars["Int"]["input"]>;
  text: Scalars["String"]["input"];
  totalCount: Scalars["Int"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type TriggerListRelationFilter = {
  every?: InputMaybe<TriggerWhereInput>;
  none?: InputMaybe<TriggerWhereInput>;
  some?: InputMaybe<TriggerWhereInput>;
};

export type TriggerMaxAggregate = {
  __typename?: "TriggerMaxAggregate";
  badgeId?: Maybe<Scalars["Int"]["output"]>;
  countDuration?: Maybe<Scalars["Int"]["output"]>;
  countPeriod?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  earn?: Maybe<Scalars["Int"]["output"]>;
  gamePointTypeId?: Maybe<Scalars["Int"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  isActive?: Maybe<Scalars["Boolean"]["output"]>;
  key?: Maybe<Scalars["String"]["output"]>;
  label?: Maybe<Scalars["String"]["output"]>;
  levelId?: Maybe<Scalars["Int"]["output"]>;
  maximumEarningsPerUser?: Maybe<Scalars["Int"]["output"]>;
  model?: Maybe<Scalars["String"]["output"]>;
  modelId?: Maybe<Scalars["Int"]["output"]>;
  text?: Maybe<Scalars["String"]["output"]>;
  totalCount?: Maybe<Scalars["Int"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type TriggerMinAggregate = {
  __typename?: "TriggerMinAggregate";
  badgeId?: Maybe<Scalars["Int"]["output"]>;
  countDuration?: Maybe<Scalars["Int"]["output"]>;
  countPeriod?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  earn?: Maybe<Scalars["Int"]["output"]>;
  gamePointTypeId?: Maybe<Scalars["Int"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  isActive?: Maybe<Scalars["Boolean"]["output"]>;
  key?: Maybe<Scalars["String"]["output"]>;
  label?: Maybe<Scalars["String"]["output"]>;
  levelId?: Maybe<Scalars["Int"]["output"]>;
  maximumEarningsPerUser?: Maybe<Scalars["Int"]["output"]>;
  model?: Maybe<Scalars["String"]["output"]>;
  modelId?: Maybe<Scalars["Int"]["output"]>;
  text?: Maybe<Scalars["String"]["output"]>;
  totalCount?: Maybe<Scalars["Int"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type TriggerOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type TriggerOrderByWithRelationInput = {
  badge?: InputMaybe<BadgeOrderByWithRelationInput>;
  badgeId?: InputMaybe<SortOrderInput>;
  countDuration?: InputMaybe<SortOrderInput>;
  countPeriod?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  earn?: InputMaybe<SortOrder>;
  gamePointType?: InputMaybe<GamePointTypeOrderByWithRelationInput>;
  gamePointTypeId?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  isActive?: InputMaybe<SortOrder>;
  key?: InputMaybe<SortOrder>;
  label?: InputMaybe<SortOrderInput>;
  level?: InputMaybe<LevelOrderByWithRelationInput>;
  levelId?: InputMaybe<SortOrderInput>;
  maximumEarningsPerUser?: InputMaybe<SortOrder>;
  model?: InputMaybe<SortOrderInput>;
  modelId?: InputMaybe<SortOrderInput>;
  studentGameLogs?: InputMaybe<StudentGameLogOrderByRelationAggregateInput>;
  text?: InputMaybe<SortOrder>;
  totalCount?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type TriggerRelationFilter = {
  is?: InputMaybe<TriggerWhereInput>;
  isNot?: InputMaybe<TriggerWhereInput>;
};

export enum TriggerScalarFieldEnum {
  BADGEID = "badgeId",
  COUNTDURATION = "countDuration",
  COUNTPERIOD = "countPeriod",
  CREATEDAT = "createdAt",
  EARN = "earn",
  GAMEPOINTTYPEID = "gamePointTypeId",
  ID = "id",
  ISACTIVE = "isActive",
  KEY = "key",
  LABEL = "label",
  LEVELID = "levelId",
  MAXIMUMEARNINGSPERUSER = "maximumEarningsPerUser",
  MODEL = "model",
  MODELID = "modelId",
  TEXT = "text",
  TOTALCOUNT = "totalCount",
  UPDATEDAT = "updatedAt"
}

export type TriggerScalarWhereInput = {
  AND?: InputMaybe<Array<TriggerScalarWhereInput>>;
  NOT?: InputMaybe<Array<TriggerScalarWhereInput>>;
  OR?: InputMaybe<Array<TriggerScalarWhereInput>>;
  badgeId?: InputMaybe<IntNullableFilter>;
  countDuration?: InputMaybe<IntNullableFilter>;
  countPeriod?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  earn?: InputMaybe<IntFilter>;
  gamePointTypeId?: InputMaybe<IntNullableFilter>;
  id?: InputMaybe<IntFilter>;
  isActive?: InputMaybe<BoolFilter>;
  key?: InputMaybe<StringFilter>;
  label?: InputMaybe<StringNullableFilter>;
  levelId?: InputMaybe<IntNullableFilter>;
  maximumEarningsPerUser?: InputMaybe<IntFilter>;
  model?: InputMaybe<StringNullableFilter>;
  modelId?: InputMaybe<IntNullableFilter>;
  text?: InputMaybe<StringFilter>;
  totalCount?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type TriggerSumAggregate = {
  __typename?: "TriggerSumAggregate";
  badgeId?: Maybe<Scalars["Int"]["output"]>;
  countDuration?: Maybe<Scalars["Int"]["output"]>;
  earn?: Maybe<Scalars["Int"]["output"]>;
  gamePointTypeId?: Maybe<Scalars["Int"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  levelId?: Maybe<Scalars["Int"]["output"]>;
  maximumEarningsPerUser?: Maybe<Scalars["Int"]["output"]>;
  modelId?: Maybe<Scalars["Int"]["output"]>;
  totalCount?: Maybe<Scalars["Int"]["output"]>;
};

export enum TriggerType {
  ACHIEVEMENT_AWARD = "ACHIEVEMENT_AWARD",
  ACHIEVEMENT_EARN = "ACHIEVEMENT_EARN",
  ACTIVITY_UNLOCK = "ACTIVITY_UNLOCK",
  BADGE_EARN = "BADGE_EARN",
  COINS_ADD = "COINS_ADD",
  COINS_EARNED = "COINS_EARNED",
  COINS_SPENT = "COINS_SPENT",
  COMMENT_APPROVE = "COMMENT_APPROVE",
  COMMENT_CREATE = "COMMENT_CREATE",
  COMMENT_REJECT = "COMMENT_REJECT",
  CONTENT_UNLOCK = "CONTENT_UNLOCK",
  DAILY_ACTIVITY = "DAILY_ACTIVITY",
  DAILY_CHALLENGE = "DAILY_CHALLENGE",
  LEVEL_UPDATE = "LEVEL_UPDATE",
  LOG_IN = "LOG_IN",
  LOG_OUT = "LOG_OUT",
  PRODUCT_PURCHASE = "PRODUCT_PURCHASE",
  QA_COMPLETE = "QA_COMPLETE",
  RANK_AWARD = "RANK_AWARD",
  RANK_EARN = "RANK_EARN",
  REGISTER = "REGISTER",
  SKILL_XPS_EARN = "SKILL_XPS_EARN",
  STAR_EARN = "STAR_EARN",
  STORY_COMPLETE = "STORY_COMPLETE",
  STORY_UNLOCK = "STORY_UNLOCK",
  UPDATE_PROFILE = "UPDATE_PROFILE",
  XPS_EARN = "XPS_EARN"
}

export type TriggerUpdateInput = {
  badge?: InputMaybe<BadgeUpdateOneWithoutTriggersNestedInput>;
  countDuration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  countPeriod?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  earn?: InputMaybe<IntFieldUpdateOperationsInput>;
  gamePointType?: InputMaybe<GamePointTypeUpdateOneWithoutTriggersNestedInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  key?: InputMaybe<StringFieldUpdateOperationsInput>;
  label?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  level?: InputMaybe<LevelUpdateOneWithoutTriggersNestedInput>;
  maximumEarningsPerUser?: InputMaybe<IntFieldUpdateOperationsInput>;
  model?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  modelId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  studentGameLogs?: InputMaybe<StudentGameLogUpdateManyWithoutTriggerNestedInput>;
  text?: InputMaybe<StringFieldUpdateOperationsInput>;
  totalCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TriggerUpdateManyMutationInput = {
  countDuration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  countPeriod?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  earn?: InputMaybe<IntFieldUpdateOperationsInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  key?: InputMaybe<StringFieldUpdateOperationsInput>;
  label?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  maximumEarningsPerUser?: InputMaybe<IntFieldUpdateOperationsInput>;
  model?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  modelId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  text?: InputMaybe<StringFieldUpdateOperationsInput>;
  totalCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TriggerUpdateManyWithWhereWithoutBadgeInput = {
  data: TriggerUpdateManyMutationInput;
  where: TriggerScalarWhereInput;
};

export type TriggerUpdateManyWithWhereWithoutGamePointTypeInput = {
  data: TriggerUpdateManyMutationInput;
  where: TriggerScalarWhereInput;
};

export type TriggerUpdateManyWithWhereWithoutLevelInput = {
  data: TriggerUpdateManyMutationInput;
  where: TriggerScalarWhereInput;
};

export type TriggerUpdateManyWithoutBadgeNestedInput = {
  connect?: InputMaybe<Array<TriggerWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TriggerCreateOrConnectWithoutBadgeInput>>;
  create?: InputMaybe<Array<TriggerCreateWithoutBadgeInput>>;
  createMany?: InputMaybe<TriggerCreateManyBadgeInputEnvelope>;
  delete?: InputMaybe<Array<TriggerWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TriggerScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TriggerWhereUniqueInput>>;
  set?: InputMaybe<Array<TriggerWhereUniqueInput>>;
  update?: InputMaybe<Array<TriggerUpdateWithWhereUniqueWithoutBadgeInput>>;
  updateMany?: InputMaybe<Array<TriggerUpdateManyWithWhereWithoutBadgeInput>>;
  upsert?: InputMaybe<Array<TriggerUpsertWithWhereUniqueWithoutBadgeInput>>;
};

export type TriggerUpdateManyWithoutGamePointTypeNestedInput = {
  connect?: InputMaybe<Array<TriggerWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TriggerCreateOrConnectWithoutGamePointTypeInput>>;
  create?: InputMaybe<Array<TriggerCreateWithoutGamePointTypeInput>>;
  createMany?: InputMaybe<TriggerCreateManyGamePointTypeInputEnvelope>;
  delete?: InputMaybe<Array<TriggerWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TriggerScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TriggerWhereUniqueInput>>;
  set?: InputMaybe<Array<TriggerWhereUniqueInput>>;
  update?: InputMaybe<Array<TriggerUpdateWithWhereUniqueWithoutGamePointTypeInput>>;
  updateMany?: InputMaybe<Array<TriggerUpdateManyWithWhereWithoutGamePointTypeInput>>;
  upsert?: InputMaybe<Array<TriggerUpsertWithWhereUniqueWithoutGamePointTypeInput>>;
};

export type TriggerUpdateManyWithoutLevelNestedInput = {
  connect?: InputMaybe<Array<TriggerWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TriggerCreateOrConnectWithoutLevelInput>>;
  create?: InputMaybe<Array<TriggerCreateWithoutLevelInput>>;
  createMany?: InputMaybe<TriggerCreateManyLevelInputEnvelope>;
  delete?: InputMaybe<Array<TriggerWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TriggerScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TriggerWhereUniqueInput>>;
  set?: InputMaybe<Array<TriggerWhereUniqueInput>>;
  update?: InputMaybe<Array<TriggerUpdateWithWhereUniqueWithoutLevelInput>>;
  updateMany?: InputMaybe<Array<TriggerUpdateManyWithWhereWithoutLevelInput>>;
  upsert?: InputMaybe<Array<TriggerUpsertWithWhereUniqueWithoutLevelInput>>;
};

export type TriggerUpdateOneWithoutStudentGameLogsNestedInput = {
  connect?: InputMaybe<TriggerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TriggerCreateOrConnectWithoutStudentGameLogsInput>;
  create?: InputMaybe<TriggerCreateWithoutStudentGameLogsInput>;
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  update?: InputMaybe<TriggerUpdateWithoutStudentGameLogsInput>;
  upsert?: InputMaybe<TriggerUpsertWithoutStudentGameLogsInput>;
};

export type TriggerUpdateWithWhereUniqueWithoutBadgeInput = {
  data: TriggerUpdateWithoutBadgeInput;
  where: TriggerWhereUniqueInput;
};

export type TriggerUpdateWithWhereUniqueWithoutGamePointTypeInput = {
  data: TriggerUpdateWithoutGamePointTypeInput;
  where: TriggerWhereUniqueInput;
};

export type TriggerUpdateWithWhereUniqueWithoutLevelInput = {
  data: TriggerUpdateWithoutLevelInput;
  where: TriggerWhereUniqueInput;
};

export type TriggerUpdateWithoutBadgeInput = {
  countDuration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  countPeriod?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  earn?: InputMaybe<IntFieldUpdateOperationsInput>;
  gamePointType?: InputMaybe<GamePointTypeUpdateOneWithoutTriggersNestedInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  key?: InputMaybe<StringFieldUpdateOperationsInput>;
  label?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  level?: InputMaybe<LevelUpdateOneWithoutTriggersNestedInput>;
  maximumEarningsPerUser?: InputMaybe<IntFieldUpdateOperationsInput>;
  model?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  modelId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  studentGameLogs?: InputMaybe<StudentGameLogUpdateManyWithoutTriggerNestedInput>;
  text?: InputMaybe<StringFieldUpdateOperationsInput>;
  totalCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TriggerUpdateWithoutGamePointTypeInput = {
  badge?: InputMaybe<BadgeUpdateOneWithoutTriggersNestedInput>;
  countDuration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  countPeriod?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  earn?: InputMaybe<IntFieldUpdateOperationsInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  key?: InputMaybe<StringFieldUpdateOperationsInput>;
  label?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  level?: InputMaybe<LevelUpdateOneWithoutTriggersNestedInput>;
  maximumEarningsPerUser?: InputMaybe<IntFieldUpdateOperationsInput>;
  model?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  modelId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  studentGameLogs?: InputMaybe<StudentGameLogUpdateManyWithoutTriggerNestedInput>;
  text?: InputMaybe<StringFieldUpdateOperationsInput>;
  totalCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TriggerUpdateWithoutLevelInput = {
  badge?: InputMaybe<BadgeUpdateOneWithoutTriggersNestedInput>;
  countDuration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  countPeriod?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  earn?: InputMaybe<IntFieldUpdateOperationsInput>;
  gamePointType?: InputMaybe<GamePointTypeUpdateOneWithoutTriggersNestedInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  key?: InputMaybe<StringFieldUpdateOperationsInput>;
  label?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  maximumEarningsPerUser?: InputMaybe<IntFieldUpdateOperationsInput>;
  model?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  modelId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  studentGameLogs?: InputMaybe<StudentGameLogUpdateManyWithoutTriggerNestedInput>;
  text?: InputMaybe<StringFieldUpdateOperationsInput>;
  totalCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TriggerUpdateWithoutStudentGameLogsInput = {
  badge?: InputMaybe<BadgeUpdateOneWithoutTriggersNestedInput>;
  countDuration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  countPeriod?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  earn?: InputMaybe<IntFieldUpdateOperationsInput>;
  gamePointType?: InputMaybe<GamePointTypeUpdateOneWithoutTriggersNestedInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  key?: InputMaybe<StringFieldUpdateOperationsInput>;
  label?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  level?: InputMaybe<LevelUpdateOneWithoutTriggersNestedInput>;
  maximumEarningsPerUser?: InputMaybe<IntFieldUpdateOperationsInput>;
  model?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  modelId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  text?: InputMaybe<StringFieldUpdateOperationsInput>;
  totalCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TriggerUpsertWithWhereUniqueWithoutBadgeInput = {
  create: TriggerCreateWithoutBadgeInput;
  update: TriggerUpdateWithoutBadgeInput;
  where: TriggerWhereUniqueInput;
};

export type TriggerUpsertWithWhereUniqueWithoutGamePointTypeInput = {
  create: TriggerCreateWithoutGamePointTypeInput;
  update: TriggerUpdateWithoutGamePointTypeInput;
  where: TriggerWhereUniqueInput;
};

export type TriggerUpsertWithWhereUniqueWithoutLevelInput = {
  create: TriggerCreateWithoutLevelInput;
  update: TriggerUpdateWithoutLevelInput;
  where: TriggerWhereUniqueInput;
};

export type TriggerUpsertWithoutStudentGameLogsInput = {
  create: TriggerCreateWithoutStudentGameLogsInput;
  update: TriggerUpdateWithoutStudentGameLogsInput;
};

export type TriggerWhereInput = {
  AND?: InputMaybe<Array<TriggerWhereInput>>;
  NOT?: InputMaybe<Array<TriggerWhereInput>>;
  OR?: InputMaybe<Array<TriggerWhereInput>>;
  badge?: InputMaybe<BadgeRelationFilter>;
  badgeId?: InputMaybe<IntNullableFilter>;
  countDuration?: InputMaybe<IntNullableFilter>;
  countPeriod?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  earn?: InputMaybe<IntFilter>;
  gamePointType?: InputMaybe<GamePointTypeRelationFilter>;
  gamePointTypeId?: InputMaybe<IntNullableFilter>;
  id?: InputMaybe<IntFilter>;
  isActive?: InputMaybe<BoolFilter>;
  key?: InputMaybe<StringFilter>;
  label?: InputMaybe<StringNullableFilter>;
  level?: InputMaybe<LevelRelationFilter>;
  levelId?: InputMaybe<IntNullableFilter>;
  maximumEarningsPerUser?: InputMaybe<IntFilter>;
  model?: InputMaybe<StringNullableFilter>;
  modelId?: InputMaybe<IntNullableFilter>;
  studentGameLogs?: InputMaybe<StudentGameLogListRelationFilter>;
  text?: InputMaybe<StringFilter>;
  totalCount?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type TriggerWhereUniqueInput = {
  id?: InputMaybe<Scalars["Int"]["input"]>;
};

export type UpdateProfileInput = {
  address1?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  displayName?: InputMaybe<StringFieldUpdateOperationsInput>;
  dob?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: InputMaybe<StringFieldUpdateOperationsInput>;
  isHomeschooler?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lastName?: InputMaybe<StringFieldUpdateOperationsInput>;
  notificationDevice?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  notificationToken?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  profileImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  schoolText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  standard?: InputMaybe<StandardWhereUniqueInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UpdateStudentMetaInput = {
  meta: Scalars["JSON"]["input"];
};

export type UpdateStudentMetaResponse = {
  __typename?: "UpdateStudentMetaResponse";
  message: Scalars["String"]["output"];
  success: Scalars["Boolean"]["output"];
};

export type User = {
  __typename?: "User";
  _count?: Maybe<UserCount>;
  activities: Array<Activity>;
  comments: Array<Comment>;
  createdAt: Scalars["DateTime"]["output"];
  email?: Maybe<Scalars["String"]["output"]>;
  firstName: Scalars["String"]["output"];
  id: Scalars["Int"]["output"];
  isActive: Scalars["Boolean"]["output"];
  lastLoginAt?: Maybe<Scalars["DateTime"]["output"]>;
  lastName: Scalars["String"]["output"];
  phoneNumber: Scalars["String"]["output"];
  role: Role;
  schools: Array<School>;
  standards: Array<Standard>;
  updatedAt: Scalars["DateTime"]["output"];
  userLogs: Array<UserLog>;
  workshops: Array<Workshop>;
};


export type UserActivitiesArgs = {
  cursor?: InputMaybe<ActivityWhereUniqueInput>;
  distinct?: InputMaybe<Array<ActivityScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ActivityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ActivityWhereInput>;
};


export type UserCommentsArgs = {
  cursor?: InputMaybe<CommentWhereUniqueInput>;
  distinct?: InputMaybe<Array<CommentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CommentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<CommentWhereInput>;
};


export type UserSchoolsArgs = {
  cursor?: InputMaybe<SchoolWhereUniqueInput>;
  distinct?: InputMaybe<Array<SchoolScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<SchoolOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<SchoolWhereInput>;
};


export type UserStandardsArgs = {
  cursor?: InputMaybe<StandardWhereUniqueInput>;
  distinct?: InputMaybe<Array<StandardScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StandardOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<StandardWhereInput>;
};


export type UserUserLogsArgs = {
  cursor?: InputMaybe<UserLogWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserLogScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserLogOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<UserLogWhereInput>;
};


export type UserWorkshopsArgs = {
  cursor?: InputMaybe<WorkshopWhereUniqueInput>;
  distinct?: InputMaybe<Array<WorkshopScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<WorkshopOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<WorkshopWhereInput>;
};

export type UserAvgAggregate = {
  __typename?: "UserAvgAggregate";
  id?: Maybe<Scalars["Float"]["output"]>;
};

export type UserCount = {
  __typename?: "UserCount";
  activities: Scalars["Int"]["output"];
  comments: Scalars["Int"]["output"];
  schools: Scalars["Int"]["output"];
  standards: Scalars["Int"]["output"];
  userLogs: Scalars["Int"]["output"];
  workshops: Scalars["Int"]["output"];
};


export type UserCountActivitiesArgs = {
  where?: InputMaybe<ActivityWhereInput>;
};


export type UserCountCommentsArgs = {
  where?: InputMaybe<CommentWhereInput>;
};


export type UserCountSchoolsArgs = {
  where?: InputMaybe<SchoolWhereInput>;
};


export type UserCountStandardsArgs = {
  where?: InputMaybe<StandardWhereInput>;
};


export type UserCountUserLogsArgs = {
  where?: InputMaybe<UserLogWhereInput>;
};


export type UserCountWorkshopsArgs = {
  where?: InputMaybe<WorkshopWhereInput>;
};

export type UserCountAggregate = {
  __typename?: "UserCountAggregate";
  _all: Scalars["Int"]["output"];
  createdAt: Scalars["Int"]["output"];
  email: Scalars["Int"]["output"];
  firstName: Scalars["Int"]["output"];
  id: Scalars["Int"]["output"];
  isActive: Scalars["Int"]["output"];
  lastLoginAt: Scalars["Int"]["output"];
  lastName: Scalars["Int"]["output"];
  phoneNumber: Scalars["Int"]["output"];
  role: Scalars["Int"]["output"];
  updatedAt: Scalars["Int"]["output"];
};

export type UserCreateInput = {
  activities?: InputMaybe<ActivityCreateNestedManyWithoutApprovedByInput>;
  comments?: InputMaybe<CommentCreateNestedManyWithoutApprovedByInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  firstName: Scalars["String"]["input"];
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastLoginAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastName: Scalars["String"]["input"];
  phoneNumber: Scalars["String"]["input"];
  role?: InputMaybe<Role>;
  schools?: InputMaybe<SchoolCreateNestedManyWithoutUsersInput>;
  standards?: InputMaybe<StandardCreateNestedManyWithoutUsersInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  userLogs?: InputMaybe<UserLogCreateNestedManyWithoutUserInput>;
  workshops?: InputMaybe<WorkshopCreateNestedManyWithoutTrainerInput>;
};

export type UserCreateNestedManyWithoutSchoolsInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserCreateOrConnectWithoutSchoolsInput>>;
  create?: InputMaybe<Array<UserCreateWithoutSchoolsInput>>;
};

export type UserCreateNestedManyWithoutStandardsInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserCreateOrConnectWithoutStandardsInput>>;
  create?: InputMaybe<Array<UserCreateWithoutStandardsInput>>;
};

export type UserCreateNestedOneWithoutActivitiesInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutActivitiesInput>;
  create?: InputMaybe<UserCreateWithoutActivitiesInput>;
};

export type UserCreateNestedOneWithoutCommentsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutCommentsInput>;
  create?: InputMaybe<UserCreateWithoutCommentsInput>;
};

export type UserCreateNestedOneWithoutUserLogsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutUserLogsInput>;
  create?: InputMaybe<UserCreateWithoutUserLogsInput>;
};

export type UserCreateNestedOneWithoutWorkshopsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutWorkshopsInput>;
  create?: InputMaybe<UserCreateWithoutWorkshopsInput>;
};

export type UserCreateOrConnectWithoutActivitiesInput = {
  create: UserCreateWithoutActivitiesInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutCommentsInput = {
  create: UserCreateWithoutCommentsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutSchoolsInput = {
  create: UserCreateWithoutSchoolsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutStandardsInput = {
  create: UserCreateWithoutStandardsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutUserLogsInput = {
  create: UserCreateWithoutUserLogsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutWorkshopsInput = {
  create: UserCreateWithoutWorkshopsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateWithoutActivitiesInput = {
  comments?: InputMaybe<CommentCreateNestedManyWithoutApprovedByInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  firstName: Scalars["String"]["input"];
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastLoginAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastName: Scalars["String"]["input"];
  phoneNumber: Scalars["String"]["input"];
  role?: InputMaybe<Role>;
  schools?: InputMaybe<SchoolCreateNestedManyWithoutUsersInput>;
  standards?: InputMaybe<StandardCreateNestedManyWithoutUsersInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  userLogs?: InputMaybe<UserLogCreateNestedManyWithoutUserInput>;
  workshops?: InputMaybe<WorkshopCreateNestedManyWithoutTrainerInput>;
};

export type UserCreateWithoutCommentsInput = {
  activities?: InputMaybe<ActivityCreateNestedManyWithoutApprovedByInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  firstName: Scalars["String"]["input"];
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastLoginAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastName: Scalars["String"]["input"];
  phoneNumber: Scalars["String"]["input"];
  role?: InputMaybe<Role>;
  schools?: InputMaybe<SchoolCreateNestedManyWithoutUsersInput>;
  standards?: InputMaybe<StandardCreateNestedManyWithoutUsersInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  userLogs?: InputMaybe<UserLogCreateNestedManyWithoutUserInput>;
  workshops?: InputMaybe<WorkshopCreateNestedManyWithoutTrainerInput>;
};

export type UserCreateWithoutSchoolsInput = {
  activities?: InputMaybe<ActivityCreateNestedManyWithoutApprovedByInput>;
  comments?: InputMaybe<CommentCreateNestedManyWithoutApprovedByInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  firstName: Scalars["String"]["input"];
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastLoginAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastName: Scalars["String"]["input"];
  phoneNumber: Scalars["String"]["input"];
  role?: InputMaybe<Role>;
  standards?: InputMaybe<StandardCreateNestedManyWithoutUsersInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  userLogs?: InputMaybe<UserLogCreateNestedManyWithoutUserInput>;
  workshops?: InputMaybe<WorkshopCreateNestedManyWithoutTrainerInput>;
};

export type UserCreateWithoutStandardsInput = {
  activities?: InputMaybe<ActivityCreateNestedManyWithoutApprovedByInput>;
  comments?: InputMaybe<CommentCreateNestedManyWithoutApprovedByInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  firstName: Scalars["String"]["input"];
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastLoginAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastName: Scalars["String"]["input"];
  phoneNumber: Scalars["String"]["input"];
  role?: InputMaybe<Role>;
  schools?: InputMaybe<SchoolCreateNestedManyWithoutUsersInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  userLogs?: InputMaybe<UserLogCreateNestedManyWithoutUserInput>;
  workshops?: InputMaybe<WorkshopCreateNestedManyWithoutTrainerInput>;
};

export type UserCreateWithoutUserLogsInput = {
  activities?: InputMaybe<ActivityCreateNestedManyWithoutApprovedByInput>;
  comments?: InputMaybe<CommentCreateNestedManyWithoutApprovedByInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  firstName: Scalars["String"]["input"];
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastLoginAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastName: Scalars["String"]["input"];
  phoneNumber: Scalars["String"]["input"];
  role?: InputMaybe<Role>;
  schools?: InputMaybe<SchoolCreateNestedManyWithoutUsersInput>;
  standards?: InputMaybe<StandardCreateNestedManyWithoutUsersInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  workshops?: InputMaybe<WorkshopCreateNestedManyWithoutTrainerInput>;
};

export type UserCreateWithoutWorkshopsInput = {
  activities?: InputMaybe<ActivityCreateNestedManyWithoutApprovedByInput>;
  comments?: InputMaybe<CommentCreateNestedManyWithoutApprovedByInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  firstName: Scalars["String"]["input"];
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastLoginAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastName: Scalars["String"]["input"];
  phoneNumber: Scalars["String"]["input"];
  role?: InputMaybe<Role>;
  schools?: InputMaybe<SchoolCreateNestedManyWithoutUsersInput>;
  standards?: InputMaybe<StandardCreateNestedManyWithoutUsersInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  userLogs?: InputMaybe<UserLogCreateNestedManyWithoutUserInput>;
};

export type UserListRelationFilter = {
  every?: InputMaybe<UserWhereInput>;
  none?: InputMaybe<UserWhereInput>;
  some?: InputMaybe<UserWhereInput>;
};

export type UserLog = {
  __typename?: "UserLog";
  createdAt: Scalars["DateTime"]["output"];
  data?: Maybe<Scalars["JSON"]["output"]>;
  id: Scalars["Int"]["output"];
  module: Scalars["String"]["output"];
  moduleId: Scalars["Int"]["output"];
  status: Scalars["String"]["output"];
  user: User;
  userId: Scalars["Int"]["output"];
};

export type UserLogAvgAggregate = {
  __typename?: "UserLogAvgAggregate";
  id?: Maybe<Scalars["Float"]["output"]>;
  moduleId?: Maybe<Scalars["Float"]["output"]>;
  userId?: Maybe<Scalars["Float"]["output"]>;
};

export type UserLogCountAggregate = {
  __typename?: "UserLogCountAggregate";
  _all: Scalars["Int"]["output"];
  createdAt: Scalars["Int"]["output"];
  data: Scalars["Int"]["output"];
  id: Scalars["Int"]["output"];
  module: Scalars["Int"]["output"];
  moduleId: Scalars["Int"]["output"];
  status: Scalars["Int"]["output"];
  userId: Scalars["Int"]["output"];
};

export type UserLogCreateInput = {
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  data?: InputMaybe<Scalars["JSON"]["input"]>;
  module: Scalars["String"]["input"];
  moduleId: Scalars["Int"]["input"];
  status: Scalars["String"]["input"];
  user: UserCreateNestedOneWithoutUserLogsInput;
};

export type UserLogCreateManyUserInput = {
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  data?: InputMaybe<Scalars["JSON"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  module: Scalars["String"]["input"];
  moduleId: Scalars["Int"]["input"];
  status: Scalars["String"]["input"];
};

export type UserLogCreateManyUserInputEnvelope = {
  data: Array<UserLogCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type UserLogCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<UserLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserLogCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<UserLogCreateWithoutUserInput>>;
  createMany?: InputMaybe<UserLogCreateManyUserInputEnvelope>;
};

export type UserLogCreateOrConnectWithoutUserInput = {
  create: UserLogCreateWithoutUserInput;
  where: UserLogWhereUniqueInput;
};

export type UserLogCreateWithoutUserInput = {
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  data?: InputMaybe<Scalars["JSON"]["input"]>;
  module: Scalars["String"]["input"];
  moduleId: Scalars["Int"]["input"];
  status: Scalars["String"]["input"];
};

export type UserLogListRelationFilter = {
  every?: InputMaybe<UserLogWhereInput>;
  none?: InputMaybe<UserLogWhereInput>;
  some?: InputMaybe<UserLogWhereInput>;
};

export type UserLogMaxAggregate = {
  __typename?: "UserLogMaxAggregate";
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  module?: Maybe<Scalars["String"]["output"]>;
  moduleId?: Maybe<Scalars["Int"]["output"]>;
  status?: Maybe<Scalars["String"]["output"]>;
  userId?: Maybe<Scalars["Int"]["output"]>;
};

export type UserLogMinAggregate = {
  __typename?: "UserLogMinAggregate";
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  module?: Maybe<Scalars["String"]["output"]>;
  moduleId?: Maybe<Scalars["Int"]["output"]>;
  status?: Maybe<Scalars["String"]["output"]>;
  userId?: Maybe<Scalars["Int"]["output"]>;
};

export type UserLogOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type UserLogOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  data?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  module?: InputMaybe<SortOrder>;
  moduleId?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrder>;
};

export enum UserLogScalarFieldEnum {
  CREATEDAT = "createdAt",
  DATA = "data",
  ID = "id",
  MODULE = "module",
  MODULEID = "moduleId",
  STATUS = "status",
  USERID = "userId"
}

export type UserLogScalarWhereInput = {
  AND?: InputMaybe<Array<UserLogScalarWhereInput>>;
  NOT?: InputMaybe<Array<UserLogScalarWhereInput>>;
  OR?: InputMaybe<Array<UserLogScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  data?: InputMaybe<JsonNullableFilter>;
  id?: InputMaybe<IntFilter>;
  module?: InputMaybe<StringFilter>;
  moduleId?: InputMaybe<IntFilter>;
  status?: InputMaybe<StringFilter>;
  userId?: InputMaybe<IntFilter>;
};

export type UserLogSumAggregate = {
  __typename?: "UserLogSumAggregate";
  id?: Maybe<Scalars["Int"]["output"]>;
  moduleId?: Maybe<Scalars["Int"]["output"]>;
  userId?: Maybe<Scalars["Int"]["output"]>;
};

export type UserLogUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  data?: InputMaybe<Scalars["JSON"]["input"]>;
  module?: InputMaybe<StringFieldUpdateOperationsInput>;
  moduleId?: InputMaybe<IntFieldUpdateOperationsInput>;
  status?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type UserLogUpdateManyWithWhereWithoutUserInput = {
  data: UserLogUpdateManyMutationInput;
  where: UserLogScalarWhereInput;
};

export type UserLogUpdateManyWithoutUserNestedInput = {
  connect?: InputMaybe<Array<UserLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserLogCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<UserLogCreateWithoutUserInput>>;
  createMany?: InputMaybe<UserLogCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<UserLogWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserLogScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserLogWhereUniqueInput>>;
  set?: InputMaybe<Array<UserLogWhereUniqueInput>>;
  update?: InputMaybe<Array<UserLogUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<UserLogUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<UserLogUpsertWithWhereUniqueWithoutUserInput>>;
};

export type UserLogUpdateWithWhereUniqueWithoutUserInput = {
  data: UserLogUpdateWithoutUserInput;
  where: UserLogWhereUniqueInput;
};

export type UserLogUpdateWithoutUserInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  data?: InputMaybe<Scalars["JSON"]["input"]>;
  module?: InputMaybe<StringFieldUpdateOperationsInput>;
  moduleId?: InputMaybe<IntFieldUpdateOperationsInput>;
  status?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type UserLogUpsertWithWhereUniqueWithoutUserInput = {
  create: UserLogCreateWithoutUserInput;
  update: UserLogUpdateWithoutUserInput;
  where: UserLogWhereUniqueInput;
};

export type UserLogWhereInput = {
  AND?: InputMaybe<Array<UserLogWhereInput>>;
  NOT?: InputMaybe<Array<UserLogWhereInput>>;
  OR?: InputMaybe<Array<UserLogWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  data?: InputMaybe<JsonNullableFilter>;
  id?: InputMaybe<IntFilter>;
  module?: InputMaybe<StringFilter>;
  moduleId?: InputMaybe<IntFilter>;
  status?: InputMaybe<StringFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<IntFilter>;
};

export type UserLogWhereUniqueInput = {
  id?: InputMaybe<Scalars["Int"]["input"]>;
};

export type UserMaxAggregate = {
  __typename?: "UserMaxAggregate";
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  email?: Maybe<Scalars["String"]["output"]>;
  firstName?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  isActive?: Maybe<Scalars["Boolean"]["output"]>;
  lastLoginAt?: Maybe<Scalars["DateTime"]["output"]>;
  lastName?: Maybe<Scalars["String"]["output"]>;
  phoneNumber?: Maybe<Scalars["String"]["output"]>;
  role?: Maybe<Role>;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type UserMinAggregate = {
  __typename?: "UserMinAggregate";
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  email?: Maybe<Scalars["String"]["output"]>;
  firstName?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  isActive?: Maybe<Scalars["Boolean"]["output"]>;
  lastLoginAt?: Maybe<Scalars["DateTime"]["output"]>;
  lastName?: Maybe<Scalars["String"]["output"]>;
  phoneNumber?: Maybe<Scalars["String"]["output"]>;
  role?: Maybe<Role>;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type UserOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type UserOrderByWithRelationInput = {
  activities?: InputMaybe<ActivityOrderByRelationAggregateInput>;
  comments?: InputMaybe<CommentOrderByRelationAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrderInput>;
  firstName?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isActive?: InputMaybe<SortOrder>;
  lastLoginAt?: InputMaybe<SortOrderInput>;
  lastName?: InputMaybe<SortOrder>;
  phoneNumber?: InputMaybe<SortOrder>;
  role?: InputMaybe<SortOrder>;
  schools?: InputMaybe<SchoolOrderByRelationAggregateInput>;
  standards?: InputMaybe<StandardOrderByRelationAggregateInput>;
  updatedAt?: InputMaybe<SortOrder>;
  userLogs?: InputMaybe<UserLogOrderByRelationAggregateInput>;
  workshops?: InputMaybe<WorkshopOrderByRelationAggregateInput>;
};

export type UserRelationFilter = {
  is?: InputMaybe<UserWhereInput>;
  isNot?: InputMaybe<UserWhereInput>;
};

export enum UserScalarFieldEnum {
  CREATEDAT = "createdAt",
  EMAIL = "email",
  FIRSTNAME = "firstName",
  ID = "id",
  ISACTIVE = "isActive",
  LASTLOGINAT = "lastLoginAt",
  LASTNAME = "lastName",
  PHONENUMBER = "phoneNumber",
  ROLE = "role",
  UPDATEDAT = "updatedAt"
}

export type UserScalarWhereInput = {
  AND?: InputMaybe<Array<UserScalarWhereInput>>;
  NOT?: InputMaybe<Array<UserScalarWhereInput>>;
  OR?: InputMaybe<Array<UserScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  email?: InputMaybe<StringNullableFilter>;
  firstName?: InputMaybe<StringFilter>;
  id?: InputMaybe<IntFilter>;
  isActive?: InputMaybe<BoolFilter>;
  lastLoginAt?: InputMaybe<DateTimeNullableFilter>;
  lastName?: InputMaybe<StringFilter>;
  phoneNumber?: InputMaybe<StringFilter>;
  role?: InputMaybe<EnumRoleFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type UserSumAggregate = {
  __typename?: "UserSumAggregate";
  id?: Maybe<Scalars["Int"]["output"]>;
};

export type UserUpdateInput = {
  activities?: InputMaybe<ActivityUpdateManyWithoutApprovedByNestedInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutApprovedByNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: InputMaybe<StringFieldUpdateOperationsInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lastLoginAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lastName?: InputMaybe<StringFieldUpdateOperationsInput>;
  phoneNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  schools?: InputMaybe<SchoolUpdateManyWithoutUsersNestedInput>;
  standards?: InputMaybe<StandardUpdateManyWithoutUsersNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userLogs?: InputMaybe<UserLogUpdateManyWithoutUserNestedInput>;
  workshops?: InputMaybe<WorkshopUpdateManyWithoutTrainerNestedInput>;
};

export type UserUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: InputMaybe<StringFieldUpdateOperationsInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lastLoginAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lastName?: InputMaybe<StringFieldUpdateOperationsInput>;
  phoneNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UserUpdateManyWithWhereWithoutSchoolsInput = {
  data: UserUpdateManyMutationInput;
  where: UserScalarWhereInput;
};

export type UserUpdateManyWithWhereWithoutStandardsInput = {
  data: UserUpdateManyMutationInput;
  where: UserScalarWhereInput;
};

export type UserUpdateManyWithoutSchoolsNestedInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserCreateOrConnectWithoutSchoolsInput>>;
  create?: InputMaybe<Array<UserCreateWithoutSchoolsInput>>;
  delete?: InputMaybe<Array<UserWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserWhereUniqueInput>>;
  set?: InputMaybe<Array<UserWhereUniqueInput>>;
  update?: InputMaybe<Array<UserUpdateWithWhereUniqueWithoutSchoolsInput>>;
  updateMany?: InputMaybe<Array<UserUpdateManyWithWhereWithoutSchoolsInput>>;
  upsert?: InputMaybe<Array<UserUpsertWithWhereUniqueWithoutSchoolsInput>>;
};

export type UserUpdateManyWithoutStandardsNestedInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserCreateOrConnectWithoutStandardsInput>>;
  create?: InputMaybe<Array<UserCreateWithoutStandardsInput>>;
  delete?: InputMaybe<Array<UserWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserWhereUniqueInput>>;
  set?: InputMaybe<Array<UserWhereUniqueInput>>;
  update?: InputMaybe<Array<UserUpdateWithWhereUniqueWithoutStandardsInput>>;
  updateMany?: InputMaybe<Array<UserUpdateManyWithWhereWithoutStandardsInput>>;
  upsert?: InputMaybe<Array<UserUpsertWithWhereUniqueWithoutStandardsInput>>;
};

export type UserUpdateOneWithoutActivitiesNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutActivitiesInput>;
  create?: InputMaybe<UserCreateWithoutActivitiesInput>;
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  update?: InputMaybe<UserUpdateWithoutActivitiesInput>;
  upsert?: InputMaybe<UserUpsertWithoutActivitiesInput>;
};

export type UserUpdateOneWithoutCommentsNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutCommentsInput>;
  create?: InputMaybe<UserCreateWithoutCommentsInput>;
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  update?: InputMaybe<UserUpdateWithoutCommentsInput>;
  upsert?: InputMaybe<UserUpsertWithoutCommentsInput>;
};

export type UserUpdateOneWithoutWorkshopsNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutWorkshopsInput>;
  create?: InputMaybe<UserCreateWithoutWorkshopsInput>;
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  update?: InputMaybe<UserUpdateWithoutWorkshopsInput>;
  upsert?: InputMaybe<UserUpsertWithoutWorkshopsInput>;
};

export type UserUpdateWithWhereUniqueWithoutSchoolsInput = {
  data: UserUpdateWithoutSchoolsInput;
  where: UserWhereUniqueInput;
};

export type UserUpdateWithWhereUniqueWithoutStandardsInput = {
  data: UserUpdateWithoutStandardsInput;
  where: UserWhereUniqueInput;
};

export type UserUpdateWithoutActivitiesInput = {
  comments?: InputMaybe<CommentUpdateManyWithoutApprovedByNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: InputMaybe<StringFieldUpdateOperationsInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lastLoginAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lastName?: InputMaybe<StringFieldUpdateOperationsInput>;
  phoneNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  schools?: InputMaybe<SchoolUpdateManyWithoutUsersNestedInput>;
  standards?: InputMaybe<StandardUpdateManyWithoutUsersNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userLogs?: InputMaybe<UserLogUpdateManyWithoutUserNestedInput>;
  workshops?: InputMaybe<WorkshopUpdateManyWithoutTrainerNestedInput>;
};

export type UserUpdateWithoutCommentsInput = {
  activities?: InputMaybe<ActivityUpdateManyWithoutApprovedByNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: InputMaybe<StringFieldUpdateOperationsInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lastLoginAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lastName?: InputMaybe<StringFieldUpdateOperationsInput>;
  phoneNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  schools?: InputMaybe<SchoolUpdateManyWithoutUsersNestedInput>;
  standards?: InputMaybe<StandardUpdateManyWithoutUsersNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userLogs?: InputMaybe<UserLogUpdateManyWithoutUserNestedInput>;
  workshops?: InputMaybe<WorkshopUpdateManyWithoutTrainerNestedInput>;
};

export type UserUpdateWithoutSchoolsInput = {
  activities?: InputMaybe<ActivityUpdateManyWithoutApprovedByNestedInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutApprovedByNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: InputMaybe<StringFieldUpdateOperationsInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lastLoginAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lastName?: InputMaybe<StringFieldUpdateOperationsInput>;
  phoneNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  standards?: InputMaybe<StandardUpdateManyWithoutUsersNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userLogs?: InputMaybe<UserLogUpdateManyWithoutUserNestedInput>;
  workshops?: InputMaybe<WorkshopUpdateManyWithoutTrainerNestedInput>;
};

export type UserUpdateWithoutStandardsInput = {
  activities?: InputMaybe<ActivityUpdateManyWithoutApprovedByNestedInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutApprovedByNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: InputMaybe<StringFieldUpdateOperationsInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lastLoginAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lastName?: InputMaybe<StringFieldUpdateOperationsInput>;
  phoneNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  schools?: InputMaybe<SchoolUpdateManyWithoutUsersNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userLogs?: InputMaybe<UserLogUpdateManyWithoutUserNestedInput>;
  workshops?: InputMaybe<WorkshopUpdateManyWithoutTrainerNestedInput>;
};

export type UserUpdateWithoutWorkshopsInput = {
  activities?: InputMaybe<ActivityUpdateManyWithoutApprovedByNestedInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutApprovedByNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: InputMaybe<StringFieldUpdateOperationsInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lastLoginAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lastName?: InputMaybe<StringFieldUpdateOperationsInput>;
  phoneNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  schools?: InputMaybe<SchoolUpdateManyWithoutUsersNestedInput>;
  standards?: InputMaybe<StandardUpdateManyWithoutUsersNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userLogs?: InputMaybe<UserLogUpdateManyWithoutUserNestedInput>;
};

export type UserUpsertWithWhereUniqueWithoutSchoolsInput = {
  create: UserCreateWithoutSchoolsInput;
  update: UserUpdateWithoutSchoolsInput;
  where: UserWhereUniqueInput;
};

export type UserUpsertWithWhereUniqueWithoutStandardsInput = {
  create: UserCreateWithoutStandardsInput;
  update: UserUpdateWithoutStandardsInput;
  where: UserWhereUniqueInput;
};

export type UserUpsertWithoutActivitiesInput = {
  create: UserCreateWithoutActivitiesInput;
  update: UserUpdateWithoutActivitiesInput;
};

export type UserUpsertWithoutCommentsInput = {
  create: UserCreateWithoutCommentsInput;
  update: UserUpdateWithoutCommentsInput;
};

export type UserUpsertWithoutWorkshopsInput = {
  create: UserCreateWithoutWorkshopsInput;
  update: UserUpdateWithoutWorkshopsInput;
};

export type UserWhereInput = {
  AND?: InputMaybe<Array<UserWhereInput>>;
  NOT?: InputMaybe<Array<UserWhereInput>>;
  OR?: InputMaybe<Array<UserWhereInput>>;
  activities?: InputMaybe<ActivityListRelationFilter>;
  comments?: InputMaybe<CommentListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  email?: InputMaybe<StringNullableFilter>;
  firstName?: InputMaybe<StringFilter>;
  id?: InputMaybe<IntFilter>;
  isActive?: InputMaybe<BoolFilter>;
  lastLoginAt?: InputMaybe<DateTimeNullableFilter>;
  lastName?: InputMaybe<StringFilter>;
  phoneNumber?: InputMaybe<StringFilter>;
  role?: InputMaybe<EnumRoleFilter>;
  schools?: InputMaybe<SchoolListRelationFilter>;
  standards?: InputMaybe<StandardListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userLogs?: InputMaybe<UserLogListRelationFilter>;
  workshops?: InputMaybe<WorkshopListRelationFilter>;
};

export type UserWhereUniqueInput = {
  id?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber?: InputMaybe<Scalars["String"]["input"]>;
};

export type ValidateActivityQuestionAnswerInput = {
  answers: Array<Scalars["String"]["input"]>;
};

export type ValidateActivityQuestionAnswerOutput = {
  __typename?: "ValidateActivityQuestionAnswerOutput";
  answerText?: Maybe<Scalars["String"]["output"]>;
  incorrectText?: Maybe<Scalars["String"]["output"]>;
  isCorrect: Scalars["Boolean"]["output"];
};

export type ValidateActivityQuestionSubmitInput = {
  answers: Array<Scalars["String"]["input"]>;
  questionId: Scalars["Int"]["input"];
};

export type ValidateActivityQuestionSubmitOutput = {
  __typename?: "ValidateActivityQuestionSubmitOutput";
  message: Scalars["String"]["output"];
  success: Scalars["Boolean"]["output"];
};

export type VerifySignUpCodeInput = {
  code: Scalars["String"]["input"];
};

export type VerifyUserInput = {
  id: Scalars["String"]["input"];
};

export type VerifyUserResponse = {
  __typename?: "VerifyUserResponse";
  message: Scalars["String"]["output"];
  success: Scalars["Boolean"]["output"];
};

export type Workshop = {
  __typename?: "Workshop";
  _count?: Maybe<WorkshopCount>;
  address?: Maybe<Scalars["String"]["output"]>;
  ageGroup?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  description?: Maybe<Scalars["String"]["output"]>;
  displayTime?: Maybe<Scalars["String"]["output"]>;
  endDate?: Maybe<Scalars["DateTime"]["output"]>;
  featuredImage?: Maybe<Scalars["String"]["output"]>;
  feesInCurrency?: Maybe<Scalars["Int"]["output"]>;
  feesWithKit?: Maybe<Scalars["Int"]["output"]>;
  feesWithoutKit?: Maybe<Scalars["Int"]["output"]>;
  footerImageLarge?: Maybe<Scalars["String"]["output"]>;
  footerImageSmall?: Maybe<Scalars["String"]["output"]>;
  headerImageLarge?: Maybe<Scalars["String"]["output"]>;
  headerImageSmall?: Maybe<Scalars["String"]["output"]>;
  headerText?: Maybe<Scalars["String"]["output"]>;
  headerVideo?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["Int"]["output"];
  image?: Maybe<Scalars["String"]["output"]>;
  insiderActivities: Array<Activity>;
  isOpen: Scalars["Boolean"]["output"];
  liveUrl?: Maybe<Scalars["String"]["output"]>;
  location?: Maybe<Scalars["String"]["output"]>;
  meta?: Maybe<Scalars["JSON"]["output"]>;
  metaTagData?: Maybe<Scalars["JSON"]["output"]>;
  mode: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  product?: Maybe<Product>;
  productId?: Maybe<Scalars["Int"]["output"]>;
  promoCodes: Array<PromoCode>;
  relatedActivities: Array<Activity>;
  school: School;
  schoolId: Scalars["Int"]["output"];
  skill: Skill;
  skillId: Scalars["Int"]["output"];
  slug: Scalars["String"]["output"];
  standards: Array<Standard>;
  startDate: Scalars["DateTime"]["output"];
  tags: Array<Tag>;
  trainer?: Maybe<User>;
  trainerId?: Maybe<Scalars["Int"]["output"]>;
  trainerImage?: Maybe<Scalars["String"]["output"]>;
  type: Scalars["String"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
  workshopRegistrations: Array<WorkshopRegistration>;
};


export type WorkshopInsiderActivitiesArgs = {
  cursor?: InputMaybe<ActivityWhereUniqueInput>;
  distinct?: InputMaybe<Array<ActivityScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ActivityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ActivityWhereInput>;
};


export type WorkshopPromoCodesArgs = {
  cursor?: InputMaybe<PromoCodeWhereUniqueInput>;
  distinct?: InputMaybe<Array<PromoCodeScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PromoCodeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<PromoCodeWhereInput>;
};


export type WorkshopRelatedActivitiesArgs = {
  cursor?: InputMaybe<ActivityWhereUniqueInput>;
  distinct?: InputMaybe<Array<ActivityScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ActivityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ActivityWhereInput>;
};


export type WorkshopStandardsArgs = {
  cursor?: InputMaybe<StandardWhereUniqueInput>;
  distinct?: InputMaybe<Array<StandardScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StandardOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<StandardWhereInput>;
};


export type WorkshopTagsArgs = {
  cursor?: InputMaybe<TagWhereUniqueInput>;
  distinct?: InputMaybe<Array<TagScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TagOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<TagWhereInput>;
};


export type WorkshopWorkshopRegistrationsArgs = {
  cursor?: InputMaybe<WorkshopRegistrationWhereUniqueInput>;
  distinct?: InputMaybe<Array<WorkshopRegistrationScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<WorkshopRegistrationOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<WorkshopRegistrationWhereInput>;
};

export type WorkshopAvgAggregate = {
  __typename?: "WorkshopAvgAggregate";
  feesInCurrency?: Maybe<Scalars["Float"]["output"]>;
  feesWithKit?: Maybe<Scalars["Float"]["output"]>;
  feesWithoutKit?: Maybe<Scalars["Float"]["output"]>;
  id?: Maybe<Scalars["Float"]["output"]>;
  productId?: Maybe<Scalars["Float"]["output"]>;
  schoolId?: Maybe<Scalars["Float"]["output"]>;
  skillId?: Maybe<Scalars["Float"]["output"]>;
  trainerId?: Maybe<Scalars["Float"]["output"]>;
};

export type WorkshopCount = {
  __typename?: "WorkshopCount";
  insiderActivities: Scalars["Int"]["output"];
  promoCodes: Scalars["Int"]["output"];
  relatedActivities: Scalars["Int"]["output"];
  standards: Scalars["Int"]["output"];
  tags: Scalars["Int"]["output"];
  workshopRegistrations: Scalars["Int"]["output"];
};


export type WorkshopCountInsiderActivitiesArgs = {
  where?: InputMaybe<ActivityWhereInput>;
};


export type WorkshopCountPromoCodesArgs = {
  where?: InputMaybe<PromoCodeWhereInput>;
};


export type WorkshopCountRelatedActivitiesArgs = {
  where?: InputMaybe<ActivityWhereInput>;
};


export type WorkshopCountStandardsArgs = {
  where?: InputMaybe<StandardWhereInput>;
};


export type WorkshopCountTagsArgs = {
  where?: InputMaybe<TagWhereInput>;
};


export type WorkshopCountWorkshopRegistrationsArgs = {
  where?: InputMaybe<WorkshopRegistrationWhereInput>;
};

export type WorkshopCountAggregate = {
  __typename?: "WorkshopCountAggregate";
  _all: Scalars["Int"]["output"];
  address: Scalars["Int"]["output"];
  ageGroup: Scalars["Int"]["output"];
  createdAt: Scalars["Int"]["output"];
  description: Scalars["Int"]["output"];
  displayTime: Scalars["Int"]["output"];
  endDate: Scalars["Int"]["output"];
  featuredImage: Scalars["Int"]["output"];
  feesInCurrency: Scalars["Int"]["output"];
  feesWithKit: Scalars["Int"]["output"];
  feesWithoutKit: Scalars["Int"]["output"];
  footerImageLarge: Scalars["Int"]["output"];
  footerImageSmall: Scalars["Int"]["output"];
  headerImageLarge: Scalars["Int"]["output"];
  headerImageSmall: Scalars["Int"]["output"];
  headerText: Scalars["Int"]["output"];
  headerVideo: Scalars["Int"]["output"];
  id: Scalars["Int"]["output"];
  image: Scalars["Int"]["output"];
  isOpen: Scalars["Int"]["output"];
  liveUrl: Scalars["Int"]["output"];
  location: Scalars["Int"]["output"];
  meta: Scalars["Int"]["output"];
  metaTagData: Scalars["Int"]["output"];
  mode: Scalars["Int"]["output"];
  name: Scalars["Int"]["output"];
  productId: Scalars["Int"]["output"];
  schoolId: Scalars["Int"]["output"];
  skillId: Scalars["Int"]["output"];
  slug: Scalars["Int"]["output"];
  startDate: Scalars["Int"]["output"];
  trainerId: Scalars["Int"]["output"];
  trainerImage: Scalars["Int"]["output"];
  type: Scalars["Int"]["output"];
  updatedAt: Scalars["Int"]["output"];
};

export type WorkshopCreateInput = {
  address?: InputMaybe<Scalars["String"]["input"]>;
  ageGroup?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  displayTime?: InputMaybe<Scalars["String"]["input"]>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  featuredImage?: InputMaybe<Scalars["String"]["input"]>;
  feesInCurrency?: InputMaybe<Scalars["Int"]["input"]>;
  feesWithKit?: InputMaybe<Scalars["Int"]["input"]>;
  feesWithoutKit?: InputMaybe<Scalars["Int"]["input"]>;
  footerImageLarge?: InputMaybe<Scalars["String"]["input"]>;
  footerImageSmall?: InputMaybe<Scalars["String"]["input"]>;
  headerImageLarge?: InputMaybe<Scalars["String"]["input"]>;
  headerImageSmall?: InputMaybe<Scalars["String"]["input"]>;
  headerText?: InputMaybe<Scalars["String"]["input"]>;
  headerVideo?: InputMaybe<Scalars["String"]["input"]>;
  image?: InputMaybe<Scalars["String"]["input"]>;
  insiderActivities?: InputMaybe<ActivityCreateNestedManyWithoutInsideWorkshopsInput>;
  isOpen?: InputMaybe<Scalars["Boolean"]["input"]>;
  liveUrl?: InputMaybe<Scalars["String"]["input"]>;
  location?: InputMaybe<Scalars["String"]["input"]>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  metaTagData?: InputMaybe<Scalars["JSON"]["input"]>;
  mode: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  product?: InputMaybe<ProductCreateNestedOneWithoutWorkshopsInput>;
  promoCodes?: InputMaybe<PromoCodeCreateNestedManyWithoutWorkshopsInput>;
  relatedActivities?: InputMaybe<ActivityCreateNestedManyWithoutRelatedWorkshopsInput>;
  school: SchoolCreateNestedOneWithoutWorkshopsInput;
  skill: SkillCreateNestedOneWithoutWorkshopsInput;
  slug: Scalars["String"]["input"];
  standards?: InputMaybe<StandardCreateNestedManyWithoutWorkshopsInput>;
  startDate: Scalars["DateTime"]["input"];
  tags?: InputMaybe<TagCreateNestedManyWithoutWorkshopsInput>;
  trainer?: InputMaybe<UserCreateNestedOneWithoutWorkshopsInput>;
  trainerImage?: InputMaybe<Scalars["String"]["input"]>;
  type: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  workshopRegistrations?: InputMaybe<WorkshopRegistrationCreateNestedManyWithoutWorkshopInput>;
};

export type WorkshopCreateManyProductInput = {
  address?: InputMaybe<Scalars["String"]["input"]>;
  ageGroup?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  displayTime?: InputMaybe<Scalars["String"]["input"]>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  featuredImage?: InputMaybe<Scalars["String"]["input"]>;
  feesInCurrency?: InputMaybe<Scalars["Int"]["input"]>;
  feesWithKit?: InputMaybe<Scalars["Int"]["input"]>;
  feesWithoutKit?: InputMaybe<Scalars["Int"]["input"]>;
  footerImageLarge?: InputMaybe<Scalars["String"]["input"]>;
  footerImageSmall?: InputMaybe<Scalars["String"]["input"]>;
  headerImageLarge?: InputMaybe<Scalars["String"]["input"]>;
  headerImageSmall?: InputMaybe<Scalars["String"]["input"]>;
  headerText?: InputMaybe<Scalars["String"]["input"]>;
  headerVideo?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  image?: InputMaybe<Scalars["String"]["input"]>;
  isOpen?: InputMaybe<Scalars["Boolean"]["input"]>;
  liveUrl?: InputMaybe<Scalars["String"]["input"]>;
  location?: InputMaybe<Scalars["String"]["input"]>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  metaTagData?: InputMaybe<Scalars["JSON"]["input"]>;
  mode: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  schoolId: Scalars["Int"]["input"];
  skillId: Scalars["Int"]["input"];
  slug: Scalars["String"]["input"];
  startDate: Scalars["DateTime"]["input"];
  trainerId?: InputMaybe<Scalars["Int"]["input"]>;
  trainerImage?: InputMaybe<Scalars["String"]["input"]>;
  type: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type WorkshopCreateManyProductInputEnvelope = {
  data: Array<WorkshopCreateManyProductInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type WorkshopCreateManySchoolInput = {
  address?: InputMaybe<Scalars["String"]["input"]>;
  ageGroup?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  displayTime?: InputMaybe<Scalars["String"]["input"]>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  featuredImage?: InputMaybe<Scalars["String"]["input"]>;
  feesInCurrency?: InputMaybe<Scalars["Int"]["input"]>;
  feesWithKit?: InputMaybe<Scalars["Int"]["input"]>;
  feesWithoutKit?: InputMaybe<Scalars["Int"]["input"]>;
  footerImageLarge?: InputMaybe<Scalars["String"]["input"]>;
  footerImageSmall?: InputMaybe<Scalars["String"]["input"]>;
  headerImageLarge?: InputMaybe<Scalars["String"]["input"]>;
  headerImageSmall?: InputMaybe<Scalars["String"]["input"]>;
  headerText?: InputMaybe<Scalars["String"]["input"]>;
  headerVideo?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  image?: InputMaybe<Scalars["String"]["input"]>;
  isOpen?: InputMaybe<Scalars["Boolean"]["input"]>;
  liveUrl?: InputMaybe<Scalars["String"]["input"]>;
  location?: InputMaybe<Scalars["String"]["input"]>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  metaTagData?: InputMaybe<Scalars["JSON"]["input"]>;
  mode: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  productId?: InputMaybe<Scalars["Int"]["input"]>;
  skillId: Scalars["Int"]["input"];
  slug: Scalars["String"]["input"];
  startDate: Scalars["DateTime"]["input"];
  trainerId?: InputMaybe<Scalars["Int"]["input"]>;
  trainerImage?: InputMaybe<Scalars["String"]["input"]>;
  type: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type WorkshopCreateManySchoolInputEnvelope = {
  data: Array<WorkshopCreateManySchoolInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type WorkshopCreateManySkillInput = {
  address?: InputMaybe<Scalars["String"]["input"]>;
  ageGroup?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  displayTime?: InputMaybe<Scalars["String"]["input"]>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  featuredImage?: InputMaybe<Scalars["String"]["input"]>;
  feesInCurrency?: InputMaybe<Scalars["Int"]["input"]>;
  feesWithKit?: InputMaybe<Scalars["Int"]["input"]>;
  feesWithoutKit?: InputMaybe<Scalars["Int"]["input"]>;
  footerImageLarge?: InputMaybe<Scalars["String"]["input"]>;
  footerImageSmall?: InputMaybe<Scalars["String"]["input"]>;
  headerImageLarge?: InputMaybe<Scalars["String"]["input"]>;
  headerImageSmall?: InputMaybe<Scalars["String"]["input"]>;
  headerText?: InputMaybe<Scalars["String"]["input"]>;
  headerVideo?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  image?: InputMaybe<Scalars["String"]["input"]>;
  isOpen?: InputMaybe<Scalars["Boolean"]["input"]>;
  liveUrl?: InputMaybe<Scalars["String"]["input"]>;
  location?: InputMaybe<Scalars["String"]["input"]>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  metaTagData?: InputMaybe<Scalars["JSON"]["input"]>;
  mode: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  productId?: InputMaybe<Scalars["Int"]["input"]>;
  schoolId: Scalars["Int"]["input"];
  slug: Scalars["String"]["input"];
  startDate: Scalars["DateTime"]["input"];
  trainerId?: InputMaybe<Scalars["Int"]["input"]>;
  trainerImage?: InputMaybe<Scalars["String"]["input"]>;
  type: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type WorkshopCreateManySkillInputEnvelope = {
  data: Array<WorkshopCreateManySkillInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type WorkshopCreateManyTrainerInput = {
  address?: InputMaybe<Scalars["String"]["input"]>;
  ageGroup?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  displayTime?: InputMaybe<Scalars["String"]["input"]>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  featuredImage?: InputMaybe<Scalars["String"]["input"]>;
  feesInCurrency?: InputMaybe<Scalars["Int"]["input"]>;
  feesWithKit?: InputMaybe<Scalars["Int"]["input"]>;
  feesWithoutKit?: InputMaybe<Scalars["Int"]["input"]>;
  footerImageLarge?: InputMaybe<Scalars["String"]["input"]>;
  footerImageSmall?: InputMaybe<Scalars["String"]["input"]>;
  headerImageLarge?: InputMaybe<Scalars["String"]["input"]>;
  headerImageSmall?: InputMaybe<Scalars["String"]["input"]>;
  headerText?: InputMaybe<Scalars["String"]["input"]>;
  headerVideo?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  image?: InputMaybe<Scalars["String"]["input"]>;
  isOpen?: InputMaybe<Scalars["Boolean"]["input"]>;
  liveUrl?: InputMaybe<Scalars["String"]["input"]>;
  location?: InputMaybe<Scalars["String"]["input"]>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  metaTagData?: InputMaybe<Scalars["JSON"]["input"]>;
  mode: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  productId?: InputMaybe<Scalars["Int"]["input"]>;
  schoolId: Scalars["Int"]["input"];
  skillId: Scalars["Int"]["input"];
  slug: Scalars["String"]["input"];
  startDate: Scalars["DateTime"]["input"];
  trainerImage?: InputMaybe<Scalars["String"]["input"]>;
  type: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type WorkshopCreateManyTrainerInputEnvelope = {
  data: Array<WorkshopCreateManyTrainerInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type WorkshopCreateNestedManyWithoutInsiderActivitiesInput = {
  connect?: InputMaybe<Array<WorkshopWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<WorkshopCreateOrConnectWithoutInsiderActivitiesInput>>;
  create?: InputMaybe<Array<WorkshopCreateWithoutInsiderActivitiesInput>>;
};

export type WorkshopCreateNestedManyWithoutProductInput = {
  connect?: InputMaybe<Array<WorkshopWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<WorkshopCreateOrConnectWithoutProductInput>>;
  create?: InputMaybe<Array<WorkshopCreateWithoutProductInput>>;
  createMany?: InputMaybe<WorkshopCreateManyProductInputEnvelope>;
};

export type WorkshopCreateNestedManyWithoutPromoCodesInput = {
  connect?: InputMaybe<Array<WorkshopWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<WorkshopCreateOrConnectWithoutPromoCodesInput>>;
  create?: InputMaybe<Array<WorkshopCreateWithoutPromoCodesInput>>;
};

export type WorkshopCreateNestedManyWithoutRelatedActivitiesInput = {
  connect?: InputMaybe<Array<WorkshopWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<WorkshopCreateOrConnectWithoutRelatedActivitiesInput>>;
  create?: InputMaybe<Array<WorkshopCreateWithoutRelatedActivitiesInput>>;
};

export type WorkshopCreateNestedManyWithoutSchoolInput = {
  connect?: InputMaybe<Array<WorkshopWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<WorkshopCreateOrConnectWithoutSchoolInput>>;
  create?: InputMaybe<Array<WorkshopCreateWithoutSchoolInput>>;
  createMany?: InputMaybe<WorkshopCreateManySchoolInputEnvelope>;
};

export type WorkshopCreateNestedManyWithoutSkillInput = {
  connect?: InputMaybe<Array<WorkshopWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<WorkshopCreateOrConnectWithoutSkillInput>>;
  create?: InputMaybe<Array<WorkshopCreateWithoutSkillInput>>;
  createMany?: InputMaybe<WorkshopCreateManySkillInputEnvelope>;
};

export type WorkshopCreateNestedManyWithoutStandardsInput = {
  connect?: InputMaybe<Array<WorkshopWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<WorkshopCreateOrConnectWithoutStandardsInput>>;
  create?: InputMaybe<Array<WorkshopCreateWithoutStandardsInput>>;
};

export type WorkshopCreateNestedManyWithoutTagsInput = {
  connect?: InputMaybe<Array<WorkshopWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<WorkshopCreateOrConnectWithoutTagsInput>>;
  create?: InputMaybe<Array<WorkshopCreateWithoutTagsInput>>;
};

export type WorkshopCreateNestedManyWithoutTrainerInput = {
  connect?: InputMaybe<Array<WorkshopWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<WorkshopCreateOrConnectWithoutTrainerInput>>;
  create?: InputMaybe<Array<WorkshopCreateWithoutTrainerInput>>;
  createMany?: InputMaybe<WorkshopCreateManyTrainerInputEnvelope>;
};

export type WorkshopCreateNestedOneWithoutWorkshopRegistrationsInput = {
  connect?: InputMaybe<WorkshopWhereUniqueInput>;
  connectOrCreate?: InputMaybe<WorkshopCreateOrConnectWithoutWorkshopRegistrationsInput>;
  create?: InputMaybe<WorkshopCreateWithoutWorkshopRegistrationsInput>;
};

export type WorkshopCreateOrConnectWithoutInsiderActivitiesInput = {
  create: WorkshopCreateWithoutInsiderActivitiesInput;
  where: WorkshopWhereUniqueInput;
};

export type WorkshopCreateOrConnectWithoutProductInput = {
  create: WorkshopCreateWithoutProductInput;
  where: WorkshopWhereUniqueInput;
};

export type WorkshopCreateOrConnectWithoutPromoCodesInput = {
  create: WorkshopCreateWithoutPromoCodesInput;
  where: WorkshopWhereUniqueInput;
};

export type WorkshopCreateOrConnectWithoutRelatedActivitiesInput = {
  create: WorkshopCreateWithoutRelatedActivitiesInput;
  where: WorkshopWhereUniqueInput;
};

export type WorkshopCreateOrConnectWithoutSchoolInput = {
  create: WorkshopCreateWithoutSchoolInput;
  where: WorkshopWhereUniqueInput;
};

export type WorkshopCreateOrConnectWithoutSkillInput = {
  create: WorkshopCreateWithoutSkillInput;
  where: WorkshopWhereUniqueInput;
};

export type WorkshopCreateOrConnectWithoutStandardsInput = {
  create: WorkshopCreateWithoutStandardsInput;
  where: WorkshopWhereUniqueInput;
};

export type WorkshopCreateOrConnectWithoutTagsInput = {
  create: WorkshopCreateWithoutTagsInput;
  where: WorkshopWhereUniqueInput;
};

export type WorkshopCreateOrConnectWithoutTrainerInput = {
  create: WorkshopCreateWithoutTrainerInput;
  where: WorkshopWhereUniqueInput;
};

export type WorkshopCreateOrConnectWithoutWorkshopRegistrationsInput = {
  create: WorkshopCreateWithoutWorkshopRegistrationsInput;
  where: WorkshopWhereUniqueInput;
};

export type WorkshopCreateWithoutInsiderActivitiesInput = {
  address?: InputMaybe<Scalars["String"]["input"]>;
  ageGroup?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  displayTime?: InputMaybe<Scalars["String"]["input"]>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  featuredImage?: InputMaybe<Scalars["String"]["input"]>;
  feesInCurrency?: InputMaybe<Scalars["Int"]["input"]>;
  feesWithKit?: InputMaybe<Scalars["Int"]["input"]>;
  feesWithoutKit?: InputMaybe<Scalars["Int"]["input"]>;
  footerImageLarge?: InputMaybe<Scalars["String"]["input"]>;
  footerImageSmall?: InputMaybe<Scalars["String"]["input"]>;
  headerImageLarge?: InputMaybe<Scalars["String"]["input"]>;
  headerImageSmall?: InputMaybe<Scalars["String"]["input"]>;
  headerText?: InputMaybe<Scalars["String"]["input"]>;
  headerVideo?: InputMaybe<Scalars["String"]["input"]>;
  image?: InputMaybe<Scalars["String"]["input"]>;
  isOpen?: InputMaybe<Scalars["Boolean"]["input"]>;
  liveUrl?: InputMaybe<Scalars["String"]["input"]>;
  location?: InputMaybe<Scalars["String"]["input"]>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  metaTagData?: InputMaybe<Scalars["JSON"]["input"]>;
  mode: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  product?: InputMaybe<ProductCreateNestedOneWithoutWorkshopsInput>;
  promoCodes?: InputMaybe<PromoCodeCreateNestedManyWithoutWorkshopsInput>;
  relatedActivities?: InputMaybe<ActivityCreateNestedManyWithoutRelatedWorkshopsInput>;
  school: SchoolCreateNestedOneWithoutWorkshopsInput;
  skill: SkillCreateNestedOneWithoutWorkshopsInput;
  slug: Scalars["String"]["input"];
  standards?: InputMaybe<StandardCreateNestedManyWithoutWorkshopsInput>;
  startDate: Scalars["DateTime"]["input"];
  tags?: InputMaybe<TagCreateNestedManyWithoutWorkshopsInput>;
  trainer?: InputMaybe<UserCreateNestedOneWithoutWorkshopsInput>;
  trainerImage?: InputMaybe<Scalars["String"]["input"]>;
  type: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  workshopRegistrations?: InputMaybe<WorkshopRegistrationCreateNestedManyWithoutWorkshopInput>;
};

export type WorkshopCreateWithoutProductInput = {
  address?: InputMaybe<Scalars["String"]["input"]>;
  ageGroup?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  displayTime?: InputMaybe<Scalars["String"]["input"]>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  featuredImage?: InputMaybe<Scalars["String"]["input"]>;
  feesInCurrency?: InputMaybe<Scalars["Int"]["input"]>;
  feesWithKit?: InputMaybe<Scalars["Int"]["input"]>;
  feesWithoutKit?: InputMaybe<Scalars["Int"]["input"]>;
  footerImageLarge?: InputMaybe<Scalars["String"]["input"]>;
  footerImageSmall?: InputMaybe<Scalars["String"]["input"]>;
  headerImageLarge?: InputMaybe<Scalars["String"]["input"]>;
  headerImageSmall?: InputMaybe<Scalars["String"]["input"]>;
  headerText?: InputMaybe<Scalars["String"]["input"]>;
  headerVideo?: InputMaybe<Scalars["String"]["input"]>;
  image?: InputMaybe<Scalars["String"]["input"]>;
  insiderActivities?: InputMaybe<ActivityCreateNestedManyWithoutInsideWorkshopsInput>;
  isOpen?: InputMaybe<Scalars["Boolean"]["input"]>;
  liveUrl?: InputMaybe<Scalars["String"]["input"]>;
  location?: InputMaybe<Scalars["String"]["input"]>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  metaTagData?: InputMaybe<Scalars["JSON"]["input"]>;
  mode: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  promoCodes?: InputMaybe<PromoCodeCreateNestedManyWithoutWorkshopsInput>;
  relatedActivities?: InputMaybe<ActivityCreateNestedManyWithoutRelatedWorkshopsInput>;
  school: SchoolCreateNestedOneWithoutWorkshopsInput;
  skill: SkillCreateNestedOneWithoutWorkshopsInput;
  slug: Scalars["String"]["input"];
  standards?: InputMaybe<StandardCreateNestedManyWithoutWorkshopsInput>;
  startDate: Scalars["DateTime"]["input"];
  tags?: InputMaybe<TagCreateNestedManyWithoutWorkshopsInput>;
  trainer?: InputMaybe<UserCreateNestedOneWithoutWorkshopsInput>;
  trainerImage?: InputMaybe<Scalars["String"]["input"]>;
  type: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  workshopRegistrations?: InputMaybe<WorkshopRegistrationCreateNestedManyWithoutWorkshopInput>;
};

export type WorkshopCreateWithoutPromoCodesInput = {
  address?: InputMaybe<Scalars["String"]["input"]>;
  ageGroup?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  displayTime?: InputMaybe<Scalars["String"]["input"]>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  featuredImage?: InputMaybe<Scalars["String"]["input"]>;
  feesInCurrency?: InputMaybe<Scalars["Int"]["input"]>;
  feesWithKit?: InputMaybe<Scalars["Int"]["input"]>;
  feesWithoutKit?: InputMaybe<Scalars["Int"]["input"]>;
  footerImageLarge?: InputMaybe<Scalars["String"]["input"]>;
  footerImageSmall?: InputMaybe<Scalars["String"]["input"]>;
  headerImageLarge?: InputMaybe<Scalars["String"]["input"]>;
  headerImageSmall?: InputMaybe<Scalars["String"]["input"]>;
  headerText?: InputMaybe<Scalars["String"]["input"]>;
  headerVideo?: InputMaybe<Scalars["String"]["input"]>;
  image?: InputMaybe<Scalars["String"]["input"]>;
  insiderActivities?: InputMaybe<ActivityCreateNestedManyWithoutInsideWorkshopsInput>;
  isOpen?: InputMaybe<Scalars["Boolean"]["input"]>;
  liveUrl?: InputMaybe<Scalars["String"]["input"]>;
  location?: InputMaybe<Scalars["String"]["input"]>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  metaTagData?: InputMaybe<Scalars["JSON"]["input"]>;
  mode: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  product?: InputMaybe<ProductCreateNestedOneWithoutWorkshopsInput>;
  relatedActivities?: InputMaybe<ActivityCreateNestedManyWithoutRelatedWorkshopsInput>;
  school: SchoolCreateNestedOneWithoutWorkshopsInput;
  skill: SkillCreateNestedOneWithoutWorkshopsInput;
  slug: Scalars["String"]["input"];
  standards?: InputMaybe<StandardCreateNestedManyWithoutWorkshopsInput>;
  startDate: Scalars["DateTime"]["input"];
  tags?: InputMaybe<TagCreateNestedManyWithoutWorkshopsInput>;
  trainer?: InputMaybe<UserCreateNestedOneWithoutWorkshopsInput>;
  trainerImage?: InputMaybe<Scalars["String"]["input"]>;
  type: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  workshopRegistrations?: InputMaybe<WorkshopRegistrationCreateNestedManyWithoutWorkshopInput>;
};

export type WorkshopCreateWithoutRelatedActivitiesInput = {
  address?: InputMaybe<Scalars["String"]["input"]>;
  ageGroup?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  displayTime?: InputMaybe<Scalars["String"]["input"]>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  featuredImage?: InputMaybe<Scalars["String"]["input"]>;
  feesInCurrency?: InputMaybe<Scalars["Int"]["input"]>;
  feesWithKit?: InputMaybe<Scalars["Int"]["input"]>;
  feesWithoutKit?: InputMaybe<Scalars["Int"]["input"]>;
  footerImageLarge?: InputMaybe<Scalars["String"]["input"]>;
  footerImageSmall?: InputMaybe<Scalars["String"]["input"]>;
  headerImageLarge?: InputMaybe<Scalars["String"]["input"]>;
  headerImageSmall?: InputMaybe<Scalars["String"]["input"]>;
  headerText?: InputMaybe<Scalars["String"]["input"]>;
  headerVideo?: InputMaybe<Scalars["String"]["input"]>;
  image?: InputMaybe<Scalars["String"]["input"]>;
  insiderActivities?: InputMaybe<ActivityCreateNestedManyWithoutInsideWorkshopsInput>;
  isOpen?: InputMaybe<Scalars["Boolean"]["input"]>;
  liveUrl?: InputMaybe<Scalars["String"]["input"]>;
  location?: InputMaybe<Scalars["String"]["input"]>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  metaTagData?: InputMaybe<Scalars["JSON"]["input"]>;
  mode: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  product?: InputMaybe<ProductCreateNestedOneWithoutWorkshopsInput>;
  promoCodes?: InputMaybe<PromoCodeCreateNestedManyWithoutWorkshopsInput>;
  school: SchoolCreateNestedOneWithoutWorkshopsInput;
  skill: SkillCreateNestedOneWithoutWorkshopsInput;
  slug: Scalars["String"]["input"];
  standards?: InputMaybe<StandardCreateNestedManyWithoutWorkshopsInput>;
  startDate: Scalars["DateTime"]["input"];
  tags?: InputMaybe<TagCreateNestedManyWithoutWorkshopsInput>;
  trainer?: InputMaybe<UserCreateNestedOneWithoutWorkshopsInput>;
  trainerImage?: InputMaybe<Scalars["String"]["input"]>;
  type: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  workshopRegistrations?: InputMaybe<WorkshopRegistrationCreateNestedManyWithoutWorkshopInput>;
};

export type WorkshopCreateWithoutSchoolInput = {
  address?: InputMaybe<Scalars["String"]["input"]>;
  ageGroup?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  displayTime?: InputMaybe<Scalars["String"]["input"]>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  featuredImage?: InputMaybe<Scalars["String"]["input"]>;
  feesInCurrency?: InputMaybe<Scalars["Int"]["input"]>;
  feesWithKit?: InputMaybe<Scalars["Int"]["input"]>;
  feesWithoutKit?: InputMaybe<Scalars["Int"]["input"]>;
  footerImageLarge?: InputMaybe<Scalars["String"]["input"]>;
  footerImageSmall?: InputMaybe<Scalars["String"]["input"]>;
  headerImageLarge?: InputMaybe<Scalars["String"]["input"]>;
  headerImageSmall?: InputMaybe<Scalars["String"]["input"]>;
  headerText?: InputMaybe<Scalars["String"]["input"]>;
  headerVideo?: InputMaybe<Scalars["String"]["input"]>;
  image?: InputMaybe<Scalars["String"]["input"]>;
  insiderActivities?: InputMaybe<ActivityCreateNestedManyWithoutInsideWorkshopsInput>;
  isOpen?: InputMaybe<Scalars["Boolean"]["input"]>;
  liveUrl?: InputMaybe<Scalars["String"]["input"]>;
  location?: InputMaybe<Scalars["String"]["input"]>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  metaTagData?: InputMaybe<Scalars["JSON"]["input"]>;
  mode: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  product?: InputMaybe<ProductCreateNestedOneWithoutWorkshopsInput>;
  promoCodes?: InputMaybe<PromoCodeCreateNestedManyWithoutWorkshopsInput>;
  relatedActivities?: InputMaybe<ActivityCreateNestedManyWithoutRelatedWorkshopsInput>;
  skill: SkillCreateNestedOneWithoutWorkshopsInput;
  slug: Scalars["String"]["input"];
  standards?: InputMaybe<StandardCreateNestedManyWithoutWorkshopsInput>;
  startDate: Scalars["DateTime"]["input"];
  tags?: InputMaybe<TagCreateNestedManyWithoutWorkshopsInput>;
  trainer?: InputMaybe<UserCreateNestedOneWithoutWorkshopsInput>;
  trainerImage?: InputMaybe<Scalars["String"]["input"]>;
  type: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  workshopRegistrations?: InputMaybe<WorkshopRegistrationCreateNestedManyWithoutWorkshopInput>;
};

export type WorkshopCreateWithoutSkillInput = {
  address?: InputMaybe<Scalars["String"]["input"]>;
  ageGroup?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  displayTime?: InputMaybe<Scalars["String"]["input"]>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  featuredImage?: InputMaybe<Scalars["String"]["input"]>;
  feesInCurrency?: InputMaybe<Scalars["Int"]["input"]>;
  feesWithKit?: InputMaybe<Scalars["Int"]["input"]>;
  feesWithoutKit?: InputMaybe<Scalars["Int"]["input"]>;
  footerImageLarge?: InputMaybe<Scalars["String"]["input"]>;
  footerImageSmall?: InputMaybe<Scalars["String"]["input"]>;
  headerImageLarge?: InputMaybe<Scalars["String"]["input"]>;
  headerImageSmall?: InputMaybe<Scalars["String"]["input"]>;
  headerText?: InputMaybe<Scalars["String"]["input"]>;
  headerVideo?: InputMaybe<Scalars["String"]["input"]>;
  image?: InputMaybe<Scalars["String"]["input"]>;
  insiderActivities?: InputMaybe<ActivityCreateNestedManyWithoutInsideWorkshopsInput>;
  isOpen?: InputMaybe<Scalars["Boolean"]["input"]>;
  liveUrl?: InputMaybe<Scalars["String"]["input"]>;
  location?: InputMaybe<Scalars["String"]["input"]>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  metaTagData?: InputMaybe<Scalars["JSON"]["input"]>;
  mode: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  product?: InputMaybe<ProductCreateNestedOneWithoutWorkshopsInput>;
  promoCodes?: InputMaybe<PromoCodeCreateNestedManyWithoutWorkshopsInput>;
  relatedActivities?: InputMaybe<ActivityCreateNestedManyWithoutRelatedWorkshopsInput>;
  school: SchoolCreateNestedOneWithoutWorkshopsInput;
  slug: Scalars["String"]["input"];
  standards?: InputMaybe<StandardCreateNestedManyWithoutWorkshopsInput>;
  startDate: Scalars["DateTime"]["input"];
  tags?: InputMaybe<TagCreateNestedManyWithoutWorkshopsInput>;
  trainer?: InputMaybe<UserCreateNestedOneWithoutWorkshopsInput>;
  trainerImage?: InputMaybe<Scalars["String"]["input"]>;
  type: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  workshopRegistrations?: InputMaybe<WorkshopRegistrationCreateNestedManyWithoutWorkshopInput>;
};

export type WorkshopCreateWithoutStandardsInput = {
  address?: InputMaybe<Scalars["String"]["input"]>;
  ageGroup?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  displayTime?: InputMaybe<Scalars["String"]["input"]>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  featuredImage?: InputMaybe<Scalars["String"]["input"]>;
  feesInCurrency?: InputMaybe<Scalars["Int"]["input"]>;
  feesWithKit?: InputMaybe<Scalars["Int"]["input"]>;
  feesWithoutKit?: InputMaybe<Scalars["Int"]["input"]>;
  footerImageLarge?: InputMaybe<Scalars["String"]["input"]>;
  footerImageSmall?: InputMaybe<Scalars["String"]["input"]>;
  headerImageLarge?: InputMaybe<Scalars["String"]["input"]>;
  headerImageSmall?: InputMaybe<Scalars["String"]["input"]>;
  headerText?: InputMaybe<Scalars["String"]["input"]>;
  headerVideo?: InputMaybe<Scalars["String"]["input"]>;
  image?: InputMaybe<Scalars["String"]["input"]>;
  insiderActivities?: InputMaybe<ActivityCreateNestedManyWithoutInsideWorkshopsInput>;
  isOpen?: InputMaybe<Scalars["Boolean"]["input"]>;
  liveUrl?: InputMaybe<Scalars["String"]["input"]>;
  location?: InputMaybe<Scalars["String"]["input"]>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  metaTagData?: InputMaybe<Scalars["JSON"]["input"]>;
  mode: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  product?: InputMaybe<ProductCreateNestedOneWithoutWorkshopsInput>;
  promoCodes?: InputMaybe<PromoCodeCreateNestedManyWithoutWorkshopsInput>;
  relatedActivities?: InputMaybe<ActivityCreateNestedManyWithoutRelatedWorkshopsInput>;
  school: SchoolCreateNestedOneWithoutWorkshopsInput;
  skill: SkillCreateNestedOneWithoutWorkshopsInput;
  slug: Scalars["String"]["input"];
  startDate: Scalars["DateTime"]["input"];
  tags?: InputMaybe<TagCreateNestedManyWithoutWorkshopsInput>;
  trainer?: InputMaybe<UserCreateNestedOneWithoutWorkshopsInput>;
  trainerImage?: InputMaybe<Scalars["String"]["input"]>;
  type: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  workshopRegistrations?: InputMaybe<WorkshopRegistrationCreateNestedManyWithoutWorkshopInput>;
};

export type WorkshopCreateWithoutTagsInput = {
  address?: InputMaybe<Scalars["String"]["input"]>;
  ageGroup?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  displayTime?: InputMaybe<Scalars["String"]["input"]>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  featuredImage?: InputMaybe<Scalars["String"]["input"]>;
  feesInCurrency?: InputMaybe<Scalars["Int"]["input"]>;
  feesWithKit?: InputMaybe<Scalars["Int"]["input"]>;
  feesWithoutKit?: InputMaybe<Scalars["Int"]["input"]>;
  footerImageLarge?: InputMaybe<Scalars["String"]["input"]>;
  footerImageSmall?: InputMaybe<Scalars["String"]["input"]>;
  headerImageLarge?: InputMaybe<Scalars["String"]["input"]>;
  headerImageSmall?: InputMaybe<Scalars["String"]["input"]>;
  headerText?: InputMaybe<Scalars["String"]["input"]>;
  headerVideo?: InputMaybe<Scalars["String"]["input"]>;
  image?: InputMaybe<Scalars["String"]["input"]>;
  insiderActivities?: InputMaybe<ActivityCreateNestedManyWithoutInsideWorkshopsInput>;
  isOpen?: InputMaybe<Scalars["Boolean"]["input"]>;
  liveUrl?: InputMaybe<Scalars["String"]["input"]>;
  location?: InputMaybe<Scalars["String"]["input"]>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  metaTagData?: InputMaybe<Scalars["JSON"]["input"]>;
  mode: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  product?: InputMaybe<ProductCreateNestedOneWithoutWorkshopsInput>;
  promoCodes?: InputMaybe<PromoCodeCreateNestedManyWithoutWorkshopsInput>;
  relatedActivities?: InputMaybe<ActivityCreateNestedManyWithoutRelatedWorkshopsInput>;
  school: SchoolCreateNestedOneWithoutWorkshopsInput;
  skill: SkillCreateNestedOneWithoutWorkshopsInput;
  slug: Scalars["String"]["input"];
  standards?: InputMaybe<StandardCreateNestedManyWithoutWorkshopsInput>;
  startDate: Scalars["DateTime"]["input"];
  trainer?: InputMaybe<UserCreateNestedOneWithoutWorkshopsInput>;
  trainerImage?: InputMaybe<Scalars["String"]["input"]>;
  type: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  workshopRegistrations?: InputMaybe<WorkshopRegistrationCreateNestedManyWithoutWorkshopInput>;
};

export type WorkshopCreateWithoutTrainerInput = {
  address?: InputMaybe<Scalars["String"]["input"]>;
  ageGroup?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  displayTime?: InputMaybe<Scalars["String"]["input"]>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  featuredImage?: InputMaybe<Scalars["String"]["input"]>;
  feesInCurrency?: InputMaybe<Scalars["Int"]["input"]>;
  feesWithKit?: InputMaybe<Scalars["Int"]["input"]>;
  feesWithoutKit?: InputMaybe<Scalars["Int"]["input"]>;
  footerImageLarge?: InputMaybe<Scalars["String"]["input"]>;
  footerImageSmall?: InputMaybe<Scalars["String"]["input"]>;
  headerImageLarge?: InputMaybe<Scalars["String"]["input"]>;
  headerImageSmall?: InputMaybe<Scalars["String"]["input"]>;
  headerText?: InputMaybe<Scalars["String"]["input"]>;
  headerVideo?: InputMaybe<Scalars["String"]["input"]>;
  image?: InputMaybe<Scalars["String"]["input"]>;
  insiderActivities?: InputMaybe<ActivityCreateNestedManyWithoutInsideWorkshopsInput>;
  isOpen?: InputMaybe<Scalars["Boolean"]["input"]>;
  liveUrl?: InputMaybe<Scalars["String"]["input"]>;
  location?: InputMaybe<Scalars["String"]["input"]>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  metaTagData?: InputMaybe<Scalars["JSON"]["input"]>;
  mode: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  product?: InputMaybe<ProductCreateNestedOneWithoutWorkshopsInput>;
  promoCodes?: InputMaybe<PromoCodeCreateNestedManyWithoutWorkshopsInput>;
  relatedActivities?: InputMaybe<ActivityCreateNestedManyWithoutRelatedWorkshopsInput>;
  school: SchoolCreateNestedOneWithoutWorkshopsInput;
  skill: SkillCreateNestedOneWithoutWorkshopsInput;
  slug: Scalars["String"]["input"];
  standards?: InputMaybe<StandardCreateNestedManyWithoutWorkshopsInput>;
  startDate: Scalars["DateTime"]["input"];
  tags?: InputMaybe<TagCreateNestedManyWithoutWorkshopsInput>;
  trainerImage?: InputMaybe<Scalars["String"]["input"]>;
  type: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  workshopRegistrations?: InputMaybe<WorkshopRegistrationCreateNestedManyWithoutWorkshopInput>;
};

export type WorkshopCreateWithoutWorkshopRegistrationsInput = {
  address?: InputMaybe<Scalars["String"]["input"]>;
  ageGroup?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  displayTime?: InputMaybe<Scalars["String"]["input"]>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  featuredImage?: InputMaybe<Scalars["String"]["input"]>;
  feesInCurrency?: InputMaybe<Scalars["Int"]["input"]>;
  feesWithKit?: InputMaybe<Scalars["Int"]["input"]>;
  feesWithoutKit?: InputMaybe<Scalars["Int"]["input"]>;
  footerImageLarge?: InputMaybe<Scalars["String"]["input"]>;
  footerImageSmall?: InputMaybe<Scalars["String"]["input"]>;
  headerImageLarge?: InputMaybe<Scalars["String"]["input"]>;
  headerImageSmall?: InputMaybe<Scalars["String"]["input"]>;
  headerText?: InputMaybe<Scalars["String"]["input"]>;
  headerVideo?: InputMaybe<Scalars["String"]["input"]>;
  image?: InputMaybe<Scalars["String"]["input"]>;
  insiderActivities?: InputMaybe<ActivityCreateNestedManyWithoutInsideWorkshopsInput>;
  isOpen?: InputMaybe<Scalars["Boolean"]["input"]>;
  liveUrl?: InputMaybe<Scalars["String"]["input"]>;
  location?: InputMaybe<Scalars["String"]["input"]>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  metaTagData?: InputMaybe<Scalars["JSON"]["input"]>;
  mode: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  product?: InputMaybe<ProductCreateNestedOneWithoutWorkshopsInput>;
  promoCodes?: InputMaybe<PromoCodeCreateNestedManyWithoutWorkshopsInput>;
  relatedActivities?: InputMaybe<ActivityCreateNestedManyWithoutRelatedWorkshopsInput>;
  school: SchoolCreateNestedOneWithoutWorkshopsInput;
  skill: SkillCreateNestedOneWithoutWorkshopsInput;
  slug: Scalars["String"]["input"];
  standards?: InputMaybe<StandardCreateNestedManyWithoutWorkshopsInput>;
  startDate: Scalars["DateTime"]["input"];
  tags?: InputMaybe<TagCreateNestedManyWithoutWorkshopsInput>;
  trainer?: InputMaybe<UserCreateNestedOneWithoutWorkshopsInput>;
  trainerImage?: InputMaybe<Scalars["String"]["input"]>;
  type: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type WorkshopListRelationFilter = {
  every?: InputMaybe<WorkshopWhereInput>;
  none?: InputMaybe<WorkshopWhereInput>;
  some?: InputMaybe<WorkshopWhereInput>;
};

export type WorkshopMaxAggregate = {
  __typename?: "WorkshopMaxAggregate";
  address?: Maybe<Scalars["String"]["output"]>;
  ageGroup?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  displayTime?: Maybe<Scalars["String"]["output"]>;
  endDate?: Maybe<Scalars["DateTime"]["output"]>;
  featuredImage?: Maybe<Scalars["String"]["output"]>;
  feesInCurrency?: Maybe<Scalars["Int"]["output"]>;
  feesWithKit?: Maybe<Scalars["Int"]["output"]>;
  feesWithoutKit?: Maybe<Scalars["Int"]["output"]>;
  footerImageLarge?: Maybe<Scalars["String"]["output"]>;
  footerImageSmall?: Maybe<Scalars["String"]["output"]>;
  headerImageLarge?: Maybe<Scalars["String"]["output"]>;
  headerImageSmall?: Maybe<Scalars["String"]["output"]>;
  headerText?: Maybe<Scalars["String"]["output"]>;
  headerVideo?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  image?: Maybe<Scalars["String"]["output"]>;
  isOpen?: Maybe<Scalars["Boolean"]["output"]>;
  liveUrl?: Maybe<Scalars["String"]["output"]>;
  location?: Maybe<Scalars["String"]["output"]>;
  mode?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  productId?: Maybe<Scalars["Int"]["output"]>;
  schoolId?: Maybe<Scalars["Int"]["output"]>;
  skillId?: Maybe<Scalars["Int"]["output"]>;
  slug?: Maybe<Scalars["String"]["output"]>;
  startDate?: Maybe<Scalars["DateTime"]["output"]>;
  trainerId?: Maybe<Scalars["Int"]["output"]>;
  trainerImage?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<Scalars["String"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type WorkshopMinAggregate = {
  __typename?: "WorkshopMinAggregate";
  address?: Maybe<Scalars["String"]["output"]>;
  ageGroup?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  displayTime?: Maybe<Scalars["String"]["output"]>;
  endDate?: Maybe<Scalars["DateTime"]["output"]>;
  featuredImage?: Maybe<Scalars["String"]["output"]>;
  feesInCurrency?: Maybe<Scalars["Int"]["output"]>;
  feesWithKit?: Maybe<Scalars["Int"]["output"]>;
  feesWithoutKit?: Maybe<Scalars["Int"]["output"]>;
  footerImageLarge?: Maybe<Scalars["String"]["output"]>;
  footerImageSmall?: Maybe<Scalars["String"]["output"]>;
  headerImageLarge?: Maybe<Scalars["String"]["output"]>;
  headerImageSmall?: Maybe<Scalars["String"]["output"]>;
  headerText?: Maybe<Scalars["String"]["output"]>;
  headerVideo?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  image?: Maybe<Scalars["String"]["output"]>;
  isOpen?: Maybe<Scalars["Boolean"]["output"]>;
  liveUrl?: Maybe<Scalars["String"]["output"]>;
  location?: Maybe<Scalars["String"]["output"]>;
  mode?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  productId?: Maybe<Scalars["Int"]["output"]>;
  schoolId?: Maybe<Scalars["Int"]["output"]>;
  skillId?: Maybe<Scalars["Int"]["output"]>;
  slug?: Maybe<Scalars["String"]["output"]>;
  startDate?: Maybe<Scalars["DateTime"]["output"]>;
  trainerId?: Maybe<Scalars["Int"]["output"]>;
  trainerImage?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<Scalars["String"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type WorkshopOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type WorkshopOrderByWithRelationInput = {
  address?: InputMaybe<SortOrderInput>;
  ageGroup?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrderInput>;
  displayTime?: InputMaybe<SortOrderInput>;
  endDate?: InputMaybe<SortOrderInput>;
  featuredImage?: InputMaybe<SortOrderInput>;
  feesInCurrency?: InputMaybe<SortOrderInput>;
  feesWithKit?: InputMaybe<SortOrderInput>;
  feesWithoutKit?: InputMaybe<SortOrderInput>;
  footerImageLarge?: InputMaybe<SortOrderInput>;
  footerImageSmall?: InputMaybe<SortOrderInput>;
  headerImageLarge?: InputMaybe<SortOrderInput>;
  headerImageSmall?: InputMaybe<SortOrderInput>;
  headerText?: InputMaybe<SortOrderInput>;
  headerVideo?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  image?: InputMaybe<SortOrderInput>;
  insiderActivities?: InputMaybe<ActivityOrderByRelationAggregateInput>;
  isOpen?: InputMaybe<SortOrder>;
  liveUrl?: InputMaybe<SortOrderInput>;
  location?: InputMaybe<SortOrderInput>;
  meta?: InputMaybe<SortOrderInput>;
  metaTagData?: InputMaybe<SortOrderInput>;
  mode?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  product?: InputMaybe<ProductOrderByWithRelationInput>;
  productId?: InputMaybe<SortOrderInput>;
  promoCodes?: InputMaybe<PromoCodeOrderByRelationAggregateInput>;
  relatedActivities?: InputMaybe<ActivityOrderByRelationAggregateInput>;
  school?: InputMaybe<SchoolOrderByWithRelationInput>;
  schoolId?: InputMaybe<SortOrder>;
  skill?: InputMaybe<SkillOrderByWithRelationInput>;
  skillId?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  standards?: InputMaybe<StandardOrderByRelationAggregateInput>;
  startDate?: InputMaybe<SortOrder>;
  tags?: InputMaybe<TagOrderByRelationAggregateInput>;
  trainer?: InputMaybe<UserOrderByWithRelationInput>;
  trainerId?: InputMaybe<SortOrderInput>;
  trainerImage?: InputMaybe<SortOrderInput>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  workshopRegistrations?: InputMaybe<WorkshopRegistrationOrderByRelationAggregateInput>;
};

export type WorkshopRegistration = {
  __typename?: "WorkshopRegistration";
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["Int"]["output"];
  order?: Maybe<Order>;
  orderId?: Maybe<Scalars["Int"]["output"]>;
  status: WorkshopStatus;
  student: Student;
  studentId: Scalars["Int"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
  workshop: Workshop;
  workshopId: Scalars["Int"]["output"];
};

export type WorkshopRegistrationAvgAggregate = {
  __typename?: "WorkshopRegistrationAvgAggregate";
  id?: Maybe<Scalars["Float"]["output"]>;
  orderId?: Maybe<Scalars["Float"]["output"]>;
  studentId?: Maybe<Scalars["Float"]["output"]>;
  workshopId?: Maybe<Scalars["Float"]["output"]>;
};

export type WorkshopRegistrationCountAggregate = {
  __typename?: "WorkshopRegistrationCountAggregate";
  _all: Scalars["Int"]["output"];
  createdAt: Scalars["Int"]["output"];
  id: Scalars["Int"]["output"];
  orderId: Scalars["Int"]["output"];
  status: Scalars["Int"]["output"];
  studentId: Scalars["Int"]["output"];
  updatedAt: Scalars["Int"]["output"];
  workshopId: Scalars["Int"]["output"];
};

export type WorkshopRegistrationCreateInput = {
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  order?: InputMaybe<OrderCreateNestedOneWithoutWorkshopRegistrationInput>;
  status?: InputMaybe<WorkshopStatus>;
  student: StudentCreateNestedOneWithoutWorkshopRegistrationsInput;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  workshop: WorkshopCreateNestedOneWithoutWorkshopRegistrationsInput;
};

export type WorkshopRegistrationCreateManyStudentInput = {
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  orderId?: InputMaybe<Scalars["Int"]["input"]>;
  status?: InputMaybe<WorkshopStatus>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  workshopId: Scalars["Int"]["input"];
};

export type WorkshopRegistrationCreateManyStudentInputEnvelope = {
  data: Array<WorkshopRegistrationCreateManyStudentInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type WorkshopRegistrationCreateManyWorkshopInput = {
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  orderId?: InputMaybe<Scalars["Int"]["input"]>;
  status?: InputMaybe<WorkshopStatus>;
  studentId: Scalars["Int"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type WorkshopRegistrationCreateManyWorkshopInputEnvelope = {
  data: Array<WorkshopRegistrationCreateManyWorkshopInput>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type WorkshopRegistrationCreateNestedManyWithoutStudentInput = {
  connect?: InputMaybe<Array<WorkshopRegistrationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<WorkshopRegistrationCreateOrConnectWithoutStudentInput>>;
  create?: InputMaybe<Array<WorkshopRegistrationCreateWithoutStudentInput>>;
  createMany?: InputMaybe<WorkshopRegistrationCreateManyStudentInputEnvelope>;
};

export type WorkshopRegistrationCreateNestedManyWithoutWorkshopInput = {
  connect?: InputMaybe<Array<WorkshopRegistrationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<WorkshopRegistrationCreateOrConnectWithoutWorkshopInput>>;
  create?: InputMaybe<Array<WorkshopRegistrationCreateWithoutWorkshopInput>>;
  createMany?: InputMaybe<WorkshopRegistrationCreateManyWorkshopInputEnvelope>;
};

export type WorkshopRegistrationCreateNestedOneWithoutOrderInput = {
  connect?: InputMaybe<WorkshopRegistrationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<WorkshopRegistrationCreateOrConnectWithoutOrderInput>;
  create?: InputMaybe<WorkshopRegistrationCreateWithoutOrderInput>;
};

export type WorkshopRegistrationCreateOrConnectWithoutOrderInput = {
  create: WorkshopRegistrationCreateWithoutOrderInput;
  where: WorkshopRegistrationWhereUniqueInput;
};

export type WorkshopRegistrationCreateOrConnectWithoutStudentInput = {
  create: WorkshopRegistrationCreateWithoutStudentInput;
  where: WorkshopRegistrationWhereUniqueInput;
};

export type WorkshopRegistrationCreateOrConnectWithoutWorkshopInput = {
  create: WorkshopRegistrationCreateWithoutWorkshopInput;
  where: WorkshopRegistrationWhereUniqueInput;
};

export type WorkshopRegistrationCreateWithoutOrderInput = {
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<WorkshopStatus>;
  student: StudentCreateNestedOneWithoutWorkshopRegistrationsInput;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  workshop: WorkshopCreateNestedOneWithoutWorkshopRegistrationsInput;
};

export type WorkshopRegistrationCreateWithoutStudentInput = {
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  order?: InputMaybe<OrderCreateNestedOneWithoutWorkshopRegistrationInput>;
  status?: InputMaybe<WorkshopStatus>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  workshop: WorkshopCreateNestedOneWithoutWorkshopRegistrationsInput;
};

export type WorkshopRegistrationCreateWithoutWorkshopInput = {
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  order?: InputMaybe<OrderCreateNestedOneWithoutWorkshopRegistrationInput>;
  status?: InputMaybe<WorkshopStatus>;
  student: StudentCreateNestedOneWithoutWorkshopRegistrationsInput;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type WorkshopRegistrationListRelationFilter = {
  every?: InputMaybe<WorkshopRegistrationWhereInput>;
  none?: InputMaybe<WorkshopRegistrationWhereInput>;
  some?: InputMaybe<WorkshopRegistrationWhereInput>;
};

export type WorkshopRegistrationMaxAggregate = {
  __typename?: "WorkshopRegistrationMaxAggregate";
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  orderId?: Maybe<Scalars["Int"]["output"]>;
  status?: Maybe<WorkshopStatus>;
  studentId?: Maybe<Scalars["Int"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
  workshopId?: Maybe<Scalars["Int"]["output"]>;
};

export type WorkshopRegistrationMinAggregate = {
  __typename?: "WorkshopRegistrationMinAggregate";
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  orderId?: Maybe<Scalars["Int"]["output"]>;
  status?: Maybe<WorkshopStatus>;
  studentId?: Maybe<Scalars["Int"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
  workshopId?: Maybe<Scalars["Int"]["output"]>;
};

export type WorkshopRegistrationOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type WorkshopRegistrationOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  order?: InputMaybe<OrderOrderByWithRelationInput>;
  orderId?: InputMaybe<SortOrderInput>;
  status?: InputMaybe<SortOrder>;
  student?: InputMaybe<StudentOrderByWithRelationInput>;
  studentId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  workshop?: InputMaybe<WorkshopOrderByWithRelationInput>;
  workshopId?: InputMaybe<SortOrder>;
};

export type WorkshopRegistrationRelationFilter = {
  is?: InputMaybe<WorkshopRegistrationWhereInput>;
  isNot?: InputMaybe<WorkshopRegistrationWhereInput>;
};

export enum WorkshopRegistrationScalarFieldEnum {
  CREATEDAT = "createdAt",
  ID = "id",
  ORDERID = "orderId",
  STATUS = "status",
  STUDENTID = "studentId",
  UPDATEDAT = "updatedAt",
  WORKSHOPID = "workshopId"
}

export type WorkshopRegistrationScalarWhereInput = {
  AND?: InputMaybe<Array<WorkshopRegistrationScalarWhereInput>>;
  NOT?: InputMaybe<Array<WorkshopRegistrationScalarWhereInput>>;
  OR?: InputMaybe<Array<WorkshopRegistrationScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  orderId?: InputMaybe<IntNullableFilter>;
  status?: InputMaybe<EnumWorkshopStatusFilter>;
  studentId?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  workshopId?: InputMaybe<IntFilter>;
};

export type WorkshopRegistrationSumAggregate = {
  __typename?: "WorkshopRegistrationSumAggregate";
  id?: Maybe<Scalars["Int"]["output"]>;
  orderId?: Maybe<Scalars["Int"]["output"]>;
  studentId?: Maybe<Scalars["Int"]["output"]>;
  workshopId?: Maybe<Scalars["Int"]["output"]>;
};

export type WorkshopRegistrationUpdateInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  order?: InputMaybe<OrderUpdateOneWithoutWorkshopRegistrationNestedInput>;
  status?: InputMaybe<EnumWorkshopStatusFieldUpdateOperationsInput>;
  student?: InputMaybe<StudentUpdateOneRequiredWithoutWorkshopRegistrationsNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  workshop?: InputMaybe<WorkshopUpdateOneRequiredWithoutWorkshopRegistrationsNestedInput>;
};

export type WorkshopRegistrationUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumWorkshopStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type WorkshopRegistrationUpdateManyWithWhereWithoutStudentInput = {
  data: WorkshopRegistrationUpdateManyMutationInput;
  where: WorkshopRegistrationScalarWhereInput;
};

export type WorkshopRegistrationUpdateManyWithWhereWithoutWorkshopInput = {
  data: WorkshopRegistrationUpdateManyMutationInput;
  where: WorkshopRegistrationScalarWhereInput;
};

export type WorkshopRegistrationUpdateManyWithoutStudentNestedInput = {
  connect?: InputMaybe<Array<WorkshopRegistrationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<WorkshopRegistrationCreateOrConnectWithoutStudentInput>>;
  create?: InputMaybe<Array<WorkshopRegistrationCreateWithoutStudentInput>>;
  createMany?: InputMaybe<WorkshopRegistrationCreateManyStudentInputEnvelope>;
  delete?: InputMaybe<Array<WorkshopRegistrationWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<WorkshopRegistrationScalarWhereInput>>;
  disconnect?: InputMaybe<Array<WorkshopRegistrationWhereUniqueInput>>;
  set?: InputMaybe<Array<WorkshopRegistrationWhereUniqueInput>>;
  update?: InputMaybe<Array<WorkshopRegistrationUpdateWithWhereUniqueWithoutStudentInput>>;
  updateMany?: InputMaybe<Array<WorkshopRegistrationUpdateManyWithWhereWithoutStudentInput>>;
  upsert?: InputMaybe<Array<WorkshopRegistrationUpsertWithWhereUniqueWithoutStudentInput>>;
};

export type WorkshopRegistrationUpdateManyWithoutWorkshopNestedInput = {
  connect?: InputMaybe<Array<WorkshopRegistrationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<WorkshopRegistrationCreateOrConnectWithoutWorkshopInput>>;
  create?: InputMaybe<Array<WorkshopRegistrationCreateWithoutWorkshopInput>>;
  createMany?: InputMaybe<WorkshopRegistrationCreateManyWorkshopInputEnvelope>;
  delete?: InputMaybe<Array<WorkshopRegistrationWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<WorkshopRegistrationScalarWhereInput>>;
  disconnect?: InputMaybe<Array<WorkshopRegistrationWhereUniqueInput>>;
  set?: InputMaybe<Array<WorkshopRegistrationWhereUniqueInput>>;
  update?: InputMaybe<Array<WorkshopRegistrationUpdateWithWhereUniqueWithoutWorkshopInput>>;
  updateMany?: InputMaybe<Array<WorkshopRegistrationUpdateManyWithWhereWithoutWorkshopInput>>;
  upsert?: InputMaybe<Array<WorkshopRegistrationUpsertWithWhereUniqueWithoutWorkshopInput>>;
};

export type WorkshopRegistrationUpdateOneWithoutOrderNestedInput = {
  connect?: InputMaybe<WorkshopRegistrationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<WorkshopRegistrationCreateOrConnectWithoutOrderInput>;
  create?: InputMaybe<WorkshopRegistrationCreateWithoutOrderInput>;
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  update?: InputMaybe<WorkshopRegistrationUpdateWithoutOrderInput>;
  upsert?: InputMaybe<WorkshopRegistrationUpsertWithoutOrderInput>;
};

export type WorkshopRegistrationUpdateWithWhereUniqueWithoutStudentInput = {
  data: WorkshopRegistrationUpdateWithoutStudentInput;
  where: WorkshopRegistrationWhereUniqueInput;
};

export type WorkshopRegistrationUpdateWithWhereUniqueWithoutWorkshopInput = {
  data: WorkshopRegistrationUpdateWithoutWorkshopInput;
  where: WorkshopRegistrationWhereUniqueInput;
};

export type WorkshopRegistrationUpdateWithoutOrderInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumWorkshopStatusFieldUpdateOperationsInput>;
  student?: InputMaybe<StudentUpdateOneRequiredWithoutWorkshopRegistrationsNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  workshop?: InputMaybe<WorkshopUpdateOneRequiredWithoutWorkshopRegistrationsNestedInput>;
};

export type WorkshopRegistrationUpdateWithoutStudentInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  order?: InputMaybe<OrderUpdateOneWithoutWorkshopRegistrationNestedInput>;
  status?: InputMaybe<EnumWorkshopStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  workshop?: InputMaybe<WorkshopUpdateOneRequiredWithoutWorkshopRegistrationsNestedInput>;
};

export type WorkshopRegistrationUpdateWithoutWorkshopInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  order?: InputMaybe<OrderUpdateOneWithoutWorkshopRegistrationNestedInput>;
  status?: InputMaybe<EnumWorkshopStatusFieldUpdateOperationsInput>;
  student?: InputMaybe<StudentUpdateOneRequiredWithoutWorkshopRegistrationsNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type WorkshopRegistrationUpsertWithWhereUniqueWithoutStudentInput = {
  create: WorkshopRegistrationCreateWithoutStudentInput;
  update: WorkshopRegistrationUpdateWithoutStudentInput;
  where: WorkshopRegistrationWhereUniqueInput;
};

export type WorkshopRegistrationUpsertWithWhereUniqueWithoutWorkshopInput = {
  create: WorkshopRegistrationCreateWithoutWorkshopInput;
  update: WorkshopRegistrationUpdateWithoutWorkshopInput;
  where: WorkshopRegistrationWhereUniqueInput;
};

export type WorkshopRegistrationUpsertWithoutOrderInput = {
  create: WorkshopRegistrationCreateWithoutOrderInput;
  update: WorkshopRegistrationUpdateWithoutOrderInput;
};

export type WorkshopRegistrationWhereInput = {
  AND?: InputMaybe<Array<WorkshopRegistrationWhereInput>>;
  NOT?: InputMaybe<Array<WorkshopRegistrationWhereInput>>;
  OR?: InputMaybe<Array<WorkshopRegistrationWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  order?: InputMaybe<OrderRelationFilter>;
  orderId?: InputMaybe<IntNullableFilter>;
  status?: InputMaybe<EnumWorkshopStatusFilter>;
  student?: InputMaybe<StudentRelationFilter>;
  studentId?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  workshop?: InputMaybe<WorkshopRelationFilter>;
  workshopId?: InputMaybe<IntFilter>;
};

export type WorkshopRegistrationWhereUniqueInput = {
  id?: InputMaybe<Scalars["Int"]["input"]>;
  orderId?: InputMaybe<Scalars["Int"]["input"]>;
};

export type WorkshopRelationFilter = {
  is?: InputMaybe<WorkshopWhereInput>;
  isNot?: InputMaybe<WorkshopWhereInput>;
};

export enum WorkshopScalarFieldEnum {
  ADDRESS = "address",
  AGEGROUP = "ageGroup",
  CREATEDAT = "createdAt",
  DESCRIPTION = "description",
  DISPLAYTIME = "displayTime",
  ENDDATE = "endDate",
  FEATUREDIMAGE = "featuredImage",
  FEESINCURRENCY = "feesInCurrency",
  FEESWITHKIT = "feesWithKit",
  FEESWITHOUTKIT = "feesWithoutKit",
  FOOTERIMAGELARGE = "footerImageLarge",
  FOOTERIMAGESMALL = "footerImageSmall",
  HEADERIMAGELARGE = "headerImageLarge",
  HEADERIMAGESMALL = "headerImageSmall",
  HEADERTEXT = "headerText",
  HEADERVIDEO = "headerVideo",
  ID = "id",
  IMAGE = "image",
  ISOPEN = "isOpen",
  LIVEURL = "liveUrl",
  LOCATION = "location",
  META = "meta",
  METATAGDATA = "metaTagData",
  MODE = "mode",
  NAME = "name",
  PRODUCTID = "productId",
  SCHOOLID = "schoolId",
  SKILLID = "skillId",
  SLUG = "slug",
  STARTDATE = "startDate",
  TRAINERID = "trainerId",
  TRAINERIMAGE = "trainerImage",
  TYPE = "type",
  UPDATEDAT = "updatedAt"
}

export type WorkshopScalarWhereInput = {
  AND?: InputMaybe<Array<WorkshopScalarWhereInput>>;
  NOT?: InputMaybe<Array<WorkshopScalarWhereInput>>;
  OR?: InputMaybe<Array<WorkshopScalarWhereInput>>;
  address?: InputMaybe<StringNullableFilter>;
  ageGroup?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  displayTime?: InputMaybe<StringNullableFilter>;
  endDate?: InputMaybe<DateTimeNullableFilter>;
  featuredImage?: InputMaybe<StringNullableFilter>;
  feesInCurrency?: InputMaybe<IntNullableFilter>;
  feesWithKit?: InputMaybe<IntNullableFilter>;
  feesWithoutKit?: InputMaybe<IntNullableFilter>;
  footerImageLarge?: InputMaybe<StringNullableFilter>;
  footerImageSmall?: InputMaybe<StringNullableFilter>;
  headerImageLarge?: InputMaybe<StringNullableFilter>;
  headerImageSmall?: InputMaybe<StringNullableFilter>;
  headerText?: InputMaybe<StringNullableFilter>;
  headerVideo?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  image?: InputMaybe<StringNullableFilter>;
  isOpen?: InputMaybe<BoolFilter>;
  liveUrl?: InputMaybe<StringNullableFilter>;
  location?: InputMaybe<StringNullableFilter>;
  meta?: InputMaybe<JsonNullableFilter>;
  metaTagData?: InputMaybe<JsonNullableFilter>;
  mode?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  productId?: InputMaybe<IntNullableFilter>;
  schoolId?: InputMaybe<IntFilter>;
  skillId?: InputMaybe<IntFilter>;
  slug?: InputMaybe<StringFilter>;
  startDate?: InputMaybe<DateTimeFilter>;
  trainerId?: InputMaybe<IntNullableFilter>;
  trainerImage?: InputMaybe<StringNullableFilter>;
  type?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export enum WorkshopStatus {
  CONFIRMED = "CONFIRMED",
  PENDING = "PENDING",
  REJECTED = "REJECTED"
}

export type WorkshopSumAggregate = {
  __typename?: "WorkshopSumAggregate";
  feesInCurrency?: Maybe<Scalars["Int"]["output"]>;
  feesWithKit?: Maybe<Scalars["Int"]["output"]>;
  feesWithoutKit?: Maybe<Scalars["Int"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  productId?: Maybe<Scalars["Int"]["output"]>;
  schoolId?: Maybe<Scalars["Int"]["output"]>;
  skillId?: Maybe<Scalars["Int"]["output"]>;
  trainerId?: Maybe<Scalars["Int"]["output"]>;
};

export type WorkshopUpdateInput = {
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ageGroup?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  displayTime?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  endDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  feesInCurrency?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  feesWithKit?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  feesWithoutKit?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  footerImageLarge?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  footerImageSmall?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headerImageLarge?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headerImageSmall?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headerText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headerVideo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  insiderActivities?: InputMaybe<ActivityUpdateManyWithoutInsideWorkshopsNestedInput>;
  isOpen?: InputMaybe<BoolFieldUpdateOperationsInput>;
  liveUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  location?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  metaTagData?: InputMaybe<Scalars["JSON"]["input"]>;
  mode?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  product?: InputMaybe<ProductUpdateOneWithoutWorkshopsNestedInput>;
  promoCodes?: InputMaybe<PromoCodeUpdateManyWithoutWorkshopsNestedInput>;
  relatedActivities?: InputMaybe<ActivityUpdateManyWithoutRelatedWorkshopsNestedInput>;
  school?: InputMaybe<SchoolUpdateOneRequiredWithoutWorkshopsNestedInput>;
  skill?: InputMaybe<SkillUpdateOneRequiredWithoutWorkshopsNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  standards?: InputMaybe<StandardUpdateManyWithoutWorkshopsNestedInput>;
  startDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  tags?: InputMaybe<TagUpdateManyWithoutWorkshopsNestedInput>;
  trainer?: InputMaybe<UserUpdateOneWithoutWorkshopsNestedInput>;
  trainerImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  workshopRegistrations?: InputMaybe<WorkshopRegistrationUpdateManyWithoutWorkshopNestedInput>;
};

export type WorkshopUpdateManyMutationInput = {
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ageGroup?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  displayTime?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  endDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  feesInCurrency?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  feesWithKit?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  feesWithoutKit?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  footerImageLarge?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  footerImageSmall?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headerImageLarge?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headerImageSmall?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headerText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headerVideo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isOpen?: InputMaybe<BoolFieldUpdateOperationsInput>;
  liveUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  location?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  metaTagData?: InputMaybe<Scalars["JSON"]["input"]>;
  mode?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  startDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  trainerImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type WorkshopUpdateManyWithWhereWithoutInsiderActivitiesInput = {
  data: WorkshopUpdateManyMutationInput;
  where: WorkshopScalarWhereInput;
};

export type WorkshopUpdateManyWithWhereWithoutProductInput = {
  data: WorkshopUpdateManyMutationInput;
  where: WorkshopScalarWhereInput;
};

export type WorkshopUpdateManyWithWhereWithoutPromoCodesInput = {
  data: WorkshopUpdateManyMutationInput;
  where: WorkshopScalarWhereInput;
};

export type WorkshopUpdateManyWithWhereWithoutRelatedActivitiesInput = {
  data: WorkshopUpdateManyMutationInput;
  where: WorkshopScalarWhereInput;
};

export type WorkshopUpdateManyWithWhereWithoutSchoolInput = {
  data: WorkshopUpdateManyMutationInput;
  where: WorkshopScalarWhereInput;
};

export type WorkshopUpdateManyWithWhereWithoutSkillInput = {
  data: WorkshopUpdateManyMutationInput;
  where: WorkshopScalarWhereInput;
};

export type WorkshopUpdateManyWithWhereWithoutStandardsInput = {
  data: WorkshopUpdateManyMutationInput;
  where: WorkshopScalarWhereInput;
};

export type WorkshopUpdateManyWithWhereWithoutTagsInput = {
  data: WorkshopUpdateManyMutationInput;
  where: WorkshopScalarWhereInput;
};

export type WorkshopUpdateManyWithWhereWithoutTrainerInput = {
  data: WorkshopUpdateManyMutationInput;
  where: WorkshopScalarWhereInput;
};

export type WorkshopUpdateManyWithoutInsiderActivitiesNestedInput = {
  connect?: InputMaybe<Array<WorkshopWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<WorkshopCreateOrConnectWithoutInsiderActivitiesInput>>;
  create?: InputMaybe<Array<WorkshopCreateWithoutInsiderActivitiesInput>>;
  delete?: InputMaybe<Array<WorkshopWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<WorkshopScalarWhereInput>>;
  disconnect?: InputMaybe<Array<WorkshopWhereUniqueInput>>;
  set?: InputMaybe<Array<WorkshopWhereUniqueInput>>;
  update?: InputMaybe<Array<WorkshopUpdateWithWhereUniqueWithoutInsiderActivitiesInput>>;
  updateMany?: InputMaybe<Array<WorkshopUpdateManyWithWhereWithoutInsiderActivitiesInput>>;
  upsert?: InputMaybe<Array<WorkshopUpsertWithWhereUniqueWithoutInsiderActivitiesInput>>;
};

export type WorkshopUpdateManyWithoutProductNestedInput = {
  connect?: InputMaybe<Array<WorkshopWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<WorkshopCreateOrConnectWithoutProductInput>>;
  create?: InputMaybe<Array<WorkshopCreateWithoutProductInput>>;
  createMany?: InputMaybe<WorkshopCreateManyProductInputEnvelope>;
  delete?: InputMaybe<Array<WorkshopWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<WorkshopScalarWhereInput>>;
  disconnect?: InputMaybe<Array<WorkshopWhereUniqueInput>>;
  set?: InputMaybe<Array<WorkshopWhereUniqueInput>>;
  update?: InputMaybe<Array<WorkshopUpdateWithWhereUniqueWithoutProductInput>>;
  updateMany?: InputMaybe<Array<WorkshopUpdateManyWithWhereWithoutProductInput>>;
  upsert?: InputMaybe<Array<WorkshopUpsertWithWhereUniqueWithoutProductInput>>;
};

export type WorkshopUpdateManyWithoutPromoCodesNestedInput = {
  connect?: InputMaybe<Array<WorkshopWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<WorkshopCreateOrConnectWithoutPromoCodesInput>>;
  create?: InputMaybe<Array<WorkshopCreateWithoutPromoCodesInput>>;
  delete?: InputMaybe<Array<WorkshopWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<WorkshopScalarWhereInput>>;
  disconnect?: InputMaybe<Array<WorkshopWhereUniqueInput>>;
  set?: InputMaybe<Array<WorkshopWhereUniqueInput>>;
  update?: InputMaybe<Array<WorkshopUpdateWithWhereUniqueWithoutPromoCodesInput>>;
  updateMany?: InputMaybe<Array<WorkshopUpdateManyWithWhereWithoutPromoCodesInput>>;
  upsert?: InputMaybe<Array<WorkshopUpsertWithWhereUniqueWithoutPromoCodesInput>>;
};

export type WorkshopUpdateManyWithoutRelatedActivitiesNestedInput = {
  connect?: InputMaybe<Array<WorkshopWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<WorkshopCreateOrConnectWithoutRelatedActivitiesInput>>;
  create?: InputMaybe<Array<WorkshopCreateWithoutRelatedActivitiesInput>>;
  delete?: InputMaybe<Array<WorkshopWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<WorkshopScalarWhereInput>>;
  disconnect?: InputMaybe<Array<WorkshopWhereUniqueInput>>;
  set?: InputMaybe<Array<WorkshopWhereUniqueInput>>;
  update?: InputMaybe<Array<WorkshopUpdateWithWhereUniqueWithoutRelatedActivitiesInput>>;
  updateMany?: InputMaybe<Array<WorkshopUpdateManyWithWhereWithoutRelatedActivitiesInput>>;
  upsert?: InputMaybe<Array<WorkshopUpsertWithWhereUniqueWithoutRelatedActivitiesInput>>;
};

export type WorkshopUpdateManyWithoutSchoolNestedInput = {
  connect?: InputMaybe<Array<WorkshopWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<WorkshopCreateOrConnectWithoutSchoolInput>>;
  create?: InputMaybe<Array<WorkshopCreateWithoutSchoolInput>>;
  createMany?: InputMaybe<WorkshopCreateManySchoolInputEnvelope>;
  delete?: InputMaybe<Array<WorkshopWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<WorkshopScalarWhereInput>>;
  disconnect?: InputMaybe<Array<WorkshopWhereUniqueInput>>;
  set?: InputMaybe<Array<WorkshopWhereUniqueInput>>;
  update?: InputMaybe<Array<WorkshopUpdateWithWhereUniqueWithoutSchoolInput>>;
  updateMany?: InputMaybe<Array<WorkshopUpdateManyWithWhereWithoutSchoolInput>>;
  upsert?: InputMaybe<Array<WorkshopUpsertWithWhereUniqueWithoutSchoolInput>>;
};

export type WorkshopUpdateManyWithoutSkillNestedInput = {
  connect?: InputMaybe<Array<WorkshopWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<WorkshopCreateOrConnectWithoutSkillInput>>;
  create?: InputMaybe<Array<WorkshopCreateWithoutSkillInput>>;
  createMany?: InputMaybe<WorkshopCreateManySkillInputEnvelope>;
  delete?: InputMaybe<Array<WorkshopWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<WorkshopScalarWhereInput>>;
  disconnect?: InputMaybe<Array<WorkshopWhereUniqueInput>>;
  set?: InputMaybe<Array<WorkshopWhereUniqueInput>>;
  update?: InputMaybe<Array<WorkshopUpdateWithWhereUniqueWithoutSkillInput>>;
  updateMany?: InputMaybe<Array<WorkshopUpdateManyWithWhereWithoutSkillInput>>;
  upsert?: InputMaybe<Array<WorkshopUpsertWithWhereUniqueWithoutSkillInput>>;
};

export type WorkshopUpdateManyWithoutStandardsNestedInput = {
  connect?: InputMaybe<Array<WorkshopWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<WorkshopCreateOrConnectWithoutStandardsInput>>;
  create?: InputMaybe<Array<WorkshopCreateWithoutStandardsInput>>;
  delete?: InputMaybe<Array<WorkshopWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<WorkshopScalarWhereInput>>;
  disconnect?: InputMaybe<Array<WorkshopWhereUniqueInput>>;
  set?: InputMaybe<Array<WorkshopWhereUniqueInput>>;
  update?: InputMaybe<Array<WorkshopUpdateWithWhereUniqueWithoutStandardsInput>>;
  updateMany?: InputMaybe<Array<WorkshopUpdateManyWithWhereWithoutStandardsInput>>;
  upsert?: InputMaybe<Array<WorkshopUpsertWithWhereUniqueWithoutStandardsInput>>;
};

export type WorkshopUpdateManyWithoutTagsNestedInput = {
  connect?: InputMaybe<Array<WorkshopWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<WorkshopCreateOrConnectWithoutTagsInput>>;
  create?: InputMaybe<Array<WorkshopCreateWithoutTagsInput>>;
  delete?: InputMaybe<Array<WorkshopWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<WorkshopScalarWhereInput>>;
  disconnect?: InputMaybe<Array<WorkshopWhereUniqueInput>>;
  set?: InputMaybe<Array<WorkshopWhereUniqueInput>>;
  update?: InputMaybe<Array<WorkshopUpdateWithWhereUniqueWithoutTagsInput>>;
  updateMany?: InputMaybe<Array<WorkshopUpdateManyWithWhereWithoutTagsInput>>;
  upsert?: InputMaybe<Array<WorkshopUpsertWithWhereUniqueWithoutTagsInput>>;
};

export type WorkshopUpdateManyWithoutTrainerNestedInput = {
  connect?: InputMaybe<Array<WorkshopWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<WorkshopCreateOrConnectWithoutTrainerInput>>;
  create?: InputMaybe<Array<WorkshopCreateWithoutTrainerInput>>;
  createMany?: InputMaybe<WorkshopCreateManyTrainerInputEnvelope>;
  delete?: InputMaybe<Array<WorkshopWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<WorkshopScalarWhereInput>>;
  disconnect?: InputMaybe<Array<WorkshopWhereUniqueInput>>;
  set?: InputMaybe<Array<WorkshopWhereUniqueInput>>;
  update?: InputMaybe<Array<WorkshopUpdateWithWhereUniqueWithoutTrainerInput>>;
  updateMany?: InputMaybe<Array<WorkshopUpdateManyWithWhereWithoutTrainerInput>>;
  upsert?: InputMaybe<Array<WorkshopUpsertWithWhereUniqueWithoutTrainerInput>>;
};

export type WorkshopUpdateOneRequiredWithoutWorkshopRegistrationsNestedInput = {
  connect?: InputMaybe<WorkshopWhereUniqueInput>;
  connectOrCreate?: InputMaybe<WorkshopCreateOrConnectWithoutWorkshopRegistrationsInput>;
  create?: InputMaybe<WorkshopCreateWithoutWorkshopRegistrationsInput>;
  update?: InputMaybe<WorkshopUpdateWithoutWorkshopRegistrationsInput>;
  upsert?: InputMaybe<WorkshopUpsertWithoutWorkshopRegistrationsInput>;
};

export type WorkshopUpdateWithWhereUniqueWithoutInsiderActivitiesInput = {
  data: WorkshopUpdateWithoutInsiderActivitiesInput;
  where: WorkshopWhereUniqueInput;
};

export type WorkshopUpdateWithWhereUniqueWithoutProductInput = {
  data: WorkshopUpdateWithoutProductInput;
  where: WorkshopWhereUniqueInput;
};

export type WorkshopUpdateWithWhereUniqueWithoutPromoCodesInput = {
  data: WorkshopUpdateWithoutPromoCodesInput;
  where: WorkshopWhereUniqueInput;
};

export type WorkshopUpdateWithWhereUniqueWithoutRelatedActivitiesInput = {
  data: WorkshopUpdateWithoutRelatedActivitiesInput;
  where: WorkshopWhereUniqueInput;
};

export type WorkshopUpdateWithWhereUniqueWithoutSchoolInput = {
  data: WorkshopUpdateWithoutSchoolInput;
  where: WorkshopWhereUniqueInput;
};

export type WorkshopUpdateWithWhereUniqueWithoutSkillInput = {
  data: WorkshopUpdateWithoutSkillInput;
  where: WorkshopWhereUniqueInput;
};

export type WorkshopUpdateWithWhereUniqueWithoutStandardsInput = {
  data: WorkshopUpdateWithoutStandardsInput;
  where: WorkshopWhereUniqueInput;
};

export type WorkshopUpdateWithWhereUniqueWithoutTagsInput = {
  data: WorkshopUpdateWithoutTagsInput;
  where: WorkshopWhereUniqueInput;
};

export type WorkshopUpdateWithWhereUniqueWithoutTrainerInput = {
  data: WorkshopUpdateWithoutTrainerInput;
  where: WorkshopWhereUniqueInput;
};

export type WorkshopUpdateWithoutInsiderActivitiesInput = {
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ageGroup?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  displayTime?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  endDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  feesInCurrency?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  feesWithKit?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  feesWithoutKit?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  footerImageLarge?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  footerImageSmall?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headerImageLarge?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headerImageSmall?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headerText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headerVideo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isOpen?: InputMaybe<BoolFieldUpdateOperationsInput>;
  liveUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  location?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  metaTagData?: InputMaybe<Scalars["JSON"]["input"]>;
  mode?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  product?: InputMaybe<ProductUpdateOneWithoutWorkshopsNestedInput>;
  promoCodes?: InputMaybe<PromoCodeUpdateManyWithoutWorkshopsNestedInput>;
  relatedActivities?: InputMaybe<ActivityUpdateManyWithoutRelatedWorkshopsNestedInput>;
  school?: InputMaybe<SchoolUpdateOneRequiredWithoutWorkshopsNestedInput>;
  skill?: InputMaybe<SkillUpdateOneRequiredWithoutWorkshopsNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  standards?: InputMaybe<StandardUpdateManyWithoutWorkshopsNestedInput>;
  startDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  tags?: InputMaybe<TagUpdateManyWithoutWorkshopsNestedInput>;
  trainer?: InputMaybe<UserUpdateOneWithoutWorkshopsNestedInput>;
  trainerImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  workshopRegistrations?: InputMaybe<WorkshopRegistrationUpdateManyWithoutWorkshopNestedInput>;
};

export type WorkshopUpdateWithoutProductInput = {
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ageGroup?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  displayTime?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  endDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  feesInCurrency?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  feesWithKit?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  feesWithoutKit?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  footerImageLarge?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  footerImageSmall?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headerImageLarge?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headerImageSmall?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headerText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headerVideo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  insiderActivities?: InputMaybe<ActivityUpdateManyWithoutInsideWorkshopsNestedInput>;
  isOpen?: InputMaybe<BoolFieldUpdateOperationsInput>;
  liveUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  location?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  metaTagData?: InputMaybe<Scalars["JSON"]["input"]>;
  mode?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  promoCodes?: InputMaybe<PromoCodeUpdateManyWithoutWorkshopsNestedInput>;
  relatedActivities?: InputMaybe<ActivityUpdateManyWithoutRelatedWorkshopsNestedInput>;
  school?: InputMaybe<SchoolUpdateOneRequiredWithoutWorkshopsNestedInput>;
  skill?: InputMaybe<SkillUpdateOneRequiredWithoutWorkshopsNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  standards?: InputMaybe<StandardUpdateManyWithoutWorkshopsNestedInput>;
  startDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  tags?: InputMaybe<TagUpdateManyWithoutWorkshopsNestedInput>;
  trainer?: InputMaybe<UserUpdateOneWithoutWorkshopsNestedInput>;
  trainerImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  workshopRegistrations?: InputMaybe<WorkshopRegistrationUpdateManyWithoutWorkshopNestedInput>;
};

export type WorkshopUpdateWithoutPromoCodesInput = {
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ageGroup?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  displayTime?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  endDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  feesInCurrency?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  feesWithKit?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  feesWithoutKit?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  footerImageLarge?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  footerImageSmall?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headerImageLarge?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headerImageSmall?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headerText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headerVideo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  insiderActivities?: InputMaybe<ActivityUpdateManyWithoutInsideWorkshopsNestedInput>;
  isOpen?: InputMaybe<BoolFieldUpdateOperationsInput>;
  liveUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  location?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  metaTagData?: InputMaybe<Scalars["JSON"]["input"]>;
  mode?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  product?: InputMaybe<ProductUpdateOneWithoutWorkshopsNestedInput>;
  relatedActivities?: InputMaybe<ActivityUpdateManyWithoutRelatedWorkshopsNestedInput>;
  school?: InputMaybe<SchoolUpdateOneRequiredWithoutWorkshopsNestedInput>;
  skill?: InputMaybe<SkillUpdateOneRequiredWithoutWorkshopsNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  standards?: InputMaybe<StandardUpdateManyWithoutWorkshopsNestedInput>;
  startDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  tags?: InputMaybe<TagUpdateManyWithoutWorkshopsNestedInput>;
  trainer?: InputMaybe<UserUpdateOneWithoutWorkshopsNestedInput>;
  trainerImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  workshopRegistrations?: InputMaybe<WorkshopRegistrationUpdateManyWithoutWorkshopNestedInput>;
};

export type WorkshopUpdateWithoutRelatedActivitiesInput = {
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ageGroup?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  displayTime?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  endDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  feesInCurrency?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  feesWithKit?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  feesWithoutKit?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  footerImageLarge?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  footerImageSmall?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headerImageLarge?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headerImageSmall?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headerText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headerVideo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  insiderActivities?: InputMaybe<ActivityUpdateManyWithoutInsideWorkshopsNestedInput>;
  isOpen?: InputMaybe<BoolFieldUpdateOperationsInput>;
  liveUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  location?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  metaTagData?: InputMaybe<Scalars["JSON"]["input"]>;
  mode?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  product?: InputMaybe<ProductUpdateOneWithoutWorkshopsNestedInput>;
  promoCodes?: InputMaybe<PromoCodeUpdateManyWithoutWorkshopsNestedInput>;
  school?: InputMaybe<SchoolUpdateOneRequiredWithoutWorkshopsNestedInput>;
  skill?: InputMaybe<SkillUpdateOneRequiredWithoutWorkshopsNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  standards?: InputMaybe<StandardUpdateManyWithoutWorkshopsNestedInput>;
  startDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  tags?: InputMaybe<TagUpdateManyWithoutWorkshopsNestedInput>;
  trainer?: InputMaybe<UserUpdateOneWithoutWorkshopsNestedInput>;
  trainerImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  workshopRegistrations?: InputMaybe<WorkshopRegistrationUpdateManyWithoutWorkshopNestedInput>;
};

export type WorkshopUpdateWithoutSchoolInput = {
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ageGroup?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  displayTime?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  endDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  feesInCurrency?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  feesWithKit?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  feesWithoutKit?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  footerImageLarge?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  footerImageSmall?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headerImageLarge?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headerImageSmall?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headerText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headerVideo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  insiderActivities?: InputMaybe<ActivityUpdateManyWithoutInsideWorkshopsNestedInput>;
  isOpen?: InputMaybe<BoolFieldUpdateOperationsInput>;
  liveUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  location?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  metaTagData?: InputMaybe<Scalars["JSON"]["input"]>;
  mode?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  product?: InputMaybe<ProductUpdateOneWithoutWorkshopsNestedInput>;
  promoCodes?: InputMaybe<PromoCodeUpdateManyWithoutWorkshopsNestedInput>;
  relatedActivities?: InputMaybe<ActivityUpdateManyWithoutRelatedWorkshopsNestedInput>;
  skill?: InputMaybe<SkillUpdateOneRequiredWithoutWorkshopsNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  standards?: InputMaybe<StandardUpdateManyWithoutWorkshopsNestedInput>;
  startDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  tags?: InputMaybe<TagUpdateManyWithoutWorkshopsNestedInput>;
  trainer?: InputMaybe<UserUpdateOneWithoutWorkshopsNestedInput>;
  trainerImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  workshopRegistrations?: InputMaybe<WorkshopRegistrationUpdateManyWithoutWorkshopNestedInput>;
};

export type WorkshopUpdateWithoutSkillInput = {
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ageGroup?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  displayTime?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  endDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  feesInCurrency?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  feesWithKit?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  feesWithoutKit?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  footerImageLarge?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  footerImageSmall?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headerImageLarge?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headerImageSmall?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headerText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headerVideo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  insiderActivities?: InputMaybe<ActivityUpdateManyWithoutInsideWorkshopsNestedInput>;
  isOpen?: InputMaybe<BoolFieldUpdateOperationsInput>;
  liveUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  location?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  metaTagData?: InputMaybe<Scalars["JSON"]["input"]>;
  mode?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  product?: InputMaybe<ProductUpdateOneWithoutWorkshopsNestedInput>;
  promoCodes?: InputMaybe<PromoCodeUpdateManyWithoutWorkshopsNestedInput>;
  relatedActivities?: InputMaybe<ActivityUpdateManyWithoutRelatedWorkshopsNestedInput>;
  school?: InputMaybe<SchoolUpdateOneRequiredWithoutWorkshopsNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  standards?: InputMaybe<StandardUpdateManyWithoutWorkshopsNestedInput>;
  startDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  tags?: InputMaybe<TagUpdateManyWithoutWorkshopsNestedInput>;
  trainer?: InputMaybe<UserUpdateOneWithoutWorkshopsNestedInput>;
  trainerImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  workshopRegistrations?: InputMaybe<WorkshopRegistrationUpdateManyWithoutWorkshopNestedInput>;
};

export type WorkshopUpdateWithoutStandardsInput = {
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ageGroup?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  displayTime?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  endDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  feesInCurrency?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  feesWithKit?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  feesWithoutKit?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  footerImageLarge?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  footerImageSmall?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headerImageLarge?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headerImageSmall?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headerText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headerVideo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  insiderActivities?: InputMaybe<ActivityUpdateManyWithoutInsideWorkshopsNestedInput>;
  isOpen?: InputMaybe<BoolFieldUpdateOperationsInput>;
  liveUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  location?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  metaTagData?: InputMaybe<Scalars["JSON"]["input"]>;
  mode?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  product?: InputMaybe<ProductUpdateOneWithoutWorkshopsNestedInput>;
  promoCodes?: InputMaybe<PromoCodeUpdateManyWithoutWorkshopsNestedInput>;
  relatedActivities?: InputMaybe<ActivityUpdateManyWithoutRelatedWorkshopsNestedInput>;
  school?: InputMaybe<SchoolUpdateOneRequiredWithoutWorkshopsNestedInput>;
  skill?: InputMaybe<SkillUpdateOneRequiredWithoutWorkshopsNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  startDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  tags?: InputMaybe<TagUpdateManyWithoutWorkshopsNestedInput>;
  trainer?: InputMaybe<UserUpdateOneWithoutWorkshopsNestedInput>;
  trainerImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  workshopRegistrations?: InputMaybe<WorkshopRegistrationUpdateManyWithoutWorkshopNestedInput>;
};

export type WorkshopUpdateWithoutTagsInput = {
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ageGroup?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  displayTime?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  endDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  feesInCurrency?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  feesWithKit?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  feesWithoutKit?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  footerImageLarge?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  footerImageSmall?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headerImageLarge?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headerImageSmall?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headerText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headerVideo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  insiderActivities?: InputMaybe<ActivityUpdateManyWithoutInsideWorkshopsNestedInput>;
  isOpen?: InputMaybe<BoolFieldUpdateOperationsInput>;
  liveUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  location?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  metaTagData?: InputMaybe<Scalars["JSON"]["input"]>;
  mode?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  product?: InputMaybe<ProductUpdateOneWithoutWorkshopsNestedInput>;
  promoCodes?: InputMaybe<PromoCodeUpdateManyWithoutWorkshopsNestedInput>;
  relatedActivities?: InputMaybe<ActivityUpdateManyWithoutRelatedWorkshopsNestedInput>;
  school?: InputMaybe<SchoolUpdateOneRequiredWithoutWorkshopsNestedInput>;
  skill?: InputMaybe<SkillUpdateOneRequiredWithoutWorkshopsNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  standards?: InputMaybe<StandardUpdateManyWithoutWorkshopsNestedInput>;
  startDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  trainer?: InputMaybe<UserUpdateOneWithoutWorkshopsNestedInput>;
  trainerImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  workshopRegistrations?: InputMaybe<WorkshopRegistrationUpdateManyWithoutWorkshopNestedInput>;
};

export type WorkshopUpdateWithoutTrainerInput = {
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ageGroup?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  displayTime?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  endDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  feesInCurrency?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  feesWithKit?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  feesWithoutKit?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  footerImageLarge?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  footerImageSmall?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headerImageLarge?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headerImageSmall?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headerText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headerVideo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  insiderActivities?: InputMaybe<ActivityUpdateManyWithoutInsideWorkshopsNestedInput>;
  isOpen?: InputMaybe<BoolFieldUpdateOperationsInput>;
  liveUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  location?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  metaTagData?: InputMaybe<Scalars["JSON"]["input"]>;
  mode?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  product?: InputMaybe<ProductUpdateOneWithoutWorkshopsNestedInput>;
  promoCodes?: InputMaybe<PromoCodeUpdateManyWithoutWorkshopsNestedInput>;
  relatedActivities?: InputMaybe<ActivityUpdateManyWithoutRelatedWorkshopsNestedInput>;
  school?: InputMaybe<SchoolUpdateOneRequiredWithoutWorkshopsNestedInput>;
  skill?: InputMaybe<SkillUpdateOneRequiredWithoutWorkshopsNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  standards?: InputMaybe<StandardUpdateManyWithoutWorkshopsNestedInput>;
  startDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  tags?: InputMaybe<TagUpdateManyWithoutWorkshopsNestedInput>;
  trainerImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  workshopRegistrations?: InputMaybe<WorkshopRegistrationUpdateManyWithoutWorkshopNestedInput>;
};

export type WorkshopUpdateWithoutWorkshopRegistrationsInput = {
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ageGroup?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  displayTime?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  endDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  feesInCurrency?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  feesWithKit?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  feesWithoutKit?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  footerImageLarge?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  footerImageSmall?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headerImageLarge?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headerImageSmall?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headerText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headerVideo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  insiderActivities?: InputMaybe<ActivityUpdateManyWithoutInsideWorkshopsNestedInput>;
  isOpen?: InputMaybe<BoolFieldUpdateOperationsInput>;
  liveUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  location?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars["JSON"]["input"]>;
  metaTagData?: InputMaybe<Scalars["JSON"]["input"]>;
  mode?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  product?: InputMaybe<ProductUpdateOneWithoutWorkshopsNestedInput>;
  promoCodes?: InputMaybe<PromoCodeUpdateManyWithoutWorkshopsNestedInput>;
  relatedActivities?: InputMaybe<ActivityUpdateManyWithoutRelatedWorkshopsNestedInput>;
  school?: InputMaybe<SchoolUpdateOneRequiredWithoutWorkshopsNestedInput>;
  skill?: InputMaybe<SkillUpdateOneRequiredWithoutWorkshopsNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  standards?: InputMaybe<StandardUpdateManyWithoutWorkshopsNestedInput>;
  startDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  tags?: InputMaybe<TagUpdateManyWithoutWorkshopsNestedInput>;
  trainer?: InputMaybe<UserUpdateOneWithoutWorkshopsNestedInput>;
  trainerImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type WorkshopUpsertWithWhereUniqueWithoutInsiderActivitiesInput = {
  create: WorkshopCreateWithoutInsiderActivitiesInput;
  update: WorkshopUpdateWithoutInsiderActivitiesInput;
  where: WorkshopWhereUniqueInput;
};

export type WorkshopUpsertWithWhereUniqueWithoutProductInput = {
  create: WorkshopCreateWithoutProductInput;
  update: WorkshopUpdateWithoutProductInput;
  where: WorkshopWhereUniqueInput;
};

export type WorkshopUpsertWithWhereUniqueWithoutPromoCodesInput = {
  create: WorkshopCreateWithoutPromoCodesInput;
  update: WorkshopUpdateWithoutPromoCodesInput;
  where: WorkshopWhereUniqueInput;
};

export type WorkshopUpsertWithWhereUniqueWithoutRelatedActivitiesInput = {
  create: WorkshopCreateWithoutRelatedActivitiesInput;
  update: WorkshopUpdateWithoutRelatedActivitiesInput;
  where: WorkshopWhereUniqueInput;
};

export type WorkshopUpsertWithWhereUniqueWithoutSchoolInput = {
  create: WorkshopCreateWithoutSchoolInput;
  update: WorkshopUpdateWithoutSchoolInput;
  where: WorkshopWhereUniqueInput;
};

export type WorkshopUpsertWithWhereUniqueWithoutSkillInput = {
  create: WorkshopCreateWithoutSkillInput;
  update: WorkshopUpdateWithoutSkillInput;
  where: WorkshopWhereUniqueInput;
};

export type WorkshopUpsertWithWhereUniqueWithoutStandardsInput = {
  create: WorkshopCreateWithoutStandardsInput;
  update: WorkshopUpdateWithoutStandardsInput;
  where: WorkshopWhereUniqueInput;
};

export type WorkshopUpsertWithWhereUniqueWithoutTagsInput = {
  create: WorkshopCreateWithoutTagsInput;
  update: WorkshopUpdateWithoutTagsInput;
  where: WorkshopWhereUniqueInput;
};

export type WorkshopUpsertWithWhereUniqueWithoutTrainerInput = {
  create: WorkshopCreateWithoutTrainerInput;
  update: WorkshopUpdateWithoutTrainerInput;
  where: WorkshopWhereUniqueInput;
};

export type WorkshopUpsertWithoutWorkshopRegistrationsInput = {
  create: WorkshopCreateWithoutWorkshopRegistrationsInput;
  update: WorkshopUpdateWithoutWorkshopRegistrationsInput;
};

export type WorkshopWhereInput = {
  AND?: InputMaybe<Array<WorkshopWhereInput>>;
  NOT?: InputMaybe<Array<WorkshopWhereInput>>;
  OR?: InputMaybe<Array<WorkshopWhereInput>>;
  address?: InputMaybe<StringNullableFilter>;
  ageGroup?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  displayTime?: InputMaybe<StringNullableFilter>;
  endDate?: InputMaybe<DateTimeNullableFilter>;
  featuredImage?: InputMaybe<StringNullableFilter>;
  feesInCurrency?: InputMaybe<IntNullableFilter>;
  feesWithKit?: InputMaybe<IntNullableFilter>;
  feesWithoutKit?: InputMaybe<IntNullableFilter>;
  footerImageLarge?: InputMaybe<StringNullableFilter>;
  footerImageSmall?: InputMaybe<StringNullableFilter>;
  headerImageLarge?: InputMaybe<StringNullableFilter>;
  headerImageSmall?: InputMaybe<StringNullableFilter>;
  headerText?: InputMaybe<StringNullableFilter>;
  headerVideo?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  image?: InputMaybe<StringNullableFilter>;
  insiderActivities?: InputMaybe<ActivityListRelationFilter>;
  isOpen?: InputMaybe<BoolFilter>;
  liveUrl?: InputMaybe<StringNullableFilter>;
  location?: InputMaybe<StringNullableFilter>;
  meta?: InputMaybe<JsonNullableFilter>;
  metaTagData?: InputMaybe<JsonNullableFilter>;
  mode?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  product?: InputMaybe<ProductRelationFilter>;
  productId?: InputMaybe<IntNullableFilter>;
  promoCodes?: InputMaybe<PromoCodeListRelationFilter>;
  relatedActivities?: InputMaybe<ActivityListRelationFilter>;
  school?: InputMaybe<SchoolRelationFilter>;
  schoolId?: InputMaybe<IntFilter>;
  skill?: InputMaybe<SkillRelationFilter>;
  skillId?: InputMaybe<IntFilter>;
  slug?: InputMaybe<StringFilter>;
  standards?: InputMaybe<StandardListRelationFilter>;
  startDate?: InputMaybe<DateTimeFilter>;
  tags?: InputMaybe<TagListRelationFilter>;
  trainer?: InputMaybe<UserRelationFilter>;
  trainerId?: InputMaybe<IntNullableFilter>;
  trainerImage?: InputMaybe<StringNullableFilter>;
  type?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  workshopRegistrations?: InputMaybe<WorkshopRegistrationListRelationFilter>;
};

export type WorkshopWhereUniqueInput = {
  id?: InputMaybe<Scalars["Int"]["input"]>;
  slug?: InputMaybe<Scalars["String"]["input"]>;
};

export type UnlockStudentActivityMutationVariables = Exact<{
  slug: Scalars["String"]["input"];
}>;


export type UnlockStudentActivityMutation = { __typename?: "Mutation", unlockStudentActivity?: { __typename?: "Activity", id: number } | null };

export type ValidateActivityQuestionAnswerMutationVariables = Exact<{
  data: ValidateActivityQuestionAnswerInput;
  where: ActivityQuestionWhereUniqueInput;
}>;


export type ValidateActivityQuestionAnswerMutation = { __typename?: "Mutation", validateActivityQuestionAnswer: { __typename?: "ValidateActivityQuestionAnswerOutput", isCorrect: boolean, incorrectText?: string | null, answerText?: string | null } };

export type ActivityQuestionSubmitMutationVariables = Exact<{
  activity: ActivityWhereUniqueInput;
  data: Array<ValidateActivityQuestionSubmitInput> | ValidateActivityQuestionSubmitInput;
}>;


export type ActivityQuestionSubmitMutation = { __typename?: "Mutation", activityQuestionSubmit: { __typename?: "ValidateActivityQuestionSubmitOutput", success: boolean, message: string } };

export type PublicActivityBannersQueryVariables = Exact<{ [key: string]: never; }>;


export type PublicActivityBannersQuery = { __typename?: "Query", publicActivityBanners: Array<{ __typename?: "ActivityBanner", id: number, image?: string | null, destinationUrl: string }> };

export type StudentActivitiesQueryVariables = Exact<{
  where: FindStudentManyActivityInput;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
}>;


export type StudentActivitiesQuery = { __typename?: "Query", studentActivities: Array<{ __typename?: "PublicActivity", id: number, slug: string, title: string, timeRequired: string, difficultyLevel: string, featuredImage?: string | null, commentsCount?: number | null, skill?: { __typename?: "PublicSkill", id: number, name: string, slug: string } | null }> };

export type PublicActivityPageQueriesQueryVariables = Exact<{
  publicLiveActivitiesWhere: FindPublicManyActivityInput;
  publicNewReleaseActivitiesWhere: FindPublicManyActivityInput;
  publicChallengeActivitiesWhere: FindPublicManyActivityInput;
  publicPopularActivitiesWhere: FindPublicManyActivityInput;
}>;


export type PublicActivityPageQueriesQuery = { __typename?: "Query", publicActivitySkills: Array<{ __typename?: "PublicSkill", id: number, name: string, slug: string, featuredImage?: string | null }>, publicLiveActivities: Array<{ __typename?: "PublicActivity", id: number, slug: string, title: string, timeRequired: string, difficultyLevel: string, featuredImage?: string | null, commentsCount?: number | null, skill?: { __typename?: "PublicSkill", id: number, name: string, slug: string } | null }>, publicNewReleaseActivities: Array<{ __typename?: "PublicActivity", id: number, slug: string, title: string, timeRequired: string, difficultyLevel: string, featuredImage?: string | null, commentsCount?: number | null, skill?: { __typename?: "PublicSkill", id: number, name: string, slug: string } | null }>, publicChallengeActivities: Array<{ __typename?: "PublicActivity", id: number, slug: string, title: string, timeRequired: string, difficultyLevel: string, featuredImage?: string | null, commentsCount?: number | null, skill?: { __typename?: "PublicSkill", id: number, name: string, slug: string } | null }>, publicPopularActivities: Array<{ __typename?: "PublicActivity", id: number, slug: string, title: string, timeRequired: string, difficultyLevel: string, featuredImage?: string | null, commentsCount?: number | null, skill?: { __typename?: "PublicSkill", id: number, name: string, slug: string } | null }> };

export type PublicActivitiesQueryVariables = Exact<{
  where: FindPublicManyActivityInput;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
}>;


export type PublicActivitiesQuery = { __typename?: "Query", publicActivities: Array<{ __typename?: "PublicActivity", id: number, slug: string, title: string, timeRequired: string, difficultyLevel: string, featuredImage?: string | null, commentsCount?: number | null, skill?: { __typename?: "PublicSkill", id: number, name: string, slug: string } | null }> };

export type PublicActivityQueryVariables = Exact<{
  where: ActivityWhereUniqueInput;
}>;


export type PublicActivityQuery = { __typename?: "Query", publicActivity?: { __typename?: "PublicActivity", id: number, title: string, slug: string, shortDescription?: string | null, description?: string | null, difficultyLevel: string, timeRequired: string, featuredImage?: string | null, challengeStatement?: string | null, materialsNeeded?: string | null, video?: string | null, instructionImage?: string | null, instructionText?: string | null, image?: string | null, xps?: number | null, isIndexable: boolean, metaTagData?: any | null, jsonLD?: any | null, tags?: Array<{ __typename?: "PublicTag", id: number, name: string, slug: string }> | null, skill?: { __typename?: "PublicSkill", id: number, name: string, slug: string } | null } | null };

export type FeaturedStudentActivitiesQueryVariables = Exact<{ [key: string]: never; }>;


export type FeaturedStudentActivitiesQuery = { __typename?: "Query", featuredStudentActivities: Array<{ __typename?: "PublicActivity", id: number, title: string, slug: string, timeRequired: string, difficultyLevel: string, featuredImage?: string | null, commentsCount?: number | null, skill?: { __typename?: "PublicSkill", id: number, name: string, slug: string } | null }> };

export type StudentActivityAccessQueryVariables = Exact<{
  slug: Scalars["String"]["input"];
}>;


export type StudentActivityAccessQuery = { __typename?: "Query", studentActivityAccess?: { __typename?: "FindStudentActivityAccessOutput", isUnlocked: boolean, isQaCompleted: boolean, message: string } | null };

export type StudentActivityPartialQueryVariables = Exact<{
  where: ActivityWhereUniqueInput;
}>;


export type StudentActivityPartialQuery = { __typename?: "Query", studentActivity?: { __typename?: "FindStudentUniqueActivityOutput", id: number, slug: string, title: string, shortDescription?: string | null, description?: string | null, featuredImage?: string | null, image?: string | null, xps: number, questionXps: number, metaTagData?: any | null, jsonLD?: any | null, timeRequired: string, difficultyLevel: string, coinsToSpend: number, skill?: { __typename?: "PublicSkill", id: number, name: string, slug: string } | null, tags?: Array<{ __typename?: "PublicTag", id: number, name: string, slug: string }> | null } | null };

export type StudentActivityWithoutQuestionsQueryVariables = Exact<{
  where: ActivityWhereUniqueInput;
}>;


export type StudentActivityWithoutQuestionsQuery = { __typename?: "Query", studentActivity?: { __typename?: "FindStudentUniqueActivityOutput", id: number, slug: string, title: string, shortDescription?: string | null, description?: string | null, image?: string | null, featuredImage?: string | null, xps: number, questionXps: number, metaTagData?: any | null, jsonLD?: any | null, timeRequired: string, difficultyLevel: string, video?: string | null, challengeStatement: string, materialsNeeded?: string | null, instructionImage?: string | null, instructionText?: string | null, coinsToSpend: number, isCommentEnabled: boolean, tags?: Array<{ __typename?: "PublicTag", id: number, name: string, slug: string }> | null, skill?: { __typename?: "PublicSkill", id: number, name: string, slug: string } | null, nextActivity?: { __typename?: "PublicActivity", id: number, slug: string, skill?: { __typename?: "PublicSkill", id: number, slug: string } | null } | null } | null };

export type StudentActivityWithQuestionsQueryVariables = Exact<{
  where: ActivityWhereUniqueInput;
}>;


export type StudentActivityWithQuestionsQuery = { __typename?: "Query", studentActivity?: { __typename?: "FindStudentUniqueActivityOutput", id: number, slug: string, title: string, shortDescription?: string | null, description?: string | null, image?: string | null, featuredImage?: string | null, xps: number, questionXps: number, metaTagData?: any | null, jsonLD?: any | null, timeRequired: string, difficultyLevel: string, video?: string | null, challengeStatement: string, materialsNeeded?: string | null, instructionImage?: string | null, instructionText?: string | null, coinsToSpend: number, isCommentEnabled: boolean, tags?: Array<{ __typename?: "PublicTag", id: number, name: string, slug: string }> | null, skill?: { __typename?: "PublicSkill", id: number, name: string, slug: string } | null, nextActivity?: { __typename?: "PublicActivity", id: number, slug: string, skill?: { __typename?: "PublicSkill", id: number, slug: string } | null } | null, questions?: Array<{ __typename?: "ActivityQuestion", id: number, type: string, questionText: string, options?: any | null }> | null } | null };

export type StudentPendingActivitiesQueryVariables = Exact<{ [key: string]: never; }>;


export type StudentPendingActivitiesQuery = { __typename?: "Query", studentPendingActivities: Array<{ __typename?: "PublicActivity", id: number, title: string, slug: string, timeRequired: string, difficultyLevel: string, featuredImage?: string | null, commentsCount?: number | null, skill?: { __typename?: "PublicSkill", id: number, name: string, slug: string } | null }> };

export type StudentNextRandomActivityQueryVariables = Exact<{
  where?: InputMaybe<StudentNextRandomActivityInput>;
}>;


export type StudentNextRandomActivityQuery = { __typename?: "Query", studentNextRandomActivity?: { __typename?: "FindStudentUniqueActivityOutput", id: number, slug: string, skill?: { __typename?: "PublicSkill", id: number, slug: string } | null } | null };

export type LogInMutationVariables = Exact<{
  data: LogInInput;
}>;


export type LogInMutation = { __typename?: "Mutation", logIn: { __typename?: "LogInResponse", authToken?: string | null, id?: number | null, userId?: string | null, expiresAt?: any | null, message: string, rules?: any | null, success: boolean } };

export type SignUpMutationVariables = Exact<{
  data: SignUpInput;
}>;


export type SignUpMutation = { __typename?: "Mutation", signUp: { __typename?: "LogInResponse", authToken?: string | null, id?: number | null, userId?: string | null, expiresAt?: any | null, message: string, rules?: any | null, success: boolean } };

export type RefreshTokenMutationVariables = Exact<{ [key: string]: never; }>;


export type RefreshTokenMutation = { __typename?: "Mutation", refreshToken: { __typename?: "RefreshTokenResponse", authToken?: string | null, expiresAt?: any | null } };

export type StandardsQueryVariables = Exact<{ [key: string]: never; }>;


export type StandardsQuery = { __typename?: "Query", standards: Array<{ __typename?: "Standard", id: number, key: string, value: string }> };

export type StudentCreateOrderMutationVariables = Exact<{
  data: StudentCreateOrderProductInput;
}>;


export type StudentCreateOrderMutation = { __typename?: "Mutation", studentCreateOrder?: { __typename?: "StudentCreateOrderOutput", paymentUrl?: string | null, success: boolean, message: string } | null };

export type StudentReferralCodeQueryVariables = Exact<{ [key: string]: never; }>;


export type StudentReferralCodeQuery = { __typename?: "Query", studentReferralCode: { __typename?: "StudentReferralCodeResponse", code?: string | null, success: boolean, message: string } };

export type GenerateUploadSignedUrlMutationVariables = Exact<{
  data: GenerateUploadSignedUrlInput;
}>;


export type GenerateUploadSignedUrlMutation = { __typename?: "Mutation", generateUploadSignedUrl: Array<{ __typename?: "File", name?: string | null, signedUrl?: string | null }> };

export type CreateCommentMutationVariables = Exact<{
  data: StudentCreateCommentInput;
}>;


export type CreateCommentMutation = { __typename?: "Mutation", createComment: { __typename?: "Comment", id: number } };

export type StudentActivityCommentsQueryVariables = Exact<{
  where: FindStudentActivityCommentsInput;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
}>;


export type StudentActivityCommentsQuery = { __typename?: "Query", studentActivityComments: Array<{ __typename?: "FindStudentActivityCommentsOutput", id: number, text: string, attachmentUrl?: string | null, createdAt: any, createdBy?: { __typename?: "FindStudentActivityCommentsStudentOutput", displayName: string, profileImage?: string | null } | null }> };

export type StudentActivitySelfCommentsQueryVariables = Exact<{
  where: FindStudentActivityCommentsInput;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
}>;


export type StudentActivitySelfCommentsQuery = { __typename?: "Query", studentActivitySelfComments: Array<{ __typename?: "FindStudentActivityCommentsOutput", id: number, text: string, attachmentUrl?: string | null, meta?: any | null, status?: CommentStatus | null, createdAt: any, createdBy?: { __typename?: "FindStudentActivityCommentsStudentOutput", displayName: string, profileImage?: string | null } | null }> };

export type AnswerStudentDailyActivityMutationVariables = Exact<{
  data: AnswerStudentDailyActivityInput;
  where: DailyActivityWhereUniqueInput;
}>;


export type AnswerStudentDailyActivityMutation = { __typename?: "Mutation", answerStudentDailyActivity?: { __typename?: "StudentDailyActivity", id: number, answer?: number | null, answerText?: string | null } | null };

export type StudentDailyActivityQueryVariables = Exact<{
  data: StudentDailyActivityInput;
}>;


export type StudentDailyActivityQuery = { __typename?: "Query", studentDailyActivity?: { __typename?: "StudentDailyActivity", id: number, question: string, options: any, questionImage?: string | null, questionVideo?: string | null } | null };

export type StudentDailyChallengeQueryVariables = Exact<{
  data: StudentDailyChallengeInput;
}>;


export type StudentDailyChallengeQuery = { __typename?: "Query", studentDailyChallenge?: { __typename?: "StudentDailyChallenge", id: number, title: string, video?: string | null, image?: string | null, type: string, challengeStatement?: string | null } | null };

export type StudentLeaderboardQueryVariables = Exact<{ [key: string]: never; }>;


export type StudentLeaderboardQuery = { __typename?: "Query", studentLeaderboard?: Array<{ __typename?: "StudentLeaderboardResponse", name: string, xps: number, stars: number, level: number, profileImage?: string | null }> | null };

export type StudentTopPerformerMonthLeaderboardQueryVariables = Exact<{ [key: string]: never; }>;


export type StudentTopPerformerMonthLeaderboardQuery = { __typename?: "Query", studentTopPerformerMonthLeaderboard?: Array<{ __typename?: "StudentLeaderboardResponse", name: string, xps: number, stars: number, level: number, profileImage?: string | null }> | null };

export type StudentTopPerformerWeekLeaderboardQueryVariables = Exact<{ [key: string]: never; }>;


export type StudentTopPerformerWeekLeaderboardQuery = { __typename?: "Query", studentTopPerformerWeekLeaderboard?: Array<{ __typename?: "StudentLeaderboardResponse", name: string, xps: number, stars: number, level: number, profileImage?: string | null }> | null };

export type PublicProductBannersQueryVariables = Exact<{ [key: string]: never; }>;


export type PublicProductBannersQuery = { __typename?: "Query", publicProductBanners: Array<{ __typename?: "ProductBanner", id: number, image?: string | null, destinationUrl: string, displayOrder: number }> };

export type StudentProductQueryVariables = Exact<{
  where: ProductWhereUniqueInput;
}>;


export type StudentProductQuery = { __typename?: "Query", studentProduct?: { __typename?: "PublicProduct", id: number, slug: string, name: string, featuredImage?: string | null, price: number, discountPrice?: number | null, shortDescription?: string | null, description?: string | null, age?: string | null, images?: Array<string> | null, inCurrency: number, video?: string | null, isBestSelling: boolean, isNewReleased: boolean, isVirtual: boolean, purchaseLink?: string | null, skill?: { __typename?: "PublicSkill", id: number, slug: string, name: string } | null } | null };

export type PublicProductsQueryVariables = Exact<{
  where?: InputMaybe<StudentProductWhereInput>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
}>;


export type PublicProductsQuery = { __typename?: "Query", publicProducts: Array<{ __typename?: "PublicProduct", id: number, slug: string, name: string, age?: string | null, featuredImage?: string | null, price: number, discountPrice?: number | null }> };

export type ProductPageDataQueryVariables = Exact<{ [key: string]: never; }>;


export type ProductPageDataQuery = { __typename?: "Query", publicProductBanners: Array<{ __typename?: "ProductBanner", id: number, image?: string | null, destinationUrl: string, displayOrder: number }>, newReleasedProducts: Array<{ __typename?: "PublicProduct", id: number, slug: string, name: string, age?: string | null, featuredImage?: string | null, price: number, discountPrice?: number | null }> };

export type ApplyPromoCodeMutationVariables = Exact<{
  code: Scalars["String"]["input"];
}>;


export type ApplyPromoCodeMutation = { __typename?: "Mutation", applyPromoCode: { __typename?: "ApplyPromoCodeOutput", success: boolean, message: string } };

export type StudentRewardQueryVariables = Exact<{ [key: string]: never; }>;


export type StudentRewardQuery = { __typename?: "Query", studentReward?: { __typename?: "StudentRewardResponse", level: number, coins: number, totalXps: number, stars: number, badges?: Array<{ __typename?: "Badge", id: number, name: string, image?: string | null }> | null } | null };

export type StudentSkillXpsQueryVariables = Exact<{ [key: string]: never; }>;


export type StudentSkillXpsQuery = { __typename?: "Query", studentSkillXps?: { __typename?: "StudentSkillXpsResponse", data?: any | null } | null };

export type StudentCoinLogsQueryVariables = Exact<{ [key: string]: never; }>;


export type StudentCoinLogsQuery = { __typename?: "Query", studentCoinLogs?: { __typename?: "StudentCoinLogsResponse", data?: any | null } | null };

export type StudentNextLevelRequirementQueryVariables = Exact<{ [key: string]: never; }>;


export type StudentNextLevelRequirementQuery = { __typename?: "Query", studentNextLevelRequirement?: { __typename?: "StudentNextLevelRequirementResponse", data?: any | null } | null };

export type SitemapQueryVariables = Exact<{ [key: string]: never; }>;


export type SitemapQuery = { __typename?: "Query", sitemap: Array<{ __typename?: "Sitemap", loc: string, lastmod?: any | null }> };

export type StudentSkillsQueryVariables = Exact<{
  take: Scalars["Int"]["input"];
  skip: Scalars["Int"]["input"];
}>;


export type StudentSkillsQuery = { __typename?: "Query", studentSkills: Array<{ __typename?: "PublicSkill", id: number, name: string, slug: string, featuredImage?: string | null }> };

export type PublicSkillsQueryVariables = Exact<{
  where?: InputMaybe<FindStudentManySkillWhereInput>;
  take: Scalars["Int"]["input"];
  skip: Scalars["Int"]["input"];
}>;


export type PublicSkillsQuery = { __typename?: "Query", publicSkills: Array<{ __typename?: "PublicSkill", id: number, name: string, slug: string, featuredImage?: string | null }> };

export type PublicSkillQueryVariables = Exact<{
  where: SkillWhereUniqueInput;
}>;


export type PublicSkillQuery = { __typename?: "Query", publicSkill: { __typename?: "PublicSkill", id: number, name: string, slug: string, bundle?: string | null, image?: string | null, metaTagData?: any | null, featuredImage?: string | null, description?: string | null } };

export type StudentSkillQueryVariables = Exact<{
  slug: Scalars["String"]["input"];
}>;


export type StudentSkillQuery = { __typename?: "Query", studentSkill?: { __typename?: "PublicSkill", id: number, name: string, slug: string, bundle?: string | null, image?: string | null, metaTagData?: any | null, featuredImage?: string | null, description?: string | null } | null };

export type StudentStoriesQueryVariables = Exact<{
  tag?: InputMaybe<StoryTagWhereUniqueInput>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
}>;


export type StudentStoriesQuery = { __typename?: "Query", studentStories: Array<{ __typename?: "StudentStoryOutput", id: number, slug: string, title: string, featuredImage?: string | null }> };

export type StudentStoryQueryVariables = Exact<{
  slug: Scalars["String"]["input"];
}>;


export type StudentStoryQuery = { __typename?: "Query", studentStory?: { __typename?: "StudentStoryOutput", id: number, slug: string, title: string, featuredImage?: string | null, description?: string | null } | null };

export type StudentStoryBannersQueryVariables = Exact<{ [key: string]: never; }>;


export type StudentStoryBannersQuery = { __typename?: "Query", studentStoryBanners: Array<{ __typename?: "StoryBanner", id: number, image?: string | null, destinationUrl: string }> };

export type StudentStoryTagsQueryVariables = Exact<{ [key: string]: never; }>;


export type StudentStoryTagsQuery = { __typename?: "Query", studentStoryTags: Array<{ __typename?: "StoryTag", id: number, key: string, text?: string | null, image?: string | null }> };

export type UnlockStudentStoryActivityMutationVariables = Exact<{
  slug: Scalars["String"]["input"];
}>;


export type UnlockStudentStoryActivityMutation = { __typename?: "Mutation", unlockStudentStoryActivity?: { __typename?: "StoryActivity", id: number, slug: string, title: string, meta?: any | null, content: string, featuredImage?: string | null, xps: number, timeRequired: string, video?: string | null, challengeStatement: string, skill: { __typename?: "Skill", slug: string } } | null };

export type StudentStoryActivitiesQueryVariables = Exact<{
  where?: InputMaybe<FindStudentStoryActivityInput>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
}>;


export type StudentStoryActivitiesQuery = { __typename?: "Query", studentStoryActivities: Array<{ __typename?: "StudentStoryActivity", id: number, slug: string, title: string, timeRequired: string, featuredImage?: string | null, commentsCount: number, story: { __typename?: "StudentStoryOutput", slug: string } }> };

export type StudentStoryActivityAccessQueryVariables = Exact<{
  slug: Scalars["String"]["input"];
}>;


export type StudentStoryActivityAccessQuery = { __typename?: "Query", studentStoryActivityAccess?: { __typename?: "FindStudentStoryActivityAccessResponse", success: boolean, message: string } | null };

export type StudentStoryActivityPartialQueryVariables = Exact<{
  where: StoryActivityWhereUniqueInput;
}>;


export type StudentStoryActivityPartialQuery = { __typename?: "Query", studentStoryActivity?: { __typename?: "StoryActivity", id: number, slug: string, title: string, meta?: any | null, image?: string | null, featuredImage?: string | null, xps: number, timeRequired: string, coinsToSpend: number } | null };

export type StudentStoryActivityDetailQueryVariables = Exact<{
  where: StoryActivityWhereUniqueInput;
}>;


export type StudentStoryActivityDetailQuery = { __typename?: "Query", studentStoryActivity?: { __typename?: "StoryActivity", id: number, slug: string, title: string, meta?: any | null, content: string, image?: string | null, featuredImage?: string | null, xps: number, timeRequired: string, video?: string | null, audio?: string | null, challengeStatement: string, coinsToSpend: number, isCommentEnabled: boolean, skill: { __typename?: "Skill", name: string } } | null };

export type PublicStoryActivitiesQueryVariables = Exact<{
  where?: InputMaybe<StoryActivityWhereInput>;
  orderBy?: InputMaybe<Array<StoryActivityOrderByWithRelationInput> | StoryActivityOrderByWithRelationInput>;
  cursor?: InputMaybe<StoryActivityWhereUniqueInput>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  distinct?: InputMaybe<Array<StoryActivityScalarFieldEnum> | StoryActivityScalarFieldEnum>;
}>;


export type PublicStoryActivitiesQuery = { __typename?: "Query", publicStoryActivities: Array<{ __typename?: "PublicStoryActivity", id: number, slug: string, title: string, content: string, image?: string | null, featuredImage?: string | null, xps: number, timeRequired: string, video?: string | null, audio?: string | null, challengeStatement: string, coinsToSpend: number, skill: { __typename?: "PublicSkill", name: string } }> };

export type PublicStoryActivityQueryVariables = Exact<{
  where: StoryActivityWhereUniqueInput;
}>;


export type PublicStoryActivityQuery = { __typename?: "Query", publicStoryActivity?: { __typename?: "PublicStoryActivity", id: number, slug: string, title: string, content: string, image?: string | null, featuredImage?: string | null, xps: number, timeRequired: string, video?: string | null, audio?: string | null, challengeStatement: string, coinsToSpend: number, skill: { __typename?: "PublicSkill", name: string } } | null };

export type PublicTagQueryVariables = Exact<{
  where: TagWhereUniqueInput;
}>;


export type PublicTagQuery = { __typename?: "Query", publicTag?: { __typename?: "PublicTag", id: number, name: string, slug: string, description?: string | null, seoContent?: string | null, metaTagData?: any | null, jsonLD?: any | null, productScript?: string | null, buyScript?: string | null } | null };

export type UpdateProfileMutationVariables = Exact<{
  data: UpdateProfileInput;
}>;


export type UpdateProfileMutation = { __typename?: "Mutation", updateProfile?: { __typename?: "Student", id: number } | null };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: "Query", me?: { __typename?: "MeResponse", id: number, userId?: string | null, displayName?: string | null, email?: string | null, coins: number, totalXps: number, stars: number, level: number, levelPercentage: number, isMeta: boolean, dailyActivityAnswered: boolean, dailyChallengeAnswered?: string | null, notificationToken: boolean } | null };

export type UserProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type UserProfileQuery = { __typename?: "Query", me?: { __typename?: "MeResponse", id: number, userId?: string | null, firstName: string, lastName: string, displayName?: string | null, email?: string | null, coins: number, phoneNumber: string, address1?: string | null, address2?: string | null, city?: string | null, state?: string | null, country?: string | null, postcode?: string | null, schoolText?: string | null, isHomeschooler: boolean, dob?: string | null, allowStandardUpdate: boolean, profileImage?: string | null, standard: { __typename?: "Standard", value: string } } | null };

export type UpdateStudentMetaMutationVariables = Exact<{
  data: UpdateStudentMetaInput;
}>;


export type UpdateStudentMetaMutation = { __typename?: "Mutation", updateStudentMeta?: { __typename?: "UpdateStudentMetaResponse", success: boolean, message: string } | null };

export type StudentRegisterWorkshopMutationVariables = Exact<{
  where: StudentWorkshopUniqueInput;
}>;


export type StudentRegisterWorkshopMutation = { __typename?: "Mutation", studentRegisterWorkshop?: { __typename?: "StudentRegisterWorkshopOutput", success: boolean, message: string } | null };

export type PublicPastWorkshopsQueryVariables = Exact<{
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
}>;


export type PublicPastWorkshopsQuery = { __typename?: "Query", publicPastWorkshops: Array<{ __typename?: "PublicWorkshopsOutput", id: number, name: string, slug: string, featuredImage?: string | null }> };

export type PublicUpcomingWorkshopsQueryVariables = Exact<{
  where?: InputMaybe<FindPublicManyWorkshopWhereInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
}>;


export type PublicUpcomingWorkshopsQuery = { __typename?: "Query", publicUpcomingWorkshops: Array<{ __typename?: "PublicWorkshopsOutput", id: number, name: string, slug: string, featuredImage?: string | null }> };

export type PublicSsrWorkshopsQueryVariables = Exact<{
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
}>;


export type PublicSsrWorkshopsQuery = { __typename?: "Query", publicUpcomingWorkshops: Array<{ __typename?: "PublicWorkshopsOutput", id: number, name: string, slug: string, featuredImage?: string | null }>, publicPastWorkshops: Array<{ __typename?: "PublicWorkshopsOutput", id: number, name: string, slug: string, featuredImage?: string | null }> };

export type StudentUpcomingWorkshopsQueryVariables = Exact<{
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
}>;


export type StudentUpcomingWorkshopsQuery = { __typename?: "Query", studentUpcomingWorkshops: Array<{ __typename?: "StudentUpcomingWorkshopOutput", id: number, name: string, slug: string, featuredImage?: string | null }> };

export type StudentPastWorkshopsQueryVariables = Exact<{
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
}>;


export type StudentPastWorkshopsQuery = { __typename?: "Query", studentPastWorkshops: Array<{ __typename?: "StudentWorkshopsOutput", id: number, name: string, slug: string, featuredImage?: string | null }> };

export type StudentRegisteredWorkshopsQueryVariables = Exact<{ [key: string]: never; }>;


export type StudentRegisteredWorkshopsQuery = { __typename?: "Query", studentRegisteredWorkshops: Array<{ __typename?: "StudentWorkshopsOutput", id: number, name: string, slug: string, featuredImage?: string | null }> };

export type PublicWorkshopQueryVariables = Exact<{
  where: StudentWorkshopUniqueInput;
}>;


export type PublicWorkshopQuery = { __typename?: "Query", publicWorkshop?: { __typename?: "PublicWorkshopOutput", id: number, name: string, slug: string, image?: string | null, description?: string | null, mode: string, type: string, ageGroup?: string | null, feesWithKit?: number | null, feesInCurrency?: number | null, feesWithoutKit?: number | null, metaTagData?: any | null, isOpen: boolean, headerImageLarge?: string | null, headerImageSmall?: string | null, footerImageLarge?: string | null, footerImageSmall?: string | null, headerVideo?: string | null, headerText?: string | null, displayTime?: string | null, trainerImage?: string | null, location?: string | null, address?: string | null, liveUrl?: string | null, insiderActivities?: Array<{ __typename?: "PublicActivity", id: number, title: string, featuredImage?: string | null, slug: string, timeRequired: string, difficultyLevel: string, commentsCount?: number | null, skill?: { __typename?: "PublicSkill", id: number, name: string, slug: string } | null }> | null, relatedActivities?: Array<{ __typename?: "PublicActivity", id: number, title: string, featuredImage?: string | null, slug: string, timeRequired: string, difficultyLevel: string, commentsCount?: number | null, skill?: { __typename?: "PublicSkill", id: number, name: string, slug: string } | null }> | null, standards?: Array<{ __typename?: "Standard", id: number, key: string, value: string }> | null, trainer?: { __typename?: "User", id: number, firstName: string, lastName: string } | null, product?: { __typename?: "PublicProduct", id: number } | null } | null };

export type StudentWorkshopAccessQueryVariables = Exact<{
  where: ActivityWhereUniqueInput;
}>;


export type StudentWorkshopAccessQuery = { __typename?: "Query", studentWorkshopAccess?: { __typename?: "FindStudentWorkshopAccessResponse", success: boolean, message: string } | null };


export const UnlockStudentActivityDocument = gql`
    mutation unlockStudentActivity($slug: String!) {
  unlockStudentActivity(where: {slug: $slug}) {
    id
  }
}
    `
export type UnlockStudentActivityMutationFn = Apollo.MutationFunction<UnlockStudentActivityMutation, UnlockStudentActivityMutationVariables>;

/**
 * __useUnlockStudentActivityMutation__
 *
 * To run a mutation, you first call `useUnlockStudentActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnlockStudentActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unlockStudentActivityMutation, { data, loading, error }] = useUnlockStudentActivityMutation({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useUnlockStudentActivityMutation(baseOptions?: Apollo.MutationHookOptions<UnlockStudentActivityMutation, UnlockStudentActivityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnlockStudentActivityMutation, UnlockStudentActivityMutationVariables>(UnlockStudentActivityDocument, options)
      }
export type UnlockStudentActivityMutationHookResult = ReturnType<typeof useUnlockStudentActivityMutation>;
export type UnlockStudentActivityMutationResult = Apollo.MutationResult<UnlockStudentActivityMutation>;
export type UnlockStudentActivityMutationOptions = Apollo.BaseMutationOptions<UnlockStudentActivityMutation, UnlockStudentActivityMutationVariables>;
export const ValidateActivityQuestionAnswerDocument = gql`
    mutation validateActivityQuestionAnswer($data: ValidateActivityQuestionAnswerInput!, $where: ActivityQuestionWhereUniqueInput!) {
  validateActivityQuestionAnswer(data: $data, where: $where) {
    isCorrect
    incorrectText
    answerText
  }
}
    `
export type ValidateActivityQuestionAnswerMutationFn = Apollo.MutationFunction<ValidateActivityQuestionAnswerMutation, ValidateActivityQuestionAnswerMutationVariables>;

/**
 * __useValidateActivityQuestionAnswerMutation__
 *
 * To run a mutation, you first call `useValidateActivityQuestionAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateActivityQuestionAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateActivityQuestionAnswerMutation, { data, loading, error }] = useValidateActivityQuestionAnswerMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useValidateActivityQuestionAnswerMutation(baseOptions?: Apollo.MutationHookOptions<ValidateActivityQuestionAnswerMutation, ValidateActivityQuestionAnswerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ValidateActivityQuestionAnswerMutation, ValidateActivityQuestionAnswerMutationVariables>(ValidateActivityQuestionAnswerDocument, options)
      }
export type ValidateActivityQuestionAnswerMutationHookResult = ReturnType<typeof useValidateActivityQuestionAnswerMutation>;
export type ValidateActivityQuestionAnswerMutationResult = Apollo.MutationResult<ValidateActivityQuestionAnswerMutation>;
export type ValidateActivityQuestionAnswerMutationOptions = Apollo.BaseMutationOptions<ValidateActivityQuestionAnswerMutation, ValidateActivityQuestionAnswerMutationVariables>;
export const ActivityQuestionSubmitDocument = gql`
    mutation activityQuestionSubmit($activity: ActivityWhereUniqueInput!, $data: [ValidateActivityQuestionSubmitInput!]!) {
  activityQuestionSubmit(activity: $activity, data: $data) {
    success
    message
  }
}
    `
export type ActivityQuestionSubmitMutationFn = Apollo.MutationFunction<ActivityQuestionSubmitMutation, ActivityQuestionSubmitMutationVariables>;

/**
 * __useActivityQuestionSubmitMutation__
 *
 * To run a mutation, you first call `useActivityQuestionSubmitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivityQuestionSubmitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activityQuestionSubmitMutation, { data, loading, error }] = useActivityQuestionSubmitMutation({
 *   variables: {
 *      activity: // value for 'activity'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useActivityQuestionSubmitMutation(baseOptions?: Apollo.MutationHookOptions<ActivityQuestionSubmitMutation, ActivityQuestionSubmitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ActivityQuestionSubmitMutation, ActivityQuestionSubmitMutationVariables>(ActivityQuestionSubmitDocument, options)
      }
export type ActivityQuestionSubmitMutationHookResult = ReturnType<typeof useActivityQuestionSubmitMutation>;
export type ActivityQuestionSubmitMutationResult = Apollo.MutationResult<ActivityQuestionSubmitMutation>;
export type ActivityQuestionSubmitMutationOptions = Apollo.BaseMutationOptions<ActivityQuestionSubmitMutation, ActivityQuestionSubmitMutationVariables>;
export const PublicActivityBannersDocument = gql`
    query publicActivityBanners {
  publicActivityBanners {
    id
    image
    destinationUrl
  }
}
    `

/**
 * __usePublicActivityBannersQuery__
 *
 * To run a query within a React component, call `usePublicActivityBannersQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicActivityBannersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicActivityBannersQuery({
 *   variables: {
 *   },
 * });
 */
export function usePublicActivityBannersQuery(baseOptions?: Apollo.QueryHookOptions<PublicActivityBannersQuery, PublicActivityBannersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PublicActivityBannersQuery, PublicActivityBannersQueryVariables>(PublicActivityBannersDocument, options)
      }
export function usePublicActivityBannersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PublicActivityBannersQuery, PublicActivityBannersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PublicActivityBannersQuery, PublicActivityBannersQueryVariables>(PublicActivityBannersDocument, options)
        }
export type PublicActivityBannersQueryHookResult = ReturnType<typeof usePublicActivityBannersQuery>;
export type PublicActivityBannersLazyQueryHookResult = ReturnType<typeof usePublicActivityBannersLazyQuery>;
export type PublicActivityBannersQueryResult = Apollo.QueryResult<PublicActivityBannersQuery, PublicActivityBannersQueryVariables>;
export const StudentActivitiesDocument = gql`
    query studentActivities($where: FindStudentManyActivityInput!, $skip: Int, $take: Int) {
  studentActivities(where: $where, skip: $skip, take: $take) {
    id
    slug
    title
    timeRequired
    difficultyLevel
    featuredImage
    skill {
      id
      name
      slug
    }
    commentsCount
  }
}
    `

/**
 * __useStudentActivitiesQuery__
 *
 * To run a query within a React component, call `useStudentActivitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentActivitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentActivitiesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useStudentActivitiesQuery(baseOptions: Apollo.QueryHookOptions<StudentActivitiesQuery, StudentActivitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StudentActivitiesQuery, StudentActivitiesQueryVariables>(StudentActivitiesDocument, options)
      }
export function useStudentActivitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StudentActivitiesQuery, StudentActivitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StudentActivitiesQuery, StudentActivitiesQueryVariables>(StudentActivitiesDocument, options)
        }
export type StudentActivitiesQueryHookResult = ReturnType<typeof useStudentActivitiesQuery>;
export type StudentActivitiesLazyQueryHookResult = ReturnType<typeof useStudentActivitiesLazyQuery>;
export type StudentActivitiesQueryResult = Apollo.QueryResult<StudentActivitiesQuery, StudentActivitiesQueryVariables>;
export const PublicActivityPageQueriesDocument = gql`
    query publicActivityPageQueries($publicLiveActivitiesWhere: FindPublicManyActivityInput!, $publicNewReleaseActivitiesWhere: FindPublicManyActivityInput!, $publicChallengeActivitiesWhere: FindPublicManyActivityInput!, $publicPopularActivitiesWhere: FindPublicManyActivityInput!) {
  publicActivitySkills: publicSkills(take: 10) {
    id
    name
    slug
    featuredImage
  }
  publicLiveActivities: publicActivities(where: $publicLiveActivitiesWhere) {
    id
    slug
    title
    timeRequired
    difficultyLevel
    featuredImage
    skill {
      id
      name
      slug
    }
    commentsCount
  }
  publicNewReleaseActivities: publicActivities(
    where: $publicNewReleaseActivitiesWhere
  ) {
    id
    slug
    title
    timeRequired
    difficultyLevel
    featuredImage
    skill {
      id
      name
      slug
    }
    commentsCount
  }
  publicChallengeActivities: publicActivities(
    where: $publicChallengeActivitiesWhere
  ) {
    id
    slug
    title
    timeRequired
    difficultyLevel
    featuredImage
    skill {
      id
      name
      slug
    }
    commentsCount
  }
  publicPopularActivities: publicActivities(where: $publicPopularActivitiesWhere) {
    id
    slug
    title
    timeRequired
    difficultyLevel
    featuredImage
    skill {
      id
      name
      slug
    }
    commentsCount
  }
}
    `

/**
 * __usePublicActivityPageQueriesQuery__
 *
 * To run a query within a React component, call `usePublicActivityPageQueriesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicActivityPageQueriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicActivityPageQueriesQuery({
 *   variables: {
 *      publicLiveActivitiesWhere: // value for 'publicLiveActivitiesWhere'
 *      publicNewReleaseActivitiesWhere: // value for 'publicNewReleaseActivitiesWhere'
 *      publicChallengeActivitiesWhere: // value for 'publicChallengeActivitiesWhere'
 *      publicPopularActivitiesWhere: // value for 'publicPopularActivitiesWhere'
 *   },
 * });
 */
export function usePublicActivityPageQueriesQuery(baseOptions: Apollo.QueryHookOptions<PublicActivityPageQueriesQuery, PublicActivityPageQueriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PublicActivityPageQueriesQuery, PublicActivityPageQueriesQueryVariables>(PublicActivityPageQueriesDocument, options)
      }
export function usePublicActivityPageQueriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PublicActivityPageQueriesQuery, PublicActivityPageQueriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PublicActivityPageQueriesQuery, PublicActivityPageQueriesQueryVariables>(PublicActivityPageQueriesDocument, options)
        }
export type PublicActivityPageQueriesQueryHookResult = ReturnType<typeof usePublicActivityPageQueriesQuery>;
export type PublicActivityPageQueriesLazyQueryHookResult = ReturnType<typeof usePublicActivityPageQueriesLazyQuery>;
export type PublicActivityPageQueriesQueryResult = Apollo.QueryResult<PublicActivityPageQueriesQuery, PublicActivityPageQueriesQueryVariables>;
export const PublicActivitiesDocument = gql`
    query publicActivities($where: FindPublicManyActivityInput!, $skip: Int, $take: Int) {
  publicActivities(where: $where, skip: $skip, take: $take) {
    id
    slug
    title
    timeRequired
    difficultyLevel
    featuredImage
    skill {
      id
      name
      slug
    }
    commentsCount
  }
}
    `

/**
 * __usePublicActivitiesQuery__
 *
 * To run a query within a React component, call `usePublicActivitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicActivitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicActivitiesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function usePublicActivitiesQuery(baseOptions: Apollo.QueryHookOptions<PublicActivitiesQuery, PublicActivitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PublicActivitiesQuery, PublicActivitiesQueryVariables>(PublicActivitiesDocument, options)
      }
export function usePublicActivitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PublicActivitiesQuery, PublicActivitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PublicActivitiesQuery, PublicActivitiesQueryVariables>(PublicActivitiesDocument, options)
        }
export type PublicActivitiesQueryHookResult = ReturnType<typeof usePublicActivitiesQuery>;
export type PublicActivitiesLazyQueryHookResult = ReturnType<typeof usePublicActivitiesLazyQuery>;
export type PublicActivitiesQueryResult = Apollo.QueryResult<PublicActivitiesQuery, PublicActivitiesQueryVariables>;
export const PublicActivityDocument = gql`
    query publicActivity($where: ActivityWhereUniqueInput!) {
  publicActivity(where: $where) {
    id
    title
    slug
    shortDescription
    description
    difficultyLevel
    timeRequired
    featuredImage
    challengeStatement
    materialsNeeded
    video
    instructionImage
    instructionText
    image
    xps
    isIndexable
    metaTagData
    jsonLD
    tags {
      id
      name
      slug
    }
    skill {
      id
      name
      slug
    }
  }
}
    `

/**
 * __usePublicActivityQuery__
 *
 * To run a query within a React component, call `usePublicActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicActivityQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function usePublicActivityQuery(baseOptions: Apollo.QueryHookOptions<PublicActivityQuery, PublicActivityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PublicActivityQuery, PublicActivityQueryVariables>(PublicActivityDocument, options)
      }
export function usePublicActivityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PublicActivityQuery, PublicActivityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PublicActivityQuery, PublicActivityQueryVariables>(PublicActivityDocument, options)
        }
export type PublicActivityQueryHookResult = ReturnType<typeof usePublicActivityQuery>;
export type PublicActivityLazyQueryHookResult = ReturnType<typeof usePublicActivityLazyQuery>;
export type PublicActivityQueryResult = Apollo.QueryResult<PublicActivityQuery, PublicActivityQueryVariables>;
export const FeaturedStudentActivitiesDocument = gql`
    query featuredStudentActivities {
  featuredStudentActivities {
    id
    title
    slug
    timeRequired
    difficultyLevel
    featuredImage
    skill {
      id
      name
      slug
    }
    commentsCount
  }
}
    `

/**
 * __useFeaturedStudentActivitiesQuery__
 *
 * To run a query within a React component, call `useFeaturedStudentActivitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeaturedStudentActivitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeaturedStudentActivitiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useFeaturedStudentActivitiesQuery(baseOptions?: Apollo.QueryHookOptions<FeaturedStudentActivitiesQuery, FeaturedStudentActivitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FeaturedStudentActivitiesQuery, FeaturedStudentActivitiesQueryVariables>(FeaturedStudentActivitiesDocument, options)
      }
export function useFeaturedStudentActivitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FeaturedStudentActivitiesQuery, FeaturedStudentActivitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FeaturedStudentActivitiesQuery, FeaturedStudentActivitiesQueryVariables>(FeaturedStudentActivitiesDocument, options)
        }
export type FeaturedStudentActivitiesQueryHookResult = ReturnType<typeof useFeaturedStudentActivitiesQuery>;
export type FeaturedStudentActivitiesLazyQueryHookResult = ReturnType<typeof useFeaturedStudentActivitiesLazyQuery>;
export type FeaturedStudentActivitiesQueryResult = Apollo.QueryResult<FeaturedStudentActivitiesQuery, FeaturedStudentActivitiesQueryVariables>;
export const StudentActivityAccessDocument = gql`
    query studentActivityAccess($slug: String!) {
  studentActivityAccess(where: {slug: $slug}) {
    isUnlocked
    isQaCompleted
    message
  }
}
    `

/**
 * __useStudentActivityAccessQuery__
 *
 * To run a query within a React component, call `useStudentActivityAccessQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentActivityAccessQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentActivityAccessQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useStudentActivityAccessQuery(baseOptions: Apollo.QueryHookOptions<StudentActivityAccessQuery, StudentActivityAccessQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StudentActivityAccessQuery, StudentActivityAccessQueryVariables>(StudentActivityAccessDocument, options)
      }
export function useStudentActivityAccessLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StudentActivityAccessQuery, StudentActivityAccessQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StudentActivityAccessQuery, StudentActivityAccessQueryVariables>(StudentActivityAccessDocument, options)
        }
export type StudentActivityAccessQueryHookResult = ReturnType<typeof useStudentActivityAccessQuery>;
export type StudentActivityAccessLazyQueryHookResult = ReturnType<typeof useStudentActivityAccessLazyQuery>;
export type StudentActivityAccessQueryResult = Apollo.QueryResult<StudentActivityAccessQuery, StudentActivityAccessQueryVariables>;
export const StudentActivityPartialDocument = gql`
    query studentActivityPartial($where: ActivityWhereUniqueInput!) {
  studentActivity(where: $where) {
    id
    slug
    title
    shortDescription
    description
    featuredImage
    image
    xps
    questionXps
    metaTagData
    jsonLD
    timeRequired
    difficultyLevel
    coinsToSpend
    skill {
      id
      name
      slug
    }
    tags {
      id
      name
      slug
    }
  }
}
    `

/**
 * __useStudentActivityPartialQuery__
 *
 * To run a query within a React component, call `useStudentActivityPartialQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentActivityPartialQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentActivityPartialQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useStudentActivityPartialQuery(baseOptions: Apollo.QueryHookOptions<StudentActivityPartialQuery, StudentActivityPartialQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StudentActivityPartialQuery, StudentActivityPartialQueryVariables>(StudentActivityPartialDocument, options)
      }
export function useStudentActivityPartialLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StudentActivityPartialQuery, StudentActivityPartialQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StudentActivityPartialQuery, StudentActivityPartialQueryVariables>(StudentActivityPartialDocument, options)
        }
export type StudentActivityPartialQueryHookResult = ReturnType<typeof useStudentActivityPartialQuery>;
export type StudentActivityPartialLazyQueryHookResult = ReturnType<typeof useStudentActivityPartialLazyQuery>;
export type StudentActivityPartialQueryResult = Apollo.QueryResult<StudentActivityPartialQuery, StudentActivityPartialQueryVariables>;
export const StudentActivityWithoutQuestionsDocument = gql`
    query studentActivityWithoutQuestions($where: ActivityWhereUniqueInput!) {
  studentActivity(where: $where) {
    id
    slug
    title
    shortDescription
    description
    image
    featuredImage
    xps
    questionXps
    metaTagData
    jsonLD
    tags {
      id
      name
      slug
    }
    skill {
      id
      name
      slug
    }
    nextActivity {
      id
      slug
      skill {
        id
        slug
      }
    }
    timeRequired
    difficultyLevel
    video
    challengeStatement
    materialsNeeded
    instructionImage
    instructionText
    coinsToSpend
    isCommentEnabled
  }
}
    `

/**
 * __useStudentActivityWithoutQuestionsQuery__
 *
 * To run a query within a React component, call `useStudentActivityWithoutQuestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentActivityWithoutQuestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentActivityWithoutQuestionsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useStudentActivityWithoutQuestionsQuery(baseOptions: Apollo.QueryHookOptions<StudentActivityWithoutQuestionsQuery, StudentActivityWithoutQuestionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StudentActivityWithoutQuestionsQuery, StudentActivityWithoutQuestionsQueryVariables>(StudentActivityWithoutQuestionsDocument, options)
      }
export function useStudentActivityWithoutQuestionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StudentActivityWithoutQuestionsQuery, StudentActivityWithoutQuestionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StudentActivityWithoutQuestionsQuery, StudentActivityWithoutQuestionsQueryVariables>(StudentActivityWithoutQuestionsDocument, options)
        }
export type StudentActivityWithoutQuestionsQueryHookResult = ReturnType<typeof useStudentActivityWithoutQuestionsQuery>;
export type StudentActivityWithoutQuestionsLazyQueryHookResult = ReturnType<typeof useStudentActivityWithoutQuestionsLazyQuery>;
export type StudentActivityWithoutQuestionsQueryResult = Apollo.QueryResult<StudentActivityWithoutQuestionsQuery, StudentActivityWithoutQuestionsQueryVariables>;
export const StudentActivityWithQuestionsDocument = gql`
    query studentActivityWithQuestions($where: ActivityWhereUniqueInput!) {
  studentActivity(where: $where) {
    id
    slug
    title
    shortDescription
    description
    image
    featuredImage
    xps
    questionXps
    metaTagData
    jsonLD
    tags {
      id
      name
      slug
    }
    skill {
      id
      name
      slug
    }
    nextActivity {
      id
      slug
      skill {
        id
        slug
      }
    }
    timeRequired
    difficultyLevel
    video
    challengeStatement
    materialsNeeded
    instructionImage
    instructionText
    coinsToSpend
    isCommentEnabled
    questions {
      id
      type
      questionText
      options
    }
  }
}
    `

/**
 * __useStudentActivityWithQuestionsQuery__
 *
 * To run a query within a React component, call `useStudentActivityWithQuestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentActivityWithQuestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentActivityWithQuestionsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useStudentActivityWithQuestionsQuery(baseOptions: Apollo.QueryHookOptions<StudentActivityWithQuestionsQuery, StudentActivityWithQuestionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StudentActivityWithQuestionsQuery, StudentActivityWithQuestionsQueryVariables>(StudentActivityWithQuestionsDocument, options)
      }
export function useStudentActivityWithQuestionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StudentActivityWithQuestionsQuery, StudentActivityWithQuestionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StudentActivityWithQuestionsQuery, StudentActivityWithQuestionsQueryVariables>(StudentActivityWithQuestionsDocument, options)
        }
export type StudentActivityWithQuestionsQueryHookResult = ReturnType<typeof useStudentActivityWithQuestionsQuery>;
export type StudentActivityWithQuestionsLazyQueryHookResult = ReturnType<typeof useStudentActivityWithQuestionsLazyQuery>;
export type StudentActivityWithQuestionsQueryResult = Apollo.QueryResult<StudentActivityWithQuestionsQuery, StudentActivityWithQuestionsQueryVariables>;
export const StudentPendingActivitiesDocument = gql`
    query studentPendingActivities {
  studentPendingActivities {
    id
    title
    slug
    timeRequired
    difficultyLevel
    featuredImage
    skill {
      id
      name
      slug
    }
    commentsCount
  }
}
    `

/**
 * __useStudentPendingActivitiesQuery__
 *
 * To run a query within a React component, call `useStudentPendingActivitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentPendingActivitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentPendingActivitiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useStudentPendingActivitiesQuery(baseOptions?: Apollo.QueryHookOptions<StudentPendingActivitiesQuery, StudentPendingActivitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StudentPendingActivitiesQuery, StudentPendingActivitiesQueryVariables>(StudentPendingActivitiesDocument, options)
      }
export function useStudentPendingActivitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StudentPendingActivitiesQuery, StudentPendingActivitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StudentPendingActivitiesQuery, StudentPendingActivitiesQueryVariables>(StudentPendingActivitiesDocument, options)
        }
export type StudentPendingActivitiesQueryHookResult = ReturnType<typeof useStudentPendingActivitiesQuery>;
export type StudentPendingActivitiesLazyQueryHookResult = ReturnType<typeof useStudentPendingActivitiesLazyQuery>;
export type StudentPendingActivitiesQueryResult = Apollo.QueryResult<StudentPendingActivitiesQuery, StudentPendingActivitiesQueryVariables>;
export const StudentNextRandomActivityDocument = gql`
    query studentNextRandomActivity($where: StudentNextRandomActivityInput) {
  studentNextRandomActivity(where: $where) {
    id
    slug
    skill {
      id
      slug
    }
  }
}
    `

/**
 * __useStudentNextRandomActivityQuery__
 *
 * To run a query within a React component, call `useStudentNextRandomActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentNextRandomActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentNextRandomActivityQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useStudentNextRandomActivityQuery(baseOptions?: Apollo.QueryHookOptions<StudentNextRandomActivityQuery, StudentNextRandomActivityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StudentNextRandomActivityQuery, StudentNextRandomActivityQueryVariables>(StudentNextRandomActivityDocument, options)
      }
export function useStudentNextRandomActivityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StudentNextRandomActivityQuery, StudentNextRandomActivityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StudentNextRandomActivityQuery, StudentNextRandomActivityQueryVariables>(StudentNextRandomActivityDocument, options)
        }
export type StudentNextRandomActivityQueryHookResult = ReturnType<typeof useStudentNextRandomActivityQuery>;
export type StudentNextRandomActivityLazyQueryHookResult = ReturnType<typeof useStudentNextRandomActivityLazyQuery>;
export type StudentNextRandomActivityQueryResult = Apollo.QueryResult<StudentNextRandomActivityQuery, StudentNextRandomActivityQueryVariables>;
export const LogInDocument = gql`
    mutation logIn($data: LogInInput!) {
  logIn(data: $data) {
    authToken
    id
    userId
    expiresAt
    message
    rules
    success
  }
}
    `
export type LogInMutationFn = Apollo.MutationFunction<LogInMutation, LogInMutationVariables>;

/**
 * __useLogInMutation__
 *
 * To run a mutation, you first call `useLogInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logInMutation, { data, loading, error }] = useLogInMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useLogInMutation(baseOptions?: Apollo.MutationHookOptions<LogInMutation, LogInMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogInMutation, LogInMutationVariables>(LogInDocument, options)
      }
export type LogInMutationHookResult = ReturnType<typeof useLogInMutation>;
export type LogInMutationResult = Apollo.MutationResult<LogInMutation>;
export type LogInMutationOptions = Apollo.BaseMutationOptions<LogInMutation, LogInMutationVariables>;
export const SignUpDocument = gql`
    mutation signUp($data: SignUpInput!) {
  signUp(data: $data) {
    authToken
    id
    userId
    expiresAt
    message
    rules
    success
  }
}
    `
export type SignUpMutationFn = Apollo.MutationFunction<SignUpMutation, SignUpMutationVariables>;

/**
 * __useSignUpMutation__
 *
 * To run a mutation, you first call `useSignUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpMutation, { data, loading, error }] = useSignUpMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSignUpMutation(baseOptions?: Apollo.MutationHookOptions<SignUpMutation, SignUpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignUpMutation, SignUpMutationVariables>(SignUpDocument, options)
      }
export type SignUpMutationHookResult = ReturnType<typeof useSignUpMutation>;
export type SignUpMutationResult = Apollo.MutationResult<SignUpMutation>;
export type SignUpMutationOptions = Apollo.BaseMutationOptions<SignUpMutation, SignUpMutationVariables>;
export const RefreshTokenDocument = gql`
    mutation refreshToken {
  refreshToken {
    authToken
    expiresAt
  }
}
    `
export type RefreshTokenMutationFn = Apollo.MutationFunction<RefreshTokenMutation, RefreshTokenMutationVariables>;

/**
 * __useRefreshTokenMutation__
 *
 * To run a mutation, you first call `useRefreshTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshTokenMutation, { data, loading, error }] = useRefreshTokenMutation({
 *   variables: {
 *   },
 * });
 */
export function useRefreshTokenMutation(baseOptions?: Apollo.MutationHookOptions<RefreshTokenMutation, RefreshTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RefreshTokenMutation, RefreshTokenMutationVariables>(RefreshTokenDocument, options)
      }
export type RefreshTokenMutationHookResult = ReturnType<typeof useRefreshTokenMutation>;
export type RefreshTokenMutationResult = Apollo.MutationResult<RefreshTokenMutation>;
export type RefreshTokenMutationOptions = Apollo.BaseMutationOptions<RefreshTokenMutation, RefreshTokenMutationVariables>;
export const StandardsDocument = gql`
    query standards {
  standards {
    id
    key
    value
  }
}
    `

/**
 * __useStandardsQuery__
 *
 * To run a query within a React component, call `useStandardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStandardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStandardsQuery({
 *   variables: {
 *   },
 * });
 */
export function useStandardsQuery(baseOptions?: Apollo.QueryHookOptions<StandardsQuery, StandardsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StandardsQuery, StandardsQueryVariables>(StandardsDocument, options)
      }
export function useStandardsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StandardsQuery, StandardsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StandardsQuery, StandardsQueryVariables>(StandardsDocument, options)
        }
export type StandardsQueryHookResult = ReturnType<typeof useStandardsQuery>;
export type StandardsLazyQueryHookResult = ReturnType<typeof useStandardsLazyQuery>;
export type StandardsQueryResult = Apollo.QueryResult<StandardsQuery, StandardsQueryVariables>;
export const StudentCreateOrderDocument = gql`
    mutation studentCreateOrder($data: StudentCreateOrderProductInput!) {
  studentCreateOrder(data: $data) {
    paymentUrl
    success
    message
  }
}
    `
export type StudentCreateOrderMutationFn = Apollo.MutationFunction<StudentCreateOrderMutation, StudentCreateOrderMutationVariables>;

/**
 * __useStudentCreateOrderMutation__
 *
 * To run a mutation, you first call `useStudentCreateOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStudentCreateOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [studentCreateOrderMutation, { data, loading, error }] = useStudentCreateOrderMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useStudentCreateOrderMutation(baseOptions?: Apollo.MutationHookOptions<StudentCreateOrderMutation, StudentCreateOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StudentCreateOrderMutation, StudentCreateOrderMutationVariables>(StudentCreateOrderDocument, options)
      }
export type StudentCreateOrderMutationHookResult = ReturnType<typeof useStudentCreateOrderMutation>;
export type StudentCreateOrderMutationResult = Apollo.MutationResult<StudentCreateOrderMutation>;
export type StudentCreateOrderMutationOptions = Apollo.BaseMutationOptions<StudentCreateOrderMutation, StudentCreateOrderMutationVariables>;
export const StudentReferralCodeDocument = gql`
    query studentReferralCode {
  studentReferralCode {
    code
    success
    message
  }
}
    `

/**
 * __useStudentReferralCodeQuery__
 *
 * To run a query within a React component, call `useStudentReferralCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentReferralCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentReferralCodeQuery({
 *   variables: {
 *   },
 * });
 */
export function useStudentReferralCodeQuery(baseOptions?: Apollo.QueryHookOptions<StudentReferralCodeQuery, StudentReferralCodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StudentReferralCodeQuery, StudentReferralCodeQueryVariables>(StudentReferralCodeDocument, options)
      }
export function useStudentReferralCodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StudentReferralCodeQuery, StudentReferralCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StudentReferralCodeQuery, StudentReferralCodeQueryVariables>(StudentReferralCodeDocument, options)
        }
export type StudentReferralCodeQueryHookResult = ReturnType<typeof useStudentReferralCodeQuery>;
export type StudentReferralCodeLazyQueryHookResult = ReturnType<typeof useStudentReferralCodeLazyQuery>;
export type StudentReferralCodeQueryResult = Apollo.QueryResult<StudentReferralCodeQuery, StudentReferralCodeQueryVariables>;
export const GenerateUploadSignedUrlDocument = gql`
    mutation generateUploadSignedUrl($data: GenerateUploadSignedUrlInput!) {
  generateUploadSignedUrl(data: $data) {
    name
    signedUrl
  }
}
    `
export type GenerateUploadSignedUrlMutationFn = Apollo.MutationFunction<GenerateUploadSignedUrlMutation, GenerateUploadSignedUrlMutationVariables>;

/**
 * __useGenerateUploadSignedUrlMutation__
 *
 * To run a mutation, you first call `useGenerateUploadSignedUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateUploadSignedUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateUploadSignedUrlMutation, { data, loading, error }] = useGenerateUploadSignedUrlMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGenerateUploadSignedUrlMutation(baseOptions?: Apollo.MutationHookOptions<GenerateUploadSignedUrlMutation, GenerateUploadSignedUrlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateUploadSignedUrlMutation, GenerateUploadSignedUrlMutationVariables>(GenerateUploadSignedUrlDocument, options)
      }
export type GenerateUploadSignedUrlMutationHookResult = ReturnType<typeof useGenerateUploadSignedUrlMutation>;
export type GenerateUploadSignedUrlMutationResult = Apollo.MutationResult<GenerateUploadSignedUrlMutation>;
export type GenerateUploadSignedUrlMutationOptions = Apollo.BaseMutationOptions<GenerateUploadSignedUrlMutation, GenerateUploadSignedUrlMutationVariables>;
export const CreateCommentDocument = gql`
    mutation createComment($data: StudentCreateCommentInput!) {
  createComment(data: $data) {
    id
  }
}
    `
export type CreateCommentMutationFn = Apollo.MutationFunction<CreateCommentMutation, CreateCommentMutationVariables>;

/**
 * __useCreateCommentMutation__
 *
 * To run a mutation, you first call `useCreateCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCommentMutation, { data, loading, error }] = useCreateCommentMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateCommentMutation(baseOptions?: Apollo.MutationHookOptions<CreateCommentMutation, CreateCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCommentMutation, CreateCommentMutationVariables>(CreateCommentDocument, options)
      }
export type CreateCommentMutationHookResult = ReturnType<typeof useCreateCommentMutation>;
export type CreateCommentMutationResult = Apollo.MutationResult<CreateCommentMutation>;
export type CreateCommentMutationOptions = Apollo.BaseMutationOptions<CreateCommentMutation, CreateCommentMutationVariables>;
export const StudentActivityCommentsDocument = gql`
    query studentActivityComments($where: FindStudentActivityCommentsInput!, $skip: Int, $take: Int) {
  studentActivityComments(where: $where, skip: $skip, take: $take) {
    id
    text
    attachmentUrl
    createdBy {
      displayName
      profileImage
    }
    createdAt
  }
}
    `

/**
 * __useStudentActivityCommentsQuery__
 *
 * To run a query within a React component, call `useStudentActivityCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentActivityCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentActivityCommentsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useStudentActivityCommentsQuery(baseOptions: Apollo.QueryHookOptions<StudentActivityCommentsQuery, StudentActivityCommentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StudentActivityCommentsQuery, StudentActivityCommentsQueryVariables>(StudentActivityCommentsDocument, options)
      }
export function useStudentActivityCommentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StudentActivityCommentsQuery, StudentActivityCommentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StudentActivityCommentsQuery, StudentActivityCommentsQueryVariables>(StudentActivityCommentsDocument, options)
        }
export type StudentActivityCommentsQueryHookResult = ReturnType<typeof useStudentActivityCommentsQuery>;
export type StudentActivityCommentsLazyQueryHookResult = ReturnType<typeof useStudentActivityCommentsLazyQuery>;
export type StudentActivityCommentsQueryResult = Apollo.QueryResult<StudentActivityCommentsQuery, StudentActivityCommentsQueryVariables>;
export const StudentActivitySelfCommentsDocument = gql`
    query studentActivitySelfComments($where: FindStudentActivityCommentsInput!, $skip: Int, $take: Int) {
  studentActivitySelfComments(where: $where, skip: $skip, take: $take) {
    id
    text
    attachmentUrl
    meta
    status
    createdBy {
      displayName
      profileImage
    }
    createdAt
  }
}
    `

/**
 * __useStudentActivitySelfCommentsQuery__
 *
 * To run a query within a React component, call `useStudentActivitySelfCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentActivitySelfCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentActivitySelfCommentsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useStudentActivitySelfCommentsQuery(baseOptions: Apollo.QueryHookOptions<StudentActivitySelfCommentsQuery, StudentActivitySelfCommentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StudentActivitySelfCommentsQuery, StudentActivitySelfCommentsQueryVariables>(StudentActivitySelfCommentsDocument, options)
      }
export function useStudentActivitySelfCommentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StudentActivitySelfCommentsQuery, StudentActivitySelfCommentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StudentActivitySelfCommentsQuery, StudentActivitySelfCommentsQueryVariables>(StudentActivitySelfCommentsDocument, options)
        }
export type StudentActivitySelfCommentsQueryHookResult = ReturnType<typeof useStudentActivitySelfCommentsQuery>;
export type StudentActivitySelfCommentsLazyQueryHookResult = ReturnType<typeof useStudentActivitySelfCommentsLazyQuery>;
export type StudentActivitySelfCommentsQueryResult = Apollo.QueryResult<StudentActivitySelfCommentsQuery, StudentActivitySelfCommentsQueryVariables>;
export const AnswerStudentDailyActivityDocument = gql`
    mutation answerStudentDailyActivity($data: AnswerStudentDailyActivityInput!, $where: DailyActivityWhereUniqueInput!) {
  answerStudentDailyActivity(data: $data, where: $where) {
    id
    answer
    answerText
  }
}
    `
export type AnswerStudentDailyActivityMutationFn = Apollo.MutationFunction<AnswerStudentDailyActivityMutation, AnswerStudentDailyActivityMutationVariables>;

/**
 * __useAnswerStudentDailyActivityMutation__
 *
 * To run a mutation, you first call `useAnswerStudentDailyActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAnswerStudentDailyActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [answerStudentDailyActivityMutation, { data, loading, error }] = useAnswerStudentDailyActivityMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAnswerStudentDailyActivityMutation(baseOptions?: Apollo.MutationHookOptions<AnswerStudentDailyActivityMutation, AnswerStudentDailyActivityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AnswerStudentDailyActivityMutation, AnswerStudentDailyActivityMutationVariables>(AnswerStudentDailyActivityDocument, options)
      }
export type AnswerStudentDailyActivityMutationHookResult = ReturnType<typeof useAnswerStudentDailyActivityMutation>;
export type AnswerStudentDailyActivityMutationResult = Apollo.MutationResult<AnswerStudentDailyActivityMutation>;
export type AnswerStudentDailyActivityMutationOptions = Apollo.BaseMutationOptions<AnswerStudentDailyActivityMutation, AnswerStudentDailyActivityMutationVariables>;
export const StudentDailyActivityDocument = gql`
    query studentDailyActivity($data: StudentDailyActivityInput!) {
  studentDailyActivity(data: $data) {
    id
    question
    options
    questionImage
    questionVideo
  }
}
    `

/**
 * __useStudentDailyActivityQuery__
 *
 * To run a query within a React component, call `useStudentDailyActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentDailyActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentDailyActivityQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useStudentDailyActivityQuery(baseOptions: Apollo.QueryHookOptions<StudentDailyActivityQuery, StudentDailyActivityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StudentDailyActivityQuery, StudentDailyActivityQueryVariables>(StudentDailyActivityDocument, options)
      }
export function useStudentDailyActivityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StudentDailyActivityQuery, StudentDailyActivityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StudentDailyActivityQuery, StudentDailyActivityQueryVariables>(StudentDailyActivityDocument, options)
        }
export type StudentDailyActivityQueryHookResult = ReturnType<typeof useStudentDailyActivityQuery>;
export type StudentDailyActivityLazyQueryHookResult = ReturnType<typeof useStudentDailyActivityLazyQuery>;
export type StudentDailyActivityQueryResult = Apollo.QueryResult<StudentDailyActivityQuery, StudentDailyActivityQueryVariables>;
export const StudentDailyChallengeDocument = gql`
    query studentDailyChallenge($data: StudentDailyChallengeInput!) {
  studentDailyChallenge(data: $data) {
    id
    title
    video
    image
    type
    challengeStatement
  }
}
    `

/**
 * __useStudentDailyChallengeQuery__
 *
 * To run a query within a React component, call `useStudentDailyChallengeQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentDailyChallengeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentDailyChallengeQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useStudentDailyChallengeQuery(baseOptions: Apollo.QueryHookOptions<StudentDailyChallengeQuery, StudentDailyChallengeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StudentDailyChallengeQuery, StudentDailyChallengeQueryVariables>(StudentDailyChallengeDocument, options)
      }
export function useStudentDailyChallengeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StudentDailyChallengeQuery, StudentDailyChallengeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StudentDailyChallengeQuery, StudentDailyChallengeQueryVariables>(StudentDailyChallengeDocument, options)
        }
export type StudentDailyChallengeQueryHookResult = ReturnType<typeof useStudentDailyChallengeQuery>;
export type StudentDailyChallengeLazyQueryHookResult = ReturnType<typeof useStudentDailyChallengeLazyQuery>;
export type StudentDailyChallengeQueryResult = Apollo.QueryResult<StudentDailyChallengeQuery, StudentDailyChallengeQueryVariables>;
export const StudentLeaderboardDocument = gql`
    query studentLeaderboard {
  studentLeaderboard {
    name
    xps
    stars
    level
    profileImage
  }
}
    `

/**
 * __useStudentLeaderboardQuery__
 *
 * To run a query within a React component, call `useStudentLeaderboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentLeaderboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentLeaderboardQuery({
 *   variables: {
 *   },
 * });
 */
export function useStudentLeaderboardQuery(baseOptions?: Apollo.QueryHookOptions<StudentLeaderboardQuery, StudentLeaderboardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StudentLeaderboardQuery, StudentLeaderboardQueryVariables>(StudentLeaderboardDocument, options)
      }
export function useStudentLeaderboardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StudentLeaderboardQuery, StudentLeaderboardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StudentLeaderboardQuery, StudentLeaderboardQueryVariables>(StudentLeaderboardDocument, options)
        }
export type StudentLeaderboardQueryHookResult = ReturnType<typeof useStudentLeaderboardQuery>;
export type StudentLeaderboardLazyQueryHookResult = ReturnType<typeof useStudentLeaderboardLazyQuery>;
export type StudentLeaderboardQueryResult = Apollo.QueryResult<StudentLeaderboardQuery, StudentLeaderboardQueryVariables>;
export const StudentTopPerformerMonthLeaderboardDocument = gql`
    query studentTopPerformerMonthLeaderboard {
  studentTopPerformerMonthLeaderboard {
    name
    xps
    stars
    level
    profileImage
  }
}
    `

/**
 * __useStudentTopPerformerMonthLeaderboardQuery__
 *
 * To run a query within a React component, call `useStudentTopPerformerMonthLeaderboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentTopPerformerMonthLeaderboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentTopPerformerMonthLeaderboardQuery({
 *   variables: {
 *   },
 * });
 */
export function useStudentTopPerformerMonthLeaderboardQuery(baseOptions?: Apollo.QueryHookOptions<StudentTopPerformerMonthLeaderboardQuery, StudentTopPerformerMonthLeaderboardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StudentTopPerformerMonthLeaderboardQuery, StudentTopPerformerMonthLeaderboardQueryVariables>(StudentTopPerformerMonthLeaderboardDocument, options)
      }
export function useStudentTopPerformerMonthLeaderboardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StudentTopPerformerMonthLeaderboardQuery, StudentTopPerformerMonthLeaderboardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StudentTopPerformerMonthLeaderboardQuery, StudentTopPerformerMonthLeaderboardQueryVariables>(StudentTopPerformerMonthLeaderboardDocument, options)
        }
export type StudentTopPerformerMonthLeaderboardQueryHookResult = ReturnType<typeof useStudentTopPerformerMonthLeaderboardQuery>;
export type StudentTopPerformerMonthLeaderboardLazyQueryHookResult = ReturnType<typeof useStudentTopPerformerMonthLeaderboardLazyQuery>;
export type StudentTopPerformerMonthLeaderboardQueryResult = Apollo.QueryResult<StudentTopPerformerMonthLeaderboardQuery, StudentTopPerformerMonthLeaderboardQueryVariables>;
export const StudentTopPerformerWeekLeaderboardDocument = gql`
    query studentTopPerformerWeekLeaderboard {
  studentTopPerformerWeekLeaderboard {
    name
    xps
    stars
    level
    profileImage
  }
}
    `

/**
 * __useStudentTopPerformerWeekLeaderboardQuery__
 *
 * To run a query within a React component, call `useStudentTopPerformerWeekLeaderboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentTopPerformerWeekLeaderboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentTopPerformerWeekLeaderboardQuery({
 *   variables: {
 *   },
 * });
 */
export function useStudentTopPerformerWeekLeaderboardQuery(baseOptions?: Apollo.QueryHookOptions<StudentTopPerformerWeekLeaderboardQuery, StudentTopPerformerWeekLeaderboardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StudentTopPerformerWeekLeaderboardQuery, StudentTopPerformerWeekLeaderboardQueryVariables>(StudentTopPerformerWeekLeaderboardDocument, options)
      }
export function useStudentTopPerformerWeekLeaderboardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StudentTopPerformerWeekLeaderboardQuery, StudentTopPerformerWeekLeaderboardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StudentTopPerformerWeekLeaderboardQuery, StudentTopPerformerWeekLeaderboardQueryVariables>(StudentTopPerformerWeekLeaderboardDocument, options)
        }
export type StudentTopPerformerWeekLeaderboardQueryHookResult = ReturnType<typeof useStudentTopPerformerWeekLeaderboardQuery>;
export type StudentTopPerformerWeekLeaderboardLazyQueryHookResult = ReturnType<typeof useStudentTopPerformerWeekLeaderboardLazyQuery>;
export type StudentTopPerformerWeekLeaderboardQueryResult = Apollo.QueryResult<StudentTopPerformerWeekLeaderboardQuery, StudentTopPerformerWeekLeaderboardQueryVariables>;
export const PublicProductBannersDocument = gql`
    query publicProductBanners {
  publicProductBanners {
    id
    image
    destinationUrl
    displayOrder
  }
}
    `

/**
 * __usePublicProductBannersQuery__
 *
 * To run a query within a React component, call `usePublicProductBannersQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicProductBannersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicProductBannersQuery({
 *   variables: {
 *   },
 * });
 */
export function usePublicProductBannersQuery(baseOptions?: Apollo.QueryHookOptions<PublicProductBannersQuery, PublicProductBannersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PublicProductBannersQuery, PublicProductBannersQueryVariables>(PublicProductBannersDocument, options)
      }
export function usePublicProductBannersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PublicProductBannersQuery, PublicProductBannersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PublicProductBannersQuery, PublicProductBannersQueryVariables>(PublicProductBannersDocument, options)
        }
export type PublicProductBannersQueryHookResult = ReturnType<typeof usePublicProductBannersQuery>;
export type PublicProductBannersLazyQueryHookResult = ReturnType<typeof usePublicProductBannersLazyQuery>;
export type PublicProductBannersQueryResult = Apollo.QueryResult<PublicProductBannersQuery, PublicProductBannersQueryVariables>;
export const StudentProductDocument = gql`
    query studentProduct($where: ProductWhereUniqueInput!) {
  studentProduct(where: $where) {
    id
    slug
    name
    featuredImage
    price
    discountPrice
    shortDescription
    description
    age
    images
    inCurrency
    video
    isBestSelling
    isNewReleased
    isVirtual
    skill {
      id
      slug
      name
    }
    purchaseLink
  }
}
    `

/**
 * __useStudentProductQuery__
 *
 * To run a query within a React component, call `useStudentProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentProductQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useStudentProductQuery(baseOptions: Apollo.QueryHookOptions<StudentProductQuery, StudentProductQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StudentProductQuery, StudentProductQueryVariables>(StudentProductDocument, options)
      }
export function useStudentProductLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StudentProductQuery, StudentProductQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StudentProductQuery, StudentProductQueryVariables>(StudentProductDocument, options)
        }
export type StudentProductQueryHookResult = ReturnType<typeof useStudentProductQuery>;
export type StudentProductLazyQueryHookResult = ReturnType<typeof useStudentProductLazyQuery>;
export type StudentProductQueryResult = Apollo.QueryResult<StudentProductQuery, StudentProductQueryVariables>;
export const PublicProductsDocument = gql`
    query publicProducts($where: StudentProductWhereInput, $take: Int, $skip: Int) {
  publicProducts(where: $where, take: $take, skip: $skip) {
    id
    slug
    name
    age
    featuredImage
    price
    discountPrice
  }
}
    `

/**
 * __usePublicProductsQuery__
 *
 * To run a query within a React component, call `usePublicProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicProductsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function usePublicProductsQuery(baseOptions?: Apollo.QueryHookOptions<PublicProductsQuery, PublicProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PublicProductsQuery, PublicProductsQueryVariables>(PublicProductsDocument, options)
      }
export function usePublicProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PublicProductsQuery, PublicProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PublicProductsQuery, PublicProductsQueryVariables>(PublicProductsDocument, options)
        }
export type PublicProductsQueryHookResult = ReturnType<typeof usePublicProductsQuery>;
export type PublicProductsLazyQueryHookResult = ReturnType<typeof usePublicProductsLazyQuery>;
export type PublicProductsQueryResult = Apollo.QueryResult<PublicProductsQuery, PublicProductsQueryVariables>;
export const ProductPageDataDocument = gql`
    query productPageData {
  publicProductBanners: publicProductBanners {
    id
    image
    destinationUrl
    displayOrder
  }
  newReleasedProducts: publicProducts(
    where: {isNewReleased: {equals: true}, isVirtual: {equals: false}}
  ) {
    id
    slug
    name
    age
    featuredImage
    price
    discountPrice
  }
}
    `

/**
 * __useProductPageDataQuery__
 *
 * To run a query within a React component, call `useProductPageDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductPageDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductPageDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useProductPageDataQuery(baseOptions?: Apollo.QueryHookOptions<ProductPageDataQuery, ProductPageDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductPageDataQuery, ProductPageDataQueryVariables>(ProductPageDataDocument, options)
      }
export function useProductPageDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductPageDataQuery, ProductPageDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductPageDataQuery, ProductPageDataQueryVariables>(ProductPageDataDocument, options)
        }
export type ProductPageDataQueryHookResult = ReturnType<typeof useProductPageDataQuery>;
export type ProductPageDataLazyQueryHookResult = ReturnType<typeof useProductPageDataLazyQuery>;
export type ProductPageDataQueryResult = Apollo.QueryResult<ProductPageDataQuery, ProductPageDataQueryVariables>;
export const ApplyPromoCodeDocument = gql`
    mutation applyPromoCode($code: String!) {
  applyPromoCode(code: $code) {
    success
    message
  }
}
    `
export type ApplyPromoCodeMutationFn = Apollo.MutationFunction<ApplyPromoCodeMutation, ApplyPromoCodeMutationVariables>;

/**
 * __useApplyPromoCodeMutation__
 *
 * To run a mutation, you first call `useApplyPromoCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApplyPromoCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [applyPromoCodeMutation, { data, loading, error }] = useApplyPromoCodeMutation({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useApplyPromoCodeMutation(baseOptions?: Apollo.MutationHookOptions<ApplyPromoCodeMutation, ApplyPromoCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApplyPromoCodeMutation, ApplyPromoCodeMutationVariables>(ApplyPromoCodeDocument, options)
      }
export type ApplyPromoCodeMutationHookResult = ReturnType<typeof useApplyPromoCodeMutation>;
export type ApplyPromoCodeMutationResult = Apollo.MutationResult<ApplyPromoCodeMutation>;
export type ApplyPromoCodeMutationOptions = Apollo.BaseMutationOptions<ApplyPromoCodeMutation, ApplyPromoCodeMutationVariables>;
export const StudentRewardDocument = gql`
    query studentReward {
  studentReward {
    level
    coins
    totalXps
    stars
    badges {
      id
      name
      image
    }
  }
}
    `

/**
 * __useStudentRewardQuery__
 *
 * To run a query within a React component, call `useStudentRewardQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentRewardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentRewardQuery({
 *   variables: {
 *   },
 * });
 */
export function useStudentRewardQuery(baseOptions?: Apollo.QueryHookOptions<StudentRewardQuery, StudentRewardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StudentRewardQuery, StudentRewardQueryVariables>(StudentRewardDocument, options)
      }
export function useStudentRewardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StudentRewardQuery, StudentRewardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StudentRewardQuery, StudentRewardQueryVariables>(StudentRewardDocument, options)
        }
export type StudentRewardQueryHookResult = ReturnType<typeof useStudentRewardQuery>;
export type StudentRewardLazyQueryHookResult = ReturnType<typeof useStudentRewardLazyQuery>;
export type StudentRewardQueryResult = Apollo.QueryResult<StudentRewardQuery, StudentRewardQueryVariables>;
export const StudentSkillXpsDocument = gql`
    query studentSkillXps {
  studentSkillXps {
    data
  }
}
    `

/**
 * __useStudentSkillXpsQuery__
 *
 * To run a query within a React component, call `useStudentSkillXpsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentSkillXpsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentSkillXpsQuery({
 *   variables: {
 *   },
 * });
 */
export function useStudentSkillXpsQuery(baseOptions?: Apollo.QueryHookOptions<StudentSkillXpsQuery, StudentSkillXpsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StudentSkillXpsQuery, StudentSkillXpsQueryVariables>(StudentSkillXpsDocument, options)
      }
export function useStudentSkillXpsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StudentSkillXpsQuery, StudentSkillXpsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StudentSkillXpsQuery, StudentSkillXpsQueryVariables>(StudentSkillXpsDocument, options)
        }
export type StudentSkillXpsQueryHookResult = ReturnType<typeof useStudentSkillXpsQuery>;
export type StudentSkillXpsLazyQueryHookResult = ReturnType<typeof useStudentSkillXpsLazyQuery>;
export type StudentSkillXpsQueryResult = Apollo.QueryResult<StudentSkillXpsQuery, StudentSkillXpsQueryVariables>;
export const StudentCoinLogsDocument = gql`
    query studentCoinLogs {
  studentCoinLogs {
    data
  }
}
    `

/**
 * __useStudentCoinLogsQuery__
 *
 * To run a query within a React component, call `useStudentCoinLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentCoinLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentCoinLogsQuery({
 *   variables: {
 *   },
 * });
 */
export function useStudentCoinLogsQuery(baseOptions?: Apollo.QueryHookOptions<StudentCoinLogsQuery, StudentCoinLogsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StudentCoinLogsQuery, StudentCoinLogsQueryVariables>(StudentCoinLogsDocument, options)
      }
export function useStudentCoinLogsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StudentCoinLogsQuery, StudentCoinLogsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StudentCoinLogsQuery, StudentCoinLogsQueryVariables>(StudentCoinLogsDocument, options)
        }
export type StudentCoinLogsQueryHookResult = ReturnType<typeof useStudentCoinLogsQuery>;
export type StudentCoinLogsLazyQueryHookResult = ReturnType<typeof useStudentCoinLogsLazyQuery>;
export type StudentCoinLogsQueryResult = Apollo.QueryResult<StudentCoinLogsQuery, StudentCoinLogsQueryVariables>;
export const StudentNextLevelRequirementDocument = gql`
    query studentNextLevelRequirement {
  studentNextLevelRequirement {
    data
  }
}
    `

/**
 * __useStudentNextLevelRequirementQuery__
 *
 * To run a query within a React component, call `useStudentNextLevelRequirementQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentNextLevelRequirementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentNextLevelRequirementQuery({
 *   variables: {
 *   },
 * });
 */
export function useStudentNextLevelRequirementQuery(baseOptions?: Apollo.QueryHookOptions<StudentNextLevelRequirementQuery, StudentNextLevelRequirementQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StudentNextLevelRequirementQuery, StudentNextLevelRequirementQueryVariables>(StudentNextLevelRequirementDocument, options)
      }
export function useStudentNextLevelRequirementLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StudentNextLevelRequirementQuery, StudentNextLevelRequirementQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StudentNextLevelRequirementQuery, StudentNextLevelRequirementQueryVariables>(StudentNextLevelRequirementDocument, options)
        }
export type StudentNextLevelRequirementQueryHookResult = ReturnType<typeof useStudentNextLevelRequirementQuery>;
export type StudentNextLevelRequirementLazyQueryHookResult = ReturnType<typeof useStudentNextLevelRequirementLazyQuery>;
export type StudentNextLevelRequirementQueryResult = Apollo.QueryResult<StudentNextLevelRequirementQuery, StudentNextLevelRequirementQueryVariables>;
export const SitemapDocument = gql`
    query sitemap {
  sitemap {
    loc
    lastmod
  }
}
    `

/**
 * __useSitemapQuery__
 *
 * To run a query within a React component, call `useSitemapQuery` and pass it any options that fit your needs.
 * When your component renders, `useSitemapQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSitemapQuery({
 *   variables: {
 *   },
 * });
 */
export function useSitemapQuery(baseOptions?: Apollo.QueryHookOptions<SitemapQuery, SitemapQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SitemapQuery, SitemapQueryVariables>(SitemapDocument, options)
      }
export function useSitemapLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SitemapQuery, SitemapQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SitemapQuery, SitemapQueryVariables>(SitemapDocument, options)
        }
export type SitemapQueryHookResult = ReturnType<typeof useSitemapQuery>;
export type SitemapLazyQueryHookResult = ReturnType<typeof useSitemapLazyQuery>;
export type SitemapQueryResult = Apollo.QueryResult<SitemapQuery, SitemapQueryVariables>;
export const StudentSkillsDocument = gql`
    query studentSkills($take: Int!, $skip: Int!) {
  studentSkills(take: $take, skip: $skip) {
    id
    name
    slug
    featuredImage
  }
}
    `

/**
 * __useStudentSkillsQuery__
 *
 * To run a query within a React component, call `useStudentSkillsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentSkillsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentSkillsQuery({
 *   variables: {
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useStudentSkillsQuery(baseOptions: Apollo.QueryHookOptions<StudentSkillsQuery, StudentSkillsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StudentSkillsQuery, StudentSkillsQueryVariables>(StudentSkillsDocument, options)
      }
export function useStudentSkillsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StudentSkillsQuery, StudentSkillsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StudentSkillsQuery, StudentSkillsQueryVariables>(StudentSkillsDocument, options)
        }
export type StudentSkillsQueryHookResult = ReturnType<typeof useStudentSkillsQuery>;
export type StudentSkillsLazyQueryHookResult = ReturnType<typeof useStudentSkillsLazyQuery>;
export type StudentSkillsQueryResult = Apollo.QueryResult<StudentSkillsQuery, StudentSkillsQueryVariables>;
export const PublicSkillsDocument = gql`
    query publicSkills($where: FindStudentManySkillWhereInput, $take: Int!, $skip: Int!) {
  publicSkills(where: $where, take: $take, skip: $skip) {
    id
    name
    slug
    featuredImage
  }
}
    `

/**
 * __usePublicSkillsQuery__
 *
 * To run a query within a React component, call `usePublicSkillsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicSkillsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicSkillsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function usePublicSkillsQuery(baseOptions: Apollo.QueryHookOptions<PublicSkillsQuery, PublicSkillsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PublicSkillsQuery, PublicSkillsQueryVariables>(PublicSkillsDocument, options)
      }
export function usePublicSkillsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PublicSkillsQuery, PublicSkillsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PublicSkillsQuery, PublicSkillsQueryVariables>(PublicSkillsDocument, options)
        }
export type PublicSkillsQueryHookResult = ReturnType<typeof usePublicSkillsQuery>;
export type PublicSkillsLazyQueryHookResult = ReturnType<typeof usePublicSkillsLazyQuery>;
export type PublicSkillsQueryResult = Apollo.QueryResult<PublicSkillsQuery, PublicSkillsQueryVariables>;
export const PublicSkillDocument = gql`
    query publicSkill($where: SkillWhereUniqueInput!) {
  publicSkill(where: $where) {
    id
    name
    slug
    bundle
    image
    metaTagData
    featuredImage
    description
  }
}
    `

/**
 * __usePublicSkillQuery__
 *
 * To run a query within a React component, call `usePublicSkillQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicSkillQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicSkillQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function usePublicSkillQuery(baseOptions: Apollo.QueryHookOptions<PublicSkillQuery, PublicSkillQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PublicSkillQuery, PublicSkillQueryVariables>(PublicSkillDocument, options)
      }
export function usePublicSkillLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PublicSkillQuery, PublicSkillQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PublicSkillQuery, PublicSkillQueryVariables>(PublicSkillDocument, options)
        }
export type PublicSkillQueryHookResult = ReturnType<typeof usePublicSkillQuery>;
export type PublicSkillLazyQueryHookResult = ReturnType<typeof usePublicSkillLazyQuery>;
export type PublicSkillQueryResult = Apollo.QueryResult<PublicSkillQuery, PublicSkillQueryVariables>;
export const StudentSkillDocument = gql`
    query studentSkill($slug: String!) {
  studentSkill(where: {slug: $slug}) {
    id
    name
    slug
    bundle
    image
    metaTagData
    featuredImage
    description
  }
}
    `

/**
 * __useStudentSkillQuery__
 *
 * To run a query within a React component, call `useStudentSkillQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentSkillQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentSkillQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useStudentSkillQuery(baseOptions: Apollo.QueryHookOptions<StudentSkillQuery, StudentSkillQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StudentSkillQuery, StudentSkillQueryVariables>(StudentSkillDocument, options)
      }
export function useStudentSkillLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StudentSkillQuery, StudentSkillQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StudentSkillQuery, StudentSkillQueryVariables>(StudentSkillDocument, options)
        }
export type StudentSkillQueryHookResult = ReturnType<typeof useStudentSkillQuery>;
export type StudentSkillLazyQueryHookResult = ReturnType<typeof useStudentSkillLazyQuery>;
export type StudentSkillQueryResult = Apollo.QueryResult<StudentSkillQuery, StudentSkillQueryVariables>;
export const StudentStoriesDocument = gql`
    query studentStories($tag: StoryTagWhereUniqueInput, $take: Int, $skip: Int) {
  studentStories(tag: $tag, take: $take, skip: $skip) {
    id
    slug
    title
    featuredImage
  }
}
    `

/**
 * __useStudentStoriesQuery__
 *
 * To run a query within a React component, call `useStudentStoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentStoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentStoriesQuery({
 *   variables: {
 *      tag: // value for 'tag'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useStudentStoriesQuery(baseOptions?: Apollo.QueryHookOptions<StudentStoriesQuery, StudentStoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StudentStoriesQuery, StudentStoriesQueryVariables>(StudentStoriesDocument, options)
      }
export function useStudentStoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StudentStoriesQuery, StudentStoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StudentStoriesQuery, StudentStoriesQueryVariables>(StudentStoriesDocument, options)
        }
export type StudentStoriesQueryHookResult = ReturnType<typeof useStudentStoriesQuery>;
export type StudentStoriesLazyQueryHookResult = ReturnType<typeof useStudentStoriesLazyQuery>;
export type StudentStoriesQueryResult = Apollo.QueryResult<StudentStoriesQuery, StudentStoriesQueryVariables>;
export const StudentStoryDocument = gql`
    query studentStory($slug: String!) {
  studentStory(where: {slug: $slug}) {
    id
    slug
    title
    featuredImage
    description
  }
}
    `

/**
 * __useStudentStoryQuery__
 *
 * To run a query within a React component, call `useStudentStoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentStoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentStoryQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useStudentStoryQuery(baseOptions: Apollo.QueryHookOptions<StudentStoryQuery, StudentStoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StudentStoryQuery, StudentStoryQueryVariables>(StudentStoryDocument, options)
      }
export function useStudentStoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StudentStoryQuery, StudentStoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StudentStoryQuery, StudentStoryQueryVariables>(StudentStoryDocument, options)
        }
export type StudentStoryQueryHookResult = ReturnType<typeof useStudentStoryQuery>;
export type StudentStoryLazyQueryHookResult = ReturnType<typeof useStudentStoryLazyQuery>;
export type StudentStoryQueryResult = Apollo.QueryResult<StudentStoryQuery, StudentStoryQueryVariables>;
export const StudentStoryBannersDocument = gql`
    query studentStoryBanners {
  studentStoryBanners {
    id
    image
    destinationUrl
  }
}
    `

/**
 * __useStudentStoryBannersQuery__
 *
 * To run a query within a React component, call `useStudentStoryBannersQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentStoryBannersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentStoryBannersQuery({
 *   variables: {
 *   },
 * });
 */
export function useStudentStoryBannersQuery(baseOptions?: Apollo.QueryHookOptions<StudentStoryBannersQuery, StudentStoryBannersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StudentStoryBannersQuery, StudentStoryBannersQueryVariables>(StudentStoryBannersDocument, options)
      }
export function useStudentStoryBannersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StudentStoryBannersQuery, StudentStoryBannersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StudentStoryBannersQuery, StudentStoryBannersQueryVariables>(StudentStoryBannersDocument, options)
        }
export type StudentStoryBannersQueryHookResult = ReturnType<typeof useStudentStoryBannersQuery>;
export type StudentStoryBannersLazyQueryHookResult = ReturnType<typeof useStudentStoryBannersLazyQuery>;
export type StudentStoryBannersQueryResult = Apollo.QueryResult<StudentStoryBannersQuery, StudentStoryBannersQueryVariables>;
export const StudentStoryTagsDocument = gql`
    query studentStoryTags {
  studentStoryTags {
    id
    key
    text
    image
  }
}
    `

/**
 * __useStudentStoryTagsQuery__
 *
 * To run a query within a React component, call `useStudentStoryTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentStoryTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentStoryTagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useStudentStoryTagsQuery(baseOptions?: Apollo.QueryHookOptions<StudentStoryTagsQuery, StudentStoryTagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StudentStoryTagsQuery, StudentStoryTagsQueryVariables>(StudentStoryTagsDocument, options)
      }
export function useStudentStoryTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StudentStoryTagsQuery, StudentStoryTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StudentStoryTagsQuery, StudentStoryTagsQueryVariables>(StudentStoryTagsDocument, options)
        }
export type StudentStoryTagsQueryHookResult = ReturnType<typeof useStudentStoryTagsQuery>;
export type StudentStoryTagsLazyQueryHookResult = ReturnType<typeof useStudentStoryTagsLazyQuery>;
export type StudentStoryTagsQueryResult = Apollo.QueryResult<StudentStoryTagsQuery, StudentStoryTagsQueryVariables>;
export const UnlockStudentStoryActivityDocument = gql`
    mutation unlockStudentStoryActivity($slug: String!) {
  unlockStudentStoryActivity(where: {slug: $slug}) {
    id
    slug
    title
    meta
    content
    featuredImage
    xps
    timeRequired
    video
    challengeStatement
    skill {
      slug
    }
  }
}
    `
export type UnlockStudentStoryActivityMutationFn = Apollo.MutationFunction<UnlockStudentStoryActivityMutation, UnlockStudentStoryActivityMutationVariables>;

/**
 * __useUnlockStudentStoryActivityMutation__
 *
 * To run a mutation, you first call `useUnlockStudentStoryActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnlockStudentStoryActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unlockStudentStoryActivityMutation, { data, loading, error }] = useUnlockStudentStoryActivityMutation({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useUnlockStudentStoryActivityMutation(baseOptions?: Apollo.MutationHookOptions<UnlockStudentStoryActivityMutation, UnlockStudentStoryActivityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnlockStudentStoryActivityMutation, UnlockStudentStoryActivityMutationVariables>(UnlockStudentStoryActivityDocument, options)
      }
export type UnlockStudentStoryActivityMutationHookResult = ReturnType<typeof useUnlockStudentStoryActivityMutation>;
export type UnlockStudentStoryActivityMutationResult = Apollo.MutationResult<UnlockStudentStoryActivityMutation>;
export type UnlockStudentStoryActivityMutationOptions = Apollo.BaseMutationOptions<UnlockStudentStoryActivityMutation, UnlockStudentStoryActivityMutationVariables>;
export const StudentStoryActivitiesDocument = gql`
    query studentStoryActivities($where: FindStudentStoryActivityInput, $take: Int, $skip: Int) {
  studentStoryActivities(where: $where, take: $take, skip: $skip) {
    id
    slug
    title
    timeRequired
    featuredImage
    commentsCount
    story {
      slug
    }
  }
}
    `

/**
 * __useStudentStoryActivitiesQuery__
 *
 * To run a query within a React component, call `useStudentStoryActivitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentStoryActivitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentStoryActivitiesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useStudentStoryActivitiesQuery(baseOptions?: Apollo.QueryHookOptions<StudentStoryActivitiesQuery, StudentStoryActivitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StudentStoryActivitiesQuery, StudentStoryActivitiesQueryVariables>(StudentStoryActivitiesDocument, options)
      }
export function useStudentStoryActivitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StudentStoryActivitiesQuery, StudentStoryActivitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StudentStoryActivitiesQuery, StudentStoryActivitiesQueryVariables>(StudentStoryActivitiesDocument, options)
        }
export type StudentStoryActivitiesQueryHookResult = ReturnType<typeof useStudentStoryActivitiesQuery>;
export type StudentStoryActivitiesLazyQueryHookResult = ReturnType<typeof useStudentStoryActivitiesLazyQuery>;
export type StudentStoryActivitiesQueryResult = Apollo.QueryResult<StudentStoryActivitiesQuery, StudentStoryActivitiesQueryVariables>;
export const StudentStoryActivityAccessDocument = gql`
    query studentStoryActivityAccess($slug: String!) {
  studentStoryActivityAccess(where: {slug: $slug}) {
    success
    message
  }
}
    `

/**
 * __useStudentStoryActivityAccessQuery__
 *
 * To run a query within a React component, call `useStudentStoryActivityAccessQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentStoryActivityAccessQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentStoryActivityAccessQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useStudentStoryActivityAccessQuery(baseOptions: Apollo.QueryHookOptions<StudentStoryActivityAccessQuery, StudentStoryActivityAccessQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StudentStoryActivityAccessQuery, StudentStoryActivityAccessQueryVariables>(StudentStoryActivityAccessDocument, options)
      }
export function useStudentStoryActivityAccessLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StudentStoryActivityAccessQuery, StudentStoryActivityAccessQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StudentStoryActivityAccessQuery, StudentStoryActivityAccessQueryVariables>(StudentStoryActivityAccessDocument, options)
        }
export type StudentStoryActivityAccessQueryHookResult = ReturnType<typeof useStudentStoryActivityAccessQuery>;
export type StudentStoryActivityAccessLazyQueryHookResult = ReturnType<typeof useStudentStoryActivityAccessLazyQuery>;
export type StudentStoryActivityAccessQueryResult = Apollo.QueryResult<StudentStoryActivityAccessQuery, StudentStoryActivityAccessQueryVariables>;
export const StudentStoryActivityPartialDocument = gql`
    query studentStoryActivityPartial($where: StoryActivityWhereUniqueInput!) {
  studentStoryActivity(where: $where) {
    id
    slug
    title
    meta
    image
    featuredImage
    xps
    timeRequired
    coinsToSpend
  }
}
    `

/**
 * __useStudentStoryActivityPartialQuery__
 *
 * To run a query within a React component, call `useStudentStoryActivityPartialQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentStoryActivityPartialQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentStoryActivityPartialQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useStudentStoryActivityPartialQuery(baseOptions: Apollo.QueryHookOptions<StudentStoryActivityPartialQuery, StudentStoryActivityPartialQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StudentStoryActivityPartialQuery, StudentStoryActivityPartialQueryVariables>(StudentStoryActivityPartialDocument, options)
      }
export function useStudentStoryActivityPartialLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StudentStoryActivityPartialQuery, StudentStoryActivityPartialQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StudentStoryActivityPartialQuery, StudentStoryActivityPartialQueryVariables>(StudentStoryActivityPartialDocument, options)
        }
export type StudentStoryActivityPartialQueryHookResult = ReturnType<typeof useStudentStoryActivityPartialQuery>;
export type StudentStoryActivityPartialLazyQueryHookResult = ReturnType<typeof useStudentStoryActivityPartialLazyQuery>;
export type StudentStoryActivityPartialQueryResult = Apollo.QueryResult<StudentStoryActivityPartialQuery, StudentStoryActivityPartialQueryVariables>;
export const StudentStoryActivityDetailDocument = gql`
    query studentStoryActivityDetail($where: StoryActivityWhereUniqueInput!) {
  studentStoryActivity(where: $where) {
    id
    slug
    title
    meta
    content
    image
    featuredImage
    xps
    timeRequired
    video
    audio
    challengeStatement
    coinsToSpend
    isCommentEnabled
    skill {
      name
    }
  }
}
    `

/**
 * __useStudentStoryActivityDetailQuery__
 *
 * To run a query within a React component, call `useStudentStoryActivityDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentStoryActivityDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentStoryActivityDetailQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useStudentStoryActivityDetailQuery(baseOptions: Apollo.QueryHookOptions<StudentStoryActivityDetailQuery, StudentStoryActivityDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StudentStoryActivityDetailQuery, StudentStoryActivityDetailQueryVariables>(StudentStoryActivityDetailDocument, options)
      }
export function useStudentStoryActivityDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StudentStoryActivityDetailQuery, StudentStoryActivityDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StudentStoryActivityDetailQuery, StudentStoryActivityDetailQueryVariables>(StudentStoryActivityDetailDocument, options)
        }
export type StudentStoryActivityDetailQueryHookResult = ReturnType<typeof useStudentStoryActivityDetailQuery>;
export type StudentStoryActivityDetailLazyQueryHookResult = ReturnType<typeof useStudentStoryActivityDetailLazyQuery>;
export type StudentStoryActivityDetailQueryResult = Apollo.QueryResult<StudentStoryActivityDetailQuery, StudentStoryActivityDetailQueryVariables>;
export const PublicStoryActivitiesDocument = gql`
    query publicStoryActivities($where: StoryActivityWhereInput, $orderBy: [StoryActivityOrderByWithRelationInput!], $cursor: StoryActivityWhereUniqueInput, $take: Int, $skip: Int, $distinct: [StoryActivityScalarFieldEnum!]) {
  publicStoryActivities(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
    distinct: $distinct
  ) {
    id
    slug
    title
    content
    image
    featuredImage
    xps
    timeRequired
    video
    audio
    challengeStatement
    coinsToSpend
    skill {
      name
    }
  }
}
    `

/**
 * __usePublicStoryActivitiesQuery__
 *
 * To run a query within a React component, call `usePublicStoryActivitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicStoryActivitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicStoryActivitiesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      distinct: // value for 'distinct'
 *   },
 * });
 */
export function usePublicStoryActivitiesQuery(baseOptions?: Apollo.QueryHookOptions<PublicStoryActivitiesQuery, PublicStoryActivitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PublicStoryActivitiesQuery, PublicStoryActivitiesQueryVariables>(PublicStoryActivitiesDocument, options)
      }
export function usePublicStoryActivitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PublicStoryActivitiesQuery, PublicStoryActivitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PublicStoryActivitiesQuery, PublicStoryActivitiesQueryVariables>(PublicStoryActivitiesDocument, options)
        }
export type PublicStoryActivitiesQueryHookResult = ReturnType<typeof usePublicStoryActivitiesQuery>;
export type PublicStoryActivitiesLazyQueryHookResult = ReturnType<typeof usePublicStoryActivitiesLazyQuery>;
export type PublicStoryActivitiesQueryResult = Apollo.QueryResult<PublicStoryActivitiesQuery, PublicStoryActivitiesQueryVariables>;
export const PublicStoryActivityDocument = gql`
    query publicStoryActivity($where: StoryActivityWhereUniqueInput!) {
  publicStoryActivity(where: $where) {
    id
    slug
    title
    content
    image
    featuredImage
    xps
    timeRequired
    video
    audio
    challengeStatement
    coinsToSpend
    skill {
      name
    }
  }
}
    `

/**
 * __usePublicStoryActivityQuery__
 *
 * To run a query within a React component, call `usePublicStoryActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicStoryActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicStoryActivityQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function usePublicStoryActivityQuery(baseOptions: Apollo.QueryHookOptions<PublicStoryActivityQuery, PublicStoryActivityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PublicStoryActivityQuery, PublicStoryActivityQueryVariables>(PublicStoryActivityDocument, options)
      }
export function usePublicStoryActivityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PublicStoryActivityQuery, PublicStoryActivityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PublicStoryActivityQuery, PublicStoryActivityQueryVariables>(PublicStoryActivityDocument, options)
        }
export type PublicStoryActivityQueryHookResult = ReturnType<typeof usePublicStoryActivityQuery>;
export type PublicStoryActivityLazyQueryHookResult = ReturnType<typeof usePublicStoryActivityLazyQuery>;
export type PublicStoryActivityQueryResult = Apollo.QueryResult<PublicStoryActivityQuery, PublicStoryActivityQueryVariables>;
export const PublicTagDocument = gql`
    query publicTag($where: TagWhereUniqueInput!) {
  publicTag(where: $where) {
    id
    name
    slug
    description
    seoContent
    metaTagData
    jsonLD
    productScript
    buyScript
  }
}
    `

/**
 * __usePublicTagQuery__
 *
 * To run a query within a React component, call `usePublicTagQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicTagQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicTagQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function usePublicTagQuery(baseOptions: Apollo.QueryHookOptions<PublicTagQuery, PublicTagQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PublicTagQuery, PublicTagQueryVariables>(PublicTagDocument, options)
      }
export function usePublicTagLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PublicTagQuery, PublicTagQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PublicTagQuery, PublicTagQueryVariables>(PublicTagDocument, options)
        }
export type PublicTagQueryHookResult = ReturnType<typeof usePublicTagQuery>;
export type PublicTagLazyQueryHookResult = ReturnType<typeof usePublicTagLazyQuery>;
export type PublicTagQueryResult = Apollo.QueryResult<PublicTagQuery, PublicTagQueryVariables>;
export const UpdateProfileDocument = gql`
    mutation updateProfile($data: UpdateProfileInput!) {
  updateProfile(data: $data) {
    id
  }
}
    `
export type UpdateProfileMutationFn = Apollo.MutationFunction<UpdateProfileMutation, UpdateProfileMutationVariables>;

/**
 * __useUpdateProfileMutation__
 *
 * To run a mutation, you first call `useUpdateProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileMutation, { data, loading, error }] = useUpdateProfileMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateProfileMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProfileMutation, UpdateProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProfileMutation, UpdateProfileMutationVariables>(UpdateProfileDocument, options)
      }
export type UpdateProfileMutationHookResult = ReturnType<typeof useUpdateProfileMutation>;
export type UpdateProfileMutationResult = Apollo.MutationResult<UpdateProfileMutation>;
export type UpdateProfileMutationOptions = Apollo.BaseMutationOptions<UpdateProfileMutation, UpdateProfileMutationVariables>;
export const MeDocument = gql`
    query me {
  me {
    id
    userId
    displayName
    email
    coins
    totalXps
    stars
    level
    levelPercentage
    isMeta
    dailyActivityAnswered
    dailyChallengeAnswered
    notificationToken
  }
}
    `

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options)
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options)
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const UserProfileDocument = gql`
    query userProfile {
  me {
    id
    userId
    firstName
    lastName
    displayName
    email
    coins
    standard {
      value
    }
    phoneNumber
    address1
    address2
    city
    state
    country
    postcode
    schoolText
    isHomeschooler
    dob
    allowStandardUpdate
    profileImage
  }
}
    `

/**
 * __useUserProfileQuery__
 *
 * To run a query within a React component, call `useUserProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserProfileQuery(baseOptions?: Apollo.QueryHookOptions<UserProfileQuery, UserProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserProfileQuery, UserProfileQueryVariables>(UserProfileDocument, options)
      }
export function useUserProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserProfileQuery, UserProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserProfileQuery, UserProfileQueryVariables>(UserProfileDocument, options)
        }
export type UserProfileQueryHookResult = ReturnType<typeof useUserProfileQuery>;
export type UserProfileLazyQueryHookResult = ReturnType<typeof useUserProfileLazyQuery>;
export type UserProfileQueryResult = Apollo.QueryResult<UserProfileQuery, UserProfileQueryVariables>;
export const UpdateStudentMetaDocument = gql`
    mutation updateStudentMeta($data: UpdateStudentMetaInput!) {
  updateStudentMeta(data: $data) {
    success
    message
  }
}
    `
export type UpdateStudentMetaMutationFn = Apollo.MutationFunction<UpdateStudentMetaMutation, UpdateStudentMetaMutationVariables>;

/**
 * __useUpdateStudentMetaMutation__
 *
 * To run a mutation, you first call `useUpdateStudentMetaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStudentMetaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStudentMetaMutation, { data, loading, error }] = useUpdateStudentMetaMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateStudentMetaMutation(baseOptions?: Apollo.MutationHookOptions<UpdateStudentMetaMutation, UpdateStudentMetaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateStudentMetaMutation, UpdateStudentMetaMutationVariables>(UpdateStudentMetaDocument, options)
      }
export type UpdateStudentMetaMutationHookResult = ReturnType<typeof useUpdateStudentMetaMutation>;
export type UpdateStudentMetaMutationResult = Apollo.MutationResult<UpdateStudentMetaMutation>;
export type UpdateStudentMetaMutationOptions = Apollo.BaseMutationOptions<UpdateStudentMetaMutation, UpdateStudentMetaMutationVariables>;
export const StudentRegisterWorkshopDocument = gql`
    mutation studentRegisterWorkshop($where: StudentWorkshopUniqueInput!) {
  studentRegisterWorkshop(where: $where) {
    success
    message
  }
}
    `
export type StudentRegisterWorkshopMutationFn = Apollo.MutationFunction<StudentRegisterWorkshopMutation, StudentRegisterWorkshopMutationVariables>;

/**
 * __useStudentRegisterWorkshopMutation__
 *
 * To run a mutation, you first call `useStudentRegisterWorkshopMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStudentRegisterWorkshopMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [studentRegisterWorkshopMutation, { data, loading, error }] = useStudentRegisterWorkshopMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useStudentRegisterWorkshopMutation(baseOptions?: Apollo.MutationHookOptions<StudentRegisterWorkshopMutation, StudentRegisterWorkshopMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StudentRegisterWorkshopMutation, StudentRegisterWorkshopMutationVariables>(StudentRegisterWorkshopDocument, options)
      }
export type StudentRegisterWorkshopMutationHookResult = ReturnType<typeof useStudentRegisterWorkshopMutation>;
export type StudentRegisterWorkshopMutationResult = Apollo.MutationResult<StudentRegisterWorkshopMutation>;
export type StudentRegisterWorkshopMutationOptions = Apollo.BaseMutationOptions<StudentRegisterWorkshopMutation, StudentRegisterWorkshopMutationVariables>;
export const PublicPastWorkshopsDocument = gql`
    query publicPastWorkshops($skip: Int, $take: Int) {
  publicPastWorkshops(skip: $skip, take: $take) {
    id
    name
    slug
    featuredImage
  }
}
    `

/**
 * __usePublicPastWorkshopsQuery__
 *
 * To run a query within a React component, call `usePublicPastWorkshopsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicPastWorkshopsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicPastWorkshopsQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function usePublicPastWorkshopsQuery(baseOptions?: Apollo.QueryHookOptions<PublicPastWorkshopsQuery, PublicPastWorkshopsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PublicPastWorkshopsQuery, PublicPastWorkshopsQueryVariables>(PublicPastWorkshopsDocument, options)
      }
export function usePublicPastWorkshopsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PublicPastWorkshopsQuery, PublicPastWorkshopsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PublicPastWorkshopsQuery, PublicPastWorkshopsQueryVariables>(PublicPastWorkshopsDocument, options)
        }
export type PublicPastWorkshopsQueryHookResult = ReturnType<typeof usePublicPastWorkshopsQuery>;
export type PublicPastWorkshopsLazyQueryHookResult = ReturnType<typeof usePublicPastWorkshopsLazyQuery>;
export type PublicPastWorkshopsQueryResult = Apollo.QueryResult<PublicPastWorkshopsQuery, PublicPastWorkshopsQueryVariables>;
export const PublicUpcomingWorkshopsDocument = gql`
    query publicUpcomingWorkshops($where: FindPublicManyWorkshopWhereInput, $skip: Int, $take: Int) {
  publicUpcomingWorkshops(where: $where, skip: $skip, take: $take) {
    id
    name
    slug
    featuredImage
  }
}
    `

/**
 * __usePublicUpcomingWorkshopsQuery__
 *
 * To run a query within a React component, call `usePublicUpcomingWorkshopsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicUpcomingWorkshopsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicUpcomingWorkshopsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function usePublicUpcomingWorkshopsQuery(baseOptions?: Apollo.QueryHookOptions<PublicUpcomingWorkshopsQuery, PublicUpcomingWorkshopsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PublicUpcomingWorkshopsQuery, PublicUpcomingWorkshopsQueryVariables>(PublicUpcomingWorkshopsDocument, options)
      }
export function usePublicUpcomingWorkshopsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PublicUpcomingWorkshopsQuery, PublicUpcomingWorkshopsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PublicUpcomingWorkshopsQuery, PublicUpcomingWorkshopsQueryVariables>(PublicUpcomingWorkshopsDocument, options)
        }
export type PublicUpcomingWorkshopsQueryHookResult = ReturnType<typeof usePublicUpcomingWorkshopsQuery>;
export type PublicUpcomingWorkshopsLazyQueryHookResult = ReturnType<typeof usePublicUpcomingWorkshopsLazyQuery>;
export type PublicUpcomingWorkshopsQueryResult = Apollo.QueryResult<PublicUpcomingWorkshopsQuery, PublicUpcomingWorkshopsQueryVariables>;
export const PublicSsrWorkshopsDocument = gql`
    query publicSSRWorkshops($skip: Int, $take: Int) {
  publicUpcomingWorkshops(skip: $skip, take: $take) {
    id
    name
    slug
    featuredImage
  }
  publicPastWorkshops(skip: $skip, take: $take) {
    id
    name
    slug
    featuredImage
  }
}
    `

/**
 * __usePublicSsrWorkshopsQuery__
 *
 * To run a query within a React component, call `usePublicSsrWorkshopsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicSsrWorkshopsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicSsrWorkshopsQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function usePublicSsrWorkshopsQuery(baseOptions?: Apollo.QueryHookOptions<PublicSsrWorkshopsQuery, PublicSsrWorkshopsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PublicSsrWorkshopsQuery, PublicSsrWorkshopsQueryVariables>(PublicSsrWorkshopsDocument, options)
      }
export function usePublicSsrWorkshopsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PublicSsrWorkshopsQuery, PublicSsrWorkshopsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PublicSsrWorkshopsQuery, PublicSsrWorkshopsQueryVariables>(PublicSsrWorkshopsDocument, options)
        }
export type PublicSsrWorkshopsQueryHookResult = ReturnType<typeof usePublicSsrWorkshopsQuery>;
export type PublicSsrWorkshopsLazyQueryHookResult = ReturnType<typeof usePublicSsrWorkshopsLazyQuery>;
export type PublicSsrWorkshopsQueryResult = Apollo.QueryResult<PublicSsrWorkshopsQuery, PublicSsrWorkshopsQueryVariables>;
export const StudentUpcomingWorkshopsDocument = gql`
    query studentUpcomingWorkshops($skip: Int, $take: Int) {
  studentUpcomingWorkshops(skip: $skip, take: $take) {
    id
    name
    slug
    featuredImage
  }
}
    `

/**
 * __useStudentUpcomingWorkshopsQuery__
 *
 * To run a query within a React component, call `useStudentUpcomingWorkshopsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentUpcomingWorkshopsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentUpcomingWorkshopsQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useStudentUpcomingWorkshopsQuery(baseOptions?: Apollo.QueryHookOptions<StudentUpcomingWorkshopsQuery, StudentUpcomingWorkshopsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StudentUpcomingWorkshopsQuery, StudentUpcomingWorkshopsQueryVariables>(StudentUpcomingWorkshopsDocument, options)
      }
export function useStudentUpcomingWorkshopsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StudentUpcomingWorkshopsQuery, StudentUpcomingWorkshopsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StudentUpcomingWorkshopsQuery, StudentUpcomingWorkshopsQueryVariables>(StudentUpcomingWorkshopsDocument, options)
        }
export type StudentUpcomingWorkshopsQueryHookResult = ReturnType<typeof useStudentUpcomingWorkshopsQuery>;
export type StudentUpcomingWorkshopsLazyQueryHookResult = ReturnType<typeof useStudentUpcomingWorkshopsLazyQuery>;
export type StudentUpcomingWorkshopsQueryResult = Apollo.QueryResult<StudentUpcomingWorkshopsQuery, StudentUpcomingWorkshopsQueryVariables>;
export const StudentPastWorkshopsDocument = gql`
    query studentPastWorkshops($skip: Int, $take: Int) {
  studentPastWorkshops(skip: $skip, take: $take) {
    id
    name
    slug
    featuredImage
  }
}
    `

/**
 * __useStudentPastWorkshopsQuery__
 *
 * To run a query within a React component, call `useStudentPastWorkshopsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentPastWorkshopsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentPastWorkshopsQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useStudentPastWorkshopsQuery(baseOptions?: Apollo.QueryHookOptions<StudentPastWorkshopsQuery, StudentPastWorkshopsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StudentPastWorkshopsQuery, StudentPastWorkshopsQueryVariables>(StudentPastWorkshopsDocument, options)
      }
export function useStudentPastWorkshopsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StudentPastWorkshopsQuery, StudentPastWorkshopsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StudentPastWorkshopsQuery, StudentPastWorkshopsQueryVariables>(StudentPastWorkshopsDocument, options)
        }
export type StudentPastWorkshopsQueryHookResult = ReturnType<typeof useStudentPastWorkshopsQuery>;
export type StudentPastWorkshopsLazyQueryHookResult = ReturnType<typeof useStudentPastWorkshopsLazyQuery>;
export type StudentPastWorkshopsQueryResult = Apollo.QueryResult<StudentPastWorkshopsQuery, StudentPastWorkshopsQueryVariables>;
export const StudentRegisteredWorkshopsDocument = gql`
    query studentRegisteredWorkshops {
  studentRegisteredWorkshops {
    id
    name
    slug
    featuredImage
  }
}
    `

/**
 * __useStudentRegisteredWorkshopsQuery__
 *
 * To run a query within a React component, call `useStudentRegisteredWorkshopsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentRegisteredWorkshopsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentRegisteredWorkshopsQuery({
 *   variables: {
 *   },
 * });
 */
export function useStudentRegisteredWorkshopsQuery(baseOptions?: Apollo.QueryHookOptions<StudentRegisteredWorkshopsQuery, StudentRegisteredWorkshopsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StudentRegisteredWorkshopsQuery, StudentRegisteredWorkshopsQueryVariables>(StudentRegisteredWorkshopsDocument, options)
      }
export function useStudentRegisteredWorkshopsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StudentRegisteredWorkshopsQuery, StudentRegisteredWorkshopsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StudentRegisteredWorkshopsQuery, StudentRegisteredWorkshopsQueryVariables>(StudentRegisteredWorkshopsDocument, options)
        }
export type StudentRegisteredWorkshopsQueryHookResult = ReturnType<typeof useStudentRegisteredWorkshopsQuery>;
export type StudentRegisteredWorkshopsLazyQueryHookResult = ReturnType<typeof useStudentRegisteredWorkshopsLazyQuery>;
export type StudentRegisteredWorkshopsQueryResult = Apollo.QueryResult<StudentRegisteredWorkshopsQuery, StudentRegisteredWorkshopsQueryVariables>;
export const PublicWorkshopDocument = gql`
    query publicWorkshop($where: StudentWorkshopUniqueInput!) {
  publicWorkshop(where: $where) {
    id
    name
    slug
    image
    description
    mode
    type
    ageGroup
    feesWithKit
    feesInCurrency
    feesWithoutKit
    metaTagData
    isOpen
    headerImageLarge
    headerImageSmall
    footerImageLarge
    footerImageSmall
    headerVideo
    headerText
    insiderActivities {
      id
      title
      featuredImage
      slug
      timeRequired
      difficultyLevel
      commentsCount
      skill {
        id
        name
        slug
      }
    }
    relatedActivities {
      id
      title
      featuredImage
      slug
      timeRequired
      difficultyLevel
      commentsCount
      skill {
        id
        name
        slug
      }
    }
    standards {
      id
      key
      value
    }
    displayTime
    trainerImage
    trainer {
      id
      firstName
      lastName
    }
    product {
      id
    }
    location
    address
    liveUrl
  }
}
    `

/**
 * __usePublicWorkshopQuery__
 *
 * To run a query within a React component, call `usePublicWorkshopQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicWorkshopQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicWorkshopQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function usePublicWorkshopQuery(baseOptions: Apollo.QueryHookOptions<PublicWorkshopQuery, PublicWorkshopQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PublicWorkshopQuery, PublicWorkshopQueryVariables>(PublicWorkshopDocument, options)
      }
export function usePublicWorkshopLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PublicWorkshopQuery, PublicWorkshopQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PublicWorkshopQuery, PublicWorkshopQueryVariables>(PublicWorkshopDocument, options)
        }
export type PublicWorkshopQueryHookResult = ReturnType<typeof usePublicWorkshopQuery>;
export type PublicWorkshopLazyQueryHookResult = ReturnType<typeof usePublicWorkshopLazyQuery>;
export type PublicWorkshopQueryResult = Apollo.QueryResult<PublicWorkshopQuery, PublicWorkshopQueryVariables>;
export const StudentWorkshopAccessDocument = gql`
    query studentWorkshopAccess($where: ActivityWhereUniqueInput!) {
  studentWorkshopAccess(where: $where) {
    success
    message
  }
}
    `

/**
 * __useStudentWorkshopAccessQuery__
 *
 * To run a query within a React component, call `useStudentWorkshopAccessQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentWorkshopAccessQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentWorkshopAccessQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useStudentWorkshopAccessQuery(baseOptions: Apollo.QueryHookOptions<StudentWorkshopAccessQuery, StudentWorkshopAccessQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StudentWorkshopAccessQuery, StudentWorkshopAccessQueryVariables>(StudentWorkshopAccessDocument, options)
      }
export function useStudentWorkshopAccessLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StudentWorkshopAccessQuery, StudentWorkshopAccessQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StudentWorkshopAccessQuery, StudentWorkshopAccessQueryVariables>(StudentWorkshopAccessDocument, options)
        }
export type StudentWorkshopAccessQueryHookResult = ReturnType<typeof useStudentWorkshopAccessQuery>;
export type StudentWorkshopAccessLazyQueryHookResult = ReturnType<typeof useStudentWorkshopAccessLazyQuery>;
export type StudentWorkshopAccessQueryResult = Apollo.QueryResult<StudentWorkshopAccessQuery, StudentWorkshopAccessQueryVariables>;