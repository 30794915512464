import styled from "styled-components"

export const StyledLink = styled.a`
  text-decoration: none;
  cursor: pointer;
  color: black;
  &:hover {
    color: black;
  }
  span {
    color: var(--bs-secondary);
    font-weight: bold;
    display: block;
    font-size: 1.2em;
    margin-top: .5rem;
    margin-bottom: 1rem;
  }
`

export const StyledSpan = styled.span`
  text-decoration: none;
  color: black;
  &:hover {
    color: black;
  }
`
