import { ReactNode, useContext, useEffect, useState } from "react"
import { useCookies } from "react-cookie"
import { useRouter } from "next/router"
import Head from "next/head"
import Script from "next/script"
import dynamic from "next/dynamic"
import { addEvent } from "@utils/gtm"
import { Desktop, Mobile } from "./Device"
import { differenceInDays } from "date-fns"
import { ACTION_TYPE, AppContext } from "AppContext"
import MediaQuery, { useMediaQuery } from "react-responsive"
import WebFooter from "@modules/Common/components/WebFooter"
import MobileFooter from "@modules/Common/components/MobileFooter"
import { useMeQuery, useRefreshTokenMutation } from "@generated/graphql"

const LoginModal = dynamic(() => import("@modules/Common/components/LoginModal"))

type Props = {
  children?: ReactNode
  title?: string
}

const PROTECTED_ROUTES = ["/welcome", "/checkout", "/dashboard", "/profile", "/purchase", "/rewards"]

export default function Layout({ children }: Props) {
  const router = useRouter()
  const [cookies, setCookie, removeCookie] = useCookies(["idToken", "expiresAt"])
  const { state, dispatch } = useContext(AppContext)
  const [isPublicPage, setIsPublicPage] = useState(true)
  const [showFooter, setShowFooter] = useState(false)

  const [refreshTokenMutation] = useRefreshTokenMutation()

  useEffect(() => {
    setIsPublicPage(!PROTECTED_ROUTES.includes(router.route))

    const localShowFooter =
      router.route === ("/") ||
      router.route === ("/welcome") ||
      router.route === "/register" ||
      router.route === "/login" ||
      router.route === "/sitemap.xml" ||
      router.route === "/faq" ||
      router.route.startsWith("/workshops/") ||
      router.route === "/logout"
    setShowFooter(!localShowFooter)
  }, [router])

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      if (state.user) {
        addEvent({
          "event": "page_view",
          "user_id": state.user?.id,
        })
      } else {
        addEvent({
          "event": "page_view",
        })
      }
    }

    router.events.on("routeChangeStart", handleRouteChange)

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      router.events.off("routeChangeStart", handleRouteChange)
    }
  }, [router, state])

  const { data: currentUserData, loading: currentUserLoading, error: currentUserError } = useMeQuery({
    skip: isPublicPage && cookies.idToken === undefined,
  })

  useEffect(() => {
    if (currentUserData && currentUserData.me) {
      if (currentUserData.me?.displayName === null) {
        router.push("/logout")
      } else {
        dispatch({ type: ACTION_TYPE.SET_USER, payload: currentUserData.me })
      }
      if (currentUserData.me.isMeta === false) {
        router.push("/welcome")
      }
    } else if (currentUserError !== undefined) {
      router.push("/logout")
    }
  }, [currentUserData, currentUserError, dispatch])

  useEffect(() => {
    if (cookies.idToken !== undefined) {
      if (differenceInDays(new Date(cookies.expiresAt), new Date()) < 10) {
        refreshTokenMutation().then(res => {
          if (res.data) {
            setCookie("idToken", res.data.refreshToken.authToken, {
              path: "/",
              expires: new Date(`${res.data.refreshToken.expiresAt}`),
              domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
              sameSite: "lax"
            })
            setCookie("expiresAt", res.data.refreshToken.expiresAt, {
              path: "/",
              expires: new Date(`${res.data.refreshToken.expiresAt}`),
              domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
              sameSite: "strict"
            })
          }
        })
      }
    }
  }, [cookies, isPublicPage, router])

  return (
    <>
      <Head>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </Head>
      <Script
        id="gtm-script"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GTM_ID}');`
        }}></Script>
      {children}
      {showFooter && <WebFooter />}
      {/* {!isPublicPage ? <>
        <Desktop>
          <WebFooter />
        </Desktop>
        <Mobile>
          <MobileFooter />
        </Mobile>
      </> : null} */}
      {state.showLoginModal && <LoginModal />}
    </>
  )
}
